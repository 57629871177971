import { FC, useState } from 'react';
import { ChecklistProps } from './types';
import { useTheme } from '@morf/theming';
import { Item } from './Item';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Container } from '../Container';
import { useResizeHandler } from '../../../apps/questions/lib/hooks/useResizeHandler';

export const Checklist: FC<ChecklistProps> = ({
  list,
  showAllItems = false,
}) => {
  const MAX_ITEMS = showAllItems ? list.length : 7;
  const theme = useTheme();
  const [showAll, setShowAll] = useState(false);

  const handleToggleClick = () => {
    setShowAll(!showAll);
  };

  const selectedItems = list.filter((item) => item.isSelected);
  const nonSelectedItems = list.filter((item) => !item.isSelected);

  const sortedItems = [
    ...selectedItems.sort((a, b) => a.label.localeCompare(b.label)),
    ...nonSelectedItems.sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const visibleItems = showAll ? sortedItems : sortedItems.slice(0, MAX_ITEMS);
  useResizeHandler([showAll]);

  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      flexWrap='wrap'
      gap={0.5}
      height='auto'
    >
      {visibleItems.map((item, index) => (
        <Item key={index} label={item.label} isSelected={item.isSelected} />
      ))}
      {list.length > MAX_ITEMS && (
        <Container cursor='pointer' px={0.25}>
          <Text
            tag='h5'
            color={theme.colors.main.primary.darker}
            onClick={handleToggleClick}
            whiteSpace='nowrap'
          >
            {showAll
              ? 'Hide specialties'
              : `+ ${list.length - visibleItems.length} more...`}
          </Text>
        </Container>
      )}
    </Flexbox>
  );
};
