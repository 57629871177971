"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./struct_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.intercom = (() => {
  const intercom = {};
  intercom.v1 = (() => {
    const v1 = {};
    v1.SendEventResponse = (() => {
      class SendEventResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SendEventResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.statusCode != null && Object.hasOwnProperty.call(message, "statusCode")) {
            writer.uint32(8).int64(message.statusCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SendEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.statusCode = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.SendEventResponse) {
            return object;
          }
          const message = new $root.intercom.v1.SendEventResponse();
          if (object.statusCode != null) {
            if ($util.Long) {
              (message.statusCode = $util.Long.fromValue(object.statusCode)).unsigned = false;
            } else if (typeof object.statusCode === "string") {
              message.statusCode = parseInt(object.statusCode, 10);
            } else if (typeof object.statusCode === "number") {
              message.statusCode = object.statusCode;
            } else if (typeof object.statusCode === "object") {
              message.statusCode = new $util.LongBits(object.statusCode.low >>> 0, object.statusCode.high >>> 0).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.statusCode = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.statusCode = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.statusCode != null && message.hasOwnProperty("statusCode")) {
            object.statusCode = typeof message.statusCode === "number" ? (options.longs === String ? String(message.statusCode) : message.statusCode) : (options.longs === String ? $util.Long.prototype.toString.call(message.statusCode) : options.longs === Number ? new $util.LongBits(message.statusCode.low >>> 0, message.statusCode.high >>> 0).toNumber() : message.statusCode);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.SendEventResponse";
        }
      }

      SendEventResponse.prototype.statusCode = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      return SendEventResponse;
    })();

    v1.ArchivedContact = (() => {
      class ArchivedContact {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ArchivedContact(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId")) {
            writer.uint32(18).string(message.externalId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ArchivedContact();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.externalId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.ArchivedContact) {
            return object;
          }
          const message = new $root.intercom.v1.ArchivedContact();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.externalId != null) {
            message.externalId = String(object.externalId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.externalId != null && message.hasOwnProperty("externalId")) {
            object.externalId = message.externalId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.ArchivedContact";
        }
      }

      ArchivedContact.prototype.id = "";
      ArchivedContact.prototype.externalId = null;

      return ArchivedContact;
    })();

    v1.Contact = (() => {
      class Contact {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Contact(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(18).string(message.id);
          }
          if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId")) {
            writer.uint32(26).string(message.externalId);
          }
          if (message.workspaceId != null && Object.hasOwnProperty.call(message, "workspaceId")) {
            writer.uint32(34).string(message.workspaceId);
          }
          if (message.role != null && Object.hasOwnProperty.call(message, "role")) {
            writer.uint32(42).string(message.role);
          }
          if (message.email != null && Object.hasOwnProperty.call(message, "email")) {
            writer.uint32(50).string(message.email);
          }
          if (message.emailDomain != null && Object.hasOwnProperty.call(message, "emailDomain")) {
            writer.uint32(58).string(message.emailDomain);
          }
          if (message.phone != null && Object.hasOwnProperty.call(message, "phone")) {
            writer.uint32(66).string(message.phone);
          }
          if (message.formattedPhone != null && Object.hasOwnProperty.call(message, "formattedPhone")) {
            writer.uint32(74).string(message.formattedPhone);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(82).string(message.name);
          }
          if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId")) {
            writer.uint32(88).int32(message.ownerId);
          }
          if (message.hasHardBounced != null && Object.hasOwnProperty.call(message, "hasHardBounced")) {
            writer.uint32(96).bool(message.hasHardBounced);
          }
          if (message.markedEmailAsSpam != null && Object.hasOwnProperty.call(message, "markedEmailAsSpam")) {
            writer.uint32(104).bool(message.markedEmailAsSpam);
          }
          if (message.unsubscribedFromEmails != null && Object.hasOwnProperty.call(message, "unsubscribedFromEmails")) {
            writer.uint32(112).bool(message.unsubscribedFromEmails);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(122).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(130).fork()).ldelim();
          }
          if (message.signedUpAt != null && Object.hasOwnProperty.call(message, "signedUpAt")) {
            timing$1.v1.Timestamp.encode(message.signedUpAt, writer.uint32(138).fork()).ldelim();
          }
          if (message.lastSeenAt != null && Object.hasOwnProperty.call(message, "lastSeenAt")) {
            timing$1.v1.Timestamp.encode(message.lastSeenAt, writer.uint32(146).fork()).ldelim();
          }
          if (message.lastRepliedAt != null && Object.hasOwnProperty.call(message, "lastRepliedAt")) {
            timing$1.v1.Timestamp.encode(message.lastRepliedAt, writer.uint32(154).fork()).ldelim();
          }
          if (message.lastContactedAt != null && Object.hasOwnProperty.call(message, "lastContactedAt")) {
            timing$1.v1.Timestamp.encode(message.lastContactedAt, writer.uint32(162).fork()).ldelim();
          }
          if (message.lastEmailOpenedAt != null && Object.hasOwnProperty.call(message, "lastEmailOpenedAt")) {
            timing$1.v1.Timestamp.encode(message.lastEmailOpenedAt, writer.uint32(170).fork()).ldelim();
          }
          if (message.lastEmailClickedAt != null && Object.hasOwnProperty.call(message, "lastEmailClickedAt")) {
            timing$1.v1.Timestamp.encode(message.lastEmailClickedAt, writer.uint32(178).fork()).ldelim();
          }
          if (message.languageOverride != null && Object.hasOwnProperty.call(message, "languageOverride")) {
            writer.uint32(186).string(message.languageOverride);
          }
          if (message.browser != null && Object.hasOwnProperty.call(message, "browser")) {
            writer.uint32(194).string(message.browser);
          }
          if (message.browserVersion != null && Object.hasOwnProperty.call(message, "browserVersion")) {
            writer.uint32(202).string(message.browserVersion);
          }
          if (message.browserLanguage != null && Object.hasOwnProperty.call(message, "browserLanguage")) {
            writer.uint32(210).string(message.browserLanguage);
          }
          if (message.os != null && Object.hasOwnProperty.call(message, "os")) {
            writer.uint32(218).string(message.os);
          }
          if (message.androidAppName != null && Object.hasOwnProperty.call(message, "androidAppName")) {
            writer.uint32(226).string(message.androidAppName);
          }
          if (message.androidAppVersion != null && Object.hasOwnProperty.call(message, "androidAppVersion")) {
            writer.uint32(234).string(message.androidAppVersion);
          }
          if (message.androidDevice != null && Object.hasOwnProperty.call(message, "androidDevice")) {
            writer.uint32(242).string(message.androidDevice);
          }
          if (message.androidOsVersion != null && Object.hasOwnProperty.call(message, "androidOsVersion")) {
            writer.uint32(250).string(message.androidOsVersion);
          }
          if (message.androidSdkVersion != null && Object.hasOwnProperty.call(message, "androidSdkVersion")) {
            writer.uint32(258).string(message.androidSdkVersion);
          }
          if (message.androidLastSeenAt != null && Object.hasOwnProperty.call(message, "androidLastSeenAt")) {
            timing$1.v1.Timestamp.encode(message.androidLastSeenAt, writer.uint32(266).fork()).ldelim();
          }
          if (message.iosAppName != null && Object.hasOwnProperty.call(message, "iosAppName")) {
            writer.uint32(274).string(message.iosAppName);
          }
          if (message.iosAppVersion != null && Object.hasOwnProperty.call(message, "iosAppVersion")) {
            writer.uint32(282).string(message.iosAppVersion);
          }
          if (message.iosDevice != null && Object.hasOwnProperty.call(message, "iosDevice")) {
            writer.uint32(290).string(message.iosDevice);
          }
          if (message.iosOsVersion != null && Object.hasOwnProperty.call(message, "iosOsVersion")) {
            writer.uint32(298).string(message.iosOsVersion);
          }
          if (message.iosSdkVersion != null && Object.hasOwnProperty.call(message, "iosSdkVersion")) {
            writer.uint32(306).string(message.iosSdkVersion);
          }
          if (message.iosLastSeenAt != null && Object.hasOwnProperty.call(message, "iosLastSeenAt")) {
            timing$1.v1.Timestamp.encode(message.iosLastSeenAt, writer.uint32(314).fork()).ldelim();
          }
          if (message.customAttributes != null && Object.hasOwnProperty.call(message, "customAttributes")) {
            google$1.protobuf.Struct.encode(message.customAttributes, writer.uint32(322).fork()).ldelim();
          }
          if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar")) {
            writer.uint32(330).string(message.avatar);
          }
          if (message.tags != null && Object.hasOwnProperty.call(message, "tags")) {
            $root.intercom.v1.Tags.encode(message.tags, writer.uint32(338).fork()).ldelim();
          }
          if (message.notes != null && Object.hasOwnProperty.call(message, "notes")) {
            $root.intercom.v1.Notes.encode(message.notes, writer.uint32(346).fork()).ldelim();
          }
          if (message.companies != null && Object.hasOwnProperty.call(message, "companies")) {
            $root.intercom.v1.Companies.encode(message.companies, writer.uint32(354).fork()).ldelim();
          }
          if (message.location != null && Object.hasOwnProperty.call(message, "location")) {
            $root.intercom.v1.Location.encode(message.location, writer.uint32(362).fork()).ldelim();
          }
          if (message.socialProfiles != null && Object.hasOwnProperty.call(message, "socialProfiles")) {
            $root.intercom.v1.SocialProfiles.encode(message.socialProfiles, writer.uint32(370).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Contact();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                message.externalId = reader.string();
                break;
              }
              case 4: {
                message.workspaceId = reader.string();
                break;
              }
              case 5: {
                message.role = reader.string();
                break;
              }
              case 6: {
                message.email = reader.string();
                break;
              }
              case 7: {
                message.emailDomain = reader.string();
                break;
              }
              case 8: {
                message.phone = reader.string();
                break;
              }
              case 9: {
                message.formattedPhone = reader.string();
                break;
              }
              case 10: {
                message.name = reader.string();
                break;
              }
              case 11: {
                message.ownerId = reader.int32();
                break;
              }
              case 12: {
                message.hasHardBounced = reader.bool();
                break;
              }
              case 13: {
                message.markedEmailAsSpam = reader.bool();
                break;
              }
              case 14: {
                message.unsubscribedFromEmails = reader.bool();
                break;
              }
              case 15: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.signedUpAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.lastSeenAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 19: {
                message.lastRepliedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 20: {
                message.lastContactedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 21: {
                message.lastEmailOpenedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 22: {
                message.lastEmailClickedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 23: {
                message.languageOverride = reader.string();
                break;
              }
              case 24: {
                message.browser = reader.string();
                break;
              }
              case 25: {
                message.browserVersion = reader.string();
                break;
              }
              case 26: {
                message.browserLanguage = reader.string();
                break;
              }
              case 27: {
                message.os = reader.string();
                break;
              }
              case 28: {
                message.androidAppName = reader.string();
                break;
              }
              case 29: {
                message.androidAppVersion = reader.string();
                break;
              }
              case 30: {
                message.androidDevice = reader.string();
                break;
              }
              case 31: {
                message.androidOsVersion = reader.string();
                break;
              }
              case 32: {
                message.androidSdkVersion = reader.string();
                break;
              }
              case 33: {
                message.androidLastSeenAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 34: {
                message.iosAppName = reader.string();
                break;
              }
              case 35: {
                message.iosAppVersion = reader.string();
                break;
              }
              case 36: {
                message.iosDevice = reader.string();
                break;
              }
              case 37: {
                message.iosOsVersion = reader.string();
                break;
              }
              case 38: {
                message.iosSdkVersion = reader.string();
                break;
              }
              case 39: {
                message.iosLastSeenAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 40: {
                message.customAttributes = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 41: {
                message.avatar = reader.string();
                break;
              }
              case 42: {
                message.tags = $root.intercom.v1.Tags.decode(reader, reader.uint32());
                break;
              }
              case 43: {
                message.notes = $root.intercom.v1.Notes.decode(reader, reader.uint32());
                break;
              }
              case 44: {
                message.companies = $root.intercom.v1.Companies.decode(reader, reader.uint32());
                break;
              }
              case 45: {
                message.location = $root.intercom.v1.Location.decode(reader, reader.uint32());
                break;
              }
              case 46: {
                message.socialProfiles = $root.intercom.v1.SocialProfiles.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Contact) {
            return object;
          }
          const message = new $root.intercom.v1.Contact();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.externalId != null) {
            message.externalId = String(object.externalId);
          }
          if (object.workspaceId != null) {
            message.workspaceId = String(object.workspaceId);
          }
          if (object.role != null) {
            message.role = String(object.role);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.emailDomain != null) {
            message.emailDomain = String(object.emailDomain);
          }
          if (object.phone != null) {
            message.phone = String(object.phone);
          }
          if (object.formattedPhone != null) {
            message.formattedPhone = String(object.formattedPhone);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.ownerId != null) {
            message.ownerId = object.ownerId | 0;
          }
          if (object.hasHardBounced != null) {
            message.hasHardBounced = Boolean(object.hasHardBounced);
          }
          if (object.markedEmailAsSpam != null) {
            message.markedEmailAsSpam = Boolean(object.markedEmailAsSpam);
          }
          if (object.unsubscribedFromEmails != null) {
            message.unsubscribedFromEmails = Boolean(object.unsubscribedFromEmails);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          if (object.signedUpAt != null) {
            if (typeof object.signedUpAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.signedUpAt: object expected, but got " + (typeof object.signedUpAt));
            }
            message.signedUpAt = timing$1.v1.Timestamp.fromObject(object.signedUpAt);
          }
          if (object.lastSeenAt != null) {
            if (typeof object.lastSeenAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.lastSeenAt: object expected, but got " + (typeof object.lastSeenAt));
            }
            message.lastSeenAt = timing$1.v1.Timestamp.fromObject(object.lastSeenAt);
          }
          if (object.lastRepliedAt != null) {
            if (typeof object.lastRepliedAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.lastRepliedAt: object expected, but got " + (typeof object.lastRepliedAt));
            }
            message.lastRepliedAt = timing$1.v1.Timestamp.fromObject(object.lastRepliedAt);
          }
          if (object.lastContactedAt != null) {
            if (typeof object.lastContactedAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.lastContactedAt: object expected, but got " + (typeof object.lastContactedAt));
            }
            message.lastContactedAt = timing$1.v1.Timestamp.fromObject(object.lastContactedAt);
          }
          if (object.lastEmailOpenedAt != null) {
            if (typeof object.lastEmailOpenedAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.lastEmailOpenedAt: object expected, but got " + (typeof object.lastEmailOpenedAt));
            }
            message.lastEmailOpenedAt = timing$1.v1.Timestamp.fromObject(object.lastEmailOpenedAt);
          }
          if (object.lastEmailClickedAt != null) {
            if (typeof object.lastEmailClickedAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.lastEmailClickedAt: object expected, but got " + (typeof object.lastEmailClickedAt));
            }
            message.lastEmailClickedAt = timing$1.v1.Timestamp.fromObject(object.lastEmailClickedAt);
          }
          if (object.languageOverride != null) {
            message.languageOverride = String(object.languageOverride);
          }
          if (object.browser != null) {
            message.browser = String(object.browser);
          }
          if (object.browserVersion != null) {
            message.browserVersion = String(object.browserVersion);
          }
          if (object.browserLanguage != null) {
            message.browserLanguage = String(object.browserLanguage);
          }
          if (object.os != null) {
            message.os = String(object.os);
          }
          if (object.androidAppName != null) {
            message.androidAppName = String(object.androidAppName);
          }
          if (object.androidAppVersion != null) {
            message.androidAppVersion = String(object.androidAppVersion);
          }
          if (object.androidDevice != null) {
            message.androidDevice = String(object.androidDevice);
          }
          if (object.androidOsVersion != null) {
            message.androidOsVersion = String(object.androidOsVersion);
          }
          if (object.androidSdkVersion != null) {
            message.androidSdkVersion = String(object.androidSdkVersion);
          }
          if (object.androidLastSeenAt != null) {
            if (typeof object.androidLastSeenAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.androidLastSeenAt: object expected, but got " + (typeof object.androidLastSeenAt));
            }
            message.androidLastSeenAt = timing$1.v1.Timestamp.fromObject(object.androidLastSeenAt);
          }
          if (object.iosAppName != null) {
            message.iosAppName = String(object.iosAppName);
          }
          if (object.iosAppVersion != null) {
            message.iosAppVersion = String(object.iosAppVersion);
          }
          if (object.iosDevice != null) {
            message.iosDevice = String(object.iosDevice);
          }
          if (object.iosOsVersion != null) {
            message.iosOsVersion = String(object.iosOsVersion);
          }
          if (object.iosSdkVersion != null) {
            message.iosSdkVersion = String(object.iosSdkVersion);
          }
          if (object.iosLastSeenAt != null) {
            if (typeof object.iosLastSeenAt !== "object") {
              throw new TypeError(".intercom.v1.Contact.iosLastSeenAt: object expected, but got " + (typeof object.iosLastSeenAt));
            }
            message.iosLastSeenAt = timing$1.v1.Timestamp.fromObject(object.iosLastSeenAt);
          }
          if (object.customAttributes != null) {
            if (typeof object.customAttributes !== "object") {
              throw new TypeError(".intercom.v1.Contact.customAttributes: object expected, but got " + (typeof object.customAttributes));
            }
            message.customAttributes = google$1.protobuf.Struct.fromObject(object.customAttributes);
          }
          if (object.avatar != null) {
            message.avatar = String(object.avatar);
          }
          if (object.tags != null) {
            if (typeof object.tags !== "object") {
              throw new TypeError(".intercom.v1.Contact.tags: object expected, but got " + (typeof object.tags));
            }
            message.tags = $root.intercom.v1.Tags.fromObject(object.tags);
          }
          if (object.notes != null) {
            if (typeof object.notes !== "object") {
              throw new TypeError(".intercom.v1.Contact.notes: object expected, but got " + (typeof object.notes));
            }
            message.notes = $root.intercom.v1.Notes.fromObject(object.notes);
          }
          if (object.companies != null) {
            if (typeof object.companies !== "object") {
              throw new TypeError(".intercom.v1.Contact.companies: object expected, but got " + (typeof object.companies));
            }
            message.companies = $root.intercom.v1.Companies.fromObject(object.companies);
          }
          if (object.location != null) {
            if (typeof object.location !== "object") {
              throw new TypeError(".intercom.v1.Contact.location: object expected, but got " + (typeof object.location));
            }
            message.location = $root.intercom.v1.Location.fromObject(object.location);
          }
          if (object.socialProfiles != null) {
            if (typeof object.socialProfiles !== "object") {
              throw new TypeError(".intercom.v1.Contact.socialProfiles: object expected, but got " + (typeof object.socialProfiles));
            }
            message.socialProfiles = $root.intercom.v1.SocialProfiles.fromObject(object.socialProfiles);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
            object.id = "";
            object.workspaceId = "";
            object.role = "";
            object.email = "";
            object.emailDomain = "";
            object.hasHardBounced = false;
            object.markedEmailAsSpam = false;
            object.unsubscribedFromEmails = false;
            object.createdAt = null;
            object.updatedAt = null;
            object.customAttributes = null;
            object.companies = null;
            object.location = null;
            object.socialProfiles = null;
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.externalId != null && message.hasOwnProperty("externalId")) {
            object.externalId = message.externalId;
          }
          if (message.workspaceId != null && message.hasOwnProperty("workspaceId")) {
            object.workspaceId = message.workspaceId;
          }
          if (message.role != null && message.hasOwnProperty("role")) {
            object.role = message.role;
          }
          if (message.email != null && message.hasOwnProperty("email")) {
            object.email = message.email;
          }
          if (message.emailDomain != null && message.hasOwnProperty("emailDomain")) {
            object.emailDomain = message.emailDomain;
          }
          if (message.phone != null && message.hasOwnProperty("phone")) {
            object.phone = message.phone;
          }
          if (message.formattedPhone != null && message.hasOwnProperty("formattedPhone")) {
            object.formattedPhone = message.formattedPhone;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.ownerId != null && message.hasOwnProperty("ownerId")) {
            object.ownerId = message.ownerId;
          }
          if (message.hasHardBounced != null && message.hasOwnProperty("hasHardBounced")) {
            object.hasHardBounced = message.hasHardBounced;
          }
          if (message.markedEmailAsSpam != null && message.hasOwnProperty("markedEmailAsSpam")) {
            object.markedEmailAsSpam = message.markedEmailAsSpam;
          }
          if (message.unsubscribedFromEmails != null && message.hasOwnProperty("unsubscribedFromEmails")) {
            object.unsubscribedFromEmails = message.unsubscribedFromEmails;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          if (message.signedUpAt != null && message.hasOwnProperty("signedUpAt")) {
            object.signedUpAt = timing$1.v1.Timestamp.toObject(message.signedUpAt, options);
          }
          if (message.lastSeenAt != null && message.hasOwnProperty("lastSeenAt")) {
            object.lastSeenAt = timing$1.v1.Timestamp.toObject(message.lastSeenAt, options);
          }
          if (message.lastRepliedAt != null && message.hasOwnProperty("lastRepliedAt")) {
            object.lastRepliedAt = timing$1.v1.Timestamp.toObject(message.lastRepliedAt, options);
          }
          if (message.lastContactedAt != null && message.hasOwnProperty("lastContactedAt")) {
            object.lastContactedAt = timing$1.v1.Timestamp.toObject(message.lastContactedAt, options);
          }
          if (message.lastEmailOpenedAt != null && message.hasOwnProperty("lastEmailOpenedAt")) {
            object.lastEmailOpenedAt = timing$1.v1.Timestamp.toObject(message.lastEmailOpenedAt, options);
          }
          if (message.lastEmailClickedAt != null && message.hasOwnProperty("lastEmailClickedAt")) {
            object.lastEmailClickedAt = timing$1.v1.Timestamp.toObject(message.lastEmailClickedAt, options);
          }
          if (message.languageOverride != null && message.hasOwnProperty("languageOverride")) {
            object.languageOverride = message.languageOverride;
          }
          if (message.browser != null && message.hasOwnProperty("browser")) {
            object.browser = message.browser;
          }
          if (message.browserVersion != null && message.hasOwnProperty("browserVersion")) {
            object.browserVersion = message.browserVersion;
          }
          if (message.browserLanguage != null && message.hasOwnProperty("browserLanguage")) {
            object.browserLanguage = message.browserLanguage;
          }
          if (message.os != null && message.hasOwnProperty("os")) {
            object.os = message.os;
          }
          if (message.androidAppName != null && message.hasOwnProperty("androidAppName")) {
            object.androidAppName = message.androidAppName;
          }
          if (message.androidAppVersion != null && message.hasOwnProperty("androidAppVersion")) {
            object.androidAppVersion = message.androidAppVersion;
          }
          if (message.androidDevice != null && message.hasOwnProperty("androidDevice")) {
            object.androidDevice = message.androidDevice;
          }
          if (message.androidOsVersion != null && message.hasOwnProperty("androidOsVersion")) {
            object.androidOsVersion = message.androidOsVersion;
          }
          if (message.androidSdkVersion != null && message.hasOwnProperty("androidSdkVersion")) {
            object.androidSdkVersion = message.androidSdkVersion;
          }
          if (message.androidLastSeenAt != null && message.hasOwnProperty("androidLastSeenAt")) {
            object.androidLastSeenAt = timing$1.v1.Timestamp.toObject(message.androidLastSeenAt, options);
          }
          if (message.iosAppName != null && message.hasOwnProperty("iosAppName")) {
            object.iosAppName = message.iosAppName;
          }
          if (message.iosAppVersion != null && message.hasOwnProperty("iosAppVersion")) {
            object.iosAppVersion = message.iosAppVersion;
          }
          if (message.iosDevice != null && message.hasOwnProperty("iosDevice")) {
            object.iosDevice = message.iosDevice;
          }
          if (message.iosOsVersion != null && message.hasOwnProperty("iosOsVersion")) {
            object.iosOsVersion = message.iosOsVersion;
          }
          if (message.iosSdkVersion != null && message.hasOwnProperty("iosSdkVersion")) {
            object.iosSdkVersion = message.iosSdkVersion;
          }
          if (message.iosLastSeenAt != null && message.hasOwnProperty("iosLastSeenAt")) {
            object.iosLastSeenAt = timing$1.v1.Timestamp.toObject(message.iosLastSeenAt, options);
          }
          if (message.customAttributes != null && message.hasOwnProperty("customAttributes")) {
            object.customAttributes = google$1.protobuf.Struct.toObject(message.customAttributes, options);
          }
          if (message.avatar != null && message.hasOwnProperty("avatar")) {
            object.avatar = message.avatar;
          }
          if (message.tags != null && message.hasOwnProperty("tags")) {
            object.tags = $root.intercom.v1.Tags.toObject(message.tags, options);
          }
          if (message.notes != null && message.hasOwnProperty("notes")) {
            object.notes = $root.intercom.v1.Notes.toObject(message.notes, options);
          }
          if (message.companies != null && message.hasOwnProperty("companies")) {
            object.companies = $root.intercom.v1.Companies.toObject(message.companies, options);
          }
          if (message.location != null && message.hasOwnProperty("location")) {
            object.location = $root.intercom.v1.Location.toObject(message.location, options);
          }
          if (message.socialProfiles != null && message.hasOwnProperty("socialProfiles")) {
            object.socialProfiles = $root.intercom.v1.SocialProfiles.toObject(message.socialProfiles, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Contact";
        }
      }

      Contact.prototype.type = "";
      Contact.prototype.id = "";
      Contact.prototype.externalId = null;
      Contact.prototype.workspaceId = "";
      Contact.prototype.role = "";
      Contact.prototype.email = "";
      Contact.prototype.emailDomain = "";
      Contact.prototype.phone = null;
      Contact.prototype.formattedPhone = null;
      Contact.prototype.name = null;
      Contact.prototype.ownerId = null;
      Contact.prototype.hasHardBounced = false;
      Contact.prototype.markedEmailAsSpam = false;
      Contact.prototype.unsubscribedFromEmails = false;
      Contact.prototype.createdAt = null;
      Contact.prototype.updatedAt = null;
      Contact.prototype.signedUpAt = null;
      Contact.prototype.lastSeenAt = null;
      Contact.prototype.lastRepliedAt = null;
      Contact.prototype.lastContactedAt = null;
      Contact.prototype.lastEmailOpenedAt = null;
      Contact.prototype.lastEmailClickedAt = null;
      Contact.prototype.languageOverride = null;
      Contact.prototype.browser = null;
      Contact.prototype.browserVersion = null;
      Contact.prototype.browserLanguage = null;
      Contact.prototype.os = null;
      Contact.prototype.androidAppName = null;
      Contact.prototype.androidAppVersion = null;
      Contact.prototype.androidDevice = null;
      Contact.prototype.androidOsVersion = null;
      Contact.prototype.androidSdkVersion = null;
      Contact.prototype.androidLastSeenAt = null;
      Contact.prototype.iosAppName = null;
      Contact.prototype.iosAppVersion = null;
      Contact.prototype.iosDevice = null;
      Contact.prototype.iosOsVersion = null;
      Contact.prototype.iosSdkVersion = null;
      Contact.prototype.iosLastSeenAt = null;
      Contact.prototype.customAttributes = null;
      Contact.prototype.avatar = null;
      Contact.prototype.tags = null;
      Contact.prototype.notes = null;
      Contact.prototype.companies = null;
      Contact.prototype.location = null;
      Contact.prototype.socialProfiles = null;

      return Contact;
    })();

    v1.Tag = (() => {
      class Tag {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Tag(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(18).string(message.id);
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(26).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Tag();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Tag) {
            return object;
          }
          const message = new $root.intercom.v1.Tag();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Tag";
        }
      }

      Tag.prototype.type = "";
      Tag.prototype.id = null;
      Tag.prototype.url = null;

      return Tag;
    })();

    v1.Tags = (() => {
      class Tags {
        constructor(properties) {
          this.data = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Tags(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.data != null && Object.hasOwnProperty.call(message, "data")) {
            for (const element of message.data) {
              $root.intercom.v1.Tag.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount")) {
            writer.uint32(24).int64(message.totalCount);
          }
          if (message.hasMore != null && Object.hasOwnProperty.call(message, "hasMore")) {
            writer.uint32(32).bool(message.hasMore);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Tags();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.data || !message.data.length) {
                  message.data = [];
                }
                message.data.push($root.intercom.v1.Tag.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              case 3: {
                message.totalCount = reader.int64();
                break;
              }
              case 4: {
                message.hasMore = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Tags) {
            return object;
          }
          const message = new $root.intercom.v1.Tags();
          if (object.data) {
            if (!Array.isArray(object.data)) {
              throw new TypeError(".intercom.v1.Tags.data: array type expected, but got " + (typeof object.data))
            }
            message.data = new Array(object.data.length);
            for (let i = 0; i < object.data.length; ++i) {
              if (typeof object.data[i] !== "object") {
                throw new TypeError(".intercom.v1.Tags.data: object expected, but got " + (typeof object.data[i]));
              }
              message.data[i] = $root.intercom.v1.Tag.fromObject(object.data[i]);
            }
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.totalCount != null) {
            if ($util.Long) {
              (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            } else if (typeof object.totalCount === "string") {
              message.totalCount = parseInt(object.totalCount, 10);
            } else if (typeof object.totalCount === "number") {
              message.totalCount = object.totalCount;
            } else if (typeof object.totalCount === "object") {
              message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
            }
          }
          if (object.hasMore != null) {
            message.hasMore = Boolean(object.hasMore);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.data = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.totalCount = options.longs === String ? "0" : 0;
            }
            object.hasMore = false;
          }
          let keys;
          if (message.data && message.data.length) {
            object.data = new Array(message.data.length);
            for (let i = 0; i < message.data.length; ++i) {
              object.data[i] = $root.intercom.v1.Tag.toObject(message.data[i], options);
            }
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.totalCount != null && message.hasOwnProperty("totalCount")) {
            object.totalCount = typeof message.totalCount === "number" ? (options.longs === String ? String(message.totalCount) : message.totalCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount);
          }
          if (message.hasMore != null && message.hasOwnProperty("hasMore")) {
            object.hasMore = message.hasMore;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Tags";
        }
      }

      Tags.prototype.data = $util.emptyArray;
      Tags.prototype.url = null;
      Tags.prototype.totalCount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Tags.prototype.hasMore = false;

      return Tags;
    })();

    v1.Note = (() => {
      class Note {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Note(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(18).string(message.id);
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(26).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Note();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Note) {
            return object;
          }
          const message = new $root.intercom.v1.Note();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Note";
        }
      }

      Note.prototype.type = "";
      Note.prototype.id = null;
      Note.prototype.url = null;

      return Note;
    })();

    v1.Notes = (() => {
      class Notes {
        constructor(properties) {
          this.data = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Notes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.data != null && Object.hasOwnProperty.call(message, "data")) {
            for (const element of message.data) {
              $root.intercom.v1.Note.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount")) {
            writer.uint32(24).int64(message.totalCount);
          }
          if (message.hasMore != null && Object.hasOwnProperty.call(message, "hasMore")) {
            writer.uint32(32).bool(message.hasMore);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Notes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.data || !message.data.length) {
                  message.data = [];
                }
                message.data.push($root.intercom.v1.Note.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              case 3: {
                message.totalCount = reader.int64();
                break;
              }
              case 4: {
                message.hasMore = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Notes) {
            return object;
          }
          const message = new $root.intercom.v1.Notes();
          if (object.data) {
            if (!Array.isArray(object.data)) {
              throw new TypeError(".intercom.v1.Notes.data: array type expected, but got " + (typeof object.data))
            }
            message.data = new Array(object.data.length);
            for (let i = 0; i < object.data.length; ++i) {
              if (typeof object.data[i] !== "object") {
                throw new TypeError(".intercom.v1.Notes.data: object expected, but got " + (typeof object.data[i]));
              }
              message.data[i] = $root.intercom.v1.Note.fromObject(object.data[i]);
            }
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.totalCount != null) {
            if ($util.Long) {
              (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            } else if (typeof object.totalCount === "string") {
              message.totalCount = parseInt(object.totalCount, 10);
            } else if (typeof object.totalCount === "number") {
              message.totalCount = object.totalCount;
            } else if (typeof object.totalCount === "object") {
              message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
            }
          }
          if (object.hasMore != null) {
            message.hasMore = Boolean(object.hasMore);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.data = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.totalCount = options.longs === String ? "0" : 0;
            }
            object.hasMore = false;
          }
          let keys;
          if (message.data && message.data.length) {
            object.data = new Array(message.data.length);
            for (let i = 0; i < message.data.length; ++i) {
              object.data[i] = $root.intercom.v1.Note.toObject(message.data[i], options);
            }
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.totalCount != null && message.hasOwnProperty("totalCount")) {
            object.totalCount = typeof message.totalCount === "number" ? (options.longs === String ? String(message.totalCount) : message.totalCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount);
          }
          if (message.hasMore != null && message.hasOwnProperty("hasMore")) {
            object.hasMore = message.hasMore;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Notes";
        }
      }

      Notes.prototype.data = $util.emptyArray;
      Notes.prototype.url = null;
      Notes.prototype.totalCount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Notes.prototype.hasMore = false;

      return Notes;
    })();

    v1.Company = (() => {
      class Company {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Company(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(18).string(message.id);
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(26).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Company();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              case 3: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Company) {
            return object;
          }
          const message = new $root.intercom.v1.Company();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Company";
        }
      }

      Company.prototype.type = "";
      Company.prototype.id = null;
      Company.prototype.url = null;

      return Company;
    })();

    v1.Companies = (() => {
      class Companies {
        constructor(properties) {
          this.data = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Companies(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.data != null && Object.hasOwnProperty.call(message, "data")) {
            for (const element of message.data) {
              $root.intercom.v1.Company.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount")) {
            writer.uint32(24).int64(message.totalCount);
          }
          if (message.hasMore != null && Object.hasOwnProperty.call(message, "hasMore")) {
            writer.uint32(32).bool(message.hasMore);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Companies();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.data || !message.data.length) {
                  message.data = [];
                }
                message.data.push($root.intercom.v1.Company.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              case 3: {
                message.totalCount = reader.int64();
                break;
              }
              case 4: {
                message.hasMore = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Companies) {
            return object;
          }
          const message = new $root.intercom.v1.Companies();
          if (object.data) {
            if (!Array.isArray(object.data)) {
              throw new TypeError(".intercom.v1.Companies.data: array type expected, but got " + (typeof object.data))
            }
            message.data = new Array(object.data.length);
            for (let i = 0; i < object.data.length; ++i) {
              if (typeof object.data[i] !== "object") {
                throw new TypeError(".intercom.v1.Companies.data: object expected, but got " + (typeof object.data[i]));
              }
              message.data[i] = $root.intercom.v1.Company.fromObject(object.data[i]);
            }
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.totalCount != null) {
            if ($util.Long) {
              (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            } else if (typeof object.totalCount === "string") {
              message.totalCount = parseInt(object.totalCount, 10);
            } else if (typeof object.totalCount === "number") {
              message.totalCount = object.totalCount;
            } else if (typeof object.totalCount === "object") {
              message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
            }
          }
          if (object.hasMore != null) {
            message.hasMore = Boolean(object.hasMore);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.data = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.totalCount = options.longs === String ? "0" : 0;
            }
            object.hasMore = false;
          }
          let keys;
          if (message.data && message.data.length) {
            object.data = new Array(message.data.length);
            for (let i = 0; i < message.data.length; ++i) {
              object.data[i] = $root.intercom.v1.Company.toObject(message.data[i], options);
            }
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.totalCount != null && message.hasOwnProperty("totalCount")) {
            object.totalCount = typeof message.totalCount === "number" ? (options.longs === String ? String(message.totalCount) : message.totalCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount);
          }
          if (message.hasMore != null && message.hasOwnProperty("hasMore")) {
            object.hasMore = message.hasMore;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Companies";
        }
      }

      Companies.prototype.data = $util.emptyArray;
      Companies.prototype.url = null;
      Companies.prototype.totalCount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Companies.prototype.hasMore = false;

      return Companies;
    })();

    v1.Location = (() => {
      class Location {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Location(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.country != null && Object.hasOwnProperty.call(message, "country")) {
            writer.uint32(18).string(message.country);
          }
          if (message.region != null && Object.hasOwnProperty.call(message, "region")) {
            writer.uint32(26).string(message.region);
          }
          if (message.city != null && Object.hasOwnProperty.call(message, "city")) {
            writer.uint32(34).string(message.city);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Location();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.country = reader.string();
                break;
              }
              case 3: {
                message.region = reader.string();
                break;
              }
              case 4: {
                message.city = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Location) {
            return object;
          }
          const message = new $root.intercom.v1.Location();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.country != null) {
            message.country = String(object.country);
          }
          if (object.region != null) {
            message.region = String(object.region);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.country != null && message.hasOwnProperty("country")) {
            object.country = message.country;
          }
          if (message.region != null && message.hasOwnProperty("region")) {
            object.region = message.region;
          }
          if (message.city != null && message.hasOwnProperty("city")) {
            object.city = message.city;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Location";
        }
      }

      Location.prototype.type = "";
      Location.prototype.country = null;
      Location.prototype.region = null;
      Location.prototype.city = null;

      return Location;
    })();

    v1.SocialProfile = (() => {
      class SocialProfile {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SocialProfile(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(26).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SocialProfile();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.SocialProfile) {
            return object;
          }
          const message = new $root.intercom.v1.SocialProfile();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.SocialProfile";
        }
      }

      SocialProfile.prototype.type = "";
      SocialProfile.prototype.name = null;
      SocialProfile.prototype.url = null;

      return SocialProfile;
    })();

    v1.SocialProfiles = (() => {
      class SocialProfiles {
        constructor(properties) {
          this.data = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SocialProfiles(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.data != null && Object.hasOwnProperty.call(message, "data")) {
            for (const element of message.data) {
              $root.intercom.v1.SocialProfile.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SocialProfiles();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.data || !message.data.length) {
                  message.data = [];
                }
                message.data.push($root.intercom.v1.SocialProfile.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.SocialProfiles) {
            return object;
          }
          const message = new $root.intercom.v1.SocialProfiles();
          if (object.data) {
            if (!Array.isArray(object.data)) {
              throw new TypeError(".intercom.v1.SocialProfiles.data: array type expected, but got " + (typeof object.data))
            }
            message.data = new Array(object.data.length);
            for (let i = 0; i < object.data.length; ++i) {
              if (typeof object.data[i] !== "object") {
                throw new TypeError(".intercom.v1.SocialProfiles.data: object expected, but got " + (typeof object.data[i]));
              }
              message.data[i] = $root.intercom.v1.SocialProfile.fromObject(object.data[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.data = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.data && message.data.length) {
            object.data = new Array(message.data.length);
            for (let i = 0; i < message.data.length; ++i) {
              object.data[i] = $root.intercom.v1.SocialProfile.toObject(message.data[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.SocialProfiles";
        }
      }

      SocialProfiles.prototype.data = $util.emptyArray;

      return SocialProfiles;
    })();

    v1.Pagination = (() => {
      class Pagination {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Pagination(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.page != null && Object.hasOwnProperty.call(message, "page")) {
            writer.uint32(8).int32(message.page);
          }
          if (message.perPage != null && Object.hasOwnProperty.call(message, "perPage")) {
            writer.uint32(16).int32(message.perPage);
          }
          if (message.totalPages != null && Object.hasOwnProperty.call(message, "totalPages")) {
            writer.uint32(24).int32(message.totalPages);
          }
          if (message.next != null && Object.hasOwnProperty.call(message, "next")) {
            $root.intercom.v1.Pagination.Next.encode(message.next, writer.uint32(34).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Pagination();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = reader.int32();
                break;
              }
              case 2: {
                message.perPage = reader.int32();
                break;
              }
              case 3: {
                message.totalPages = reader.int32();
                break;
              }
              case 4: {
                message.next = $root.intercom.v1.Pagination.Next.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.Pagination) {
            return object;
          }
          const message = new $root.intercom.v1.Pagination();
          if (object.page != null) {
            message.page = object.page | 0;
          }
          if (object.perPage != null) {
            message.perPage = object.perPage | 0;
          }
          if (object.totalPages != null) {
            message.totalPages = object.totalPages | 0;
          }
          if (object.next != null) {
            if (typeof object.next !== "object") {
              throw new TypeError(".intercom.v1.Pagination.next: object expected, but got " + (typeof object.next));
            }
            message.next = $root.intercom.v1.Pagination.Next.fromObject(object.next);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = 0;
            object.perPage = 0;
            object.totalPages = 0;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty("page")) {
            object.page = message.page;
          }
          if (message.perPage != null && message.hasOwnProperty("perPage")) {
            object.perPage = message.perPage;
          }
          if (message.totalPages != null && message.hasOwnProperty("totalPages")) {
            object.totalPages = message.totalPages;
          }
          if (message.next != null && message.hasOwnProperty("next")) {
            object.next = $root.intercom.v1.Pagination.Next.toObject(message.next, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.Pagination";
        }
      }

      Pagination.prototype.page = 0;
      Pagination.prototype.perPage = 0;
      Pagination.prototype.totalPages = 0;
      Pagination.prototype.next = null;
      Pagination.Next = (() => {
        class Next {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Next(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.perPage != null && Object.hasOwnProperty.call(message, "perPage")) {
              writer.uint32(8).int32(message.perPage);
            }
            if (message.startingAfter != null && Object.hasOwnProperty.call(message, "startingAfter")) {
              writer.uint32(18).string(message.startingAfter);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Next();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.perPage = reader.int32();
                  break;
                }
                case 2: {
                  message.startingAfter = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.intercom.v1.Pagination.Next) {
              return object;
            }
            const message = new $root.intercom.v1.Pagination.Next();
            if (object.perPage != null) {
              message.perPage = object.perPage | 0;
            }
            if (object.startingAfter != null) {
              message.startingAfter = String(object.startingAfter);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.perPage = 0;
            }
            let keys;
            if (message.perPage != null && message.hasOwnProperty("perPage")) {
              object.perPage = message.perPage;
            }
            if (message.startingAfter != null && message.hasOwnProperty("startingAfter")) {
              object.startingAfter = message.startingAfter;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "intercom.v1.Pagination.Next";
          }
        }

        Next.prototype.perPage = 0;
        Next.prototype.startingAfter = null;

        return Next;
      })();

      return Pagination;
    })();

    v1.PaginatedContacts = (() => {
      class PaginatedContacts {
        constructor(properties) {
          this.data = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PaginatedContacts(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.data != null && Object.hasOwnProperty.call(message, "data")) {
            for (const element of message.data) {
              $root.intercom.v1.Contact.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount")) {
            writer.uint32(16).int64(message.totalCount);
          }
          if (message.pages != null && Object.hasOwnProperty.call(message, "pages")) {
            $root.intercom.v1.Pagination.encode(message.pages, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PaginatedContacts();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.data || !message.data.length) {
                  message.data = [];
                }
                message.data.push($root.intercom.v1.Contact.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.totalCount = reader.int64();
                break;
              }
              case 3: {
                message.pages = $root.intercom.v1.Pagination.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.PaginatedContacts) {
            return object;
          }
          const message = new $root.intercom.v1.PaginatedContacts();
          if (object.data) {
            if (!Array.isArray(object.data)) {
              throw new TypeError(".intercom.v1.PaginatedContacts.data: array type expected, but got " + (typeof object.data))
            }
            message.data = new Array(object.data.length);
            for (let i = 0; i < object.data.length; ++i) {
              if (typeof object.data[i] !== "object") {
                throw new TypeError(".intercom.v1.PaginatedContacts.data: object expected, but got " + (typeof object.data[i]));
              }
              message.data[i] = $root.intercom.v1.Contact.fromObject(object.data[i]);
            }
          }
          if (object.totalCount != null) {
            if ($util.Long) {
              (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            } else if (typeof object.totalCount === "string") {
              message.totalCount = parseInt(object.totalCount, 10);
            } else if (typeof object.totalCount === "number") {
              message.totalCount = object.totalCount;
            } else if (typeof object.totalCount === "object") {
              message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
            }
          }
          if (object.pages != null) {
            if (typeof object.pages !== "object") {
              throw new TypeError(".intercom.v1.PaginatedContacts.pages: object expected, but got " + (typeof object.pages));
            }
            message.pages = $root.intercom.v1.Pagination.fromObject(object.pages);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.data = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.totalCount = options.longs === String ? "0" : 0;
            }
            object.pages = null;
          }
          let keys;
          if (message.data && message.data.length) {
            object.data = new Array(message.data.length);
            for (let i = 0; i < message.data.length; ++i) {
              object.data[i] = $root.intercom.v1.Contact.toObject(message.data[i], options);
            }
          }
          if (message.totalCount != null && message.hasOwnProperty("totalCount")) {
            object.totalCount = typeof message.totalCount === "number" ? (options.longs === String ? String(message.totalCount) : message.totalCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount);
          }
          if (message.pages != null && message.hasOwnProperty("pages")) {
            object.pages = $root.intercom.v1.Pagination.toObject(message.pages, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.PaginatedContacts";
        }
      }

      PaginatedContacts.prototype.data = $util.emptyArray;
      PaginatedContacts.prototype.totalCount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      PaginatedContacts.prototype.pages = null;

      return PaginatedContacts;
    })();

    v1.DataAttribute = (() => {
      class DataAttribute {
        constructor(properties) {
          this.options = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DataAttribute(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(10).string(message.type);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(16).int64(message.id);
          }
          if (message.model != null && Object.hasOwnProperty.call(message, "model")) {
            writer.uint32(26).string(message.model);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(34).string(message.name);
          }
          if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
            writer.uint32(42).string(message.fullName);
          }
          if (message.label != null && Object.hasOwnProperty.call(message, "label")) {
            writer.uint32(50).string(message.label);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(58).string(message.description);
          }
          if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType")) {
            writer.uint32(66).string(message.dataType);
          }
          if (message.options != null && Object.hasOwnProperty.call(message, "options")) {
            for (const element of message.options) {
              writer.uint32(74).string(element);
            }
          }
          if (message.apiWritable != null && Object.hasOwnProperty.call(message, "apiWritable")) {
            writer.uint32(80).bool(message.apiWritable);
          }
          if (message.messengerWritable != null && Object.hasOwnProperty.call(message, "messengerWritable")) {
            writer.uint32(88).bool(message.messengerWritable);
          }
          if (message.uiWritable != null && Object.hasOwnProperty.call(message, "uiWritable")) {
            writer.uint32(96).bool(message.uiWritable);
          }
          if (message.custom != null && Object.hasOwnProperty.call(message, "custom")) {
            writer.uint32(104).bool(message.custom);
          }
          if (message.archived != null && Object.hasOwnProperty.call(message, "archived")) {
            writer.uint32(112).bool(message.archived);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(122).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(130).fork()).ldelim();
          }
          if (message.adminId != null && Object.hasOwnProperty.call(message, "adminId")) {
            writer.uint32(138).string(message.adminId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DataAttribute();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.id = reader.int64();
                break;
              }
              case 3: {
                message.model = reader.string();
                break;
              }
              case 4: {
                message.name = reader.string();
                break;
              }
              case 5: {
                message.fullName = reader.string();
                break;
              }
              case 6: {
                message.label = reader.string();
                break;
              }
              case 7: {
                message.description = reader.string();
                break;
              }
              case 8: {
                message.dataType = reader.string();
                break;
              }
              case 9: {
                if (!message.options || !message.options.length) {
                  message.options = [];
                }
                message.options.push(reader.string());
                break;
              }
              case 10: {
                message.apiWritable = reader.bool();
                break;
              }
              case 11: {
                message.messengerWritable = reader.bool();
                break;
              }
              case 12: {
                message.uiWritable = reader.bool();
                break;
              }
              case 13: {
                message.custom = reader.bool();
                break;
              }
              case 14: {
                message.archived = reader.bool();
                break;
              }
              case 15: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.adminId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.intercom.v1.DataAttribute) {
            return object;
          }
          const message = new $root.intercom.v1.DataAttribute();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.id != null) {
            if ($util.Long) {
              (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            } else if (typeof object.id === "string") {
              message.id = parseInt(object.id, 10);
            } else if (typeof object.id === "number") {
              message.id = object.id;
            } else if (typeof object.id === "object") {
              message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            }
          }
          if (object.model != null) {
            message.model = String(object.model);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.fullName != null) {
            message.fullName = String(object.fullName);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.dataType != null) {
            message.dataType = String(object.dataType);
          }
          if (object.options) {
            if (!Array.isArray(object.options)) {
              throw new TypeError(".intercom.v1.DataAttribute.options: array type expected, but got " + (typeof object.options))
            }
            message.options = new Array(object.options.length);
            for (let i = 0; i < object.options.length; ++i) {
              message.options[i] = String(object.options[i]);
            }
          }
          if (object.apiWritable != null) {
            message.apiWritable = Boolean(object.apiWritable);
          }
          if (object.messengerWritable != null) {
            message.messengerWritable = Boolean(object.messengerWritable);
          }
          if (object.uiWritable != null) {
            message.uiWritable = Boolean(object.uiWritable);
          }
          if (object.custom != null) {
            message.custom = Boolean(object.custom);
          }
          if (object.archived != null) {
            message.archived = Boolean(object.archived);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".intercom.v1.DataAttribute.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".intercom.v1.DataAttribute.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          if (object.adminId != null) {
            message.adminId = String(object.adminId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.options = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.id = options.longs === String ? "0" : 0;
            }
            object.model = "";
            object.name = "";
            object.fullName = "";
            object.label = "";
            object.description = "";
            object.dataType = "";
            object.apiWritable = false;
            object.messengerWritable = false;
            object.uiWritable = false;
            object.custom = false;
            object.archived = false;
            object.createdAt = null;
            object.updatedAt = null;
            object.adminId = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = typeof message.id === "number" ? (options.longs === String ? String(message.id) : message.id) : (options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id);
          }
          if (message.model != null && message.hasOwnProperty("model")) {
            object.model = message.model;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.fullName != null && message.hasOwnProperty("fullName")) {
            object.fullName = message.fullName;
          }
          if (message.label != null && message.hasOwnProperty("label")) {
            object.label = message.label;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.dataType != null && message.hasOwnProperty("dataType")) {
            object.dataType = message.dataType;
          }
          if (message.options && message.options.length) {
            object.options = new Array(message.options.length);
            for (let i = 0; i < message.options.length; ++i) {
              object.options[i] = message.options[i];
            }
          }
          if (message.apiWritable != null && message.hasOwnProperty("apiWritable")) {
            object.apiWritable = message.apiWritable;
          }
          if (message.messengerWritable != null && message.hasOwnProperty("messengerWritable")) {
            object.messengerWritable = message.messengerWritable;
          }
          if (message.uiWritable != null && message.hasOwnProperty("uiWritable")) {
            object.uiWritable = message.uiWritable;
          }
          if (message.custom != null && message.hasOwnProperty("custom")) {
            object.custom = message.custom;
          }
          if (message.archived != null && message.hasOwnProperty("archived")) {
            object.archived = message.archived;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          if (message.adminId != null && message.hasOwnProperty("adminId")) {
            object.adminId = message.adminId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "intercom.v1.DataAttribute";
        }
      }

      DataAttribute.prototype.type = "";
      DataAttribute.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      DataAttribute.prototype.model = "";
      DataAttribute.prototype.name = "";
      DataAttribute.prototype.fullName = "";
      DataAttribute.prototype.label = "";
      DataAttribute.prototype.description = "";
      DataAttribute.prototype.dataType = "";
      DataAttribute.prototype.options = $util.emptyArray;
      DataAttribute.prototype.apiWritable = false;
      DataAttribute.prototype.messengerWritable = false;
      DataAttribute.prototype.uiWritable = false;
      DataAttribute.prototype.custom = false;
      DataAttribute.prototype.archived = false;
      DataAttribute.prototype.createdAt = null;
      DataAttribute.prototype.updatedAt = null;
      DataAttribute.prototype.adminId = "";

      return DataAttribute;
    })();

    return v1;
  })();

  return intercom;
})();

export const intercom = $root.intercom;
