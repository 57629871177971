"use strict";

import * as $protobuf from "protobufjs/minimal";
import { api_key as api_key$1 } from "./api_key_v1_ts_proto";
import { google as google$1 } from "./empty_ts_proto";
import { profile_properties as profile_properties$1 } from "./profile_properties_v1_ts_proto";
import { sensitivity as sensitivity$1 } from "./sensitivity_v1_ts_proto";
import { sources as sources$1 } from "./sources_v1_ts_proto";
import { google as google$2 } from "./struct_ts_proto";
import { thirdparties as thirdparties$1 } from "./thirdparties_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.organization = (() => {
  const organization = {};
  organization.v1 = (() => {
    const v1 = {};
    v1.ListAllowedRpcsRequest = (() => {
      class ListAllowedRpcsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAllowedRpcsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAllowedRpcsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListAllowedRpcsRequest) {
            return object;
          }
          const message = new $root.organization.v1.ListAllowedRpcsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".organization.v1.ListAllowedRpcsRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "organization.v1.ListAllowedRpcsRequest";
        }
      }

      ListAllowedRpcsRequest.prototype.stub = null;

      return ListAllowedRpcsRequest;
    })();

    v1.ListAllowedRpcsResponse = (() => {
      class ListAllowedRpcsResponse {
        constructor(properties) {
          this.allowedRpcs = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAllowedRpcsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.allowedRpcs != null && Object.hasOwnProperty.call(message, "allowedRpcs")) {
            for (const element of message.allowedRpcs) {
              writer.uint32(10).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAllowedRpcsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.allowedRpcs || !message.allowedRpcs.length) {
                  message.allowedRpcs = [];
                }
                message.allowedRpcs.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListAllowedRpcsResponse) {
            return object;
          }
          const message = new $root.organization.v1.ListAllowedRpcsResponse();
          if (object.allowedRpcs) {
            if (!Array.isArray(object.allowedRpcs)) {
              throw new TypeError(".organization.v1.ListAllowedRpcsResponse.allowedRpcs: array type expected, but got " + (typeof object.allowedRpcs))
            }
            message.allowedRpcs = new Array(object.allowedRpcs.length);
            for (let i = 0; i < object.allowedRpcs.length; ++i) {
              message.allowedRpcs[i] = String(object.allowedRpcs[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.allowedRpcs = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.allowedRpcs && message.allowedRpcs.length) {
            object.allowedRpcs = new Array(message.allowedRpcs.length);
            for (let i = 0; i < message.allowedRpcs.length; ++i) {
              object.allowedRpcs[i] = message.allowedRpcs[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "organization.v1.ListAllowedRpcsResponse";
        }
      }

      ListAllowedRpcsResponse.prototype.allowedRpcs = $util.emptyArray;

      return ListAllowedRpcsResponse;
    })();

    v1.CreateProfilePropertyRequest = (() => {
      class CreateProfilePropertyRequest {
        constructor(properties) {
          this.additionalCustomSensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateProfilePropertyRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(18).string(message.description);
          }
          if (message.propertyTypeStub != null && Object.hasOwnProperty.call(message, "propertyTypeStub")) {
            profile_properties$1.v1.PropertyType.encode(message.propertyTypeStub, writer.uint32(26).fork()).ldelim();
          }
          if (message.additionalCustomSensitivities != null && Object.hasOwnProperty.call(message, "additionalCustomSensitivities")) {
            writer.uint32(34).fork();
            for (const element of message.additionalCustomSensitivities) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata")) {
            google$2.protobuf.Struct.encode(message.metadata, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateProfilePropertyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.propertyTypeStub = profile_properties$1.v1.PropertyType.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                if (!message.additionalCustomSensitivities || !message.additionalCustomSensitivities.length) {
                  message.additionalCustomSensitivities = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.additionalCustomSensitivities.push(reader.int32());
                } else {
                  message.additionalCustomSensitivities.push(reader.int32());
                }
                break;
              }
              case 5: {
                message.metadata = google$2.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.CreateProfilePropertyRequest) {
            return object;
          }
          const message = new $root.organization.v1.CreateProfilePropertyRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.propertyTypeStub != null) {
            if (typeof object.propertyTypeStub !== "object") {
              throw new TypeError(".organization.v1.CreateProfilePropertyRequest.propertyTypeStub: object expected, but got " + (typeof object.propertyTypeStub));
            }
            message.propertyTypeStub = profile_properties$1.v1.PropertyType.fromObject(object.propertyTypeStub);
          }
          if (object.additionalCustomSensitivities) {
            if (!Array.isArray(object.additionalCustomSensitivities)) {
              throw new TypeError(".organization.v1.CreateProfilePropertyRequest.additionalCustomSensitivities: array type expected, but got " + (typeof object.additionalCustomSensitivities))
            }
            message.additionalCustomSensitivities = new Array(object.additionalCustomSensitivities.length);
            for (let i = 0; i < object.additionalCustomSensitivities.length; ++i) {
              switch (object.additionalCustomSensitivities[i]) {
                case "UNSPECIFIED":
                case 0: {
                  message.additionalCustomSensitivities[i] = 0;
                  break;
                }
                case "PII":
                case 1: {
                  message.additionalCustomSensitivities[i] = 1;
                  break;
                }
                case "HI":
                case 2: {
                  message.additionalCustomSensitivities[i] = 2;
                  break;
                }
                case "ID":
                case 3: {
                  message.additionalCustomSensitivities[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.additionalCustomSensitivities[i] == "number") {
                    message.additionalCustomSensitivities[i] = object.additionalCustomSensitivities[i];
                    break;
                  }
                  message.additionalCustomSensitivities[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== "object") {
              throw new TypeError(".organization.v1.CreateProfilePropertyRequest.metadata: object expected, but got " + (typeof object.metadata));
            }
            message.metadata = google$2.protobuf.Struct.fromObject(object.metadata);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.additionalCustomSensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
            object.propertyTypeStub = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.propertyTypeStub != null && message.hasOwnProperty("propertyTypeStub")) {
            object.propertyTypeStub = profile_properties$1.v1.PropertyType.toObject(message.propertyTypeStub, options);
          }
          if (message.additionalCustomSensitivities && message.additionalCustomSensitivities.length) {
            object.additionalCustomSensitivities = new Array(message.additionalCustomSensitivities.length);
            for (let i = 0; i < message.additionalCustomSensitivities.length; ++i) {
              object.additionalCustomSensitivities[i] = options.enums === String ? sensitivity$1.v1.Sensitivity.Value[message.additionalCustomSensitivities[i]] === undefined ? message.additionalCustomSensitivities[i] : sensitivity$1.v1.Sensitivity.Value[message.additionalCustomSensitivities[i]] : message.additionalCustomSensitivities[i];
            }
          }
          if (message.metadata != null && message.hasOwnProperty("metadata")) {
            object.metadata = google$2.protobuf.Struct.toObject(message.metadata, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "organization.v1.CreateProfilePropertyRequest";
        }
      }

      CreateProfilePropertyRequest.prototype.name = "";
      CreateProfilePropertyRequest.prototype.description = null;
      CreateProfilePropertyRequest.prototype.propertyTypeStub = null;
      CreateProfilePropertyRequest.prototype.additionalCustomSensitivities = $util.emptyArray;
      CreateProfilePropertyRequest.prototype.metadata = null;

      return CreateProfilePropertyRequest;
    })();

    v1.CreateProfilePropertyResponse = (() => {
      class CreateProfilePropertyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateProfilePropertyResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.property != null && Object.hasOwnProperty.call(message, "property")) {
            profile_properties$1.v1.ProfileProperty.encode(message.property, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateProfilePropertyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.property = profile_properties$1.v1.ProfileProperty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.CreateProfilePropertyResponse) {
            return object;
          }
          const message = new $root.organization.v1.CreateProfilePropertyResponse();
          if (object.property != null) {
            if (typeof object.property !== "object") {
              throw new TypeError(".organization.v1.CreateProfilePropertyResponse.property: object expected, but got " + (typeof object.property));
            }
            message.property = profile_properties$1.v1.ProfileProperty.fromObject(object.property);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.property = null;
          }
          let keys;
          if (message.property != null && message.hasOwnProperty("property")) {
            object.property = profile_properties$1.v1.ProfileProperty.toObject(message.property, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "organization.v1.CreateProfilePropertyResponse";
        }
      }

      CreateProfilePropertyResponse.prototype.property = null;

      return CreateProfilePropertyResponse;
    })();

    v1.ListProfilePropertiesRequest = (() => {
      class ListProfilePropertiesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfilePropertiesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.includeDefaultProperties != null && Object.hasOwnProperty.call(message, "includeDefaultProperties")) {
            writer.uint32(8).bool(message.includeDefaultProperties);
          }
          if (message.includeCustomProperties != null && Object.hasOwnProperty.call(message, "includeCustomProperties")) {
            writer.uint32(16).bool(message.includeCustomProperties);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfilePropertiesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.includeDefaultProperties = reader.bool();
                break;
              }
              case 2: {
                message.includeCustomProperties = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListProfilePropertiesRequest) {
            return object;
          }
          const message = new $root.organization.v1.ListProfilePropertiesRequest();
          if (object.includeDefaultProperties != null) {
            message.includeDefaultProperties = Boolean(object.includeDefaultProperties);
          }
          if (object.includeCustomProperties != null) {
            message.includeCustomProperties = Boolean(object.includeCustomProperties);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.includeDefaultProperties = false;
            object.includeCustomProperties = false;
          }
          let keys;
          if (message.includeDefaultProperties != null && message.hasOwnProperty("includeDefaultProperties")) {
            object.includeDefaultProperties = message.includeDefaultProperties;
          }
          if (message.includeCustomProperties != null && message.hasOwnProperty("includeCustomProperties")) {
            object.includeCustomProperties = message.includeCustomProperties;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "organization.v1.ListProfilePropertiesRequest";
        }
      }

      ListProfilePropertiesRequest.prototype.includeDefaultProperties = false;
      ListProfilePropertiesRequest.prototype.includeCustomProperties = false;

      return ListProfilePropertiesRequest;
    })();

    v1.ListProfilePropertiesResponse = (() => {
      class ListProfilePropertiesResponse {
        constructor(properties) {
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfilePropertiesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.properties != null && Object.hasOwnProperty.call(message, "properties")) {
            for (const key of Object.keys(message.properties)) {
              writer.uint32(10).fork().uint32(10).string(key);
              profile_properties$1.v1.ProfileProperty.encode(message.properties[key], writer.uint32(18).fork()).ldelim().ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfilePropertiesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.properties === $util.emptyObject) message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = profile_properties$1.v1.ProfileProperty.decode(reader, reader.uint32());
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListProfilePropertiesResponse) {
            return object;
          }
          const message = new $root.organization.v1.ListProfilePropertiesResponse();
          if (object.properties) {
            if (typeof object.properties !== "object") {
              throw new TypeError(".organization.v1.ListProfilePropertiesResponse.properties: object expected, but got " + (typeof object.properties));
            }
            message.properties = {};
            for (let keys = Object.keys(object.properties), i = 0; i < keys.length; ++i) {
              if (typeof object.properties[keys[i]] !== "object") {
                throw new TypeError(".organization.v1.ListProfilePropertiesResponse.value: object expected, but got " + (typeof object.properties[keys[i]]));
              }
              message.properties[keys[i]] = profile_properties$1.v1.ProfileProperty.fromObject(object.properties[keys[i]]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.properties && (keys = Object.keys(message.properties)).length) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] = profile_properties$1.v1.ProfileProperty.toObject(message.properties[keys[i]], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "organization.v1.ListProfilePropertiesResponse";
        }
      }

      ListProfilePropertiesResponse.prototype.properties = $util.emptyObject;

      return ListProfilePropertiesResponse;
    })();

    v1.OrganizationService = (() => {
      class OrganizationService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new OrganizationService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(OrganizationService.prototype.createThirdPartySecret = function createThirdPartySecret(request, callback) {
        return this.rpcCall(createThirdPartySecret, thirdparties$1.v1.CreateThirdPartySecretRequest, thirdparties$1.v1.CreateThirdPartySecretResponse, request, callback);
      }, "name", { value: "CreateThirdPartySecret" });

      Object.defineProperty(OrganizationService.prototype.listThirdPartySecrets = function listThirdPartySecrets(request, callback) {
        return this.rpcCall(listThirdPartySecrets, thirdparties$1.v1.ListThirdPartySecretsRequest, thirdparties$1.v1.ListThirdPartySecretsResponse, request, callback);
      }, "name", { value: "ListThirdPartySecrets" });

      Object.defineProperty(OrganizationService.prototype.createAPIKey = function createAPIKey(request, callback) {
        return this.rpcCall(createAPIKey, api_key$1.v1.CreateAPIKeyRequest, api_key$1.v1.CreateAPIKeyResponse, request, callback);
      }, "name", { value: "CreateAPIKey" });

      Object.defineProperty(OrganizationService.prototype.listAPIKeys = function listAPIKeys(request, callback) {
        return this.rpcCall(listAPIKeys, api_key$1.v1.ListAPIKeysRequest, api_key$1.v1.ListAPIKeysResponse, request, callback);
      }, "name", { value: "ListAPIKeys" });

      Object.defineProperty(OrganizationService.prototype.deleteAPIKey = function deleteAPIKey(request, callback) {
        return this.rpcCall(deleteAPIKey, api_key$1.v1.DeleteAPIKeyRequest, api_key$1.v1.DeleteAPIKeyResponse, request, callback);
      }, "name", { value: "DeleteAPIKey" });

      Object.defineProperty(OrganizationService.prototype.updateWebhookRequest = function updateWebhookRequest(request, callback) {
        return this.rpcCall(updateWebhookRequest, sources$1.v1.UpdateWebhookRequestRequest, sources$1.v1.UpdateWebhookRequestResponse, request, callback);
      }, "name", { value: "UpdateWebhookRequest" });

      Object.defineProperty(OrganizationService.prototype.listAllowedRpcs = function listAllowedRpcs(request, callback) {
        return this.rpcCall(listAllowedRpcs, $root.organization.v1.ListAllowedRpcsRequest, $root.organization.v1.ListAllowedRpcsResponse, request, callback);
      }, "name", { value: "ListAllowedRpcs" });

      Object.defineProperty(OrganizationService.prototype.createProfileProperty = function createProfileProperty(request, callback) {
        return this.rpcCall(createProfileProperty, $root.organization.v1.CreateProfilePropertyRequest, $root.organization.v1.CreateProfilePropertyResponse, request, callback);
      }, "name", { value: "CreateProfileProperty" });

      Object.defineProperty(OrganizationService.prototype.listProfileProperties = function listProfileProperties(request, callback) {
        return this.rpcCall(listProfileProperties, $root.organization.v1.ListProfilePropertiesRequest, $root.organization.v1.ListProfilePropertiesResponse, request, callback);
      }, "name", { value: "ListProfileProperties" });

      return OrganizationService;
    })();

    return v1;
  })();

  return organization;
})();

export const organization = $root.organization;
