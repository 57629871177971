"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.address = (() => {
  const address = {};
  address.v1 = (() => {
    const v1 = {};
    v1.Address = (() => {
      class Address {
        get address() {
          for (const key of ["usAddress", "internationalAddress"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set address(name) {
          for (const key of ["usAddress", "internationalAddress"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Address(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.usAddress != null && Object.hasOwnProperty.call(message, "usAddress")) {
            $root.address.v1.UsAddress.encode(message.usAddress, writer.uint32(10).fork()).ldelim();
          }
          if (message.internationalAddress != null && Object.hasOwnProperty.call(message, "internationalAddress")) {
            $root.address.v1.InternationalAddress.encode(message.internationalAddress, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Address();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.usAddress = $root.address.v1.UsAddress.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.internationalAddress = $root.address.v1.InternationalAddress.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.address.v1.Address) {
            return object;
          }
          const message = new $root.address.v1.Address();
          if (object.usAddress != null) {
            if (typeof object.usAddress !== "object") {
              throw new TypeError(".address.v1.Address.usAddress: object expected, but got " + (typeof object.usAddress));
            }
            message.usAddress = $root.address.v1.UsAddress.fromObject(object.usAddress);
          }
          if (object.internationalAddress != null) {
            if (typeof object.internationalAddress !== "object") {
              throw new TypeError(".address.v1.Address.internationalAddress: object expected, but got " + (typeof object.internationalAddress));
            }
            message.internationalAddress = $root.address.v1.InternationalAddress.fromObject(object.internationalAddress);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.usAddress != null && message.hasOwnProperty("usAddress")) {
            object.usAddress = $root.address.v1.UsAddress.toObject(message.usAddress, options);
            if (options.oneofs) {
              object.address = "usAddress";
            }
          }
          if (message.internationalAddress != null && message.hasOwnProperty("internationalAddress")) {
            object.internationalAddress = $root.address.v1.InternationalAddress.toObject(message.internationalAddress, options);
            if (options.oneofs) {
              object.address = "internationalAddress";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "address.v1.Address";
        }
      }

      Address.prototype.usAddress = null;
      Address.prototype.internationalAddress = null;

      return Address;
    })();

    v1.UsAddress = (() => {
      class UsAddress {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UsAddress(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.line1 != null && Object.hasOwnProperty.call(message, "line1")) {
            writer.uint32(10).string(message.line1);
          }
          if (message.line2 != null && Object.hasOwnProperty.call(message, "line2")) {
            writer.uint32(18).string(message.line2);
          }
          if (message.city != null && Object.hasOwnProperty.call(message, "city")) {
            writer.uint32(26).string(message.city);
          }
          if (message.state != null && Object.hasOwnProperty.call(message, "state")) {
            writer.uint32(34).string(message.state);
          }
          if (message.zipCode != null && Object.hasOwnProperty.call(message, "zipCode")) {
            writer.uint32(42).string(message.zipCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UsAddress();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.line1 = reader.string();
                break;
              }
              case 2: {
                message.line2 = reader.string();
                break;
              }
              case 3: {
                message.city = reader.string();
                break;
              }
              case 4: {
                message.state = reader.string();
                break;
              }
              case 5: {
                message.zipCode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.address.v1.UsAddress) {
            return object;
          }
          const message = new $root.address.v1.UsAddress();
          if (object.line1 != null) {
            message.line1 = String(object.line1);
          }
          if (object.line2 != null) {
            message.line2 = String(object.line2);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.zipCode != null) {
            message.zipCode = String(object.zipCode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.line1 = "";
            object.city = "";
            object.state = "";
            object.zipCode = "";
          }
          let keys;
          if (message.line1 != null && message.hasOwnProperty("line1")) {
            object.line1 = message.line1;
          }
          if (message.line2 != null && message.hasOwnProperty("line2")) {
            object.line2 = message.line2;
          }
          if (message.city != null && message.hasOwnProperty("city")) {
            object.city = message.city;
          }
          if (message.state != null && message.hasOwnProperty("state")) {
            object.state = message.state;
          }
          if (message.zipCode != null && message.hasOwnProperty("zipCode")) {
            object.zipCode = message.zipCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "address.v1.UsAddress";
        }
      }

      UsAddress.prototype.line1 = "";
      UsAddress.prototype.line2 = null;
      UsAddress.prototype.city = "";
      UsAddress.prototype.state = "";
      UsAddress.prototype.zipCode = "";

      return UsAddress;
    })();

    v1.InternationalAddress = (() => {
      class InternationalAddress {
        constructor(properties) {
          this.lines = [];
          this.cityStateCountyOrDistricts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InternationalAddress(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.lines != null && Object.hasOwnProperty.call(message, "lines")) {
            for (const element of message.lines) {
              writer.uint32(10).string(element);
            }
          }
          if (message.cityStateCountyOrDistricts != null && Object.hasOwnProperty.call(message, "cityStateCountyOrDistricts")) {
            for (const element of message.cityStateCountyOrDistricts) {
              writer.uint32(18).string(element);
            }
          }
          if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode")) {
            writer.uint32(26).string(message.postalCode);
          }
          if (message.country != null && Object.hasOwnProperty.call(message, "country")) {
            writer.uint32(34).string(message.country);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InternationalAddress();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.lines || !message.lines.length) {
                  message.lines = [];
                }
                message.lines.push(reader.string());
                break;
              }
              case 2: {
                if (!message.cityStateCountyOrDistricts || !message.cityStateCountyOrDistricts.length) {
                  message.cityStateCountyOrDistricts = [];
                }
                message.cityStateCountyOrDistricts.push(reader.string());
                break;
              }
              case 3: {
                message.postalCode = reader.string();
                break;
              }
              case 4: {
                message.country = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.address.v1.InternationalAddress) {
            return object;
          }
          const message = new $root.address.v1.InternationalAddress();
          if (object.lines) {
            if (!Array.isArray(object.lines)) {
              throw new TypeError(".address.v1.InternationalAddress.lines: array type expected, but got " + (typeof object.lines))
            }
            message.lines = new Array(object.lines.length);
            for (let i = 0; i < object.lines.length; ++i) {
              message.lines[i] = String(object.lines[i]);
            }
          }
          if (object.cityStateCountyOrDistricts) {
            if (!Array.isArray(object.cityStateCountyOrDistricts)) {
              throw new TypeError(".address.v1.InternationalAddress.cityStateCountyOrDistricts: array type expected, but got " + (typeof object.cityStateCountyOrDistricts))
            }
            message.cityStateCountyOrDistricts = new Array(object.cityStateCountyOrDistricts.length);
            for (let i = 0; i < object.cityStateCountyOrDistricts.length; ++i) {
              message.cityStateCountyOrDistricts[i] = String(object.cityStateCountyOrDistricts[i]);
            }
          }
          if (object.postalCode != null) {
            message.postalCode = String(object.postalCode);
          }
          if (object.country != null) {
            message.country = String(object.country);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.lines = [];
            object.cityStateCountyOrDistricts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.postalCode = "";
            object.country = "";
          }
          let keys;
          if (message.lines && message.lines.length) {
            object.lines = new Array(message.lines.length);
            for (let i = 0; i < message.lines.length; ++i) {
              object.lines[i] = message.lines[i];
            }
          }
          if (message.cityStateCountyOrDistricts && message.cityStateCountyOrDistricts.length) {
            object.cityStateCountyOrDistricts = new Array(message.cityStateCountyOrDistricts.length);
            for (let i = 0; i < message.cityStateCountyOrDistricts.length; ++i) {
              object.cityStateCountyOrDistricts[i] = message.cityStateCountyOrDistricts[i];
            }
          }
          if (message.postalCode != null && message.hasOwnProperty("postalCode")) {
            object.postalCode = message.postalCode;
          }
          if (message.country != null && message.hasOwnProperty("country")) {
            object.country = message.country;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "address.v1.InternationalAddress";
        }
      }

      InternationalAddress.prototype.lines = $util.emptyArray;
      InternationalAddress.prototype.cityStateCountyOrDistricts = $util.emptyArray;
      InternationalAddress.prototype.postalCode = "";
      InternationalAddress.prototype.country = "";

      return InternationalAddress;
    })();

    return v1;
  })();

  return address;
})();

export const address = $root.address;
