import Long from 'long';

export const OAUTH_TOKEN = 'oauth';
export const MORF_ORG_ID = 'morf_organization_id';
export const MORF_USER = 'morf_user';
export const STYTCH_SESSION = 'stytch_session';
export const STYTCH_SESSION_JWT = 'stytch_session_jwt';
export const CONNECTED_SOURCES = 'connected_sources';
export const TIMEZONE = 'timezone';
export const PROVIDER = 'provider';
export const SCHEDULING_TIMEZONE = 'scheduling_timezone';

export const LIMIT = 35;
export const LONG_LIMIT = new Long(LIMIT);
