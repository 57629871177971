"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./struct_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.customerio = (() => {
  const customerio = {};
  customerio.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.customerio.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.customerio.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".customerio.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case "UNSPECIFIED_ILLEGAL_CUSTOMERIO_EVENT_TYPE":
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case "CUSTOMER_IO_IDENTIFY":
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case "CUSTOMER_IO_TRACK":
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                case "CUSTOMER_IO_PAGE":
                case 3: {
                  message.eventTypes[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == "number") {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = options.enums === String ? $root.customerio.v1.CustomerIoEventType[message.eventTypes[i]] === undefined ? message.eventTypes[i] : $root.customerio.v1.CustomerIoEventType[message.eventTypes[i]] : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "customerio.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Identify = (() => {
      class Identify {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Identify(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.userId != null && Object.hasOwnProperty.call(message, "userId")) {
            writer.uint32(10).string(message.userId);
          }
          if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp")) {
            timing$1.v1.Timestamp.encode(message.timestamp, writer.uint32(18).fork()).ldelim();
          }
          if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId")) {
            writer.uint32(26).string(message.messageId);
          }
          if (message.traits != null && Object.hasOwnProperty.call(message, "traits")) {
            google$1.protobuf.Struct.encode(message.traits, writer.uint32(34).fork()).ldelim();
          }
          if (message.anonymousId != null && Object.hasOwnProperty.call(message, "anonymousId")) {
            writer.uint32(42).string(message.anonymousId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Identify();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.userId = reader.string();
                break;
              }
              case 2: {
                message.timestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.messageId = reader.string();
                break;
              }
              case 4: {
                message.traits = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.anonymousId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.customerio.v1.Identify) {
            return object;
          }
          const message = new $root.customerio.v1.Identify();
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== "object") {
              throw new TypeError(".customerio.v1.Identify.timestamp: object expected, but got " + (typeof object.timestamp));
            }
            message.timestamp = timing$1.v1.Timestamp.fromObject(object.timestamp);
          }
          if (object.messageId != null) {
            message.messageId = String(object.messageId);
          }
          if (object.traits != null) {
            if (typeof object.traits !== "object") {
              throw new TypeError(".customerio.v1.Identify.traits: object expected, but got " + (typeof object.traits));
            }
            message.traits = google$1.protobuf.Struct.fromObject(object.traits);
          }
          if (object.anonymousId != null) {
            message.anonymousId = String(object.anonymousId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.userId = "";
            object.timestamp = null;
            object.messageId = "";
            object.traits = null;
            object.anonymousId = "";
          }
          let keys;
          if (message.userId != null && message.hasOwnProperty("userId")) {
            object.userId = message.userId;
          }
          if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
            object.timestamp = timing$1.v1.Timestamp.toObject(message.timestamp, options);
          }
          if (message.messageId != null && message.hasOwnProperty("messageId")) {
            object.messageId = message.messageId;
          }
          if (message.traits != null && message.hasOwnProperty("traits")) {
            object.traits = google$1.protobuf.Struct.toObject(message.traits, options);
          }
          if (message.anonymousId != null && message.hasOwnProperty("anonymousId")) {
            object.anonymousId = message.anonymousId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "customerio.v1.Identify";
        }
      }

      Identify.prototype.userId = "";
      Identify.prototype.timestamp = null;
      Identify.prototype.messageId = "";
      Identify.prototype.traits = null;
      Identify.prototype.anonymousId = "";

      return Identify;
    })();

    v1.Track = (() => {
      class Track {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Track(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.userId != null && Object.hasOwnProperty.call(message, "userId")) {
            writer.uint32(10).string(message.userId);
          }
          if (message.event != null && Object.hasOwnProperty.call(message, "event")) {
            writer.uint32(18).string(message.event);
          }
          if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp")) {
            timing$1.v1.Timestamp.encode(message.timestamp, writer.uint32(26).fork()).ldelim();
          }
          if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId")) {
            writer.uint32(34).string(message.messageId);
          }
          if (message.properties != null && Object.hasOwnProperty.call(message, "properties")) {
            google$1.protobuf.Struct.encode(message.properties, writer.uint32(42).fork()).ldelim();
          }
          if (message.anonymousId != null && Object.hasOwnProperty.call(message, "anonymousId")) {
            writer.uint32(50).string(message.anonymousId);
          }
          if (message.userAgent != null && Object.hasOwnProperty.call(message, "userAgent")) {
            writer.uint32(58).string(message.userAgent);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Track();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.userId = reader.string();
                break;
              }
              case 2: {
                message.event = reader.string();
                break;
              }
              case 3: {
                message.timestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.messageId = reader.string();
                break;
              }
              case 5: {
                message.properties = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.anonymousId = reader.string();
                break;
              }
              case 7: {
                message.userAgent = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.customerio.v1.Track) {
            return object;
          }
          const message = new $root.customerio.v1.Track();
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.event != null) {
            message.event = String(object.event);
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== "object") {
              throw new TypeError(".customerio.v1.Track.timestamp: object expected, but got " + (typeof object.timestamp));
            }
            message.timestamp = timing$1.v1.Timestamp.fromObject(object.timestamp);
          }
          if (object.messageId != null) {
            message.messageId = String(object.messageId);
          }
          if (object.properties != null) {
            if (typeof object.properties !== "object") {
              throw new TypeError(".customerio.v1.Track.properties: object expected, but got " + (typeof object.properties));
            }
            message.properties = google$1.protobuf.Struct.fromObject(object.properties);
          }
          if (object.anonymousId != null) {
            message.anonymousId = String(object.anonymousId);
          }
          if (object.userAgent != null) {
            message.userAgent = String(object.userAgent);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.userId = "";
            object.event = "";
            object.timestamp = null;
            object.messageId = "";
            object.properties = null;
            object.anonymousId = "";
            object.userAgent = "";
          }
          let keys;
          if (message.userId != null && message.hasOwnProperty("userId")) {
            object.userId = message.userId;
          }
          if (message.event != null && message.hasOwnProperty("event")) {
            object.event = message.event;
          }
          if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
            object.timestamp = timing$1.v1.Timestamp.toObject(message.timestamp, options);
          }
          if (message.messageId != null && message.hasOwnProperty("messageId")) {
            object.messageId = message.messageId;
          }
          if (message.properties != null && message.hasOwnProperty("properties")) {
            object.properties = google$1.protobuf.Struct.toObject(message.properties, options);
          }
          if (message.anonymousId != null && message.hasOwnProperty("anonymousId")) {
            object.anonymousId = message.anonymousId;
          }
          if (message.userAgent != null && message.hasOwnProperty("userAgent")) {
            object.userAgent = message.userAgent;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "customerio.v1.Track";
        }
      }

      Track.prototype.userId = "";
      Track.prototype.event = "";
      Track.prototype.timestamp = null;
      Track.prototype.messageId = "";
      Track.prototype.properties = null;
      Track.prototype.anonymousId = "";
      Track.prototype.userAgent = "";

      return Track;
    })();

    v1.Page = (() => {
      class Page {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Page(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.userId != null && Object.hasOwnProperty.call(message, "userId")) {
            writer.uint32(10).string(message.userId);
          }
          if (message.path != null && Object.hasOwnProperty.call(message, "path")) {
            writer.uint32(18).string(message.path);
          }
          if (message.referrer != null && Object.hasOwnProperty.call(message, "referrer")) {
            writer.uint32(26).string(message.referrer);
          }
          if (message.search != null && Object.hasOwnProperty.call(message, "search")) {
            writer.uint32(34).string(message.search);
          }
          if (message.title != null && Object.hasOwnProperty.call(message, "title")) {
            writer.uint32(42).string(message.title);
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(50).string(message.url);
          }
          if (message.userAgent != null && Object.hasOwnProperty.call(message, "userAgent")) {
            writer.uint32(58).string(message.userAgent);
          }
          if (message.locale != null && Object.hasOwnProperty.call(message, "locale")) {
            writer.uint32(66).string(message.locale);
          }
          if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp")) {
            timing$1.v1.Timestamp.encode(message.timestamp, writer.uint32(74).fork()).ldelim();
          }
          if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId")) {
            writer.uint32(82).string(message.messageId);
          }
          if (message.properties != null && Object.hasOwnProperty.call(message, "properties")) {
            google$1.protobuf.Struct.encode(message.properties, writer.uint32(90).fork()).ldelim();
          }
          if (message.anonymousId != null && Object.hasOwnProperty.call(message, "anonymousId")) {
            writer.uint32(98).string(message.anonymousId);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(106).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Page();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.userId = reader.string();
                break;
              }
              case 2: {
                message.path = reader.string();
                break;
              }
              case 3: {
                message.referrer = reader.string();
                break;
              }
              case 4: {
                message.search = reader.string();
                break;
              }
              case 5: {
                message.title = reader.string();
                break;
              }
              case 6: {
                message.url = reader.string();
                break;
              }
              case 7: {
                message.userAgent = reader.string();
                break;
              }
              case 8: {
                message.locale = reader.string();
                break;
              }
              case 9: {
                message.timestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.messageId = reader.string();
                break;
              }
              case 11: {
                message.properties = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.anonymousId = reader.string();
                break;
              }
              case 13: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.customerio.v1.Page) {
            return object;
          }
          const message = new $root.customerio.v1.Page();
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.path != null) {
            message.path = String(object.path);
          }
          if (object.referrer != null) {
            message.referrer = String(object.referrer);
          }
          if (object.search != null) {
            message.search = String(object.search);
          }
          if (object.title != null) {
            message.title = String(object.title);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.userAgent != null) {
            message.userAgent = String(object.userAgent);
          }
          if (object.locale != null) {
            message.locale = String(object.locale);
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== "object") {
              throw new TypeError(".customerio.v1.Page.timestamp: object expected, but got " + (typeof object.timestamp));
            }
            message.timestamp = timing$1.v1.Timestamp.fromObject(object.timestamp);
          }
          if (object.messageId != null) {
            message.messageId = String(object.messageId);
          }
          if (object.properties != null) {
            if (typeof object.properties !== "object") {
              throw new TypeError(".customerio.v1.Page.properties: object expected, but got " + (typeof object.properties));
            }
            message.properties = google$1.protobuf.Struct.fromObject(object.properties);
          }
          if (object.anonymousId != null) {
            message.anonymousId = String(object.anonymousId);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.userId = "";
            object.path = "";
            object.referrer = "";
            object.search = "";
            object.title = "";
            object.url = "";
            object.userAgent = "";
            object.locale = "";
            object.timestamp = null;
            object.messageId = "";
            object.properties = null;
            object.anonymousId = "";
            object.name = "";
          }
          let keys;
          if (message.userId != null && message.hasOwnProperty("userId")) {
            object.userId = message.userId;
          }
          if (message.path != null && message.hasOwnProperty("path")) {
            object.path = message.path;
          }
          if (message.referrer != null && message.hasOwnProperty("referrer")) {
            object.referrer = message.referrer;
          }
          if (message.search != null && message.hasOwnProperty("search")) {
            object.search = message.search;
          }
          if (message.title != null && message.hasOwnProperty("title")) {
            object.title = message.title;
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.userAgent != null && message.hasOwnProperty("userAgent")) {
            object.userAgent = message.userAgent;
          }
          if (message.locale != null && message.hasOwnProperty("locale")) {
            object.locale = message.locale;
          }
          if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
            object.timestamp = timing$1.v1.Timestamp.toObject(message.timestamp, options);
          }
          if (message.messageId != null && message.hasOwnProperty("messageId")) {
            object.messageId = message.messageId;
          }
          if (message.properties != null && message.hasOwnProperty("properties")) {
            object.properties = google$1.protobuf.Struct.toObject(message.properties, options);
          }
          if (message.anonymousId != null && message.hasOwnProperty("anonymousId")) {
            object.anonymousId = message.anonymousId;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "customerio.v1.Page";
        }
      }

      Page.prototype.userId = "";
      Page.prototype.path = "";
      Page.prototype.referrer = "";
      Page.prototype.search = "";
      Page.prototype.title = "";
      Page.prototype.url = "";
      Page.prototype.userAgent = "";
      Page.prototype.locale = "";
      Page.prototype.timestamp = null;
      Page.prototype.messageId = "";
      Page.prototype.properties = null;
      Page.prototype.anonymousId = "";
      Page.prototype.name = "";

      return Page;
    })();

    v1.CustomerIoEventType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_CUSTOMERIO_EVENT_TYPE"] = 0;
      values[valuesById[1] = "CUSTOMER_IO_IDENTIFY"] = 1;
      values[valuesById[2] = "CUSTOMER_IO_TRACK"] = 2;
      values[valuesById[3] = "CUSTOMER_IO_PAGE"] = 3;
      return values;
    })();

    return v1;
  })();

  return customerio;
})();

export const customerio = $root.customerio;
