"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.sources = (() => {
  const sources = {};
  sources.v1 = (() => {
    const v1 = {};
    v1.UpdateWebhookRequestRequest = (() => {
      class UpdateWebhookRequestRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWebhookRequestRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.reprocess != null && Object.hasOwnProperty.call(message, "reprocess")) {
            writer.uint32(16).bool(message.reprocess);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWebhookRequestRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.reprocess = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.UpdateWebhookRequestRequest) {
            return object;
          }
          const message = new $root.sources.v1.UpdateWebhookRequestRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.reprocess != null) {
            message.reprocess = Boolean(object.reprocess);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.reprocess != null && message.hasOwnProperty("reprocess")) {
            object.reprocess = message.reprocess;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v1.UpdateWebhookRequestRequest";
        }
      }

      UpdateWebhookRequestRequest.prototype.id = "";
      UpdateWebhookRequestRequest.prototype.reprocess = null;

      return UpdateWebhookRequestRequest;
    })();

    v1.UpdateWebhookRequestResponse = (() => {
      class UpdateWebhookRequestResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWebhookRequestResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWebhookRequestResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.UpdateWebhookRequestResponse) {
            return object;
          }
          const message = new $root.sources.v1.UpdateWebhookRequestResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v1.UpdateWebhookRequestResponse";
        }
      }

      return UpdateWebhookRequestResponse;
    })();

    return v1;
  })();

  return sources;
})();

export const sources = $root.sources;
