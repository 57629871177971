import Cookies from 'js-cookie';
import { MORF_ORG_ID } from '@morf/constants';
import { getQueryParamArray, getQueryParamString } from '@morf/ui';

export type Params = {
  attemptNumber?: string;
  classes?: string[];
  errorDetailLike?: string;
  eventId?: string;
  eventTypes?: string[];
  from?: string;
  geographicLocale?: string;
  insurancePayer?: string;
  internalTags?: string[];
  issueErrorHash?: string;
  issueErrorType?: string;
  nodeId?: string;
  organizationId?: string;
  panelNodeId?: string;
  payloadTypes?: string[];
  providerId?: string;
  providerIdType?: string;
  providerName?: string;
  showCELPlayground?: string;
  sources?: string[];
  specialties?: string[];
  states?: string[];
  stytch_token_type?: string;
  tab?: string;
  thirdPartyId?: string;
  thirdPartyType?: string;
  time?: string;
  token?: string;
  workflowExecutionId?: string;
  workflowId?: string;
  workflowIds?: string[];
};

export const useUrlParams = (): Params => {
  const attemptNumber = getQueryParamString('attemptNumber');
  const classes = getQueryParamArray('classes');
  const errorDetailLike = getQueryParamString('errorDetailLike');
  const eventId = getQueryParamString('eventId');
  const eventTypes = getQueryParamArray('eventTypes');
  const from = getQueryParamString('from');
  const geographicLocale = getQueryParamString('geographicLocale');
  const insurancePayer = getQueryParamString('insurancePayer');
  const internalTags = getQueryParamArray('internalTags');
  const issueErrorHash = getQueryParamString('issueErrorHash');
  const issueErrorType = getQueryParamString('issueErrorType');
  const nodeId = getQueryParamString('nodeId');
  const organizationId = getQueryParamString('organizationId');
  const panelNodeId = getQueryParamString('panelNodeId');
  const payloadTypes = getQueryParamArray('payloadTypes');
  const providerId = getQueryParamString('providerId');
  const providerIdType = getQueryParamString('providerIdType');
  const providerName = getQueryParamString('providerName');
  const showCELPlayground = getQueryParamString('showCELPlayground');
  const sources = getQueryParamArray('sources');
  const specialties = getQueryParamArray('specialties');
  const states = getQueryParamArray('states');
  const stytch_token_type = getQueryParamString('stytch_token_type');
  const tab = getQueryParamString('tab');
  const thirdPartyId = getQueryParamString('thirdPartyId');
  const thirdPartyType = getQueryParamString('thirdPartyType');
  const time = getQueryParamString('time');
  const token = getQueryParamString('token');
  const workflowExecutionId = getQueryParamString('workflowExecutionId');
  const workflowId = getQueryParamString('workflowId');
  const workflowIds = getQueryParamArray('workflowIds');

  if (organizationId && organizationId !== Cookies.get(MORF_ORG_ID)) {
    Cookies.set(MORF_ORG_ID, organizationId);
  }

  return {
    attemptNumber,
    classes,
    errorDetailLike,
    eventId,
    eventTypes,
    from,
    geographicLocale,
    insurancePayer,
    internalTags,
    issueErrorHash,
    issueErrorType,
    nodeId,
    organizationId,
    panelNodeId,
    payloadTypes,
    providerId,
    providerIdType,
    providerName,
    showCELPlayground,
    sources,
    specialties,
    states,
    stytch_token_type,
    tab,
    thirdPartyId,
    thirdPartyType,
    time,
    token,
    workflowExecutionId,
    workflowId,
    workflowIds,
  };
};
