import { useRouter } from 'next/router';

export const getQueryParamString = (param: string): string | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (!value || typeof value !== 'string') {
    return undefined;
  }

  return value;
};

export const getQueryParamArray = (param: string): string[] | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (Array.isArray(value)) {
    return value;
  }

  if (value) {
    return [value];
  }

  return undefined;
};

export const getQueryParamBoolean = (param: string): boolean => {
  const router = useRouter();
  const value = router.query[param];

  if (value === 'true' || value === '') {
    return true;
  }

  return false;
};
