"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { timing as timing$1 } from "./timing_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.acuity = (() => {
  const acuity = {};
  acuity.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.acuity.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".acuity.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case "UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE":
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case "ACUITY_APPOINTMENT_SCHEDULED":
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case "ACUITY_APPOINTMENT_RESCHEDULED":
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                case "ACUITY_APPOINTMENT_CANCELLED":
                case 3: {
                  message.eventTypes[i] = 3;
                  break;
                }
                case "ACUITY_APPOINTMENT_UPDATED":
                case 4: {
                  message.eventTypes[i] = 4;
                  break;
                }
                case "ACUITY_APPOINTMENT_NO_SHOW":
                case 5: {
                  message.eventTypes[i] = 5;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == "number") {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = options.enums === String ? $root.acuity.v1.AcuityEventType[message.eventTypes[i]] === undefined ? message.eventTypes[i] : $root.acuity.v1.AcuityEventType[message.eventTypes[i]] : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.AppointmentType = (() => {
      class AppointmentType {
        constructor(properties) {
          this.calendarIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AppointmentType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.active != null && Object.hasOwnProperty.call(message, "active")) {
            writer.uint32(16).bool(message.active);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(26).string(message.name);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(34).string(message.description);
          }
          if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes")) {
            writer.uint32(40).uint32(message.lengthMinutes);
          }
          if (message.category != null && Object.hasOwnProperty.call(message, "category")) {
            writer.uint32(50).string(message.category);
          }
          if (message.private != null && Object.hasOwnProperty.call(message, "private")) {
            writer.uint32(56).bool(message.private);
          }
          if (message.minutesPaddingBefore != null && Object.hasOwnProperty.call(message, "minutesPaddingBefore")) {
            writer.uint32(64).uint32(message.minutesPaddingBefore);
          }
          if (message.minutesPaddingAfter != null && Object.hasOwnProperty.call(message, "minutesPaddingAfter")) {
            writer.uint32(72).uint32(message.minutesPaddingAfter);
          }
          if (message.calendarIds != null && Object.hasOwnProperty.call(message, "calendarIds")) {
            for (const element of message.calendarIds) {
              writer.uint32(82).string(element);
            }
          }
          if (message.schedulingUrl != null && Object.hasOwnProperty.call(message, "schedulingUrl")) {
            writer.uint32(90).string(message.schedulingUrl);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AppointmentType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.active = reader.bool();
                break;
              }
              case 3: {
                message.name = reader.string();
                break;
              }
              case 4: {
                message.description = reader.string();
                break;
              }
              case 5: {
                message.lengthMinutes = reader.uint32();
                break;
              }
              case 6: {
                message.category = reader.string();
                break;
              }
              case 7: {
                message.private = reader.bool();
                break;
              }
              case 8: {
                message.minutesPaddingBefore = reader.uint32();
                break;
              }
              case 9: {
                message.minutesPaddingAfter = reader.uint32();
                break;
              }
              case 10: {
                if (!message.calendarIds || !message.calendarIds.length) {
                  message.calendarIds = [];
                }
                message.calendarIds.push(reader.string());
                break;
              }
              case 11: {
                message.schedulingUrl = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.AppointmentType) {
            return object;
          }
          const message = new $root.acuity.v1.AppointmentType();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.active != null) {
            message.active = Boolean(object.active);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = object.lengthMinutes >>> 0;
          }
          if (object.category != null) {
            message.category = String(object.category);
          }
          if (object.private != null) {
            message.private = Boolean(object.private);
          }
          if (object.minutesPaddingBefore != null) {
            message.minutesPaddingBefore = object.minutesPaddingBefore >>> 0;
          }
          if (object.minutesPaddingAfter != null) {
            message.minutesPaddingAfter = object.minutesPaddingAfter >>> 0;
          }
          if (object.calendarIds) {
            if (!Array.isArray(object.calendarIds)) {
              throw new TypeError(".acuity.v1.AppointmentType.calendarIds: array type expected, but got " + (typeof object.calendarIds))
            }
            message.calendarIds = new Array(object.calendarIds.length);
            for (let i = 0; i < object.calendarIds.length; ++i) {
              message.calendarIds[i] = String(object.calendarIds[i]);
            }
          }
          if (object.schedulingUrl != null) {
            message.schedulingUrl = String(object.schedulingUrl);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.calendarIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.active = false;
            object.name = "";
            object.lengthMinutes = 0;
            object.private = false;
            object.minutesPaddingBefore = 0;
            object.minutesPaddingAfter = 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.active != null && message.hasOwnProperty("active")) {
            object.active = message.active;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes")) {
            object.lengthMinutes = message.lengthMinutes;
          }
          if (message.category != null && message.hasOwnProperty("category")) {
            object.category = message.category;
          }
          if (message.private != null && message.hasOwnProperty("private")) {
            object.private = message.private;
          }
          if (message.minutesPaddingBefore != null && message.hasOwnProperty("minutesPaddingBefore")) {
            object.minutesPaddingBefore = message.minutesPaddingBefore;
          }
          if (message.minutesPaddingAfter != null && message.hasOwnProperty("minutesPaddingAfter")) {
            object.minutesPaddingAfter = message.minutesPaddingAfter;
          }
          if (message.calendarIds && message.calendarIds.length) {
            object.calendarIds = new Array(message.calendarIds.length);
            for (let i = 0; i < message.calendarIds.length; ++i) {
              object.calendarIds[i] = message.calendarIds[i];
            }
          }
          if (message.schedulingUrl != null && message.hasOwnProperty("schedulingUrl")) {
            object.schedulingUrl = message.schedulingUrl;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.AppointmentType";
        }
      }

      AppointmentType.prototype.id = "";
      AppointmentType.prototype.active = false;
      AppointmentType.prototype.name = "";
      AppointmentType.prototype.description = null;
      AppointmentType.prototype.lengthMinutes = 0;
      AppointmentType.prototype.category = null;
      AppointmentType.prototype.private = false;
      AppointmentType.prototype.minutesPaddingBefore = 0;
      AppointmentType.prototype.minutesPaddingAfter = 0;
      AppointmentType.prototype.calendarIds = $util.emptyArray;
      AppointmentType.prototype.schedulingUrl = null;

      return AppointmentType;
    })();

    v1.Appointment = (() => {
      class Appointment {
        constructor(properties) {
          this.labels = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Appointment(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId")) {
            writer.uint32(10).string(message.appointmentId);
          }
          if (message.appointmentEmailAddress != null && Object.hasOwnProperty.call(message, "appointmentEmailAddress")) {
            writer.uint32(18).string(message.appointmentEmailAddress);
          }
          if (message.appointmentTypeId != null && Object.hasOwnProperty.call(message, "appointmentTypeId")) {
            writer.uint32(26).string(message.appointmentTypeId);
          }
          if (message.appointmentTypeTitle != null && Object.hasOwnProperty.call(message, "appointmentTypeTitle")) {
            writer.uint32(34).string(message.appointmentTypeTitle);
          }
          if (message.appointmentType != null && Object.hasOwnProperty.call(message, "appointmentType")) {
            $root.acuity.v1.AppointmentType.encode(message.appointmentType, writer.uint32(42).fork()).ldelim();
          }
          if (message.appointmentLocationDetail != null && Object.hasOwnProperty.call(message, "appointmentLocationDetail")) {
            writer.uint32(50).string(message.appointmentLocationDetail);
          }
          if (message.appointmentZoomMeetingId != null && Object.hasOwnProperty.call(message, "appointmentZoomMeetingId")) {
            writer.uint32(58).string(message.appointmentZoomMeetingId);
          }
          if (message.appointmentZoomMeetingPassword != null && Object.hasOwnProperty.call(message, "appointmentZoomMeetingPassword")) {
            writer.uint32(66).string(message.appointmentZoomMeetingPassword);
          }
          if (message.appointmentProviderId != null && Object.hasOwnProperty.call(message, "appointmentProviderId")) {
            writer.uint32(74).string(message.appointmentProviderId);
          }
          if (message.appointmentProviderFullName != null && Object.hasOwnProperty.call(message, "appointmentProviderFullName")) {
            writer.uint32(82).string(message.appointmentProviderFullName);
          }
          if (message.appointmentDatetimeRaw != null && Object.hasOwnProperty.call(message, "appointmentDatetimeRaw")) {
            writer.uint32(90).string(message.appointmentDatetimeRaw);
          }
          if (message.appointmentDatetime != null && Object.hasOwnProperty.call(message, "appointmentDatetime")) {
            timing$1.v1.Timestamp.encode(message.appointmentDatetime, writer.uint32(98).fork()).ldelim();
          }
          if (message.appointmentEndDatetime != null && Object.hasOwnProperty.call(message, "appointmentEndDatetime")) {
            timing$1.v1.Timestamp.encode(message.appointmentEndDatetime, writer.uint32(106).fork()).ldelim();
          }
          if (message.appointmentLengthMinutes != null && Object.hasOwnProperty.call(message, "appointmentLengthMinutes")) {
            writer.uint32(112).uint32(message.appointmentLengthMinutes);
          }
          if (message.appointmentNotes != null && Object.hasOwnProperty.call(message, "appointmentNotes")) {
            writer.uint32(122).string(message.appointmentNotes);
          }
          if (message.appointmentCreatedAt != null && Object.hasOwnProperty.call(message, "appointmentCreatedAt")) {
            timing$1.v1.Timestamp.encode(message.appointmentCreatedAt, writer.uint32(130).fork()).ldelim();
          }
          if (message.appointmentCreatedBy != null && Object.hasOwnProperty.call(message, "appointmentCreatedBy")) {
            writer.uint32(138).string(message.appointmentCreatedBy);
          }
          if (message.appointmentTimezoneShortName != null && Object.hasOwnProperty.call(message, "appointmentTimezoneShortName")) {
            writer.uint32(146).string(message.appointmentTimezoneShortName);
          }
          if (message.appointmentTimezoneOffset != null && Object.hasOwnProperty.call(message, "appointmentTimezoneOffset")) {
            writer.uint32(154).string(message.appointmentTimezoneOffset);
          }
          if (message.appointmentTimezoneOffsetSecondsEastOfUtc != null && Object.hasOwnProperty.call(message, "appointmentTimezoneOffsetSecondsEastOfUtc")) {
            writer.uint32(160).int32(message.appointmentTimezoneOffsetSecondsEastOfUtc);
          }
          if (message.appointmentPatientTimezoneIdentifier != null && Object.hasOwnProperty.call(message, "appointmentPatientTimezoneIdentifier")) {
            writer.uint32(170).string(message.appointmentPatientTimezoneIdentifier);
          }
          if (message.appointmentConfirmationLink != null && Object.hasOwnProperty.call(message, "appointmentConfirmationLink")) {
            writer.uint32(178).string(message.appointmentConfirmationLink);
          }
          if (message.appointmentFirstName != null && Object.hasOwnProperty.call(message, "appointmentFirstName")) {
            writer.uint32(186).string(message.appointmentFirstName);
          }
          if (message.appointmentLastName != null && Object.hasOwnProperty.call(message, "appointmentLastName")) {
            writer.uint32(194).string(message.appointmentLastName);
          }
          if (message.appointmentDayOfWeek != null && Object.hasOwnProperty.call(message, "appointmentDayOfWeek")) {
            writer.uint32(202).string(message.appointmentDayOfWeek);
          }
          if (message.appointmentDayOfMonth != null && Object.hasOwnProperty.call(message, "appointmentDayOfMonth")) {
            writer.uint32(210).string(message.appointmentDayOfMonth);
          }
          if (message.appointmentMonth != null && Object.hasOwnProperty.call(message, "appointmentMonth")) {
            writer.uint32(218).string(message.appointmentMonth);
          }
          if (message.appointmentDate != null && Object.hasOwnProperty.call(message, "appointmentDate")) {
            writer.uint32(226).string(message.appointmentDate);
          }
          if (message.appointmentTime != null && Object.hasOwnProperty.call(message, "appointmentTime")) {
            writer.uint32(234).string(message.appointmentTime);
          }
          if (message.appointmentPhoneNumber != null && Object.hasOwnProperty.call(message, "appointmentPhoneNumber")) {
            writer.uint32(242).string(message.appointmentPhoneNumber);
          }
          if (message.customerProvidedId != null && Object.hasOwnProperty.call(message, "customerProvidedId")) {
            writer.uint32(250).string(message.customerProvidedId);
          }
          if (message.labels != null && Object.hasOwnProperty.call(message, "labels")) {
            for (const element of message.labels) {
              writer.uint32(258).string(element);
            }
          }
          if (message.canceled != null && Object.hasOwnProperty.call(message, "canceled")) {
            writer.uint32(264).bool(message.canceled);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Appointment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointmentId = reader.string();
                break;
              }
              case 2: {
                message.appointmentEmailAddress = reader.string();
                break;
              }
              case 3: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 4: {
                message.appointmentTypeTitle = reader.string();
                break;
              }
              case 5: {
                message.appointmentType = $root.acuity.v1.AppointmentType.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.appointmentLocationDetail = reader.string();
                break;
              }
              case 7: {
                message.appointmentZoomMeetingId = reader.string();
                break;
              }
              case 8: {
                message.appointmentZoomMeetingPassword = reader.string();
                break;
              }
              case 9: {
                message.appointmentProviderId = reader.string();
                break;
              }
              case 10: {
                message.appointmentProviderFullName = reader.string();
                break;
              }
              case 11: {
                message.appointmentDatetimeRaw = reader.string();
                break;
              }
              case 12: {
                message.appointmentDatetime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.appointmentEndDatetime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.appointmentLengthMinutes = reader.uint32();
                break;
              }
              case 15: {
                message.appointmentNotes = reader.string();
                break;
              }
              case 16: {
                message.appointmentCreatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.appointmentCreatedBy = reader.string();
                break;
              }
              case 18: {
                message.appointmentTimezoneShortName = reader.string();
                break;
              }
              case 19: {
                message.appointmentTimezoneOffset = reader.string();
                break;
              }
              case 20: {
                message.appointmentTimezoneOffsetSecondsEastOfUtc = reader.int32();
                break;
              }
              case 21: {
                message.appointmentPatientTimezoneIdentifier = reader.string();
                break;
              }
              case 22: {
                message.appointmentConfirmationLink = reader.string();
                break;
              }
              case 23: {
                message.appointmentFirstName = reader.string();
                break;
              }
              case 24: {
                message.appointmentLastName = reader.string();
                break;
              }
              case 25: {
                message.appointmentDayOfWeek = reader.string();
                break;
              }
              case 26: {
                message.appointmentDayOfMonth = reader.string();
                break;
              }
              case 27: {
                message.appointmentMonth = reader.string();
                break;
              }
              case 28: {
                message.appointmentDate = reader.string();
                break;
              }
              case 29: {
                message.appointmentTime = reader.string();
                break;
              }
              case 30: {
                message.appointmentPhoneNumber = reader.string();
                break;
              }
              case 31: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 32: {
                if (!message.labels || !message.labels.length) {
                  message.labels = [];
                }
                message.labels.push(reader.string());
                break;
              }
              case 33: {
                message.canceled = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.Appointment) {
            return object;
          }
          const message = new $root.acuity.v1.Appointment();
          if (object.appointmentId != null) {
            message.appointmentId = String(object.appointmentId);
          }
          if (object.appointmentEmailAddress != null) {
            message.appointmentEmailAddress = String(object.appointmentEmailAddress);
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.appointmentTypeTitle != null) {
            message.appointmentTypeTitle = String(object.appointmentTypeTitle);
          }
          if (object.appointmentType != null) {
            if (typeof object.appointmentType !== "object") {
              throw new TypeError(".acuity.v1.Appointment.appointmentType: object expected, but got " + (typeof object.appointmentType));
            }
            message.appointmentType = $root.acuity.v1.AppointmentType.fromObject(object.appointmentType);
          }
          if (object.appointmentLocationDetail != null) {
            message.appointmentLocationDetail = String(object.appointmentLocationDetail);
          }
          if (object.appointmentZoomMeetingId != null) {
            message.appointmentZoomMeetingId = String(object.appointmentZoomMeetingId);
          }
          if (object.appointmentZoomMeetingPassword != null) {
            message.appointmentZoomMeetingPassword = String(object.appointmentZoomMeetingPassword);
          }
          if (object.appointmentProviderId != null) {
            message.appointmentProviderId = String(object.appointmentProviderId);
          }
          if (object.appointmentProviderFullName != null) {
            message.appointmentProviderFullName = String(object.appointmentProviderFullName);
          }
          if (object.appointmentDatetimeRaw != null) {
            message.appointmentDatetimeRaw = String(object.appointmentDatetimeRaw);
          }
          if (object.appointmentDatetime != null) {
            if (typeof object.appointmentDatetime !== "object") {
              throw new TypeError(".acuity.v1.Appointment.appointmentDatetime: object expected, but got " + (typeof object.appointmentDatetime));
            }
            message.appointmentDatetime = timing$1.v1.Timestamp.fromObject(object.appointmentDatetime);
          }
          if (object.appointmentEndDatetime != null) {
            if (typeof object.appointmentEndDatetime !== "object") {
              throw new TypeError(".acuity.v1.Appointment.appointmentEndDatetime: object expected, but got " + (typeof object.appointmentEndDatetime));
            }
            message.appointmentEndDatetime = timing$1.v1.Timestamp.fromObject(object.appointmentEndDatetime);
          }
          if (object.appointmentLengthMinutes != null) {
            message.appointmentLengthMinutes = object.appointmentLengthMinutes >>> 0;
          }
          if (object.appointmentNotes != null) {
            message.appointmentNotes = String(object.appointmentNotes);
          }
          if (object.appointmentCreatedAt != null) {
            if (typeof object.appointmentCreatedAt !== "object") {
              throw new TypeError(".acuity.v1.Appointment.appointmentCreatedAt: object expected, but got " + (typeof object.appointmentCreatedAt));
            }
            message.appointmentCreatedAt = timing$1.v1.Timestamp.fromObject(object.appointmentCreatedAt);
          }
          if (object.appointmentCreatedBy != null) {
            message.appointmentCreatedBy = String(object.appointmentCreatedBy);
          }
          if (object.appointmentTimezoneShortName != null) {
            message.appointmentTimezoneShortName = String(object.appointmentTimezoneShortName);
          }
          if (object.appointmentTimezoneOffset != null) {
            message.appointmentTimezoneOffset = String(object.appointmentTimezoneOffset);
          }
          if (object.appointmentTimezoneOffsetSecondsEastOfUtc != null) {
            message.appointmentTimezoneOffsetSecondsEastOfUtc = object.appointmentTimezoneOffsetSecondsEastOfUtc | 0;
          }
          if (object.appointmentPatientTimezoneIdentifier != null) {
            message.appointmentPatientTimezoneIdentifier = String(object.appointmentPatientTimezoneIdentifier);
          }
          if (object.appointmentConfirmationLink != null) {
            message.appointmentConfirmationLink = String(object.appointmentConfirmationLink);
          }
          if (object.appointmentFirstName != null) {
            message.appointmentFirstName = String(object.appointmentFirstName);
          }
          if (object.appointmentLastName != null) {
            message.appointmentLastName = String(object.appointmentLastName);
          }
          if (object.appointmentDayOfWeek != null) {
            message.appointmentDayOfWeek = String(object.appointmentDayOfWeek);
          }
          if (object.appointmentDayOfMonth != null) {
            message.appointmentDayOfMonth = String(object.appointmentDayOfMonth);
          }
          if (object.appointmentMonth != null) {
            message.appointmentMonth = String(object.appointmentMonth);
          }
          if (object.appointmentDate != null) {
            message.appointmentDate = String(object.appointmentDate);
          }
          if (object.appointmentTime != null) {
            message.appointmentTime = String(object.appointmentTime);
          }
          if (object.appointmentPhoneNumber != null) {
            message.appointmentPhoneNumber = String(object.appointmentPhoneNumber);
          }
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.labels) {
            if (!Array.isArray(object.labels)) {
              throw new TypeError(".acuity.v1.Appointment.labels: array type expected, but got " + (typeof object.labels))
            }
            message.labels = new Array(object.labels.length);
            for (let i = 0; i < object.labels.length; ++i) {
              message.labels[i] = String(object.labels[i]);
            }
          }
          if (object.canceled != null) {
            message.canceled = Boolean(object.canceled);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.labels = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentId = "";
            object.appointmentDatetimeRaw = "";
            object.appointmentDatetime = null;
            object.appointmentEndDatetime = null;
            object.appointmentCreatedAt = null;
            object.appointmentTimezoneOffset = "";
            object.appointmentTimezoneOffsetSecondsEastOfUtc = 0;
            object.appointmentDayOfWeek = "";
            object.appointmentDayOfMonth = "";
            object.appointmentMonth = "";
            object.appointmentDate = "";
            object.appointmentTime = "";
            object.canceled = false;
          }
          let keys;
          if (message.appointmentId != null && message.hasOwnProperty("appointmentId")) {
            object.appointmentId = message.appointmentId;
          }
          if (message.appointmentEmailAddress != null && message.hasOwnProperty("appointmentEmailAddress")) {
            object.appointmentEmailAddress = message.appointmentEmailAddress;
          }
          if (message.appointmentTypeId != null && message.hasOwnProperty("appointmentTypeId")) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (message.appointmentTypeTitle != null && message.hasOwnProperty("appointmentTypeTitle")) {
            object.appointmentTypeTitle = message.appointmentTypeTitle;
          }
          if (message.appointmentType != null && message.hasOwnProperty("appointmentType")) {
            object.appointmentType = $root.acuity.v1.AppointmentType.toObject(message.appointmentType, options);
          }
          if (message.appointmentLocationDetail != null && message.hasOwnProperty("appointmentLocationDetail")) {
            object.appointmentLocationDetail = message.appointmentLocationDetail;
          }
          if (message.appointmentZoomMeetingId != null && message.hasOwnProperty("appointmentZoomMeetingId")) {
            object.appointmentZoomMeetingId = message.appointmentZoomMeetingId;
          }
          if (message.appointmentZoomMeetingPassword != null && message.hasOwnProperty("appointmentZoomMeetingPassword")) {
            object.appointmentZoomMeetingPassword = message.appointmentZoomMeetingPassword;
          }
          if (message.appointmentProviderId != null && message.hasOwnProperty("appointmentProviderId")) {
            object.appointmentProviderId = message.appointmentProviderId;
          }
          if (message.appointmentProviderFullName != null && message.hasOwnProperty("appointmentProviderFullName")) {
            object.appointmentProviderFullName = message.appointmentProviderFullName;
          }
          if (message.appointmentDatetimeRaw != null && message.hasOwnProperty("appointmentDatetimeRaw")) {
            object.appointmentDatetimeRaw = message.appointmentDatetimeRaw;
          }
          if (message.appointmentDatetime != null && message.hasOwnProperty("appointmentDatetime")) {
            object.appointmentDatetime = timing$1.v1.Timestamp.toObject(message.appointmentDatetime, options);
          }
          if (message.appointmentEndDatetime != null && message.hasOwnProperty("appointmentEndDatetime")) {
            object.appointmentEndDatetime = timing$1.v1.Timestamp.toObject(message.appointmentEndDatetime, options);
          }
          if (message.appointmentLengthMinutes != null && message.hasOwnProperty("appointmentLengthMinutes")) {
            object.appointmentLengthMinutes = message.appointmentLengthMinutes;
          }
          if (message.appointmentNotes != null && message.hasOwnProperty("appointmentNotes")) {
            object.appointmentNotes = message.appointmentNotes;
          }
          if (message.appointmentCreatedAt != null && message.hasOwnProperty("appointmentCreatedAt")) {
            object.appointmentCreatedAt = timing$1.v1.Timestamp.toObject(message.appointmentCreatedAt, options);
          }
          if (message.appointmentCreatedBy != null && message.hasOwnProperty("appointmentCreatedBy")) {
            object.appointmentCreatedBy = message.appointmentCreatedBy;
          }
          if (message.appointmentTimezoneShortName != null && message.hasOwnProperty("appointmentTimezoneShortName")) {
            object.appointmentTimezoneShortName = message.appointmentTimezoneShortName;
          }
          if (message.appointmentTimezoneOffset != null && message.hasOwnProperty("appointmentTimezoneOffset")) {
            object.appointmentTimezoneOffset = message.appointmentTimezoneOffset;
          }
          if (message.appointmentTimezoneOffsetSecondsEastOfUtc != null && message.hasOwnProperty("appointmentTimezoneOffsetSecondsEastOfUtc")) {
            object.appointmentTimezoneOffsetSecondsEastOfUtc = message.appointmentTimezoneOffsetSecondsEastOfUtc;
          }
          if (message.appointmentPatientTimezoneIdentifier != null && message.hasOwnProperty("appointmentPatientTimezoneIdentifier")) {
            object.appointmentPatientTimezoneIdentifier = message.appointmentPatientTimezoneIdentifier;
          }
          if (message.appointmentConfirmationLink != null && message.hasOwnProperty("appointmentConfirmationLink")) {
            object.appointmentConfirmationLink = message.appointmentConfirmationLink;
          }
          if (message.appointmentFirstName != null && message.hasOwnProperty("appointmentFirstName")) {
            object.appointmentFirstName = message.appointmentFirstName;
          }
          if (message.appointmentLastName != null && message.hasOwnProperty("appointmentLastName")) {
            object.appointmentLastName = message.appointmentLastName;
          }
          if (message.appointmentDayOfWeek != null && message.hasOwnProperty("appointmentDayOfWeek")) {
            object.appointmentDayOfWeek = message.appointmentDayOfWeek;
          }
          if (message.appointmentDayOfMonth != null && message.hasOwnProperty("appointmentDayOfMonth")) {
            object.appointmentDayOfMonth = message.appointmentDayOfMonth;
          }
          if (message.appointmentMonth != null && message.hasOwnProperty("appointmentMonth")) {
            object.appointmentMonth = message.appointmentMonth;
          }
          if (message.appointmentDate != null && message.hasOwnProperty("appointmentDate")) {
            object.appointmentDate = message.appointmentDate;
          }
          if (message.appointmentTime != null && message.hasOwnProperty("appointmentTime")) {
            object.appointmentTime = message.appointmentTime;
          }
          if (message.appointmentPhoneNumber != null && message.hasOwnProperty("appointmentPhoneNumber")) {
            object.appointmentPhoneNumber = message.appointmentPhoneNumber;
          }
          if (message.customerProvidedId != null && message.hasOwnProperty("customerProvidedId")) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (message.labels && message.labels.length) {
            object.labels = new Array(message.labels.length);
            for (let i = 0; i < message.labels.length; ++i) {
              object.labels[i] = message.labels[i];
            }
          }
          if (message.canceled != null && message.hasOwnProperty("canceled")) {
            object.canceled = message.canceled;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.Appointment";
        }
      }

      Appointment.prototype.appointmentId = "";
      Appointment.prototype.appointmentEmailAddress = null;
      Appointment.prototype.appointmentTypeId = null;
      Appointment.prototype.appointmentTypeTitle = null;
      Appointment.prototype.appointmentType = null;
      Appointment.prototype.appointmentLocationDetail = null;
      Appointment.prototype.appointmentZoomMeetingId = null;
      Appointment.prototype.appointmentZoomMeetingPassword = null;
      Appointment.prototype.appointmentProviderId = null;
      Appointment.prototype.appointmentProviderFullName = null;
      Appointment.prototype.appointmentDatetimeRaw = "";
      Appointment.prototype.appointmentDatetime = null;
      Appointment.prototype.appointmentEndDatetime = null;
      Appointment.prototype.appointmentLengthMinutes = null;
      Appointment.prototype.appointmentNotes = null;
      Appointment.prototype.appointmentCreatedAt = null;
      Appointment.prototype.appointmentCreatedBy = null;
      Appointment.prototype.appointmentTimezoneShortName = null;
      Appointment.prototype.appointmentTimezoneOffset = "";
      Appointment.prototype.appointmentTimezoneOffsetSecondsEastOfUtc = 0;
      Appointment.prototype.appointmentPatientTimezoneIdentifier = null;
      Appointment.prototype.appointmentConfirmationLink = null;
      Appointment.prototype.appointmentFirstName = null;
      Appointment.prototype.appointmentLastName = null;
      Appointment.prototype.appointmentDayOfWeek = "";
      Appointment.prototype.appointmentDayOfMonth = "";
      Appointment.prototype.appointmentMonth = "";
      Appointment.prototype.appointmentDate = "";
      Appointment.prototype.appointmentTime = "";
      Appointment.prototype.appointmentPhoneNumber = null;
      Appointment.prototype.customerProvidedId = null;
      Appointment.prototype.labels = $util.emptyArray;
      Appointment.prototype.canceled = false;

      return Appointment;
    })();

    v1.ListAppointmentTypesRequest = (() => {
      class ListAppointmentTypesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAppointmentTypesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.includeDeleted != null && Object.hasOwnProperty.call(message, "includeDeleted")) {
            writer.uint32(8).bool(message.includeDeleted);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAppointmentTypesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.includeDeleted = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.ListAppointmentTypesRequest) {
            return object;
          }
          const message = new $root.acuity.v1.ListAppointmentTypesRequest();
          if (object.includeDeleted != null) {
            message.includeDeleted = Boolean(object.includeDeleted);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.includeDeleted = false;
          }
          let keys;
          if (message.includeDeleted != null && message.hasOwnProperty("includeDeleted")) {
            object.includeDeleted = message.includeDeleted;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.ListAppointmentTypesRequest";
        }
      }

      ListAppointmentTypesRequest.prototype.includeDeleted = false;

      return ListAppointmentTypesRequest;
    })();

    v1.ListAppointmentTypesResponse = (() => {
      class ListAppointmentTypesResponse {
        constructor(properties) {
          this.appointmentTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAppointmentTypesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.appointmentTypes != null && Object.hasOwnProperty.call(message, "appointmentTypes")) {
            for (const element of message.appointmentTypes) {
              $root.acuity.v1.AppointmentType.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAppointmentTypesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.appointmentTypes || !message.appointmentTypes.length) {
                  message.appointmentTypes = [];
                }
                message.appointmentTypes.push($root.acuity.v1.AppointmentType.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.ListAppointmentTypesResponse) {
            return object;
          }
          const message = new $root.acuity.v1.ListAppointmentTypesResponse();
          if (object.appointmentTypes) {
            if (!Array.isArray(object.appointmentTypes)) {
              throw new TypeError(".acuity.v1.ListAppointmentTypesResponse.appointmentTypes: array type expected, but got " + (typeof object.appointmentTypes))
            }
            message.appointmentTypes = new Array(object.appointmentTypes.length);
            for (let i = 0; i < object.appointmentTypes.length; ++i) {
              if (typeof object.appointmentTypes[i] !== "object") {
                throw new TypeError(".acuity.v1.ListAppointmentTypesResponse.appointmentTypes: object expected, but got " + (typeof object.appointmentTypes[i]));
              }
              message.appointmentTypes[i] = $root.acuity.v1.AppointmentType.fromObject(object.appointmentTypes[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.appointmentTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.appointmentTypes && message.appointmentTypes.length) {
            object.appointmentTypes = new Array(message.appointmentTypes.length);
            for (let i = 0; i < message.appointmentTypes.length; ++i) {
              object.appointmentTypes[i] = $root.acuity.v1.AppointmentType.toObject(message.appointmentTypes[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.ListAppointmentTypesResponse";
        }
      }

      ListAppointmentTypesResponse.prototype.appointmentTypes = $util.emptyArray;

      return ListAppointmentTypesResponse;
    })();

    v1.GetAppointmentRequest = (() => {
      class GetAppointmentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.GetAppointmentRequest) {
            return object;
          }
          const message = new $root.acuity.v1.GetAppointmentRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.GetAppointmentRequest";
        }
      }

      GetAppointmentRequest.prototype.id = "";

      return GetAppointmentRequest;
    })();

    v1.GetAppointmentResponse = (() => {
      class GetAppointmentResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.appointment != null && Object.hasOwnProperty.call(message, "appointment")) {
            $root.acuity.v1.Appointment.encode(message.appointment, writer.uint32(10).fork()).ldelim();
          }
          if (message.canceled != null && Object.hasOwnProperty.call(message, "canceled")) {
            writer.uint32(16).bool(message.canceled);
          }
          if (message.noShow != null && Object.hasOwnProperty.call(message, "noShow")) {
            writer.uint32(24).bool(message.noShow);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointment = $root.acuity.v1.Appointment.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.canceled = reader.bool();
                break;
              }
              case 3: {
                message.noShow = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.GetAppointmentResponse) {
            return object;
          }
          const message = new $root.acuity.v1.GetAppointmentResponse();
          if (object.appointment != null) {
            if (typeof object.appointment !== "object") {
              throw new TypeError(".acuity.v1.GetAppointmentResponse.appointment: object expected, but got " + (typeof object.appointment));
            }
            message.appointment = $root.acuity.v1.Appointment.fromObject(object.appointment);
          }
          if (object.canceled != null) {
            message.canceled = Boolean(object.canceled);
          }
          if (object.noShow != null) {
            message.noShow = Boolean(object.noShow);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointment = null;
            object.canceled = false;
            object.noShow = false;
          }
          let keys;
          if (message.appointment != null && message.hasOwnProperty("appointment")) {
            object.appointment = $root.acuity.v1.Appointment.toObject(message.appointment, options);
          }
          if (message.canceled != null && message.hasOwnProperty("canceled")) {
            object.canceled = message.canceled;
          }
          if (message.noShow != null && message.hasOwnProperty("noShow")) {
            object.noShow = message.noShow;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.GetAppointmentResponse";
        }
      }

      GetAppointmentResponse.prototype.appointment = null;
      GetAppointmentResponse.prototype.canceled = false;
      GetAppointmentResponse.prototype.noShow = false;

      return GetAppointmentResponse;
    })();

    v1.UpdateAppointmentLabelRequest = (() => {
      class UpdateAppointmentLabelRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateAppointmentLabelRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.labelId != null && Object.hasOwnProperty.call(message, "labelId")) {
            writer.uint32(16).int64(message.labelId);
          }
          if (message.labelName != null && Object.hasOwnProperty.call(message, "labelName")) {
            writer.uint32(26).string(message.labelName);
          }
          if (message.labelColor != null && Object.hasOwnProperty.call(message, "labelColor")) {
            writer.uint32(34).string(message.labelColor);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateAppointmentLabelRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.labelId = reader.int64();
                break;
              }
              case 3: {
                message.labelName = reader.string();
                break;
              }
              case 4: {
                message.labelColor = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.UpdateAppointmentLabelRequest) {
            return object;
          }
          const message = new $root.acuity.v1.UpdateAppointmentLabelRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.labelId != null) {
            if ($util.Long) {
              (message.labelId = $util.Long.fromValue(object.labelId)).unsigned = false;
            } else if (typeof object.labelId === "string") {
              message.labelId = parseInt(object.labelId, 10);
            } else if (typeof object.labelId === "number") {
              message.labelId = object.labelId;
            } else if (typeof object.labelId === "object") {
              message.labelId = new $util.LongBits(object.labelId.low >>> 0, object.labelId.high >>> 0).toNumber();
            }
          }
          if (object.labelName != null) {
            message.labelName = String(object.labelName);
          }
          if (object.labelColor != null) {
            message.labelColor = String(object.labelColor);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.labelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.labelId = options.longs === String ? "0" : 0;
            }
            object.labelName = "";
            object.labelColor = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.labelId != null && message.hasOwnProperty("labelId")) {
            object.labelId = typeof message.labelId === "number" ? (options.longs === String ? String(message.labelId) : message.labelId) : (options.longs === String ? $util.Long.prototype.toString.call(message.labelId) : options.longs === Number ? new $util.LongBits(message.labelId.low >>> 0, message.labelId.high >>> 0).toNumber() : message.labelId);
          }
          if (message.labelName != null && message.hasOwnProperty("labelName")) {
            object.labelName = message.labelName;
          }
          if (message.labelColor != null && message.hasOwnProperty("labelColor")) {
            object.labelColor = message.labelColor;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.UpdateAppointmentLabelRequest";
        }
      }

      UpdateAppointmentLabelRequest.prototype.id = "";
      UpdateAppointmentLabelRequest.prototype.labelId = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      UpdateAppointmentLabelRequest.prototype.labelName = "";
      UpdateAppointmentLabelRequest.prototype.labelColor = "";

      return UpdateAppointmentLabelRequest;
    })();

    v1.UpdateAppointmentLabelResponse = (() => {
      class UpdateAppointmentLabelResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateAppointmentLabelResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.statusCode != null && Object.hasOwnProperty.call(message, "statusCode")) {
            writer.uint32(8).int32(message.statusCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateAppointmentLabelResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.statusCode = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.acuity.v1.UpdateAppointmentLabelResponse) {
            return object;
          }
          const message = new $root.acuity.v1.UpdateAppointmentLabelResponse();
          if (object.statusCode != null) {
            message.statusCode = object.statusCode | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.statusCode = 0;
          }
          let keys;
          if (message.statusCode != null && message.hasOwnProperty("statusCode")) {
            object.statusCode = message.statusCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "acuity.v1.UpdateAppointmentLabelResponse";
        }
      }

      UpdateAppointmentLabelResponse.prototype.statusCode = 0;

      return UpdateAppointmentLabelResponse;
    })();

    v1.AcuityEventType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE"] = 0;
      values[valuesById[1] = "ACUITY_APPOINTMENT_SCHEDULED"] = 1;
      values[valuesById[2] = "ACUITY_APPOINTMENT_RESCHEDULED"] = 2;
      values[valuesById[3] = "ACUITY_APPOINTMENT_CANCELLED"] = 3;
      values[valuesById[4] = "ACUITY_APPOINTMENT_UPDATED"] = 4;
      values[valuesById[5] = "ACUITY_APPOINTMENT_NO_SHOW"] = 5;
      return values;
    })();

    v1.AcuityService = (() => {
      class AcuityService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new AcuityService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(AcuityService.prototype.listAppointmentTypes = function listAppointmentTypes(request, callback) {
        return this.rpcCall(listAppointmentTypes, $root.acuity.v1.ListAppointmentTypesRequest, $root.acuity.v1.ListAppointmentTypesResponse, request, callback);
      }, "name", { value: "ListAppointmentTypes" });

      Object.defineProperty(AcuityService.prototype.getAppointment = function getAppointment(request, callback) {
        return this.rpcCall(getAppointment, $root.acuity.v1.GetAppointmentRequest, $root.acuity.v1.GetAppointmentResponse, request, callback);
      }, "name", { value: "GetAppointment" });

      Object.defineProperty(AcuityService.prototype.updateAppointmentLabel = function updateAppointmentLabel(request, callback) {
        return this.rpcCall(updateAppointmentLabel, $root.acuity.v1.UpdateAppointmentLabelRequest, $root.acuity.v1.UpdateAppointmentLabelResponse, request, callback);
      }, "name", { value: "UpdateAppointmentLabel" });

      return AcuityService;
    })();

    return v1;
  })();

  return acuity;
})();

export const acuity = $root.acuity;
