import { styled } from '@morf/theming';

export const LoaderContainer = styled.div<
  { 'data-testid'?: string } & React.HTMLAttributes<HTMLDivElement>
>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
