"use strict";

import * as $protobuf from "protobufjs/minimal";
import { timing as timing$1 } from "./timing_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.twilio = (() => {
  const twilio = {};
  twilio.v1 = (() => {
    const v1 = {};
    v1.MessageSent = (() => {
      class MessageSent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MessageSent(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.dateCreated != null && Object.hasOwnProperty.call(message, "dateCreated")) {
            timing$1.v1.Timestamp.encode(message.dateCreated, writer.uint32(10).fork()).ldelim();
          }
          if (message.dateSent != null && Object.hasOwnProperty.call(message, "dateSent")) {
            timing$1.v1.Timestamp.encode(message.dateSent, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MessageSent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.dateCreated = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.dateSent = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.twilio.v1.MessageSent) {
            return object;
          }
          const message = new $root.twilio.v1.MessageSent();
          if (object.dateCreated != null) {
            if (typeof object.dateCreated !== "object") {
              throw new TypeError(".twilio.v1.MessageSent.dateCreated: object expected, but got " + (typeof object.dateCreated));
            }
            message.dateCreated = timing$1.v1.Timestamp.fromObject(object.dateCreated);
          }
          if (object.dateSent != null) {
            if (typeof object.dateSent !== "object") {
              throw new TypeError(".twilio.v1.MessageSent.dateSent: object expected, but got " + (typeof object.dateSent));
            }
            message.dateSent = timing$1.v1.Timestamp.fromObject(object.dateSent);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.dateCreated != null && message.hasOwnProperty("dateCreated")) {
            object.dateCreated = timing$1.v1.Timestamp.toObject(message.dateCreated, options);
          }
          if (message.dateSent != null && message.hasOwnProperty("dateSent")) {
            object.dateSent = timing$1.v1.Timestamp.toObject(message.dateSent, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "twilio.v1.MessageSent";
        }
      }

      MessageSent.prototype.dateCreated = null;
      MessageSent.prototype.dateSent = null;

      return MessageSent;
    })();

    return v1;
  })();

  return twilio;
})();

export const twilio = $root.twilio;
