"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./empty_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.thirdparties = (() => {
  const thirdparties = {};
  thirdparties.v1 = (() => {
    const v1 = {};
    v1.ThirdPartyIdTypeAnnotation = (() => {
      class ThirdPartyIdTypeAnnotation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ThirdPartyIdTypeAnnotation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartyIdType != null && Object.hasOwnProperty.call(message, "thirdPartyIdType")) {
            writer.uint32(8).int32(message.thirdPartyIdType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ThirdPartyIdTypeAnnotation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartyIdType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.ThirdPartyIdTypeAnnotation) {
            return object;
          }
          const message = new $root.thirdparties.v1.ThirdPartyIdTypeAnnotation();
          if (object.thirdPartyIdType != null) {
            switch (object.thirdPartyIdType) {
              case "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION":
              case 0: {
                message.thirdPartyIdType = 0;
                break;
              }
              case "CUSTOMER":
              case 1: {
                message.thirdPartyIdType = 1;
                break;
              }
              case "FORMSORT":
              case 2: {
                message.thirdPartyIdType = 2;
                break;
              }
              case "HEALTHIE":
              case 3: {
                message.thirdPartyIdType = 3;
                break;
              }
              case "AXLE_HEALTH":
              case 4: {
                message.thirdPartyIdType = 4;
                break;
              }
              case "BUTTERFLY_LABS":
              case 5: {
                message.thirdPartyIdType = 5;
                break;
              }
              case "RECURLY":
              case 6: {
                message.thirdPartyIdType = 6;
                break;
              }
              case "INTERCOM":
              case 7: {
                message.thirdPartyIdType = 7;
                break;
              }
              case "SANA_BENEFITS":
              case 8: {
                message.thirdPartyIdType = 8;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 9: {
                message.thirdPartyIdType = 9;
                break;
              }
              case "VITAL":
              case 10: {
                message.thirdPartyIdType = 10;
                break;
              }
              case "SEGMENT":
              case 11: {
                message.thirdPartyIdType = 11;
                break;
              }
              case "INTAKEQ":
              case 12: {
                message.thirdPartyIdType = 12;
                break;
              }
              case "CUSTOMER_IO":
              case 13: {
                message.thirdPartyIdType = 13;
                break;
              }
              case "FRESHDESK":
              case 14: {
                message.thirdPartyIdType = 14;
                break;
              }
              case "HUBSPOT":
              case 15: {
                message.thirdPartyIdType = 15;
                break;
              }
              default: {
                if (typeof object.thirdPartyIdType == "number") {
                  message.thirdPartyIdType = object.thirdPartyIdType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartyIdType = options.enums === String ? "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION" : 0
          }
          let keys;
          if (message.thirdPartyIdType != null && message.hasOwnProperty("thirdPartyIdType")) {
            object.thirdPartyIdType = options.enums === String ? $root.thirdparties.v1.ThirdPartyIdType[message.thirdPartyIdType] === undefined ? message.thirdPartyIdType : $root.thirdparties.v1.ThirdPartyIdType[message.thirdPartyIdType] : message.thirdPartyIdType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.ThirdPartyIdTypeAnnotation";
        }
      }

      ThirdPartyIdTypeAnnotation.prototype.thirdPartyIdType = 0;

      return ThirdPartyIdTypeAnnotation;
    })();

    v1.ThirdPartySecretData = (() => {
      class ThirdPartySecretData {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ThirdPartySecretData(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartySecretType != null && Object.hasOwnProperty.call(message, "thirdPartySecretType")) {
            writer.uint32(8).int32(message.thirdPartySecretType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ThirdPartySecretData();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartySecretType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.ThirdPartySecretData) {
            return object;
          }
          const message = new $root.thirdparties.v1.ThirdPartySecretData();
          if (object.thirdPartySecretType != null) {
            switch (object.thirdPartySecretType) {
              case "UNSPECIFIED_ILLEGAL":
              case 0: {
                message.thirdPartySecretType = 0;
                break;
              }
              case "FORMSORT_API_KEY":
              case 1: {
                message.thirdPartySecretType = 1;
                break;
              }
              case "SEGMENT_WRITE_KEY":
              case 2: {
                message.thirdPartySecretType = 2;
                break;
              }
              case "HEALTHIE_STAGING_API_KEY":
              case 3: {
                message.thirdPartySecretType = 3;
                break;
              }
              case "HEALTHIE_PRODUCTION_API_KEY":
              case 4: {
                message.thirdPartySecretType = 4;
                break;
              }
              case "AXLE_HEALTH_DEVELOPMENT_API_KEY":
              case 5: {
                message.thirdPartySecretType = 5;
                break;
              }
              case "AXLE_HEALTH_PRODUCTION_API_KEY":
              case 6: {
                message.thirdPartySecretType = 6;
                break;
              }
              case "BUTTERFLY_LABS_API_KEY":
              case 7: {
                message.thirdPartySecretType = 7;
                break;
              }
              case "FRESHPAINT_SOURCE_TOKEN":
              case 10: {
                message.thirdPartySecretType = 10;
                break;
              }
              case "ACTIVE_CAMPAIGN_CONTACT_SECRET":
              case 11: {
                message.thirdPartySecretType = 11;
                break;
              }
              case "ACTIVE_CAMPAIGN_EVENT_TRACKING_SECRET":
              case 12: {
                message.thirdPartySecretType = 12;
                break;
              }
              case "MIGA_CLIENT_ID_AND_SECRET":
              case 13: {
                message.thirdPartySecretType = 13;
                break;
              }
              case "ACUITY_USER_ID_AND_SECRET":
              case 14: {
                message.thirdPartySecretType = 14;
                break;
              }
              case "VITAL_STAGING_API_KEY":
              case 15: {
                message.thirdPartySecretType = 15;
                break;
              }
              case "VITAL_PRODUCTION_API_KEY":
              case 16: {
                message.thirdPartySecretType = 16;
                break;
              }
              case "INTAKEQ_API_KEY":
              case 17: {
                message.thirdPartySecretType = 17;
                break;
              }
              case "SANA_SFTP":
              case 18: {
                message.thirdPartySecretType = 18;
                break;
              }
              case "CUSTOMERIO_TRACK_API_KEY":
              case 19: {
                message.thirdPartySecretType = 19;
                break;
              }
              case "CUSTOMERIO_APP_API_KEY":
              case 20: {
                message.thirdPartySecretType = 20;
                break;
              }
              case "SPRUCE_API_KEY":
              case 21: {
                message.thirdPartySecretType = 21;
                break;
              }
              case "FRESHDESK_API_KEY":
              case 22: {
                message.thirdPartySecretType = 22;
                break;
              }
              case "HUBSPOT_APP_ACCESS_TOKEN":
              case 23: {
                message.thirdPartySecretType = 23;
                break;
              }
              case "INTERCOM_ACCESS_TOKEN":
              case 24: {
                message.thirdPartySecretType = 24;
                break;
              }
              case "FACEBOOK_CONVERSIONS_ACCESS_TOKEN":
              case 25: {
                message.thirdPartySecretType = 25;
                break;
              }
              case "GOOGLE_ADS_CUSTOMER_ID":
              case 26: {
                message.thirdPartySecretType = 26;
                break;
              }
              case "TIKTOK_EVENTS_ACCESS_TOKEN":
              case 27: {
                message.thirdPartySecretType = 27;
                break;
              }
              case "TWILIO_SUBACCOUNT_AUTH_TOKEN":
              case 28: {
                message.thirdPartySecretType = 28;
                break;
              }
              default: {
                if (typeof object.thirdPartySecretType == "number") {
                  message.thirdPartySecretType = object.thirdPartySecretType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartySecretType = options.enums === String ? "UNSPECIFIED_ILLEGAL" : 0
          }
          let keys;
          if (message.thirdPartySecretType != null && message.hasOwnProperty("thirdPartySecretType")) {
            object.thirdPartySecretType = options.enums === String ? $root.thirdparties.v1.ThirdPartySecretType[message.thirdPartySecretType] === undefined ? message.thirdPartySecretType : $root.thirdparties.v1.ThirdPartySecretType[message.thirdPartySecretType] : message.thirdPartySecretType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.ThirdPartySecretData";
        }
      }

      ThirdPartySecretData.prototype.thirdPartySecretType = 0;

      return ThirdPartySecretData;
    })();

    v1.CreateThirdPartySecretRequest = (() => {
      class CreateThirdPartySecretRequest {
        get secret() {
          for (const key of ["plaintext", "clientIdAndSecret", "sftpConfig", "backendProvisioned"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set secret(name) {
          for (const key of ["plaintext", "clientIdAndSecret", "sftpConfig", "backendProvisioned"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateThirdPartySecretRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartySecretType != null && Object.hasOwnProperty.call(message, "thirdPartySecretType")) {
            writer.uint32(8).int32(message.thirdPartySecretType);
          }
          if (message.plaintext != null && Object.hasOwnProperty.call(message, "plaintext")) {
            writer.uint32(18).string(message.plaintext);
          }
          if (message.clientIdAndSecret != null && Object.hasOwnProperty.call(message, "clientIdAndSecret")) {
            $root.thirdparties.v1.ClientIdAndSecret.encode(message.clientIdAndSecret, writer.uint32(26).fork()).ldelim();
          }
          if (message.sftpConfig != null && Object.hasOwnProperty.call(message, "sftpConfig")) {
            $root.thirdparties.v1.SftpConfig.encode(message.sftpConfig, writer.uint32(34).fork()).ldelim();
          }
          if (message.backendProvisioned != null && Object.hasOwnProperty.call(message, "backendProvisioned")) {
            google$1.protobuf.Empty.encode(message.backendProvisioned, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateThirdPartySecretRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartySecretType = reader.int32();
                break;
              }
              case 2: {
                message.plaintext = reader.string();
                break;
              }
              case 3: {
                message.clientIdAndSecret = $root.thirdparties.v1.ClientIdAndSecret.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.sftpConfig = $root.thirdparties.v1.SftpConfig.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.backendProvisioned = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.CreateThirdPartySecretRequest) {
            return object;
          }
          const message = new $root.thirdparties.v1.CreateThirdPartySecretRequest();
          if (object.thirdPartySecretType != null) {
            switch (object.thirdPartySecretType) {
              case "UNSPECIFIED_ILLEGAL":
              case 0: {
                message.thirdPartySecretType = 0;
                break;
              }
              case "FORMSORT_API_KEY":
              case 1: {
                message.thirdPartySecretType = 1;
                break;
              }
              case "SEGMENT_WRITE_KEY":
              case 2: {
                message.thirdPartySecretType = 2;
                break;
              }
              case "HEALTHIE_STAGING_API_KEY":
              case 3: {
                message.thirdPartySecretType = 3;
                break;
              }
              case "HEALTHIE_PRODUCTION_API_KEY":
              case 4: {
                message.thirdPartySecretType = 4;
                break;
              }
              case "AXLE_HEALTH_DEVELOPMENT_API_KEY":
              case 5: {
                message.thirdPartySecretType = 5;
                break;
              }
              case "AXLE_HEALTH_PRODUCTION_API_KEY":
              case 6: {
                message.thirdPartySecretType = 6;
                break;
              }
              case "BUTTERFLY_LABS_API_KEY":
              case 7: {
                message.thirdPartySecretType = 7;
                break;
              }
              case "FRESHPAINT_SOURCE_TOKEN":
              case 10: {
                message.thirdPartySecretType = 10;
                break;
              }
              case "ACTIVE_CAMPAIGN_CONTACT_SECRET":
              case 11: {
                message.thirdPartySecretType = 11;
                break;
              }
              case "ACTIVE_CAMPAIGN_EVENT_TRACKING_SECRET":
              case 12: {
                message.thirdPartySecretType = 12;
                break;
              }
              case "MIGA_CLIENT_ID_AND_SECRET":
              case 13: {
                message.thirdPartySecretType = 13;
                break;
              }
              case "ACUITY_USER_ID_AND_SECRET":
              case 14: {
                message.thirdPartySecretType = 14;
                break;
              }
              case "VITAL_STAGING_API_KEY":
              case 15: {
                message.thirdPartySecretType = 15;
                break;
              }
              case "VITAL_PRODUCTION_API_KEY":
              case 16: {
                message.thirdPartySecretType = 16;
                break;
              }
              case "INTAKEQ_API_KEY":
              case 17: {
                message.thirdPartySecretType = 17;
                break;
              }
              case "SANA_SFTP":
              case 18: {
                message.thirdPartySecretType = 18;
                break;
              }
              case "CUSTOMERIO_TRACK_API_KEY":
              case 19: {
                message.thirdPartySecretType = 19;
                break;
              }
              case "CUSTOMERIO_APP_API_KEY":
              case 20: {
                message.thirdPartySecretType = 20;
                break;
              }
              case "SPRUCE_API_KEY":
              case 21: {
                message.thirdPartySecretType = 21;
                break;
              }
              case "FRESHDESK_API_KEY":
              case 22: {
                message.thirdPartySecretType = 22;
                break;
              }
              case "HUBSPOT_APP_ACCESS_TOKEN":
              case 23: {
                message.thirdPartySecretType = 23;
                break;
              }
              case "INTERCOM_ACCESS_TOKEN":
              case 24: {
                message.thirdPartySecretType = 24;
                break;
              }
              case "FACEBOOK_CONVERSIONS_ACCESS_TOKEN":
              case 25: {
                message.thirdPartySecretType = 25;
                break;
              }
              case "GOOGLE_ADS_CUSTOMER_ID":
              case 26: {
                message.thirdPartySecretType = 26;
                break;
              }
              case "TIKTOK_EVENTS_ACCESS_TOKEN":
              case 27: {
                message.thirdPartySecretType = 27;
                break;
              }
              case "TWILIO_SUBACCOUNT_AUTH_TOKEN":
              case 28: {
                message.thirdPartySecretType = 28;
                break;
              }
              default: {
                if (typeof object.thirdPartySecretType == "number") {
                  message.thirdPartySecretType = object.thirdPartySecretType;
                  break;
                }
                break;
              }
            }
          }
          if (object.plaintext != null) {
            message.plaintext = String(object.plaintext);
          }
          if (object.clientIdAndSecret != null) {
            if (typeof object.clientIdAndSecret !== "object") {
              throw new TypeError(".thirdparties.v1.CreateThirdPartySecretRequest.clientIdAndSecret: object expected, but got " + (typeof object.clientIdAndSecret));
            }
            message.clientIdAndSecret = $root.thirdparties.v1.ClientIdAndSecret.fromObject(object.clientIdAndSecret);
          }
          if (object.sftpConfig != null) {
            if (typeof object.sftpConfig !== "object") {
              throw new TypeError(".thirdparties.v1.CreateThirdPartySecretRequest.sftpConfig: object expected, but got " + (typeof object.sftpConfig));
            }
            message.sftpConfig = $root.thirdparties.v1.SftpConfig.fromObject(object.sftpConfig);
          }
          if (object.backendProvisioned != null) {
            if (typeof object.backendProvisioned !== "object") {
              throw new TypeError(".thirdparties.v1.CreateThirdPartySecretRequest.backendProvisioned: object expected, but got " + (typeof object.backendProvisioned));
            }
            message.backendProvisioned = google$1.protobuf.Empty.fromObject(object.backendProvisioned);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartySecretType = options.enums === String ? "UNSPECIFIED_ILLEGAL" : 0
          }
          let keys;
          if (message.thirdPartySecretType != null && message.hasOwnProperty("thirdPartySecretType")) {
            object.thirdPartySecretType = options.enums === String ? $root.thirdparties.v1.ThirdPartySecretType[message.thirdPartySecretType] === undefined ? message.thirdPartySecretType : $root.thirdparties.v1.ThirdPartySecretType[message.thirdPartySecretType] : message.thirdPartySecretType;
          }
          if (message.plaintext != null && message.hasOwnProperty("plaintext")) {
            object.plaintext = message.plaintext;
            if (options.oneofs) {
              object.secret = "plaintext";
            }
          }
          if (message.clientIdAndSecret != null && message.hasOwnProperty("clientIdAndSecret")) {
            object.clientIdAndSecret = $root.thirdparties.v1.ClientIdAndSecret.toObject(message.clientIdAndSecret, options);
            if (options.oneofs) {
              object.secret = "clientIdAndSecret";
            }
          }
          if (message.sftpConfig != null && message.hasOwnProperty("sftpConfig")) {
            object.sftpConfig = $root.thirdparties.v1.SftpConfig.toObject(message.sftpConfig, options);
            if (options.oneofs) {
              object.secret = "sftpConfig";
            }
          }
          if (message.backendProvisioned != null && message.hasOwnProperty("backendProvisioned")) {
            object.backendProvisioned = google$1.protobuf.Empty.toObject(message.backendProvisioned, options);
            if (options.oneofs) {
              object.secret = "backendProvisioned";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.CreateThirdPartySecretRequest";
        }
      }

      CreateThirdPartySecretRequest.prototype.thirdPartySecretType = 0;
      CreateThirdPartySecretRequest.prototype.plaintext = "";
      CreateThirdPartySecretRequest.prototype.clientIdAndSecret = null;
      CreateThirdPartySecretRequest.prototype.sftpConfig = null;
      CreateThirdPartySecretRequest.prototype.backendProvisioned = null;

      return CreateThirdPartySecretRequest;
    })();

    v1.SftpConfig = (() => {
      class SftpConfig {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SftpConfig(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.host != null && Object.hasOwnProperty.call(message, "host")) {
            writer.uint32(10).string(message.host);
          }
          if (message.privateKey != null && Object.hasOwnProperty.call(message, "privateKey")) {
            $root.thirdparties.v1.SftpConfig.PrivateKeyAuth.encode(message.privateKey, writer.uint32(18).fork()).ldelim();
          }
          if (message.userAuth != null && Object.hasOwnProperty.call(message, "userAuth")) {
            $root.thirdparties.v1.SftpConfig.UserPasswordAuth.encode(message.userAuth, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SftpConfig();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.host = reader.string();
                break;
              }
              case 2: {
                message.privateKey = $root.thirdparties.v1.SftpConfig.PrivateKeyAuth.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.userAuth = $root.thirdparties.v1.SftpConfig.UserPasswordAuth.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.SftpConfig) {
            return object;
          }
          const message = new $root.thirdparties.v1.SftpConfig();
          if (object.host != null) {
            message.host = String(object.host);
          }
          if (object.privateKey != null) {
            if (typeof object.privateKey !== "object") {
              throw new TypeError(".thirdparties.v1.SftpConfig.privateKey: object expected, but got " + (typeof object.privateKey));
            }
            message.privateKey = $root.thirdparties.v1.SftpConfig.PrivateKeyAuth.fromObject(object.privateKey);
          }
          if (object.userAuth != null) {
            if (typeof object.userAuth !== "object") {
              throw new TypeError(".thirdparties.v1.SftpConfig.userAuth: object expected, but got " + (typeof object.userAuth));
            }
            message.userAuth = $root.thirdparties.v1.SftpConfig.UserPasswordAuth.fromObject(object.userAuth);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.host = "";
            object.userAuth = null;
          }
          let keys;
          if (message.host != null && message.hasOwnProperty("host")) {
            object.host = message.host;
          }
          if (message.privateKey != null && message.hasOwnProperty("privateKey")) {
            object.privateKey = $root.thirdparties.v1.SftpConfig.PrivateKeyAuth.toObject(message.privateKey, options);
          }
          if (message.userAuth != null && message.hasOwnProperty("userAuth")) {
            object.userAuth = $root.thirdparties.v1.SftpConfig.UserPasswordAuth.toObject(message.userAuth, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.SftpConfig";
        }
      }

      SftpConfig.prototype.host = "";
      SftpConfig.prototype.privateKey = null;
      SftpConfig.prototype.userAuth = null;
      SftpConfig.PrivateKeyAuth = (() => {
        class PrivateKeyAuth {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new PrivateKeyAuth(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.sftpKeyBase64 != null && Object.hasOwnProperty.call(message, "sftpKeyBase64")) {
              writer.uint32(10).string(message.sftpKeyBase64);
            }
            if (message.passphrasePlaintext != null && Object.hasOwnProperty.call(message, "passphrasePlaintext")) {
              writer.uint32(18).string(message.passphrasePlaintext);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new PrivateKeyAuth();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sftpKeyBase64 = reader.string();
                  break;
                }
                case 2: {
                  message.passphrasePlaintext = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.thirdparties.v1.SftpConfig.PrivateKeyAuth) {
              return object;
            }
            const message = new $root.thirdparties.v1.SftpConfig.PrivateKeyAuth();
            if (object.sftpKeyBase64 != null) {
              message.sftpKeyBase64 = String(object.sftpKeyBase64);
            }
            if (object.passphrasePlaintext != null) {
              message.passphrasePlaintext = String(object.passphrasePlaintext);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.sftpKeyBase64 = "";
            }
            let keys;
            if (message.sftpKeyBase64 != null && message.hasOwnProperty("sftpKeyBase64")) {
              object.sftpKeyBase64 = message.sftpKeyBase64;
            }
            if (message.passphrasePlaintext != null && message.hasOwnProperty("passphrasePlaintext")) {
              object.passphrasePlaintext = message.passphrasePlaintext;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "thirdparties.v1.SftpConfig.PrivateKeyAuth";
          }
        }

        PrivateKeyAuth.prototype.sftpKeyBase64 = "";
        PrivateKeyAuth.prototype.passphrasePlaintext = null;

        return PrivateKeyAuth;
      })();

      SftpConfig.UserPasswordAuth = (() => {
        class UserPasswordAuth {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UserPasswordAuth(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user")) {
              writer.uint32(10).string(message.user);
            }
            if (message.passwordPlaintext != null && Object.hasOwnProperty.call(message, "passwordPlaintext")) {
              writer.uint32(18).string(message.passwordPlaintext);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UserPasswordAuth();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.user = reader.string();
                  break;
                }
                case 2: {
                  message.passwordPlaintext = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.thirdparties.v1.SftpConfig.UserPasswordAuth) {
              return object;
            }
            const message = new $root.thirdparties.v1.SftpConfig.UserPasswordAuth();
            if (object.user != null) {
              message.user = String(object.user);
            }
            if (object.passwordPlaintext != null) {
              message.passwordPlaintext = String(object.passwordPlaintext);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.user = "";
            }
            let keys;
            if (message.user != null && message.hasOwnProperty("user")) {
              object.user = message.user;
            }
            if (message.passwordPlaintext != null && message.hasOwnProperty("passwordPlaintext")) {
              object.passwordPlaintext = message.passwordPlaintext;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "thirdparties.v1.SftpConfig.UserPasswordAuth";
          }
        }

        UserPasswordAuth.prototype.user = "";
        UserPasswordAuth.prototype.passwordPlaintext = null;

        return UserPasswordAuth;
      })();

      return SftpConfig;
    })();

    v1.ClientIdAndSecret = (() => {
      class ClientIdAndSecret {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ClientIdAndSecret(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId")) {
            writer.uint32(10).string(message.clientId);
          }
          if (message.secretPlaintext != null && Object.hasOwnProperty.call(message, "secretPlaintext")) {
            writer.uint32(18).string(message.secretPlaintext);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ClientIdAndSecret();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.clientId = reader.string();
                break;
              }
              case 2: {
                message.secretPlaintext = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.ClientIdAndSecret) {
            return object;
          }
          const message = new $root.thirdparties.v1.ClientIdAndSecret();
          if (object.clientId != null) {
            message.clientId = String(object.clientId);
          }
          if (object.secretPlaintext != null) {
            message.secretPlaintext = String(object.secretPlaintext);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.clientId = "";
            object.secretPlaintext = "";
          }
          let keys;
          if (message.clientId != null && message.hasOwnProperty("clientId")) {
            object.clientId = message.clientId;
          }
          if (message.secretPlaintext != null && message.hasOwnProperty("secretPlaintext")) {
            object.secretPlaintext = message.secretPlaintext;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.ClientIdAndSecret";
        }
      }

      ClientIdAndSecret.prototype.clientId = "";
      ClientIdAndSecret.prototype.secretPlaintext = "";

      return ClientIdAndSecret;
    })();

    v1.TwilioSecret = (() => {
      class TwilioSecret {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TwilioSecret(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.accountSid != null && Object.hasOwnProperty.call(message, "accountSid")) {
            writer.uint32(10).string(message.accountSid);
          }
          if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken")) {
            writer.uint32(18).string(message.authToken);
          }
          if (message.messagingServiceId != null && Object.hasOwnProperty.call(message, "messagingServiceId")) {
            writer.uint32(26).string(message.messagingServiceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TwilioSecret();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.accountSid = reader.string();
                break;
              }
              case 2: {
                message.authToken = reader.string();
                break;
              }
              case 3: {
                message.messagingServiceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.TwilioSecret) {
            return object;
          }
          const message = new $root.thirdparties.v1.TwilioSecret();
          if (object.accountSid != null) {
            message.accountSid = String(object.accountSid);
          }
          if (object.authToken != null) {
            message.authToken = String(object.authToken);
          }
          if (object.messagingServiceId != null) {
            message.messagingServiceId = String(object.messagingServiceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.accountSid = "";
            object.authToken = "";
            object.messagingServiceId = "";
          }
          let keys;
          if (message.accountSid != null && message.hasOwnProperty("accountSid")) {
            object.accountSid = message.accountSid;
          }
          if (message.authToken != null && message.hasOwnProperty("authToken")) {
            object.authToken = message.authToken;
          }
          if (message.messagingServiceId != null && message.hasOwnProperty("messagingServiceId")) {
            object.messagingServiceId = message.messagingServiceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.TwilioSecret";
        }
      }

      TwilioSecret.prototype.accountSid = "";
      TwilioSecret.prototype.authToken = "";
      TwilioSecret.prototype.messagingServiceId = "";

      return TwilioSecret;
    })();

    v1.CreateThirdPartySecretResponse = (() => {
      class CreateThirdPartySecretResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateThirdPartySecretResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateThirdPartySecretResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.CreateThirdPartySecretResponse) {
            return object;
          }
          const message = new $root.thirdparties.v1.CreateThirdPartySecretResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.CreateThirdPartySecretResponse";
        }
      }

      return CreateThirdPartySecretResponse;
    })();

    v1.Secret = (() => {
      class Secret {
        get type() {
          for (const key of ["plaintext", "clientIdAndSecret", "sftpConfig", "twilioSecret"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["plaintext", "clientIdAndSecret", "sftpConfig", "twilioSecret"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Secret(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartySecretType != null && Object.hasOwnProperty.call(message, "thirdPartySecretType")) {
            writer.uint32(8).int32(message.thirdPartySecretType);
          }
          if (message.plaintext != null && Object.hasOwnProperty.call(message, "plaintext")) {
            writer.uint32(18).string(message.plaintext);
          }
          if (message.clientIdAndSecret != null && Object.hasOwnProperty.call(message, "clientIdAndSecret")) {
            $root.thirdparties.v1.ClientIdAndSecret.encode(message.clientIdAndSecret, writer.uint32(26).fork()).ldelim();
          }
          if (message.sftpConfig != null && Object.hasOwnProperty.call(message, "sftpConfig")) {
            $root.thirdparties.v1.SftpConfig.encode(message.sftpConfig, writer.uint32(34).fork()).ldelim();
          }
          if (message.twilioSecret != null && Object.hasOwnProperty.call(message, "twilioSecret")) {
            $root.thirdparties.v1.TwilioSecret.encode(message.twilioSecret, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Secret();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartySecretType = reader.int32();
                break;
              }
              case 2: {
                message.plaintext = reader.string();
                break;
              }
              case 3: {
                message.clientIdAndSecret = $root.thirdparties.v1.ClientIdAndSecret.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.sftpConfig = $root.thirdparties.v1.SftpConfig.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.twilioSecret = $root.thirdparties.v1.TwilioSecret.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.Secret) {
            return object;
          }
          const message = new $root.thirdparties.v1.Secret();
          if (object.thirdPartySecretType != null) {
            switch (object.thirdPartySecretType) {
              case "UNSPECIFIED_ILLEGAL":
              case 0: {
                message.thirdPartySecretType = 0;
                break;
              }
              case "FORMSORT_API_KEY":
              case 1: {
                message.thirdPartySecretType = 1;
                break;
              }
              case "SEGMENT_WRITE_KEY":
              case 2: {
                message.thirdPartySecretType = 2;
                break;
              }
              case "HEALTHIE_STAGING_API_KEY":
              case 3: {
                message.thirdPartySecretType = 3;
                break;
              }
              case "HEALTHIE_PRODUCTION_API_KEY":
              case 4: {
                message.thirdPartySecretType = 4;
                break;
              }
              case "AXLE_HEALTH_DEVELOPMENT_API_KEY":
              case 5: {
                message.thirdPartySecretType = 5;
                break;
              }
              case "AXLE_HEALTH_PRODUCTION_API_KEY":
              case 6: {
                message.thirdPartySecretType = 6;
                break;
              }
              case "BUTTERFLY_LABS_API_KEY":
              case 7: {
                message.thirdPartySecretType = 7;
                break;
              }
              case "FRESHPAINT_SOURCE_TOKEN":
              case 10: {
                message.thirdPartySecretType = 10;
                break;
              }
              case "ACTIVE_CAMPAIGN_CONTACT_SECRET":
              case 11: {
                message.thirdPartySecretType = 11;
                break;
              }
              case "ACTIVE_CAMPAIGN_EVENT_TRACKING_SECRET":
              case 12: {
                message.thirdPartySecretType = 12;
                break;
              }
              case "MIGA_CLIENT_ID_AND_SECRET":
              case 13: {
                message.thirdPartySecretType = 13;
                break;
              }
              case "ACUITY_USER_ID_AND_SECRET":
              case 14: {
                message.thirdPartySecretType = 14;
                break;
              }
              case "VITAL_STAGING_API_KEY":
              case 15: {
                message.thirdPartySecretType = 15;
                break;
              }
              case "VITAL_PRODUCTION_API_KEY":
              case 16: {
                message.thirdPartySecretType = 16;
                break;
              }
              case "INTAKEQ_API_KEY":
              case 17: {
                message.thirdPartySecretType = 17;
                break;
              }
              case "SANA_SFTP":
              case 18: {
                message.thirdPartySecretType = 18;
                break;
              }
              case "CUSTOMERIO_TRACK_API_KEY":
              case 19: {
                message.thirdPartySecretType = 19;
                break;
              }
              case "CUSTOMERIO_APP_API_KEY":
              case 20: {
                message.thirdPartySecretType = 20;
                break;
              }
              case "SPRUCE_API_KEY":
              case 21: {
                message.thirdPartySecretType = 21;
                break;
              }
              case "FRESHDESK_API_KEY":
              case 22: {
                message.thirdPartySecretType = 22;
                break;
              }
              case "HUBSPOT_APP_ACCESS_TOKEN":
              case 23: {
                message.thirdPartySecretType = 23;
                break;
              }
              case "INTERCOM_ACCESS_TOKEN":
              case 24: {
                message.thirdPartySecretType = 24;
                break;
              }
              case "FACEBOOK_CONVERSIONS_ACCESS_TOKEN":
              case 25: {
                message.thirdPartySecretType = 25;
                break;
              }
              case "GOOGLE_ADS_CUSTOMER_ID":
              case 26: {
                message.thirdPartySecretType = 26;
                break;
              }
              case "TIKTOK_EVENTS_ACCESS_TOKEN":
              case 27: {
                message.thirdPartySecretType = 27;
                break;
              }
              case "TWILIO_SUBACCOUNT_AUTH_TOKEN":
              case 28: {
                message.thirdPartySecretType = 28;
                break;
              }
              default: {
                if (typeof object.thirdPartySecretType == "number") {
                  message.thirdPartySecretType = object.thirdPartySecretType;
                  break;
                }
                break;
              }
            }
          }
          if (object.plaintext != null) {
            message.plaintext = String(object.plaintext);
          }
          if (object.clientIdAndSecret != null) {
            if (typeof object.clientIdAndSecret !== "object") {
              throw new TypeError(".thirdparties.v1.Secret.clientIdAndSecret: object expected, but got " + (typeof object.clientIdAndSecret));
            }
            message.clientIdAndSecret = $root.thirdparties.v1.ClientIdAndSecret.fromObject(object.clientIdAndSecret);
          }
          if (object.sftpConfig != null) {
            if (typeof object.sftpConfig !== "object") {
              throw new TypeError(".thirdparties.v1.Secret.sftpConfig: object expected, but got " + (typeof object.sftpConfig));
            }
            message.sftpConfig = $root.thirdparties.v1.SftpConfig.fromObject(object.sftpConfig);
          }
          if (object.twilioSecret != null) {
            if (typeof object.twilioSecret !== "object") {
              throw new TypeError(".thirdparties.v1.Secret.twilioSecret: object expected, but got " + (typeof object.twilioSecret));
            }
            message.twilioSecret = $root.thirdparties.v1.TwilioSecret.fromObject(object.twilioSecret);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartySecretType = options.enums === String ? "UNSPECIFIED_ILLEGAL" : 0
          }
          let keys;
          if (message.thirdPartySecretType != null && message.hasOwnProperty("thirdPartySecretType")) {
            object.thirdPartySecretType = options.enums === String ? $root.thirdparties.v1.ThirdPartySecretType[message.thirdPartySecretType] === undefined ? message.thirdPartySecretType : $root.thirdparties.v1.ThirdPartySecretType[message.thirdPartySecretType] : message.thirdPartySecretType;
          }
          if (message.plaintext != null && message.hasOwnProperty("plaintext")) {
            object.plaintext = message.plaintext;
            if (options.oneofs) {
              object.type = "plaintext";
            }
          }
          if (message.clientIdAndSecret != null && message.hasOwnProperty("clientIdAndSecret")) {
            object.clientIdAndSecret = $root.thirdparties.v1.ClientIdAndSecret.toObject(message.clientIdAndSecret, options);
            if (options.oneofs) {
              object.type = "clientIdAndSecret";
            }
          }
          if (message.sftpConfig != null && message.hasOwnProperty("sftpConfig")) {
            object.sftpConfig = $root.thirdparties.v1.SftpConfig.toObject(message.sftpConfig, options);
            if (options.oneofs) {
              object.type = "sftpConfig";
            }
          }
          if (message.twilioSecret != null && message.hasOwnProperty("twilioSecret")) {
            object.twilioSecret = $root.thirdparties.v1.TwilioSecret.toObject(message.twilioSecret, options);
            if (options.oneofs) {
              object.type = "twilioSecret";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.Secret";
        }
      }

      Secret.prototype.thirdPartySecretType = 0;
      Secret.prototype.plaintext = "";
      Secret.prototype.clientIdAndSecret = null;
      Secret.prototype.sftpConfig = null;
      Secret.prototype.twilioSecret = null;

      return Secret;
    })();

    v1.ListThirdPartySecretsRequest = (() => {
      class ListThirdPartySecretsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListThirdPartySecretsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListThirdPartySecretsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.ListThirdPartySecretsRequest) {
            return object;
          }
          const message = new $root.thirdparties.v1.ListThirdPartySecretsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".thirdparties.v1.ListThirdPartySecretsRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.ListThirdPartySecretsRequest";
        }
      }

      ListThirdPartySecretsRequest.prototype.stub = null;

      return ListThirdPartySecretsRequest;
    })();

    v1.ListThirdPartySecretsResponse = (() => {
      class ListThirdPartySecretsResponse {
        constructor(properties) {
          this.secrets = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListThirdPartySecretsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.secrets != null && Object.hasOwnProperty.call(message, "secrets")) {
            for (const element of message.secrets) {
              $root.thirdparties.v1.Secret.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListThirdPartySecretsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.secrets || !message.secrets.length) {
                  message.secrets = [];
                }
                message.secrets.push($root.thirdparties.v1.Secret.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.thirdparties.v1.ListThirdPartySecretsResponse) {
            return object;
          }
          const message = new $root.thirdparties.v1.ListThirdPartySecretsResponse();
          if (object.secrets) {
            if (!Array.isArray(object.secrets)) {
              throw new TypeError(".thirdparties.v1.ListThirdPartySecretsResponse.secrets: array type expected, but got " + (typeof object.secrets))
            }
            message.secrets = new Array(object.secrets.length);
            for (let i = 0; i < object.secrets.length; ++i) {
              if (typeof object.secrets[i] !== "object") {
                throw new TypeError(".thirdparties.v1.ListThirdPartySecretsResponse.secrets: object expected, but got " + (typeof object.secrets[i]));
              }
              message.secrets[i] = $root.thirdparties.v1.Secret.fromObject(object.secrets[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.secrets = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.secrets && message.secrets.length) {
            object.secrets = new Array(message.secrets.length);
            for (let i = 0; i < message.secrets.length; ++i) {
              object.secrets[i] = $root.thirdparties.v1.Secret.toObject(message.secrets[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "thirdparties.v1.ListThirdPartySecretsResponse";
        }
      }

      ListThirdPartySecretsResponse.prototype.secrets = $util.emptyArray;

      return ListThirdPartySecretsResponse;
    })();

    v1.ThirdPartyIdType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION"] = 0;
      values[valuesById[1] = "CUSTOMER"] = 1;
      values[valuesById[2] = "FORMSORT"] = 2;
      values[valuesById[3] = "HEALTHIE"] = 3;
      values[valuesById[4] = "AXLE_HEALTH"] = 4;
      values[valuesById[5] = "BUTTERFLY_LABS"] = 5;
      values[valuesById[6] = "RECURLY"] = 6;
      values[valuesById[7] = "INTERCOM"] = 7;
      values[valuesById[8] = "SANA_BENEFITS"] = 8;
      values[valuesById[9] = "ACTIVE_CAMPAIGN"] = 9;
      values[valuesById[10] = "VITAL"] = 10;
      values[valuesById[11] = "SEGMENT"] = 11;
      values[valuesById[12] = "INTAKEQ"] = 12;
      values[valuesById[13] = "CUSTOMER_IO"] = 13;
      values[valuesById[14] = "FRESHDESK"] = 14;
      values[valuesById[15] = "HUBSPOT"] = 15;
      return values;
    })();

    v1.SecretKind = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_SECRET_KIND"] = 0;
      values[valuesById[1] = "PLAINTEXT"] = 1;
      values[valuesById[2] = "CLIENT_ID_AND_SECRET"] = 2;
      values[valuesById[3] = "SFTP_CONFIG"] = 3;
      values[valuesById[4] = "TWILIO_BACKEND_PROVISIONED"] = 4;
      return values;
    })();

    v1.ThirdPartySecretType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL"] = 0;
      values[valuesById[1] = "FORMSORT_API_KEY"] = 1;
      values[valuesById[2] = "SEGMENT_WRITE_KEY"] = 2;
      values[valuesById[3] = "HEALTHIE_STAGING_API_KEY"] = 3;
      values[valuesById[4] = "HEALTHIE_PRODUCTION_API_KEY"] = 4;
      values[valuesById[5] = "AXLE_HEALTH_DEVELOPMENT_API_KEY"] = 5;
      values[valuesById[6] = "AXLE_HEALTH_PRODUCTION_API_KEY"] = 6;
      values[valuesById[7] = "BUTTERFLY_LABS_API_KEY"] = 7;
      values[valuesById[10] = "FRESHPAINT_SOURCE_TOKEN"] = 10;
      values[valuesById[11] = "ACTIVE_CAMPAIGN_CONTACT_SECRET"] = 11;
      values[valuesById[12] = "ACTIVE_CAMPAIGN_EVENT_TRACKING_SECRET"] = 12;
      values[valuesById[13] = "MIGA_CLIENT_ID_AND_SECRET"] = 13;
      values[valuesById[14] = "ACUITY_USER_ID_AND_SECRET"] = 14;
      values[valuesById[15] = "VITAL_STAGING_API_KEY"] = 15;
      values[valuesById[16] = "VITAL_PRODUCTION_API_KEY"] = 16;
      values[valuesById[17] = "INTAKEQ_API_KEY"] = 17;
      values[valuesById[18] = "SANA_SFTP"] = 18;
      values[valuesById[19] = "CUSTOMERIO_TRACK_API_KEY"] = 19;
      values[valuesById[20] = "CUSTOMERIO_APP_API_KEY"] = 20;
      values[valuesById[21] = "SPRUCE_API_KEY"] = 21;
      values[valuesById[22] = "FRESHDESK_API_KEY"] = 22;
      values[valuesById[23] = "HUBSPOT_APP_ACCESS_TOKEN"] = 23;
      values[valuesById[24] = "INTERCOM_ACCESS_TOKEN"] = 24;
      values[valuesById[25] = "FACEBOOK_CONVERSIONS_ACCESS_TOKEN"] = 25;
      values[valuesById[26] = "GOOGLE_ADS_CUSTOMER_ID"] = 26;
      values[valuesById[27] = "TIKTOK_EVENTS_ACCESS_TOKEN"] = 27;
      values[valuesById[28] = "TWILIO_SUBACCOUNT_AUTH_TOKEN"] = 28;
      return values;
    })();

    return v1;
  })();

  return thirdparties;
})();

export const thirdparties = $root.thirdparties;
