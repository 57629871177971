import { Container } from '../Container';
import { FC } from 'react';
import { Image } from '../Image';
import { LoaderContainer } from './Loader.css';
import { LoaderProps } from './types';
import { PropagateLoader } from 'react-spinners';
import { useTheme } from '@morf/theming';

export const Loader: FC<LoaderProps> = ({ color, ...props }) => {
  const theme = useTheme();
  const src = `${process.env.NEXT_PUBLIC_APP_BASE_URL}/loaders/${theme.loader}`;

  return (
    <Container position='relative' {...props}>
      <LoaderContainer data-testid='loader'>
        {theme.loader ? (
          <Image width='5rem' height='5rem' src={src} alt='Loading...' />
        ) : (
          <PropagateLoader color={color || theme.colors.main.primary.darker} />
        )}
      </LoaderContainer>
    </Container>
  );
};
