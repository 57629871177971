import { ThemeContainerProps, css, styled } from '@morf/theming';

const NoFill = css(
  ({ theme }) => `
      color: ${theme.colors.main.primary.darker};
      background-color: ${theme.colors.ui.card};

      :hover {
        background-color: ${theme.colors.main.light.light};
        border-color: ${theme.colors.main.light.light};
      }

      :active{
        color: ${theme.colors.text.inverse};
        background-color: ${theme.colors.main.primary.darkest};
        border-color: ${theme.colors.main.primary.darkest};
      }
    
        
      :focus {
        border-color: ${theme.colors.main.light.light};
      }
      `
);

const Fill = css(
  ({ theme }) => `
      color: ${theme.colors.main.primary.darker};
      background-color: ${theme.colors.main.light.light};

      :hover {
        background-color: ${theme.colors.ui.body};
      }

      :active{
        color: ${theme.colors.text.inverse};
        background-color: ${theme.colors.main.primary.darkest};
        border-color: ${theme.colors.main.primary.darkest};
      }
    
      :focus{
        border: 4px solid ${theme.colors.main.light.light};
        background-color: ${theme.colors.ui.body};
      }
      `
);

const Outline = css(
  ({ theme }) => `
      color: ${theme.colors.main.primary.darker};
      background-color: ${theme.colors.ui.card};
      border: 1px solid ${theme.colors.main.primary.darker};

      :hover {
        background-color: ${theme.colors.main.light.light};
        }

      :active{
        color: ${theme.colors.text.inverse};
        background-color: ${theme.colors.main.primary.darkest};
        border-color: ${theme.colors.main.primary.darkest};
      }
    
      :focus{
        border: 4px solid ${theme.colors.main.light.light};
       }
      `
);

const handleStyle = (theme: ThemeContainerProps) => {
  const style = theme.datePicker.style;
  switch (style) {
    case 'nofill':
      return NoFill;
    case 'fill':
      return Fill;
    case 'outline':
      return Outline;
  }
};

export const StyledDate = styled.button<
  {
    isActive: boolean;
    isDisabled: boolean;
    isHidden: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  margin: 0.125rem;
  width: 2.125rem;
  height: 2.125rem;
  padding: 0rem;
  border-radius: ${(props) =>
    props.theme.borderRadius[props.theme.datePicker.borderRadius]};
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;

  ${(props) =>
    props.isHidden &&
    props.isDisabled &&
    css`
      color: ${props.theme.colors.text.inverse};
      background-color: transparent;
      cursor: not-allowed;
    `}

  ${(props) =>
    !props.isHidden &&
    props.isDisabled &&
    css`
      color: ${props.theme.colors.text.disabled};
      background-color: transparent;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.isActive &&
    css`
      color: ${props.theme.colors.text.inverse};
      background-color: ${props.theme.colors.main.primary.darkest};
      border-color: ${props.theme.colors.main.primary.darkest};
    `}
  
  ${(props) =>
    !props.isDisabled &&
    !props.isActive &&
    !props.isHidden &&
    handleStyle(props.theme)};
`;
