"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { phonenumbers as phonenumbers$1 } from "./phonenumbers_ts_proto";
import { sensitivity as sensitivity$1 } from "./sensitivity_v1_ts_proto";
import { google as google$1 } from "./struct_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";
import { values as values$1 } from "./values_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.profile_properties = (() => {
  const profile_properties = {};
  profile_properties.v1 = (() => {
    const v1 = {};
    v1.ProfileProperty = (() => {
      class ProfileProperty {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfileProperty(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(18).string(message.description);
          }
          if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata")) {
            google$1.protobuf.Struct.encode(message.metadata, writer.uint32(26).fork()).ldelim();
          }
          if (message.timing != null && Object.hasOwnProperty.call(message, "timing")) {
            timing$1.v1.Timing.encode(message.timing, writer.uint32(34).fork()).ldelim();
          }
          if (message.propertySchema != null && Object.hasOwnProperty.call(message, "propertySchema")) {
            $root.profile_properties.v1.PropertySchema.encode(message.propertySchema, writer.uint32(42).fork()).ldelim();
          }
          if (message.morfDefaultProperty != null && Object.hasOwnProperty.call(message, "morfDefaultProperty")) {
            writer.uint32(48).bool(message.morfDefaultProperty);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfileProperty();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.metadata = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.timing = timing$1.v1.Timing.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.propertySchema = $root.profile_properties.v1.PropertySchema.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.morfDefaultProperty = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.ProfileProperty) {
            return object;
          }
          const message = new $root.profile_properties.v1.ProfileProperty();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== "object") {
              throw new TypeError(".profile_properties.v1.ProfileProperty.metadata: object expected, but got " + (typeof object.metadata));
            }
            message.metadata = google$1.protobuf.Struct.fromObject(object.metadata);
          }
          if (object.timing != null) {
            if (typeof object.timing !== "object") {
              throw new TypeError(".profile_properties.v1.ProfileProperty.timing: object expected, but got " + (typeof object.timing));
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.propertySchema != null) {
            if (typeof object.propertySchema !== "object") {
              throw new TypeError(".profile_properties.v1.ProfileProperty.propertySchema: object expected, but got " + (typeof object.propertySchema));
            }
            message.propertySchema = $root.profile_properties.v1.PropertySchema.fromObject(object.propertySchema);
          }
          if (object.morfDefaultProperty != null) {
            message.morfDefaultProperty = Boolean(object.morfDefaultProperty);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
            object.metadata = null;
            object.timing = null;
            object.propertySchema = null;
            object.morfDefaultProperty = false;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.metadata != null && message.hasOwnProperty("metadata")) {
            object.metadata = google$1.protobuf.Struct.toObject(message.metadata, options);
          }
          if (message.timing != null && message.hasOwnProperty("timing")) {
            object.timing = timing$1.v1.Timing.toObject(message.timing, options);
          }
          if (message.propertySchema != null && message.hasOwnProperty("propertySchema")) {
            object.propertySchema = $root.profile_properties.v1.PropertySchema.toObject(message.propertySchema, options);
          }
          if (message.morfDefaultProperty != null && message.hasOwnProperty("morfDefaultProperty")) {
            object.morfDefaultProperty = message.morfDefaultProperty;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.ProfileProperty";
        }
      }

      ProfileProperty.prototype.name = "";
      ProfileProperty.prototype.description = null;
      ProfileProperty.prototype.metadata = null;
      ProfileProperty.prototype.timing = null;
      ProfileProperty.prototype.propertySchema = null;
      ProfileProperty.prototype.morfDefaultProperty = false;

      return ProfileProperty;
    })();

    v1.ProfileProperties = (() => {
      class ProfileProperties {
        constructor(properties) {
          this.profileProperties = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfileProperties(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileProperties != null && Object.hasOwnProperty.call(message, "profileProperties")) {
            for (const element of message.profileProperties) {
              $root.profile_properties.v1.ProfileProperty.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfileProperties();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.profileProperties || !message.profileProperties.length) {
                  message.profileProperties = [];
                }
                message.profileProperties.push($root.profile_properties.v1.ProfileProperty.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.ProfileProperties) {
            return object;
          }
          const message = new $root.profile_properties.v1.ProfileProperties();
          if (object.profileProperties) {
            if (!Array.isArray(object.profileProperties)) {
              throw new TypeError(".profile_properties.v1.ProfileProperties.profileProperties: array type expected, but got " + (typeof object.profileProperties))
            }
            message.profileProperties = new Array(object.profileProperties.length);
            for (let i = 0; i < object.profileProperties.length; ++i) {
              if (typeof object.profileProperties[i] !== "object") {
                throw new TypeError(".profile_properties.v1.ProfileProperties.profileProperties: object expected, but got " + (typeof object.profileProperties[i]));
              }
              message.profileProperties[i] = $root.profile_properties.v1.ProfileProperty.fromObject(object.profileProperties[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.profileProperties = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.profileProperties && message.profileProperties.length) {
            object.profileProperties = new Array(message.profileProperties.length);
            for (let i = 0; i < message.profileProperties.length; ++i) {
              object.profileProperties[i] = $root.profile_properties.v1.ProfileProperty.toObject(message.profileProperties[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.ProfileProperties";
        }
      }

      ProfileProperties.prototype.profileProperties = $util.emptyArray;

      return ProfileProperties;
    })();

    v1.PropertySchema = (() => {
      class PropertySchema {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PropertySchema(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.schema != null && Object.hasOwnProperty.call(message, "schema")) {
            $root.profile_properties.v1.PropertyTypeSchema.encode(message.schema, writer.uint32(10).fork()).ldelim();
          }
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            $root.profile_properties.v1.PropertyType.encode(message.stub, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PropertySchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.schema = $root.profile_properties.v1.PropertyTypeSchema.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.stub = $root.profile_properties.v1.PropertyType.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.PropertySchema) {
            return object;
          }
          const message = new $root.profile_properties.v1.PropertySchema();
          if (object.schema != null) {
            if (typeof object.schema !== "object") {
              throw new TypeError(".profile_properties.v1.PropertySchema.schema: object expected, but got " + (typeof object.schema));
            }
            message.schema = $root.profile_properties.v1.PropertyTypeSchema.fromObject(object.schema);
          }
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".profile_properties.v1.PropertySchema.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = $root.profile_properties.v1.PropertyType.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.schema = null;
            object.stub = null;
          }
          let keys;
          if (message.schema != null && message.hasOwnProperty("schema")) {
            object.schema = $root.profile_properties.v1.PropertyTypeSchema.toObject(message.schema, options);
          }
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = $root.profile_properties.v1.PropertyType.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.PropertySchema";
        }
      }

      PropertySchema.prototype.schema = null;
      PropertySchema.prototype.stub = null;

      return PropertySchema;
    })();

    v1.PropertyTypeSchema = (() => {
      class PropertyTypeSchema {
        get type() {
          for (const key of ["object", "scalar"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["object", "scalar"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PropertyTypeSchema(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.object != null && Object.hasOwnProperty.call(message, "object")) {
            $root.profile_properties.v1.PropertyTypeSchema.ObjectSchema.encode(message.object, writer.uint32(10).fork()).ldelim();
          }
          if (message.scalar != null && Object.hasOwnProperty.call(message, "scalar")) {
            $root.profile_properties.v1.PropertyTypeSchema.ScalarSchema.encode(message.scalar, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PropertyTypeSchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.object = $root.profile_properties.v1.PropertyTypeSchema.ObjectSchema.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.scalar = $root.profile_properties.v1.PropertyTypeSchema.ScalarSchema.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.PropertyTypeSchema) {
            return object;
          }
          const message = new $root.profile_properties.v1.PropertyTypeSchema();
          if (object.object != null) {
            if (typeof object.object !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyTypeSchema.object: object expected, but got " + (typeof object.object));
            }
            message.object = $root.profile_properties.v1.PropertyTypeSchema.ObjectSchema.fromObject(object.object);
          }
          if (object.scalar != null) {
            if (typeof object.scalar !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyTypeSchema.scalar: object expected, but got " + (typeof object.scalar));
            }
            message.scalar = $root.profile_properties.v1.PropertyTypeSchema.ScalarSchema.fromObject(object.scalar);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.object != null && message.hasOwnProperty("object")) {
            object.object = $root.profile_properties.v1.PropertyTypeSchema.ObjectSchema.toObject(message.object, options);
            if (options.oneofs) {
              object.type = "object";
            }
          }
          if (message.scalar != null && message.hasOwnProperty("scalar")) {
            object.scalar = $root.profile_properties.v1.PropertyTypeSchema.ScalarSchema.toObject(message.scalar, options);
            if (options.oneofs) {
              object.type = "scalar";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.PropertyTypeSchema";
        }
      }

      PropertyTypeSchema.prototype.object = null;
      PropertyTypeSchema.prototype.scalar = null;
      PropertyTypeSchema.ObjectSchema = (() => {
        class ObjectSchema {
          constructor(properties) {
            this.fields = {};
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ObjectSchema(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.fields != null && Object.hasOwnProperty.call(message, "fields")) {
              for (const key of Object.keys(message.fields)) {
                writer.uint32(10).fork().uint32(10).string(key);
                $root.profile_properties.v1.PropertyTypeSchema.encode(message.fields[key], writer.uint32(18).fork()).ldelim().ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ObjectSchema();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (message.fields === $util.emptyObject) message.fields = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = "";
                  value = null;
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.string();
                        break;
                      }
                      case 2: {
                        value = $root.profile_properties.v1.PropertyTypeSchema.decode(reader, reader.uint32());
                        break;
                      }
                    }
                    message.fields[key] = value;
                  }
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profile_properties.v1.PropertyTypeSchema.ObjectSchema) {
              return object;
            }
            const message = new $root.profile_properties.v1.PropertyTypeSchema.ObjectSchema();
            if (object.fields) {
              if (typeof object.fields !== "object") {
                throw new TypeError(".profile_properties.v1.PropertyTypeSchema.ObjectSchema.fields: object expected, but got " + (typeof object.fields));
              }
              message.fields = {};
              for (let keys = Object.keys(object.fields), i = 0; i < keys.length; ++i) {
                if (typeof object.fields[keys[i]] !== "object") {
                  throw new TypeError(".profile_properties.v1.PropertyTypeSchema.ObjectSchema.value: object expected, but got " + (typeof object.fields[keys[i]]));
                }
                message.fields[keys[i]] = $root.profile_properties.v1.PropertyTypeSchema.fromObject(object.fields[keys[i]]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
              object.fields = {};
            }
            if (options.defaults) {
            }
            let keys;
            if (message.fields && (keys = Object.keys(message.fields)).length) {
              object.fields = {};
              for (let i = 0; i < keys.length; ++i) {
                object.fields[keys[i]] = $root.profile_properties.v1.PropertyTypeSchema.toObject(message.fields[keys[i]], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profile_properties.v1.PropertyTypeSchema.ObjectSchema";
          }
        }

        ObjectSchema.prototype.fields = $util.emptyObject;

        return ObjectSchema;
      })();

      PropertyTypeSchema.ScalarSchema = (() => {
        class ScalarSchema {
          constructor(properties) {
            this.sensitivityAnnotations = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ScalarSchema(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.valueType != null && Object.hasOwnProperty.call(message, "valueType")) {
              values$1.v1.ValueType.encode(message.valueType, writer.uint32(10).fork()).ldelim();
            }
            if (message.celExpressionStr != null && Object.hasOwnProperty.call(message, "celExpressionStr")) {
              writer.uint32(18).string(message.celExpressionStr);
            }
            if (message.indexHash != null && Object.hasOwnProperty.call(message, "indexHash")) {
              writer.uint32(24).int64(message.indexHash);
            }
            if (message.sensitivityAnnotations != null && Object.hasOwnProperty.call(message, "sensitivityAnnotations")) {
              writer.uint32(34).fork();
              for (const element of message.sensitivityAnnotations) {
                writer.int32(element)
              }
              writer.ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ScalarSchema();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.valueType = values$1.v1.ValueType.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.celExpressionStr = reader.string();
                  break;
                }
                case 3: {
                  message.indexHash = reader.int64();
                  break;
                }
                case 4: {
                  if (!message.sensitivityAnnotations || !message.sensitivityAnnotations.length) {
                    message.sensitivityAnnotations = [];
                  }
                  if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2) message.sensitivityAnnotations.push(reader.int32());
                  } else {
                    message.sensitivityAnnotations.push(reader.int32());
                  }
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profile_properties.v1.PropertyTypeSchema.ScalarSchema) {
              return object;
            }
            const message = new $root.profile_properties.v1.PropertyTypeSchema.ScalarSchema();
            if (object.valueType != null) {
              if (typeof object.valueType !== "object") {
                throw new TypeError(".profile_properties.v1.PropertyTypeSchema.ScalarSchema.valueType: object expected, but got " + (typeof object.valueType));
              }
              message.valueType = values$1.v1.ValueType.fromObject(object.valueType);
            }
            if (object.celExpressionStr != null) {
              message.celExpressionStr = String(object.celExpressionStr);
            }
            if (object.indexHash != null) {
              if ($util.Long) {
                (message.indexHash = $util.Long.fromValue(object.indexHash)).unsigned = false;
              } else if (typeof object.indexHash === "string") {
                message.indexHash = parseInt(object.indexHash, 10);
              } else if (typeof object.indexHash === "number") {
                message.indexHash = object.indexHash;
              } else if (typeof object.indexHash === "object") {
                message.indexHash = new $util.LongBits(object.indexHash.low >>> 0, object.indexHash.high >>> 0).toNumber();
              }
            }
            if (object.sensitivityAnnotations) {
              if (!Array.isArray(object.sensitivityAnnotations)) {
                throw new TypeError(".profile_properties.v1.PropertyTypeSchema.ScalarSchema.sensitivityAnnotations: array type expected, but got " + (typeof object.sensitivityAnnotations))
              }
              message.sensitivityAnnotations = new Array(object.sensitivityAnnotations.length);
              for (let i = 0; i < object.sensitivityAnnotations.length; ++i) {
                switch (object.sensitivityAnnotations[i]) {
                  case "UNSPECIFIED":
                  case 0: {
                    message.sensitivityAnnotations[i] = 0;
                    break;
                  }
                  case "PII":
                  case 1: {
                    message.sensitivityAnnotations[i] = 1;
                    break;
                  }
                  case "HI":
                  case 2: {
                    message.sensitivityAnnotations[i] = 2;
                    break;
                  }
                  case "ID":
                  case 3: {
                    message.sensitivityAnnotations[i] = 3;
                    break;
                  }
                  default: {
                    if (typeof object.sensitivityAnnotations[i] == "number") {
                      message.sensitivityAnnotations[i] = object.sensitivityAnnotations[i];
                      break;
                    }
                    message.sensitivityAnnotations[i] = 0;
                    break;
                  }
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.sensitivityAnnotations = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.valueType = null;
              if ($util.Long) {
                let long = new $util.Long(0, 0, false)
                object.indexHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.indexHash = options.longs === String ? "0" : 0;
              }
            }
            let keys;
            if (message.valueType != null && message.hasOwnProperty("valueType")) {
              object.valueType = values$1.v1.ValueType.toObject(message.valueType, options);
            }
            if (message.celExpressionStr != null && message.hasOwnProperty("celExpressionStr")) {
              object.celExpressionStr = message.celExpressionStr;
            }
            if (message.indexHash != null && message.hasOwnProperty("indexHash")) {
              object.indexHash = typeof message.indexHash === "number" ? (options.longs === String ? String(message.indexHash) : message.indexHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.indexHash) : options.longs === Number ? new $util.LongBits(message.indexHash.low >>> 0, message.indexHash.high >>> 0).toNumber() : message.indexHash);
            }
            if (message.sensitivityAnnotations && message.sensitivityAnnotations.length) {
              object.sensitivityAnnotations = new Array(message.sensitivityAnnotations.length);
              for (let i = 0; i < message.sensitivityAnnotations.length; ++i) {
                object.sensitivityAnnotations[i] = options.enums === String ? sensitivity$1.v1.Sensitivity.Value[message.sensitivityAnnotations[i]] === undefined ? message.sensitivityAnnotations[i] : sensitivity$1.v1.Sensitivity.Value[message.sensitivityAnnotations[i]] : message.sensitivityAnnotations[i];
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profile_properties.v1.PropertyTypeSchema.ScalarSchema";
          }
        }

        ScalarSchema.prototype.valueType = null;
        ScalarSchema.prototype.celExpressionStr = null;
        ScalarSchema.prototype.indexHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
        ScalarSchema.prototype.sensitivityAnnotations = $util.emptyArray;

        return ScalarSchema;
      })();

      return PropertyTypeSchema;
    })();

    v1.PropertyType = (() => {
      class PropertyType {
        get type() {
          for (const key of ["default", "custom"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["default", "custom"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PropertyType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.default != null && Object.hasOwnProperty.call(message, "default")) {
            $root.profile_properties.v1.DefaultPropertyType.encode(message.default, writer.uint32(10).fork()).ldelim();
          }
          if (message.custom != null && Object.hasOwnProperty.call(message, "custom")) {
            $root.profile_properties.v1.CustomPropertyType.encode(message.custom, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PropertyType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.default = $root.profile_properties.v1.DefaultPropertyType.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.custom = $root.profile_properties.v1.CustomPropertyType.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.PropertyType) {
            return object;
          }
          const message = new $root.profile_properties.v1.PropertyType();
          if (object.default != null) {
            if (typeof object.default !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyType.default: object expected, but got " + (typeof object.default));
            }
            message.default = $root.profile_properties.v1.DefaultPropertyType.fromObject(object.default);
          }
          if (object.custom != null) {
            if (typeof object.custom !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyType.custom: object expected, but got " + (typeof object.custom));
            }
            message.custom = $root.profile_properties.v1.CustomPropertyType.fromObject(object.custom);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.default != null && message.hasOwnProperty("default")) {
            object.default = $root.profile_properties.v1.DefaultPropertyType.toObject(message.default, options);
            if (options.oneofs) {
              object.type = "default";
            }
          }
          if (message.custom != null && message.hasOwnProperty("custom")) {
            object.custom = $root.profile_properties.v1.CustomPropertyType.toObject(message.custom, options);
            if (options.oneofs) {
              object.type = "custom";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.PropertyType";
        }
      }

      PropertyType.prototype.default = null;
      PropertyType.prototype.custom = null;

      return PropertyType;
    })();

    v1.DefaultPropertyType = (() => {
      class DefaultPropertyType {
        get type() {
          for (const key of ["firstName", "lastName", "dateOfBirth", "emailAddress", "phoneNumber", "usMailingAddress", "sexAssignedAtBirth", "socialSecurityNumber", "genderIdentity", "timezone", "primaryInsurance", "secondaryInsurance", "preferredLanguage", "nextOfKin", "emergencyContact", "internationalMailingAddress"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["firstName", "lastName", "dateOfBirth", "emailAddress", "phoneNumber", "usMailingAddress", "sexAssignedAtBirth", "socialSecurityNumber", "genderIdentity", "timezone", "primaryInsurance", "secondaryInsurance", "preferredLanguage", "nextOfKin", "emergencyContact", "internationalMailingAddress"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DefaultPropertyType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(10).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(18).string(message.lastName);
          }
          if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
            values$1.v1.Date.encode(message.dateOfBirth, writer.uint32(26).fork()).ldelim();
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(34).string(message.emailAddress);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            phonenumbers$1.PhoneNumber.encode(message.phoneNumber, writer.uint32(42).fork()).ldelim();
          }
          if (message.usMailingAddress != null && Object.hasOwnProperty.call(message, "usMailingAddress")) {
            $root.profile_properties.v1.UsAddress.encode(message.usMailingAddress, writer.uint32(50).fork()).ldelim();
          }
          if (message.sexAssignedAtBirth != null && Object.hasOwnProperty.call(message, "sexAssignedAtBirth")) {
            writer.uint32(56).int32(message.sexAssignedAtBirth);
          }
          if (message.socialSecurityNumber != null && Object.hasOwnProperty.call(message, "socialSecurityNumber")) {
            writer.uint32(66).string(message.socialSecurityNumber);
          }
          if (message.genderIdentity != null && Object.hasOwnProperty.call(message, "genderIdentity")) {
            writer.uint32(74).string(message.genderIdentity);
          }
          if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone")) {
            writer.uint32(82).string(message.timezone);
          }
          if (message.primaryInsurance != null && Object.hasOwnProperty.call(message, "primaryInsurance")) {
            $root.profile_properties.v1.InsurancePolicy.encode(message.primaryInsurance, writer.uint32(90).fork()).ldelim();
          }
          if (message.secondaryInsurance != null && Object.hasOwnProperty.call(message, "secondaryInsurance")) {
            $root.profile_properties.v1.InsurancePolicy.encode(message.secondaryInsurance, writer.uint32(98).fork()).ldelim();
          }
          if (message.preferredLanguage != null && Object.hasOwnProperty.call(message, "preferredLanguage")) {
            writer.uint32(106).string(message.preferredLanguage);
          }
          if (message.nextOfKin != null && Object.hasOwnProperty.call(message, "nextOfKin")) {
            $root.profile_properties.v1.Person.encode(message.nextOfKin, writer.uint32(114).fork()).ldelim();
          }
          if (message.emergencyContact != null && Object.hasOwnProperty.call(message, "emergencyContact")) {
            $root.profile_properties.v1.Person.encode(message.emergencyContact, writer.uint32(122).fork()).ldelim();
          }
          if (message.internationalMailingAddress != null && Object.hasOwnProperty.call(message, "internationalMailingAddress")) {
            $root.profile_properties.v1.InternationalAddress.encode(message.internationalMailingAddress, writer.uint32(130).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DefaultPropertyType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.firstName = reader.string();
                break;
              }
              case 2: {
                message.lastName = reader.string();
                break;
              }
              case 3: {
                message.dateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.emailAddress = reader.string();
                break;
              }
              case 5: {
                message.phoneNumber = phonenumbers$1.PhoneNumber.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.usMailingAddress = $root.profile_properties.v1.UsAddress.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.sexAssignedAtBirth = reader.int32();
                break;
              }
              case 8: {
                message.socialSecurityNumber = reader.string();
                break;
              }
              case 9: {
                message.genderIdentity = reader.string();
                break;
              }
              case 10: {
                message.timezone = reader.string();
                break;
              }
              case 11: {
                message.primaryInsurance = $root.profile_properties.v1.InsurancePolicy.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.secondaryInsurance = $root.profile_properties.v1.InsurancePolicy.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.preferredLanguage = reader.string();
                break;
              }
              case 14: {
                message.nextOfKin = $root.profile_properties.v1.Person.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.emergencyContact = $root.profile_properties.v1.Person.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.internationalMailingAddress = $root.profile_properties.v1.InternationalAddress.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.DefaultPropertyType) {
            return object;
          }
          const message = new $root.profile_properties.v1.DefaultPropertyType();
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.dateOfBirth: object expected, but got " + (typeof object.dateOfBirth));
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(object.dateOfBirth);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            if (typeof object.phoneNumber !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.phoneNumber: object expected, but got " + (typeof object.phoneNumber));
            }
            message.phoneNumber = phonenumbers$1.PhoneNumber.fromObject(object.phoneNumber);
          }
          if (object.usMailingAddress != null) {
            if (typeof object.usMailingAddress !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.usMailingAddress: object expected, but got " + (typeof object.usMailingAddress));
            }
            message.usMailingAddress = $root.profile_properties.v1.UsAddress.fromObject(object.usMailingAddress);
          }
          if (object.sexAssignedAtBirth != null) {
            switch (object.sexAssignedAtBirth) {
              case "UNSPECIFIED_SEX_ASSIGNED_AT_BIRTH":
              case 0: {
                message.sexAssignedAtBirth = 0;
                break;
              }
              case "FEMALE":
              case 1: {
                message.sexAssignedAtBirth = 1;
                break;
              }
              case "MALE":
              case 2: {
                message.sexAssignedAtBirth = 2;
                break;
              }
              case "INTERSEX":
              case 3: {
                message.sexAssignedAtBirth = 3;
                break;
              }
              case "OTHER":
              case 4: {
                message.sexAssignedAtBirth = 4;
                break;
              }
              default: {
                if (typeof object.sexAssignedAtBirth == "number") {
                  message.sexAssignedAtBirth = object.sexAssignedAtBirth;
                  break;
                }
                break;
              }
            }
          }
          if (object.socialSecurityNumber != null) {
            message.socialSecurityNumber = String(object.socialSecurityNumber);
          }
          if (object.genderIdentity != null) {
            message.genderIdentity = String(object.genderIdentity);
          }
          if (object.timezone != null) {
            message.timezone = String(object.timezone);
          }
          if (object.primaryInsurance != null) {
            if (typeof object.primaryInsurance !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.primaryInsurance: object expected, but got " + (typeof object.primaryInsurance));
            }
            message.primaryInsurance = $root.profile_properties.v1.InsurancePolicy.fromObject(object.primaryInsurance);
          }
          if (object.secondaryInsurance != null) {
            if (typeof object.secondaryInsurance !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.secondaryInsurance: object expected, but got " + (typeof object.secondaryInsurance));
            }
            message.secondaryInsurance = $root.profile_properties.v1.InsurancePolicy.fromObject(object.secondaryInsurance);
          }
          if (object.preferredLanguage != null) {
            message.preferredLanguage = String(object.preferredLanguage);
          }
          if (object.nextOfKin != null) {
            if (typeof object.nextOfKin !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.nextOfKin: object expected, but got " + (typeof object.nextOfKin));
            }
            message.nextOfKin = $root.profile_properties.v1.Person.fromObject(object.nextOfKin);
          }
          if (object.emergencyContact != null) {
            if (typeof object.emergencyContact !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.emergencyContact: object expected, but got " + (typeof object.emergencyContact));
            }
            message.emergencyContact = $root.profile_properties.v1.Person.fromObject(object.emergencyContact);
          }
          if (object.internationalMailingAddress != null) {
            if (typeof object.internationalMailingAddress !== "object") {
              throw new TypeError(".profile_properties.v1.DefaultPropertyType.internationalMailingAddress: object expected, but got " + (typeof object.internationalMailingAddress));
            }
            message.internationalMailingAddress = $root.profile_properties.v1.InternationalAddress.fromObject(object.internationalMailingAddress);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
            if (options.oneofs) {
              object.type = "firstName";
            }
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
            if (options.oneofs) {
              object.type = "lastName";
            }
          }
          if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
            object.dateOfBirth = values$1.v1.Date.toObject(message.dateOfBirth, options);
            if (options.oneofs) {
              object.type = "dateOfBirth";
            }
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
            if (options.oneofs) {
              object.type = "emailAddress";
            }
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = phonenumbers$1.PhoneNumber.toObject(message.phoneNumber, options);
            if (options.oneofs) {
              object.type = "phoneNumber";
            }
          }
          if (message.usMailingAddress != null && message.hasOwnProperty("usMailingAddress")) {
            object.usMailingAddress = $root.profile_properties.v1.UsAddress.toObject(message.usMailingAddress, options);
            if (options.oneofs) {
              object.type = "usMailingAddress";
            }
          }
          if (message.sexAssignedAtBirth != null && message.hasOwnProperty("sexAssignedAtBirth")) {
            object.sexAssignedAtBirth = options.enums === String ? $root.profile_properties.v1.SexAssignedAtBirth[message.sexAssignedAtBirth] === undefined ? message.sexAssignedAtBirth : $root.profile_properties.v1.SexAssignedAtBirth[message.sexAssignedAtBirth] : message.sexAssignedAtBirth;
            if (options.oneofs) {
              object.type = "sexAssignedAtBirth";
            }
          }
          if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber")) {
            object.socialSecurityNumber = message.socialSecurityNumber;
            if (options.oneofs) {
              object.type = "socialSecurityNumber";
            }
          }
          if (message.genderIdentity != null && message.hasOwnProperty("genderIdentity")) {
            object.genderIdentity = message.genderIdentity;
            if (options.oneofs) {
              object.type = "genderIdentity";
            }
          }
          if (message.timezone != null && message.hasOwnProperty("timezone")) {
            object.timezone = message.timezone;
            if (options.oneofs) {
              object.type = "timezone";
            }
          }
          if (message.primaryInsurance != null && message.hasOwnProperty("primaryInsurance")) {
            object.primaryInsurance = $root.profile_properties.v1.InsurancePolicy.toObject(message.primaryInsurance, options);
            if (options.oneofs) {
              object.type = "primaryInsurance";
            }
          }
          if (message.secondaryInsurance != null && message.hasOwnProperty("secondaryInsurance")) {
            object.secondaryInsurance = $root.profile_properties.v1.InsurancePolicy.toObject(message.secondaryInsurance, options);
            if (options.oneofs) {
              object.type = "secondaryInsurance";
            }
          }
          if (message.preferredLanguage != null && message.hasOwnProperty("preferredLanguage")) {
            object.preferredLanguage = message.preferredLanguage;
            if (options.oneofs) {
              object.type = "preferredLanguage";
            }
          }
          if (message.nextOfKin != null && message.hasOwnProperty("nextOfKin")) {
            object.nextOfKin = $root.profile_properties.v1.Person.toObject(message.nextOfKin, options);
            if (options.oneofs) {
              object.type = "nextOfKin";
            }
          }
          if (message.emergencyContact != null && message.hasOwnProperty("emergencyContact")) {
            object.emergencyContact = $root.profile_properties.v1.Person.toObject(message.emergencyContact, options);
            if (options.oneofs) {
              object.type = "emergencyContact";
            }
          }
          if (message.internationalMailingAddress != null && message.hasOwnProperty("internationalMailingAddress")) {
            object.internationalMailingAddress = $root.profile_properties.v1.InternationalAddress.toObject(message.internationalMailingAddress, options);
            if (options.oneofs) {
              object.type = "internationalMailingAddress";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.DefaultPropertyType";
        }
      }

      DefaultPropertyType.prototype.firstName = "";
      DefaultPropertyType.prototype.lastName = "";
      DefaultPropertyType.prototype.dateOfBirth = null;
      DefaultPropertyType.prototype.emailAddress = "";
      DefaultPropertyType.prototype.phoneNumber = null;
      DefaultPropertyType.prototype.usMailingAddress = null;
      DefaultPropertyType.prototype.sexAssignedAtBirth = 0;
      DefaultPropertyType.prototype.socialSecurityNumber = "";
      DefaultPropertyType.prototype.genderIdentity = "";
      DefaultPropertyType.prototype.timezone = "";
      DefaultPropertyType.prototype.primaryInsurance = null;
      DefaultPropertyType.prototype.secondaryInsurance = null;
      DefaultPropertyType.prototype.preferredLanguage = "";
      DefaultPropertyType.prototype.nextOfKin = null;
      DefaultPropertyType.prototype.emergencyContact = null;
      DefaultPropertyType.prototype.internationalMailingAddress = null;

      return DefaultPropertyType;
    })();

    v1.CustomPropertyType = (() => {
      class CustomPropertyType {
        get type() {
          for (const key of ["stringProperty", "integerProperty", "booleanProperty", "timestampProperty", "durationProperty", "dateProperty", "urlProperty", "genericProperty", "usAddress", "internationalAddress", "person", "insurancePolicy", "labResult", "formAnswer", "phoneNumber", "doubleProperty", "stringArrayProperty"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["stringProperty", "integerProperty", "booleanProperty", "timestampProperty", "durationProperty", "dateProperty", "urlProperty", "genericProperty", "usAddress", "internationalAddress", "person", "insurancePolicy", "labResult", "formAnswer", "phoneNumber", "doubleProperty", "stringArrayProperty"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomPropertyType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stringProperty != null && Object.hasOwnProperty.call(message, "stringProperty")) {
            writer.uint32(10).string(message.stringProperty);
          }
          if (message.integerProperty != null && Object.hasOwnProperty.call(message, "integerProperty")) {
            writer.uint32(16).int64(message.integerProperty);
          }
          if (message.booleanProperty != null && Object.hasOwnProperty.call(message, "booleanProperty")) {
            writer.uint32(24).bool(message.booleanProperty);
          }
          if (message.timestampProperty != null && Object.hasOwnProperty.call(message, "timestampProperty")) {
            timing$1.v1.Timestamp.encode(message.timestampProperty, writer.uint32(34).fork()).ldelim();
          }
          if (message.durationProperty != null && Object.hasOwnProperty.call(message, "durationProperty")) {
            timing$1.v1.Duration.encode(message.durationProperty, writer.uint32(42).fork()).ldelim();
          }
          if (message.dateProperty != null && Object.hasOwnProperty.call(message, "dateProperty")) {
            values$1.v1.Date.encode(message.dateProperty, writer.uint32(50).fork()).ldelim();
          }
          if (message.urlProperty != null && Object.hasOwnProperty.call(message, "urlProperty")) {
            values$1.v1.URL.encode(message.urlProperty, writer.uint32(58).fork()).ldelim();
          }
          if (message.genericProperty != null && Object.hasOwnProperty.call(message, "genericProperty")) {
            values$1.v1.Value.encode(message.genericProperty, writer.uint32(66).fork()).ldelim();
          }
          if (message.usAddress != null && Object.hasOwnProperty.call(message, "usAddress")) {
            $root.profile_properties.v1.UsAddress.encode(message.usAddress, writer.uint32(74).fork()).ldelim();
          }
          if (message.internationalAddress != null && Object.hasOwnProperty.call(message, "internationalAddress")) {
            $root.profile_properties.v1.InternationalAddress.encode(message.internationalAddress, writer.uint32(82).fork()).ldelim();
          }
          if (message.person != null && Object.hasOwnProperty.call(message, "person")) {
            $root.profile_properties.v1.Person.encode(message.person, writer.uint32(90).fork()).ldelim();
          }
          if (message.insurancePolicy != null && Object.hasOwnProperty.call(message, "insurancePolicy")) {
            $root.profile_properties.v1.InsurancePolicy.encode(message.insurancePolicy, writer.uint32(98).fork()).ldelim();
          }
          if (message.labResult != null && Object.hasOwnProperty.call(message, "labResult")) {
            $root.profile_properties.v1.LabResult.encode(message.labResult, writer.uint32(106).fork()).ldelim();
          }
          if (message.formAnswer != null && Object.hasOwnProperty.call(message, "formAnswer")) {
            $root.profile_properties.v1.FormAnswer.encode(message.formAnswer, writer.uint32(114).fork()).ldelim();
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            phonenumbers$1.PhoneNumber.encode(message.phoneNumber, writer.uint32(122).fork()).ldelim();
          }
          if (message.doubleProperty != null && Object.hasOwnProperty.call(message, "doubleProperty")) {
            writer.uint32(129).double(message.doubleProperty);
          }
          if (message.stringArrayProperty != null && Object.hasOwnProperty.call(message, "stringArrayProperty")) {
            values$1.v1.ArrayValue.encode(message.stringArrayProperty, writer.uint32(138).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomPropertyType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stringProperty = reader.string();
                break;
              }
              case 2: {
                message.integerProperty = reader.int64();
                break;
              }
              case 3: {
                message.booleanProperty = reader.bool();
                break;
              }
              case 4: {
                message.timestampProperty = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.durationProperty = timing$1.v1.Duration.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.dateProperty = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.urlProperty = values$1.v1.URL.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.genericProperty = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.usAddress = $root.profile_properties.v1.UsAddress.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.internationalAddress = $root.profile_properties.v1.InternationalAddress.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.person = $root.profile_properties.v1.Person.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.insurancePolicy = $root.profile_properties.v1.InsurancePolicy.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.labResult = $root.profile_properties.v1.LabResult.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.formAnswer = $root.profile_properties.v1.FormAnswer.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.phoneNumber = phonenumbers$1.PhoneNumber.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.doubleProperty = reader.double();
                break;
              }
              case 17: {
                message.stringArrayProperty = values$1.v1.ArrayValue.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.CustomPropertyType) {
            return object;
          }
          const message = new $root.profile_properties.v1.CustomPropertyType();
          if (object.stringProperty != null) {
            message.stringProperty = String(object.stringProperty);
          }
          if (object.integerProperty != null) {
            if ($util.Long) {
              (message.integerProperty = $util.Long.fromValue(object.integerProperty)).unsigned = false;
            } else if (typeof object.integerProperty === "string") {
              message.integerProperty = parseInt(object.integerProperty, 10);
            } else if (typeof object.integerProperty === "number") {
              message.integerProperty = object.integerProperty;
            } else if (typeof object.integerProperty === "object") {
              message.integerProperty = new $util.LongBits(object.integerProperty.low >>> 0, object.integerProperty.high >>> 0).toNumber();
            }
          }
          if (object.booleanProperty != null) {
            message.booleanProperty = Boolean(object.booleanProperty);
          }
          if (object.timestampProperty != null) {
            if (typeof object.timestampProperty !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.timestampProperty: object expected, but got " + (typeof object.timestampProperty));
            }
            message.timestampProperty = timing$1.v1.Timestamp.fromObject(object.timestampProperty);
          }
          if (object.durationProperty != null) {
            if (typeof object.durationProperty !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.durationProperty: object expected, but got " + (typeof object.durationProperty));
            }
            message.durationProperty = timing$1.v1.Duration.fromObject(object.durationProperty);
          }
          if (object.dateProperty != null) {
            if (typeof object.dateProperty !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.dateProperty: object expected, but got " + (typeof object.dateProperty));
            }
            message.dateProperty = values$1.v1.Date.fromObject(object.dateProperty);
          }
          if (object.urlProperty != null) {
            if (typeof object.urlProperty !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.urlProperty: object expected, but got " + (typeof object.urlProperty));
            }
            message.urlProperty = values$1.v1.URL.fromObject(object.urlProperty);
          }
          if (object.genericProperty != null) {
            if (typeof object.genericProperty !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.genericProperty: object expected, but got " + (typeof object.genericProperty));
            }
            message.genericProperty = values$1.v1.Value.fromObject(object.genericProperty);
          }
          if (object.usAddress != null) {
            if (typeof object.usAddress !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.usAddress: object expected, but got " + (typeof object.usAddress));
            }
            message.usAddress = $root.profile_properties.v1.UsAddress.fromObject(object.usAddress);
          }
          if (object.internationalAddress != null) {
            if (typeof object.internationalAddress !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.internationalAddress: object expected, but got " + (typeof object.internationalAddress));
            }
            message.internationalAddress = $root.profile_properties.v1.InternationalAddress.fromObject(object.internationalAddress);
          }
          if (object.person != null) {
            if (typeof object.person !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.person: object expected, but got " + (typeof object.person));
            }
            message.person = $root.profile_properties.v1.Person.fromObject(object.person);
          }
          if (object.insurancePolicy != null) {
            if (typeof object.insurancePolicy !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.insurancePolicy: object expected, but got " + (typeof object.insurancePolicy));
            }
            message.insurancePolicy = $root.profile_properties.v1.InsurancePolicy.fromObject(object.insurancePolicy);
          }
          if (object.labResult != null) {
            if (typeof object.labResult !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.labResult: object expected, but got " + (typeof object.labResult));
            }
            message.labResult = $root.profile_properties.v1.LabResult.fromObject(object.labResult);
          }
          if (object.formAnswer != null) {
            if (typeof object.formAnswer !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.formAnswer: object expected, but got " + (typeof object.formAnswer));
            }
            message.formAnswer = $root.profile_properties.v1.FormAnswer.fromObject(object.formAnswer);
          }
          if (object.phoneNumber != null) {
            if (typeof object.phoneNumber !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.phoneNumber: object expected, but got " + (typeof object.phoneNumber));
            }
            message.phoneNumber = phonenumbers$1.PhoneNumber.fromObject(object.phoneNumber);
          }
          if (object.doubleProperty != null) {
            message.doubleProperty = Number(object.doubleProperty);
          }
          if (object.stringArrayProperty != null) {
            if (typeof object.stringArrayProperty !== "object") {
              throw new TypeError(".profile_properties.v1.CustomPropertyType.stringArrayProperty: object expected, but got " + (typeof object.stringArrayProperty));
            }
            message.stringArrayProperty = values$1.v1.ArrayValue.fromObject(object.stringArrayProperty);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.stringProperty != null && message.hasOwnProperty("stringProperty")) {
            object.stringProperty = message.stringProperty;
            if (options.oneofs) {
              object.type = "stringProperty";
            }
          }
          if (message.integerProperty != null && message.hasOwnProperty("integerProperty")) {
            object.integerProperty = typeof message.integerProperty === "number" ? (options.longs === String ? String(message.integerProperty) : message.integerProperty) : (options.longs === String ? $util.Long.prototype.toString.call(message.integerProperty) : options.longs === Number ? new $util.LongBits(message.integerProperty.low >>> 0, message.integerProperty.high >>> 0).toNumber() : message.integerProperty);
            if (options.oneofs) {
              object.type = "integerProperty";
            }
          }
          if (message.booleanProperty != null && message.hasOwnProperty("booleanProperty")) {
            object.booleanProperty = message.booleanProperty;
            if (options.oneofs) {
              object.type = "booleanProperty";
            }
          }
          if (message.timestampProperty != null && message.hasOwnProperty("timestampProperty")) {
            object.timestampProperty = timing$1.v1.Timestamp.toObject(message.timestampProperty, options);
            if (options.oneofs) {
              object.type = "timestampProperty";
            }
          }
          if (message.durationProperty != null && message.hasOwnProperty("durationProperty")) {
            object.durationProperty = timing$1.v1.Duration.toObject(message.durationProperty, options);
            if (options.oneofs) {
              object.type = "durationProperty";
            }
          }
          if (message.dateProperty != null && message.hasOwnProperty("dateProperty")) {
            object.dateProperty = values$1.v1.Date.toObject(message.dateProperty, options);
            if (options.oneofs) {
              object.type = "dateProperty";
            }
          }
          if (message.urlProperty != null && message.hasOwnProperty("urlProperty")) {
            object.urlProperty = values$1.v1.URL.toObject(message.urlProperty, options);
            if (options.oneofs) {
              object.type = "urlProperty";
            }
          }
          if (message.genericProperty != null && message.hasOwnProperty("genericProperty")) {
            object.genericProperty = values$1.v1.Value.toObject(message.genericProperty, options);
            if (options.oneofs) {
              object.type = "genericProperty";
            }
          }
          if (message.usAddress != null && message.hasOwnProperty("usAddress")) {
            object.usAddress = $root.profile_properties.v1.UsAddress.toObject(message.usAddress, options);
            if (options.oneofs) {
              object.type = "usAddress";
            }
          }
          if (message.internationalAddress != null && message.hasOwnProperty("internationalAddress")) {
            object.internationalAddress = $root.profile_properties.v1.InternationalAddress.toObject(message.internationalAddress, options);
            if (options.oneofs) {
              object.type = "internationalAddress";
            }
          }
          if (message.person != null && message.hasOwnProperty("person")) {
            object.person = $root.profile_properties.v1.Person.toObject(message.person, options);
            if (options.oneofs) {
              object.type = "person";
            }
          }
          if (message.insurancePolicy != null && message.hasOwnProperty("insurancePolicy")) {
            object.insurancePolicy = $root.profile_properties.v1.InsurancePolicy.toObject(message.insurancePolicy, options);
            if (options.oneofs) {
              object.type = "insurancePolicy";
            }
          }
          if (message.labResult != null && message.hasOwnProperty("labResult")) {
            object.labResult = $root.profile_properties.v1.LabResult.toObject(message.labResult, options);
            if (options.oneofs) {
              object.type = "labResult";
            }
          }
          if (message.formAnswer != null && message.hasOwnProperty("formAnswer")) {
            object.formAnswer = $root.profile_properties.v1.FormAnswer.toObject(message.formAnswer, options);
            if (options.oneofs) {
              object.type = "formAnswer";
            }
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = phonenumbers$1.PhoneNumber.toObject(message.phoneNumber, options);
            if (options.oneofs) {
              object.type = "phoneNumber";
            }
          }
          if (message.doubleProperty != null && message.hasOwnProperty("doubleProperty")) {
            object.doubleProperty = options.json && !isFinite(message.doubleProperty) ? String(message.doubleProperty) : message.doubleProperty;
            if (options.oneofs) {
              object.type = "doubleProperty";
            }
          }
          if (message.stringArrayProperty != null && message.hasOwnProperty("stringArrayProperty")) {
            object.stringArrayProperty = values$1.v1.ArrayValue.toObject(message.stringArrayProperty, options);
            if (options.oneofs) {
              object.type = "stringArrayProperty";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.CustomPropertyType";
        }
      }

      CustomPropertyType.prototype.stringProperty = "";
      CustomPropertyType.prototype.integerProperty = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      CustomPropertyType.prototype.booleanProperty = false;
      CustomPropertyType.prototype.timestampProperty = null;
      CustomPropertyType.prototype.durationProperty = null;
      CustomPropertyType.prototype.dateProperty = null;
      CustomPropertyType.prototype.urlProperty = null;
      CustomPropertyType.prototype.genericProperty = null;
      CustomPropertyType.prototype.usAddress = null;
      CustomPropertyType.prototype.internationalAddress = null;
      CustomPropertyType.prototype.person = null;
      CustomPropertyType.prototype.insurancePolicy = null;
      CustomPropertyType.prototype.labResult = null;
      CustomPropertyType.prototype.formAnswer = null;
      CustomPropertyType.prototype.phoneNumber = null;
      CustomPropertyType.prototype.doubleProperty = 0;
      CustomPropertyType.prototype.stringArrayProperty = null;

      return CustomPropertyType;
    })();

    v1.UsAddress = (() => {
      class UsAddress {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UsAddress(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.address1 != null && Object.hasOwnProperty.call(message, "address1")) {
            writer.uint32(10).string(message.address1);
          }
          if (message.address2 != null && Object.hasOwnProperty.call(message, "address2")) {
            writer.uint32(18).string(message.address2);
          }
          if (message.city != null && Object.hasOwnProperty.call(message, "city")) {
            writer.uint32(26).string(message.city);
          }
          if (message.state != null && Object.hasOwnProperty.call(message, "state")) {
            writer.uint32(34).string(message.state);
          }
          if (message.zipCode != null && Object.hasOwnProperty.call(message, "zipCode")) {
            writer.uint32(42).string(message.zipCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UsAddress();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.address1 = reader.string();
                break;
              }
              case 2: {
                message.address2 = reader.string();
                break;
              }
              case 3: {
                message.city = reader.string();
                break;
              }
              case 4: {
                message.state = reader.string();
                break;
              }
              case 5: {
                message.zipCode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.UsAddress) {
            return object;
          }
          const message = new $root.profile_properties.v1.UsAddress();
          if (object.address1 != null) {
            message.address1 = String(object.address1);
          }
          if (object.address2 != null) {
            message.address2 = String(object.address2);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.zipCode != null) {
            message.zipCode = String(object.zipCode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.address1 != null && message.hasOwnProperty("address1")) {
            object.address1 = message.address1;
          }
          if (message.address2 != null && message.hasOwnProperty("address2")) {
            object.address2 = message.address2;
          }
          if (message.city != null && message.hasOwnProperty("city")) {
            object.city = message.city;
          }
          if (message.state != null && message.hasOwnProperty("state")) {
            object.state = message.state;
          }
          if (message.zipCode != null && message.hasOwnProperty("zipCode")) {
            object.zipCode = message.zipCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.UsAddress";
        }
      }

      UsAddress.prototype.address1 = null;
      UsAddress.prototype.address2 = null;
      UsAddress.prototype.city = null;
      UsAddress.prototype.state = null;
      UsAddress.prototype.zipCode = null;

      return UsAddress;
    })();

    v1.InternationalAddress = (() => {
      class InternationalAddress {
        constructor(properties) {
          this.lines = [];
          this.cityStateCountyOrDistricts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InternationalAddress(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.lines != null && Object.hasOwnProperty.call(message, "lines")) {
            for (const element of message.lines) {
              writer.uint32(10).string(element);
            }
          }
          if (message.cityStateCountyOrDistricts != null && Object.hasOwnProperty.call(message, "cityStateCountyOrDistricts")) {
            for (const element of message.cityStateCountyOrDistricts) {
              writer.uint32(18).string(element);
            }
          }
          if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode")) {
            writer.uint32(26).string(message.postalCode);
          }
          if (message.country != null && Object.hasOwnProperty.call(message, "country")) {
            writer.uint32(34).string(message.country);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InternationalAddress();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.lines || !message.lines.length) {
                  message.lines = [];
                }
                message.lines.push(reader.string());
                break;
              }
              case 2: {
                if (!message.cityStateCountyOrDistricts || !message.cityStateCountyOrDistricts.length) {
                  message.cityStateCountyOrDistricts = [];
                }
                message.cityStateCountyOrDistricts.push(reader.string());
                break;
              }
              case 3: {
                message.postalCode = reader.string();
                break;
              }
              case 4: {
                message.country = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.InternationalAddress) {
            return object;
          }
          const message = new $root.profile_properties.v1.InternationalAddress();
          if (object.lines) {
            if (!Array.isArray(object.lines)) {
              throw new TypeError(".profile_properties.v1.InternationalAddress.lines: array type expected, but got " + (typeof object.lines))
            }
            message.lines = new Array(object.lines.length);
            for (let i = 0; i < object.lines.length; ++i) {
              message.lines[i] = String(object.lines[i]);
            }
          }
          if (object.cityStateCountyOrDistricts) {
            if (!Array.isArray(object.cityStateCountyOrDistricts)) {
              throw new TypeError(".profile_properties.v1.InternationalAddress.cityStateCountyOrDistricts: array type expected, but got " + (typeof object.cityStateCountyOrDistricts))
            }
            message.cityStateCountyOrDistricts = new Array(object.cityStateCountyOrDistricts.length);
            for (let i = 0; i < object.cityStateCountyOrDistricts.length; ++i) {
              message.cityStateCountyOrDistricts[i] = String(object.cityStateCountyOrDistricts[i]);
            }
          }
          if (object.postalCode != null) {
            message.postalCode = String(object.postalCode);
          }
          if (object.country != null) {
            message.country = String(object.country);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.lines = [];
            object.cityStateCountyOrDistricts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.lines && message.lines.length) {
            object.lines = new Array(message.lines.length);
            for (let i = 0; i < message.lines.length; ++i) {
              object.lines[i] = message.lines[i];
            }
          }
          if (message.cityStateCountyOrDistricts && message.cityStateCountyOrDistricts.length) {
            object.cityStateCountyOrDistricts = new Array(message.cityStateCountyOrDistricts.length);
            for (let i = 0; i < message.cityStateCountyOrDistricts.length; ++i) {
              object.cityStateCountyOrDistricts[i] = message.cityStateCountyOrDistricts[i];
            }
          }
          if (message.postalCode != null && message.hasOwnProperty("postalCode")) {
            object.postalCode = message.postalCode;
          }
          if (message.country != null && message.hasOwnProperty("country")) {
            object.country = message.country;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.InternationalAddress";
        }
      }

      InternationalAddress.prototype.lines = $util.emptyArray;
      InternationalAddress.prototype.cityStateCountyOrDistricts = $util.emptyArray;
      InternationalAddress.prototype.postalCode = null;
      InternationalAddress.prototype.country = null;

      return InternationalAddress;
    })();

    v1.Person = (() => {
      class Person {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Person(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(10).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(18).string(message.lastName);
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(26).string(message.emailAddress);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            phonenumbers$1.PhoneNumber.encode(message.phoneNumber, writer.uint32(34).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Person();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.firstName = reader.string();
                break;
              }
              case 2: {
                message.lastName = reader.string();
                break;
              }
              case 3: {
                message.emailAddress = reader.string();
                break;
              }
              case 4: {
                message.phoneNumber = phonenumbers$1.PhoneNumber.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.Person) {
            return object;
          }
          const message = new $root.profile_properties.v1.Person();
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            if (typeof object.phoneNumber !== "object") {
              throw new TypeError(".profile_properties.v1.Person.phoneNumber: object expected, but got " + (typeof object.phoneNumber));
            }
            message.phoneNumber = phonenumbers$1.PhoneNumber.fromObject(object.phoneNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = phonenumbers$1.PhoneNumber.toObject(message.phoneNumber, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.Person";
        }
      }

      Person.prototype.firstName = null;
      Person.prototype.lastName = null;
      Person.prototype.emailAddress = null;
      Person.prototype.phoneNumber = null;

      return Person;
    })();

    v1.InsurancePolicy = (() => {
      class InsurancePolicy {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InsurancePolicy(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.holderRelationship != null && Object.hasOwnProperty.call(message, "holderRelationship")) {
            writer.uint32(10).string(message.holderRelationship);
          }
          if (message.holderDateOfBirth != null && Object.hasOwnProperty.call(message, "holderDateOfBirth")) {
            values$1.v1.Date.encode(message.holderDateOfBirth, writer.uint32(18).fork()).ldelim();
          }
          if (message.payerName != null && Object.hasOwnProperty.call(message, "payerName")) {
            writer.uint32(26).string(message.payerName);
          }
          if (message.primaryInsuredFirstName != null && Object.hasOwnProperty.call(message, "primaryInsuredFirstName")) {
            writer.uint32(34).string(message.primaryInsuredFirstName);
          }
          if (message.primaryInsuredLastName != null && Object.hasOwnProperty.call(message, "primaryInsuredLastName")) {
            writer.uint32(42).string(message.primaryInsuredLastName);
          }
          if (message.planName != null && Object.hasOwnProperty.call(message, "planName")) {
            writer.uint32(50).string(message.planName);
          }
          if (message.payerId != null && Object.hasOwnProperty.call(message, "payerId")) {
            writer.uint32(58).string(message.payerId);
          }
          if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId")) {
            writer.uint32(66).string(message.memberId);
          }
          if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId")) {
            writer.uint32(74).string(message.groupId);
          }
          if (message.cardFrontUrl != null && Object.hasOwnProperty.call(message, "cardFrontUrl")) {
            values$1.v1.URL.encode(message.cardFrontUrl, writer.uint32(82).fork()).ldelim();
          }
          if (message.cardBackUrl != null && Object.hasOwnProperty.call(message, "cardBackUrl")) {
            values$1.v1.URL.encode(message.cardBackUrl, writer.uint32(90).fork()).ldelim();
          }
          if (message.coverageStartDate != null && Object.hasOwnProperty.call(message, "coverageStartDate")) {
            values$1.v1.Date.encode(message.coverageStartDate, writer.uint32(98).fork()).ldelim();
          }
          if (message.coverageEndDate != null && Object.hasOwnProperty.call(message, "coverageEndDate")) {
            values$1.v1.Date.encode(message.coverageEndDate, writer.uint32(106).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InsurancePolicy();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.holderRelationship = reader.string();
                break;
              }
              case 2: {
                message.holderDateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.payerName = reader.string();
                break;
              }
              case 4: {
                message.primaryInsuredFirstName = reader.string();
                break;
              }
              case 5: {
                message.primaryInsuredLastName = reader.string();
                break;
              }
              case 6: {
                message.planName = reader.string();
                break;
              }
              case 7: {
                message.payerId = reader.string();
                break;
              }
              case 8: {
                message.memberId = reader.string();
                break;
              }
              case 9: {
                message.groupId = reader.string();
                break;
              }
              case 10: {
                message.cardFrontUrl = values$1.v1.URL.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.cardBackUrl = values$1.v1.URL.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.coverageStartDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.coverageEndDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.InsurancePolicy) {
            return object;
          }
          const message = new $root.profile_properties.v1.InsurancePolicy();
          if (object.holderRelationship != null) {
            message.holderRelationship = String(object.holderRelationship);
          }
          if (object.holderDateOfBirth != null) {
            if (typeof object.holderDateOfBirth !== "object") {
              throw new TypeError(".profile_properties.v1.InsurancePolicy.holderDateOfBirth: object expected, but got " + (typeof object.holderDateOfBirth));
            }
            message.holderDateOfBirth = values$1.v1.Date.fromObject(object.holderDateOfBirth);
          }
          if (object.payerName != null) {
            message.payerName = String(object.payerName);
          }
          if (object.primaryInsuredFirstName != null) {
            message.primaryInsuredFirstName = String(object.primaryInsuredFirstName);
          }
          if (object.primaryInsuredLastName != null) {
            message.primaryInsuredLastName = String(object.primaryInsuredLastName);
          }
          if (object.planName != null) {
            message.planName = String(object.planName);
          }
          if (object.payerId != null) {
            message.payerId = String(object.payerId);
          }
          if (object.memberId != null) {
            message.memberId = String(object.memberId);
          }
          if (object.groupId != null) {
            message.groupId = String(object.groupId);
          }
          if (object.cardFrontUrl != null) {
            if (typeof object.cardFrontUrl !== "object") {
              throw new TypeError(".profile_properties.v1.InsurancePolicy.cardFrontUrl: object expected, but got " + (typeof object.cardFrontUrl));
            }
            message.cardFrontUrl = values$1.v1.URL.fromObject(object.cardFrontUrl);
          }
          if (object.cardBackUrl != null) {
            if (typeof object.cardBackUrl !== "object") {
              throw new TypeError(".profile_properties.v1.InsurancePolicy.cardBackUrl: object expected, but got " + (typeof object.cardBackUrl));
            }
            message.cardBackUrl = values$1.v1.URL.fromObject(object.cardBackUrl);
          }
          if (object.coverageStartDate != null) {
            if (typeof object.coverageStartDate !== "object") {
              throw new TypeError(".profile_properties.v1.InsurancePolicy.coverageStartDate: object expected, but got " + (typeof object.coverageStartDate));
            }
            message.coverageStartDate = values$1.v1.Date.fromObject(object.coverageStartDate);
          }
          if (object.coverageEndDate != null) {
            if (typeof object.coverageEndDate !== "object") {
              throw new TypeError(".profile_properties.v1.InsurancePolicy.coverageEndDate: object expected, but got " + (typeof object.coverageEndDate));
            }
            message.coverageEndDate = values$1.v1.Date.fromObject(object.coverageEndDate);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.holderRelationship != null && message.hasOwnProperty("holderRelationship")) {
            object.holderRelationship = message.holderRelationship;
          }
          if (message.holderDateOfBirth != null && message.hasOwnProperty("holderDateOfBirth")) {
            object.holderDateOfBirth = values$1.v1.Date.toObject(message.holderDateOfBirth, options);
          }
          if (message.payerName != null && message.hasOwnProperty("payerName")) {
            object.payerName = message.payerName;
          }
          if (message.primaryInsuredFirstName != null && message.hasOwnProperty("primaryInsuredFirstName")) {
            object.primaryInsuredFirstName = message.primaryInsuredFirstName;
          }
          if (message.primaryInsuredLastName != null && message.hasOwnProperty("primaryInsuredLastName")) {
            object.primaryInsuredLastName = message.primaryInsuredLastName;
          }
          if (message.planName != null && message.hasOwnProperty("planName")) {
            object.planName = message.planName;
          }
          if (message.payerId != null && message.hasOwnProperty("payerId")) {
            object.payerId = message.payerId;
          }
          if (message.memberId != null && message.hasOwnProperty("memberId")) {
            object.memberId = message.memberId;
          }
          if (message.groupId != null && message.hasOwnProperty("groupId")) {
            object.groupId = message.groupId;
          }
          if (message.cardFrontUrl != null && message.hasOwnProperty("cardFrontUrl")) {
            object.cardFrontUrl = values$1.v1.URL.toObject(message.cardFrontUrl, options);
          }
          if (message.cardBackUrl != null && message.hasOwnProperty("cardBackUrl")) {
            object.cardBackUrl = values$1.v1.URL.toObject(message.cardBackUrl, options);
          }
          if (message.coverageStartDate != null && message.hasOwnProperty("coverageStartDate")) {
            object.coverageStartDate = values$1.v1.Date.toObject(message.coverageStartDate, options);
          }
          if (message.coverageEndDate != null && message.hasOwnProperty("coverageEndDate")) {
            object.coverageEndDate = values$1.v1.Date.toObject(message.coverageEndDate, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.InsurancePolicy";
        }
      }

      InsurancePolicy.prototype.holderRelationship = null;
      InsurancePolicy.prototype.holderDateOfBirth = null;
      InsurancePolicy.prototype.payerName = null;
      InsurancePolicy.prototype.primaryInsuredFirstName = null;
      InsurancePolicy.prototype.primaryInsuredLastName = null;
      InsurancePolicy.prototype.planName = null;
      InsurancePolicy.prototype.payerId = null;
      InsurancePolicy.prototype.memberId = null;
      InsurancePolicy.prototype.groupId = null;
      InsurancePolicy.prototype.cardFrontUrl = null;
      InsurancePolicy.prototype.cardBackUrl = null;
      InsurancePolicy.prototype.coverageStartDate = null;
      InsurancePolicy.prototype.coverageEndDate = null;

      return InsurancePolicy;
    })();

    v1.LabResult = (() => {
      class LabResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LabResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(18).string(message.type);
          }
          if (message.units != null && Object.hasOwnProperty.call(message, "units")) {
            writer.uint32(26).string(message.units);
          }
          if (message.resultInterpretation != null && Object.hasOwnProperty.call(message, "resultInterpretation")) {
            writer.uint32(34).string(message.resultInterpretation);
          }
          if (message.labComments != null && Object.hasOwnProperty.call(message, "labComments")) {
            writer.uint32(42).string(message.labComments);
          }
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            values$1.v1.Value.encode(message.value, writer.uint32(50).fork()).ldelim();
          }
          if (message.referenceRangeLow != null && Object.hasOwnProperty.call(message, "referenceRangeLow")) {
            values$1.v1.Value.encode(message.referenceRangeLow, writer.uint32(58).fork()).ldelim();
          }
          if (message.referenceRangeHigh != null && Object.hasOwnProperty.call(message, "referenceRangeHigh")) {
            values$1.v1.Value.encode(message.referenceRangeHigh, writer.uint32(66).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LabResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.type = reader.string();
                break;
              }
              case 3: {
                message.units = reader.string();
                break;
              }
              case 4: {
                message.resultInterpretation = reader.string();
                break;
              }
              case 5: {
                message.labComments = reader.string();
                break;
              }
              case 6: {
                message.value = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.referenceRangeLow = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.referenceRangeHigh = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.LabResult) {
            return object;
          }
          const message = new $root.profile_properties.v1.LabResult();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.units != null) {
            message.units = String(object.units);
          }
          if (object.resultInterpretation != null) {
            message.resultInterpretation = String(object.resultInterpretation);
          }
          if (object.labComments != null) {
            message.labComments = String(object.labComments);
          }
          if (object.value != null) {
            if (typeof object.value !== "object") {
              throw new TypeError(".profile_properties.v1.LabResult.value: object expected, but got " + (typeof object.value));
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.referenceRangeLow != null) {
            if (typeof object.referenceRangeLow !== "object") {
              throw new TypeError(".profile_properties.v1.LabResult.referenceRangeLow: object expected, but got " + (typeof object.referenceRangeLow));
            }
            message.referenceRangeLow = values$1.v1.Value.fromObject(object.referenceRangeLow);
          }
          if (object.referenceRangeHigh != null) {
            if (typeof object.referenceRangeHigh !== "object") {
              throw new TypeError(".profile_properties.v1.LabResult.referenceRangeHigh: object expected, but got " + (typeof object.referenceRangeHigh));
            }
            message.referenceRangeHigh = values$1.v1.Value.fromObject(object.referenceRangeHigh);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = message.type;
          }
          if (message.units != null && message.hasOwnProperty("units")) {
            object.units = message.units;
          }
          if (message.resultInterpretation != null && message.hasOwnProperty("resultInterpretation")) {
            object.resultInterpretation = message.resultInterpretation;
          }
          if (message.labComments != null && message.hasOwnProperty("labComments")) {
            object.labComments = message.labComments;
          }
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (message.referenceRangeLow != null && message.hasOwnProperty("referenceRangeLow")) {
            object.referenceRangeLow = values$1.v1.Value.toObject(message.referenceRangeLow, options);
          }
          if (message.referenceRangeHigh != null && message.hasOwnProperty("referenceRangeHigh")) {
            object.referenceRangeHigh = values$1.v1.Value.toObject(message.referenceRangeHigh, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.LabResult";
        }
      }

      LabResult.prototype.id = null;
      LabResult.prototype.type = null;
      LabResult.prototype.units = null;
      LabResult.prototype.resultInterpretation = null;
      LabResult.prototype.labComments = null;
      LabResult.prototype.value = null;
      LabResult.prototype.referenceRangeLow = null;
      LabResult.prototype.referenceRangeHigh = null;

      return LabResult;
    })();

    v1.FormAnswer = (() => {
      class FormAnswer {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormAnswer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            writer.uint32(16).uint32(message.index);
          }
          if (message.answer != null && Object.hasOwnProperty.call(message, "answer")) {
            values$1.v1.Value.encode(message.answer, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormAnswer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.index = reader.uint32();
                break;
              }
              case 3: {
                message.answer = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.FormAnswer) {
            return object;
          }
          const message = new $root.profile_properties.v1.FormAnswer();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.index != null) {
            message.index = object.index >>> 0;
          }
          if (object.answer != null) {
            if (typeof object.answer !== "object") {
              throw new TypeError(".profile_properties.v1.FormAnswer.answer: object expected, but got " + (typeof object.answer));
            }
            message.answer = values$1.v1.Value.fromObject(object.answer);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = message.index;
          }
          if (message.answer != null && message.hasOwnProperty("answer")) {
            object.answer = values$1.v1.Value.toObject(message.answer, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.FormAnswer";
        }
      }

      FormAnswer.prototype.id = null;
      FormAnswer.prototype.index = null;
      FormAnswer.prototype.answer = null;

      return FormAnswer;
    })();

    v1.PropertyValue = (() => {
      class PropertyValue {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PropertyValue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileProperty != null && Object.hasOwnProperty.call(message, "profileProperty")) {
            $root.profile_properties.v1.ProfileProperty.encode(message.profileProperty, writer.uint32(10).fork()).ldelim();
          }
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            google$1.protobuf.Value.encode(message.value, writer.uint32(18).fork()).ldelim();
          }
          if (message.detailedValue != null && Object.hasOwnProperty.call(message, "detailedValue")) {
            $root.profile_properties.v1.DetailedPropertyValue.encode(message.detailedValue, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PropertyValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileProperty = $root.profile_properties.v1.ProfileProperty.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.value = google$1.protobuf.Value.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.detailedValue = $root.profile_properties.v1.DetailedPropertyValue.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.PropertyValue) {
            return object;
          }
          const message = new $root.profile_properties.v1.PropertyValue();
          if (object.profileProperty != null) {
            if (typeof object.profileProperty !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyValue.profileProperty: object expected, but got " + (typeof object.profileProperty));
            }
            message.profileProperty = $root.profile_properties.v1.ProfileProperty.fromObject(object.profileProperty);
          }
          if (object.value != null) {
            if (typeof object.value !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyValue.value: object expected, but got " + (typeof object.value));
            }
            message.value = google$1.protobuf.Value.fromObject(object.value);
          }
          if (object.detailedValue != null) {
            if (typeof object.detailedValue !== "object") {
              throw new TypeError(".profile_properties.v1.PropertyValue.detailedValue: object expected, but got " + (typeof object.detailedValue));
            }
            message.detailedValue = $root.profile_properties.v1.DetailedPropertyValue.fromObject(object.detailedValue);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profileProperty = null;
            object.value = null;
            object.detailedValue = null;
          }
          let keys;
          if (message.profileProperty != null && message.hasOwnProperty("profileProperty")) {
            object.profileProperty = $root.profile_properties.v1.ProfileProperty.toObject(message.profileProperty, options);
          }
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = google$1.protobuf.Value.toObject(message.value, options);
          }
          if (message.detailedValue != null && message.hasOwnProperty("detailedValue")) {
            object.detailedValue = $root.profile_properties.v1.DetailedPropertyValue.toObject(message.detailedValue, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.PropertyValue";
        }
      }

      PropertyValue.prototype.profileProperty = null;
      PropertyValue.prototype.value = null;
      PropertyValue.prototype.detailedValue = null;

      return PropertyValue;
    })();

    v1.DetailedPropertyValue = (() => {
      class DetailedPropertyValue {
        get type() {
          for (const key of ["object", "scalar"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["object", "scalar"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DetailedPropertyValue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.object != null && Object.hasOwnProperty.call(message, "object")) {
            $root.profile_properties.v1.DetailedPropertyValue.ObjectValues.encode(message.object, writer.uint32(10).fork()).ldelim();
          }
          if (message.scalar != null && Object.hasOwnProperty.call(message, "scalar")) {
            $root.profile_properties.v1.DetailedPropertyValue.ScalarValue.encode(message.scalar, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DetailedPropertyValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.object = $root.profile_properties.v1.DetailedPropertyValue.ObjectValues.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.scalar = $root.profile_properties.v1.DetailedPropertyValue.ScalarValue.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profile_properties.v1.DetailedPropertyValue) {
            return object;
          }
          const message = new $root.profile_properties.v1.DetailedPropertyValue();
          if (object.object != null) {
            if (typeof object.object !== "object") {
              throw new TypeError(".profile_properties.v1.DetailedPropertyValue.object: object expected, but got " + (typeof object.object));
            }
            message.object = $root.profile_properties.v1.DetailedPropertyValue.ObjectValues.fromObject(object.object);
          }
          if (object.scalar != null) {
            if (typeof object.scalar !== "object") {
              throw new TypeError(".profile_properties.v1.DetailedPropertyValue.scalar: object expected, but got " + (typeof object.scalar));
            }
            message.scalar = $root.profile_properties.v1.DetailedPropertyValue.ScalarValue.fromObject(object.scalar);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.object != null && message.hasOwnProperty("object")) {
            object.object = $root.profile_properties.v1.DetailedPropertyValue.ObjectValues.toObject(message.object, options);
            if (options.oneofs) {
              object.type = "object";
            }
          }
          if (message.scalar != null && message.hasOwnProperty("scalar")) {
            object.scalar = $root.profile_properties.v1.DetailedPropertyValue.ScalarValue.toObject(message.scalar, options);
            if (options.oneofs) {
              object.type = "scalar";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profile_properties.v1.DetailedPropertyValue";
        }
      }

      DetailedPropertyValue.prototype.object = null;
      DetailedPropertyValue.prototype.scalar = null;
      DetailedPropertyValue.ValueAtTime = (() => {
        class ValueAtTime {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ValueAtTime(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
              values$1.v1.Value.encode(message.value, writer.uint32(10).fork()).ldelim();
            }
            if (message.storedAt != null && Object.hasOwnProperty.call(message, "storedAt")) {
              timing$1.v1.Timestamp.encode(message.storedAt, writer.uint32(18).fork()).ldelim();
            }
            if (message.valueHash != null && Object.hasOwnProperty.call(message, "valueHash")) {
              writer.uint32(24).int64(message.valueHash);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ValueAtTime();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.value = values$1.v1.Value.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.storedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.valueHash = reader.int64();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime) {
              return object;
            }
            const message = new $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime();
            if (object.value != null) {
              if (typeof object.value !== "object") {
                throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ValueAtTime.value: object expected, but got " + (typeof object.value));
              }
              message.value = values$1.v1.Value.fromObject(object.value);
            }
            if (object.storedAt != null) {
              if (typeof object.storedAt !== "object") {
                throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ValueAtTime.storedAt: object expected, but got " + (typeof object.storedAt));
              }
              message.storedAt = timing$1.v1.Timestamp.fromObject(object.storedAt);
            }
            if (object.valueHash != null) {
              if ($util.Long) {
                (message.valueHash = $util.Long.fromValue(object.valueHash)).unsigned = false;
              } else if (typeof object.valueHash === "string") {
                message.valueHash = parseInt(object.valueHash, 10);
              } else if (typeof object.valueHash === "number") {
                message.valueHash = object.valueHash;
              } else if (typeof object.valueHash === "object") {
                message.valueHash = new $util.LongBits(object.valueHash.low >>> 0, object.valueHash.high >>> 0).toNumber();
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.value = null;
              object.storedAt = null;
              if ($util.Long) {
                let long = new $util.Long(0, 0, false)
                object.valueHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.valueHash = options.longs === String ? "0" : 0;
              }
            }
            let keys;
            if (message.value != null && message.hasOwnProperty("value")) {
              object.value = values$1.v1.Value.toObject(message.value, options);
            }
            if (message.storedAt != null && message.hasOwnProperty("storedAt")) {
              object.storedAt = timing$1.v1.Timestamp.toObject(message.storedAt, options);
            }
            if (message.valueHash != null && message.hasOwnProperty("valueHash")) {
              object.valueHash = typeof message.valueHash === "number" ? (options.longs === String ? String(message.valueHash) : message.valueHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.valueHash) : options.longs === Number ? new $util.LongBits(message.valueHash.low >>> 0, message.valueHash.high >>> 0).toNumber() : message.valueHash);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profile_properties.v1.DetailedPropertyValue.ValueAtTime";
          }
        }

        ValueAtTime.prototype.value = null;
        ValueAtTime.prototype.storedAt = null;
        ValueAtTime.prototype.valueHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

        return ValueAtTime;
      })();

      DetailedPropertyValue.ValuesOverTime = (() => {
        class ValuesOverTime {
          constructor(properties) {
            this.values = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ValuesOverTime(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.values != null && Object.hasOwnProperty.call(message, "values")) {
              for (const element of message.values) {
                $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ValuesOverTime();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.values || !message.values.length) {
                    message.values = [];
                  }
                  message.values.push($root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profile_properties.v1.DetailedPropertyValue.ValuesOverTime) {
              return object;
            }
            const message = new $root.profile_properties.v1.DetailedPropertyValue.ValuesOverTime();
            if (object.values) {
              if (!Array.isArray(object.values)) {
                throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ValuesOverTime.values: array type expected, but got " + (typeof object.values))
              }
              message.values = new Array(object.values.length);
              for (let i = 0; i < object.values.length; ++i) {
                if (typeof object.values[i] !== "object") {
                  throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ValuesOverTime.values: object expected, but got " + (typeof object.values[i]));
                }
                message.values[i] = $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.fromObject(object.values[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.values = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.values && message.values.length) {
              object.values = new Array(message.values.length);
              for (let i = 0; i < message.values.length; ++i) {
                object.values[i] = $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.toObject(message.values[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profile_properties.v1.DetailedPropertyValue.ValuesOverTime";
          }
        }

        ValuesOverTime.prototype.values = $util.emptyArray;

        return ValuesOverTime;
      })();

      DetailedPropertyValue.ObjectValues = (() => {
        class ObjectValues {
          constructor(properties) {
            this.fields = {};
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ObjectValues(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.fields != null && Object.hasOwnProperty.call(message, "fields")) {
              for (const key of Object.keys(message.fields)) {
                writer.uint32(10).fork().uint32(10).string(key);
                $root.profile_properties.v1.DetailedPropertyValue.encode(message.fields[key], writer.uint32(18).fork()).ldelim().ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ObjectValues();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (message.fields === $util.emptyObject) message.fields = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = "";
                  value = null;
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.string();
                        break;
                      }
                      case 2: {
                        value = $root.profile_properties.v1.DetailedPropertyValue.decode(reader, reader.uint32());
                        break;
                      }
                    }
                    message.fields[key] = value;
                  }
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profile_properties.v1.DetailedPropertyValue.ObjectValues) {
              return object;
            }
            const message = new $root.profile_properties.v1.DetailedPropertyValue.ObjectValues();
            if (object.fields) {
              if (typeof object.fields !== "object") {
                throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ObjectValues.fields: object expected, but got " + (typeof object.fields));
              }
              message.fields = {};
              for (let keys = Object.keys(object.fields), i = 0; i < keys.length; ++i) {
                if (typeof object.fields[keys[i]] !== "object") {
                  throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ObjectValues.value: object expected, but got " + (typeof object.fields[keys[i]]));
                }
                message.fields[keys[i]] = $root.profile_properties.v1.DetailedPropertyValue.fromObject(object.fields[keys[i]]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
              object.fields = {};
            }
            if (options.defaults) {
            }
            let keys;
            if (message.fields && (keys = Object.keys(message.fields)).length) {
              object.fields = {};
              for (let i = 0; i < keys.length; ++i) {
                object.fields[keys[i]] = $root.profile_properties.v1.DetailedPropertyValue.toObject(message.fields[keys[i]], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profile_properties.v1.DetailedPropertyValue.ObjectValues";
          }
        }

        ObjectValues.prototype.fields = $util.emptyObject;

        return ObjectValues;
      })();

      DetailedPropertyValue.ScalarValue = (() => {
        class ScalarValue {
          get value() {
            for (const key of ["valueAtTime", "valuesOverTime"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set value(name) {
            for (const key of ["valueAtTime", "valuesOverTime"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ScalarValue(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.valueAtTime != null && Object.hasOwnProperty.call(message, "valueAtTime")) {
              $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.encode(message.valueAtTime, writer.uint32(10).fork()).ldelim();
            }
            if (message.valuesOverTime != null && Object.hasOwnProperty.call(message, "valuesOverTime")) {
              $root.profile_properties.v1.DetailedPropertyValue.ValuesOverTime.encode(message.valuesOverTime, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ScalarValue();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.valueAtTime = $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.valuesOverTime = $root.profile_properties.v1.DetailedPropertyValue.ValuesOverTime.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profile_properties.v1.DetailedPropertyValue.ScalarValue) {
              return object;
            }
            const message = new $root.profile_properties.v1.DetailedPropertyValue.ScalarValue();
            if (object.valueAtTime != null) {
              if (typeof object.valueAtTime !== "object") {
                throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ScalarValue.valueAtTime: object expected, but got " + (typeof object.valueAtTime));
              }
              message.valueAtTime = $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.fromObject(object.valueAtTime);
            }
            if (object.valuesOverTime != null) {
              if (typeof object.valuesOverTime !== "object") {
                throw new TypeError(".profile_properties.v1.DetailedPropertyValue.ScalarValue.valuesOverTime: object expected, but got " + (typeof object.valuesOverTime));
              }
              message.valuesOverTime = $root.profile_properties.v1.DetailedPropertyValue.ValuesOverTime.fromObject(object.valuesOverTime);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.valueAtTime != null && message.hasOwnProperty("valueAtTime")) {
              object.valueAtTime = $root.profile_properties.v1.DetailedPropertyValue.ValueAtTime.toObject(message.valueAtTime, options);
              if (options.oneofs) {
                object.value = "valueAtTime";
              }
            }
            if (message.valuesOverTime != null && message.hasOwnProperty("valuesOverTime")) {
              object.valuesOverTime = $root.profile_properties.v1.DetailedPropertyValue.ValuesOverTime.toObject(message.valuesOverTime, options);
              if (options.oneofs) {
                object.value = "valuesOverTime";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profile_properties.v1.DetailedPropertyValue.ScalarValue";
          }
        }

        ScalarValue.prototype.valueAtTime = null;
        ScalarValue.prototype.valuesOverTime = null;

        return ScalarValue;
      })();

      return DetailedPropertyValue;
    })();

    v1.SexAssignedAtBirth = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_SEX_ASSIGNED_AT_BIRTH"] = 0;
      values[valuesById[1] = "FEMALE"] = 1;
      values[valuesById[2] = "MALE"] = 2;
      values[valuesById[3] = "INTERSEX"] = 3;
      values[valuesById[4] = "OTHER"] = 4;
      return values;
    })();

    v1.PropertyValueVersion = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_PROPERTY_VALUE_VERSION"] = 0;
      values[valuesById[1] = "CURRENT_VALUE"] = 1;
      values[valuesById[2] = "OLDEST_VALUE"] = 2;
      values[valuesById[3] = "ALL_VALUES"] = 3;
      return values;
    })();

    return v1;
  })();

  return profile_properties;
})();

export const profile_properties = $root.profile_properties;
