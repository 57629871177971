import { styled } from '@morf/theming';

export const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
` as unknown as React.ForwardRefExoticComponent<
  React.InputHTMLAttributes<HTMLInputElement> &
    React.RefAttributes<HTMLInputElement>
>;
