"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./empty_ts_proto";
import { phonenumbers as phonenumbers$1 } from "./phonenumbers_ts_proto";
import { sensitivity as sensitivity$1 } from "./sensitivity_v1_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.values = (() => {
  const values = {};
  values.v1 = (() => {
    const v1 = {};
    v1.ValueType = (() => {
      class ValueType {
        get type() {
          for (const key of ["bool", "string", "timestamp", "duration", "date", "double", "int64", "uint64", "bytes", "bigInteger", "bigDecimal", "array", "object", "numericRange", "comparison", "ratio", "stringEnum", "intEnum", "url", "doubleEnum", "phoneNumber", "generic"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["bool", "string", "timestamp", "duration", "date", "double", "int64", "uint64", "bytes", "bigInteger", "bigDecimal", "array", "object", "numericRange", "comparison", "ratio", "stringEnum", "intEnum", "url", "doubleEnum", "phoneNumber", "generic"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ValueType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.bool != null && Object.hasOwnProperty.call(message, "bool")) {
            google$1.protobuf.Empty.encode(message.bool, writer.uint32(10).fork()).ldelim();
          }
          if (message.string != null && Object.hasOwnProperty.call(message, "string")) {
            google$1.protobuf.Empty.encode(message.string, writer.uint32(18).fork()).ldelim();
          }
          if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp")) {
            google$1.protobuf.Empty.encode(message.timestamp, writer.uint32(26).fork()).ldelim();
          }
          if (message.duration != null && Object.hasOwnProperty.call(message, "duration")) {
            google$1.protobuf.Empty.encode(message.duration, writer.uint32(34).fork()).ldelim();
          }
          if (message.date != null && Object.hasOwnProperty.call(message, "date")) {
            google$1.protobuf.Empty.encode(message.date, writer.uint32(42).fork()).ldelim();
          }
          if (message.double != null && Object.hasOwnProperty.call(message, "double")) {
            google$1.protobuf.Empty.encode(message.double, writer.uint32(50).fork()).ldelim();
          }
          if (message.int64 != null && Object.hasOwnProperty.call(message, "int64")) {
            google$1.protobuf.Empty.encode(message.int64, writer.uint32(58).fork()).ldelim();
          }
          if (message.uint64 != null && Object.hasOwnProperty.call(message, "uint64")) {
            google$1.protobuf.Empty.encode(message.uint64, writer.uint32(66).fork()).ldelim();
          }
          if (message.bytes != null && Object.hasOwnProperty.call(message, "bytes")) {
            google$1.protobuf.Empty.encode(message.bytes, writer.uint32(74).fork()).ldelim();
          }
          if (message.bigInteger != null && Object.hasOwnProperty.call(message, "bigInteger")) {
            google$1.protobuf.Empty.encode(message.bigInteger, writer.uint32(82).fork()).ldelim();
          }
          if (message.bigDecimal != null && Object.hasOwnProperty.call(message, "bigDecimal")) {
            google$1.protobuf.Empty.encode(message.bigDecimal, writer.uint32(90).fork()).ldelim();
          }
          if (message.array != null && Object.hasOwnProperty.call(message, "array")) {
            $root.values.v1.ArrayType.encode(message.array, writer.uint32(98).fork()).ldelim();
          }
          if (message.object != null && Object.hasOwnProperty.call(message, "object")) {
            google$1.protobuf.Empty.encode(message.object, writer.uint32(106).fork()).ldelim();
          }
          if (message.numericRange != null && Object.hasOwnProperty.call(message, "numericRange")) {
            $root.values.v1.NumericRangeType.encode(message.numericRange, writer.uint32(114).fork()).ldelim();
          }
          if (message.comparison != null && Object.hasOwnProperty.call(message, "comparison")) {
            $root.values.v1.ComparisonType.encode(message.comparison, writer.uint32(122).fork()).ldelim();
          }
          if (message.ratio != null && Object.hasOwnProperty.call(message, "ratio")) {
            $root.values.v1.RatioType.encode(message.ratio, writer.uint32(130).fork()).ldelim();
          }
          if (message.stringEnum != null && Object.hasOwnProperty.call(message, "stringEnum")) {
            $root.values.v1.StringEnumType.encode(message.stringEnum, writer.uint32(138).fork()).ldelim();
          }
          if (message.intEnum != null && Object.hasOwnProperty.call(message, "intEnum")) {
            $root.values.v1.IntEnumType.encode(message.intEnum, writer.uint32(146).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            $root.values.v1.URLType.encode(message.url, writer.uint32(154).fork()).ldelim();
          }
          if (message.doubleEnum != null && Object.hasOwnProperty.call(message, "doubleEnum")) {
            $root.values.v1.DoubleEnumType.encode(message.doubleEnum, writer.uint32(162).fork()).ldelim();
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            google$1.protobuf.Empty.encode(message.phoneNumber, writer.uint32(170).fork()).ldelim();
          }
          if (message.generic != null && Object.hasOwnProperty.call(message, "generic")) {
            google$1.protobuf.Empty.encode(message.generic, writer.uint32(178).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ValueType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.bool = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.string = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.timestamp = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.duration = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.date = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.double = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.int64 = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.uint64 = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.bytes = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.bigInteger = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.bigDecimal = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.array = $root.values.v1.ArrayType.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.object = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.numericRange = $root.values.v1.NumericRangeType.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.comparison = $root.values.v1.ComparisonType.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.ratio = $root.values.v1.RatioType.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.stringEnum = $root.values.v1.StringEnumType.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.intEnum = $root.values.v1.IntEnumType.decode(reader, reader.uint32());
                break;
              }
              case 19: {
                message.url = $root.values.v1.URLType.decode(reader, reader.uint32());
                break;
              }
              case 20: {
                message.doubleEnum = $root.values.v1.DoubleEnumType.decode(reader, reader.uint32());
                break;
              }
              case 21: {
                message.phoneNumber = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 22: {
                message.generic = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.ValueType) {
            return object;
          }
          const message = new $root.values.v1.ValueType();
          if (object.bool != null) {
            if (typeof object.bool !== "object") {
              throw new TypeError(".values.v1.ValueType.bool: object expected, but got " + (typeof object.bool));
            }
            message.bool = google$1.protobuf.Empty.fromObject(object.bool);
          }
          if (object.string != null) {
            if (typeof object.string !== "object") {
              throw new TypeError(".values.v1.ValueType.string: object expected, but got " + (typeof object.string));
            }
            message.string = google$1.protobuf.Empty.fromObject(object.string);
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== "object") {
              throw new TypeError(".values.v1.ValueType.timestamp: object expected, but got " + (typeof object.timestamp));
            }
            message.timestamp = google$1.protobuf.Empty.fromObject(object.timestamp);
          }
          if (object.duration != null) {
            if (typeof object.duration !== "object") {
              throw new TypeError(".values.v1.ValueType.duration: object expected, but got " + (typeof object.duration));
            }
            message.duration = google$1.protobuf.Empty.fromObject(object.duration);
          }
          if (object.date != null) {
            if (typeof object.date !== "object") {
              throw new TypeError(".values.v1.ValueType.date: object expected, but got " + (typeof object.date));
            }
            message.date = google$1.protobuf.Empty.fromObject(object.date);
          }
          if (object.double != null) {
            if (typeof object.double !== "object") {
              throw new TypeError(".values.v1.ValueType.double: object expected, but got " + (typeof object.double));
            }
            message.double = google$1.protobuf.Empty.fromObject(object.double);
          }
          if (object.int64 != null) {
            if (typeof object.int64 !== "object") {
              throw new TypeError(".values.v1.ValueType.int64: object expected, but got " + (typeof object.int64));
            }
            message.int64 = google$1.protobuf.Empty.fromObject(object.int64);
          }
          if (object.uint64 != null) {
            if (typeof object.uint64 !== "object") {
              throw new TypeError(".values.v1.ValueType.uint64: object expected, but got " + (typeof object.uint64));
            }
            message.uint64 = google$1.protobuf.Empty.fromObject(object.uint64);
          }
          if (object.bytes != null) {
            if (typeof object.bytes !== "object") {
              throw new TypeError(".values.v1.ValueType.bytes: object expected, but got " + (typeof object.bytes));
            }
            message.bytes = google$1.protobuf.Empty.fromObject(object.bytes);
          }
          if (object.bigInteger != null) {
            if (typeof object.bigInteger !== "object") {
              throw new TypeError(".values.v1.ValueType.bigInteger: object expected, but got " + (typeof object.bigInteger));
            }
            message.bigInteger = google$1.protobuf.Empty.fromObject(object.bigInteger);
          }
          if (object.bigDecimal != null) {
            if (typeof object.bigDecimal !== "object") {
              throw new TypeError(".values.v1.ValueType.bigDecimal: object expected, but got " + (typeof object.bigDecimal));
            }
            message.bigDecimal = google$1.protobuf.Empty.fromObject(object.bigDecimal);
          }
          if (object.array != null) {
            if (typeof object.array !== "object") {
              throw new TypeError(".values.v1.ValueType.array: object expected, but got " + (typeof object.array));
            }
            message.array = $root.values.v1.ArrayType.fromObject(object.array);
          }
          if (object.object != null) {
            if (typeof object.object !== "object") {
              throw new TypeError(".values.v1.ValueType.object: object expected, but got " + (typeof object.object));
            }
            message.object = google$1.protobuf.Empty.fromObject(object.object);
          }
          if (object.numericRange != null) {
            if (typeof object.numericRange !== "object") {
              throw new TypeError(".values.v1.ValueType.numericRange: object expected, but got " + (typeof object.numericRange));
            }
            message.numericRange = $root.values.v1.NumericRangeType.fromObject(object.numericRange);
          }
          if (object.comparison != null) {
            if (typeof object.comparison !== "object") {
              throw new TypeError(".values.v1.ValueType.comparison: object expected, but got " + (typeof object.comparison));
            }
            message.comparison = $root.values.v1.ComparisonType.fromObject(object.comparison);
          }
          if (object.ratio != null) {
            if (typeof object.ratio !== "object") {
              throw new TypeError(".values.v1.ValueType.ratio: object expected, but got " + (typeof object.ratio));
            }
            message.ratio = $root.values.v1.RatioType.fromObject(object.ratio);
          }
          if (object.stringEnum != null) {
            if (typeof object.stringEnum !== "object") {
              throw new TypeError(".values.v1.ValueType.stringEnum: object expected, but got " + (typeof object.stringEnum));
            }
            message.stringEnum = $root.values.v1.StringEnumType.fromObject(object.stringEnum);
          }
          if (object.intEnum != null) {
            if (typeof object.intEnum !== "object") {
              throw new TypeError(".values.v1.ValueType.intEnum: object expected, but got " + (typeof object.intEnum));
            }
            message.intEnum = $root.values.v1.IntEnumType.fromObject(object.intEnum);
          }
          if (object.url != null) {
            if (typeof object.url !== "object") {
              throw new TypeError(".values.v1.ValueType.url: object expected, but got " + (typeof object.url));
            }
            message.url = $root.values.v1.URLType.fromObject(object.url);
          }
          if (object.doubleEnum != null) {
            if (typeof object.doubleEnum !== "object") {
              throw new TypeError(".values.v1.ValueType.doubleEnum: object expected, but got " + (typeof object.doubleEnum));
            }
            message.doubleEnum = $root.values.v1.DoubleEnumType.fromObject(object.doubleEnum);
          }
          if (object.phoneNumber != null) {
            if (typeof object.phoneNumber !== "object") {
              throw new TypeError(".values.v1.ValueType.phoneNumber: object expected, but got " + (typeof object.phoneNumber));
            }
            message.phoneNumber = google$1.protobuf.Empty.fromObject(object.phoneNumber);
          }
          if (object.generic != null) {
            if (typeof object.generic !== "object") {
              throw new TypeError(".values.v1.ValueType.generic: object expected, but got " + (typeof object.generic));
            }
            message.generic = google$1.protobuf.Empty.fromObject(object.generic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.bool != null && message.hasOwnProperty("bool")) {
            object.bool = google$1.protobuf.Empty.toObject(message.bool, options);
            if (options.oneofs) {
              object.type = "bool";
            }
          }
          if (message.string != null && message.hasOwnProperty("string")) {
            object.string = google$1.protobuf.Empty.toObject(message.string, options);
            if (options.oneofs) {
              object.type = "string";
            }
          }
          if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
            object.timestamp = google$1.protobuf.Empty.toObject(message.timestamp, options);
            if (options.oneofs) {
              object.type = "timestamp";
            }
          }
          if (message.duration != null && message.hasOwnProperty("duration")) {
            object.duration = google$1.protobuf.Empty.toObject(message.duration, options);
            if (options.oneofs) {
              object.type = "duration";
            }
          }
          if (message.date != null && message.hasOwnProperty("date")) {
            object.date = google$1.protobuf.Empty.toObject(message.date, options);
            if (options.oneofs) {
              object.type = "date";
            }
          }
          if (message.double != null && message.hasOwnProperty("double")) {
            object.double = google$1.protobuf.Empty.toObject(message.double, options);
            if (options.oneofs) {
              object.type = "double";
            }
          }
          if (message.int64 != null && message.hasOwnProperty("int64")) {
            object.int64 = google$1.protobuf.Empty.toObject(message.int64, options);
            if (options.oneofs) {
              object.type = "int64";
            }
          }
          if (message.uint64 != null && message.hasOwnProperty("uint64")) {
            object.uint64 = google$1.protobuf.Empty.toObject(message.uint64, options);
            if (options.oneofs) {
              object.type = "uint64";
            }
          }
          if (message.bytes != null && message.hasOwnProperty("bytes")) {
            object.bytes = google$1.protobuf.Empty.toObject(message.bytes, options);
            if (options.oneofs) {
              object.type = "bytes";
            }
          }
          if (message.bigInteger != null && message.hasOwnProperty("bigInteger")) {
            object.bigInteger = google$1.protobuf.Empty.toObject(message.bigInteger, options);
            if (options.oneofs) {
              object.type = "bigInteger";
            }
          }
          if (message.bigDecimal != null && message.hasOwnProperty("bigDecimal")) {
            object.bigDecimal = google$1.protobuf.Empty.toObject(message.bigDecimal, options);
            if (options.oneofs) {
              object.type = "bigDecimal";
            }
          }
          if (message.array != null && message.hasOwnProperty("array")) {
            object.array = $root.values.v1.ArrayType.toObject(message.array, options);
            if (options.oneofs) {
              object.type = "array";
            }
          }
          if (message.object != null && message.hasOwnProperty("object")) {
            object.object = google$1.protobuf.Empty.toObject(message.object, options);
            if (options.oneofs) {
              object.type = "object";
            }
          }
          if (message.numericRange != null && message.hasOwnProperty("numericRange")) {
            object.numericRange = $root.values.v1.NumericRangeType.toObject(message.numericRange, options);
            if (options.oneofs) {
              object.type = "numericRange";
            }
          }
          if (message.comparison != null && message.hasOwnProperty("comparison")) {
            object.comparison = $root.values.v1.ComparisonType.toObject(message.comparison, options);
            if (options.oneofs) {
              object.type = "comparison";
            }
          }
          if (message.ratio != null && message.hasOwnProperty("ratio")) {
            object.ratio = $root.values.v1.RatioType.toObject(message.ratio, options);
            if (options.oneofs) {
              object.type = "ratio";
            }
          }
          if (message.stringEnum != null && message.hasOwnProperty("stringEnum")) {
            object.stringEnum = $root.values.v1.StringEnumType.toObject(message.stringEnum, options);
            if (options.oneofs) {
              object.type = "stringEnum";
            }
          }
          if (message.intEnum != null && message.hasOwnProperty("intEnum")) {
            object.intEnum = $root.values.v1.IntEnumType.toObject(message.intEnum, options);
            if (options.oneofs) {
              object.type = "intEnum";
            }
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = $root.values.v1.URLType.toObject(message.url, options);
            if (options.oneofs) {
              object.type = "url";
            }
          }
          if (message.doubleEnum != null && message.hasOwnProperty("doubleEnum")) {
            object.doubleEnum = $root.values.v1.DoubleEnumType.toObject(message.doubleEnum, options);
            if (options.oneofs) {
              object.type = "doubleEnum";
            }
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = google$1.protobuf.Empty.toObject(message.phoneNumber, options);
            if (options.oneofs) {
              object.type = "phoneNumber";
            }
          }
          if (message.generic != null && message.hasOwnProperty("generic")) {
            object.generic = google$1.protobuf.Empty.toObject(message.generic, options);
            if (options.oneofs) {
              object.type = "generic";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.ValueType";
        }
      }

      ValueType.prototype.bool = null;
      ValueType.prototype.string = null;
      ValueType.prototype.timestamp = null;
      ValueType.prototype.duration = null;
      ValueType.prototype.date = null;
      ValueType.prototype.double = null;
      ValueType.prototype.int64 = null;
      ValueType.prototype.uint64 = null;
      ValueType.prototype.bytes = null;
      ValueType.prototype.bigInteger = null;
      ValueType.prototype.bigDecimal = null;
      ValueType.prototype.array = null;
      ValueType.prototype.object = null;
      ValueType.prototype.numericRange = null;
      ValueType.prototype.comparison = null;
      ValueType.prototype.ratio = null;
      ValueType.prototype.stringEnum = null;
      ValueType.prototype.intEnum = null;
      ValueType.prototype.url = null;
      ValueType.prototype.doubleEnum = null;
      ValueType.prototype.phoneNumber = null;
      ValueType.prototype.generic = null;

      return ValueType;
    })();

    v1.URLType = (() => {
      class URLType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new URLType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new URLType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.URLType) {
            return object;
          }
          const message = new $root.values.v1.URLType();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.URLType";
        }
      }

      return URLType;
    })();

    v1.ArrayType = (() => {
      class ArrayType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ArrayType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.elementType != null && Object.hasOwnProperty.call(message, "elementType")) {
            $root.values.v1.ValueType.encode(message.elementType, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ArrayType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.elementType = $root.values.v1.ValueType.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.ArrayType) {
            return object;
          }
          const message = new $root.values.v1.ArrayType();
          if (object.elementType != null) {
            if (typeof object.elementType !== "object") {
              throw new TypeError(".values.v1.ArrayType.elementType: object expected, but got " + (typeof object.elementType));
            }
            message.elementType = $root.values.v1.ValueType.fromObject(object.elementType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.elementType = null;
          }
          let keys;
          if (message.elementType != null && message.hasOwnProperty("elementType")) {
            object.elementType = $root.values.v1.ValueType.toObject(message.elementType, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.ArrayType";
        }
      }

      ArrayType.prototype.elementType = null;

      return ArrayType;
    })();

    v1.NumericRangeType = (() => {
      class NumericRangeType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new NumericRangeType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.operandType != null && Object.hasOwnProperty.call(message, "operandType")) {
            writer.uint32(8).int32(message.operandType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new NumericRangeType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.operandType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.NumericRangeType) {
            return object;
          }
          const message = new $root.values.v1.NumericRangeType();
          if (object.operandType != null) {
            switch (object.operandType) {
              case "UNSPECIFIED_NUMERIC_TYPE":
              case 0: {
                message.operandType = 0;
                break;
              }
              case "NUMERIC_DOUBLE":
              case 1: {
                message.operandType = 1;
                break;
              }
              case "NUMERIC_INT64":
              case 2: {
                message.operandType = 2;
                break;
              }
              case "NUMERIC_UINT64":
              case 3: {
                message.operandType = 3;
                break;
              }
              case "NUMERIC_BIG_INTEGER":
              case 4: {
                message.operandType = 4;
                break;
              }
              case "NUMERIC_BIG_DECIMAL":
              case 5: {
                message.operandType = 5;
                break;
              }
              default: {
                if (typeof object.operandType == "number") {
                  message.operandType = object.operandType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.operandType = options.enums === String ? "UNSPECIFIED_NUMERIC_TYPE" : 0
          }
          let keys;
          if (message.operandType != null && message.hasOwnProperty("operandType")) {
            object.operandType = options.enums === String ? $root.values.v1.NumericType[message.operandType] === undefined ? message.operandType : $root.values.v1.NumericType[message.operandType] : message.operandType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.NumericRangeType";
        }
      }

      NumericRangeType.prototype.operandType = 0;

      return NumericRangeType;
    })();

    v1.ComparisonType = (() => {
      class ComparisonType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ComparisonType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.operandType != null && Object.hasOwnProperty.call(message, "operandType")) {
            writer.uint32(8).int32(message.operandType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ComparisonType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.operandType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.ComparisonType) {
            return object;
          }
          const message = new $root.values.v1.ComparisonType();
          if (object.operandType != null) {
            switch (object.operandType) {
              case "UNSPECIFIED_NUMERIC_TYPE":
              case 0: {
                message.operandType = 0;
                break;
              }
              case "NUMERIC_DOUBLE":
              case 1: {
                message.operandType = 1;
                break;
              }
              case "NUMERIC_INT64":
              case 2: {
                message.operandType = 2;
                break;
              }
              case "NUMERIC_UINT64":
              case 3: {
                message.operandType = 3;
                break;
              }
              case "NUMERIC_BIG_INTEGER":
              case 4: {
                message.operandType = 4;
                break;
              }
              case "NUMERIC_BIG_DECIMAL":
              case 5: {
                message.operandType = 5;
                break;
              }
              default: {
                if (typeof object.operandType == "number") {
                  message.operandType = object.operandType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.operandType = options.enums === String ? "UNSPECIFIED_NUMERIC_TYPE" : 0
          }
          let keys;
          if (message.operandType != null && message.hasOwnProperty("operandType")) {
            object.operandType = options.enums === String ? $root.values.v1.NumericType[message.operandType] === undefined ? message.operandType : $root.values.v1.NumericType[message.operandType] : message.operandType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.ComparisonType";
        }
      }

      ComparisonType.prototype.operandType = 0;

      return ComparisonType;
    })();

    v1.RatioType = (() => {
      class RatioType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RatioType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.operandType != null && Object.hasOwnProperty.call(message, "operandType")) {
            writer.uint32(8).int32(message.operandType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RatioType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.operandType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.RatioType) {
            return object;
          }
          const message = new $root.values.v1.RatioType();
          if (object.operandType != null) {
            switch (object.operandType) {
              case "UNSPECIFIED_NUMERIC_TYPE":
              case 0: {
                message.operandType = 0;
                break;
              }
              case "NUMERIC_DOUBLE":
              case 1: {
                message.operandType = 1;
                break;
              }
              case "NUMERIC_INT64":
              case 2: {
                message.operandType = 2;
                break;
              }
              case "NUMERIC_UINT64":
              case 3: {
                message.operandType = 3;
                break;
              }
              case "NUMERIC_BIG_INTEGER":
              case 4: {
                message.operandType = 4;
                break;
              }
              case "NUMERIC_BIG_DECIMAL":
              case 5: {
                message.operandType = 5;
                break;
              }
              default: {
                if (typeof object.operandType == "number") {
                  message.operandType = object.operandType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.operandType = options.enums === String ? "UNSPECIFIED_NUMERIC_TYPE" : 0
          }
          let keys;
          if (message.operandType != null && message.hasOwnProperty("operandType")) {
            object.operandType = options.enums === String ? $root.values.v1.NumericType[message.operandType] === undefined ? message.operandType : $root.values.v1.NumericType[message.operandType] : message.operandType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.RatioType";
        }
      }

      RatioType.prototype.operandType = 0;

      return RatioType;
    })();

    v1.StringEnumType = (() => {
      class StringEnumType {
        constructor(properties) {
          this.choices = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StringEnumType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.choices != null && Object.hasOwnProperty.call(message, "choices")) {
            for (const key of Object.keys(message.choices)) {
              writer.uint32(10).fork().uint32(10).string(key);
              google$1.protobuf.Empty.encode(message.choices[key], writer.uint32(18).fork()).ldelim().ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StringEnumType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.choices === $util.emptyObject) message.choices = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = google$1.protobuf.Empty.decode(reader, reader.uint32());
                      break;
                    }
                  }
                  message.choices[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.StringEnumType) {
            return object;
          }
          const message = new $root.values.v1.StringEnumType();
          if (object.choices) {
            if (typeof object.choices !== "object") {
              throw new TypeError(".values.v1.StringEnumType.choices: object expected, but got " + (typeof object.choices));
            }
            message.choices = {};
            for (let keys = Object.keys(object.choices), i = 0; i < keys.length; ++i) {
              if (typeof object.choices[keys[i]] !== "object") {
                throw new TypeError(".values.v1.StringEnumType.value: object expected, but got " + (typeof object.choices[keys[i]]));
              }
              message.choices[keys[i]] = google$1.protobuf.Empty.fromObject(object.choices[keys[i]]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.choices = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.choices && (keys = Object.keys(message.choices)).length) {
            object.choices = {};
            for (let i = 0; i < keys.length; ++i) {
              object.choices[keys[i]] = google$1.protobuf.Empty.toObject(message.choices[keys[i]], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.StringEnumType";
        }
      }

      StringEnumType.prototype.choices = $util.emptyObject;

      return StringEnumType;
    })();

    v1.IntEnumType = (() => {
      class IntEnumType {
        constructor(properties) {
          this.choices = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IntEnumType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.choices != null && Object.hasOwnProperty.call(message, "choices")) {
            for (const key of Object.keys(message.choices)) {
              writer.uint32(10).fork().uint32(8).int64(key);
              google$1.protobuf.Empty.encode(message.choices[key], writer.uint32(18).fork()).ldelim().ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IntEnumType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.choices === $util.emptyObject) message.choices = {};
                let end2 = reader.uint32() + reader.pos;
                key = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.int64();
                      break;
                    }
                    case 2: {
                      value = google$1.protobuf.Empty.decode(reader, reader.uint32());
                      break;
                    }
                  }
                  message.choices[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.IntEnumType) {
            return object;
          }
          const message = new $root.values.v1.IntEnumType();
          if (object.choices) {
            if (typeof object.choices !== "object") {
              throw new TypeError(".values.v1.IntEnumType.choices: object expected, but got " + (typeof object.choices));
            }
            message.choices = {};
            for (let keys = Object.keys(object.choices), i = 0; i < keys.length; ++i) {
              if (typeof object.choices[keys[i]] !== "object") {
                throw new TypeError(".values.v1.IntEnumType.value: object expected, but got " + (typeof object.choices[keys[i]]));
              }
              message.choices[keys[i]] = google$1.protobuf.Empty.fromObject(object.choices[keys[i]]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.choices = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.choices && (keys = Object.keys(message.choices)).length) {
            object.choices = {};
            for (let i = 0; i < keys.length; ++i) {
              object.choices[keys[i]] = google$1.protobuf.Empty.toObject(message.choices[keys[i]], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.IntEnumType";
        }
      }

      IntEnumType.prototype.choices = $util.emptyObject;

      return IntEnumType;
    })();

    v1.DoubleEnumType = (() => {
      class DoubleEnumType {
        constructor(properties) {
          this.choices = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DoubleEnumType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.choices != null && Object.hasOwnProperty.call(message, "choices")) {
            writer.uint32(10).fork();
            for (const element of message.choices) {
              writer.double(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DoubleEnumType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.choices || !message.choices.length) {
                  message.choices = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.choices.push(reader.double());
                } else {
                  message.choices.push(reader.double());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.DoubleEnumType) {
            return object;
          }
          const message = new $root.values.v1.DoubleEnumType();
          if (object.choices) {
            if (!Array.isArray(object.choices)) {
              throw new TypeError(".values.v1.DoubleEnumType.choices: array type expected, but got " + (typeof object.choices))
            }
            message.choices = new Array(object.choices.length);
            for (let i = 0; i < object.choices.length; ++i) {
              message.choices[i] = Number(object.choices[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.choices = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.choices && message.choices.length) {
            object.choices = new Array(message.choices.length);
            for (let i = 0; i < message.choices.length; ++i) {
              object.choices[i] = options.json && !isFinite(message.choices[i]) ? String(message.choices[i]) : message.choices[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.DoubleEnumType";
        }
      }

      DoubleEnumType.prototype.choices = $util.emptyArray;

      return DoubleEnumType;
    })();

    v1.Value = (() => {
      class Value {
        get value() {
          for (const key of ["boolValue", "stringValue", "timestampValue", "durationValue", "dateValue", "floatValue", "intValue", "unsignedIntValue", "rawBytesValue", "bigIntegerValue", "bigDecimalValue", "numericRangeValue", "comparisonValue", "ratioValue", "arrayValue", "objectValue", "redactedValue", "urlValue", "phoneNumberValue"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set value(name) {
          for (const key of ["boolValue", "stringValue", "timestampValue", "durationValue", "dateValue", "floatValue", "intValue", "unsignedIntValue", "rawBytesValue", "bigIntegerValue", "bigDecimalValue", "numericRangeValue", "comparisonValue", "ratioValue", "arrayValue", "objectValue", "redactedValue", "urlValue", "phoneNumberValue"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Value(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue")) {
            writer.uint32(8).bool(message.boolValue);
          }
          if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue")) {
            writer.uint32(18).string(message.stringValue);
          }
          if (message.timestampValue != null && Object.hasOwnProperty.call(message, "timestampValue")) {
            timing$1.v1.Timestamp.encode(message.timestampValue, writer.uint32(26).fork()).ldelim();
          }
          if (message.durationValue != null && Object.hasOwnProperty.call(message, "durationValue")) {
            timing$1.v1.Duration.encode(message.durationValue, writer.uint32(34).fork()).ldelim();
          }
          if (message.dateValue != null && Object.hasOwnProperty.call(message, "dateValue")) {
            $root.values.v1.Date.encode(message.dateValue, writer.uint32(42).fork()).ldelim();
          }
          if (message.floatValue != null && Object.hasOwnProperty.call(message, "floatValue")) {
            writer.uint32(49).double(message.floatValue);
          }
          if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue")) {
            writer.uint32(56).int64(message.intValue);
          }
          if (message.unsignedIntValue != null && Object.hasOwnProperty.call(message, "unsignedIntValue")) {
            writer.uint32(64).uint64(message.unsignedIntValue);
          }
          if (message.rawBytesValue != null && Object.hasOwnProperty.call(message, "rawBytesValue")) {
            writer.uint32(74).bytes(message.rawBytesValue);
          }
          if (message.bigIntegerValue != null && Object.hasOwnProperty.call(message, "bigIntegerValue")) {
            $root.values.v1.BigInteger.encode(message.bigIntegerValue, writer.uint32(82).fork()).ldelim();
          }
          if (message.bigDecimalValue != null && Object.hasOwnProperty.call(message, "bigDecimalValue")) {
            $root.values.v1.BigDecimal.encode(message.bigDecimalValue, writer.uint32(90).fork()).ldelim();
          }
          if (message.numericRangeValue != null && Object.hasOwnProperty.call(message, "numericRangeValue")) {
            $root.values.v1.NumericRange.encode(message.numericRangeValue, writer.uint32(98).fork()).ldelim();
          }
          if (message.comparisonValue != null && Object.hasOwnProperty.call(message, "comparisonValue")) {
            $root.values.v1.Comparison.encode(message.comparisonValue, writer.uint32(106).fork()).ldelim();
          }
          if (message.ratioValue != null && Object.hasOwnProperty.call(message, "ratioValue")) {
            $root.values.v1.Ratio.encode(message.ratioValue, writer.uint32(114).fork()).ldelim();
          }
          if (message.arrayValue != null && Object.hasOwnProperty.call(message, "arrayValue")) {
            $root.values.v1.ArrayValue.encode(message.arrayValue, writer.uint32(122).fork()).ldelim();
          }
          if (message.objectValue != null && Object.hasOwnProperty.call(message, "objectValue")) {
            google$1.protobuf.Empty.encode(message.objectValue, writer.uint32(130).fork()).ldelim();
          }
          if (message.redactedValue != null && Object.hasOwnProperty.call(message, "redactedValue")) {
            $root.values.v1.RedactedValue.encode(message.redactedValue, writer.uint32(138).fork()).ldelim();
          }
          if (message.urlValue != null && Object.hasOwnProperty.call(message, "urlValue")) {
            $root.values.v1.URL.encode(message.urlValue, writer.uint32(146).fork()).ldelim();
          }
          if (message.phoneNumberValue != null && Object.hasOwnProperty.call(message, "phoneNumberValue")) {
            phonenumbers$1.PhoneNumber.encode(message.phoneNumberValue, writer.uint32(154).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Value();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.boolValue = reader.bool();
                break;
              }
              case 2: {
                message.stringValue = reader.string();
                break;
              }
              case 3: {
                message.timestampValue = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.durationValue = timing$1.v1.Duration.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.dateValue = $root.values.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.floatValue = reader.double();
                break;
              }
              case 7: {
                message.intValue = reader.int64();
                break;
              }
              case 8: {
                message.unsignedIntValue = reader.uint64();
                break;
              }
              case 9: {
                message.rawBytesValue = reader.bytes();
                break;
              }
              case 10: {
                message.bigIntegerValue = $root.values.v1.BigInteger.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.bigDecimalValue = $root.values.v1.BigDecimal.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.numericRangeValue = $root.values.v1.NumericRange.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.comparisonValue = $root.values.v1.Comparison.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.ratioValue = $root.values.v1.Ratio.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.arrayValue = $root.values.v1.ArrayValue.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.objectValue = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.redactedValue = $root.values.v1.RedactedValue.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.urlValue = $root.values.v1.URL.decode(reader, reader.uint32());
                break;
              }
              case 19: {
                message.phoneNumberValue = phonenumbers$1.PhoneNumber.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.Value) {
            return object;
          }
          const message = new $root.values.v1.Value();
          if (object.boolValue != null) {
            message.boolValue = Boolean(object.boolValue);
          }
          if (object.stringValue != null) {
            message.stringValue = String(object.stringValue);
          }
          if (object.timestampValue != null) {
            if (typeof object.timestampValue !== "object") {
              throw new TypeError(".values.v1.Value.timestampValue: object expected, but got " + (typeof object.timestampValue));
            }
            message.timestampValue = timing$1.v1.Timestamp.fromObject(object.timestampValue);
          }
          if (object.durationValue != null) {
            if (typeof object.durationValue !== "object") {
              throw new TypeError(".values.v1.Value.durationValue: object expected, but got " + (typeof object.durationValue));
            }
            message.durationValue = timing$1.v1.Duration.fromObject(object.durationValue);
          }
          if (object.dateValue != null) {
            if (typeof object.dateValue !== "object") {
              throw new TypeError(".values.v1.Value.dateValue: object expected, but got " + (typeof object.dateValue));
            }
            message.dateValue = $root.values.v1.Date.fromObject(object.dateValue);
          }
          if (object.floatValue != null) {
            message.floatValue = Number(object.floatValue);
          }
          if (object.intValue != null) {
            if ($util.Long) {
              (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
            } else if (typeof object.intValue === "string") {
              message.intValue = parseInt(object.intValue, 10);
            } else if (typeof object.intValue === "number") {
              message.intValue = object.intValue;
            } else if (typeof object.intValue === "object") {
              message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
            }
          }
          if (object.unsignedIntValue != null) {
            if ($util.Long) {
              (message.unsignedIntValue = $util.Long.fromValue(object.unsignedIntValue)).unsigned = true;
            } else if (typeof object.unsignedIntValue === "string") {
              message.unsignedIntValue = parseInt(object.unsignedIntValue, 10);
            } else if (typeof object.unsignedIntValue === "number") {
              message.unsignedIntValue = object.unsignedIntValue;
            } else if (typeof object.unsignedIntValue === "object") {
              message.unsignedIntValue = new $util.LongBits(object.unsignedIntValue.low >>> 0, object.unsignedIntValue.high >>> 0).toNumber(true);
            }
          }
          if (object.rawBytesValue != null) {
            if (typeof object.rawBytesValue === "string") {
              $util.base64.decode(object.rawBytesValue, message.rawBytesValue = $util.newBuffer($util.base64.length(object.rawBytesValue)), 0);
            } else if (object.rawBytesValue.length >= 0) {
              message.rawBytesValue = object.rawBytesValue;
            }
          }
          if (object.bigIntegerValue != null) {
            if (typeof object.bigIntegerValue !== "object") {
              throw new TypeError(".values.v1.Value.bigIntegerValue: object expected, but got " + (typeof object.bigIntegerValue));
            }
            message.bigIntegerValue = $root.values.v1.BigInteger.fromObject(object.bigIntegerValue);
          }
          if (object.bigDecimalValue != null) {
            if (typeof object.bigDecimalValue !== "object") {
              throw new TypeError(".values.v1.Value.bigDecimalValue: object expected, but got " + (typeof object.bigDecimalValue));
            }
            message.bigDecimalValue = $root.values.v1.BigDecimal.fromObject(object.bigDecimalValue);
          }
          if (object.numericRangeValue != null) {
            if (typeof object.numericRangeValue !== "object") {
              throw new TypeError(".values.v1.Value.numericRangeValue: object expected, but got " + (typeof object.numericRangeValue));
            }
            message.numericRangeValue = $root.values.v1.NumericRange.fromObject(object.numericRangeValue);
          }
          if (object.comparisonValue != null) {
            if (typeof object.comparisonValue !== "object") {
              throw new TypeError(".values.v1.Value.comparisonValue: object expected, but got " + (typeof object.comparisonValue));
            }
            message.comparisonValue = $root.values.v1.Comparison.fromObject(object.comparisonValue);
          }
          if (object.ratioValue != null) {
            if (typeof object.ratioValue !== "object") {
              throw new TypeError(".values.v1.Value.ratioValue: object expected, but got " + (typeof object.ratioValue));
            }
            message.ratioValue = $root.values.v1.Ratio.fromObject(object.ratioValue);
          }
          if (object.arrayValue != null) {
            if (typeof object.arrayValue !== "object") {
              throw new TypeError(".values.v1.Value.arrayValue: object expected, but got " + (typeof object.arrayValue));
            }
            message.arrayValue = $root.values.v1.ArrayValue.fromObject(object.arrayValue);
          }
          if (object.objectValue != null) {
            if (typeof object.objectValue !== "object") {
              throw new TypeError(".values.v1.Value.objectValue: object expected, but got " + (typeof object.objectValue));
            }
            message.objectValue = google$1.protobuf.Empty.fromObject(object.objectValue);
          }
          if (object.redactedValue != null) {
            if (typeof object.redactedValue !== "object") {
              throw new TypeError(".values.v1.Value.redactedValue: object expected, but got " + (typeof object.redactedValue));
            }
            message.redactedValue = $root.values.v1.RedactedValue.fromObject(object.redactedValue);
          }
          if (object.urlValue != null) {
            if (typeof object.urlValue !== "object") {
              throw new TypeError(".values.v1.Value.urlValue: object expected, but got " + (typeof object.urlValue));
            }
            message.urlValue = $root.values.v1.URL.fromObject(object.urlValue);
          }
          if (object.phoneNumberValue != null) {
            if (typeof object.phoneNumberValue !== "object") {
              throw new TypeError(".values.v1.Value.phoneNumberValue: object expected, but got " + (typeof object.phoneNumberValue));
            }
            message.phoneNumberValue = phonenumbers$1.PhoneNumber.fromObject(object.phoneNumberValue);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
            object.boolValue = message.boolValue;
            if (options.oneofs) {
              object.value = "boolValue";
            }
          }
          if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
            object.stringValue = message.stringValue;
            if (options.oneofs) {
              object.value = "stringValue";
            }
          }
          if (message.timestampValue != null && message.hasOwnProperty("timestampValue")) {
            object.timestampValue = timing$1.v1.Timestamp.toObject(message.timestampValue, options);
            if (options.oneofs) {
              object.value = "timestampValue";
            }
          }
          if (message.durationValue != null && message.hasOwnProperty("durationValue")) {
            object.durationValue = timing$1.v1.Duration.toObject(message.durationValue, options);
            if (options.oneofs) {
              object.value = "durationValue";
            }
          }
          if (message.dateValue != null && message.hasOwnProperty("dateValue")) {
            object.dateValue = $root.values.v1.Date.toObject(message.dateValue, options);
            if (options.oneofs) {
              object.value = "dateValue";
            }
          }
          if (message.floatValue != null && message.hasOwnProperty("floatValue")) {
            object.floatValue = options.json && !isFinite(message.floatValue) ? String(message.floatValue) : message.floatValue;
            if (options.oneofs) {
              object.value = "floatValue";
            }
          }
          if (message.intValue != null && message.hasOwnProperty("intValue")) {
            object.intValue = typeof message.intValue === "number" ? (options.longs === String ? String(message.intValue) : message.intValue) : (options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue);
            if (options.oneofs) {
              object.value = "intValue";
            }
          }
          if (message.unsignedIntValue != null && message.hasOwnProperty("unsignedIntValue")) {
            object.unsignedIntValue = typeof message.unsignedIntValue === "number" ? (options.longs === String ? String(message.unsignedIntValue) : message.unsignedIntValue) : (options.longs === String ? $util.Long.prototype.toString.call(message.unsignedIntValue) : options.longs === Number ? new $util.LongBits(message.unsignedIntValue.low >>> 0, message.unsignedIntValue.high >>> 0).toNumber(true) : message.unsignedIntValue);
            if (options.oneofs) {
              object.value = "unsignedIntValue";
            }
          }
          if (message.rawBytesValue != null && message.hasOwnProperty("rawBytesValue")) {
            object.rawBytesValue = options.bytes === String ? $util.base64.encode(message.rawBytesValue, 0, message.rawBytesValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawBytesValue) : message.rawBytesValue;
            if (options.oneofs) {
              object.value = "rawBytesValue";
            }
          }
          if (message.bigIntegerValue != null && message.hasOwnProperty("bigIntegerValue")) {
            object.bigIntegerValue = $root.values.v1.BigInteger.toObject(message.bigIntegerValue, options);
            if (options.oneofs) {
              object.value = "bigIntegerValue";
            }
          }
          if (message.bigDecimalValue != null && message.hasOwnProperty("bigDecimalValue")) {
            object.bigDecimalValue = $root.values.v1.BigDecimal.toObject(message.bigDecimalValue, options);
            if (options.oneofs) {
              object.value = "bigDecimalValue";
            }
          }
          if (message.numericRangeValue != null && message.hasOwnProperty("numericRangeValue")) {
            object.numericRangeValue = $root.values.v1.NumericRange.toObject(message.numericRangeValue, options);
            if (options.oneofs) {
              object.value = "numericRangeValue";
            }
          }
          if (message.comparisonValue != null && message.hasOwnProperty("comparisonValue")) {
            object.comparisonValue = $root.values.v1.Comparison.toObject(message.comparisonValue, options);
            if (options.oneofs) {
              object.value = "comparisonValue";
            }
          }
          if (message.ratioValue != null && message.hasOwnProperty("ratioValue")) {
            object.ratioValue = $root.values.v1.Ratio.toObject(message.ratioValue, options);
            if (options.oneofs) {
              object.value = "ratioValue";
            }
          }
          if (message.arrayValue != null && message.hasOwnProperty("arrayValue")) {
            object.arrayValue = $root.values.v1.ArrayValue.toObject(message.arrayValue, options);
            if (options.oneofs) {
              object.value = "arrayValue";
            }
          }
          if (message.objectValue != null && message.hasOwnProperty("objectValue")) {
            object.objectValue = google$1.protobuf.Empty.toObject(message.objectValue, options);
            if (options.oneofs) {
              object.value = "objectValue";
            }
          }
          if (message.redactedValue != null && message.hasOwnProperty("redactedValue")) {
            object.redactedValue = $root.values.v1.RedactedValue.toObject(message.redactedValue, options);
            if (options.oneofs) {
              object.value = "redactedValue";
            }
          }
          if (message.urlValue != null && message.hasOwnProperty("urlValue")) {
            object.urlValue = $root.values.v1.URL.toObject(message.urlValue, options);
            if (options.oneofs) {
              object.value = "urlValue";
            }
          }
          if (message.phoneNumberValue != null && message.hasOwnProperty("phoneNumberValue")) {
            object.phoneNumberValue = phonenumbers$1.PhoneNumber.toObject(message.phoneNumberValue, options);
            if (options.oneofs) {
              object.value = "phoneNumberValue";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.Value";
        }
      }

      Value.prototype.boolValue = false;
      Value.prototype.stringValue = "";
      Value.prototype.timestampValue = null;
      Value.prototype.durationValue = null;
      Value.prototype.dateValue = null;
      Value.prototype.floatValue = 0;
      Value.prototype.intValue = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Value.prototype.unsignedIntValue = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      Value.prototype.rawBytesValue = new Uint8Array();
      Value.prototype.bigIntegerValue = null;
      Value.prototype.bigDecimalValue = null;
      Value.prototype.numericRangeValue = null;
      Value.prototype.comparisonValue = null;
      Value.prototype.ratioValue = null;
      Value.prototype.arrayValue = null;
      Value.prototype.objectValue = null;
      Value.prototype.redactedValue = null;
      Value.prototype.urlValue = null;
      Value.prototype.phoneNumberValue = null;

      return Value;
    })();

    v1.Date = (() => {
      class Date {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Date(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.year != null && Object.hasOwnProperty.call(message, "year")) {
            writer.uint32(8).int32(message.year);
          }
          if (message.month != null && Object.hasOwnProperty.call(message, "month")) {
            writer.uint32(16).uint32(message.month);
          }
          if (message.day != null && Object.hasOwnProperty.call(message, "day")) {
            writer.uint32(24).uint32(message.day);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Date();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.year = reader.int32();
                break;
              }
              case 2: {
                message.month = reader.uint32();
                break;
              }
              case 3: {
                message.day = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.Date) {
            return object;
          }
          const message = new $root.values.v1.Date();
          if (object.year != null) {
            message.year = object.year | 0;
          }
          if (object.month != null) {
            message.month = object.month >>> 0;
          }
          if (object.day != null) {
            message.day = object.day >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.year = 0;
            object.month = 0;
            object.day = 0;
          }
          let keys;
          if (message.year != null && message.hasOwnProperty("year")) {
            object.year = message.year;
          }
          if (message.month != null && message.hasOwnProperty("month")) {
            object.month = message.month;
          }
          if (message.day != null && message.hasOwnProperty("day")) {
            object.day = message.day;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.Date";
        }
      }

      Date.prototype.year = 0;
      Date.prototype.month = 0;
      Date.prototype.day = 0;

      return Date;
    })();

    v1.BigInteger = (() => {
      class BigInteger {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BigInteger(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            writer.uint32(10).bytes(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BigInteger();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.value = reader.bytes();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.BigInteger) {
            return object;
          }
          const message = new $root.values.v1.BigInteger();
          if (object.value != null) {
            if (typeof object.value === "string") {
              $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
            } else if (object.value.length >= 0) {
              message.value = object.value;
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if (options.bytes === String) {
              object.value = ""
            } else {
              object.value = [];
              if (options.bytes !== Array) {
                object.value = $util.newBuffer(object.value);
              }
            }
          }
          let keys;
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.BigInteger";
        }
      }

      BigInteger.prototype.value = new Uint8Array();

      return BigInteger;
    })();

    v1.BigDecimal = (() => {
      class BigDecimal {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BigDecimal(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            writer.uint32(10).bytes(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BigDecimal();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.value = reader.bytes();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.BigDecimal) {
            return object;
          }
          const message = new $root.values.v1.BigDecimal();
          if (object.value != null) {
            if (typeof object.value === "string") {
              $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
            } else if (object.value.length >= 0) {
              message.value = object.value;
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if (options.bytes === String) {
              object.value = ""
            } else {
              object.value = [];
              if (options.bytes !== Array) {
                object.value = $util.newBuffer(object.value);
              }
            }
          }
          let keys;
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.BigDecimal";
        }
      }

      BigDecimal.prototype.value = new Uint8Array();

      return BigDecimal;
    })();

    v1.NumericValue = (() => {
      class NumericValue {
        get numeric() {
          for (const key of ["floatValue", "intValue", "unsignedIntValue", "bigIntegerValue", "bigDecimalValue"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set numeric(name) {
          for (const key of ["floatValue", "intValue", "unsignedIntValue", "bigIntegerValue", "bigDecimalValue"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new NumericValue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.floatValue != null && Object.hasOwnProperty.call(message, "floatValue")) {
            writer.uint32(9).double(message.floatValue);
          }
          if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue")) {
            writer.uint32(16).int64(message.intValue);
          }
          if (message.unsignedIntValue != null && Object.hasOwnProperty.call(message, "unsignedIntValue")) {
            writer.uint32(24).uint64(message.unsignedIntValue);
          }
          if (message.bigIntegerValue != null && Object.hasOwnProperty.call(message, "bigIntegerValue")) {
            $root.values.v1.BigInteger.encode(message.bigIntegerValue, writer.uint32(34).fork()).ldelim();
          }
          if (message.bigDecimalValue != null && Object.hasOwnProperty.call(message, "bigDecimalValue")) {
            $root.values.v1.BigDecimal.encode(message.bigDecimalValue, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new NumericValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.floatValue = reader.double();
                break;
              }
              case 2: {
                message.intValue = reader.int64();
                break;
              }
              case 3: {
                message.unsignedIntValue = reader.uint64();
                break;
              }
              case 4: {
                message.bigIntegerValue = $root.values.v1.BigInteger.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.bigDecimalValue = $root.values.v1.BigDecimal.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.NumericValue) {
            return object;
          }
          const message = new $root.values.v1.NumericValue();
          if (object.floatValue != null) {
            message.floatValue = Number(object.floatValue);
          }
          if (object.intValue != null) {
            if ($util.Long) {
              (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
            } else if (typeof object.intValue === "string") {
              message.intValue = parseInt(object.intValue, 10);
            } else if (typeof object.intValue === "number") {
              message.intValue = object.intValue;
            } else if (typeof object.intValue === "object") {
              message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
            }
          }
          if (object.unsignedIntValue != null) {
            if ($util.Long) {
              (message.unsignedIntValue = $util.Long.fromValue(object.unsignedIntValue)).unsigned = true;
            } else if (typeof object.unsignedIntValue === "string") {
              message.unsignedIntValue = parseInt(object.unsignedIntValue, 10);
            } else if (typeof object.unsignedIntValue === "number") {
              message.unsignedIntValue = object.unsignedIntValue;
            } else if (typeof object.unsignedIntValue === "object") {
              message.unsignedIntValue = new $util.LongBits(object.unsignedIntValue.low >>> 0, object.unsignedIntValue.high >>> 0).toNumber(true);
            }
          }
          if (object.bigIntegerValue != null) {
            if (typeof object.bigIntegerValue !== "object") {
              throw new TypeError(".values.v1.NumericValue.bigIntegerValue: object expected, but got " + (typeof object.bigIntegerValue));
            }
            message.bigIntegerValue = $root.values.v1.BigInteger.fromObject(object.bigIntegerValue);
          }
          if (object.bigDecimalValue != null) {
            if (typeof object.bigDecimalValue !== "object") {
              throw new TypeError(".values.v1.NumericValue.bigDecimalValue: object expected, but got " + (typeof object.bigDecimalValue));
            }
            message.bigDecimalValue = $root.values.v1.BigDecimal.fromObject(object.bigDecimalValue);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.floatValue != null && message.hasOwnProperty("floatValue")) {
            object.floatValue = options.json && !isFinite(message.floatValue) ? String(message.floatValue) : message.floatValue;
            if (options.oneofs) {
              object.numeric = "floatValue";
            }
          }
          if (message.intValue != null && message.hasOwnProperty("intValue")) {
            object.intValue = typeof message.intValue === "number" ? (options.longs === String ? String(message.intValue) : message.intValue) : (options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue);
            if (options.oneofs) {
              object.numeric = "intValue";
            }
          }
          if (message.unsignedIntValue != null && message.hasOwnProperty("unsignedIntValue")) {
            object.unsignedIntValue = typeof message.unsignedIntValue === "number" ? (options.longs === String ? String(message.unsignedIntValue) : message.unsignedIntValue) : (options.longs === String ? $util.Long.prototype.toString.call(message.unsignedIntValue) : options.longs === Number ? new $util.LongBits(message.unsignedIntValue.low >>> 0, message.unsignedIntValue.high >>> 0).toNumber(true) : message.unsignedIntValue);
            if (options.oneofs) {
              object.numeric = "unsignedIntValue";
            }
          }
          if (message.bigIntegerValue != null && message.hasOwnProperty("bigIntegerValue")) {
            object.bigIntegerValue = $root.values.v1.BigInteger.toObject(message.bigIntegerValue, options);
            if (options.oneofs) {
              object.numeric = "bigIntegerValue";
            }
          }
          if (message.bigDecimalValue != null && message.hasOwnProperty("bigDecimalValue")) {
            object.bigDecimalValue = $root.values.v1.BigDecimal.toObject(message.bigDecimalValue, options);
            if (options.oneofs) {
              object.numeric = "bigDecimalValue";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.NumericValue";
        }
      }

      NumericValue.prototype.floatValue = 0;
      NumericValue.prototype.intValue = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      NumericValue.prototype.unsignedIntValue = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      NumericValue.prototype.bigIntegerValue = null;
      NumericValue.prototype.bigDecimalValue = null;

      return NumericValue;
    })();

    v1.NumericRange = (() => {
      class NumericRange {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new NumericRange(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.minimumBound != null && Object.hasOwnProperty.call(message, "minimumBound")) {
            $root.values.v1.NumericValue.encode(message.minimumBound, writer.uint32(10).fork()).ldelim();
          }
          if (message.maximumBound != null && Object.hasOwnProperty.call(message, "maximumBound")) {
            $root.values.v1.NumericValue.encode(message.maximumBound, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new NumericRange();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.minimumBound = $root.values.v1.NumericValue.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.maximumBound = $root.values.v1.NumericValue.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.NumericRange) {
            return object;
          }
          const message = new $root.values.v1.NumericRange();
          if (object.minimumBound != null) {
            if (typeof object.minimumBound !== "object") {
              throw new TypeError(".values.v1.NumericRange.minimumBound: object expected, but got " + (typeof object.minimumBound));
            }
            message.minimumBound = $root.values.v1.NumericValue.fromObject(object.minimumBound);
          }
          if (object.maximumBound != null) {
            if (typeof object.maximumBound !== "object") {
              throw new TypeError(".values.v1.NumericRange.maximumBound: object expected, but got " + (typeof object.maximumBound));
            }
            message.maximumBound = $root.values.v1.NumericValue.fromObject(object.maximumBound);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.minimumBound = null;
            object.maximumBound = null;
          }
          let keys;
          if (message.minimumBound != null && message.hasOwnProperty("minimumBound")) {
            object.minimumBound = $root.values.v1.NumericValue.toObject(message.minimumBound, options);
          }
          if (message.maximumBound != null && message.hasOwnProperty("maximumBound")) {
            object.maximumBound = $root.values.v1.NumericValue.toObject(message.maximumBound, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.NumericRange";
        }
      }

      NumericRange.prototype.minimumBound = null;
      NumericRange.prototype.maximumBound = null;

      return NumericRange;
    })();

    v1.Comparison = (() => {
      class Comparison {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Comparison(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.comparator != null && Object.hasOwnProperty.call(message, "comparator")) {
            writer.uint32(8).int32(message.comparator);
          }
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            $root.values.v1.NumericValue.encode(message.value, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Comparison();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.comparator = reader.int32();
                break;
              }
              case 2: {
                message.value = $root.values.v1.NumericValue.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.Comparison) {
            return object;
          }
          const message = new $root.values.v1.Comparison();
          if (object.comparator != null) {
            switch (object.comparator) {
              case "GT":
              case 0: {
                message.comparator = 0;
                break;
              }
              case "GTEQ":
              case 1: {
                message.comparator = 1;
                break;
              }
              case "EQ":
              case 2: {
                message.comparator = 2;
                break;
              }
              case "NEQ":
              case 3: {
                message.comparator = 3;
                break;
              }
              case "LT":
              case 4: {
                message.comparator = 4;
                break;
              }
              case "LTEQ":
              case 5: {
                message.comparator = 5;
                break;
              }
              default: {
                if (typeof object.comparator == "number") {
                  message.comparator = object.comparator;
                  break;
                }
                break;
              }
            }
          }
          if (object.value != null) {
            if (typeof object.value !== "object") {
              throw new TypeError(".values.v1.Comparison.value: object expected, but got " + (typeof object.value));
            }
            message.value = $root.values.v1.NumericValue.fromObject(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.comparator = options.enums === String ? "GT" : 0
            object.value = null;
          }
          let keys;
          if (message.comparator != null && message.hasOwnProperty("comparator")) {
            object.comparator = options.enums === String ? $root.values.v1.ComparisonOperator[message.comparator] === undefined ? message.comparator : $root.values.v1.ComparisonOperator[message.comparator] : message.comparator;
          }
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = $root.values.v1.NumericValue.toObject(message.value, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.Comparison";
        }
      }

      Comparison.prototype.comparator = 0;
      Comparison.prototype.value = null;

      return Comparison;
    })();

    v1.Ratio = (() => {
      class Ratio {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Ratio(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.numerator != null && Object.hasOwnProperty.call(message, "numerator")) {
            $root.values.v1.NumericValue.encode(message.numerator, writer.uint32(10).fork()).ldelim();
          }
          if (message.denominator != null && Object.hasOwnProperty.call(message, "denominator")) {
            $root.values.v1.NumericValue.encode(message.denominator, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Ratio();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.numerator = $root.values.v1.NumericValue.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.denominator = $root.values.v1.NumericValue.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.Ratio) {
            return object;
          }
          const message = new $root.values.v1.Ratio();
          if (object.numerator != null) {
            if (typeof object.numerator !== "object") {
              throw new TypeError(".values.v1.Ratio.numerator: object expected, but got " + (typeof object.numerator));
            }
            message.numerator = $root.values.v1.NumericValue.fromObject(object.numerator);
          }
          if (object.denominator != null) {
            if (typeof object.denominator !== "object") {
              throw new TypeError(".values.v1.Ratio.denominator: object expected, but got " + (typeof object.denominator));
            }
            message.denominator = $root.values.v1.NumericValue.fromObject(object.denominator);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.numerator = null;
            object.denominator = null;
          }
          let keys;
          if (message.numerator != null && message.hasOwnProperty("numerator")) {
            object.numerator = $root.values.v1.NumericValue.toObject(message.numerator, options);
          }
          if (message.denominator != null && message.hasOwnProperty("denominator")) {
            object.denominator = $root.values.v1.NumericValue.toObject(message.denominator, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.Ratio";
        }
      }

      Ratio.prototype.numerator = null;
      Ratio.prototype.denominator = null;

      return Ratio;
    })();

    v1.ArrayValue = (() => {
      class ArrayValue {
        constructor(properties) {
          this.values = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ArrayValue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.values != null && Object.hasOwnProperty.call(message, "values")) {
            for (const element of message.values) {
              $root.values.v1.Value.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ArrayValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.values || !message.values.length) {
                  message.values = [];
                }
                message.values.push($root.values.v1.Value.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.ArrayValue) {
            return object;
          }
          const message = new $root.values.v1.ArrayValue();
          if (object.values) {
            if (!Array.isArray(object.values)) {
              throw new TypeError(".values.v1.ArrayValue.values: array type expected, but got " + (typeof object.values))
            }
            message.values = new Array(object.values.length);
            for (let i = 0; i < object.values.length; ++i) {
              if (typeof object.values[i] !== "object") {
                throw new TypeError(".values.v1.ArrayValue.values: object expected, but got " + (typeof object.values[i]));
              }
              message.values[i] = $root.values.v1.Value.fromObject(object.values[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.values = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.values && message.values.length) {
            object.values = new Array(message.values.length);
            for (let i = 0; i < message.values.length; ++i) {
              object.values[i] = $root.values.v1.Value.toObject(message.values[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.ArrayValue";
        }
      }

      ArrayValue.prototype.values = $util.emptyArray;

      return ArrayValue;
    })();

    v1.RedactedValue = (() => {
      class RedactedValue {
        constructor(properties) {
          this.missingPermissions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RedactedValue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.missingPermissions != null && Object.hasOwnProperty.call(message, "missingPermissions")) {
            for (const element of message.missingPermissions) {
              sensitivity$1.v1.Sensitivity.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RedactedValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.missingPermissions || !message.missingPermissions.length) {
                  message.missingPermissions = [];
                }
                message.missingPermissions.push(sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.RedactedValue) {
            return object;
          }
          const message = new $root.values.v1.RedactedValue();
          if (object.missingPermissions) {
            if (!Array.isArray(object.missingPermissions)) {
              throw new TypeError(".values.v1.RedactedValue.missingPermissions: array type expected, but got " + (typeof object.missingPermissions))
            }
            message.missingPermissions = new Array(object.missingPermissions.length);
            for (let i = 0; i < object.missingPermissions.length; ++i) {
              if (typeof object.missingPermissions[i] !== "object") {
                throw new TypeError(".values.v1.RedactedValue.missingPermissions: object expected, but got " + (typeof object.missingPermissions[i]));
              }
              message.missingPermissions[i] = sensitivity$1.v1.Sensitivity.fromObject(object.missingPermissions[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.missingPermissions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.missingPermissions && message.missingPermissions.length) {
            object.missingPermissions = new Array(message.missingPermissions.length);
            for (let i = 0; i < message.missingPermissions.length; ++i) {
              object.missingPermissions[i] = sensitivity$1.v1.Sensitivity.toObject(message.missingPermissions[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.RedactedValue";
        }
      }

      RedactedValue.prototype.missingPermissions = $util.emptyArray;

      return RedactedValue;
    })();

    v1.URL = (() => {
      class URL {
        constructor(properties) {
          this.params = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new URL(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.scheme != null && Object.hasOwnProperty.call(message, "scheme")) {
            writer.uint32(10).string(message.scheme);
          }
          if (message.host != null && Object.hasOwnProperty.call(message, "host")) {
            writer.uint32(18).string(message.host);
          }
          if (message.path != null && Object.hasOwnProperty.call(message, "path")) {
            writer.uint32(26).string(message.path);
          }
          if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
            for (const element of message.params) {
              $root.values.v1.URLQueryParam.encode(element, writer.uint32(34).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new URL();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.scheme = reader.string();
                break;
              }
              case 2: {
                message.host = reader.string();
                break;
              }
              case 3: {
                message.path = reader.string();
                break;
              }
              case 4: {
                if (!message.params || !message.params.length) {
                  message.params = [];
                }
                message.params.push($root.values.v1.URLQueryParam.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.URL) {
            return object;
          }
          const message = new $root.values.v1.URL();
          if (object.scheme != null) {
            message.scheme = String(object.scheme);
          }
          if (object.host != null) {
            message.host = String(object.host);
          }
          if (object.path != null) {
            message.path = String(object.path);
          }
          if (object.params) {
            if (!Array.isArray(object.params)) {
              throw new TypeError(".values.v1.URL.params: array type expected, but got " + (typeof object.params))
            }
            message.params = new Array(object.params.length);
            for (let i = 0; i < object.params.length; ++i) {
              if (typeof object.params[i] !== "object") {
                throw new TypeError(".values.v1.URL.params: object expected, but got " + (typeof object.params[i]));
              }
              message.params[i] = $root.values.v1.URLQueryParam.fromObject(object.params[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.params = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.scheme = "";
            object.host = "";
            object.path = "";
          }
          let keys;
          if (message.scheme != null && message.hasOwnProperty("scheme")) {
            object.scheme = message.scheme;
          }
          if (message.host != null && message.hasOwnProperty("host")) {
            object.host = message.host;
          }
          if (message.path != null && message.hasOwnProperty("path")) {
            object.path = message.path;
          }
          if (message.params && message.params.length) {
            object.params = new Array(message.params.length);
            for (let i = 0; i < message.params.length; ++i) {
              object.params[i] = $root.values.v1.URLQueryParam.toObject(message.params[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.URL";
        }
      }

      URL.prototype.scheme = "";
      URL.prototype.host = "";
      URL.prototype.path = "";
      URL.prototype.params = $util.emptyArray;

      return URL;
    })();

    v1.URLQueryParam = (() => {
      class URLQueryParam {
        constructor(properties) {
          this.values = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new URLQueryParam(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.key != null && Object.hasOwnProperty.call(message, "key")) {
            writer.uint32(10).string(message.key);
          }
          if (message.values != null && Object.hasOwnProperty.call(message, "values")) {
            for (const element of message.values) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new URLQueryParam();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.key = reader.string();
                break;
              }
              case 2: {
                if (!message.values || !message.values.length) {
                  message.values = [];
                }
                message.values.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.values.v1.URLQueryParam) {
            return object;
          }
          const message = new $root.values.v1.URLQueryParam();
          if (object.key != null) {
            message.key = String(object.key);
          }
          if (object.values) {
            if (!Array.isArray(object.values)) {
              throw new TypeError(".values.v1.URLQueryParam.values: array type expected, but got " + (typeof object.values))
            }
            message.values = new Array(object.values.length);
            for (let i = 0; i < object.values.length; ++i) {
              message.values[i] = String(object.values[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.values = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.key = "";
          }
          let keys;
          if (message.key != null && message.hasOwnProperty("key")) {
            object.key = message.key;
          }
          if (message.values && message.values.length) {
            object.values = new Array(message.values.length);
            for (let i = 0; i < message.values.length; ++i) {
              object.values[i] = message.values[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "values.v1.URLQueryParam";
        }
      }

      URLQueryParam.prototype.key = "";
      URLQueryParam.prototype.values = $util.emptyArray;

      return URLQueryParam;
    })();

    v1.NumericType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_NUMERIC_TYPE"] = 0;
      values[valuesById[1] = "NUMERIC_DOUBLE"] = 1;
      values[valuesById[2] = "NUMERIC_INT64"] = 2;
      values[valuesById[3] = "NUMERIC_UINT64"] = 3;
      values[valuesById[4] = "NUMERIC_BIG_INTEGER"] = 4;
      values[valuesById[5] = "NUMERIC_BIG_DECIMAL"] = 5;
      return values;
    })();

    v1.ComparisonOperator = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "GT"] = 0;
      values[valuesById[1] = "GTEQ"] = 1;
      values[valuesById[2] = "EQ"] = 2;
      values[valuesById[3] = "NEQ"] = 3;
      values[valuesById[4] = "LT"] = 4;
      values[valuesById[5] = "LTEQ"] = 5;
      return values;
    })();

    return v1;
  })();

  return values;
})();

export const values = $root.values;
