"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.phonenumbers = (() => {
  const phonenumbers = {};
  phonenumbers.NumberFormat = (() => {
    class NumberFormat {
      constructor(properties) {
        this.leadingDigitsPattern = [];
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new NumberFormat(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.pattern != null && Object.hasOwnProperty.call(message, "pattern")) {
          writer.uint32(10).string(message.pattern);
        }
        if (message.format != null && Object.hasOwnProperty.call(message, "format")) {
          writer.uint32(18).string(message.format);
        }
        if (message.leadingDigitsPattern != null && Object.hasOwnProperty.call(message, "leadingDigitsPattern")) {
          for (const element of message.leadingDigitsPattern) {
            writer.uint32(26).string(element);
          }
        }
        if (message.nationalPrefixFormattingRule != null && Object.hasOwnProperty.call(message, "nationalPrefixFormattingRule")) {
          writer.uint32(34).string(message.nationalPrefixFormattingRule);
        }
        if (message.nationalPrefixOptionalWhenFormatting != null && Object.hasOwnProperty.call(message, "nationalPrefixOptionalWhenFormatting")) {
          writer.uint32(48).bool(message.nationalPrefixOptionalWhenFormatting);
        }
        if (message.domesticCarrierCodeFormattingRule != null && Object.hasOwnProperty.call(message, "domesticCarrierCodeFormattingRule")) {
          writer.uint32(42).string(message.domesticCarrierCodeFormattingRule);
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new NumberFormat();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.pattern = reader.string();
              break;
            }
            case 2: {
              message.format = reader.string();
              break;
            }
            case 3: {
              if (!message.leadingDigitsPattern || !message.leadingDigitsPattern.length) {
                message.leadingDigitsPattern = [];
              }
              message.leadingDigitsPattern.push(reader.string());
              break;
            }
            case 4: {
              message.nationalPrefixFormattingRule = reader.string();
              break;
            }
            case 6: {
              message.nationalPrefixOptionalWhenFormatting = reader.bool();
              break;
            }
            case 5: {
              message.domesticCarrierCodeFormattingRule = reader.string();
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.phonenumbers.NumberFormat) {
          return object;
        }
        const message = new $root.phonenumbers.NumberFormat();
        if (object.pattern != null) {
          message.pattern = String(object.pattern);
        }
        if (object.format != null) {
          message.format = String(object.format);
        }
        if (object.leadingDigitsPattern) {
          if (!Array.isArray(object.leadingDigitsPattern)) {
            throw new TypeError(".phonenumbers.NumberFormat.leadingDigitsPattern: array type expected, but got " + (typeof object.leadingDigitsPattern))
          }
          message.leadingDigitsPattern = new Array(object.leadingDigitsPattern.length);
          for (let i = 0; i < object.leadingDigitsPattern.length; ++i) {
            message.leadingDigitsPattern[i] = String(object.leadingDigitsPattern[i]);
          }
        }
        if (object.nationalPrefixFormattingRule != null) {
          message.nationalPrefixFormattingRule = String(object.nationalPrefixFormattingRule);
        }
        if (object.nationalPrefixOptionalWhenFormatting != null) {
          message.nationalPrefixOptionalWhenFormatting = Boolean(object.nationalPrefixOptionalWhenFormatting);
        }
        if (object.domesticCarrierCodeFormattingRule != null) {
          message.domesticCarrierCodeFormattingRule = String(object.domesticCarrierCodeFormattingRule);
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
          object.leadingDigitsPattern = [];
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
          object.pattern = "";
          object.format = "";
          object.nationalPrefixFormattingRule = "";
          object.nationalPrefixOptionalWhenFormatting = false;
          object.domesticCarrierCodeFormattingRule = "";
        }
        let keys;
        if (message.pattern != null && message.hasOwnProperty("pattern")) {
          object.pattern = message.pattern;
        }
        if (message.format != null && message.hasOwnProperty("format")) {
          object.format = message.format;
        }
        if (message.leadingDigitsPattern && message.leadingDigitsPattern.length) {
          object.leadingDigitsPattern = new Array(message.leadingDigitsPattern.length);
          for (let i = 0; i < message.leadingDigitsPattern.length; ++i) {
            object.leadingDigitsPattern[i] = message.leadingDigitsPattern[i];
          }
        }
        if (message.nationalPrefixFormattingRule != null && message.hasOwnProperty("nationalPrefixFormattingRule")) {
          object.nationalPrefixFormattingRule = message.nationalPrefixFormattingRule;
        }
        if (message.nationalPrefixOptionalWhenFormatting != null && message.hasOwnProperty("nationalPrefixOptionalWhenFormatting")) {
          object.nationalPrefixOptionalWhenFormatting = message.nationalPrefixOptionalWhenFormatting;
        }
        if (message.domesticCarrierCodeFormattingRule != null && message.hasOwnProperty("domesticCarrierCodeFormattingRule")) {
          object.domesticCarrierCodeFormattingRule = message.domesticCarrierCodeFormattingRule;
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "phonenumbers.NumberFormat";
      }
    }

    NumberFormat.prototype.pattern = "";
    NumberFormat.prototype.format = "";
    NumberFormat.prototype.leadingDigitsPattern = $util.emptyArray;
    NumberFormat.prototype.nationalPrefixFormattingRule = "";
    NumberFormat.prototype.nationalPrefixOptionalWhenFormatting = false;
    NumberFormat.prototype.domesticCarrierCodeFormattingRule = "";

    return NumberFormat;
  })();

  phonenumbers.PhoneNumberDesc = (() => {
    class PhoneNumberDesc {
      constructor(properties) {
        this.possibleLength = [];
        this.possibleLengthLocalOnly = [];
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new PhoneNumberDesc(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.nationalNumberPattern != null && Object.hasOwnProperty.call(message, "nationalNumberPattern")) {
          writer.uint32(18).string(message.nationalNumberPattern);
        }
        if (message.possibleLength != null && Object.hasOwnProperty.call(message, "possibleLength")) {
          writer.uint32(74).fork();
          for (const element of message.possibleLength) {
            writer.int32(element)
          }
          writer.ldelim();
        }
        if (message.possibleLengthLocalOnly != null && Object.hasOwnProperty.call(message, "possibleLengthLocalOnly")) {
          writer.uint32(82).fork();
          for (const element of message.possibleLengthLocalOnly) {
            writer.int32(element)
          }
          writer.ldelim();
        }
        if (message.exampleNumber != null && Object.hasOwnProperty.call(message, "exampleNumber")) {
          writer.uint32(50).string(message.exampleNumber);
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new PhoneNumberDesc();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 2: {
              message.nationalNumberPattern = reader.string();
              break;
            }
            case 9: {
              if (!message.possibleLength || !message.possibleLength.length) {
                message.possibleLength = [];
              }
              if ((tag & 7) === 2) {
                let end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.possibleLength.push(reader.int32());
              } else {
                message.possibleLength.push(reader.int32());
              }
              break;
            }
            case 10: {
              if (!message.possibleLengthLocalOnly || !message.possibleLengthLocalOnly.length) {
                message.possibleLengthLocalOnly = [];
              }
              if ((tag & 7) === 2) {
                let end2 = reader.uint32() + reader.pos;
                while (reader.pos < end2) message.possibleLengthLocalOnly.push(reader.int32());
              } else {
                message.possibleLengthLocalOnly.push(reader.int32());
              }
              break;
            }
            case 6: {
              message.exampleNumber = reader.string();
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.phonenumbers.PhoneNumberDesc) {
          return object;
        }
        const message = new $root.phonenumbers.PhoneNumberDesc();
        if (object.nationalNumberPattern != null) {
          message.nationalNumberPattern = String(object.nationalNumberPattern);
        }
        if (object.possibleLength) {
          if (!Array.isArray(object.possibleLength)) {
            throw new TypeError(".phonenumbers.PhoneNumberDesc.possibleLength: array type expected, but got " + (typeof object.possibleLength))
          }
          message.possibleLength = new Array(object.possibleLength.length);
          for (let i = 0; i < object.possibleLength.length; ++i) {
            message.possibleLength[i] = object.possibleLength[i] | 0;
          }
        }
        if (object.possibleLengthLocalOnly) {
          if (!Array.isArray(object.possibleLengthLocalOnly)) {
            throw new TypeError(".phonenumbers.PhoneNumberDesc.possibleLengthLocalOnly: array type expected, but got " + (typeof object.possibleLengthLocalOnly))
          }
          message.possibleLengthLocalOnly = new Array(object.possibleLengthLocalOnly.length);
          for (let i = 0; i < object.possibleLengthLocalOnly.length; ++i) {
            message.possibleLengthLocalOnly[i] = object.possibleLengthLocalOnly[i] | 0;
          }
        }
        if (object.exampleNumber != null) {
          message.exampleNumber = String(object.exampleNumber);
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
          object.possibleLength = [];
          object.possibleLengthLocalOnly = [];
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
          object.nationalNumberPattern = "";
          object.exampleNumber = "";
        }
        let keys;
        if (message.nationalNumberPattern != null && message.hasOwnProperty("nationalNumberPattern")) {
          object.nationalNumberPattern = message.nationalNumberPattern;
        }
        if (message.possibleLength && message.possibleLength.length) {
          object.possibleLength = new Array(message.possibleLength.length);
          for (let i = 0; i < message.possibleLength.length; ++i) {
            object.possibleLength[i] = message.possibleLength[i];
          }
        }
        if (message.possibleLengthLocalOnly && message.possibleLengthLocalOnly.length) {
          object.possibleLengthLocalOnly = new Array(message.possibleLengthLocalOnly.length);
          for (let i = 0; i < message.possibleLengthLocalOnly.length; ++i) {
            object.possibleLengthLocalOnly[i] = message.possibleLengthLocalOnly[i];
          }
        }
        if (message.exampleNumber != null && message.hasOwnProperty("exampleNumber")) {
          object.exampleNumber = message.exampleNumber;
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "phonenumbers.PhoneNumberDesc";
      }
    }

    PhoneNumberDesc.prototype.nationalNumberPattern = "";
    PhoneNumberDesc.prototype.possibleLength = $util.emptyArray;
    PhoneNumberDesc.prototype.possibleLengthLocalOnly = $util.emptyArray;
    PhoneNumberDesc.prototype.exampleNumber = "";

    return PhoneNumberDesc;
  })();

  phonenumbers.PhoneMetadata = (() => {
    class PhoneMetadata {
      constructor(properties) {
        this.numberFormat = [];
        this.intlNumberFormat = [];
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new PhoneMetadata(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.generalDesc != null && Object.hasOwnProperty.call(message, "generalDesc")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.generalDesc, writer.uint32(10).fork()).ldelim();
        }
        if (message.fixedLine != null && Object.hasOwnProperty.call(message, "fixedLine")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.fixedLine, writer.uint32(18).fork()).ldelim();
        }
        if (message.mobile != null && Object.hasOwnProperty.call(message, "mobile")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.mobile, writer.uint32(26).fork()).ldelim();
        }
        if (message.tollFree != null && Object.hasOwnProperty.call(message, "tollFree")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.tollFree, writer.uint32(34).fork()).ldelim();
        }
        if (message.premiumRate != null && Object.hasOwnProperty.call(message, "premiumRate")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.premiumRate, writer.uint32(42).fork()).ldelim();
        }
        if (message.sharedCost != null && Object.hasOwnProperty.call(message, "sharedCost")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.sharedCost, writer.uint32(50).fork()).ldelim();
        }
        if (message.personalNumber != null && Object.hasOwnProperty.call(message, "personalNumber")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.personalNumber, writer.uint32(58).fork()).ldelim();
        }
        if (message.voip != null && Object.hasOwnProperty.call(message, "voip")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.voip, writer.uint32(66).fork()).ldelim();
        }
        if (message.pager != null && Object.hasOwnProperty.call(message, "pager")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.pager, writer.uint32(170).fork()).ldelim();
        }
        if (message.uan != null && Object.hasOwnProperty.call(message, "uan")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.uan, writer.uint32(202).fork()).ldelim();
        }
        if (message.emergency != null && Object.hasOwnProperty.call(message, "emergency")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.emergency, writer.uint32(218).fork()).ldelim();
        }
        if (message.voicemail != null && Object.hasOwnProperty.call(message, "voicemail")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.voicemail, writer.uint32(226).fork()).ldelim();
        }
        if (message.shortCode != null && Object.hasOwnProperty.call(message, "shortCode")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.shortCode, writer.uint32(234).fork()).ldelim();
        }
        if (message.standardRate != null && Object.hasOwnProperty.call(message, "standardRate")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.standardRate, writer.uint32(242).fork()).ldelim();
        }
        if (message.carrierSpecific != null && Object.hasOwnProperty.call(message, "carrierSpecific")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.carrierSpecific, writer.uint32(250).fork()).ldelim();
        }
        if (message.smsServices != null && Object.hasOwnProperty.call(message, "smsServices")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.smsServices, writer.uint32(266).fork()).ldelim();
        }
        if (message.noInternationalDialling != null && Object.hasOwnProperty.call(message, "noInternationalDialling")) {
          $root.phonenumbers.PhoneNumberDesc.encode(message.noInternationalDialling, writer.uint32(194).fork()).ldelim();
        }
        if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
          writer.uint32(74).string(message.id);
        }
        if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode")) {
          writer.uint32(80).int32(message.countryCode);
        }
        if (message.internationalPrefix != null && Object.hasOwnProperty.call(message, "internationalPrefix")) {
          writer.uint32(90).string(message.internationalPrefix);
        }
        if (message.preferredInternationalPrefix != null && Object.hasOwnProperty.call(message, "preferredInternationalPrefix")) {
          writer.uint32(138).string(message.preferredInternationalPrefix);
        }
        if (message.nationalPrefix != null && Object.hasOwnProperty.call(message, "nationalPrefix")) {
          writer.uint32(98).string(message.nationalPrefix);
        }
        if (message.preferredExtnPrefix != null && Object.hasOwnProperty.call(message, "preferredExtnPrefix")) {
          writer.uint32(106).string(message.preferredExtnPrefix);
        }
        if (message.nationalPrefixForParsing != null && Object.hasOwnProperty.call(message, "nationalPrefixForParsing")) {
          writer.uint32(122).string(message.nationalPrefixForParsing);
        }
        if (message.nationalPrefixTransformRule != null && Object.hasOwnProperty.call(message, "nationalPrefixTransformRule")) {
          writer.uint32(130).string(message.nationalPrefixTransformRule);
        }
        if (message.sameMobileAndFixedLinePattern != null && Object.hasOwnProperty.call(message, "sameMobileAndFixedLinePattern")) {
          writer.uint32(144).bool(message.sameMobileAndFixedLinePattern);
        }
        if (message.numberFormat != null && Object.hasOwnProperty.call(message, "numberFormat")) {
          for (const element of message.numberFormat) {
            $root.phonenumbers.NumberFormat.encode(element, writer.uint32(154).fork()).ldelim();
          }
        }
        if (message.intlNumberFormat != null && Object.hasOwnProperty.call(message, "intlNumberFormat")) {
          for (const element of message.intlNumberFormat) {
            $root.phonenumbers.NumberFormat.encode(element, writer.uint32(162).fork()).ldelim();
          }
        }
        if (message.mainCountryForCode != null && Object.hasOwnProperty.call(message, "mainCountryForCode")) {
          writer.uint32(176).bool(message.mainCountryForCode);
        }
        if (message.leadingDigits != null && Object.hasOwnProperty.call(message, "leadingDigits")) {
          writer.uint32(186).string(message.leadingDigits);
        }
        if (message.leadingZeroPossible != null && Object.hasOwnProperty.call(message, "leadingZeroPossible")) {
          writer.uint32(208).bool(message.leadingZeroPossible);
        }
        if (message.mobileNumberPortableRegion != null && Object.hasOwnProperty.call(message, "mobileNumberPortableRegion")) {
          writer.uint32(256).bool(message.mobileNumberPortableRegion);
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new PhoneMetadata();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.generalDesc = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 2: {
              message.fixedLine = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 3: {
              message.mobile = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 4: {
              message.tollFree = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 5: {
              message.premiumRate = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 6: {
              message.sharedCost = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 7: {
              message.personalNumber = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 8: {
              message.voip = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 21: {
              message.pager = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 25: {
              message.uan = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 27: {
              message.emergency = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 28: {
              message.voicemail = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 29: {
              message.shortCode = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 30: {
              message.standardRate = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 31: {
              message.carrierSpecific = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 33: {
              message.smsServices = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 24: {
              message.noInternationalDialling = $root.phonenumbers.PhoneNumberDesc.decode(reader, reader.uint32());
              break;
            }
            case 9: {
              message.id = reader.string();
              break;
            }
            case 10: {
              message.countryCode = reader.int32();
              break;
            }
            case 11: {
              message.internationalPrefix = reader.string();
              break;
            }
            case 17: {
              message.preferredInternationalPrefix = reader.string();
              break;
            }
            case 12: {
              message.nationalPrefix = reader.string();
              break;
            }
            case 13: {
              message.preferredExtnPrefix = reader.string();
              break;
            }
            case 15: {
              message.nationalPrefixForParsing = reader.string();
              break;
            }
            case 16: {
              message.nationalPrefixTransformRule = reader.string();
              break;
            }
            case 18: {
              message.sameMobileAndFixedLinePattern = reader.bool();
              break;
            }
            case 19: {
              if (!message.numberFormat || !message.numberFormat.length) {
                message.numberFormat = [];
              }
              message.numberFormat.push($root.phonenumbers.NumberFormat.decode(reader, reader.uint32()));
              break;
            }
            case 20: {
              if (!message.intlNumberFormat || !message.intlNumberFormat.length) {
                message.intlNumberFormat = [];
              }
              message.intlNumberFormat.push($root.phonenumbers.NumberFormat.decode(reader, reader.uint32()));
              break;
            }
            case 22: {
              message.mainCountryForCode = reader.bool();
              break;
            }
            case 23: {
              message.leadingDigits = reader.string();
              break;
            }
            case 26: {
              message.leadingZeroPossible = reader.bool();
              break;
            }
            case 32: {
              message.mobileNumberPortableRegion = reader.bool();
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.phonenumbers.PhoneMetadata) {
          return object;
        }
        const message = new $root.phonenumbers.PhoneMetadata();
        if (object.generalDesc != null) {
          if (typeof object.generalDesc !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.generalDesc: object expected, but got " + (typeof object.generalDesc));
          }
          message.generalDesc = $root.phonenumbers.PhoneNumberDesc.fromObject(object.generalDesc);
        }
        if (object.fixedLine != null) {
          if (typeof object.fixedLine !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.fixedLine: object expected, but got " + (typeof object.fixedLine));
          }
          message.fixedLine = $root.phonenumbers.PhoneNumberDesc.fromObject(object.fixedLine);
        }
        if (object.mobile != null) {
          if (typeof object.mobile !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.mobile: object expected, but got " + (typeof object.mobile));
          }
          message.mobile = $root.phonenumbers.PhoneNumberDesc.fromObject(object.mobile);
        }
        if (object.tollFree != null) {
          if (typeof object.tollFree !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.tollFree: object expected, but got " + (typeof object.tollFree));
          }
          message.tollFree = $root.phonenumbers.PhoneNumberDesc.fromObject(object.tollFree);
        }
        if (object.premiumRate != null) {
          if (typeof object.premiumRate !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.premiumRate: object expected, but got " + (typeof object.premiumRate));
          }
          message.premiumRate = $root.phonenumbers.PhoneNumberDesc.fromObject(object.premiumRate);
        }
        if (object.sharedCost != null) {
          if (typeof object.sharedCost !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.sharedCost: object expected, but got " + (typeof object.sharedCost));
          }
          message.sharedCost = $root.phonenumbers.PhoneNumberDesc.fromObject(object.sharedCost);
        }
        if (object.personalNumber != null) {
          if (typeof object.personalNumber !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.personalNumber: object expected, but got " + (typeof object.personalNumber));
          }
          message.personalNumber = $root.phonenumbers.PhoneNumberDesc.fromObject(object.personalNumber);
        }
        if (object.voip != null) {
          if (typeof object.voip !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.voip: object expected, but got " + (typeof object.voip));
          }
          message.voip = $root.phonenumbers.PhoneNumberDesc.fromObject(object.voip);
        }
        if (object.pager != null) {
          if (typeof object.pager !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.pager: object expected, but got " + (typeof object.pager));
          }
          message.pager = $root.phonenumbers.PhoneNumberDesc.fromObject(object.pager);
        }
        if (object.uan != null) {
          if (typeof object.uan !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.uan: object expected, but got " + (typeof object.uan));
          }
          message.uan = $root.phonenumbers.PhoneNumberDesc.fromObject(object.uan);
        }
        if (object.emergency != null) {
          if (typeof object.emergency !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.emergency: object expected, but got " + (typeof object.emergency));
          }
          message.emergency = $root.phonenumbers.PhoneNumberDesc.fromObject(object.emergency);
        }
        if (object.voicemail != null) {
          if (typeof object.voicemail !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.voicemail: object expected, but got " + (typeof object.voicemail));
          }
          message.voicemail = $root.phonenumbers.PhoneNumberDesc.fromObject(object.voicemail);
        }
        if (object.shortCode != null) {
          if (typeof object.shortCode !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.shortCode: object expected, but got " + (typeof object.shortCode));
          }
          message.shortCode = $root.phonenumbers.PhoneNumberDesc.fromObject(object.shortCode);
        }
        if (object.standardRate != null) {
          if (typeof object.standardRate !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.standardRate: object expected, but got " + (typeof object.standardRate));
          }
          message.standardRate = $root.phonenumbers.PhoneNumberDesc.fromObject(object.standardRate);
        }
        if (object.carrierSpecific != null) {
          if (typeof object.carrierSpecific !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.carrierSpecific: object expected, but got " + (typeof object.carrierSpecific));
          }
          message.carrierSpecific = $root.phonenumbers.PhoneNumberDesc.fromObject(object.carrierSpecific);
        }
        if (object.smsServices != null) {
          if (typeof object.smsServices !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.smsServices: object expected, but got " + (typeof object.smsServices));
          }
          message.smsServices = $root.phonenumbers.PhoneNumberDesc.fromObject(object.smsServices);
        }
        if (object.noInternationalDialling != null) {
          if (typeof object.noInternationalDialling !== "object") {
            throw new TypeError(".phonenumbers.PhoneMetadata.noInternationalDialling: object expected, but got " + (typeof object.noInternationalDialling));
          }
          message.noInternationalDialling = $root.phonenumbers.PhoneNumberDesc.fromObject(object.noInternationalDialling);
        }
        if (object.id != null) {
          message.id = String(object.id);
        }
        if (object.countryCode != null) {
          message.countryCode = object.countryCode | 0;
        }
        if (object.internationalPrefix != null) {
          message.internationalPrefix = String(object.internationalPrefix);
        }
        if (object.preferredInternationalPrefix != null) {
          message.preferredInternationalPrefix = String(object.preferredInternationalPrefix);
        }
        if (object.nationalPrefix != null) {
          message.nationalPrefix = String(object.nationalPrefix);
        }
        if (object.preferredExtnPrefix != null) {
          message.preferredExtnPrefix = String(object.preferredExtnPrefix);
        }
        if (object.nationalPrefixForParsing != null) {
          message.nationalPrefixForParsing = String(object.nationalPrefixForParsing);
        }
        if (object.nationalPrefixTransformRule != null) {
          message.nationalPrefixTransformRule = String(object.nationalPrefixTransformRule);
        }
        if (object.sameMobileAndFixedLinePattern != null) {
          message.sameMobileAndFixedLinePattern = Boolean(object.sameMobileAndFixedLinePattern);
        }
        if (object.numberFormat) {
          if (!Array.isArray(object.numberFormat)) {
            throw new TypeError(".phonenumbers.PhoneMetadata.numberFormat: array type expected, but got " + (typeof object.numberFormat))
          }
          message.numberFormat = new Array(object.numberFormat.length);
          for (let i = 0; i < object.numberFormat.length; ++i) {
            if (typeof object.numberFormat[i] !== "object") {
              throw new TypeError(".phonenumbers.PhoneMetadata.numberFormat: object expected, but got " + (typeof object.numberFormat[i]));
            }
            message.numberFormat[i] = $root.phonenumbers.NumberFormat.fromObject(object.numberFormat[i]);
          }
        }
        if (object.intlNumberFormat) {
          if (!Array.isArray(object.intlNumberFormat)) {
            throw new TypeError(".phonenumbers.PhoneMetadata.intlNumberFormat: array type expected, but got " + (typeof object.intlNumberFormat))
          }
          message.intlNumberFormat = new Array(object.intlNumberFormat.length);
          for (let i = 0; i < object.intlNumberFormat.length; ++i) {
            if (typeof object.intlNumberFormat[i] !== "object") {
              throw new TypeError(".phonenumbers.PhoneMetadata.intlNumberFormat: object expected, but got " + (typeof object.intlNumberFormat[i]));
            }
            message.intlNumberFormat[i] = $root.phonenumbers.NumberFormat.fromObject(object.intlNumberFormat[i]);
          }
        }
        if (object.mainCountryForCode != null) {
          message.mainCountryForCode = Boolean(object.mainCountryForCode);
        }
        if (object.leadingDigits != null) {
          message.leadingDigits = String(object.leadingDigits);
        }
        if (object.leadingZeroPossible != null) {
          message.leadingZeroPossible = Boolean(object.leadingZeroPossible);
        }
        if (object.mobileNumberPortableRegion != null) {
          message.mobileNumberPortableRegion = Boolean(object.mobileNumberPortableRegion);
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
          object.numberFormat = [];
          object.intlNumberFormat = [];
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
          object.generalDesc = null;
          object.fixedLine = null;
          object.mobile = null;
          object.tollFree = null;
          object.premiumRate = null;
          object.sharedCost = null;
          object.personalNumber = null;
          object.voip = null;
          object.pager = null;
          object.uan = null;
          object.emergency = null;
          object.voicemail = null;
          object.shortCode = null;
          object.standardRate = null;
          object.carrierSpecific = null;
          object.smsServices = null;
          object.noInternationalDialling = null;
          object.id = "";
          object.countryCode = 0;
          object.internationalPrefix = "";
          object.preferredInternationalPrefix = "";
          object.nationalPrefix = "";
          object.preferredExtnPrefix = "";
          object.nationalPrefixForParsing = "";
          object.nationalPrefixTransformRule = "";
          object.sameMobileAndFixedLinePattern = false;
          object.mainCountryForCode = false;
          object.leadingDigits = "";
          object.leadingZeroPossible = false;
          object.mobileNumberPortableRegion = false;
        }
        let keys;
        if (message.generalDesc != null && message.hasOwnProperty("generalDesc")) {
          object.generalDesc = $root.phonenumbers.PhoneNumberDesc.toObject(message.generalDesc, options);
        }
        if (message.fixedLine != null && message.hasOwnProperty("fixedLine")) {
          object.fixedLine = $root.phonenumbers.PhoneNumberDesc.toObject(message.fixedLine, options);
        }
        if (message.mobile != null && message.hasOwnProperty("mobile")) {
          object.mobile = $root.phonenumbers.PhoneNumberDesc.toObject(message.mobile, options);
        }
        if (message.tollFree != null && message.hasOwnProperty("tollFree")) {
          object.tollFree = $root.phonenumbers.PhoneNumberDesc.toObject(message.tollFree, options);
        }
        if (message.premiumRate != null && message.hasOwnProperty("premiumRate")) {
          object.premiumRate = $root.phonenumbers.PhoneNumberDesc.toObject(message.premiumRate, options);
        }
        if (message.sharedCost != null && message.hasOwnProperty("sharedCost")) {
          object.sharedCost = $root.phonenumbers.PhoneNumberDesc.toObject(message.sharedCost, options);
        }
        if (message.personalNumber != null && message.hasOwnProperty("personalNumber")) {
          object.personalNumber = $root.phonenumbers.PhoneNumberDesc.toObject(message.personalNumber, options);
        }
        if (message.voip != null && message.hasOwnProperty("voip")) {
          object.voip = $root.phonenumbers.PhoneNumberDesc.toObject(message.voip, options);
        }
        if (message.pager != null && message.hasOwnProperty("pager")) {
          object.pager = $root.phonenumbers.PhoneNumberDesc.toObject(message.pager, options);
        }
        if (message.uan != null && message.hasOwnProperty("uan")) {
          object.uan = $root.phonenumbers.PhoneNumberDesc.toObject(message.uan, options);
        }
        if (message.emergency != null && message.hasOwnProperty("emergency")) {
          object.emergency = $root.phonenumbers.PhoneNumberDesc.toObject(message.emergency, options);
        }
        if (message.voicemail != null && message.hasOwnProperty("voicemail")) {
          object.voicemail = $root.phonenumbers.PhoneNumberDesc.toObject(message.voicemail, options);
        }
        if (message.shortCode != null && message.hasOwnProperty("shortCode")) {
          object.shortCode = $root.phonenumbers.PhoneNumberDesc.toObject(message.shortCode, options);
        }
        if (message.standardRate != null && message.hasOwnProperty("standardRate")) {
          object.standardRate = $root.phonenumbers.PhoneNumberDesc.toObject(message.standardRate, options);
        }
        if (message.carrierSpecific != null && message.hasOwnProperty("carrierSpecific")) {
          object.carrierSpecific = $root.phonenumbers.PhoneNumberDesc.toObject(message.carrierSpecific, options);
        }
        if (message.smsServices != null && message.hasOwnProperty("smsServices")) {
          object.smsServices = $root.phonenumbers.PhoneNumberDesc.toObject(message.smsServices, options);
        }
        if (message.noInternationalDialling != null && message.hasOwnProperty("noInternationalDialling")) {
          object.noInternationalDialling = $root.phonenumbers.PhoneNumberDesc.toObject(message.noInternationalDialling, options);
        }
        if (message.id != null && message.hasOwnProperty("id")) {
          object.id = message.id;
        }
        if (message.countryCode != null && message.hasOwnProperty("countryCode")) {
          object.countryCode = message.countryCode;
        }
        if (message.internationalPrefix != null && message.hasOwnProperty("internationalPrefix")) {
          object.internationalPrefix = message.internationalPrefix;
        }
        if (message.preferredInternationalPrefix != null && message.hasOwnProperty("preferredInternationalPrefix")) {
          object.preferredInternationalPrefix = message.preferredInternationalPrefix;
        }
        if (message.nationalPrefix != null && message.hasOwnProperty("nationalPrefix")) {
          object.nationalPrefix = message.nationalPrefix;
        }
        if (message.preferredExtnPrefix != null && message.hasOwnProperty("preferredExtnPrefix")) {
          object.preferredExtnPrefix = message.preferredExtnPrefix;
        }
        if (message.nationalPrefixForParsing != null && message.hasOwnProperty("nationalPrefixForParsing")) {
          object.nationalPrefixForParsing = message.nationalPrefixForParsing;
        }
        if (message.nationalPrefixTransformRule != null && message.hasOwnProperty("nationalPrefixTransformRule")) {
          object.nationalPrefixTransformRule = message.nationalPrefixTransformRule;
        }
        if (message.sameMobileAndFixedLinePattern != null && message.hasOwnProperty("sameMobileAndFixedLinePattern")) {
          object.sameMobileAndFixedLinePattern = message.sameMobileAndFixedLinePattern;
        }
        if (message.numberFormat && message.numberFormat.length) {
          object.numberFormat = new Array(message.numberFormat.length);
          for (let i = 0; i < message.numberFormat.length; ++i) {
            object.numberFormat[i] = $root.phonenumbers.NumberFormat.toObject(message.numberFormat[i], options);
          }
        }
        if (message.intlNumberFormat && message.intlNumberFormat.length) {
          object.intlNumberFormat = new Array(message.intlNumberFormat.length);
          for (let i = 0; i < message.intlNumberFormat.length; ++i) {
            object.intlNumberFormat[i] = $root.phonenumbers.NumberFormat.toObject(message.intlNumberFormat[i], options);
          }
        }
        if (message.mainCountryForCode != null && message.hasOwnProperty("mainCountryForCode")) {
          object.mainCountryForCode = message.mainCountryForCode;
        }
        if (message.leadingDigits != null && message.hasOwnProperty("leadingDigits")) {
          object.leadingDigits = message.leadingDigits;
        }
        if (message.leadingZeroPossible != null && message.hasOwnProperty("leadingZeroPossible")) {
          object.leadingZeroPossible = message.leadingZeroPossible;
        }
        if (message.mobileNumberPortableRegion != null && message.hasOwnProperty("mobileNumberPortableRegion")) {
          object.mobileNumberPortableRegion = message.mobileNumberPortableRegion;
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "phonenumbers.PhoneMetadata";
      }
    }

    PhoneMetadata.prototype.generalDesc = null;
    PhoneMetadata.prototype.fixedLine = null;
    PhoneMetadata.prototype.mobile = null;
    PhoneMetadata.prototype.tollFree = null;
    PhoneMetadata.prototype.premiumRate = null;
    PhoneMetadata.prototype.sharedCost = null;
    PhoneMetadata.prototype.personalNumber = null;
    PhoneMetadata.prototype.voip = null;
    PhoneMetadata.prototype.pager = null;
    PhoneMetadata.prototype.uan = null;
    PhoneMetadata.prototype.emergency = null;
    PhoneMetadata.prototype.voicemail = null;
    PhoneMetadata.prototype.shortCode = null;
    PhoneMetadata.prototype.standardRate = null;
    PhoneMetadata.prototype.carrierSpecific = null;
    PhoneMetadata.prototype.smsServices = null;
    PhoneMetadata.prototype.noInternationalDialling = null;
    PhoneMetadata.prototype.id = "";
    PhoneMetadata.prototype.countryCode = 0;
    PhoneMetadata.prototype.internationalPrefix = "";
    PhoneMetadata.prototype.preferredInternationalPrefix = "";
    PhoneMetadata.prototype.nationalPrefix = "";
    PhoneMetadata.prototype.preferredExtnPrefix = "";
    PhoneMetadata.prototype.nationalPrefixForParsing = "";
    PhoneMetadata.prototype.nationalPrefixTransformRule = "";
    PhoneMetadata.prototype.sameMobileAndFixedLinePattern = false;
    PhoneMetadata.prototype.numberFormat = $util.emptyArray;
    PhoneMetadata.prototype.intlNumberFormat = $util.emptyArray;
    PhoneMetadata.prototype.mainCountryForCode = false;
    PhoneMetadata.prototype.leadingDigits = "";
    PhoneMetadata.prototype.leadingZeroPossible = false;
    PhoneMetadata.prototype.mobileNumberPortableRegion = false;

    return PhoneMetadata;
  })();

  phonenumbers.PhoneMetadataCollection = (() => {
    class PhoneMetadataCollection {
      constructor(properties) {
        this.metadata = [];
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new PhoneMetadataCollection(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata")) {
          for (const element of message.metadata) {
            $root.phonenumbers.PhoneMetadata.encode(element, writer.uint32(10).fork()).ldelim();
          }
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new PhoneMetadataCollection();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              if (!message.metadata || !message.metadata.length) {
                message.metadata = [];
              }
              message.metadata.push($root.phonenumbers.PhoneMetadata.decode(reader, reader.uint32()));
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.phonenumbers.PhoneMetadataCollection) {
          return object;
        }
        const message = new $root.phonenumbers.PhoneMetadataCollection();
        if (object.metadata) {
          if (!Array.isArray(object.metadata)) {
            throw new TypeError(".phonenumbers.PhoneMetadataCollection.metadata: array type expected, but got " + (typeof object.metadata))
          }
          message.metadata = new Array(object.metadata.length);
          for (let i = 0; i < object.metadata.length; ++i) {
            if (typeof object.metadata[i] !== "object") {
              throw new TypeError(".phonenumbers.PhoneMetadataCollection.metadata: object expected, but got " + (typeof object.metadata[i]));
            }
            message.metadata[i] = $root.phonenumbers.PhoneMetadata.fromObject(object.metadata[i]);
          }
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
          object.metadata = [];
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
        }
        let keys;
        if (message.metadata && message.metadata.length) {
          object.metadata = new Array(message.metadata.length);
          for (let i = 0; i < message.metadata.length; ++i) {
            object.metadata[i] = $root.phonenumbers.PhoneMetadata.toObject(message.metadata[i], options);
          }
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "phonenumbers.PhoneMetadataCollection";
      }
    }

    PhoneMetadataCollection.prototype.metadata = $util.emptyArray;

    return PhoneMetadataCollection;
  })();

  phonenumbers.PhoneNumber = (() => {
    class PhoneNumber {
      constructor(properties) {
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new PhoneNumber(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode")) {
          writer.uint32(8).int32(message.countryCode);
        }
        if (message.nationalNumber != null && Object.hasOwnProperty.call(message, "nationalNumber")) {
          writer.uint32(16).uint64(message.nationalNumber);
        }
        if (message.extension != null && Object.hasOwnProperty.call(message, "extension")) {
          writer.uint32(26).string(message.extension);
        }
        if (message.italianLeadingZero != null && Object.hasOwnProperty.call(message, "italianLeadingZero")) {
          writer.uint32(32).bool(message.italianLeadingZero);
        }
        if (message.numberOfLeadingZeros != null && Object.hasOwnProperty.call(message, "numberOfLeadingZeros")) {
          writer.uint32(64).int32(message.numberOfLeadingZeros);
        }
        if (message.rawInput != null && Object.hasOwnProperty.call(message, "rawInput")) {
          writer.uint32(42).string(message.rawInput);
        }
        if (message.countryCodeSource != null && Object.hasOwnProperty.call(message, "countryCodeSource")) {
          writer.uint32(48).int32(message.countryCodeSource);
        }
        if (message.preferredDomesticCarrierCode != null && Object.hasOwnProperty.call(message, "preferredDomesticCarrierCode")) {
          writer.uint32(58).string(message.preferredDomesticCarrierCode);
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new PhoneNumber();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.countryCode = reader.int32();
              break;
            }
            case 2: {
              message.nationalNumber = reader.uint64();
              break;
            }
            case 3: {
              message.extension = reader.string();
              break;
            }
            case 4: {
              message.italianLeadingZero = reader.bool();
              break;
            }
            case 8: {
              message.numberOfLeadingZeros = reader.int32();
              break;
            }
            case 5: {
              message.rawInput = reader.string();
              break;
            }
            case 6: {
              message.countryCodeSource = reader.int32();
              break;
            }
            case 7: {
              message.preferredDomesticCarrierCode = reader.string();
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.phonenumbers.PhoneNumber) {
          return object;
        }
        const message = new $root.phonenumbers.PhoneNumber();
        if (object.countryCode != null) {
          message.countryCode = object.countryCode | 0;
        }
        if (object.nationalNumber != null) {
          if ($util.Long) {
            (message.nationalNumber = $util.Long.fromValue(object.nationalNumber)).unsigned = true;
          } else if (typeof object.nationalNumber === "string") {
            message.nationalNumber = parseInt(object.nationalNumber, 10);
          } else if (typeof object.nationalNumber === "number") {
            message.nationalNumber = object.nationalNumber;
          } else if (typeof object.nationalNumber === "object") {
            message.nationalNumber = new $util.LongBits(object.nationalNumber.low >>> 0, object.nationalNumber.high >>> 0).toNumber(true);
          }
        }
        if (object.extension != null) {
          message.extension = String(object.extension);
        }
        if (object.italianLeadingZero != null) {
          message.italianLeadingZero = Boolean(object.italianLeadingZero);
        }
        if (object.numberOfLeadingZeros != null) {
          message.numberOfLeadingZeros = object.numberOfLeadingZeros | 0;
        }
        if (object.rawInput != null) {
          message.rawInput = String(object.rawInput);
        }
        if (object.countryCodeSource != null) {
          switch (object.countryCodeSource) {
            case "UNSPECIFIED":
            case 0: {
              message.countryCodeSource = 0;
              break;
            }
            case "FROM_NUMBER_WITH_PLUS_SIGN":
            case 1: {
              message.countryCodeSource = 1;
              break;
            }
            case "FROM_NUMBER_WITH_IDD":
            case 5: {
              message.countryCodeSource = 5;
              break;
            }
            case "FROM_NUMBER_WITHOUT_PLUS_SIGN":
            case 10: {
              message.countryCodeSource = 10;
              break;
            }
            case "FROM_DEFAULT_COUNTRY":
            case 20: {
              message.countryCodeSource = 20;
              break;
            }
            default: {
              if (typeof object.countryCodeSource == "number") {
                message.countryCodeSource = object.countryCodeSource;
                break;
              }
              break;
            }
          }
        }
        if (object.preferredDomesticCarrierCode != null) {
          message.preferredDomesticCarrierCode = String(object.preferredDomesticCarrierCode);
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
          object.countryCode = 0;
          if ($util.Long) {
            let long = new $util.Long(0, 0, true)
            object.nationalNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else {
            object.nationalNumber = options.longs === String ? "0" : 0;
          }
          object.extension = "";
          object.italianLeadingZero = false;
          object.numberOfLeadingZeros = 0;
          object.rawInput = "";
          object.countryCodeSource = options.enums === String ? "UNSPECIFIED" : 0
          object.preferredDomesticCarrierCode = "";
        }
        let keys;
        if (message.countryCode != null && message.hasOwnProperty("countryCode")) {
          object.countryCode = message.countryCode;
        }
        if (message.nationalNumber != null && message.hasOwnProperty("nationalNumber")) {
          object.nationalNumber = typeof message.nationalNumber === "number" ? (options.longs === String ? String(message.nationalNumber) : message.nationalNumber) : (options.longs === String ? $util.Long.prototype.toString.call(message.nationalNumber) : options.longs === Number ? new $util.LongBits(message.nationalNumber.low >>> 0, message.nationalNumber.high >>> 0).toNumber(true) : message.nationalNumber);
        }
        if (message.extension != null && message.hasOwnProperty("extension")) {
          object.extension = message.extension;
        }
        if (message.italianLeadingZero != null && message.hasOwnProperty("italianLeadingZero")) {
          object.italianLeadingZero = message.italianLeadingZero;
        }
        if (message.numberOfLeadingZeros != null && message.hasOwnProperty("numberOfLeadingZeros")) {
          object.numberOfLeadingZeros = message.numberOfLeadingZeros;
        }
        if (message.rawInput != null && message.hasOwnProperty("rawInput")) {
          object.rawInput = message.rawInput;
        }
        if (message.countryCodeSource != null && message.hasOwnProperty("countryCodeSource")) {
          object.countryCodeSource = options.enums === String ? $root.phonenumbers.PhoneNumber.CountryCodeSource[message.countryCodeSource] === undefined ? message.countryCodeSource : $root.phonenumbers.PhoneNumber.CountryCodeSource[message.countryCodeSource] : message.countryCodeSource;
        }
        if (message.preferredDomesticCarrierCode != null && message.hasOwnProperty("preferredDomesticCarrierCode")) {
          object.preferredDomesticCarrierCode = message.preferredDomesticCarrierCode;
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "phonenumbers.PhoneNumber";
      }
    }

    PhoneNumber.prototype.countryCode = 0;
    PhoneNumber.prototype.nationalNumber = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    PhoneNumber.prototype.extension = "";
    PhoneNumber.prototype.italianLeadingZero = false;
    PhoneNumber.prototype.numberOfLeadingZeros = 0;
    PhoneNumber.prototype.rawInput = "";
    PhoneNumber.prototype.countryCodeSource = 0;
    PhoneNumber.prototype.preferredDomesticCarrierCode = "";
    PhoneNumber.CountryCodeSource = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED"] = 0;
      values[valuesById[1] = "FROM_NUMBER_WITH_PLUS_SIGN"] = 1;
      values[valuesById[5] = "FROM_NUMBER_WITH_IDD"] = 5;
      values[valuesById[10] = "FROM_NUMBER_WITHOUT_PLUS_SIGN"] = 10;
      values[valuesById[20] = "FROM_DEFAULT_COUNTRY"] = 20;
      return values;
    })();

    return PhoneNumber;
  })();

  return phonenumbers;
})();

export const phonenumbers = $root.phonenumbers;
