"use strict";

import * as $protobuf from "protobufjs/minimal";
import { freshdesk as freshdesk$1 } from "../../freshdesk_v1_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../workflow_parameters_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.freshdesk = (() => {
    const freshdesk = {};
    freshdesk.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ["fetchContacts", "createOrUpdateCompany", "createOrUpdateContact", "createTicket"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["fetchContacts", "createOrUpdateCompany", "createOrUpdateContact", "createTicket"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.fetchContacts != null && Object.hasOwnProperty.call(message, "fetchContacts")) {
              $root.fetchactions.freshdesk.v1.FetchContacts.encode(message.fetchContacts, writer.uint32(10).fork()).ldelim();
            }
            if (message.createOrUpdateCompany != null && Object.hasOwnProperty.call(message, "createOrUpdateCompany")) {
              $root.fetchactions.freshdesk.v1.CreateOrUpdateCompany.encode(message.createOrUpdateCompany, writer.uint32(18).fork()).ldelim();
            }
            if (message.createOrUpdateContact != null && Object.hasOwnProperty.call(message, "createOrUpdateContact")) {
              $root.fetchactions.freshdesk.v1.CreateOrUpdateContact.encode(message.createOrUpdateContact, writer.uint32(26).fork()).ldelim();
            }
            if (message.createTicket != null && Object.hasOwnProperty.call(message, "createTicket")) {
              $root.fetchactions.freshdesk.v1.CreateTicket.encode(message.createTicket, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.fetchContacts = $root.fetchactions.freshdesk.v1.FetchContacts.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.createOrUpdateCompany = $root.fetchactions.freshdesk.v1.CreateOrUpdateCompany.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.createOrUpdateContact = $root.fetchactions.freshdesk.v1.CreateOrUpdateContact.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  message.createTicket = $root.fetchactions.freshdesk.v1.CreateTicket.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.freshdesk.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.freshdesk.v1.FetchAction();
            if (object.fetchContacts != null) {
              if (typeof object.fetchContacts !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchAction.fetchContacts: object expected, but got " + (typeof object.fetchContacts));
              }
              message.fetchContacts = $root.fetchactions.freshdesk.v1.FetchContacts.fromObject(object.fetchContacts);
            }
            if (object.createOrUpdateCompany != null) {
              if (typeof object.createOrUpdateCompany !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchAction.createOrUpdateCompany: object expected, but got " + (typeof object.createOrUpdateCompany));
              }
              message.createOrUpdateCompany = $root.fetchactions.freshdesk.v1.CreateOrUpdateCompany.fromObject(object.createOrUpdateCompany);
            }
            if (object.createOrUpdateContact != null) {
              if (typeof object.createOrUpdateContact !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchAction.createOrUpdateContact: object expected, but got " + (typeof object.createOrUpdateContact));
              }
              message.createOrUpdateContact = $root.fetchactions.freshdesk.v1.CreateOrUpdateContact.fromObject(object.createOrUpdateContact);
            }
            if (object.createTicket != null) {
              if (typeof object.createTicket !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchAction.createTicket: object expected, but got " + (typeof object.createTicket));
              }
              message.createTicket = $root.fetchactions.freshdesk.v1.CreateTicket.fromObject(object.createTicket);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.fetchContacts != null && message.hasOwnProperty("fetchContacts")) {
              object.fetchContacts = $root.fetchactions.freshdesk.v1.FetchContacts.toObject(message.fetchContacts, options);
              if (options.oneofs) {
                object.type = "fetchContacts";
              }
            }
            if (message.createOrUpdateCompany != null && message.hasOwnProperty("createOrUpdateCompany")) {
              object.createOrUpdateCompany = $root.fetchactions.freshdesk.v1.CreateOrUpdateCompany.toObject(message.createOrUpdateCompany, options);
              if (options.oneofs) {
                object.type = "createOrUpdateCompany";
              }
            }
            if (message.createOrUpdateContact != null && message.hasOwnProperty("createOrUpdateContact")) {
              object.createOrUpdateContact = $root.fetchactions.freshdesk.v1.CreateOrUpdateContact.toObject(message.createOrUpdateContact, options);
              if (options.oneofs) {
                object.type = "createOrUpdateContact";
              }
            }
            if (message.createTicket != null && message.hasOwnProperty("createTicket")) {
              object.createTicket = $root.fetchactions.freshdesk.v1.CreateTicket.toObject(message.createTicket, options);
              if (options.oneofs) {
                object.type = "createTicket";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.freshdesk.v1.FetchAction";
          }
        }

        FetchAction.prototype.fetchContacts = null;
        FetchAction.prototype.createOrUpdateCompany = null;
        FetchAction.prototype.createOrUpdateContact = null;
        FetchAction.prototype.createTicket = null;

        return FetchAction;
      })();

      v1.FetchContacts = (() => {
        class FetchContacts {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchContacts(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                freshdesk$1.v1.Contact.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchContacts();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(freshdesk$1.v1.Contact.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.freshdesk.v1.FetchContacts) {
              return object;
            }
            const message = new $root.fetchactions.freshdesk.v1.FetchContacts();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchContacts.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.FetchContacts.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchContacts.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.FetchContacts.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.freshdesk.v1.FetchContacts.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.FetchContacts.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = freshdesk$1.v1.Contact.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = freshdesk$1.v1.Contact.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.freshdesk.v1.FetchContacts";
          }
        }

        FetchContacts.prototype.params = $util.emptyArray;
        FetchContacts.prototype.configs = $util.emptyArray;
        FetchContacts.prototype.result = $util.emptyArray;

        return FetchContacts;
      })();

      v1.CreateOrUpdateCompany = (() => {
        class CreateOrUpdateCompany {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateCompany(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              freshdesk$1.v1.Company.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateCompany();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  message.result = freshdesk$1.v1.Company.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.freshdesk.v1.CreateOrUpdateCompany) {
              return object;
            }
            const message = new $root.fetchactions.freshdesk.v1.CreateOrUpdateCompany();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateCompany.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateCompany.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateCompany.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateCompany.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateCompany.result: object expected, but got " + (typeof object.result));
              }
              message.result = freshdesk$1.v1.Company.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = freshdesk$1.v1.Company.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.freshdesk.v1.CreateOrUpdateCompany";
          }
        }

        CreateOrUpdateCompany.prototype.params = $util.emptyArray;
        CreateOrUpdateCompany.prototype.configs = $util.emptyArray;
        CreateOrUpdateCompany.prototype.result = null;

        return CreateOrUpdateCompany;
      })();

      v1.CreateOrUpdateContact = (() => {
        class CreateOrUpdateContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateContact(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.doNotAssociateThirdPartyId != null && Object.hasOwnProperty.call(message, "doNotAssociateThirdPartyId")) {
              writer.uint32(24).bool(message.doNotAssociateThirdPartyId);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              freshdesk$1.v1.Contact.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.doNotAssociateThirdPartyId = reader.bool();
                  break;
                }
                case 4: {
                  message.result = freshdesk$1.v1.Contact.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.freshdesk.v1.CreateOrUpdateContact) {
              return object;
            }
            const message = new $root.fetchactions.freshdesk.v1.CreateOrUpdateContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateContact.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateContact.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateContact.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateContact.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.doNotAssociateThirdPartyId != null) {
              message.doNotAssociateThirdPartyId = Boolean(object.doNotAssociateThirdPartyId);
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateOrUpdateContact.result: object expected, but got " + (typeof object.result));
              }
              message.result = freshdesk$1.v1.Contact.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.doNotAssociateThirdPartyId = false;
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.doNotAssociateThirdPartyId != null && message.hasOwnProperty("doNotAssociateThirdPartyId")) {
              object.doNotAssociateThirdPartyId = message.doNotAssociateThirdPartyId;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = freshdesk$1.v1.Contact.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.freshdesk.v1.CreateOrUpdateContact";
          }
        }

        CreateOrUpdateContact.prototype.params = $util.emptyArray;
        CreateOrUpdateContact.prototype.configs = $util.emptyArray;
        CreateOrUpdateContact.prototype.doNotAssociateThirdPartyId = false;
        CreateOrUpdateContact.prototype.result = null;

        return CreateOrUpdateContact;
      })();

      v1.CreateTicket = (() => {
        class CreateTicket {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateTicket(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              freshdesk$1.v1.Ticket.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateTicket();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.result = freshdesk$1.v1.Ticket.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.freshdesk.v1.CreateTicket) {
              return object;
            }
            const message = new $root.fetchactions.freshdesk.v1.CreateTicket();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateTicket.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.CreateTicket.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateTicket.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.freshdesk.v1.CreateTicket.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.freshdesk.v1.CreateTicket.result: object expected, but got " + (typeof object.result));
              }
              message.result = freshdesk$1.v1.Ticket.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = freshdesk$1.v1.Ticket.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.freshdesk.v1.CreateTicket";
          }
        }

        CreateTicket.prototype.params = $util.emptyArray;
        CreateTicket.prototype.configs = $util.emptyArray;
        CreateTicket.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateTicket.prototype.result = null;

        return CreateTicket;
      })();

      return v1;
    })();

    return freshdesk;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
