"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { google as google$3 } from "./cel_values_ts_proto";
import { google as google$1 } from "./checked_ts_proto";
import { google as google$4 } from "./empty_ts_proto";
import { sensitivity as sensitivity$1 } from "./sensitivity_v1_ts_proto";
import { google as google$2 } from "./struct_ts_proto";
import { thirdparties as thirdparties$1 } from "./thirdparties_v1_ts_proto";
import { values as values$1 } from "./values_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflow_parameters = (() => {
  const workflow_parameters = {};
  workflow_parameters.v1 = (() => {
    const v1 = {};
    v1.ExtendedType = (() => {
      class ExtendedType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ExtendedType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ExtendedType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.ExtendedType) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.ExtendedType();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.ExtendedType";
        }
      }

      ExtendedType.Type = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED_TYPE"] = 0;
        values[valuesById[1] = "DATE"] = 1;
        values[valuesById[2] = "URL"] = 2;
        return values;
      })();

      return ExtendedType;
    })();

    v1.StaticDestinationActionParameterType = (() => {
      class StaticDestinationActionParameterType {
        get type() {
          for (const key of ["primitiveType", "wellKnownType", "extendedType"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["primitiveType", "wellKnownType", "extendedType"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StaticDestinationActionParameterType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.primitiveType != null && Object.hasOwnProperty.call(message, "primitiveType")) {
            writer.uint32(8).int32(message.primitiveType);
          }
          if (message.wellKnownType != null && Object.hasOwnProperty.call(message, "wellKnownType")) {
            writer.uint32(16).int32(message.wellKnownType);
          }
          if (message.extendedType != null && Object.hasOwnProperty.call(message, "extendedType")) {
            writer.uint32(24).int32(message.extendedType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StaticDestinationActionParameterType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.primitiveType = reader.int32();
                break;
              }
              case 2: {
                message.wellKnownType = reader.int32();
                break;
              }
              case 3: {
                message.extendedType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.StaticDestinationActionParameterType) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.StaticDestinationActionParameterType();
          if (object.primitiveType != null) {
            switch (object.primitiveType) {
              case "PRIMITIVE_TYPE_UNSPECIFIED":
              case 0: {
                message.primitiveType = 0;
                break;
              }
              case "BOOL":
              case 1: {
                message.primitiveType = 1;
                break;
              }
              case "INT64":
              case 2: {
                message.primitiveType = 2;
                break;
              }
              case "UINT64":
              case 3: {
                message.primitiveType = 3;
                break;
              }
              case "DOUBLE":
              case 4: {
                message.primitiveType = 4;
                break;
              }
              case "STRING":
              case 5: {
                message.primitiveType = 5;
                break;
              }
              case "BYTES":
              case 6: {
                message.primitiveType = 6;
                break;
              }
              default: {
                if (typeof object.primitiveType == "number") {
                  message.primitiveType = object.primitiveType;
                  break;
                }
                break;
              }
            }
          }
          if (object.wellKnownType != null) {
            switch (object.wellKnownType) {
              case "WELL_KNOWN_TYPE_UNSPECIFIED":
              case 0: {
                message.wellKnownType = 0;
                break;
              }
              case "ANY":
              case 1: {
                message.wellKnownType = 1;
                break;
              }
              case "TIMESTAMP":
              case 2: {
                message.wellKnownType = 2;
                break;
              }
              case "DURATION":
              case 3: {
                message.wellKnownType = 3;
                break;
              }
              default: {
                if (typeof object.wellKnownType == "number") {
                  message.wellKnownType = object.wellKnownType;
                  break;
                }
                break;
              }
            }
          }
          if (object.extendedType != null) {
            switch (object.extendedType) {
              case "UNSPECIFIED_TYPE":
              case 0: {
                message.extendedType = 0;
                break;
              }
              case "DATE":
              case 1: {
                message.extendedType = 1;
                break;
              }
              case "URL":
              case 2: {
                message.extendedType = 2;
                break;
              }
              default: {
                if (typeof object.extendedType == "number") {
                  message.extendedType = object.extendedType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.primitiveType != null && message.hasOwnProperty("primitiveType")) {
            object.primitiveType = options.enums === String ? google$1.api.expr.v1alpha1.Type.PrimitiveType[message.primitiveType] === undefined ? message.primitiveType : google$1.api.expr.v1alpha1.Type.PrimitiveType[message.primitiveType] : message.primitiveType;
            if (options.oneofs) {
              object.type = "primitiveType";
            }
          }
          if (message.wellKnownType != null && message.hasOwnProperty("wellKnownType")) {
            object.wellKnownType = options.enums === String ? google$1.api.expr.v1alpha1.Type.WellKnownType[message.wellKnownType] === undefined ? message.wellKnownType : google$1.api.expr.v1alpha1.Type.WellKnownType[message.wellKnownType] : message.wellKnownType;
            if (options.oneofs) {
              object.type = "wellKnownType";
            }
          }
          if (message.extendedType != null && message.hasOwnProperty("extendedType")) {
            object.extendedType = options.enums === String ? $root.workflow_parameters.v1.ExtendedType.Type[message.extendedType] === undefined ? message.extendedType : $root.workflow_parameters.v1.ExtendedType.Type[message.extendedType] : message.extendedType;
            if (options.oneofs) {
              object.type = "extendedType";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.StaticDestinationActionParameterType";
        }
      }

      StaticDestinationActionParameterType.prototype.primitiveType = 0;
      StaticDestinationActionParameterType.prototype.wellKnownType = 0;
      StaticDestinationActionParameterType.prototype.extendedType = 0;

      return StaticDestinationActionParameterType;
    })();

    v1.DynamicDestinationActionParameterType = (() => {
      class DynamicDestinationActionParameterType {
        get type() {
          for (const key of ["mapType", "listType"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["mapType", "listType"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DynamicDestinationActionParameterType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType")) {
            google$1.api.expr.v1alpha1.Type.MapType.encode(message.mapType, writer.uint32(18).fork()).ldelim();
          }
          if (message.listType != null && Object.hasOwnProperty.call(message, "listType")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.encode(message.listType, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DynamicDestinationActionParameterType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 2: {
                message.mapType = google$1.api.expr.v1alpha1.Type.MapType.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.listType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterType) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterType();
          if (object.mapType != null) {
            if (typeof object.mapType !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterType.mapType: object expected, but got " + (typeof object.mapType));
            }
            message.mapType = google$1.api.expr.v1alpha1.Type.MapType.fromObject(object.mapType);
          }
          if (object.listType != null) {
            if (typeof object.listType !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterType.listType: object expected, but got " + (typeof object.listType));
            }
            message.listType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.fromObject(object.listType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.mapType != null && message.hasOwnProperty("mapType")) {
            object.mapType = google$1.api.expr.v1alpha1.Type.MapType.toObject(message.mapType, options);
            if (options.oneofs) {
              object.type = "mapType";
            }
          }
          if (message.listType != null && message.hasOwnProperty("listType")) {
            object.listType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.toObject(message.listType, options);
            if (options.oneofs) {
              object.type = "listType";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterType";
        }
      }

      DynamicDestinationActionParameterType.prototype.mapType = null;
      DynamicDestinationActionParameterType.prototype.listType = null;
      DynamicDestinationActionParameterType.ListType = (() => {
        class ListType {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListType(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.elemType != null && Object.hasOwnProperty.call(message, "elemType")) {
              $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType.encode(message.elemType, writer.uint32(10).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListType();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.elemType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType();
            if (object.elemType != null) {
              if (typeof object.elemType !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.elemType: object expected, but got " + (typeof object.elemType));
              }
              message.elemType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType.fromObject(object.elemType);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.elemType = null;
            }
            let keys;
            if (message.elemType != null && message.hasOwnProperty("elemType")) {
              object.elemType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType.toObject(message.elemType, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterType.ListType";
          }
        }

        ListType.prototype.elemType = null;
        ListType.ElemType = (() => {
          class ElemType {
            get type() {
              for (const key of ["primitiveType", "wellKnownType", "extendedType", "messageType"]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set type(name) {
              for (const key of ["primitiveType", "wellKnownType", "extendedType", "messageType"]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new ElemType(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.primitiveType != null && Object.hasOwnProperty.call(message, "primitiveType")) {
                writer.uint32(8).int32(message.primitiveType);
              }
              if (message.wellKnownType != null && Object.hasOwnProperty.call(message, "wellKnownType")) {
                writer.uint32(16).int32(message.wellKnownType);
              }
              if (message.extendedType != null && Object.hasOwnProperty.call(message, "extendedType")) {
                writer.uint32(24).int32(message.extendedType);
              }
              if (message.messageType != null && Object.hasOwnProperty.call(message, "messageType")) {
                writer.uint32(34).string(message.messageType);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new ElemType();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.primitiveType = reader.int32();
                    break;
                  }
                  case 2: {
                    message.wellKnownType = reader.int32();
                    break;
                  }
                  case 3: {
                    message.extendedType = reader.int32();
                    break;
                  }
                  case 4: {
                    message.messageType = reader.string();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType) {
                return object;
              }
              const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType();
              if (object.primitiveType != null) {
                switch (object.primitiveType) {
                  case "PRIMITIVE_TYPE_UNSPECIFIED":
                  case 0: {
                    message.primitiveType = 0;
                    break;
                  }
                  case "BOOL":
                  case 1: {
                    message.primitiveType = 1;
                    break;
                  }
                  case "INT64":
                  case 2: {
                    message.primitiveType = 2;
                    break;
                  }
                  case "UINT64":
                  case 3: {
                    message.primitiveType = 3;
                    break;
                  }
                  case "DOUBLE":
                  case 4: {
                    message.primitiveType = 4;
                    break;
                  }
                  case "STRING":
                  case 5: {
                    message.primitiveType = 5;
                    break;
                  }
                  case "BYTES":
                  case 6: {
                    message.primitiveType = 6;
                    break;
                  }
                  default: {
                    if (typeof object.primitiveType == "number") {
                      message.primitiveType = object.primitiveType;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.wellKnownType != null) {
                switch (object.wellKnownType) {
                  case "WELL_KNOWN_TYPE_UNSPECIFIED":
                  case 0: {
                    message.wellKnownType = 0;
                    break;
                  }
                  case "ANY":
                  case 1: {
                    message.wellKnownType = 1;
                    break;
                  }
                  case "TIMESTAMP":
                  case 2: {
                    message.wellKnownType = 2;
                    break;
                  }
                  case "DURATION":
                  case 3: {
                    message.wellKnownType = 3;
                    break;
                  }
                  default: {
                    if (typeof object.wellKnownType == "number") {
                      message.wellKnownType = object.wellKnownType;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.extendedType != null) {
                switch (object.extendedType) {
                  case "UNSPECIFIED_TYPE":
                  case 0: {
                    message.extendedType = 0;
                    break;
                  }
                  case "DATE":
                  case 1: {
                    message.extendedType = 1;
                    break;
                  }
                  case "URL":
                  case 2: {
                    message.extendedType = 2;
                    break;
                  }
                  default: {
                    if (typeof object.extendedType == "number") {
                      message.extendedType = object.extendedType;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.messageType != null) {
                message.messageType = String(object.messageType);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (message.primitiveType != null && message.hasOwnProperty("primitiveType")) {
                object.primitiveType = options.enums === String ? google$1.api.expr.v1alpha1.Type.PrimitiveType[message.primitiveType] === undefined ? message.primitiveType : google$1.api.expr.v1alpha1.Type.PrimitiveType[message.primitiveType] : message.primitiveType;
                if (options.oneofs) {
                  object.type = "primitiveType";
                }
              }
              if (message.wellKnownType != null && message.hasOwnProperty("wellKnownType")) {
                object.wellKnownType = options.enums === String ? google$1.api.expr.v1alpha1.Type.WellKnownType[message.wellKnownType] === undefined ? message.wellKnownType : google$1.api.expr.v1alpha1.Type.WellKnownType[message.wellKnownType] : message.wellKnownType;
                if (options.oneofs) {
                  object.type = "wellKnownType";
                }
              }
              if (message.extendedType != null && message.hasOwnProperty("extendedType")) {
                object.extendedType = options.enums === String ? $root.workflow_parameters.v1.ExtendedType.Type[message.extendedType] === undefined ? message.extendedType : $root.workflow_parameters.v1.ExtendedType.Type[message.extendedType] : message.extendedType;
                if (options.oneofs) {
                  object.type = "extendedType";
                }
              }
              if (message.messageType != null && message.hasOwnProperty("messageType")) {
                object.messageType = message.messageType;
                if (options.oneofs) {
                  object.type = "messageType";
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType";
            }
          }

          ElemType.prototype.primitiveType = 0;
          ElemType.prototype.wellKnownType = 0;
          ElemType.prototype.extendedType = 0;
          ElemType.prototype.messageType = "";

          return ElemType;
        })();

        return ListType;
      })();

      return DynamicDestinationActionParameterType;
    })();

    v1.ObjectTypeIDContext = (() => {
      class ObjectTypeIDContext {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectTypeIDContext(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.childOf != null && Object.hasOwnProperty.call(message, "childOf")) {
            writer.uint32(10).string(message.childOf);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectTypeIDContext();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.childOf = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.ObjectTypeIDContext) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.ObjectTypeIDContext();
          if (object.childOf != null) {
            message.childOf = String(object.childOf);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.childOf != null && message.hasOwnProperty("childOf")) {
            object.childOf = message.childOf;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.ObjectTypeIDContext";
        }
      }

      ObjectTypeIDContext.prototype.childOf = null;
      ObjectTypeIDContext.prototype.name = "";

      return ObjectTypeIDContext;
    })();

    v1.StaticDestinationActionParameter = (() => {
      class StaticDestinationActionParameter {
        constructor(properties) {
          this.sensitivity = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StaticDestinationActionParameter(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.fieldName != null && Object.hasOwnProperty.call(message, "fieldName")) {
            writer.uint32(10).string(message.fieldName);
          }
          if (message.destinationFieldName != null && Object.hasOwnProperty.call(message, "destinationFieldName")) {
            writer.uint32(18).string(message.destinationFieldName);
          }
          if (message.fieldType != null && Object.hasOwnProperty.call(message, "fieldType")) {
            $root.workflow_parameters.v1.StaticDestinationActionParameterType.encode(message.fieldType, writer.uint32(26).fork()).ldelim();
          }
          if (message.sensitivity != null && Object.hasOwnProperty.call(message, "sensitivity")) {
            writer.uint32(34).fork();
            for (const element of message.sensitivity) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired")) {
            writer.uint32(40).bool(message.isRequired);
          }
          if (message.objectTypeIdContext != null && Object.hasOwnProperty.call(message, "objectTypeIdContext")) {
            $root.workflow_parameters.v1.ObjectTypeIDContext.encode(message.objectTypeIdContext, writer.uint32(50).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StaticDestinationActionParameter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldName = reader.string();
                break;
              }
              case 2: {
                message.destinationFieldName = reader.string();
                break;
              }
              case 3: {
                message.fieldType = $root.workflow_parameters.v1.StaticDestinationActionParameterType.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                if (!message.sensitivity || !message.sensitivity.length) {
                  message.sensitivity = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.sensitivity.push(reader.int32());
                } else {
                  message.sensitivity.push(reader.int32());
                }
                break;
              }
              case 5: {
                message.isRequired = reader.bool();
                break;
              }
              case 6: {
                message.objectTypeIdContext = $root.workflow_parameters.v1.ObjectTypeIDContext.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.StaticDestinationActionParameter) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.StaticDestinationActionParameter();
          if (object.fieldName != null) {
            message.fieldName = String(object.fieldName);
          }
          if (object.destinationFieldName != null) {
            message.destinationFieldName = String(object.destinationFieldName);
          }
          if (object.fieldType != null) {
            if (typeof object.fieldType !== "object") {
              throw new TypeError(".workflow_parameters.v1.StaticDestinationActionParameter.fieldType: object expected, but got " + (typeof object.fieldType));
            }
            message.fieldType = $root.workflow_parameters.v1.StaticDestinationActionParameterType.fromObject(object.fieldType);
          }
          if (object.sensitivity) {
            if (!Array.isArray(object.sensitivity)) {
              throw new TypeError(".workflow_parameters.v1.StaticDestinationActionParameter.sensitivity: array type expected, but got " + (typeof object.sensitivity))
            }
            message.sensitivity = new Array(object.sensitivity.length);
            for (let i = 0; i < object.sensitivity.length; ++i) {
              switch (object.sensitivity[i]) {
                case "UNSPECIFIED":
                case 0: {
                  message.sensitivity[i] = 0;
                  break;
                }
                case "PII":
                case 1: {
                  message.sensitivity[i] = 1;
                  break;
                }
                case "HI":
                case 2: {
                  message.sensitivity[i] = 2;
                  break;
                }
                case "ID":
                case 3: {
                  message.sensitivity[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.sensitivity[i] == "number") {
                    message.sensitivity[i] = object.sensitivity[i];
                    break;
                  }
                  message.sensitivity[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.isRequired != null) {
            message.isRequired = Boolean(object.isRequired);
          }
          if (object.objectTypeIdContext != null) {
            if (typeof object.objectTypeIdContext !== "object") {
              throw new TypeError(".workflow_parameters.v1.StaticDestinationActionParameter.objectTypeIdContext: object expected, but got " + (typeof object.objectTypeIdContext));
            }
            message.objectTypeIdContext = $root.workflow_parameters.v1.ObjectTypeIDContext.fromObject(object.objectTypeIdContext);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivity = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldName = "";
            object.fieldType = null;
            object.isRequired = false;
          }
          let keys;
          if (message.fieldName != null && message.hasOwnProperty("fieldName")) {
            object.fieldName = message.fieldName;
          }
          if (message.destinationFieldName != null && message.hasOwnProperty("destinationFieldName")) {
            object.destinationFieldName = message.destinationFieldName;
          }
          if (message.fieldType != null && message.hasOwnProperty("fieldType")) {
            object.fieldType = $root.workflow_parameters.v1.StaticDestinationActionParameterType.toObject(message.fieldType, options);
          }
          if (message.sensitivity && message.sensitivity.length) {
            object.sensitivity = new Array(message.sensitivity.length);
            for (let i = 0; i < message.sensitivity.length; ++i) {
              object.sensitivity[i] = options.enums === String ? sensitivity$1.v1.Sensitivity.Value[message.sensitivity[i]] === undefined ? message.sensitivity[i] : sensitivity$1.v1.Sensitivity.Value[message.sensitivity[i]] : message.sensitivity[i];
            }
          }
          if (message.isRequired != null && message.hasOwnProperty("isRequired")) {
            object.isRequired = message.isRequired;
          }
          if (message.objectTypeIdContext != null && message.hasOwnProperty("objectTypeIdContext")) {
            object.objectTypeIdContext = $root.workflow_parameters.v1.ObjectTypeIDContext.toObject(message.objectTypeIdContext, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.StaticDestinationActionParameter";
        }
      }

      StaticDestinationActionParameter.prototype.fieldName = "";
      StaticDestinationActionParameter.prototype.destinationFieldName = null;
      StaticDestinationActionParameter.prototype.fieldType = null;
      StaticDestinationActionParameter.prototype.sensitivity = $util.emptyArray;
      StaticDestinationActionParameter.prototype.isRequired = false;
      StaticDestinationActionParameter.prototype.objectTypeIdContext = null;

      return StaticDestinationActionParameter;
    })();

    v1.DynamicDestinationActionParameter = (() => {
      class DynamicDestinationActionParameter {
        constructor(properties) {
          this.sensitivity = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DynamicDestinationActionParameter(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.fieldName != null && Object.hasOwnProperty.call(message, "fieldName")) {
            writer.uint32(10).string(message.fieldName);
          }
          if (message.destinationFieldName != null && Object.hasOwnProperty.call(message, "destinationFieldName")) {
            writer.uint32(18).string(message.destinationFieldName);
          }
          if (message.elementFieldType != null && Object.hasOwnProperty.call(message, "elementFieldType")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterType.encode(message.elementFieldType, writer.uint32(26).fork()).ldelim();
          }
          if (message.sensitivity != null && Object.hasOwnProperty.call(message, "sensitivity")) {
            writer.uint32(34).fork();
            for (const element of message.sensitivity) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired")) {
            writer.uint32(40).bool(message.isRequired);
          }
          if (message.objectTypeIdContext != null && Object.hasOwnProperty.call(message, "objectTypeIdContext")) {
            $root.workflow_parameters.v1.ObjectTypeIDContext.encode(message.objectTypeIdContext, writer.uint32(50).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DynamicDestinationActionParameter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldName = reader.string();
                break;
              }
              case 2: {
                message.destinationFieldName = reader.string();
                break;
              }
              case 3: {
                message.elementFieldType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                if (!message.sensitivity || !message.sensitivity.length) {
                  message.sensitivity = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.sensitivity.push(reader.int32());
                } else {
                  message.sensitivity.push(reader.int32());
                }
                break;
              }
              case 5: {
                message.isRequired = reader.bool();
                break;
              }
              case 6: {
                message.objectTypeIdContext = $root.workflow_parameters.v1.ObjectTypeIDContext.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameter) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameter();
          if (object.fieldName != null) {
            message.fieldName = String(object.fieldName);
          }
          if (object.destinationFieldName != null) {
            message.destinationFieldName = String(object.destinationFieldName);
          }
          if (object.elementFieldType != null) {
            if (typeof object.elementFieldType !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameter.elementFieldType: object expected, but got " + (typeof object.elementFieldType));
            }
            message.elementFieldType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.fromObject(object.elementFieldType);
          }
          if (object.sensitivity) {
            if (!Array.isArray(object.sensitivity)) {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameter.sensitivity: array type expected, but got " + (typeof object.sensitivity))
            }
            message.sensitivity = new Array(object.sensitivity.length);
            for (let i = 0; i < object.sensitivity.length; ++i) {
              switch (object.sensitivity[i]) {
                case "UNSPECIFIED":
                case 0: {
                  message.sensitivity[i] = 0;
                  break;
                }
                case "PII":
                case 1: {
                  message.sensitivity[i] = 1;
                  break;
                }
                case "HI":
                case 2: {
                  message.sensitivity[i] = 2;
                  break;
                }
                case "ID":
                case 3: {
                  message.sensitivity[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.sensitivity[i] == "number") {
                    message.sensitivity[i] = object.sensitivity[i];
                    break;
                  }
                  message.sensitivity[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.isRequired != null) {
            message.isRequired = Boolean(object.isRequired);
          }
          if (object.objectTypeIdContext != null) {
            if (typeof object.objectTypeIdContext !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameter.objectTypeIdContext: object expected, but got " + (typeof object.objectTypeIdContext));
            }
            message.objectTypeIdContext = $root.workflow_parameters.v1.ObjectTypeIDContext.fromObject(object.objectTypeIdContext);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivity = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldName = "";
            object.elementFieldType = null;
            object.isRequired = false;
          }
          let keys;
          if (message.fieldName != null && message.hasOwnProperty("fieldName")) {
            object.fieldName = message.fieldName;
          }
          if (message.destinationFieldName != null && message.hasOwnProperty("destinationFieldName")) {
            object.destinationFieldName = message.destinationFieldName;
          }
          if (message.elementFieldType != null && message.hasOwnProperty("elementFieldType")) {
            object.elementFieldType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.toObject(message.elementFieldType, options);
          }
          if (message.sensitivity && message.sensitivity.length) {
            object.sensitivity = new Array(message.sensitivity.length);
            for (let i = 0; i < message.sensitivity.length; ++i) {
              object.sensitivity[i] = options.enums === String ? sensitivity$1.v1.Sensitivity.Value[message.sensitivity[i]] === undefined ? message.sensitivity[i] : sensitivity$1.v1.Sensitivity.Value[message.sensitivity[i]] : message.sensitivity[i];
            }
          }
          if (message.isRequired != null && message.hasOwnProperty("isRequired")) {
            object.isRequired = message.isRequired;
          }
          if (message.objectTypeIdContext != null && message.hasOwnProperty("objectTypeIdContext")) {
            object.objectTypeIdContext = $root.workflow_parameters.v1.ObjectTypeIDContext.toObject(message.objectTypeIdContext, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameter";
        }
      }

      DynamicDestinationActionParameter.prototype.fieldName = "";
      DynamicDestinationActionParameter.prototype.destinationFieldName = null;
      DynamicDestinationActionParameter.prototype.elementFieldType = null;
      DynamicDestinationActionParameter.prototype.sensitivity = $util.emptyArray;
      DynamicDestinationActionParameter.prototype.isRequired = false;
      DynamicDestinationActionParameter.prototype.objectTypeIdContext = null;

      return DynamicDestinationActionParameter;
    })();

    v1.DestinationActionParameter = (() => {
      class DestinationActionParameter {
        get type() {
          for (const key of ["static", "dynamic"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["static", "dynamic"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionParameter(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.static != null && Object.hasOwnProperty.call(message, "static")) {
            $root.workflow_parameters.v1.StaticDestinationActionParameter.encode(message.static, writer.uint32(10).fork()).ldelim();
          }
          if (message.dynamic != null && Object.hasOwnProperty.call(message, "dynamic")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameter.encode(message.dynamic, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionParameter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.static = $root.workflow_parameters.v1.StaticDestinationActionParameter.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.dynamic = $root.workflow_parameters.v1.DynamicDestinationActionParameter.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DestinationActionParameter) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DestinationActionParameter();
          if (object.static != null) {
            if (typeof object.static !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameter.static: object expected, but got " + (typeof object.static));
            }
            message.static = $root.workflow_parameters.v1.StaticDestinationActionParameter.fromObject(object.static);
          }
          if (object.dynamic != null) {
            if (typeof object.dynamic !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameter.dynamic: object expected, but got " + (typeof object.dynamic));
            }
            message.dynamic = $root.workflow_parameters.v1.DynamicDestinationActionParameter.fromObject(object.dynamic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.static != null && message.hasOwnProperty("static")) {
            object.static = $root.workflow_parameters.v1.StaticDestinationActionParameter.toObject(message.static, options);
            if (options.oneofs) {
              object.type = "static";
            }
          }
          if (message.dynamic != null && message.hasOwnProperty("dynamic")) {
            object.dynamic = $root.workflow_parameters.v1.DynamicDestinationActionParameter.toObject(message.dynamic, options);
            if (options.oneofs) {
              object.type = "dynamic";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DestinationActionParameter";
        }
      }

      DestinationActionParameter.prototype.static = null;
      DestinationActionParameter.prototype.dynamic = null;

      return DestinationActionParameter;
    })();

    v1.WorkflowEventPayloadFieldSchema = (() => {
      class WorkflowEventPayloadFieldSchema {
        get type() {
          for (const key of ["static", "dynamic"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["static", "dynamic"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.sensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEventPayloadFieldSchema(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.fieldIndex != null && Object.hasOwnProperty.call(message, "fieldIndex")) {
            writer.uint32(10).string(message.fieldIndex);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(18).string(message.description);
          }
          if (message.sensitivities != null && Object.hasOwnProperty.call(message, "sensitivities")) {
            writer.uint32(26).fork();
            for (const element of message.sensitivities) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          if (message.exampleValue != null && Object.hasOwnProperty.call(message, "exampleValue")) {
            google$2.protobuf.Value.encode(message.exampleValue, writer.uint32(34).fork()).ldelim();
          }
          if (message.isOptional != null && Object.hasOwnProperty.call(message, "isOptional")) {
            writer.uint32(40).bool(message.isOptional);
          }
          if (message.static != null && Object.hasOwnProperty.call(message, "static")) {
            $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static.encode(message.static, writer.uint32(50).fork()).ldelim();
          }
          if (message.dynamic != null && Object.hasOwnProperty.call(message, "dynamic")) {
            $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic.encode(message.dynamic, writer.uint32(58).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEventPayloadFieldSchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldIndex = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                if (!message.sensitivities || !message.sensitivities.length) {
                  message.sensitivities = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.sensitivities.push(reader.int32());
                } else {
                  message.sensitivities.push(reader.int32());
                }
                break;
              }
              case 4: {
                message.exampleValue = google$2.protobuf.Value.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.isOptional = reader.bool();
                break;
              }
              case 6: {
                message.static = $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.dynamic = $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema();
          if (object.fieldIndex != null) {
            message.fieldIndex = String(object.fieldIndex);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.sensitivities) {
            if (!Array.isArray(object.sensitivities)) {
              throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.sensitivities: array type expected, but got " + (typeof object.sensitivities))
            }
            message.sensitivities = new Array(object.sensitivities.length);
            for (let i = 0; i < object.sensitivities.length; ++i) {
              switch (object.sensitivities[i]) {
                case "UNSPECIFIED":
                case 0: {
                  message.sensitivities[i] = 0;
                  break;
                }
                case "PII":
                case 1: {
                  message.sensitivities[i] = 1;
                  break;
                }
                case "HI":
                case 2: {
                  message.sensitivities[i] = 2;
                  break;
                }
                case "ID":
                case 3: {
                  message.sensitivities[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.sensitivities[i] == "number") {
                    message.sensitivities[i] = object.sensitivities[i];
                    break;
                  }
                  message.sensitivities[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.exampleValue != null) {
            if (typeof object.exampleValue !== "object") {
              throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.exampleValue: object expected, but got " + (typeof object.exampleValue));
            }
            message.exampleValue = google$2.protobuf.Value.fromObject(object.exampleValue);
          }
          if (object.isOptional != null) {
            message.isOptional = Boolean(object.isOptional);
          }
          if (object.static != null) {
            if (typeof object.static !== "object") {
              throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.static: object expected, but got " + (typeof object.static));
            }
            message.static = $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static.fromObject(object.static);
          }
          if (object.dynamic != null) {
            if (typeof object.dynamic !== "object") {
              throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.dynamic: object expected, but got " + (typeof object.dynamic));
            }
            message.dynamic = $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic.fromObject(object.dynamic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldIndex = "";
            object.isOptional = false;
          }
          let keys;
          if (message.fieldIndex != null && message.hasOwnProperty("fieldIndex")) {
            object.fieldIndex = message.fieldIndex;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.sensitivities && message.sensitivities.length) {
            object.sensitivities = new Array(message.sensitivities.length);
            for (let i = 0; i < message.sensitivities.length; ++i) {
              object.sensitivities[i] = options.enums === String ? sensitivity$1.v1.Sensitivity.Value[message.sensitivities[i]] === undefined ? message.sensitivities[i] : sensitivity$1.v1.Sensitivity.Value[message.sensitivities[i]] : message.sensitivities[i];
            }
          }
          if (message.exampleValue != null && message.hasOwnProperty("exampleValue")) {
            object.exampleValue = google$2.protobuf.Value.toObject(message.exampleValue, options);
          }
          if (message.isOptional != null && message.hasOwnProperty("isOptional")) {
            object.isOptional = message.isOptional;
          }
          if (message.static != null && message.hasOwnProperty("static")) {
            object.static = $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static.toObject(message.static, options);
            if (options.oneofs) {
              object.type = "static";
            }
          }
          if (message.dynamic != null && message.hasOwnProperty("dynamic")) {
            object.dynamic = $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic.toObject(message.dynamic, options);
            if (options.oneofs) {
              object.type = "dynamic";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.WorkflowEventPayloadFieldSchema";
        }
      }

      WorkflowEventPayloadFieldSchema.prototype.fieldIndex = "";
      WorkflowEventPayloadFieldSchema.prototype.description = null;
      WorkflowEventPayloadFieldSchema.prototype.sensitivities = $util.emptyArray;
      WorkflowEventPayloadFieldSchema.prototype.exampleValue = null;
      WorkflowEventPayloadFieldSchema.prototype.isOptional = false;
      WorkflowEventPayloadFieldSchema.prototype.static = null;
      WorkflowEventPayloadFieldSchema.prototype.dynamic = null;
      WorkflowEventPayloadFieldSchema.Static = (() => {
        class Static {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Static(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.staticParameterConfig != null && Object.hasOwnProperty.call(message, "staticParameterConfig")) {
              $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.encode(message.staticParameterConfig, writer.uint32(10).fork()).ldelim();
            }
            if (message.staticParameterType != null && Object.hasOwnProperty.call(message, "staticParameterType")) {
              $root.workflow_parameters.v1.StaticDestinationActionParameterType.encode(message.staticParameterType, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Static();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.staticParameterConfig = $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.staticParameterType = $root.workflow_parameters.v1.StaticDestinationActionParameterType.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static();
            if (object.staticParameterConfig != null) {
              if (typeof object.staticParameterConfig !== "object") {
                throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static.staticParameterConfig: object expected, but got " + (typeof object.staticParameterConfig));
              }
              message.staticParameterConfig = $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.fromObject(object.staticParameterConfig);
            }
            if (object.staticParameterType != null) {
              if (typeof object.staticParameterType !== "object") {
                throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static.staticParameterType: object expected, but got " + (typeof object.staticParameterType));
              }
              message.staticParameterType = $root.workflow_parameters.v1.StaticDestinationActionParameterType.fromObject(object.staticParameterType);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.staticParameterConfig = null;
              object.staticParameterType = null;
            }
            let keys;
            if (message.staticParameterConfig != null && message.hasOwnProperty("staticParameterConfig")) {
              object.staticParameterConfig = $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.toObject(message.staticParameterConfig, options);
            }
            if (message.staticParameterType != null && message.hasOwnProperty("staticParameterType")) {
              object.staticParameterType = $root.workflow_parameters.v1.StaticDestinationActionParameterType.toObject(message.staticParameterType, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Static";
          }
        }

        Static.prototype.staticParameterConfig = null;
        Static.prototype.staticParameterType = null;

        return Static;
      })();

      WorkflowEventPayloadFieldSchema.Dynamic = (() => {
        class Dynamic {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Dynamic(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.dynamicParameterConfig != null && Object.hasOwnProperty.call(message, "dynamicParameterConfig")) {
              $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.encode(message.dynamicParameterConfig, writer.uint32(10).fork()).ldelim();
            }
            if (message.dynamicParameterType != null && Object.hasOwnProperty.call(message, "dynamicParameterType")) {
              $root.workflow_parameters.v1.DynamicDestinationActionParameterType.encode(message.dynamicParameterType, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Dynamic();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.dynamicParameterConfig = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.dynamicParameterType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic();
            if (object.dynamicParameterConfig != null) {
              if (typeof object.dynamicParameterConfig !== "object") {
                throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic.dynamicParameterConfig: object expected, but got " + (typeof object.dynamicParameterConfig));
              }
              message.dynamicParameterConfig = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.fromObject(object.dynamicParameterConfig);
            }
            if (object.dynamicParameterType != null) {
              if (typeof object.dynamicParameterType !== "object") {
                throw new TypeError(".workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic.dynamicParameterType: object expected, but got " + (typeof object.dynamicParameterType));
              }
              message.dynamicParameterType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.fromObject(object.dynamicParameterType);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.dynamicParameterConfig = null;
            }
            let keys;
            if (message.dynamicParameterConfig != null && message.hasOwnProperty("dynamicParameterConfig")) {
              object.dynamicParameterConfig = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.toObject(message.dynamicParameterConfig, options);
            }
            if (message.dynamicParameterType != null && message.hasOwnProperty("dynamicParameterType")) {
              object.dynamicParameterType = $root.workflow_parameters.v1.DynamicDestinationActionParameterType.toObject(message.dynamicParameterType, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.WorkflowEventPayloadFieldSchema.Dynamic";
          }
        }

        Dynamic.prototype.dynamicParameterConfig = null;
        Dynamic.prototype.dynamicParameterType = null;

        return Dynamic;
      })();

      return WorkflowEventPayloadFieldSchema;
    })();

    v1.ProfilePropertyLookup = (() => {
      class ProfilePropertyLookup {
        constructor(properties) {
          this.sensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfilePropertyLookup(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.propertyName != null && Object.hasOwnProperty.call(message, "propertyName")) {
            writer.uint32(10).string(message.propertyName);
          }
          if (message.indexHash != null && Object.hasOwnProperty.call(message, "indexHash")) {
            writer.uint32(16).int64(message.indexHash);
          }
          if (message.celExpressionStr != null && Object.hasOwnProperty.call(message, "celExpressionStr")) {
            writer.uint32(26).string(message.celExpressionStr);
          }
          if (message.isMorfDefault != null && Object.hasOwnProperty.call(message, "isMorfDefault")) {
            writer.uint32(32).bool(message.isMorfDefault);
          }
          if (message.sensitivities != null && Object.hasOwnProperty.call(message, "sensitivities")) {
            writer.uint32(42).fork();
            for (const element of message.sensitivities) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          if (message.valueType != null && Object.hasOwnProperty.call(message, "valueType")) {
            values$1.v1.ValueType.encode(message.valueType, writer.uint32(50).fork()).ldelim();
          }
          if (message.runtimeType != null && Object.hasOwnProperty.call(message, "runtimeType")) {
            google$1.api.expr.v1alpha1.Type.encode(message.runtimeType, writer.uint32(58).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfilePropertyLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.propertyName = reader.string();
                break;
              }
              case 2: {
                message.indexHash = reader.int64();
                break;
              }
              case 3: {
                message.celExpressionStr = reader.string();
                break;
              }
              case 4: {
                message.isMorfDefault = reader.bool();
                break;
              }
              case 5: {
                if (!message.sensitivities || !message.sensitivities.length) {
                  message.sensitivities = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.sensitivities.push(reader.int32());
                } else {
                  message.sensitivities.push(reader.int32());
                }
                break;
              }
              case 6: {
                message.valueType = values$1.v1.ValueType.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.runtimeType = google$1.api.expr.v1alpha1.Type.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.ProfilePropertyLookup) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.ProfilePropertyLookup();
          if (object.propertyName != null) {
            message.propertyName = String(object.propertyName);
          }
          if (object.indexHash != null) {
            if ($util.Long) {
              (message.indexHash = $util.Long.fromValue(object.indexHash)).unsigned = false;
            } else if (typeof object.indexHash === "string") {
              message.indexHash = parseInt(object.indexHash, 10);
            } else if (typeof object.indexHash === "number") {
              message.indexHash = object.indexHash;
            } else if (typeof object.indexHash === "object") {
              message.indexHash = new $util.LongBits(object.indexHash.low >>> 0, object.indexHash.high >>> 0).toNumber();
            }
          }
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          if (object.isMorfDefault != null) {
            message.isMorfDefault = Boolean(object.isMorfDefault);
          }
          if (object.sensitivities) {
            if (!Array.isArray(object.sensitivities)) {
              throw new TypeError(".workflow_parameters.v1.ProfilePropertyLookup.sensitivities: array type expected, but got " + (typeof object.sensitivities))
            }
            message.sensitivities = new Array(object.sensitivities.length);
            for (let i = 0; i < object.sensitivities.length; ++i) {
              switch (object.sensitivities[i]) {
                case "UNSPECIFIED":
                case 0: {
                  message.sensitivities[i] = 0;
                  break;
                }
                case "PII":
                case 1: {
                  message.sensitivities[i] = 1;
                  break;
                }
                case "HI":
                case 2: {
                  message.sensitivities[i] = 2;
                  break;
                }
                case "ID":
                case 3: {
                  message.sensitivities[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.sensitivities[i] == "number") {
                    message.sensitivities[i] = object.sensitivities[i];
                    break;
                  }
                  message.sensitivities[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.valueType != null) {
            if (typeof object.valueType !== "object") {
              throw new TypeError(".workflow_parameters.v1.ProfilePropertyLookup.valueType: object expected, but got " + (typeof object.valueType));
            }
            message.valueType = values$1.v1.ValueType.fromObject(object.valueType);
          }
          if (object.runtimeType != null) {
            if (typeof object.runtimeType !== "object") {
              throw new TypeError(".workflow_parameters.v1.ProfilePropertyLookup.runtimeType: object expected, but got " + (typeof object.runtimeType));
            }
            message.runtimeType = google$1.api.expr.v1alpha1.Type.fromObject(object.runtimeType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.propertyName = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.indexHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.indexHash = options.longs === String ? "0" : 0;
            }
            object.isMorfDefault = false;
            object.valueType = null;
            object.runtimeType = null;
          }
          let keys;
          if (message.propertyName != null && message.hasOwnProperty("propertyName")) {
            object.propertyName = message.propertyName;
          }
          if (message.indexHash != null && message.hasOwnProperty("indexHash")) {
            object.indexHash = typeof message.indexHash === "number" ? (options.longs === String ? String(message.indexHash) : message.indexHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.indexHash) : options.longs === Number ? new $util.LongBits(message.indexHash.low >>> 0, message.indexHash.high >>> 0).toNumber() : message.indexHash);
          }
          if (message.celExpressionStr != null && message.hasOwnProperty("celExpressionStr")) {
            object.celExpressionStr = message.celExpressionStr;
          }
          if (message.isMorfDefault != null && message.hasOwnProperty("isMorfDefault")) {
            object.isMorfDefault = message.isMorfDefault;
          }
          if (message.sensitivities && message.sensitivities.length) {
            object.sensitivities = new Array(message.sensitivities.length);
            for (let i = 0; i < message.sensitivities.length; ++i) {
              object.sensitivities[i] = options.enums === String ? sensitivity$1.v1.Sensitivity.Value[message.sensitivities[i]] === undefined ? message.sensitivities[i] : sensitivity$1.v1.Sensitivity.Value[message.sensitivities[i]] : message.sensitivities[i];
            }
          }
          if (message.valueType != null && message.hasOwnProperty("valueType")) {
            object.valueType = values$1.v1.ValueType.toObject(message.valueType, options);
          }
          if (message.runtimeType != null && message.hasOwnProperty("runtimeType")) {
            object.runtimeType = google$1.api.expr.v1alpha1.Type.toObject(message.runtimeType, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.ProfilePropertyLookup";
        }
      }

      ProfilePropertyLookup.prototype.propertyName = "";
      ProfilePropertyLookup.prototype.indexHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      ProfilePropertyLookup.prototype.celExpressionStr = null;
      ProfilePropertyLookup.prototype.isMorfDefault = false;
      ProfilePropertyLookup.prototype.sensitivities = $util.emptyArray;
      ProfilePropertyLookup.prototype.valueType = null;
      ProfilePropertyLookup.prototype.runtimeType = null;

      return ProfilePropertyLookup;
    })();

    v1.EventPayloadFieldLookup = (() => {
      class EventPayloadFieldLookup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventPayloadFieldLookup(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.celExpressionStr != null && Object.hasOwnProperty.call(message, "celExpressionStr")) {
            writer.uint32(18).string(message.celExpressionStr);
          }
          if (message.runtimeType != null && Object.hasOwnProperty.call(message, "runtimeType")) {
            google$1.api.expr.v1alpha1.Type.encode(message.runtimeType, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventPayloadFieldLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 2: {
                message.celExpressionStr = reader.string();
                break;
              }
              case 3: {
                message.runtimeType = google$1.api.expr.v1alpha1.Type.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.EventPayloadFieldLookup) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.EventPayloadFieldLookup();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          if (object.runtimeType != null) {
            if (typeof object.runtimeType !== "object") {
              throw new TypeError(".workflow_parameters.v1.EventPayloadFieldLookup.runtimeType: object expected, but got " + (typeof object.runtimeType));
            }
            message.runtimeType = google$1.api.expr.v1alpha1.Type.fromObject(object.runtimeType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = "";
            object.runtimeType = null;
          }
          let keys;
          if (message.celExpressionStr != null && message.hasOwnProperty("celExpressionStr")) {
            object.celExpressionStr = message.celExpressionStr;
          }
          if (message.runtimeType != null && message.hasOwnProperty("runtimeType")) {
            object.runtimeType = google$1.api.expr.v1alpha1.Type.toObject(message.runtimeType, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.EventPayloadFieldLookup";
        }
      }

      EventPayloadFieldLookup.prototype.celExpressionStr = "";
      EventPayloadFieldLookup.prototype.runtimeType = null;

      return EventPayloadFieldLookup;
    })();

    v1.CalculatedValue = (() => {
      class CalculatedValue {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CalculatedValue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.celExpressionStr != null && Object.hasOwnProperty.call(message, "celExpressionStr")) {
            writer.uint32(18).string(message.celExpressionStr);
          }
          if (message.runtimeType != null && Object.hasOwnProperty.call(message, "runtimeType")) {
            google$1.api.expr.v1alpha1.Type.encode(message.runtimeType, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CalculatedValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 2: {
                message.celExpressionStr = reader.string();
                break;
              }
              case 3: {
                message.runtimeType = google$1.api.expr.v1alpha1.Type.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.CalculatedValue) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.CalculatedValue();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          if (object.runtimeType != null) {
            if (typeof object.runtimeType !== "object") {
              throw new TypeError(".workflow_parameters.v1.CalculatedValue.runtimeType: object expected, but got " + (typeof object.runtimeType));
            }
            message.runtimeType = google$1.api.expr.v1alpha1.Type.fromObject(object.runtimeType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = "";
            object.runtimeType = null;
          }
          let keys;
          if (message.celExpressionStr != null && message.hasOwnProperty("celExpressionStr")) {
            object.celExpressionStr = message.celExpressionStr;
          }
          if (message.runtimeType != null && message.hasOwnProperty("runtimeType")) {
            object.runtimeType = google$1.api.expr.v1alpha1.Type.toObject(message.runtimeType, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.CalculatedValue";
        }
      }

      CalculatedValue.prototype.celExpressionStr = "";
      CalculatedValue.prototype.runtimeType = null;

      return CalculatedValue;
    })();

    v1.ObjectTypeID = (() => {
      class ObjectTypeID {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectTypeID(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.label != null && Object.hasOwnProperty.call(message, "label")) {
            writer.uint32(18).string(message.label);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(26).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectTypeID();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.ObjectTypeID) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.ObjectTypeID();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.label = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.label != null && message.hasOwnProperty("label")) {
            object.label = message.label;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.ObjectTypeID";
        }
      }

      ObjectTypeID.prototype.id = "";
      ObjectTypeID.prototype.label = "";
      ObjectTypeID.prototype.description = null;

      return ObjectTypeID;
    })();

    v1.DestinationActionParameterSource = (() => {
      class DestinationActionParameterSource {
        get type() {
          for (const key of ["profileIdentifier", "profilePropertyLookup", "eventPayloadFieldLookup", "calculatedValue", "constantValue", "elidedValue", "morfEventType", "objectTypeId", "morfEventTime"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["profileIdentifier", "profilePropertyLookup", "eventPayloadFieldLookup", "calculatedValue", "constantValue", "elidedValue", "morfEventType", "objectTypeId", "morfEventTime"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionParameterSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileIdentifier != null && Object.hasOwnProperty.call(message, "profileIdentifier")) {
            writer.uint32(8).int32(message.profileIdentifier);
          }
          if (message.profilePropertyLookup != null && Object.hasOwnProperty.call(message, "profilePropertyLookup")) {
            $root.workflow_parameters.v1.ProfilePropertyLookup.encode(message.profilePropertyLookup, writer.uint32(18).fork()).ldelim();
          }
          if (message.eventPayloadFieldLookup != null && Object.hasOwnProperty.call(message, "eventPayloadFieldLookup")) {
            $root.workflow_parameters.v1.EventPayloadFieldLookup.encode(message.eventPayloadFieldLookup, writer.uint32(26).fork()).ldelim();
          }
          if (message.calculatedValue != null && Object.hasOwnProperty.call(message, "calculatedValue")) {
            $root.workflow_parameters.v1.CalculatedValue.encode(message.calculatedValue, writer.uint32(34).fork()).ldelim();
          }
          if (message.constantValue != null && Object.hasOwnProperty.call(message, "constantValue")) {
            google$3.api.expr.v1alpha1.Value.encode(message.constantValue, writer.uint32(42).fork()).ldelim();
          }
          if (message.elidedValue != null && Object.hasOwnProperty.call(message, "elidedValue")) {
            google$4.protobuf.Empty.encode(message.elidedValue, writer.uint32(50).fork()).ldelim();
          }
          if (message.morfEventType != null && Object.hasOwnProperty.call(message, "morfEventType")) {
            google$4.protobuf.Empty.encode(message.morfEventType, writer.uint32(58).fork()).ldelim();
          }
          if (message.objectTypeId != null && Object.hasOwnProperty.call(message, "objectTypeId")) {
            $root.workflow_parameters.v1.ObjectTypeID.encode(message.objectTypeId, writer.uint32(66).fork()).ldelim();
          }
          if (message.morfEventTime != null && Object.hasOwnProperty.call(message, "morfEventTime")) {
            google$4.protobuf.Empty.encode(message.morfEventTime, writer.uint32(74).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionParameterSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileIdentifier = reader.int32();
                break;
              }
              case 2: {
                message.profilePropertyLookup = $root.workflow_parameters.v1.ProfilePropertyLookup.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.constantValue = google$3.api.expr.v1alpha1.Value.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.elidedValue = google$4.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.morfEventType = google$4.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.objectTypeId = $root.workflow_parameters.v1.ObjectTypeID.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.morfEventTime = google$4.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DestinationActionParameterSource) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DestinationActionParameterSource();
          if (object.profileIdentifier != null) {
            switch (object.profileIdentifier) {
              case "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION":
              case 0: {
                message.profileIdentifier = 0;
                break;
              }
              case "CUSTOMER":
              case 1: {
                message.profileIdentifier = 1;
                break;
              }
              case "FORMSORT":
              case 2: {
                message.profileIdentifier = 2;
                break;
              }
              case "HEALTHIE":
              case 3: {
                message.profileIdentifier = 3;
                break;
              }
              case "AXLE_HEALTH":
              case 4: {
                message.profileIdentifier = 4;
                break;
              }
              case "BUTTERFLY_LABS":
              case 5: {
                message.profileIdentifier = 5;
                break;
              }
              case "RECURLY":
              case 6: {
                message.profileIdentifier = 6;
                break;
              }
              case "INTERCOM":
              case 7: {
                message.profileIdentifier = 7;
                break;
              }
              case "SANA_BENEFITS":
              case 8: {
                message.profileIdentifier = 8;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 9: {
                message.profileIdentifier = 9;
                break;
              }
              case "VITAL":
              case 10: {
                message.profileIdentifier = 10;
                break;
              }
              case "SEGMENT":
              case 11: {
                message.profileIdentifier = 11;
                break;
              }
              case "INTAKEQ":
              case 12: {
                message.profileIdentifier = 12;
                break;
              }
              case "CUSTOMER_IO":
              case 13: {
                message.profileIdentifier = 13;
                break;
              }
              case "FRESHDESK":
              case 14: {
                message.profileIdentifier = 14;
                break;
              }
              case "HUBSPOT":
              case 15: {
                message.profileIdentifier = 15;
                break;
              }
              default: {
                if (typeof object.profileIdentifier == "number") {
                  message.profileIdentifier = object.profileIdentifier;
                  break;
                }
                break;
              }
            }
          }
          if (object.profilePropertyLookup != null) {
            if (typeof object.profilePropertyLookup !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.profilePropertyLookup: object expected, but got " + (typeof object.profilePropertyLookup));
            }
            message.profilePropertyLookup = $root.workflow_parameters.v1.ProfilePropertyLookup.fromObject(object.profilePropertyLookup);
          }
          if (object.eventPayloadFieldLookup != null) {
            if (typeof object.eventPayloadFieldLookup !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.eventPayloadFieldLookup: object expected, but got " + (typeof object.eventPayloadFieldLookup));
            }
            message.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.fromObject(object.eventPayloadFieldLookup);
          }
          if (object.calculatedValue != null) {
            if (typeof object.calculatedValue !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.calculatedValue: object expected, but got " + (typeof object.calculatedValue));
            }
            message.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.fromObject(object.calculatedValue);
          }
          if (object.constantValue != null) {
            if (typeof object.constantValue !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.constantValue: object expected, but got " + (typeof object.constantValue));
            }
            message.constantValue = google$3.api.expr.v1alpha1.Value.fromObject(object.constantValue);
          }
          if (object.elidedValue != null) {
            if (typeof object.elidedValue !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.elidedValue: object expected, but got " + (typeof object.elidedValue));
            }
            message.elidedValue = google$4.protobuf.Empty.fromObject(object.elidedValue);
          }
          if (object.morfEventType != null) {
            if (typeof object.morfEventType !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.morfEventType: object expected, but got " + (typeof object.morfEventType));
            }
            message.morfEventType = google$4.protobuf.Empty.fromObject(object.morfEventType);
          }
          if (object.objectTypeId != null) {
            if (typeof object.objectTypeId !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.objectTypeId: object expected, but got " + (typeof object.objectTypeId));
            }
            message.objectTypeId = $root.workflow_parameters.v1.ObjectTypeID.fromObject(object.objectTypeId);
          }
          if (object.morfEventTime != null) {
            if (typeof object.morfEventTime !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSource.morfEventTime: object expected, but got " + (typeof object.morfEventTime));
            }
            message.morfEventTime = google$4.protobuf.Empty.fromObject(object.morfEventTime);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.profileIdentifier != null && message.hasOwnProperty("profileIdentifier")) {
            object.profileIdentifier = options.enums === String ? thirdparties$1.v1.ThirdPartyIdType[message.profileIdentifier] === undefined ? message.profileIdentifier : thirdparties$1.v1.ThirdPartyIdType[message.profileIdentifier] : message.profileIdentifier;
            if (options.oneofs) {
              object.type = "profileIdentifier";
            }
          }
          if (message.profilePropertyLookup != null && message.hasOwnProperty("profilePropertyLookup")) {
            object.profilePropertyLookup = $root.workflow_parameters.v1.ProfilePropertyLookup.toObject(message.profilePropertyLookup, options);
            if (options.oneofs) {
              object.type = "profilePropertyLookup";
            }
          }
          if (message.eventPayloadFieldLookup != null && message.hasOwnProperty("eventPayloadFieldLookup")) {
            object.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.toObject(message.eventPayloadFieldLookup, options);
            if (options.oneofs) {
              object.type = "eventPayloadFieldLookup";
            }
          }
          if (message.calculatedValue != null && message.hasOwnProperty("calculatedValue")) {
            object.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.toObject(message.calculatedValue, options);
            if (options.oneofs) {
              object.type = "calculatedValue";
            }
          }
          if (message.constantValue != null && message.hasOwnProperty("constantValue")) {
            object.constantValue = google$3.api.expr.v1alpha1.Value.toObject(message.constantValue, options);
            if (options.oneofs) {
              object.type = "constantValue";
            }
          }
          if (message.elidedValue != null && message.hasOwnProperty("elidedValue")) {
            object.elidedValue = google$4.protobuf.Empty.toObject(message.elidedValue, options);
            if (options.oneofs) {
              object.type = "elidedValue";
            }
          }
          if (message.morfEventType != null && message.hasOwnProperty("morfEventType")) {
            object.morfEventType = google$4.protobuf.Empty.toObject(message.morfEventType, options);
            if (options.oneofs) {
              object.type = "morfEventType";
            }
          }
          if (message.objectTypeId != null && message.hasOwnProperty("objectTypeId")) {
            object.objectTypeId = $root.workflow_parameters.v1.ObjectTypeID.toObject(message.objectTypeId, options);
            if (options.oneofs) {
              object.type = "objectTypeId";
            }
          }
          if (message.morfEventTime != null && message.hasOwnProperty("morfEventTime")) {
            object.morfEventTime = google$4.protobuf.Empty.toObject(message.morfEventTime, options);
            if (options.oneofs) {
              object.type = "morfEventTime";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DestinationActionParameterSource";
        }
      }

      DestinationActionParameterSource.prototype.profileIdentifier = 0;
      DestinationActionParameterSource.prototype.profilePropertyLookup = null;
      DestinationActionParameterSource.prototype.eventPayloadFieldLookup = null;
      DestinationActionParameterSource.prototype.calculatedValue = null;
      DestinationActionParameterSource.prototype.constantValue = null;
      DestinationActionParameterSource.prototype.elidedValue = null;
      DestinationActionParameterSource.prototype.morfEventType = null;
      DestinationActionParameterSource.prototype.objectTypeId = null;
      DestinationActionParameterSource.prototype.morfEventTime = null;

      return DestinationActionParameterSource;
    })();

    v1.DestinationActionParameterSources = (() => {
      class DestinationActionParameterSources {
        constructor(properties) {
          this.sources = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionParameterSources(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.sources != null && Object.hasOwnProperty.call(message, "sources")) {
            for (const element of message.sources) {
              $root.workflow_parameters.v1.DestinationActionParameterSource.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionParameterSources();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.sources || !message.sources.length) {
                  message.sources = [];
                }
                message.sources.push($root.workflow_parameters.v1.DestinationActionParameterSource.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DestinationActionParameterSources) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DestinationActionParameterSources();
          if (object.sources) {
            if (!Array.isArray(object.sources)) {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSources.sources: array type expected, but got " + (typeof object.sources))
            }
            message.sources = new Array(object.sources.length);
            for (let i = 0; i < object.sources.length; ++i) {
              if (typeof object.sources[i] !== "object") {
                throw new TypeError(".workflow_parameters.v1.DestinationActionParameterSources.sources: object expected, but got " + (typeof object.sources[i]));
              }
              message.sources[i] = $root.workflow_parameters.v1.DestinationActionParameterSource.fromObject(object.sources[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sources = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.sources && message.sources.length) {
            object.sources = new Array(message.sources.length);
            for (let i = 0; i < message.sources.length; ++i) {
              object.sources[i] = $root.workflow_parameters.v1.DestinationActionParameterSource.toObject(message.sources[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DestinationActionParameterSources";
        }
      }

      DestinationActionParameterSources.prototype.sources = $util.emptyArray;

      return DestinationActionParameterSources;
    })();

    v1.StaticDestinationActionParameterConfig = (() => {
      class StaticDestinationActionParameterConfig {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StaticDestinationActionParameterConfig(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.parameter != null && Object.hasOwnProperty.call(message, "parameter")) {
            $root.workflow_parameters.v1.StaticDestinationActionParameter.encode(message.parameter, writer.uint32(10).fork()).ldelim();
          }
          if (message.source != null && Object.hasOwnProperty.call(message, "source")) {
            $root.workflow_parameters.v1.DestinationActionParameterSource.encode(message.source, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StaticDestinationActionParameterConfig();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.parameter = $root.workflow_parameters.v1.StaticDestinationActionParameter.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.source = $root.workflow_parameters.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.StaticDestinationActionParameterConfig) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.StaticDestinationActionParameterConfig();
          if (object.parameter != null) {
            if (typeof object.parameter !== "object") {
              throw new TypeError(".workflow_parameters.v1.StaticDestinationActionParameterConfig.parameter: object expected, but got " + (typeof object.parameter));
            }
            message.parameter = $root.workflow_parameters.v1.StaticDestinationActionParameter.fromObject(object.parameter);
          }
          if (object.source != null) {
            if (typeof object.source !== "object") {
              throw new TypeError(".workflow_parameters.v1.StaticDestinationActionParameterConfig.source: object expected, but got " + (typeof object.source));
            }
            message.source = $root.workflow_parameters.v1.DestinationActionParameterSource.fromObject(object.source);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.parameter = null;
            object.source = null;
          }
          let keys;
          if (message.parameter != null && message.hasOwnProperty("parameter")) {
            object.parameter = $root.workflow_parameters.v1.StaticDestinationActionParameter.toObject(message.parameter, options);
          }
          if (message.source != null && message.hasOwnProperty("source")) {
            object.source = $root.workflow_parameters.v1.DestinationActionParameterSource.toObject(message.source, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.StaticDestinationActionParameterConfig";
        }
      }

      StaticDestinationActionParameterConfig.prototype.parameter = null;
      StaticDestinationActionParameterConfig.prototype.source = null;

      return StaticDestinationActionParameterConfig;
    })();

    v1.MapKeyContext = (() => {
      class MapKeyContext {
        get type() {
          for (const key of ["objectTypeId"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["objectTypeId"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MapKeyContext(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.objectTypeId != null && Object.hasOwnProperty.call(message, "objectTypeId")) {
            $root.workflow_parameters.v1.ObjectTypeID.encode(message.objectTypeId, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MapKeyContext();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectTypeId = $root.workflow_parameters.v1.ObjectTypeID.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.MapKeyContext) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.MapKeyContext();
          if (object.objectTypeId != null) {
            if (typeof object.objectTypeId !== "object") {
              throw new TypeError(".workflow_parameters.v1.MapKeyContext.objectTypeId: object expected, but got " + (typeof object.objectTypeId));
            }
            message.objectTypeId = $root.workflow_parameters.v1.ObjectTypeID.fromObject(object.objectTypeId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.objectTypeId != null && message.hasOwnProperty("objectTypeId")) {
            object.objectTypeId = $root.workflow_parameters.v1.ObjectTypeID.toObject(message.objectTypeId, options);
            if (options.oneofs) {
              object.type = "objectTypeId";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.MapKeyContext";
        }
      }

      MapKeyContext.prototype.objectTypeId = null;

      return MapKeyContext;
    })();

    v1.DynamicDestinationActionParameterConfig = (() => {
      class DynamicDestinationActionParameterConfig {
        get source() {
          for (const key of ["mapSource", "listSource", "objectSource", "arraySource"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set source(name) {
          for (const key of ["mapSource", "listSource", "objectSource", "arraySource"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DynamicDestinationActionParameterConfig(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.parameter != null && Object.hasOwnProperty.call(message, "parameter")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameter.encode(message.parameter, writer.uint32(10).fork()).ldelim();
          }
          if (message.mapSource != null && Object.hasOwnProperty.call(message, "mapSource")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.encode(message.mapSource, writer.uint32(18).fork()).ldelim();
          }
          if (message.listSource != null && Object.hasOwnProperty.call(message, "listSource")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource.encode(message.listSource, writer.uint32(26).fork()).ldelim();
          }
          if (message.objectSource != null && Object.hasOwnProperty.call(message, "objectSource")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource.encode(message.objectSource, writer.uint32(34).fork()).ldelim();
          }
          if (message.arraySource != null && Object.hasOwnProperty.call(message, "arraySource")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.encode(message.arraySource, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DynamicDestinationActionParameterConfig();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.parameter = $root.workflow_parameters.v1.DynamicDestinationActionParameter.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mapSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.listSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.objectSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.arraySource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig();
          if (object.parameter != null) {
            if (typeof object.parameter !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.parameter: object expected, but got " + (typeof object.parameter));
            }
            message.parameter = $root.workflow_parameters.v1.DynamicDestinationActionParameter.fromObject(object.parameter);
          }
          if (object.mapSource != null) {
            if (typeof object.mapSource !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.mapSource: object expected, but got " + (typeof object.mapSource));
            }
            message.mapSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.fromObject(object.mapSource);
          }
          if (object.listSource != null) {
            if (typeof object.listSource !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.listSource: object expected, but got " + (typeof object.listSource));
            }
            message.listSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource.fromObject(object.listSource);
          }
          if (object.objectSource != null) {
            if (typeof object.objectSource !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.objectSource: object expected, but got " + (typeof object.objectSource));
            }
            message.objectSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource.fromObject(object.objectSource);
          }
          if (object.arraySource != null) {
            if (typeof object.arraySource !== "object") {
              throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.arraySource: object expected, but got " + (typeof object.arraySource));
            }
            message.arraySource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.fromObject(object.arraySource);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.parameter = null;
          }
          let keys;
          if (message.parameter != null && message.hasOwnProperty("parameter")) {
            object.parameter = $root.workflow_parameters.v1.DynamicDestinationActionParameter.toObject(message.parameter, options);
          }
          if (message.mapSource != null && message.hasOwnProperty("mapSource")) {
            object.mapSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.toObject(message.mapSource, options);
            if (options.oneofs) {
              object.source = "mapSource";
            }
          }
          if (message.listSource != null && message.hasOwnProperty("listSource")) {
            object.listSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource.toObject(message.listSource, options);
            if (options.oneofs) {
              object.source = "listSource";
            }
          }
          if (message.objectSource != null && message.hasOwnProperty("objectSource")) {
            object.objectSource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource.toObject(message.objectSource, options);
            if (options.oneofs) {
              object.source = "objectSource";
            }
          }
          if (message.arraySource != null && message.hasOwnProperty("arraySource")) {
            object.arraySource = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.toObject(message.arraySource, options);
            if (options.oneofs) {
              object.source = "arraySource";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterConfig";
        }
      }

      DynamicDestinationActionParameterConfig.prototype.parameter = null;
      DynamicDestinationActionParameterConfig.prototype.mapSource = null;
      DynamicDestinationActionParameterConfig.prototype.listSource = null;
      DynamicDestinationActionParameterConfig.prototype.objectSource = null;
      DynamicDestinationActionParameterConfig.prototype.arraySource = null;
      DynamicDestinationActionParameterConfig.MapSource = (() => {
        class MapSource {
          constructor(properties) {
            this.elementSources = {};
            this.keyContext = {};
            this.keySources = {};
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new MapSource(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.elementSources != null && Object.hasOwnProperty.call(message, "elementSources")) {
              for (const key of Object.keys(message.elementSources)) {
                writer.uint32(10).fork().uint32(10).string(key);
                $root.workflow_parameters.v1.DestinationActionParameterSource.encode(message.elementSources[key], writer.uint32(18).fork()).ldelim().ldelim();
              }
            }
            if (message.keyContext != null && Object.hasOwnProperty.call(message, "keyContext")) {
              for (const key of Object.keys(message.keyContext)) {
                writer.uint32(18).fork().uint32(10).string(key);
                $root.workflow_parameters.v1.MapKeyContext.encode(message.keyContext[key], writer.uint32(18).fork()).ldelim().ldelim();
              }
            }
            if (message.keySources != null && Object.hasOwnProperty.call(message, "keySources")) {
              for (const key of Object.keys(message.keySources)) {
                writer.uint32(26).fork().uint32(10).string(key);
                $root.workflow_parameters.v1.DestinationActionParameterSource.encode(message.keySources[key], writer.uint32(18).fork()).ldelim().ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new MapSource();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (message.elementSources === $util.emptyObject) message.elementSources = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = "";
                  value = null;
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.string();
                        break;
                      }
                      case 2: {
                        value = $root.workflow_parameters.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                        break;
                      }
                    }
                    message.elementSources[key] = value;
                  }
                  break;
                }
                case 2: {
                  if (message.keyContext === $util.emptyObject) message.keyContext = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = "";
                  value = null;
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.string();
                        break;
                      }
                      case 2: {
                        value = $root.workflow_parameters.v1.MapKeyContext.decode(reader, reader.uint32());
                        break;
                      }
                    }
                    message.keyContext[key] = value;
                  }
                  break;
                }
                case 3: {
                  if (message.keySources === $util.emptyObject) message.keySources = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = "";
                  value = null;
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.string();
                        break;
                      }
                      case 2: {
                        value = $root.workflow_parameters.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                        break;
                      }
                    }
                    message.keySources[key] = value;
                  }
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource();
            if (object.elementSources) {
              if (typeof object.elementSources !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.elementSources: object expected, but got " + (typeof object.elementSources));
              }
              message.elementSources = {};
              for (let keys = Object.keys(object.elementSources), i = 0; i < keys.length; ++i) {
                if (typeof object.elementSources[keys[i]] !== "object") {
                  throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.value: object expected, but got " + (typeof object.elementSources[keys[i]]));
                }
                message.elementSources[keys[i]] = $root.workflow_parameters.v1.DestinationActionParameterSource.fromObject(object.elementSources[keys[i]]);
              }
            }
            if (object.keyContext) {
              if (typeof object.keyContext !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.keyContext: object expected, but got " + (typeof object.keyContext));
              }
              message.keyContext = {};
              for (let keys = Object.keys(object.keyContext), i = 0; i < keys.length; ++i) {
                if (typeof object.keyContext[keys[i]] !== "object") {
                  throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.value: object expected, but got " + (typeof object.keyContext[keys[i]]));
                }
                message.keyContext[keys[i]] = $root.workflow_parameters.v1.MapKeyContext.fromObject(object.keyContext[keys[i]]);
              }
            }
            if (object.keySources) {
              if (typeof object.keySources !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.keySources: object expected, but got " + (typeof object.keySources));
              }
              message.keySources = {};
              for (let keys = Object.keys(object.keySources), i = 0; i < keys.length; ++i) {
                if (typeof object.keySources[keys[i]] !== "object") {
                  throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource.value: object expected, but got " + (typeof object.keySources[keys[i]]));
                }
                message.keySources[keys[i]] = $root.workflow_parameters.v1.DestinationActionParameterSource.fromObject(object.keySources[keys[i]]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
              object.elementSources = {};
              object.keyContext = {};
              object.keySources = {};
            }
            if (options.defaults) {
            }
            let keys;
            if (message.elementSources && (keys = Object.keys(message.elementSources)).length) {
              object.elementSources = {};
              for (let i = 0; i < keys.length; ++i) {
                object.elementSources[keys[i]] = $root.workflow_parameters.v1.DestinationActionParameterSource.toObject(message.elementSources[keys[i]], options);
              }
            }
            if (message.keyContext && (keys = Object.keys(message.keyContext)).length) {
              object.keyContext = {};
              for (let i = 0; i < keys.length; ++i) {
                object.keyContext[keys[i]] = $root.workflow_parameters.v1.MapKeyContext.toObject(message.keyContext[keys[i]], options);
              }
            }
            if (message.keySources && (keys = Object.keys(message.keySources)).length) {
              object.keySources = {};
              for (let i = 0; i < keys.length; ++i) {
                object.keySources[keys[i]] = $root.workflow_parameters.v1.DestinationActionParameterSource.toObject(message.keySources[keys[i]], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource";
          }
        }

        MapSource.prototype.elementSources = $util.emptyObject;
        MapSource.prototype.keyContext = $util.emptyObject;
        MapSource.prototype.keySources = $util.emptyObject;

        return MapSource;
      })();

      DynamicDestinationActionParameterConfig.ListSource = (() => {
        class ListSource {
          constructor(properties) {
            this.sources = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListSource(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.sources != null && Object.hasOwnProperty.call(message, "sources")) {
              for (const element of message.sources) {
                $root.workflow_parameters.v1.DestinationActionParameterSource.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListSource();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.sources || !message.sources.length) {
                    message.sources = [];
                  }
                  message.sources.push($root.workflow_parameters.v1.DestinationActionParameterSource.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource();
            if (object.sources) {
              if (!Array.isArray(object.sources)) {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource.sources: array type expected, but got " + (typeof object.sources))
              }
              message.sources = new Array(object.sources.length);
              for (let i = 0; i < object.sources.length; ++i) {
                if (typeof object.sources[i] !== "object") {
                  throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource.sources: object expected, but got " + (typeof object.sources[i]));
                }
                message.sources[i] = $root.workflow_parameters.v1.DestinationActionParameterSource.fromObject(object.sources[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.sources = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.sources && message.sources.length) {
              object.sources = new Array(message.sources.length);
              for (let i = 0; i < message.sources.length; ++i) {
                object.sources[i] = $root.workflow_parameters.v1.DestinationActionParameterSource.toObject(message.sources[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource";
          }
        }

        ListSource.prototype.sources = $util.emptyArray;

        return ListSource;
      })();

      DynamicDestinationActionParameterConfig.ObjectSource = (() => {
        class ObjectSource {
          get type() {
            for (const key of ["eventPayloadFieldLookup", "calculatedValue"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["eventPayloadFieldLookup", "calculatedValue"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ObjectSource(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.eventPayloadFieldLookup != null && Object.hasOwnProperty.call(message, "eventPayloadFieldLookup")) {
              $root.workflow_parameters.v1.EventPayloadFieldLookup.encode(message.eventPayloadFieldLookup, writer.uint32(10).fork()).ldelim();
            }
            if (message.calculatedValue != null && Object.hasOwnProperty.call(message, "calculatedValue")) {
              $root.workflow_parameters.v1.CalculatedValue.encode(message.calculatedValue, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ObjectSource();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource();
            if (object.eventPayloadFieldLookup != null) {
              if (typeof object.eventPayloadFieldLookup !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource.eventPayloadFieldLookup: object expected, but got " + (typeof object.eventPayloadFieldLookup));
              }
              message.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.fromObject(object.eventPayloadFieldLookup);
            }
            if (object.calculatedValue != null) {
              if (typeof object.calculatedValue !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource.calculatedValue: object expected, but got " + (typeof object.calculatedValue));
              }
              message.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.fromObject(object.calculatedValue);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.eventPayloadFieldLookup != null && message.hasOwnProperty("eventPayloadFieldLookup")) {
              object.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.toObject(message.eventPayloadFieldLookup, options);
              if (options.oneofs) {
                object.type = "eventPayloadFieldLookup";
              }
            }
            if (message.calculatedValue != null && message.hasOwnProperty("calculatedValue")) {
              object.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.toObject(message.calculatedValue, options);
              if (options.oneofs) {
                object.type = "calculatedValue";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource";
          }
        }

        ObjectSource.prototype.eventPayloadFieldLookup = null;
        ObjectSource.prototype.calculatedValue = null;

        return ObjectSource;
      })();

      DynamicDestinationActionParameterConfig.ArraySource = (() => {
        class ArraySource {
          get type() {
            for (const key of ["eventPayloadFieldLookup", "calculatedValue", "constantValue"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["eventPayloadFieldLookup", "calculatedValue", "constantValue"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ArraySource(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.eventPayloadFieldLookup != null && Object.hasOwnProperty.call(message, "eventPayloadFieldLookup")) {
              $root.workflow_parameters.v1.EventPayloadFieldLookup.encode(message.eventPayloadFieldLookup, writer.uint32(10).fork()).ldelim();
            }
            if (message.calculatedValue != null && Object.hasOwnProperty.call(message, "calculatedValue")) {
              $root.workflow_parameters.v1.CalculatedValue.encode(message.calculatedValue, writer.uint32(18).fork()).ldelim();
            }
            if (message.constantValue != null && Object.hasOwnProperty.call(message, "constantValue")) {
              google$3.api.expr.v1alpha1.Value.encode(message.constantValue, writer.uint32(26).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ArraySource();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.constantValue = google$3.api.expr.v1alpha1.Value.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource) {
              return object;
            }
            const message = new $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource();
            if (object.eventPayloadFieldLookup != null) {
              if (typeof object.eventPayloadFieldLookup !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.eventPayloadFieldLookup: object expected, but got " + (typeof object.eventPayloadFieldLookup));
              }
              message.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.fromObject(object.eventPayloadFieldLookup);
            }
            if (object.calculatedValue != null) {
              if (typeof object.calculatedValue !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.calculatedValue: object expected, but got " + (typeof object.calculatedValue));
              }
              message.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.fromObject(object.calculatedValue);
            }
            if (object.constantValue != null) {
              if (typeof object.constantValue !== "object") {
                throw new TypeError(".workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource.constantValue: object expected, but got " + (typeof object.constantValue));
              }
              message.constantValue = google$3.api.expr.v1alpha1.Value.fromObject(object.constantValue);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.eventPayloadFieldLookup != null && message.hasOwnProperty("eventPayloadFieldLookup")) {
              object.eventPayloadFieldLookup = $root.workflow_parameters.v1.EventPayloadFieldLookup.toObject(message.eventPayloadFieldLookup, options);
              if (options.oneofs) {
                object.type = "eventPayloadFieldLookup";
              }
            }
            if (message.calculatedValue != null && message.hasOwnProperty("calculatedValue")) {
              object.calculatedValue = $root.workflow_parameters.v1.CalculatedValue.toObject(message.calculatedValue, options);
              if (options.oneofs) {
                object.type = "calculatedValue";
              }
            }
            if (message.constantValue != null && message.hasOwnProperty("constantValue")) {
              object.constantValue = google$3.api.expr.v1alpha1.Value.toObject(message.constantValue, options);
              if (options.oneofs) {
                object.type = "constantValue";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource";
          }
        }

        ArraySource.prototype.eventPayloadFieldLookup = null;
        ArraySource.prototype.calculatedValue = null;
        ArraySource.prototype.constantValue = null;

        return ArraySource;
      })();

      return DynamicDestinationActionParameterConfig;
    })();

    v1.DestinationActionParameterConfig = (() => {
      class DestinationActionParameterConfig {
        get type() {
          for (const key of ["staticConfig", "dynamicConfig"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["staticConfig", "dynamicConfig"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionParameterConfig(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.staticConfig != null && Object.hasOwnProperty.call(message, "staticConfig")) {
            $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.encode(message.staticConfig, writer.uint32(10).fork()).ldelim();
          }
          if (message.dynamicConfig != null && Object.hasOwnProperty.call(message, "dynamicConfig")) {
            $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.encode(message.dynamicConfig, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionParameterConfig();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.staticConfig = $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.dynamicConfig = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DestinationActionParameterConfig) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DestinationActionParameterConfig();
          if (object.staticConfig != null) {
            if (typeof object.staticConfig !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterConfig.staticConfig: object expected, but got " + (typeof object.staticConfig));
            }
            message.staticConfig = $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.fromObject(object.staticConfig);
          }
          if (object.dynamicConfig != null) {
            if (typeof object.dynamicConfig !== "object") {
              throw new TypeError(".workflow_parameters.v1.DestinationActionParameterConfig.dynamicConfig: object expected, but got " + (typeof object.dynamicConfig));
            }
            message.dynamicConfig = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.fromObject(object.dynamicConfig);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.staticConfig != null && message.hasOwnProperty("staticConfig")) {
            object.staticConfig = $root.workflow_parameters.v1.StaticDestinationActionParameterConfig.toObject(message.staticConfig, options);
            if (options.oneofs) {
              object.type = "staticConfig";
            }
          }
          if (message.dynamicConfig != null && message.hasOwnProperty("dynamicConfig")) {
            object.dynamicConfig = $root.workflow_parameters.v1.DynamicDestinationActionParameterConfig.toObject(message.dynamicConfig, options);
            if (options.oneofs) {
              object.type = "dynamicConfig";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DestinationActionParameterConfig";
        }
      }

      DestinationActionParameterConfig.prototype.staticConfig = null;
      DestinationActionParameterConfig.prototype.dynamicConfig = null;

      return DestinationActionParameterConfig;
    })();

    v1.ParameterMissingPolicy = (() => {
      class ParameterMissingPolicy {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ParameterMissingPolicy(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.requiredParameterMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredParameterMissingBehavior")) {
            writer.uint32(8).int32(message.requiredParameterMissingBehavior);
          }
          if (message.optionalParameterMissingBehavior != null && Object.hasOwnProperty.call(message, "optionalParameterMissingBehavior")) {
            writer.uint32(16).int32(message.optionalParameterMissingBehavior);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ParameterMissingPolicy();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.requiredParameterMissingBehavior = reader.int32();
                break;
              }
              case 2: {
                message.optionalParameterMissingBehavior = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.ParameterMissingPolicy) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.ParameterMissingPolicy();
          if (object.requiredParameterMissingBehavior != null) {
            switch (object.requiredParameterMissingBehavior) {
              case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
              case 0: {
                message.requiredParameterMissingBehavior = 0;
                break;
              }
              case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
              case 1: {
                message.requiredParameterMissingBehavior = 1;
                break;
              }
              case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
              case 2: {
                message.requiredParameterMissingBehavior = 2;
                break;
              }
              case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
              case 3: {
                message.requiredParameterMissingBehavior = 3;
                break;
              }
              case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
              case 4: {
                message.requiredParameterMissingBehavior = 4;
                break;
              }
              default: {
                if (typeof object.requiredParameterMissingBehavior == "number") {
                  message.requiredParameterMissingBehavior = object.requiredParameterMissingBehavior;
                  break;
                }
                break;
              }
            }
          }
          if (object.optionalParameterMissingBehavior != null) {
            switch (object.optionalParameterMissingBehavior) {
              case "UNDEFINED_OPTIONAL_PARAMETER_MISSING_BEHAVIOR":
              case 0: {
                message.optionalParameterMissingBehavior = 0;
                break;
              }
              case "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
              case 1: {
                message.optionalParameterMissingBehavior = 1;
                break;
              }
              case "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
              case 2: {
                message.optionalParameterMissingBehavior = 2;
                break;
              }
              case "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
              case 3: {
                message.optionalParameterMissingBehavior = 3;
                break;
              }
              case "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
              case 4: {
                message.optionalParameterMissingBehavior = 4;
                break;
              }
              case "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_USE_NULL":
              case 5: {
                message.optionalParameterMissingBehavior = 5;
                break;
              }
              default: {
                if (typeof object.optionalParameterMissingBehavior == "number") {
                  message.optionalParameterMissingBehavior = object.optionalParameterMissingBehavior;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.requiredParameterMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
            object.optionalParameterMissingBehavior = options.enums === String ? "UNDEFINED_OPTIONAL_PARAMETER_MISSING_BEHAVIOR" : 0
          }
          let keys;
          if (message.requiredParameterMissingBehavior != null && message.hasOwnProperty("requiredParameterMissingBehavior")) {
            object.requiredParameterMissingBehavior = options.enums === String ? $root.workflow_parameters.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredParameterMissingBehavior] === undefined ? message.requiredParameterMissingBehavior : $root.workflow_parameters.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredParameterMissingBehavior] : message.requiredParameterMissingBehavior;
          }
          if (message.optionalParameterMissingBehavior != null && message.hasOwnProperty("optionalParameterMissingBehavior")) {
            object.optionalParameterMissingBehavior = options.enums === String ? $root.workflow_parameters.v1.ParameterMissingPolicy.OptionalParameterMissingBehavior[message.optionalParameterMissingBehavior] === undefined ? message.optionalParameterMissingBehavior : $root.workflow_parameters.v1.ParameterMissingPolicy.OptionalParameterMissingBehavior[message.optionalParameterMissingBehavior] : message.optionalParameterMissingBehavior;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.ParameterMissingPolicy";
        }
      }

      ParameterMissingPolicy.prototype.requiredParameterMissingBehavior = 0;
      ParameterMissingPolicy.prototype.optionalParameterMissingBehavior = 0;
      ParameterMissingPolicy.RequiredParameterMissingBehavior = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR"] = 0;
        values[valuesById[1] = "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW"] = 1;
        values[valuesById[2] = "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH"] = 2;
        values[valuesById[3] = "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE"] = 3;
        values[valuesById[4] = "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE"] = 4;
        return values;
      })();

      ParameterMissingPolicy.OptionalParameterMissingBehavior = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_OPTIONAL_PARAMETER_MISSING_BEHAVIOR"] = 0;
        values[valuesById[1] = "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW"] = 1;
        values[valuesById[2] = "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH"] = 2;
        values[valuesById[3] = "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE"] = 3;
        values[valuesById[4] = "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE"] = 4;
        values[valuesById[5] = "OPTIONAL_PARAMETER_MISSING_BEHAVIOR_USE_NULL"] = 5;
        return values;
      })();

      return ParameterMissingPolicy;
    })();

    v1.DestinationActionRetryPolicy = (() => {
      class DestinationActionRetryPolicy {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionRetryPolicy(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionRetryPolicy();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_parameters.v1.DestinationActionRetryPolicy) {
            return object;
          }
          const message = new $root.workflow_parameters.v1.DestinationActionRetryPolicy();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_parameters.v1.DestinationActionRetryPolicy";
        }
      }

      return DestinationActionRetryPolicy;
    })();

    return v1;
  })();

  return workflow_parameters;
})();

export const workflow_parameters = $root.workflow_parameters;
