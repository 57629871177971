"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { event_payload as event_payload$1 } from "../../event_payload_v2_ts_proto";
import { profiles as profiles$1 } from "../../profiles_v1_ts_proto";
import { source_application as source_application$1 } from "../../source_application_v2_ts_proto";
import { thirdparties as thirdparties$1 } from "../../thirdparties_v1_ts_proto";
import { timing as timing$1 } from "../../timing_v1_ts_proto";
import { values as values$1 } from "../../values_v1_ts_proto";
import { workflow_evaluation_results as workflow_evaluation_results$1 } from "../../workflow_evaluation_results_v1_ts_proto";
import { workflow_extra_fetched_data as workflow_extra_fetched_data$1 } from "../../workflow_extra_fetched_data_v1_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../workflow_parameters_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.destinationactions = (() => {
  const destinationactions = {};
  destinationactions.morf = (() => {
    const morf = {};
    morf.v1 = (() => {
      const v1 = {};
      v1.DestinationAction = (() => {
        class DestinationAction {
          get type() {
            for (const key of ["convertProfileToPatient", "updateProfileProperties", "trackProfileProperties"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["convertProfileToPatient", "updateProfileProperties", "trackProfileProperties"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new DestinationAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.convertProfileToPatient != null && Object.hasOwnProperty.call(message, "convertProfileToPatient")) {
              $root.destinationactions.morf.v1.ConvertProfileToPatient.encode(message.convertProfileToPatient, writer.uint32(10).fork()).ldelim();
            }
            if (message.updateProfileProperties != null && Object.hasOwnProperty.call(message, "updateProfileProperties")) {
              $root.destinationactions.morf.v1.UpdateProfileProperties.encode(message.updateProfileProperties, writer.uint32(18).fork()).ldelim();
            }
            if (message.trackProfileProperties != null && Object.hasOwnProperty.call(message, "trackProfileProperties")) {
              $root.destinationactions.morf.v1.TrackProfileProperties.encode(message.trackProfileProperties, writer.uint32(26).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new DestinationAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.convertProfileToPatient = $root.destinationactions.morf.v1.ConvertProfileToPatient.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.updateProfileProperties = $root.destinationactions.morf.v1.UpdateProfileProperties.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.trackProfileProperties = $root.destinationactions.morf.v1.TrackProfileProperties.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.DestinationAction) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.DestinationAction();
            if (object.convertProfileToPatient != null) {
              if (typeof object.convertProfileToPatient !== "object") {
                throw new TypeError(".destinationactions.morf.v1.DestinationAction.convertProfileToPatient: object expected, but got " + (typeof object.convertProfileToPatient));
              }
              message.convertProfileToPatient = $root.destinationactions.morf.v1.ConvertProfileToPatient.fromObject(object.convertProfileToPatient);
            }
            if (object.updateProfileProperties != null) {
              if (typeof object.updateProfileProperties !== "object") {
                throw new TypeError(".destinationactions.morf.v1.DestinationAction.updateProfileProperties: object expected, but got " + (typeof object.updateProfileProperties));
              }
              message.updateProfileProperties = $root.destinationactions.morf.v1.UpdateProfileProperties.fromObject(object.updateProfileProperties);
            }
            if (object.trackProfileProperties != null) {
              if (typeof object.trackProfileProperties !== "object") {
                throw new TypeError(".destinationactions.morf.v1.DestinationAction.trackProfileProperties: object expected, but got " + (typeof object.trackProfileProperties));
              }
              message.trackProfileProperties = $root.destinationactions.morf.v1.TrackProfileProperties.fromObject(object.trackProfileProperties);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.convertProfileToPatient != null && message.hasOwnProperty("convertProfileToPatient")) {
              object.convertProfileToPatient = $root.destinationactions.morf.v1.ConvertProfileToPatient.toObject(message.convertProfileToPatient, options);
              if (options.oneofs) {
                object.type = "convertProfileToPatient";
              }
            }
            if (message.updateProfileProperties != null && message.hasOwnProperty("updateProfileProperties")) {
              object.updateProfileProperties = $root.destinationactions.morf.v1.UpdateProfileProperties.toObject(message.updateProfileProperties, options);
              if (options.oneofs) {
                object.type = "updateProfileProperties";
              }
            }
            if (message.trackProfileProperties != null && message.hasOwnProperty("trackProfileProperties")) {
              object.trackProfileProperties = $root.destinationactions.morf.v1.TrackProfileProperties.toObject(message.trackProfileProperties, options);
              if (options.oneofs) {
                object.type = "trackProfileProperties";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.DestinationAction";
          }
        }

        DestinationAction.prototype.convertProfileToPatient = null;
        DestinationAction.prototype.updateProfileProperties = null;
        DestinationAction.prototype.trackProfileProperties = null;

        return DestinationAction;
      })();

      v1.ConvertProfileToPatient = (() => {
        class ConvertProfileToPatient {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ConvertProfileToPatient(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.mergeIfEmailAddressAlreadyExists != null && Object.hasOwnProperty.call(message, "mergeIfEmailAddressAlreadyExists")) {
              writer.uint32(8).bool(message.mergeIfEmailAddressAlreadyExists);
            }
            if (message.mergeIfPhoneNumberAlreadyExists != null && Object.hasOwnProperty.call(message, "mergeIfPhoneNumberAlreadyExists")) {
              writer.uint32(16).bool(message.mergeIfPhoneNumberAlreadyExists);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ConvertProfileToPatient();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.mergeIfEmailAddressAlreadyExists = reader.bool();
                  break;
                }
                case 2: {
                  message.mergeIfPhoneNumberAlreadyExists = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.ConvertProfileToPatient) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.ConvertProfileToPatient();
            if (object.mergeIfEmailAddressAlreadyExists != null) {
              message.mergeIfEmailAddressAlreadyExists = Boolean(object.mergeIfEmailAddressAlreadyExists);
            }
            if (object.mergeIfPhoneNumberAlreadyExists != null) {
              message.mergeIfPhoneNumberAlreadyExists = Boolean(object.mergeIfPhoneNumberAlreadyExists);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.mergeIfEmailAddressAlreadyExists = false;
              object.mergeIfPhoneNumberAlreadyExists = false;
            }
            let keys;
            if (message.mergeIfEmailAddressAlreadyExists != null && message.hasOwnProperty("mergeIfEmailAddressAlreadyExists")) {
              object.mergeIfEmailAddressAlreadyExists = message.mergeIfEmailAddressAlreadyExists;
            }
            if (message.mergeIfPhoneNumberAlreadyExists != null && message.hasOwnProperty("mergeIfPhoneNumberAlreadyExists")) {
              object.mergeIfPhoneNumberAlreadyExists = message.mergeIfPhoneNumberAlreadyExists;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.ConvertProfileToPatient";
          }
        }

        ConvertProfileToPatient.prototype.mergeIfEmailAddressAlreadyExists = false;
        ConvertProfileToPatient.prototype.mergeIfPhoneNumberAlreadyExists = false;

        return ConvertProfileToPatient;
      })();

      v1.ConvertProfileToPatientReturn = (() => {
        class ConvertProfileToPatientReturn {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ConvertProfileToPatientReturn(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.profileAlreadyPatientNoOp != null && Object.hasOwnProperty.call(message, "profileAlreadyPatientNoOp")) {
              writer.uint32(8).bool(message.profileAlreadyPatientNoOp);
            }
            if (message.existingConflictingEmailPatientProfile != null && Object.hasOwnProperty.call(message, "existingConflictingEmailPatientProfile")) {
              profiles$1.v1.PatientProfile.encode(message.existingConflictingEmailPatientProfile, writer.uint32(18).fork()).ldelim();
            }
            if (message.existingConflictingPhoneNumberPatientProfile != null && Object.hasOwnProperty.call(message, "existingConflictingPhoneNumberPatientProfile")) {
              profiles$1.v1.PatientProfile.encode(message.existingConflictingPhoneNumberPatientProfile, writer.uint32(26).fork()).ldelim();
            }
            if (message.convertedProfile != null && Object.hasOwnProperty.call(message, "convertedProfile")) {
              profiles$1.v1.PatientProfile.encode(message.convertedProfile, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ConvertProfileToPatientReturn();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.profileAlreadyPatientNoOp = reader.bool();
                  break;
                }
                case 2: {
                  message.existingConflictingEmailPatientProfile = profiles$1.v1.PatientProfile.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.existingConflictingPhoneNumberPatientProfile = profiles$1.v1.PatientProfile.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  message.convertedProfile = profiles$1.v1.PatientProfile.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.ConvertProfileToPatientReturn) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.ConvertProfileToPatientReturn();
            if (object.profileAlreadyPatientNoOp != null) {
              message.profileAlreadyPatientNoOp = Boolean(object.profileAlreadyPatientNoOp);
            }
            if (object.existingConflictingEmailPatientProfile != null) {
              if (typeof object.existingConflictingEmailPatientProfile !== "object") {
                throw new TypeError(".destinationactions.morf.v1.ConvertProfileToPatientReturn.existingConflictingEmailPatientProfile: object expected, but got " + (typeof object.existingConflictingEmailPatientProfile));
              }
              message.existingConflictingEmailPatientProfile = profiles$1.v1.PatientProfile.fromObject(object.existingConflictingEmailPatientProfile);
            }
            if (object.existingConflictingPhoneNumberPatientProfile != null) {
              if (typeof object.existingConflictingPhoneNumberPatientProfile !== "object") {
                throw new TypeError(".destinationactions.morf.v1.ConvertProfileToPatientReturn.existingConflictingPhoneNumberPatientProfile: object expected, but got " + (typeof object.existingConflictingPhoneNumberPatientProfile));
              }
              message.existingConflictingPhoneNumberPatientProfile = profiles$1.v1.PatientProfile.fromObject(object.existingConflictingPhoneNumberPatientProfile);
            }
            if (object.convertedProfile != null) {
              if (typeof object.convertedProfile !== "object") {
                throw new TypeError(".destinationactions.morf.v1.ConvertProfileToPatientReturn.convertedProfile: object expected, but got " + (typeof object.convertedProfile));
              }
              message.convertedProfile = profiles$1.v1.PatientProfile.fromObject(object.convertedProfile);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.profileAlreadyPatientNoOp = false;
            }
            let keys;
            if (message.profileAlreadyPatientNoOp != null && message.hasOwnProperty("profileAlreadyPatientNoOp")) {
              object.profileAlreadyPatientNoOp = message.profileAlreadyPatientNoOp;
            }
            if (message.existingConflictingEmailPatientProfile != null && message.hasOwnProperty("existingConflictingEmailPatientProfile")) {
              object.existingConflictingEmailPatientProfile = profiles$1.v1.PatientProfile.toObject(message.existingConflictingEmailPatientProfile, options);
            }
            if (message.existingConflictingPhoneNumberPatientProfile != null && message.hasOwnProperty("existingConflictingPhoneNumberPatientProfile")) {
              object.existingConflictingPhoneNumberPatientProfile = profiles$1.v1.PatientProfile.toObject(message.existingConflictingPhoneNumberPatientProfile, options);
            }
            if (message.convertedProfile != null && message.hasOwnProperty("convertedProfile")) {
              object.convertedProfile = profiles$1.v1.PatientProfile.toObject(message.convertedProfile, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.ConvertProfileToPatientReturn";
          }
        }

        ConvertProfileToPatientReturn.prototype.profileAlreadyPatientNoOp = false;
        ConvertProfileToPatientReturn.prototype.existingConflictingEmailPatientProfile = null;
        ConvertProfileToPatientReturn.prototype.existingConflictingPhoneNumberPatientProfile = null;
        ConvertProfileToPatientReturn.prototype.convertedProfile = null;

        return ConvertProfileToPatientReturn;
      })();

      v1.UpdateProfileProperties = (() => {
        class UpdateProfileProperties {
          constructor(properties) {
            this.propertyUpdateSources = [];
            this.thirdPartyProfileIdUpdateSources = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateProfileProperties(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.emailAddressUpdateSource != null && Object.hasOwnProperty.call(message, "emailAddressUpdateSource")) {
              workflow_parameters$1.v1.DestinationActionParameterSource.encode(message.emailAddressUpdateSource, writer.uint32(10).fork()).ldelim();
            }
            if (message.firstNameUpdateSource != null && Object.hasOwnProperty.call(message, "firstNameUpdateSource")) {
              workflow_parameters$1.v1.DestinationActionParameterSource.encode(message.firstNameUpdateSource, writer.uint32(18).fork()).ldelim();
            }
            if (message.lastNameUpdateSource != null && Object.hasOwnProperty.call(message, "lastNameUpdateSource")) {
              workflow_parameters$1.v1.DestinationActionParameterSource.encode(message.lastNameUpdateSource, writer.uint32(26).fork()).ldelim();
            }
            if (message.propertyUpdateSources != null && Object.hasOwnProperty.call(message, "propertyUpdateSources")) {
              for (const element of message.propertyUpdateSources) {
                $root.destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            if (message.thirdPartyProfileIdUpdateSources != null && Object.hasOwnProperty.call(message, "thirdPartyProfileIdUpdateSources")) {
              for (const element of message.thirdPartyProfileIdUpdateSources) {
                $root.destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping.encode(element, writer.uint32(42).fork()).ldelim();
              }
            }
            if (message.phoneNumberUpdateSource != null && Object.hasOwnProperty.call(message, "phoneNumberUpdateSource")) {
              workflow_parameters$1.v1.DestinationActionParameterSource.encode(message.phoneNumberUpdateSource, writer.uint32(50).fork()).ldelim();
            }
            if (message.mergeIfEmailAddressAlreadyExists != null && Object.hasOwnProperty.call(message, "mergeIfEmailAddressAlreadyExists")) {
              writer.uint32(56).bool(message.mergeIfEmailAddressAlreadyExists);
            }
            if (message.mergeIfPhoneNumberAlreadyExists != null && Object.hasOwnProperty.call(message, "mergeIfPhoneNumberAlreadyExists")) {
              writer.uint32(64).bool(message.mergeIfPhoneNumberAlreadyExists);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateProfileProperties();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.emailAddressUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.firstNameUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.lastNameUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  if (!message.propertyUpdateSources || !message.propertyUpdateSources.length) {
                    message.propertyUpdateSources = [];
                  }
                  message.propertyUpdateSources.push($root.destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping.decode(reader, reader.uint32()));
                  break;
                }
                case 5: {
                  if (!message.thirdPartyProfileIdUpdateSources || !message.thirdPartyProfileIdUpdateSources.length) {
                    message.thirdPartyProfileIdUpdateSources = [];
                  }
                  message.thirdPartyProfileIdUpdateSources.push($root.destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping.decode(reader, reader.uint32()));
                  break;
                }
                case 6: {
                  message.phoneNumberUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                  break;
                }
                case 7: {
                  message.mergeIfEmailAddressAlreadyExists = reader.bool();
                  break;
                }
                case 8: {
                  message.mergeIfPhoneNumberAlreadyExists = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.UpdateProfileProperties) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.UpdateProfileProperties();
            if (object.emailAddressUpdateSource != null) {
              if (typeof object.emailAddressUpdateSource !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.emailAddressUpdateSource: object expected, but got " + (typeof object.emailAddressUpdateSource));
              }
              message.emailAddressUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(object.emailAddressUpdateSource);
            }
            if (object.firstNameUpdateSource != null) {
              if (typeof object.firstNameUpdateSource !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.firstNameUpdateSource: object expected, but got " + (typeof object.firstNameUpdateSource));
              }
              message.firstNameUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(object.firstNameUpdateSource);
            }
            if (object.lastNameUpdateSource != null) {
              if (typeof object.lastNameUpdateSource !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.lastNameUpdateSource: object expected, but got " + (typeof object.lastNameUpdateSource));
              }
              message.lastNameUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(object.lastNameUpdateSource);
            }
            if (object.propertyUpdateSources) {
              if (!Array.isArray(object.propertyUpdateSources)) {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.propertyUpdateSources: array type expected, but got " + (typeof object.propertyUpdateSources))
              }
              message.propertyUpdateSources = new Array(object.propertyUpdateSources.length);
              for (let i = 0; i < object.propertyUpdateSources.length; ++i) {
                if (typeof object.propertyUpdateSources[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.propertyUpdateSources: object expected, but got " + (typeof object.propertyUpdateSources[i]));
                }
                message.propertyUpdateSources[i] = $root.destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping.fromObject(object.propertyUpdateSources[i]);
              }
            }
            if (object.thirdPartyProfileIdUpdateSources) {
              if (!Array.isArray(object.thirdPartyProfileIdUpdateSources)) {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.thirdPartyProfileIdUpdateSources: array type expected, but got " + (typeof object.thirdPartyProfileIdUpdateSources))
              }
              message.thirdPartyProfileIdUpdateSources = new Array(object.thirdPartyProfileIdUpdateSources.length);
              for (let i = 0; i < object.thirdPartyProfileIdUpdateSources.length; ++i) {
                if (typeof object.thirdPartyProfileIdUpdateSources[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.thirdPartyProfileIdUpdateSources: object expected, but got " + (typeof object.thirdPartyProfileIdUpdateSources[i]));
                }
                message.thirdPartyProfileIdUpdateSources[i] = $root.destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping.fromObject(object.thirdPartyProfileIdUpdateSources[i]);
              }
            }
            if (object.phoneNumberUpdateSource != null) {
              if (typeof object.phoneNumberUpdateSource !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.phoneNumberUpdateSource: object expected, but got " + (typeof object.phoneNumberUpdateSource));
              }
              message.phoneNumberUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(object.phoneNumberUpdateSource);
            }
            if (object.mergeIfEmailAddressAlreadyExists != null) {
              message.mergeIfEmailAddressAlreadyExists = Boolean(object.mergeIfEmailAddressAlreadyExists);
            }
            if (object.mergeIfPhoneNumberAlreadyExists != null) {
              message.mergeIfPhoneNumberAlreadyExists = Boolean(object.mergeIfPhoneNumberAlreadyExists);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.propertyUpdateSources = [];
              object.thirdPartyProfileIdUpdateSources = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.emailAddressUpdateSource = null;
              object.firstNameUpdateSource = null;
              object.lastNameUpdateSource = null;
              object.phoneNumberUpdateSource = null;
              object.mergeIfEmailAddressAlreadyExists = false;
              object.mergeIfPhoneNumberAlreadyExists = false;
            }
            let keys;
            if (message.emailAddressUpdateSource != null && message.hasOwnProperty("emailAddressUpdateSource")) {
              object.emailAddressUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.toObject(message.emailAddressUpdateSource, options);
            }
            if (message.firstNameUpdateSource != null && message.hasOwnProperty("firstNameUpdateSource")) {
              object.firstNameUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.toObject(message.firstNameUpdateSource, options);
            }
            if (message.lastNameUpdateSource != null && message.hasOwnProperty("lastNameUpdateSource")) {
              object.lastNameUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.toObject(message.lastNameUpdateSource, options);
            }
            if (message.propertyUpdateSources && message.propertyUpdateSources.length) {
              object.propertyUpdateSources = new Array(message.propertyUpdateSources.length);
              for (let i = 0; i < message.propertyUpdateSources.length; ++i) {
                object.propertyUpdateSources[i] = $root.destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping.toObject(message.propertyUpdateSources[i], options);
              }
            }
            if (message.thirdPartyProfileIdUpdateSources && message.thirdPartyProfileIdUpdateSources.length) {
              object.thirdPartyProfileIdUpdateSources = new Array(message.thirdPartyProfileIdUpdateSources.length);
              for (let i = 0; i < message.thirdPartyProfileIdUpdateSources.length; ++i) {
                object.thirdPartyProfileIdUpdateSources[i] = $root.destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping.toObject(message.thirdPartyProfileIdUpdateSources[i], options);
              }
            }
            if (message.phoneNumberUpdateSource != null && message.hasOwnProperty("phoneNumberUpdateSource")) {
              object.phoneNumberUpdateSource = workflow_parameters$1.v1.DestinationActionParameterSource.toObject(message.phoneNumberUpdateSource, options);
            }
            if (message.mergeIfEmailAddressAlreadyExists != null && message.hasOwnProperty("mergeIfEmailAddressAlreadyExists")) {
              object.mergeIfEmailAddressAlreadyExists = message.mergeIfEmailAddressAlreadyExists;
            }
            if (message.mergeIfPhoneNumberAlreadyExists != null && message.hasOwnProperty("mergeIfPhoneNumberAlreadyExists")) {
              object.mergeIfPhoneNumberAlreadyExists = message.mergeIfPhoneNumberAlreadyExists;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfileProperties";
          }
        }

        UpdateProfileProperties.prototype.emailAddressUpdateSource = null;
        UpdateProfileProperties.prototype.firstNameUpdateSource = null;
        UpdateProfileProperties.prototype.lastNameUpdateSource = null;
        UpdateProfileProperties.prototype.propertyUpdateSources = $util.emptyArray;
        UpdateProfileProperties.prototype.thirdPartyProfileIdUpdateSources = $util.emptyArray;
        UpdateProfileProperties.prototype.phoneNumberUpdateSource = null;
        UpdateProfileProperties.prototype.mergeIfEmailAddressAlreadyExists = false;
        UpdateProfileProperties.prototype.mergeIfPhoneNumberAlreadyExists = false;
        UpdateProfileProperties.PropertyMapping = (() => {
          class PropertyMapping {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new PropertyMapping(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.property != null && Object.hasOwnProperty.call(message, "property")) {
                workflow_parameters$1.v1.ProfilePropertyLookup.encode(message.property, writer.uint32(10).fork()).ldelim();
              }
              if (message.source != null && Object.hasOwnProperty.call(message, "source")) {
                workflow_parameters$1.v1.DestinationActionParameterSource.encode(message.source, writer.uint32(18).fork()).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new PropertyMapping();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.property = workflow_parameters$1.v1.ProfilePropertyLookup.decode(reader, reader.uint32());
                    break;
                  }
                  case 2: {
                    message.source = workflow_parameters$1.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping();
              if (object.property != null) {
                if (typeof object.property !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping.property: object expected, but got " + (typeof object.property));
                }
                message.property = workflow_parameters$1.v1.ProfilePropertyLookup.fromObject(object.property);
              }
              if (object.source != null) {
                if (typeof object.source !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping.source: object expected, but got " + (typeof object.source));
                }
                message.source = workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(object.source);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.property = null;
                object.source = null;
              }
              let keys;
              if (message.property != null && message.hasOwnProperty("property")) {
                object.property = workflow_parameters$1.v1.ProfilePropertyLookup.toObject(message.property, options);
              }
              if (message.source != null && message.hasOwnProperty("source")) {
                object.source = workflow_parameters$1.v1.DestinationActionParameterSource.toObject(message.source, options);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping";
            }
          }

          PropertyMapping.prototype.property = null;
          PropertyMapping.prototype.source = null;

          return PropertyMapping;
        })();

        UpdateProfileProperties.ThirdPartyProfileIDMapping = (() => {
          class ThirdPartyProfileIDMapping {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new ThirdPartyProfileIDMapping(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
                writer.uint32(8).int32(message.type);
              }
              if (message.source != null && Object.hasOwnProperty.call(message, "source")) {
                workflow_parameters$1.v1.DestinationActionParameterSource.encode(message.source, writer.uint32(18).fork()).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new ThirdPartyProfileIDMapping();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.type = reader.int32();
                    break;
                  }
                  case 2: {
                    message.source = workflow_parameters$1.v1.DestinationActionParameterSource.decode(reader, reader.uint32());
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping();
              if (object.type != null) {
                switch (object.type) {
                  case "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION":
                  case 0: {
                    message.type = 0;
                    break;
                  }
                  case "CUSTOMER":
                  case 1: {
                    message.type = 1;
                    break;
                  }
                  case "FORMSORT":
                  case 2: {
                    message.type = 2;
                    break;
                  }
                  case "HEALTHIE":
                  case 3: {
                    message.type = 3;
                    break;
                  }
                  case "AXLE_HEALTH":
                  case 4: {
                    message.type = 4;
                    break;
                  }
                  case "BUTTERFLY_LABS":
                  case 5: {
                    message.type = 5;
                    break;
                  }
                  case "RECURLY":
                  case 6: {
                    message.type = 6;
                    break;
                  }
                  case "INTERCOM":
                  case 7: {
                    message.type = 7;
                    break;
                  }
                  case "SANA_BENEFITS":
                  case 8: {
                    message.type = 8;
                    break;
                  }
                  case "ACTIVE_CAMPAIGN":
                  case 9: {
                    message.type = 9;
                    break;
                  }
                  case "VITAL":
                  case 10: {
                    message.type = 10;
                    break;
                  }
                  case "SEGMENT":
                  case 11: {
                    message.type = 11;
                    break;
                  }
                  case "INTAKEQ":
                  case 12: {
                    message.type = 12;
                    break;
                  }
                  case "CUSTOMER_IO":
                  case 13: {
                    message.type = 13;
                    break;
                  }
                  case "FRESHDESK":
                  case 14: {
                    message.type = 14;
                    break;
                  }
                  case "HUBSPOT":
                  case 15: {
                    message.type = 15;
                    break;
                  }
                  default: {
                    if (typeof object.type == "number") {
                      message.type = object.type;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.source != null) {
                if (typeof object.source !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping.source: object expected, but got " + (typeof object.source));
                }
                message.source = workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(object.source);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.type = options.enums === String ? "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION" : 0
                object.source = null;
              }
              let keys;
              if (message.type != null && message.hasOwnProperty("type")) {
                object.type = options.enums === String ? thirdparties$1.v1.ThirdPartyIdType[message.type] === undefined ? message.type : thirdparties$1.v1.ThirdPartyIdType[message.type] : message.type;
              }
              if (message.source != null && message.hasOwnProperty("source")) {
                object.source = workflow_parameters$1.v1.DestinationActionParameterSource.toObject(message.source, options);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping";
            }
          }

          ThirdPartyProfileIDMapping.prototype.type = 0;
          ThirdPartyProfileIDMapping.prototype.source = null;

          return ThirdPartyProfileIDMapping;
        })();

        return UpdateProfileProperties;
      })();

      v1.UpdateProfilePropertiesReturn = (() => {
        class UpdateProfilePropertiesReturn {
          constructor(properties) {
            this.propertyValueUpdates = [];
            this.failedPropertyValueUpdates = [];
            this.failedThirdPartyProfileIdValueUpdates = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateProfilePropertiesReturn(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.emailAddressValueUpdate != null && Object.hasOwnProperty.call(message, "emailAddressValueUpdate")) {
              $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.encode(message.emailAddressValueUpdate, writer.uint32(10).fork()).ldelim();
            }
            if (message.nameValueUpdate != null && Object.hasOwnProperty.call(message, "nameValueUpdate")) {
              $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.encode(message.nameValueUpdate, writer.uint32(18).fork()).ldelim();
            }
            if (message.propertyValueUpdates != null && Object.hasOwnProperty.call(message, "propertyValueUpdates")) {
              for (const element of message.propertyValueUpdates) {
                $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate.encode(element, writer.uint32(26).fork()).ldelim();
              }
            }
            if (message.thirdPartyProfileIdAssociations != null && Object.hasOwnProperty.call(message, "thirdPartyProfileIdAssociations")) {
              profiles$1.v1.ThirdPartyIds.encode(message.thirdPartyProfileIdAssociations, writer.uint32(34).fork()).ldelim();
            }
            if (message.failedPropertyValueUpdates != null && Object.hasOwnProperty.call(message, "failedPropertyValueUpdates")) {
              for (const element of message.failedPropertyValueUpdates) {
                $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate.encode(element, writer.uint32(42).fork()).ldelim();
              }
            }
            if (message.failedThirdPartyProfileIdValueUpdates != null && Object.hasOwnProperty.call(message, "failedThirdPartyProfileIdValueUpdates")) {
              for (const element of message.failedThirdPartyProfileIdValueUpdates) {
                $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate.encode(element, writer.uint32(50).fork()).ldelim();
              }
            }
            if (message.executedProfileEmailUpdate != null && Object.hasOwnProperty.call(message, "executedProfileEmailUpdate")) {
              writer.uint32(56).bool(message.executedProfileEmailUpdate);
            }
            if (message.executedProfileNameUpdate != null && Object.hasOwnProperty.call(message, "executedProfileNameUpdate")) {
              writer.uint32(64).bool(message.executedProfileNameUpdate);
            }
            if (message.executedProfileThirdPartyProfileIdAssociations != null && Object.hasOwnProperty.call(message, "executedProfileThirdPartyProfileIdAssociations")) {
              writer.uint32(72).bool(message.executedProfileThirdPartyProfileIdAssociations);
            }
            if (message.executedProfilePropertiesUpdates != null && Object.hasOwnProperty.call(message, "executedProfilePropertiesUpdates")) {
              writer.uint32(80).bool(message.executedProfilePropertiesUpdates);
            }
            if (message.phoneNumberValueUpdate != null && Object.hasOwnProperty.call(message, "phoneNumberValueUpdate")) {
              $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.encode(message.phoneNumberValueUpdate, writer.uint32(90).fork()).ldelim();
            }
            if (message.executedProfilePhoneNumberUpdate != null && Object.hasOwnProperty.call(message, "executedProfilePhoneNumberUpdate")) {
              writer.uint32(96).bool(message.executedProfilePhoneNumberUpdate);
            }
            if (message.existingConflictingEmailProfile != null && Object.hasOwnProperty.call(message, "existingConflictingEmailProfile")) {
              profiles$1.v1.Profile.encode(message.existingConflictingEmailProfile, writer.uint32(106).fork()).ldelim();
            }
            if (message.existingConflictingPhoneNumberProfile != null && Object.hasOwnProperty.call(message, "existingConflictingPhoneNumberProfile")) {
              profiles$1.v1.Profile.encode(message.existingConflictingPhoneNumberProfile, writer.uint32(114).fork()).ldelim();
            }
            if (message.executedProfileEmailAddressMerge != null && Object.hasOwnProperty.call(message, "executedProfileEmailAddressMerge")) {
              profiles$1.v1.Profile.encode(message.executedProfileEmailAddressMerge, writer.uint32(122).fork()).ldelim();
            }
            if (message.executedProfilePhoneNumberMerge != null && Object.hasOwnProperty.call(message, "executedProfilePhoneNumberMerge")) {
              profiles$1.v1.Profile.encode(message.executedProfilePhoneNumberMerge, writer.uint32(130).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateProfilePropertiesReturn();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.emailAddressValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.nameValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  if (!message.propertyValueUpdates || !message.propertyValueUpdates.length) {
                    message.propertyValueUpdates = [];
                  }
                  message.propertyValueUpdates.push($root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  message.thirdPartyProfileIdAssociations = profiles$1.v1.ThirdPartyIds.decode(reader, reader.uint32());
                  break;
                }
                case 5: {
                  if (!message.failedPropertyValueUpdates || !message.failedPropertyValueUpdates.length) {
                    message.failedPropertyValueUpdates = [];
                  }
                  message.failedPropertyValueUpdates.push($root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate.decode(reader, reader.uint32()));
                  break;
                }
                case 6: {
                  if (!message.failedThirdPartyProfileIdValueUpdates || !message.failedThirdPartyProfileIdValueUpdates.length) {
                    message.failedThirdPartyProfileIdValueUpdates = [];
                  }
                  message.failedThirdPartyProfileIdValueUpdates.push($root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate.decode(reader, reader.uint32()));
                  break;
                }
                case 7: {
                  message.executedProfileEmailUpdate = reader.bool();
                  break;
                }
                case 8: {
                  message.executedProfileNameUpdate = reader.bool();
                  break;
                }
                case 9: {
                  message.executedProfileThirdPartyProfileIdAssociations = reader.bool();
                  break;
                }
                case 10: {
                  message.executedProfilePropertiesUpdates = reader.bool();
                  break;
                }
                case 11: {
                  message.phoneNumberValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.decode(reader, reader.uint32());
                  break;
                }
                case 12: {
                  message.executedProfilePhoneNumberUpdate = reader.bool();
                  break;
                }
                case 13: {
                  message.existingConflictingEmailProfile = profiles$1.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                case 14: {
                  message.existingConflictingPhoneNumberProfile = profiles$1.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                case 15: {
                  message.executedProfileEmailAddressMerge = profiles$1.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                case 16: {
                  message.executedProfilePhoneNumberMerge = profiles$1.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn();
            if (object.emailAddressValueUpdate != null) {
              if (typeof object.emailAddressValueUpdate !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.emailAddressValueUpdate: object expected, but got " + (typeof object.emailAddressValueUpdate));
              }
              message.emailAddressValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.fromObject(object.emailAddressValueUpdate);
            }
            if (object.nameValueUpdate != null) {
              if (typeof object.nameValueUpdate !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.nameValueUpdate: object expected, but got " + (typeof object.nameValueUpdate));
              }
              message.nameValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.fromObject(object.nameValueUpdate);
            }
            if (object.propertyValueUpdates) {
              if (!Array.isArray(object.propertyValueUpdates)) {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.propertyValueUpdates: array type expected, but got " + (typeof object.propertyValueUpdates))
              }
              message.propertyValueUpdates = new Array(object.propertyValueUpdates.length);
              for (let i = 0; i < object.propertyValueUpdates.length; ++i) {
                if (typeof object.propertyValueUpdates[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.propertyValueUpdates: object expected, but got " + (typeof object.propertyValueUpdates[i]));
                }
                message.propertyValueUpdates[i] = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate.fromObject(object.propertyValueUpdates[i]);
              }
            }
            if (object.thirdPartyProfileIdAssociations != null) {
              if (typeof object.thirdPartyProfileIdAssociations !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.thirdPartyProfileIdAssociations: object expected, but got " + (typeof object.thirdPartyProfileIdAssociations));
              }
              message.thirdPartyProfileIdAssociations = profiles$1.v1.ThirdPartyIds.fromObject(object.thirdPartyProfileIdAssociations);
            }
            if (object.failedPropertyValueUpdates) {
              if (!Array.isArray(object.failedPropertyValueUpdates)) {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.failedPropertyValueUpdates: array type expected, but got " + (typeof object.failedPropertyValueUpdates))
              }
              message.failedPropertyValueUpdates = new Array(object.failedPropertyValueUpdates.length);
              for (let i = 0; i < object.failedPropertyValueUpdates.length; ++i) {
                if (typeof object.failedPropertyValueUpdates[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.failedPropertyValueUpdates: object expected, but got " + (typeof object.failedPropertyValueUpdates[i]));
                }
                message.failedPropertyValueUpdates[i] = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate.fromObject(object.failedPropertyValueUpdates[i]);
              }
            }
            if (object.failedThirdPartyProfileIdValueUpdates) {
              if (!Array.isArray(object.failedThirdPartyProfileIdValueUpdates)) {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.failedThirdPartyProfileIdValueUpdates: array type expected, but got " + (typeof object.failedThirdPartyProfileIdValueUpdates))
              }
              message.failedThirdPartyProfileIdValueUpdates = new Array(object.failedThirdPartyProfileIdValueUpdates.length);
              for (let i = 0; i < object.failedThirdPartyProfileIdValueUpdates.length; ++i) {
                if (typeof object.failedThirdPartyProfileIdValueUpdates[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.failedThirdPartyProfileIdValueUpdates: object expected, but got " + (typeof object.failedThirdPartyProfileIdValueUpdates[i]));
                }
                message.failedThirdPartyProfileIdValueUpdates[i] = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate.fromObject(object.failedThirdPartyProfileIdValueUpdates[i]);
              }
            }
            if (object.executedProfileEmailUpdate != null) {
              message.executedProfileEmailUpdate = Boolean(object.executedProfileEmailUpdate);
            }
            if (object.executedProfileNameUpdate != null) {
              message.executedProfileNameUpdate = Boolean(object.executedProfileNameUpdate);
            }
            if (object.executedProfileThirdPartyProfileIdAssociations != null) {
              message.executedProfileThirdPartyProfileIdAssociations = Boolean(object.executedProfileThirdPartyProfileIdAssociations);
            }
            if (object.executedProfilePropertiesUpdates != null) {
              message.executedProfilePropertiesUpdates = Boolean(object.executedProfilePropertiesUpdates);
            }
            if (object.phoneNumberValueUpdate != null) {
              if (typeof object.phoneNumberValueUpdate !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.phoneNumberValueUpdate: object expected, but got " + (typeof object.phoneNumberValueUpdate));
              }
              message.phoneNumberValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.fromObject(object.phoneNumberValueUpdate);
            }
            if (object.executedProfilePhoneNumberUpdate != null) {
              message.executedProfilePhoneNumberUpdate = Boolean(object.executedProfilePhoneNumberUpdate);
            }
            if (object.existingConflictingEmailProfile != null) {
              if (typeof object.existingConflictingEmailProfile !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.existingConflictingEmailProfile: object expected, but got " + (typeof object.existingConflictingEmailProfile));
              }
              message.existingConflictingEmailProfile = profiles$1.v1.Profile.fromObject(object.existingConflictingEmailProfile);
            }
            if (object.existingConflictingPhoneNumberProfile != null) {
              if (typeof object.existingConflictingPhoneNumberProfile !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.existingConflictingPhoneNumberProfile: object expected, but got " + (typeof object.existingConflictingPhoneNumberProfile));
              }
              message.existingConflictingPhoneNumberProfile = profiles$1.v1.Profile.fromObject(object.existingConflictingPhoneNumberProfile);
            }
            if (object.executedProfileEmailAddressMerge != null) {
              if (typeof object.executedProfileEmailAddressMerge !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.executedProfileEmailAddressMerge: object expected, but got " + (typeof object.executedProfileEmailAddressMerge));
              }
              message.executedProfileEmailAddressMerge = profiles$1.v1.Profile.fromObject(object.executedProfileEmailAddressMerge);
            }
            if (object.executedProfilePhoneNumberMerge != null) {
              if (typeof object.executedProfilePhoneNumberMerge !== "object") {
                throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.executedProfilePhoneNumberMerge: object expected, but got " + (typeof object.executedProfilePhoneNumberMerge));
              }
              message.executedProfilePhoneNumberMerge = profiles$1.v1.Profile.fromObject(object.executedProfilePhoneNumberMerge);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.propertyValueUpdates = [];
              object.failedPropertyValueUpdates = [];
              object.failedThirdPartyProfileIdValueUpdates = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.emailAddressValueUpdate = null;
              object.nameValueUpdate = null;
              object.thirdPartyProfileIdAssociations = null;
              object.executedProfileEmailUpdate = false;
              object.executedProfileNameUpdate = false;
              object.executedProfileThirdPartyProfileIdAssociations = false;
              object.executedProfilePropertiesUpdates = false;
              object.phoneNumberValueUpdate = null;
              object.executedProfilePhoneNumberUpdate = false;
            }
            let keys;
            if (message.emailAddressValueUpdate != null && message.hasOwnProperty("emailAddressValueUpdate")) {
              object.emailAddressValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.toObject(message.emailAddressValueUpdate, options);
            }
            if (message.nameValueUpdate != null && message.hasOwnProperty("nameValueUpdate")) {
              object.nameValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.toObject(message.nameValueUpdate, options);
            }
            if (message.propertyValueUpdates && message.propertyValueUpdates.length) {
              object.propertyValueUpdates = new Array(message.propertyValueUpdates.length);
              for (let i = 0; i < message.propertyValueUpdates.length; ++i) {
                object.propertyValueUpdates[i] = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate.toObject(message.propertyValueUpdates[i], options);
              }
            }
            if (message.thirdPartyProfileIdAssociations != null && message.hasOwnProperty("thirdPartyProfileIdAssociations")) {
              object.thirdPartyProfileIdAssociations = profiles$1.v1.ThirdPartyIds.toObject(message.thirdPartyProfileIdAssociations, options);
            }
            if (message.failedPropertyValueUpdates && message.failedPropertyValueUpdates.length) {
              object.failedPropertyValueUpdates = new Array(message.failedPropertyValueUpdates.length);
              for (let i = 0; i < message.failedPropertyValueUpdates.length; ++i) {
                object.failedPropertyValueUpdates[i] = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate.toObject(message.failedPropertyValueUpdates[i], options);
              }
            }
            if (message.failedThirdPartyProfileIdValueUpdates && message.failedThirdPartyProfileIdValueUpdates.length) {
              object.failedThirdPartyProfileIdValueUpdates = new Array(message.failedThirdPartyProfileIdValueUpdates.length);
              for (let i = 0; i < message.failedThirdPartyProfileIdValueUpdates.length; ++i) {
                object.failedThirdPartyProfileIdValueUpdates[i] = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate.toObject(message.failedThirdPartyProfileIdValueUpdates[i], options);
              }
            }
            if (message.executedProfileEmailUpdate != null && message.hasOwnProperty("executedProfileEmailUpdate")) {
              object.executedProfileEmailUpdate = message.executedProfileEmailUpdate;
            }
            if (message.executedProfileNameUpdate != null && message.hasOwnProperty("executedProfileNameUpdate")) {
              object.executedProfileNameUpdate = message.executedProfileNameUpdate;
            }
            if (message.executedProfileThirdPartyProfileIdAssociations != null && message.hasOwnProperty("executedProfileThirdPartyProfileIdAssociations")) {
              object.executedProfileThirdPartyProfileIdAssociations = message.executedProfileThirdPartyProfileIdAssociations;
            }
            if (message.executedProfilePropertiesUpdates != null && message.hasOwnProperty("executedProfilePropertiesUpdates")) {
              object.executedProfilePropertiesUpdates = message.executedProfilePropertiesUpdates;
            }
            if (message.phoneNumberValueUpdate != null && message.hasOwnProperty("phoneNumberValueUpdate")) {
              object.phoneNumberValueUpdate = $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate.toObject(message.phoneNumberValueUpdate, options);
            }
            if (message.executedProfilePhoneNumberUpdate != null && message.hasOwnProperty("executedProfilePhoneNumberUpdate")) {
              object.executedProfilePhoneNumberUpdate = message.executedProfilePhoneNumberUpdate;
            }
            if (message.existingConflictingEmailProfile != null && message.hasOwnProperty("existingConflictingEmailProfile")) {
              object.existingConflictingEmailProfile = profiles$1.v1.Profile.toObject(message.existingConflictingEmailProfile, options);
            }
            if (message.existingConflictingPhoneNumberProfile != null && message.hasOwnProperty("existingConflictingPhoneNumberProfile")) {
              object.existingConflictingPhoneNumberProfile = profiles$1.v1.Profile.toObject(message.existingConflictingPhoneNumberProfile, options);
            }
            if (message.executedProfileEmailAddressMerge != null && message.hasOwnProperty("executedProfileEmailAddressMerge")) {
              object.executedProfileEmailAddressMerge = profiles$1.v1.Profile.toObject(message.executedProfileEmailAddressMerge, options);
            }
            if (message.executedProfilePhoneNumberMerge != null && message.hasOwnProperty("executedProfilePhoneNumberMerge")) {
              object.executedProfilePhoneNumberMerge = profiles$1.v1.Profile.toObject(message.executedProfilePhoneNumberMerge, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfilePropertiesReturn";
          }
        }

        UpdateProfilePropertiesReturn.prototype.emailAddressValueUpdate = null;
        UpdateProfilePropertiesReturn.prototype.nameValueUpdate = null;
        UpdateProfilePropertiesReturn.prototype.propertyValueUpdates = $util.emptyArray;
        UpdateProfilePropertiesReturn.prototype.thirdPartyProfileIdAssociations = null;
        UpdateProfilePropertiesReturn.prototype.failedPropertyValueUpdates = $util.emptyArray;
        UpdateProfilePropertiesReturn.prototype.failedThirdPartyProfileIdValueUpdates = $util.emptyArray;
        UpdateProfilePropertiesReturn.prototype.executedProfileEmailUpdate = false;
        UpdateProfilePropertiesReturn.prototype.executedProfileNameUpdate = false;
        UpdateProfilePropertiesReturn.prototype.executedProfileThirdPartyProfileIdAssociations = false;
        UpdateProfilePropertiesReturn.prototype.executedProfilePropertiesUpdates = false;
        UpdateProfilePropertiesReturn.prototype.phoneNumberValueUpdate = null;
        UpdateProfilePropertiesReturn.prototype.executedProfilePhoneNumberUpdate = false;
        UpdateProfilePropertiesReturn.prototype.existingConflictingEmailProfile = null;
        UpdateProfilePropertiesReturn.prototype.existingConflictingPhoneNumberProfile = null;
        UpdateProfilePropertiesReturn.prototype.executedProfileEmailAddressMerge = null;
        UpdateProfilePropertiesReturn.prototype.executedProfilePhoneNumberMerge = null;
        UpdateProfilePropertiesReturn.PropertyValueUpdate = (() => {
          class PropertyValueUpdate {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new PropertyValueUpdate(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.property != null && Object.hasOwnProperty.call(message, "property")) {
                workflow_parameters$1.v1.ProfilePropertyLookup.encode(message.property, writer.uint32(10).fork()).ldelim();
              }
              if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
                values$1.v1.Value.encode(message.value, writer.uint32(18).fork()).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new PropertyValueUpdate();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.property = workflow_parameters$1.v1.ProfilePropertyLookup.decode(reader, reader.uint32());
                    break;
                  }
                  case 2: {
                    message.value = values$1.v1.Value.decode(reader, reader.uint32());
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate();
              if (object.property != null) {
                if (typeof object.property !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate.property: object expected, but got " + (typeof object.property));
                }
                message.property = workflow_parameters$1.v1.ProfilePropertyLookup.fromObject(object.property);
              }
              if (object.value != null) {
                if (typeof object.value !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate.value: object expected, but got " + (typeof object.value));
                }
                message.value = values$1.v1.Value.fromObject(object.value);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.property = null;
                object.value = null;
              }
              let keys;
              if (message.property != null && message.hasOwnProperty("property")) {
                object.property = workflow_parameters$1.v1.ProfilePropertyLookup.toObject(message.property, options);
              }
              if (message.value != null && message.hasOwnProperty("value")) {
                object.value = values$1.v1.Value.toObject(message.value, options);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfilePropertiesReturn.PropertyValueUpdate";
            }
          }

          PropertyValueUpdate.prototype.property = null;
          PropertyValueUpdate.prototype.value = null;

          return PropertyValueUpdate;
        })();

        UpdateProfilePropertiesReturn.FailedPropertyValueUpdate = (() => {
          class FailedPropertyValueUpdate {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new FailedPropertyValueUpdate(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.property != null && Object.hasOwnProperty.call(message, "property")) {
                workflow_parameters$1.v1.ProfilePropertyLookup.encode(message.property, writer.uint32(10).fork()).ldelim();
              }
              if (message.sourcingError != null && Object.hasOwnProperty.call(message, "sourcingError")) {
                writer.uint32(18).string(message.sourcingError);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new FailedPropertyValueUpdate();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.property = workflow_parameters$1.v1.ProfilePropertyLookup.decode(reader, reader.uint32());
                    break;
                  }
                  case 2: {
                    message.sourcingError = reader.string();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate();
              if (object.property != null) {
                if (typeof object.property !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate.property: object expected, but got " + (typeof object.property));
                }
                message.property = workflow_parameters$1.v1.ProfilePropertyLookup.fromObject(object.property);
              }
              if (object.sourcingError != null) {
                message.sourcingError = String(object.sourcingError);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.property = null;
                object.sourcingError = "";
              }
              let keys;
              if (message.property != null && message.hasOwnProperty("property")) {
                object.property = workflow_parameters$1.v1.ProfilePropertyLookup.toObject(message.property, options);
              }
              if (message.sourcingError != null && message.hasOwnProperty("sourcingError")) {
                object.sourcingError = message.sourcingError;
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedPropertyValueUpdate";
            }
          }

          FailedPropertyValueUpdate.prototype.property = null;
          FailedPropertyValueUpdate.prototype.sourcingError = "";

          return FailedPropertyValueUpdate;
        })();

        UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate = (() => {
          class FailedThirdPartyProfileIdValueUpdate {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new FailedThirdPartyProfileIdValueUpdate(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
                writer.uint32(8).int32(message.type);
              }
              if (message.sourcingError != null && Object.hasOwnProperty.call(message, "sourcingError")) {
                writer.uint32(18).string(message.sourcingError);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new FailedThirdPartyProfileIdValueUpdate();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.type = reader.int32();
                    break;
                  }
                  case 2: {
                    message.sourcingError = reader.string();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate();
              if (object.type != null) {
                switch (object.type) {
                  case "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION":
                  case 0: {
                    message.type = 0;
                    break;
                  }
                  case "CUSTOMER":
                  case 1: {
                    message.type = 1;
                    break;
                  }
                  case "FORMSORT":
                  case 2: {
                    message.type = 2;
                    break;
                  }
                  case "HEALTHIE":
                  case 3: {
                    message.type = 3;
                    break;
                  }
                  case "AXLE_HEALTH":
                  case 4: {
                    message.type = 4;
                    break;
                  }
                  case "BUTTERFLY_LABS":
                  case 5: {
                    message.type = 5;
                    break;
                  }
                  case "RECURLY":
                  case 6: {
                    message.type = 6;
                    break;
                  }
                  case "INTERCOM":
                  case 7: {
                    message.type = 7;
                    break;
                  }
                  case "SANA_BENEFITS":
                  case 8: {
                    message.type = 8;
                    break;
                  }
                  case "ACTIVE_CAMPAIGN":
                  case 9: {
                    message.type = 9;
                    break;
                  }
                  case "VITAL":
                  case 10: {
                    message.type = 10;
                    break;
                  }
                  case "SEGMENT":
                  case 11: {
                    message.type = 11;
                    break;
                  }
                  case "INTAKEQ":
                  case 12: {
                    message.type = 12;
                    break;
                  }
                  case "CUSTOMER_IO":
                  case 13: {
                    message.type = 13;
                    break;
                  }
                  case "FRESHDESK":
                  case 14: {
                    message.type = 14;
                    break;
                  }
                  case "HUBSPOT":
                  case 15: {
                    message.type = 15;
                    break;
                  }
                  default: {
                    if (typeof object.type == "number") {
                      message.type = object.type;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.sourcingError != null) {
                message.sourcingError = String(object.sourcingError);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.type = options.enums === String ? "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION" : 0
                object.sourcingError = "";
              }
              let keys;
              if (message.type != null && message.hasOwnProperty("type")) {
                object.type = options.enums === String ? thirdparties$1.v1.ThirdPartyIdType[message.type] === undefined ? message.type : thirdparties$1.v1.ThirdPartyIdType[message.type] : message.type;
              }
              if (message.sourcingError != null && message.hasOwnProperty("sourcingError")) {
                object.sourcingError = message.sourcingError;
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfilePropertiesReturn.FailedThirdPartyProfileIdValueUpdate";
            }
          }

          FailedThirdPartyProfileIdValueUpdate.prototype.type = 0;
          FailedThirdPartyProfileIdValueUpdate.prototype.sourcingError = "";

          return FailedThirdPartyProfileIdValueUpdate;
        })();

        UpdateProfilePropertiesReturn.ProfileIndexValueUpdate = (() => {
          class ProfileIndexValueUpdate {
            get type() {
              for (const key of ["successfullyEvaluatedValue", "sourcingError"]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set type(name) {
              for (const key of ["successfullyEvaluatedValue", "sourcingError"]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new ProfileIndexValueUpdate(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.successfullyEvaluatedValue != null && Object.hasOwnProperty.call(message, "successfullyEvaluatedValue")) {
                writer.uint32(10).string(message.successfullyEvaluatedValue);
              }
              if (message.sourcingError != null && Object.hasOwnProperty.call(message, "sourcingError")) {
                writer.uint32(18).string(message.sourcingError);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new ProfileIndexValueUpdate();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.successfullyEvaluatedValue = reader.string();
                    break;
                  }
                  case 2: {
                    message.sourcingError = reader.string();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate();
              if (object.successfullyEvaluatedValue != null) {
                message.successfullyEvaluatedValue = String(object.successfullyEvaluatedValue);
              }
              if (object.sourcingError != null) {
                message.sourcingError = String(object.sourcingError);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
              }
              let keys;
              if (message.successfullyEvaluatedValue != null && message.hasOwnProperty("successfullyEvaluatedValue")) {
                object.successfullyEvaluatedValue = message.successfullyEvaluatedValue;
                if (options.oneofs) {
                  object.type = "successfullyEvaluatedValue";
                }
              }
              if (message.sourcingError != null && message.hasOwnProperty("sourcingError")) {
                object.sourcingError = message.sourcingError;
                if (options.oneofs) {
                  object.type = "sourcingError";
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.UpdateProfilePropertiesReturn.ProfileIndexValueUpdate";
            }
          }

          ProfileIndexValueUpdate.prototype.successfullyEvaluatedValue = "";
          ProfileIndexValueUpdate.prototype.sourcingError = "";

          return ProfileIndexValueUpdate;
        })();

        return UpdateProfilePropertiesReturn;
      })();

      v1.TrackProfileProperties = (() => {
        class TrackProfileProperties {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new TrackProfileProperties(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new TrackProfileProperties();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.TrackProfileProperties) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.TrackProfileProperties();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.TrackProfileProperties";
          }
        }

        return TrackProfileProperties;
      })();

      v1.LookupProfile = (() => {
        class LookupProfile {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new LookupProfile(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
              writer.uint32(10).string(message.orgId);
            }
            if (message.configuration != null && Object.hasOwnProperty.call(message, "configuration")) {
              profiles$1.v1.LookupConfiguration.encode(message.configuration, writer.uint32(18).fork()).ldelim();
            }
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload")) {
              event_payload$1.v2.EventPayload.encode(message.payload, writer.uint32(26).fork()).ldelim();
            }
            if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
              writer.uint32(34).string(message.eventSnapshotId);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new LookupProfile();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.orgId = reader.string();
                  break;
                }
                case 2: {
                  message.configuration = profiles$1.v1.LookupConfiguration.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.payload = event_payload$1.v2.EventPayload.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  message.eventSnapshotId = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.LookupProfile) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.LookupProfile();
            if (object.orgId != null) {
              message.orgId = String(object.orgId);
            }
            if (object.configuration != null) {
              if (typeof object.configuration !== "object") {
                throw new TypeError(".destinationactions.morf.v1.LookupProfile.configuration: object expected, but got " + (typeof object.configuration));
              }
              message.configuration = profiles$1.v1.LookupConfiguration.fromObject(object.configuration);
            }
            if (object.payload != null) {
              if (typeof object.payload !== "object") {
                throw new TypeError(".destinationactions.morf.v1.LookupProfile.payload: object expected, but got " + (typeof object.payload));
              }
              message.payload = event_payload$1.v2.EventPayload.fromObject(object.payload);
            }
            if (object.eventSnapshotId != null) {
              message.eventSnapshotId = String(object.eventSnapshotId);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.orgId = "";
              object.configuration = null;
              object.payload = null;
              object.eventSnapshotId = "";
            }
            let keys;
            if (message.orgId != null && message.hasOwnProperty("orgId")) {
              object.orgId = message.orgId;
            }
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
              object.configuration = profiles$1.v1.LookupConfiguration.toObject(message.configuration, options);
            }
            if (message.payload != null && message.hasOwnProperty("payload")) {
              object.payload = event_payload$1.v2.EventPayload.toObject(message.payload, options);
            }
            if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
              object.eventSnapshotId = message.eventSnapshotId;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.LookupProfile";
          }
        }

        LookupProfile.prototype.orgId = "";
        LookupProfile.prototype.configuration = null;
        LookupProfile.prototype.payload = null;
        LookupProfile.prototype.eventSnapshotId = "";

        return LookupProfile;
      })();

      v1.TrackWorkflows = (() => {
        class TrackWorkflows {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new TrackWorkflows(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new TrackWorkflows();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.TrackWorkflows();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows";
          }
        }

        TrackWorkflows.TrackWorkflowTriggerExecution = (() => {
          class TrackWorkflowTriggerExecution {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new TrackWorkflowTriggerExecution(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
                writer.uint32(10).string(message.orgId);
              }
              if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
                writer.uint32(18).string(message.workflowId);
              }
              if (message.workflowVersion != null && Object.hasOwnProperty.call(message, "workflowVersion")) {
                writer.uint32(24).int64(message.workflowVersion);
              }
              if (message.workflowName != null && Object.hasOwnProperty.call(message, "workflowName")) {
                writer.uint32(34).string(message.workflowName);
              }
              if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
                writer.uint32(42).string(message.eventSnapshotId);
              }
              if (message.eventPayloadIndex != null && Object.hasOwnProperty.call(message, "eventPayloadIndex")) {
                writer.uint32(48).int64(message.eventPayloadIndex);
              }
              if (message.workflowApplicationType != null && Object.hasOwnProperty.call(message, "workflowApplicationType")) {
                writer.uint32(56).int32(message.workflowApplicationType);
              }
              if (message.temporalIds != null && Object.hasOwnProperty.call(message, "temporalIds")) {
                $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs.encode(message.temporalIds, writer.uint32(66).fork()).ldelim();
              }
              if (message.supersededWorkflowExecutionId != null && Object.hasOwnProperty.call(message, "supersededWorkflowExecutionId")) {
                writer.uint32(74).string(message.supersededWorkflowExecutionId);
              }
              if (message.retryOriginalWorkflowExecutionId != null && Object.hasOwnProperty.call(message, "retryOriginalWorkflowExecutionId")) {
                writer.uint32(82).string(message.retryOriginalWorkflowExecutionId);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new TrackWorkflowTriggerExecution();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.orgId = reader.string();
                    break;
                  }
                  case 2: {
                    message.workflowId = reader.string();
                    break;
                  }
                  case 3: {
                    message.workflowVersion = reader.int64();
                    break;
                  }
                  case 4: {
                    message.workflowName = reader.string();
                    break;
                  }
                  case 5: {
                    message.eventSnapshotId = reader.string();
                    break;
                  }
                  case 6: {
                    message.eventPayloadIndex = reader.int64();
                    break;
                  }
                  case 7: {
                    message.workflowApplicationType = reader.int32();
                    break;
                  }
                  case 8: {
                    message.temporalIds = $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs.decode(reader, reader.uint32());
                    break;
                  }
                  case 9: {
                    message.supersededWorkflowExecutionId = reader.string();
                    break;
                  }
                  case 10: {
                    message.retryOriginalWorkflowExecutionId = reader.string();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution();
              if (object.orgId != null) {
                message.orgId = String(object.orgId);
              }
              if (object.workflowId != null) {
                message.workflowId = String(object.workflowId);
              }
              if (object.workflowVersion != null) {
                if ($util.Long) {
                  (message.workflowVersion = $util.Long.fromValue(object.workflowVersion)).unsigned = false;
                } else if (typeof object.workflowVersion === "string") {
                  message.workflowVersion = parseInt(object.workflowVersion, 10);
                } else if (typeof object.workflowVersion === "number") {
                  message.workflowVersion = object.workflowVersion;
                } else if (typeof object.workflowVersion === "object") {
                  message.workflowVersion = new $util.LongBits(object.workflowVersion.low >>> 0, object.workflowVersion.high >>> 0).toNumber();
                }
              }
              if (object.workflowName != null) {
                message.workflowName = String(object.workflowName);
              }
              if (object.eventSnapshotId != null) {
                message.eventSnapshotId = String(object.eventSnapshotId);
              }
              if (object.eventPayloadIndex != null) {
                if ($util.Long) {
                  (message.eventPayloadIndex = $util.Long.fromValue(object.eventPayloadIndex)).unsigned = false;
                } else if (typeof object.eventPayloadIndex === "string") {
                  message.eventPayloadIndex = parseInt(object.eventPayloadIndex, 10);
                } else if (typeof object.eventPayloadIndex === "number") {
                  message.eventPayloadIndex = object.eventPayloadIndex;
                } else if (typeof object.eventPayloadIndex === "object") {
                  message.eventPayloadIndex = new $util.LongBits(object.eventPayloadIndex.low >>> 0, object.eventPayloadIndex.high >>> 0).toNumber();
                }
              }
              if (object.workflowApplicationType != null) {
                switch (object.workflowApplicationType) {
                  case "UNSPECIFIED_SOURCE_APPLICATION":
                  case 0: {
                    message.workflowApplicationType = 0;
                    break;
                  }
                  case "FORMSORT":
                  case 1: {
                    message.workflowApplicationType = 1;
                    break;
                  }
                  case "HEALTHIE":
                  case 2: {
                    message.workflowApplicationType = 2;
                    break;
                  }
                  case "AXLE_HEALTH":
                  case 3: {
                    message.workflowApplicationType = 3;
                    break;
                  }
                  case "BUTTERFLY_LABS":
                  case 4: {
                    message.workflowApplicationType = 4;
                    break;
                  }
                  case "MORF":
                  case 5: {
                    message.workflowApplicationType = 5;
                    break;
                  }
                  case "PUSH_JSON":
                  case 6: {
                    message.workflowApplicationType = 6;
                    break;
                  }
                  case "ACUITY":
                  case 7: {
                    message.workflowApplicationType = 7;
                    break;
                  }
                  case "VITAL":
                  case 8: {
                    message.workflowApplicationType = 8;
                    break;
                  }
                  case "SEGMENT":
                  case 9: {
                    message.workflowApplicationType = 9;
                    break;
                  }
                  case "ACTIVE_CAMPAIGN":
                  case 10: {
                    message.workflowApplicationType = 10;
                    break;
                  }
                  case "INTAKEQ":
                  case 11: {
                    message.workflowApplicationType = 11;
                    break;
                  }
                  case "CUSTOMER_IO":
                  case 12: {
                    message.workflowApplicationType = 12;
                    break;
                  }
                  case "HUBSPOT":
                  case 13: {
                    message.workflowApplicationType = 13;
                    break;
                  }
                  case "CUSTOMER":
                  case 14: {
                    message.workflowApplicationType = 14;
                    break;
                  }
                  case "CALENDLY":
                  case 15: {
                    message.workflowApplicationType = 15;
                    break;
                  }
                  default: {
                    if (typeof object.workflowApplicationType == "number") {
                      message.workflowApplicationType = object.workflowApplicationType;
                      break;
                    }
                    break;
                  }
                }
              }
              if (object.temporalIds != null) {
                if (typeof object.temporalIds !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.temporalIds: object expected, but got " + (typeof object.temporalIds));
                }
                message.temporalIds = $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs.fromObject(object.temporalIds);
              }
              if (object.supersededWorkflowExecutionId != null) {
                message.supersededWorkflowExecutionId = String(object.supersededWorkflowExecutionId);
              }
              if (object.retryOriginalWorkflowExecutionId != null) {
                message.retryOriginalWorkflowExecutionId = String(object.retryOriginalWorkflowExecutionId);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.orgId = "";
                object.workflowId = "";
                if ($util.Long) {
                  let long = new $util.Long(0, 0, false)
                  object.workflowVersion = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else {
                  object.workflowVersion = options.longs === String ? "0" : 0;
                }
                object.workflowName = "";
                object.eventSnapshotId = "";
                if ($util.Long) {
                  let long = new $util.Long(0, 0, false)
                  object.eventPayloadIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else {
                  object.eventPayloadIndex = options.longs === String ? "0" : 0;
                }
                object.workflowApplicationType = options.enums === String ? "UNSPECIFIED_SOURCE_APPLICATION" : 0
              }
              let keys;
              if (message.orgId != null && message.hasOwnProperty("orgId")) {
                object.orgId = message.orgId;
              }
              if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
                object.workflowId = message.workflowId;
              }
              if (message.workflowVersion != null && message.hasOwnProperty("workflowVersion")) {
                object.workflowVersion = typeof message.workflowVersion === "number" ? (options.longs === String ? String(message.workflowVersion) : message.workflowVersion) : (options.longs === String ? $util.Long.prototype.toString.call(message.workflowVersion) : options.longs === Number ? new $util.LongBits(message.workflowVersion.low >>> 0, message.workflowVersion.high >>> 0).toNumber() : message.workflowVersion);
              }
              if (message.workflowName != null && message.hasOwnProperty("workflowName")) {
                object.workflowName = message.workflowName;
              }
              if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
                object.eventSnapshotId = message.eventSnapshotId;
              }
              if (message.eventPayloadIndex != null && message.hasOwnProperty("eventPayloadIndex")) {
                object.eventPayloadIndex = typeof message.eventPayloadIndex === "number" ? (options.longs === String ? String(message.eventPayloadIndex) : message.eventPayloadIndex) : (options.longs === String ? $util.Long.prototype.toString.call(message.eventPayloadIndex) : options.longs === Number ? new $util.LongBits(message.eventPayloadIndex.low >>> 0, message.eventPayloadIndex.high >>> 0).toNumber() : message.eventPayloadIndex);
              }
              if (message.workflowApplicationType != null && message.hasOwnProperty("workflowApplicationType")) {
                object.workflowApplicationType = options.enums === String ? source_application$1.v2.SourceApplication.Application[message.workflowApplicationType] === undefined ? message.workflowApplicationType : source_application$1.v2.SourceApplication.Application[message.workflowApplicationType] : message.workflowApplicationType;
              }
              if (message.temporalIds != null && message.hasOwnProperty("temporalIds")) {
                object.temporalIds = $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs.toObject(message.temporalIds, options);
              }
              if (message.supersededWorkflowExecutionId != null && message.hasOwnProperty("supersededWorkflowExecutionId")) {
                object.supersededWorkflowExecutionId = message.supersededWorkflowExecutionId;
              }
              if (message.retryOriginalWorkflowExecutionId != null && message.hasOwnProperty("retryOriginalWorkflowExecutionId")) {
                object.retryOriginalWorkflowExecutionId = message.retryOriginalWorkflowExecutionId;
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution";
            }
          }

          TrackWorkflowTriggerExecution.prototype.orgId = "";
          TrackWorkflowTriggerExecution.prototype.workflowId = "";
          TrackWorkflowTriggerExecution.prototype.workflowVersion = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
          TrackWorkflowTriggerExecution.prototype.workflowName = "";
          TrackWorkflowTriggerExecution.prototype.eventSnapshotId = "";
          TrackWorkflowTriggerExecution.prototype.eventPayloadIndex = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
          TrackWorkflowTriggerExecution.prototype.workflowApplicationType = 0;
          TrackWorkflowTriggerExecution.prototype.temporalIds = null;
          TrackWorkflowTriggerExecution.prototype.supersededWorkflowExecutionId = null;
          TrackWorkflowTriggerExecution.prototype.retryOriginalWorkflowExecutionId = null;
          TrackWorkflowTriggerExecution.TemporalIDs = (() => {
            class TemporalIDs {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new TemporalIDs(properties)
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
                  writer.uint32(10).string(message.workflowId);
                }
                if (message.workflowRunId != null && Object.hasOwnProperty.call(message, "workflowRunId")) {
                  writer.uint32(18).string(message.workflowRunId);
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length;
                let message = new TemporalIDs();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.workflowId = reader.string();
                      break;
                    }
                    case 2: {
                      message.workflowRunId = reader.string();
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs) {
                  return object;
                }
                const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs();
                if (object.workflowId != null) {
                  message.workflowId = String(object.workflowId);
                }
                if (object.workflowRunId != null) {
                  message.workflowRunId = String(object.workflowRunId);
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.workflowId = "";
                  object.workflowRunId = "";
                }
                let keys;
                if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
                  object.workflowId = message.workflowId;
                }
                if (message.workflowRunId != null && message.hasOwnProperty("workflowRunId")) {
                  object.workflowRunId = message.workflowRunId;
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
                return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackWorkflowTriggerExecution.TemporalIDs";
              }
            }

            TemporalIDs.prototype.workflowId = "";
            TemporalIDs.prototype.workflowRunId = "";

            return TemporalIDs;
          })();

          return TrackWorkflowTriggerExecution;
        })();

        TrackWorkflows.TrackWorkflowProfileMatch = (() => {
          class TrackWorkflowProfileMatch {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new TrackWorkflowProfileMatch(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
                writer.uint32(10).string(message.orgId);
              }
              if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
                writer.uint32(18).string(message.workflowExecutionId);
              }
              if (message.instantaneousMatchedProfile != null && Object.hasOwnProperty.call(message, "instantaneousMatchedProfile")) {
                profiles$1.v1.Profile.encode(message.instantaneousMatchedProfile, writer.uint32(26).fork()).ldelim();
              }
              if (message.lookupExecutionResult != null && Object.hasOwnProperty.call(message, "lookupExecutionResult")) {
                profiles$1.v1.LookupExecutionResult.encode(message.lookupExecutionResult, writer.uint32(34).fork()).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new TrackWorkflowProfileMatch();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.orgId = reader.string();
                    break;
                  }
                  case 2: {
                    message.workflowExecutionId = reader.string();
                    break;
                  }
                  case 3: {
                    message.instantaneousMatchedProfile = profiles$1.v1.Profile.decode(reader, reader.uint32());
                    break;
                  }
                  case 4: {
                    message.lookupExecutionResult = profiles$1.v1.LookupExecutionResult.decode(reader, reader.uint32());
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowProfileMatch) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowProfileMatch();
              if (object.orgId != null) {
                message.orgId = String(object.orgId);
              }
              if (object.workflowExecutionId != null) {
                message.workflowExecutionId = String(object.workflowExecutionId);
              }
              if (object.instantaneousMatchedProfile != null) {
                if (typeof object.instantaneousMatchedProfile !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackWorkflowProfileMatch.instantaneousMatchedProfile: object expected, but got " + (typeof object.instantaneousMatchedProfile));
                }
                message.instantaneousMatchedProfile = profiles$1.v1.Profile.fromObject(object.instantaneousMatchedProfile);
              }
              if (object.lookupExecutionResult != null) {
                if (typeof object.lookupExecutionResult !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackWorkflowProfileMatch.lookupExecutionResult: object expected, but got " + (typeof object.lookupExecutionResult));
                }
                message.lookupExecutionResult = profiles$1.v1.LookupExecutionResult.fromObject(object.lookupExecutionResult);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.orgId = "";
                object.workflowExecutionId = "";
                object.lookupExecutionResult = null;
              }
              let keys;
              if (message.orgId != null && message.hasOwnProperty("orgId")) {
                object.orgId = message.orgId;
              }
              if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
                object.workflowExecutionId = message.workflowExecutionId;
              }
              if (message.instantaneousMatchedProfile != null && message.hasOwnProperty("instantaneousMatchedProfile")) {
                object.instantaneousMatchedProfile = profiles$1.v1.Profile.toObject(message.instantaneousMatchedProfile, options);
              }
              if (message.lookupExecutionResult != null && message.hasOwnProperty("lookupExecutionResult")) {
                object.lookupExecutionResult = profiles$1.v1.LookupExecutionResult.toObject(message.lookupExecutionResult, options);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackWorkflowProfileMatch";
            }
          }

          TrackWorkflowProfileMatch.prototype.orgId = "";
          TrackWorkflowProfileMatch.prototype.workflowExecutionId = "";
          TrackWorkflowProfileMatch.prototype.instantaneousMatchedProfile = null;
          TrackWorkflowProfileMatch.prototype.lookupExecutionResult = null;

          return TrackWorkflowProfileMatch;
        })();

        TrackWorkflows.TrackWorkflowEvent = (() => {
          class TrackWorkflowEvent {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new TrackWorkflowEvent(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
                writer.uint32(10).string(message.orgId);
              }
              if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
                writer.uint32(18).string(message.workflowExecutionId);
              }
              if (message.eventData != null && Object.hasOwnProperty.call(message, "eventData")) {
                workflow_evaluation_results$1.v1.WorkflowEvent.encode(message.eventData, writer.uint32(26).fork()).ldelim();
              }
              if (message.formattedError != null && Object.hasOwnProperty.call(message, "formattedError")) {
                workflow_evaluation_results$1.v1.FormattedError.encode(message.formattedError, writer.uint32(34).fork()).ldelim();
              }
              if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
                writer.uint32(42).string(message.workflowId);
              }
              if (message.thirdPartyProfileId != null && Object.hasOwnProperty.call(message, "thirdPartyProfileId")) {
                writer.uint32(48).int64(message.thirdPartyProfileId);
              }
              if (message.eventTime != null && Object.hasOwnProperty.call(message, "eventTime")) {
                timing$1.v1.Timestamp.encode(message.eventTime, writer.uint32(58).fork()).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new TrackWorkflowEvent();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.orgId = reader.string();
                    break;
                  }
                  case 2: {
                    message.workflowExecutionId = reader.string();
                    break;
                  }
                  case 3: {
                    message.eventData = workflow_evaluation_results$1.v1.WorkflowEvent.decode(reader, reader.uint32());
                    break;
                  }
                  case 4: {
                    message.formattedError = workflow_evaluation_results$1.v1.FormattedError.decode(reader, reader.uint32());
                    break;
                  }
                  case 5: {
                    message.workflowId = reader.string();
                    break;
                  }
                  case 6: {
                    message.thirdPartyProfileId = reader.int64();
                    break;
                  }
                  case 7: {
                    message.eventTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowEvent) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackWorkflowEvent();
              if (object.orgId != null) {
                message.orgId = String(object.orgId);
              }
              if (object.workflowExecutionId != null) {
                message.workflowExecutionId = String(object.workflowExecutionId);
              }
              if (object.eventData != null) {
                if (typeof object.eventData !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackWorkflowEvent.eventData: object expected, but got " + (typeof object.eventData));
                }
                message.eventData = workflow_evaluation_results$1.v1.WorkflowEvent.fromObject(object.eventData);
              }
              if (object.formattedError != null) {
                if (typeof object.formattedError !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackWorkflowEvent.formattedError: object expected, but got " + (typeof object.formattedError));
                }
                message.formattedError = workflow_evaluation_results$1.v1.FormattedError.fromObject(object.formattedError);
              }
              if (object.workflowId != null) {
                message.workflowId = String(object.workflowId);
              }
              if (object.thirdPartyProfileId != null) {
                if ($util.Long) {
                  (message.thirdPartyProfileId = $util.Long.fromValue(object.thirdPartyProfileId)).unsigned = false;
                } else if (typeof object.thirdPartyProfileId === "string") {
                  message.thirdPartyProfileId = parseInt(object.thirdPartyProfileId, 10);
                } else if (typeof object.thirdPartyProfileId === "number") {
                  message.thirdPartyProfileId = object.thirdPartyProfileId;
                } else if (typeof object.thirdPartyProfileId === "object") {
                  message.thirdPartyProfileId = new $util.LongBits(object.thirdPartyProfileId.low >>> 0, object.thirdPartyProfileId.high >>> 0).toNumber();
                }
              }
              if (object.eventTime != null) {
                if (typeof object.eventTime !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackWorkflowEvent.eventTime: object expected, but got " + (typeof object.eventTime));
                }
                message.eventTime = timing$1.v1.Timestamp.fromObject(object.eventTime);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.orgId = "";
                object.workflowExecutionId = "";
                object.eventData = null;
                object.workflowId = "";
                object.eventTime = null;
              }
              let keys;
              if (message.orgId != null && message.hasOwnProperty("orgId")) {
                object.orgId = message.orgId;
              }
              if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
                object.workflowExecutionId = message.workflowExecutionId;
              }
              if (message.eventData != null && message.hasOwnProperty("eventData")) {
                object.eventData = workflow_evaluation_results$1.v1.WorkflowEvent.toObject(message.eventData, options);
              }
              if (message.formattedError != null && message.hasOwnProperty("formattedError")) {
                object.formattedError = workflow_evaluation_results$1.v1.FormattedError.toObject(message.formattedError, options);
              }
              if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
                object.workflowId = message.workflowId;
              }
              if (message.thirdPartyProfileId != null && message.hasOwnProperty("thirdPartyProfileId")) {
                object.thirdPartyProfileId = typeof message.thirdPartyProfileId === "number" ? (options.longs === String ? String(message.thirdPartyProfileId) : message.thirdPartyProfileId) : (options.longs === String ? $util.Long.prototype.toString.call(message.thirdPartyProfileId) : options.longs === Number ? new $util.LongBits(message.thirdPartyProfileId.low >>> 0, message.thirdPartyProfileId.high >>> 0).toNumber() : message.thirdPartyProfileId);
              }
              if (message.eventTime != null && message.hasOwnProperty("eventTime")) {
                object.eventTime = timing$1.v1.Timestamp.toObject(message.eventTime, options);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackWorkflowEvent";
            }
          }

          TrackWorkflowEvent.prototype.orgId = "";
          TrackWorkflowEvent.prototype.workflowExecutionId = "";
          TrackWorkflowEvent.prototype.eventData = null;
          TrackWorkflowEvent.prototype.formattedError = null;
          TrackWorkflowEvent.prototype.workflowId = "";
          TrackWorkflowEvent.prototype.thirdPartyProfileId = null;
          TrackWorkflowEvent.prototype.eventTime = null;

          return TrackWorkflowEvent;
        })();

        TrackWorkflows.TrackDestinationActionOutcomes = (() => {
          class TrackDestinationActionOutcomes {
            constructor(properties) {
              this.destinationActionOutcomes = [];
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new TrackDestinationActionOutcomes(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
                writer.uint32(10).string(message.orgId);
              }
              if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
                writer.uint32(18).string(message.workflowExecutionId);
              }
              if (message.destinationActionOutcomes != null && Object.hasOwnProperty.call(message, "destinationActionOutcomes")) {
                for (const element of message.destinationActionOutcomes) {
                  workflow_evaluation_results$1.v1.DestinationActionOutcome.encode(element, writer.uint32(26).fork()).ldelim();
                }
              }
              if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
                writer.uint32(34).string(message.workflowId);
              }
              if (message.thirdPartyProfileId != null && Object.hasOwnProperty.call(message, "thirdPartyProfileId")) {
                writer.uint32(40).int64(message.thirdPartyProfileId);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new TrackDestinationActionOutcomes();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.orgId = reader.string();
                    break;
                  }
                  case 2: {
                    message.workflowExecutionId = reader.string();
                    break;
                  }
                  case 3: {
                    if (!message.destinationActionOutcomes || !message.destinationActionOutcomes.length) {
                      message.destinationActionOutcomes = [];
                    }
                    message.destinationActionOutcomes.push(workflow_evaluation_results$1.v1.DestinationActionOutcome.decode(reader, reader.uint32()));
                    break;
                  }
                  case 4: {
                    message.workflowId = reader.string();
                    break;
                  }
                  case 5: {
                    message.thirdPartyProfileId = reader.int64();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackDestinationActionOutcomes) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackDestinationActionOutcomes();
              if (object.orgId != null) {
                message.orgId = String(object.orgId);
              }
              if (object.workflowExecutionId != null) {
                message.workflowExecutionId = String(object.workflowExecutionId);
              }
              if (object.destinationActionOutcomes) {
                if (!Array.isArray(object.destinationActionOutcomes)) {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackDestinationActionOutcomes.destinationActionOutcomes: array type expected, but got " + (typeof object.destinationActionOutcomes))
                }
                message.destinationActionOutcomes = new Array(object.destinationActionOutcomes.length);
                for (let i = 0; i < object.destinationActionOutcomes.length; ++i) {
                  if (typeof object.destinationActionOutcomes[i] !== "object") {
                    throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackDestinationActionOutcomes.destinationActionOutcomes: object expected, but got " + (typeof object.destinationActionOutcomes[i]));
                  }
                  message.destinationActionOutcomes[i] = workflow_evaluation_results$1.v1.DestinationActionOutcome.fromObject(object.destinationActionOutcomes[i]);
                }
              }
              if (object.workflowId != null) {
                message.workflowId = String(object.workflowId);
              }
              if (object.thirdPartyProfileId != null) {
                if ($util.Long) {
                  (message.thirdPartyProfileId = $util.Long.fromValue(object.thirdPartyProfileId)).unsigned = false;
                } else if (typeof object.thirdPartyProfileId === "string") {
                  message.thirdPartyProfileId = parseInt(object.thirdPartyProfileId, 10);
                } else if (typeof object.thirdPartyProfileId === "number") {
                  message.thirdPartyProfileId = object.thirdPartyProfileId;
                } else if (typeof object.thirdPartyProfileId === "object") {
                  message.thirdPartyProfileId = new $util.LongBits(object.thirdPartyProfileId.low >>> 0, object.thirdPartyProfileId.high >>> 0).toNumber();
                }
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
                object.destinationActionOutcomes = [];
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.orgId = "";
                object.workflowExecutionId = "";
                object.workflowId = "";
                if ($util.Long) {
                  let long = new $util.Long(0, 0, false)
                  object.thirdPartyProfileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else {
                  object.thirdPartyProfileId = options.longs === String ? "0" : 0;
                }
              }
              let keys;
              if (message.orgId != null && message.hasOwnProperty("orgId")) {
                object.orgId = message.orgId;
              }
              if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
                object.workflowExecutionId = message.workflowExecutionId;
              }
              if (message.destinationActionOutcomes && message.destinationActionOutcomes.length) {
                object.destinationActionOutcomes = new Array(message.destinationActionOutcomes.length);
                for (let i = 0; i < message.destinationActionOutcomes.length; ++i) {
                  object.destinationActionOutcomes[i] = workflow_evaluation_results$1.v1.DestinationActionOutcome.toObject(message.destinationActionOutcomes[i], options);
                }
              }
              if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
                object.workflowId = message.workflowId;
              }
              if (message.thirdPartyProfileId != null && message.hasOwnProperty("thirdPartyProfileId")) {
                object.thirdPartyProfileId = typeof message.thirdPartyProfileId === "number" ? (options.longs === String ? String(message.thirdPartyProfileId) : message.thirdPartyProfileId) : (options.longs === String ? $util.Long.prototype.toString.call(message.thirdPartyProfileId) : options.longs === Number ? new $util.LongBits(message.thirdPartyProfileId.low >>> 0, message.thirdPartyProfileId.high >>> 0).toNumber() : message.thirdPartyProfileId);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackDestinationActionOutcomes";
            }
          }

          TrackDestinationActionOutcomes.prototype.orgId = "";
          TrackDestinationActionOutcomes.prototype.workflowExecutionId = "";
          TrackDestinationActionOutcomes.prototype.destinationActionOutcomes = $util.emptyArray;
          TrackDestinationActionOutcomes.prototype.workflowId = "";
          TrackDestinationActionOutcomes.prototype.thirdPartyProfileId = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

          return TrackDestinationActionOutcomes;
        })();

        TrackWorkflows.TrackExpectedActionEndTime = (() => {
          class TrackExpectedActionEndTime {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new TrackExpectedActionEndTime(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
                writer.uint32(10).string(message.workflowExecutionId);
              }
              if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId")) {
                writer.uint32(18).string(message.nodeId);
              }
              if (message.actionExecutionNumber != null && Object.hasOwnProperty.call(message, "actionExecutionNumber")) {
                writer.uint32(24).int64(message.actionExecutionNumber);
              }
              if (message.expectedActionEndTime != null && Object.hasOwnProperty.call(message, "expectedActionEndTime")) {
                timing$1.v1.Timestamp.encode(message.expectedActionEndTime, writer.uint32(34).fork()).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new TrackExpectedActionEndTime();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.workflowExecutionId = reader.string();
                    break;
                  }
                  case 2: {
                    message.nodeId = reader.string();
                    break;
                  }
                  case 3: {
                    message.actionExecutionNumber = reader.int64();
                    break;
                  }
                  case 4: {
                    message.expectedActionEndTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackExpectedActionEndTime) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackExpectedActionEndTime();
              if (object.workflowExecutionId != null) {
                message.workflowExecutionId = String(object.workflowExecutionId);
              }
              if (object.nodeId != null) {
                message.nodeId = String(object.nodeId);
              }
              if (object.actionExecutionNumber != null) {
                if ($util.Long) {
                  (message.actionExecutionNumber = $util.Long.fromValue(object.actionExecutionNumber)).unsigned = false;
                } else if (typeof object.actionExecutionNumber === "string") {
                  message.actionExecutionNumber = parseInt(object.actionExecutionNumber, 10);
                } else if (typeof object.actionExecutionNumber === "number") {
                  message.actionExecutionNumber = object.actionExecutionNumber;
                } else if (typeof object.actionExecutionNumber === "object") {
                  message.actionExecutionNumber = new $util.LongBits(object.actionExecutionNumber.low >>> 0, object.actionExecutionNumber.high >>> 0).toNumber();
                }
              }
              if (object.expectedActionEndTime != null) {
                if (typeof object.expectedActionEndTime !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackExpectedActionEndTime.expectedActionEndTime: object expected, but got " + (typeof object.expectedActionEndTime));
                }
                message.expectedActionEndTime = timing$1.v1.Timestamp.fromObject(object.expectedActionEndTime);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.workflowExecutionId = "";
                object.nodeId = "";
                if ($util.Long) {
                  let long = new $util.Long(0, 0, false)
                  object.actionExecutionNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else {
                  object.actionExecutionNumber = options.longs === String ? "0" : 0;
                }
                object.expectedActionEndTime = null;
              }
              let keys;
              if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
                object.workflowExecutionId = message.workflowExecutionId;
              }
              if (message.nodeId != null && message.hasOwnProperty("nodeId")) {
                object.nodeId = message.nodeId;
              }
              if (message.actionExecutionNumber != null && message.hasOwnProperty("actionExecutionNumber")) {
                object.actionExecutionNumber = typeof message.actionExecutionNumber === "number" ? (options.longs === String ? String(message.actionExecutionNumber) : message.actionExecutionNumber) : (options.longs === String ? $util.Long.prototype.toString.call(message.actionExecutionNumber) : options.longs === Number ? new $util.LongBits(message.actionExecutionNumber.low >>> 0, message.actionExecutionNumber.high >>> 0).toNumber() : message.actionExecutionNumber);
              }
              if (message.expectedActionEndTime != null && message.hasOwnProperty("expectedActionEndTime")) {
                object.expectedActionEndTime = timing$1.v1.Timestamp.toObject(message.expectedActionEndTime, options);
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackExpectedActionEndTime";
            }
          }

          TrackExpectedActionEndTime.prototype.workflowExecutionId = "";
          TrackExpectedActionEndTime.prototype.nodeId = "";
          TrackExpectedActionEndTime.prototype.actionExecutionNumber = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
          TrackExpectedActionEndTime.prototype.expectedActionEndTime = null;

          return TrackExpectedActionEndTime;
        })();

        TrackWorkflows.TrackActionCancellation = (() => {
          class TrackActionCancellation {
            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new TrackActionCancellation(properties)
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
                writer.uint32(10).string(message.workflowExecutionId);
              }
              if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId")) {
                writer.uint32(18).string(message.nodeId);
              }
              if (message.actionEndTime != null && Object.hasOwnProperty.call(message, "actionEndTime")) {
                timing$1.v1.Timestamp.encode(message.actionEndTime, writer.uint32(26).fork()).ldelim();
              }
              if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
                writer.uint32(34).string(message.orgId);
              }
              if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
                writer.uint32(42).string(message.workflowId);
              }
              if (message.thirdPartyProfileId != null && Object.hasOwnProperty.call(message, "thirdPartyProfileId")) {
                writer.uint32(48).int64(message.thirdPartyProfileId);
              }
              if (message.isFilter != null && Object.hasOwnProperty.call(message, "isFilter")) {
                writer.uint32(56).bool(message.isFilter);
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new TrackActionCancellation();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.workflowExecutionId = reader.string();
                    break;
                  }
                  case 2: {
                    message.nodeId = reader.string();
                    break;
                  }
                  case 3: {
                    message.actionEndTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                    break;
                  }
                  case 4: {
                    message.orgId = reader.string();
                    break;
                  }
                  case 5: {
                    message.workflowId = reader.string();
                    break;
                  }
                  case 6: {
                    message.thirdPartyProfileId = reader.int64();
                    break;
                  }
                  case 7: {
                    message.isFilter = reader.bool();
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (object instanceof $root.destinationactions.morf.v1.TrackWorkflows.TrackActionCancellation) {
                return object;
              }
              const message = new $root.destinationactions.morf.v1.TrackWorkflows.TrackActionCancellation();
              if (object.workflowExecutionId != null) {
                message.workflowExecutionId = String(object.workflowExecutionId);
              }
              if (object.nodeId != null) {
                message.nodeId = String(object.nodeId);
              }
              if (object.actionEndTime != null) {
                if (typeof object.actionEndTime !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.TrackWorkflows.TrackActionCancellation.actionEndTime: object expected, but got " + (typeof object.actionEndTime));
                }
                message.actionEndTime = timing$1.v1.Timestamp.fromObject(object.actionEndTime);
              }
              if (object.orgId != null) {
                message.orgId = String(object.orgId);
              }
              if (object.workflowId != null) {
                message.workflowId = String(object.workflowId);
              }
              if (object.thirdPartyProfileId != null) {
                if ($util.Long) {
                  (message.thirdPartyProfileId = $util.Long.fromValue(object.thirdPartyProfileId)).unsigned = false;
                } else if (typeof object.thirdPartyProfileId === "string") {
                  message.thirdPartyProfileId = parseInt(object.thirdPartyProfileId, 10);
                } else if (typeof object.thirdPartyProfileId === "number") {
                  message.thirdPartyProfileId = object.thirdPartyProfileId;
                } else if (typeof object.thirdPartyProfileId === "object") {
                  message.thirdPartyProfileId = new $util.LongBits(object.thirdPartyProfileId.low >>> 0, object.thirdPartyProfileId.high >>> 0).toNumber();
                }
              }
              if (object.isFilter != null) {
                message.isFilter = Boolean(object.isFilter);
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.workflowExecutionId = "";
                object.nodeId = "";
                object.actionEndTime = null;
                object.orgId = "";
                object.workflowId = "";
                if ($util.Long) {
                  let long = new $util.Long(0, 0, false)
                  object.thirdPartyProfileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else {
                  object.thirdPartyProfileId = options.longs === String ? "0" : 0;
                }
                object.isFilter = false;
              }
              let keys;
              if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
                object.workflowExecutionId = message.workflowExecutionId;
              }
              if (message.nodeId != null && message.hasOwnProperty("nodeId")) {
                object.nodeId = message.nodeId;
              }
              if (message.actionEndTime != null && message.hasOwnProperty("actionEndTime")) {
                object.actionEndTime = timing$1.v1.Timestamp.toObject(message.actionEndTime, options);
              }
              if (message.orgId != null && message.hasOwnProperty("orgId")) {
                object.orgId = message.orgId;
              }
              if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
                object.workflowId = message.workflowId;
              }
              if (message.thirdPartyProfileId != null && message.hasOwnProperty("thirdPartyProfileId")) {
                object.thirdPartyProfileId = typeof message.thirdPartyProfileId === "number" ? (options.longs === String ? String(message.thirdPartyProfileId) : message.thirdPartyProfileId) : (options.longs === String ? $util.Long.prototype.toString.call(message.thirdPartyProfileId) : options.longs === Number ? new $util.LongBits(message.thirdPartyProfileId.low >>> 0, message.thirdPartyProfileId.high >>> 0).toNumber() : message.thirdPartyProfileId);
              }
              if (message.isFilter != null && message.hasOwnProperty("isFilter")) {
                object.isFilter = message.isFilter;
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
              return typeUrlPrefix + "destinationactions.morf.v1.TrackWorkflows.TrackActionCancellation";
            }
          }

          TrackActionCancellation.prototype.workflowExecutionId = "";
          TrackActionCancellation.prototype.nodeId = "";
          TrackActionCancellation.prototype.actionEndTime = null;
          TrackActionCancellation.prototype.orgId = "";
          TrackActionCancellation.prototype.workflowId = "";
          TrackActionCancellation.prototype.thirdPartyProfileId = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
          TrackActionCancellation.prototype.isFilter = false;

          return TrackActionCancellation;
        })();

        return TrackWorkflows;
      })();

      v1.Filter = (() => {
        class Filter {
          constructor(properties) {
            this.extraFetchedDataInputs = [];
            this.extraFetchedDataRefs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Filter(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload")) {
              event_payload$1.v2.EventPayload.encode(message.payload, writer.uint32(10).fork()).ldelim();
            }
            if (message.profileIndex != null && Object.hasOwnProperty.call(message, "profileIndex")) {
              profiles$1.v1.ProfileIndex.encode(message.profileIndex, writer.uint32(18).fork()).ldelim();
            }
            if (message.celExpressionStr != null && Object.hasOwnProperty.call(message, "celExpressionStr")) {
              writer.uint32(34).string(message.celExpressionStr);
            }
            if (message.organizationId != null && Object.hasOwnProperty.call(message, "organizationId")) {
              writer.uint32(42).string(message.organizationId);
            }
            if (message.extraFetchedDataInputs != null && Object.hasOwnProperty.call(message, "extraFetchedDataInputs")) {
              for (const element of message.extraFetchedDataInputs) {
                workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.encode(element, writer.uint32(50).fork()).ldelim();
              }
            }
            if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
              writer.uint32(58).string(message.eventSnapshotId);
            }
            if (message.extraFetchedDataRefs != null && Object.hasOwnProperty.call(message, "extraFetchedDataRefs")) {
              for (const element of message.extraFetchedDataRefs) {
                workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.encode(element, writer.uint32(66).fork()).ldelim();
              }
            }
            if (message.parentActionError != null && Object.hasOwnProperty.call(message, "parentActionError")) {
              workflow_evaluation_results$1.v1.ActionError.encode(message.parentActionError, writer.uint32(74).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Filter();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.payload = event_payload$1.v2.EventPayload.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.profileIndex = profiles$1.v1.ProfileIndex.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  message.celExpressionStr = reader.string();
                  break;
                }
                case 5: {
                  message.organizationId = reader.string();
                  break;
                }
                case 6: {
                  if (!message.extraFetchedDataInputs || !message.extraFetchedDataInputs.length) {
                    message.extraFetchedDataInputs = [];
                  }
                  message.extraFetchedDataInputs.push(workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.decode(reader, reader.uint32()));
                  break;
                }
                case 7: {
                  message.eventSnapshotId = reader.string();
                  break;
                }
                case 8: {
                  if (!message.extraFetchedDataRefs || !message.extraFetchedDataRefs.length) {
                    message.extraFetchedDataRefs = [];
                  }
                  message.extraFetchedDataRefs.push(workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.decode(reader, reader.uint32()));
                  break;
                }
                case 9: {
                  message.parentActionError = workflow_evaluation_results$1.v1.ActionError.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.Filter) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.Filter();
            if (object.payload != null) {
              if (typeof object.payload !== "object") {
                throw new TypeError(".destinationactions.morf.v1.Filter.payload: object expected, but got " + (typeof object.payload));
              }
              message.payload = event_payload$1.v2.EventPayload.fromObject(object.payload);
            }
            if (object.profileIndex != null) {
              if (typeof object.profileIndex !== "object") {
                throw new TypeError(".destinationactions.morf.v1.Filter.profileIndex: object expected, but got " + (typeof object.profileIndex));
              }
              message.profileIndex = profiles$1.v1.ProfileIndex.fromObject(object.profileIndex);
            }
            if (object.celExpressionStr != null) {
              message.celExpressionStr = String(object.celExpressionStr);
            }
            if (object.organizationId != null) {
              message.organizationId = String(object.organizationId);
            }
            if (object.extraFetchedDataInputs) {
              if (!Array.isArray(object.extraFetchedDataInputs)) {
                throw new TypeError(".destinationactions.morf.v1.Filter.extraFetchedDataInputs: array type expected, but got " + (typeof object.extraFetchedDataInputs))
              }
              message.extraFetchedDataInputs = new Array(object.extraFetchedDataInputs.length);
              for (let i = 0; i < object.extraFetchedDataInputs.length; ++i) {
                if (typeof object.extraFetchedDataInputs[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.Filter.extraFetchedDataInputs: object expected, but got " + (typeof object.extraFetchedDataInputs[i]));
                }
                message.extraFetchedDataInputs[i] = workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.fromObject(object.extraFetchedDataInputs[i]);
              }
            }
            if (object.eventSnapshotId != null) {
              message.eventSnapshotId = String(object.eventSnapshotId);
            }
            if (object.extraFetchedDataRefs) {
              if (!Array.isArray(object.extraFetchedDataRefs)) {
                throw new TypeError(".destinationactions.morf.v1.Filter.extraFetchedDataRefs: array type expected, but got " + (typeof object.extraFetchedDataRefs))
              }
              message.extraFetchedDataRefs = new Array(object.extraFetchedDataRefs.length);
              for (let i = 0; i < object.extraFetchedDataRefs.length; ++i) {
                if (typeof object.extraFetchedDataRefs[i] !== "object") {
                  throw new TypeError(".destinationactions.morf.v1.Filter.extraFetchedDataRefs: object expected, but got " + (typeof object.extraFetchedDataRefs[i]));
                }
                message.extraFetchedDataRefs[i] = workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.fromObject(object.extraFetchedDataRefs[i]);
              }
            }
            if (object.parentActionError != null) {
              if (typeof object.parentActionError !== "object") {
                throw new TypeError(".destinationactions.morf.v1.Filter.parentActionError: object expected, but got " + (typeof object.parentActionError));
              }
              message.parentActionError = workflow_evaluation_results$1.v1.ActionError.fromObject(object.parentActionError);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.extraFetchedDataInputs = [];
              object.extraFetchedDataRefs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.payload = null;
              object.profileIndex = null;
              object.celExpressionStr = "";
              object.organizationId = "";
              object.eventSnapshotId = "";
            }
            let keys;
            if (message.payload != null && message.hasOwnProperty("payload")) {
              object.payload = event_payload$1.v2.EventPayload.toObject(message.payload, options);
            }
            if (message.profileIndex != null && message.hasOwnProperty("profileIndex")) {
              object.profileIndex = profiles$1.v1.ProfileIndex.toObject(message.profileIndex, options);
            }
            if (message.celExpressionStr != null && message.hasOwnProperty("celExpressionStr")) {
              object.celExpressionStr = message.celExpressionStr;
            }
            if (message.organizationId != null && message.hasOwnProperty("organizationId")) {
              object.organizationId = message.organizationId;
            }
            if (message.extraFetchedDataInputs && message.extraFetchedDataInputs.length) {
              object.extraFetchedDataInputs = new Array(message.extraFetchedDataInputs.length);
              for (let i = 0; i < message.extraFetchedDataInputs.length; ++i) {
                object.extraFetchedDataInputs[i] = workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.toObject(message.extraFetchedDataInputs[i], options);
              }
            }
            if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
              object.eventSnapshotId = message.eventSnapshotId;
            }
            if (message.extraFetchedDataRefs && message.extraFetchedDataRefs.length) {
              object.extraFetchedDataRefs = new Array(message.extraFetchedDataRefs.length);
              for (let i = 0; i < message.extraFetchedDataRefs.length; ++i) {
                object.extraFetchedDataRefs[i] = workflow_extra_fetched_data$1.v1.ExtraFetchedDataRef.toObject(message.extraFetchedDataRefs[i], options);
              }
            }
            if (message.parentActionError != null && message.hasOwnProperty("parentActionError")) {
              object.parentActionError = workflow_evaluation_results$1.v1.ActionError.toObject(message.parentActionError, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.Filter";
          }
        }

        Filter.prototype.payload = null;
        Filter.prototype.profileIndex = null;
        Filter.prototype.celExpressionStr = "";
        Filter.prototype.organizationId = "";
        Filter.prototype.extraFetchedDataInputs = $util.emptyArray;
        Filter.prototype.eventSnapshotId = "";
        Filter.prototype.extraFetchedDataRefs = $util.emptyArray;
        Filter.prototype.parentActionError = null;

        return Filter;
      })();

      v1.EvaluatedFilter = (() => {
        class EvaluatedFilter {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EvaluatedFilter(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.shouldContinue != null && Object.hasOwnProperty.call(message, "shouldContinue")) {
              writer.uint32(8).bool(message.shouldContinue);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              workflow_evaluation_results$1.v1.DestinationActionResult.encode(message.result, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EvaluatedFilter();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.shouldContinue = reader.bool();
                  break;
                }
                case 2: {
                  message.result = workflow_evaluation_results$1.v1.DestinationActionResult.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.EvaluatedFilter) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.EvaluatedFilter();
            if (object.shouldContinue != null) {
              message.shouldContinue = Boolean(object.shouldContinue);
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".destinationactions.morf.v1.EvaluatedFilter.result: object expected, but got " + (typeof object.result));
              }
              message.result = workflow_evaluation_results$1.v1.DestinationActionResult.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.shouldContinue = false;
              object.result = null;
            }
            let keys;
            if (message.shouldContinue != null && message.hasOwnProperty("shouldContinue")) {
              object.shouldContinue = message.shouldContinue;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = workflow_evaluation_results$1.v1.DestinationActionResult.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.EvaluatedFilter";
          }
        }

        EvaluatedFilter.prototype.shouldContinue = false;
        EvaluatedFilter.prototype.result = null;

        return EvaluatedFilter;
      })();

      v1.RefreshEventPayload = (() => {
        class RefreshEventPayload {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new RefreshEventPayload(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.webhookId != null && Object.hasOwnProperty.call(message, "webhookId")) {
              writer.uint32(10).string(message.webhookId);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new RefreshEventPayload();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.webhookId = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.RefreshEventPayload) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.RefreshEventPayload();
            if (object.webhookId != null) {
              message.webhookId = String(object.webhookId);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.webhookId = "";
            }
            let keys;
            if (message.webhookId != null && message.hasOwnProperty("webhookId")) {
              object.webhookId = message.webhookId;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.RefreshEventPayload";
          }
        }

        RefreshEventPayload.prototype.webhookId = "";

        return RefreshEventPayload;
      })();

      v1.Pause = (() => {
        class Pause {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Pause(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.timestamps != null && Object.hasOwnProperty.call(message, "timestamps")) {
              workflow_parameters$1.v1.DestinationActionParameterSources.encode(message.timestamps, writer.uint32(10).fork()).ldelim();
            }
            if (message.refreshEventPayload != null && Object.hasOwnProperty.call(message, "refreshEventPayload")) {
              $root.destinationactions.morf.v1.RefreshEventPayload.encode(message.refreshEventPayload, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Pause();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.timestamps = workflow_parameters$1.v1.DestinationActionParameterSources.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.refreshEventPayload = $root.destinationactions.morf.v1.RefreshEventPayload.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.Pause) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.Pause();
            if (object.timestamps != null) {
              if (typeof object.timestamps !== "object") {
                throw new TypeError(".destinationactions.morf.v1.Pause.timestamps: object expected, but got " + (typeof object.timestamps));
              }
              message.timestamps = workflow_parameters$1.v1.DestinationActionParameterSources.fromObject(object.timestamps);
            }
            if (object.refreshEventPayload != null) {
              if (typeof object.refreshEventPayload !== "object") {
                throw new TypeError(".destinationactions.morf.v1.Pause.refreshEventPayload: object expected, but got " + (typeof object.refreshEventPayload));
              }
              message.refreshEventPayload = $root.destinationactions.morf.v1.RefreshEventPayload.fromObject(object.refreshEventPayload);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.timestamps = null;
            }
            let keys;
            if (message.timestamps != null && message.hasOwnProperty("timestamps")) {
              object.timestamps = workflow_parameters$1.v1.DestinationActionParameterSources.toObject(message.timestamps, options);
            }
            if (message.refreshEventPayload != null && message.hasOwnProperty("refreshEventPayload")) {
              object.refreshEventPayload = $root.destinationactions.morf.v1.RefreshEventPayload.toObject(message.refreshEventPayload, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.Pause";
          }
        }

        Pause.prototype.timestamps = null;
        Pause.prototype.refreshEventPayload = null;

        return Pause;
      })();

      v1.RefreshWorkflow = (() => {
        class RefreshWorkflow {
          get workflowPayload() {
            for (const key of ["webhookId"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set workflowPayload(name) {
            for (const key of ["webhookId"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new RefreshWorkflow(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
              writer.uint32(10).string(message.orgId);
            }
            if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
              writer.uint32(18).string(message.workflowId);
            }
            if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
              writer.uint32(26).string(message.eventSnapshotId);
            }
            if (message.webhookId != null && Object.hasOwnProperty.call(message, "webhookId")) {
              writer.uint32(34).string(message.webhookId);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new RefreshWorkflow();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.orgId = reader.string();
                  break;
                }
                case 2: {
                  message.workflowId = reader.string();
                  break;
                }
                case 3: {
                  message.eventSnapshotId = reader.string();
                  break;
                }
                case 4: {
                  message.webhookId = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.morf.v1.RefreshWorkflow) {
              return object;
            }
            const message = new $root.destinationactions.morf.v1.RefreshWorkflow();
            if (object.orgId != null) {
              message.orgId = String(object.orgId);
            }
            if (object.workflowId != null) {
              message.workflowId = String(object.workflowId);
            }
            if (object.eventSnapshotId != null) {
              message.eventSnapshotId = String(object.eventSnapshotId);
            }
            if (object.webhookId != null) {
              message.webhookId = String(object.webhookId);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.orgId = "";
              object.workflowId = "";
              object.eventSnapshotId = "";
            }
            let keys;
            if (message.orgId != null && message.hasOwnProperty("orgId")) {
              object.orgId = message.orgId;
            }
            if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
              object.workflowId = message.workflowId;
            }
            if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
              object.eventSnapshotId = message.eventSnapshotId;
            }
            if (message.webhookId != null && message.hasOwnProperty("webhookId")) {
              object.webhookId = message.webhookId;
              if (options.oneofs) {
                object.workflowPayload = "webhookId";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.morf.v1.RefreshWorkflow";
          }
        }

        RefreshWorkflow.prototype.orgId = "";
        RefreshWorkflow.prototype.workflowId = "";
        RefreshWorkflow.prototype.eventSnapshotId = "";
        RefreshWorkflow.prototype.webhookId = "";

        return RefreshWorkflow;
      })();

      return v1;
    })();

    return morf;
  })();

  return destinationactions;
})();

export const destinationactions = $root.destinationactions;
