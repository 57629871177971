"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.object_category = (() => {
  const object_category = {};
  object_category.v1 = (() => {
    const v1 = {};
    v1.ObjectCategory = (() => {
      class ObjectCategory {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectCategory(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.category != null && Object.hasOwnProperty.call(message, "category")) {
            writer.uint32(8).int32(message.category);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectCategory();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.category = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_category.v1.ObjectCategory) {
            return object;
          }
          const message = new $root.object_category.v1.ObjectCategory();
          if (object.category != null) {
            switch (object.category) {
              case "UNSPECIFIED_OBJECT_CATEGORY":
              case 0: {
                message.category = 0;
                break;
              }
              case "FORM":
              case 1: {
                message.category = 1;
                break;
              }
              case "APPOINTMENT":
              case 2: {
                message.category = 2;
                break;
              }
              case "PATIENT":
              case 3: {
                message.category = 3;
                break;
              }
              case "LAB_ORDER":
              case 4: {
                message.category = 4;
                break;
              }
              default: {
                if (typeof object.category == "number") {
                  message.category = object.category;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.category = options.enums === String ? "UNSPECIFIED_OBJECT_CATEGORY" : 0
          }
          let keys;
          if (message.category != null && message.hasOwnProperty("category")) {
            object.category = options.enums === String ? $root.object_category.v1.ObjectCategory.Category[message.category] === undefined ? message.category : $root.object_category.v1.ObjectCategory.Category[message.category] : message.category;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "object_category.v1.ObjectCategory";
        }
      }

      ObjectCategory.prototype.category = 0;
      ObjectCategory.Category = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED_OBJECT_CATEGORY"] = 0;
        values[valuesById[1] = "FORM"] = 1;
        values[valuesById[2] = "APPOINTMENT"] = 2;
        values[valuesById[3] = "PATIENT"] = 3;
        values[valuesById[4] = "LAB_ORDER"] = 4;
        return values;
      })();

      return ObjectCategory;
    })();

    return v1;
  })();

  return object_category;
})();

export const object_category = $root.object_category;
