"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.permissions = (() => {
  const permissions = {};
  permissions.v1 = (() => {
    const v1 = {};
    v1.Permission = (() => {
      class Permission {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Permission(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.permission != null && Object.hasOwnProperty.call(message, "permission")) {
            writer.uint32(8).int32(message.permission);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Permission();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.permission = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.permissions.v1.Permission) {
            return object;
          }
          const message = new $root.permissions.v1.Permission();
          if (object.permission != null) {
            switch (object.permission) {
              case "UNSPECIFIED":
              case 0: {
                message.permission = 0;
                break;
              }
              case "ACCOUNT_READ":
              case 1: {
                message.permission = 1;
                break;
              }
              case "ACCOUNT_EDIT":
              case 2: {
                message.permission = 2;
                break;
              }
              case "ORGANIZATION_READ":
              case 3: {
                message.permission = 3;
                break;
              }
              case "CONFIGURATION_READ":
              case 4: {
                message.permission = 4;
                break;
              }
              case "CONFIGURATION_EDIT":
              case 5: {
                message.permission = 5;
                break;
              }
              case "API_KEY_READ":
              case 6: {
                message.permission = 6;
                break;
              }
              case "API_KEY_EDIT":
              case 7: {
                message.permission = 7;
                break;
              }
              case "PII_READ":
              case 8: {
                message.permission = 8;
                break;
              }
              case "HI_READ":
              case 9: {
                message.permission = 9;
                break;
              }
              case "ID_READ":
              case 10: {
                message.permission = 10;
                break;
              }
              case "PATIENT_READ":
              case 11: {
                message.permission = 11;
                break;
              }
              case "PATIENT_EDIT":
              case 12: {
                message.permission = 12;
                break;
              }
              case "ACTIVITY_READ":
              case 13: {
                message.permission = 13;
                break;
              }
              case "PROVIDER_READ":
              case 14: {
                message.permission = 14;
                break;
              }
              case "PROVIDER_EDIT":
              case 15: {
                message.permission = 15;
                break;
              }
              case "WORKFLOW_RETRY":
              case 16: {
                message.permission = 16;
                break;
              }
              case "THIRD_PARTY_SECRET_EDIT":
              case 17: {
                message.permission = 17;
                break;
              }
              case "SCHEDULE_APPT":
              case 18: {
                message.permission = 18;
                break;
              }
              case "DOMAIN_READ":
              case 19: {
                message.permission = 19;
                break;
              }
              case "DOMAIN_EDIT":
              case 20: {
                message.permission = 20;
                break;
              }
              default: {
                if (typeof object.permission == "number") {
                  message.permission = object.permission;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.permission = options.enums === String ? "UNSPECIFIED" : 0
          }
          let keys;
          if (message.permission != null && message.hasOwnProperty("permission")) {
            object.permission = options.enums === String ? $root.permissions.v1.Permission.Value[message.permission] === undefined ? message.permission : $root.permissions.v1.Permission.Value[message.permission] : message.permission;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "permissions.v1.Permission";
        }
      }

      Permission.prototype.permission = 0;
      Permission.Value = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED"] = 0;
        values[valuesById[1] = "ACCOUNT_READ"] = 1;
        values[valuesById[2] = "ACCOUNT_EDIT"] = 2;
        values[valuesById[3] = "ORGANIZATION_READ"] = 3;
        values[valuesById[4] = "CONFIGURATION_READ"] = 4;
        values[valuesById[5] = "CONFIGURATION_EDIT"] = 5;
        values[valuesById[6] = "API_KEY_READ"] = 6;
        values[valuesById[7] = "API_KEY_EDIT"] = 7;
        values[valuesById[8] = "PII_READ"] = 8;
        values[valuesById[9] = "HI_READ"] = 9;
        values[valuesById[10] = "ID_READ"] = 10;
        values[valuesById[11] = "PATIENT_READ"] = 11;
        values[valuesById[12] = "PATIENT_EDIT"] = 12;
        values[valuesById[13] = "ACTIVITY_READ"] = 13;
        values[valuesById[14] = "PROVIDER_READ"] = 14;
        values[valuesById[15] = "PROVIDER_EDIT"] = 15;
        values[valuesById[16] = "WORKFLOW_RETRY"] = 16;
        values[valuesById[17] = "THIRD_PARTY_SECRET_EDIT"] = 17;
        values[valuesById[18] = "SCHEDULE_APPT"] = 18;
        values[valuesById[19] = "DOMAIN_READ"] = 19;
        values[valuesById[20] = "DOMAIN_EDIT"] = 20;
        return values;
      })();

      return Permission;
    })();

    v1.PermissionSet = (() => {
      class PermissionSet {
        constructor(properties) {
          this.permissions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PermissionSet(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions")) {
            for (const element of message.permissions) {
              $root.permissions.v1.Permission.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PermissionSet();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.permissions || !message.permissions.length) {
                  message.permissions = [];
                }
                message.permissions.push($root.permissions.v1.Permission.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.permissions.v1.PermissionSet) {
            return object;
          }
          const message = new $root.permissions.v1.PermissionSet();
          if (object.permissions) {
            if (!Array.isArray(object.permissions)) {
              throw new TypeError(".permissions.v1.PermissionSet.permissions: array type expected, but got " + (typeof object.permissions))
            }
            message.permissions = new Array(object.permissions.length);
            for (let i = 0; i < object.permissions.length; ++i) {
              if (typeof object.permissions[i] !== "object") {
                throw new TypeError(".permissions.v1.PermissionSet.permissions: object expected, but got " + (typeof object.permissions[i]));
              }
              message.permissions[i] = $root.permissions.v1.Permission.fromObject(object.permissions[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.permissions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.permissions && message.permissions.length) {
            object.permissions = new Array(message.permissions.length);
            for (let i = 0; i < message.permissions.length; ++i) {
              object.permissions[i] = $root.permissions.v1.Permission.toObject(message.permissions[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "permissions.v1.PermissionSet";
        }
      }

      PermissionSet.prototype.permissions = $util.emptyArray;

      return PermissionSet;
    })();

    return v1;
  })();

  return permissions;
})();

export const permissions = $root.permissions;
