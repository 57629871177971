import { styled } from '@morf/theming';

export const StyledUserImage = styled.img<
  { isActive: boolean } & React.ImgHTMLAttributes<HTMLImageElement>
>`
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid transparent;
  border-radius: ${(props) => props.theme.borderRadius['full']};
  ${(props) =>
    props.isActive && `border-color: ${props.theme.colors.ui.divider}`};
`;
