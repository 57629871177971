"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.timing = (() => {
  const timing = {};
  timing.v1 = (() => {
    const v1 = {};
    v1.Timestamp = (() => {
      class Timestamp {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Timestamp(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds")) {
            writer.uint32(8).int64(message.seconds);
          }
          if (message.nanoseconds != null && Object.hasOwnProperty.call(message, "nanoseconds")) {
            writer.uint32(16).int32(message.nanoseconds);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Timestamp();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.seconds = reader.int64();
                break;
              }
              case 2: {
                message.nanoseconds = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.timing.v1.Timestamp) {
            return object;
          }
          const message = new $root.timing.v1.Timestamp();
          if (object.seconds != null) {
            if ($util.Long) {
              (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
            } else if (typeof object.seconds === "string") {
              message.seconds = parseInt(object.seconds, 10);
            } else if (typeof object.seconds === "number") {
              message.seconds = object.seconds;
            } else if (typeof object.seconds === "object") {
              message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
            }
          }
          if (object.nanoseconds != null) {
            message.nanoseconds = object.nanoseconds | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.seconds = options.longs === String ? "0" : 0;
            }
            object.nanoseconds = 0;
          }
          let keys;
          if (message.seconds != null && message.hasOwnProperty("seconds")) {
            object.seconds = typeof message.seconds === "number" ? (options.longs === String ? String(message.seconds) : message.seconds) : (options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds);
          }
          if (message.nanoseconds != null && message.hasOwnProperty("nanoseconds")) {
            object.nanoseconds = message.nanoseconds;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "timing.v1.Timestamp";
        }
      }

      Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Timestamp.prototype.nanoseconds = 0;

      return Timestamp;
    })();

    v1.Duration = (() => {
      class Duration {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Duration(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.nanoseconds != null && Object.hasOwnProperty.call(message, "nanoseconds")) {
            writer.uint32(8).int64(message.nanoseconds);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Duration();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.nanoseconds = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.timing.v1.Duration) {
            return object;
          }
          const message = new $root.timing.v1.Duration();
          if (object.nanoseconds != null) {
            if ($util.Long) {
              (message.nanoseconds = $util.Long.fromValue(object.nanoseconds)).unsigned = false;
            } else if (typeof object.nanoseconds === "string") {
              message.nanoseconds = parseInt(object.nanoseconds, 10);
            } else if (typeof object.nanoseconds === "number") {
              message.nanoseconds = object.nanoseconds;
            } else if (typeof object.nanoseconds === "object") {
              message.nanoseconds = new $util.LongBits(object.nanoseconds.low >>> 0, object.nanoseconds.high >>> 0).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.nanoseconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.nanoseconds = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.nanoseconds != null && message.hasOwnProperty("nanoseconds")) {
            object.nanoseconds = typeof message.nanoseconds === "number" ? (options.longs === String ? String(message.nanoseconds) : message.nanoseconds) : (options.longs === String ? $util.Long.prototype.toString.call(message.nanoseconds) : options.longs === Number ? new $util.LongBits(message.nanoseconds.low >>> 0, message.nanoseconds.high >>> 0).toNumber() : message.nanoseconds);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "timing.v1.Duration";
        }
      }

      Duration.prototype.nanoseconds = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      return Duration;
    })();

    v1.Timing = (() => {
      class Timing {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Timing(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            $root.timing.v1.Timestamp.encode(message.createdAt, writer.uint32(10).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            $root.timing.v1.Timestamp.encode(message.updatedAt, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Timing();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.createdAt = $root.timing.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.updatedAt = $root.timing.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.timing.v1.Timing) {
            return object;
          }
          const message = new $root.timing.v1.Timing();
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".timing.v1.Timing.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = $root.timing.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".timing.v1.Timing.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = $root.timing.v1.Timestamp.fromObject(object.updatedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = $root.timing.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = $root.timing.v1.Timestamp.toObject(message.updatedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "timing.v1.Timing";
        }
      }

      Timing.prototype.createdAt = null;
      Timing.prototype.updatedAt = null;

      return Timing;
    })();

    return v1;
  })();

  return timing;
})();

export const timing = $root.timing;
