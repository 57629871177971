import moment, { Moment } from 'moment';
import { timing } from '@morf/proto/timing_v1_ts_proto';

export const convertTimestampToMoment = (
  timestamp: timing.v1.Timestamp | null | undefined,
  now: Moment = moment()
): Moment => {
  if (!timestamp) {
    return now;
  }
  return moment(
    new Date(timestamp.seconds.toNumber() * 1000 + timestamp.nanoseconds / 1e6)
  );
};
