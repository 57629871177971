"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./empty_ts_proto";
import { event_payload as event_payload$1 } from "./event_payload_v2_ts_proto";
import { profiles as profiles$1 } from "./profiles_v1_ts_proto";
import { source_application as source_application$1 } from "./source_application_v2_ts_proto";
import { sources as sources$1 } from "./sources_v2_ts_proto";
import { google as google$2 } from "./struct_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";
import { workflow_destination_applications as workflow_destination_applications$1 } from "./workflow_destination_applications_v1_ts_proto";
import { workflow_evaluation_results as workflow_evaluation_results$1 } from "./workflow_evaluation_results_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflow_monitoring = (() => {
  const workflow_monitoring = {};
  workflow_monitoring.v2 = (() => {
    const v2 = {};
    v2.ListOrganizationFilterTypesRequest = (() => {
      class ListOrganizationFilterTypesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrganizationFilterTypesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrganizationFilterTypesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListOrganizationFilterTypesRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListOrganizationFilterTypesRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListOrganizationFilterTypesRequest";
        }
      }

      ListOrganizationFilterTypesRequest.prototype.stub = null;

      return ListOrganizationFilterTypesRequest;
    })();

    v2.ListOrganizationFilterTypesResponse = (() => {
      class ListOrganizationFilterTypesResponse {
        constructor(properties) {
          this.sourceApplicationEventPayloads = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrganizationFilterTypesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.sourceApplicationEventPayloads != null && Object.hasOwnProperty.call(message, "sourceApplicationEventPayloads")) {
            for (const element of message.sourceApplicationEventPayloads) {
              $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrganizationFilterTypesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.sourceApplicationEventPayloads || !message.sourceApplicationEventPayloads.length) {
                  message.sourceApplicationEventPayloads = [];
                }
                message.sourceApplicationEventPayloads.push($root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse();
          if (object.sourceApplicationEventPayloads) {
            if (!Array.isArray(object.sourceApplicationEventPayloads)) {
              throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.sourceApplicationEventPayloads: array type expected, but got " + (typeof object.sourceApplicationEventPayloads))
            }
            message.sourceApplicationEventPayloads = new Array(object.sourceApplicationEventPayloads.length);
            for (let i = 0; i < object.sourceApplicationEventPayloads.length; ++i) {
              if (typeof object.sourceApplicationEventPayloads[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.sourceApplicationEventPayloads: object expected, but got " + (typeof object.sourceApplicationEventPayloads[i]));
              }
              message.sourceApplicationEventPayloads[i] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads.fromObject(object.sourceApplicationEventPayloads[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sourceApplicationEventPayloads = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.sourceApplicationEventPayloads && message.sourceApplicationEventPayloads.length) {
            object.sourceApplicationEventPayloads = new Array(message.sourceApplicationEventPayloads.length);
            for (let i = 0; i < message.sourceApplicationEventPayloads.length; ++i) {
              object.sourceApplicationEventPayloads[i] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads.toObject(message.sourceApplicationEventPayloads[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListOrganizationFilterTypesResponse";
        }
      }

      ListOrganizationFilterTypesResponse.prototype.sourceApplicationEventPayloads = $util.emptyArray;
      ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads = (() => {
        class SourceApplicationEventPayloads {
          constructor(properties) {
            this.eventPayloadStubHashMatches = {};
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SourceApplicationEventPayloads(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.sourceApplication != null && Object.hasOwnProperty.call(message, "sourceApplication")) {
              writer.uint32(8).int32(message.sourceApplication);
            }
            if (message.eventPayloadStubHashMatches != null && Object.hasOwnProperty.call(message, "eventPayloadStubHashMatches")) {
              for (const key of Object.keys(message.eventPayloadStubHashMatches)) {
                writer.uint32(18).fork().uint32(8).int64(key);
                $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.encode(message.eventPayloadStubHashMatches[key], writer.uint32(18).fork()).ldelim().ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SourceApplicationEventPayloads();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sourceApplication = reader.int32();
                  break;
                }
                case 2: {
                  if (message.eventPayloadStubHashMatches === $util.emptyObject) message.eventPayloadStubHashMatches = {};
                  let end2 = reader.uint32() + reader.pos;
                  key = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
                  value = null;
                  while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                      case 1: {
                        key = reader.int64();
                        break;
                      }
                      case 2: {
                        value = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.decode(reader, reader.uint32());
                        break;
                      }
                    }
                    message.eventPayloadStubHashMatches[key] = value;
                  }
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads();
            if (object.sourceApplication != null) {
              switch (object.sourceApplication) {
                case "UNSPECIFIED_SOURCE_APPLICATION":
                case 0: {
                  message.sourceApplication = 0;
                  break;
                }
                case "FORMSORT":
                case 1: {
                  message.sourceApplication = 1;
                  break;
                }
                case "HEALTHIE":
                case 2: {
                  message.sourceApplication = 2;
                  break;
                }
                case "AXLE_HEALTH":
                case 3: {
                  message.sourceApplication = 3;
                  break;
                }
                case "BUTTERFLY_LABS":
                case 4: {
                  message.sourceApplication = 4;
                  break;
                }
                case "MORF":
                case 5: {
                  message.sourceApplication = 5;
                  break;
                }
                case "PUSH_JSON":
                case 6: {
                  message.sourceApplication = 6;
                  break;
                }
                case "ACUITY":
                case 7: {
                  message.sourceApplication = 7;
                  break;
                }
                case "VITAL":
                case 8: {
                  message.sourceApplication = 8;
                  break;
                }
                case "SEGMENT":
                case 9: {
                  message.sourceApplication = 9;
                  break;
                }
                case "ACTIVE_CAMPAIGN":
                case 10: {
                  message.sourceApplication = 10;
                  break;
                }
                case "INTAKEQ":
                case 11: {
                  message.sourceApplication = 11;
                  break;
                }
                case "CUSTOMER_IO":
                case 12: {
                  message.sourceApplication = 12;
                  break;
                }
                case "HUBSPOT":
                case 13: {
                  message.sourceApplication = 13;
                  break;
                }
                case "CUSTOMER":
                case 14: {
                  message.sourceApplication = 14;
                  break;
                }
                case "CALENDLY":
                case 15: {
                  message.sourceApplication = 15;
                  break;
                }
                default: {
                  if (typeof object.sourceApplication == "number") {
                    message.sourceApplication = object.sourceApplication;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.eventPayloadStubHashMatches) {
              if (typeof object.eventPayloadStubHashMatches !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads.eventPayloadStubHashMatches: object expected, but got " + (typeof object.eventPayloadStubHashMatches));
              }
              message.eventPayloadStubHashMatches = {};
              for (let keys = Object.keys(object.eventPayloadStubHashMatches), i = 0; i < keys.length; ++i) {
                if (typeof object.eventPayloadStubHashMatches[keys[i]] !== "object") {
                  throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads.value: object expected, but got " + (typeof object.eventPayloadStubHashMatches[keys[i]]));
                }
                message.eventPayloadStubHashMatches[keys[i]] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.fromObject(object.eventPayloadStubHashMatches[keys[i]]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
              object.eventPayloadStubHashMatches = {};
            }
            if (options.defaults) {
              object.sourceApplication = options.enums === String ? "UNSPECIFIED_SOURCE_APPLICATION" : 0
            }
            let keys;
            if (message.sourceApplication != null && message.hasOwnProperty("sourceApplication")) {
              object.sourceApplication = options.enums === String ? source_application$1.v2.SourceApplication.Application[message.sourceApplication] === undefined ? message.sourceApplication : source_application$1.v2.SourceApplication.Application[message.sourceApplication] : message.sourceApplication;
            }
            if (message.eventPayloadStubHashMatches && (keys = Object.keys(message.eventPayloadStubHashMatches)).length) {
              object.eventPayloadStubHashMatches = {};
              for (let i = 0; i < keys.length; ++i) {
                object.eventPayloadStubHashMatches[keys[i]] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.toObject(message.eventPayloadStubHashMatches[keys[i]], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListOrganizationFilterTypesResponse.SourceApplicationEventPayloads";
          }
        }

        SourceApplicationEventPayloads.prototype.sourceApplication = 0;
        SourceApplicationEventPayloads.prototype.eventPayloadStubHashMatches = $util.emptyObject;

        return SourceApplicationEventPayloads;
      })();

      ListOrganizationFilterTypesResponse.EventPayloadMatches = (() => {
        class EventPayloadMatches {
          constructor(properties) {
            this.eventTypeIndices = [];
            this.workflowNames = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EventPayloadMatches(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.eventPayloadStub != null && Object.hasOwnProperty.call(message, "eventPayloadStub")) {
              event_payload$1.v2.EventPayload.encode(message.eventPayloadStub, writer.uint32(10).fork()).ldelim();
            }
            if (message.eventTypeIndices != null && Object.hasOwnProperty.call(message, "eventTypeIndices")) {
              for (const element of message.eventTypeIndices) {
                $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.workflowNames != null && Object.hasOwnProperty.call(message, "workflowNames")) {
              for (const element of message.workflowNames) {
                $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName.encode(element, writer.uint32(26).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EventPayloadMatches();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.eventPayloadStub = event_payload$1.v2.EventPayload.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  if (!message.eventTypeIndices || !message.eventTypeIndices.length) {
                    message.eventTypeIndices = [];
                  }
                  message.eventTypeIndices.push($root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  if (!message.workflowNames || !message.workflowNames.length) {
                    message.workflowNames = [];
                  }
                  message.workflowNames.push($root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches();
            if (object.eventPayloadStub != null) {
              if (typeof object.eventPayloadStub !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.eventPayloadStub: object expected, but got " + (typeof object.eventPayloadStub));
              }
              message.eventPayloadStub = event_payload$1.v2.EventPayload.fromObject(object.eventPayloadStub);
            }
            if (object.eventTypeIndices) {
              if (!Array.isArray(object.eventTypeIndices)) {
                throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.eventTypeIndices: array type expected, but got " + (typeof object.eventTypeIndices))
              }
              message.eventTypeIndices = new Array(object.eventTypeIndices.length);
              for (let i = 0; i < object.eventTypeIndices.length; ++i) {
                if (typeof object.eventTypeIndices[i] !== "object") {
                  throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.eventTypeIndices: object expected, but got " + (typeof object.eventTypeIndices[i]));
                }
                message.eventTypeIndices[i] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex.fromObject(object.eventTypeIndices[i]);
              }
            }
            if (object.workflowNames) {
              if (!Array.isArray(object.workflowNames)) {
                throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.workflowNames: array type expected, but got " + (typeof object.workflowNames))
              }
              message.workflowNames = new Array(object.workflowNames.length);
              for (let i = 0; i < object.workflowNames.length; ++i) {
                if (typeof object.workflowNames[i] !== "object") {
                  throw new TypeError(".workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches.workflowNames: object expected, but got " + (typeof object.workflowNames[i]));
                }
                message.workflowNames[i] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName.fromObject(object.workflowNames[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.eventTypeIndices = [];
              object.workflowNames = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.eventPayloadStub = null;
            }
            let keys;
            if (message.eventPayloadStub != null && message.hasOwnProperty("eventPayloadStub")) {
              object.eventPayloadStub = event_payload$1.v2.EventPayload.toObject(message.eventPayloadStub, options);
            }
            if (message.eventTypeIndices && message.eventTypeIndices.length) {
              object.eventTypeIndices = new Array(message.eventTypeIndices.length);
              for (let i = 0; i < message.eventTypeIndices.length; ++i) {
                object.eventTypeIndices[i] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex.toObject(message.eventTypeIndices[i], options);
              }
            }
            if (message.workflowNames && message.workflowNames.length) {
              object.workflowNames = new Array(message.workflowNames.length);
              for (let i = 0; i < message.workflowNames.length; ++i) {
                object.workflowNames[i] = $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName.toObject(message.workflowNames[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventPayloadMatches";
          }
        }

        EventPayloadMatches.prototype.eventPayloadStub = null;
        EventPayloadMatches.prototype.eventTypeIndices = $util.emptyArray;
        EventPayloadMatches.prototype.workflowNames = $util.emptyArray;

        return EventPayloadMatches;
      })();

      ListOrganizationFilterTypesResponse.EventTypeIndex = (() => {
        class EventTypeIndex {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EventTypeIndex(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
              writer.uint32(8).int64(message.index);
            }
            if (message.label != null && Object.hasOwnProperty.call(message, "label")) {
              writer.uint32(18).string(message.label);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EventTypeIndex();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.index = reader.int64();
                  break;
                }
                case 2: {
                  message.label = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex();
            if (object.index != null) {
              if ($util.Long) {
                (message.index = $util.Long.fromValue(object.index)).unsigned = false;
              } else if (typeof object.index === "string") {
                message.index = parseInt(object.index, 10);
              } else if (typeof object.index === "number") {
                message.index = object.index;
              } else if (typeof object.index === "object") {
                message.index = new $util.LongBits(object.index.low >>> 0, object.index.high >>> 0).toNumber();
              }
            }
            if (object.label != null) {
              message.label = String(object.label);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false)
                object.index = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.index = options.longs === String ? "0" : 0;
              }
              object.label = "";
            }
            let keys;
            if (message.index != null && message.hasOwnProperty("index")) {
              object.index = typeof message.index === "number" ? (options.longs === String ? String(message.index) : message.index) : (options.longs === String ? $util.Long.prototype.toString.call(message.index) : options.longs === Number ? new $util.LongBits(message.index.low >>> 0, message.index.high >>> 0).toNumber() : message.index);
            }
            if (message.label != null && message.hasOwnProperty("label")) {
              object.label = message.label;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListOrganizationFilterTypesResponse.EventTypeIndex";
          }
        }

        EventTypeIndex.prototype.index = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
        EventTypeIndex.prototype.label = "";

        return EventTypeIndex;
      })();

      ListOrganizationFilterTypesResponse.WorkflowName = (() => {
        class WorkflowName {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WorkflowName(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
              writer.uint32(10).string(message.id);
            }
            if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
              writer.uint32(18).string(message.name);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WorkflowName();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.id = reader.string();
                  break;
                }
                case 2: {
                  message.name = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName();
            if (object.id != null) {
              message.id = String(object.id);
            }
            if (object.name != null) {
              message.name = String(object.name);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.id = "";
              object.name = "";
            }
            let keys;
            if (message.id != null && message.hasOwnProperty("id")) {
              object.id = message.id;
            }
            if (message.name != null && message.hasOwnProperty("name")) {
              object.name = message.name;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListOrganizationFilterTypesResponse.WorkflowName";
          }
        }

        WorkflowName.prototype.id = "";
        WorkflowName.prototype.name = "";

        return WorkflowName;
      })();

      return ListOrganizationFilterTypesResponse;
    })();

    v2.ListEventSnapshotsRequest = (() => {
      class ListEventSnapshotsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.earliestTimestampInclusive != null && Object.hasOwnProperty.call(message, "earliestTimestampInclusive")) {
            timing$1.v1.Timestamp.encode(message.earliestTimestampInclusive, writer.uint32(10).fork()).ldelim();
          }
          if (message.latestTimestampExclusive != null && Object.hasOwnProperty.call(message, "latestTimestampExclusive")) {
            timing$1.v1.Timestamp.encode(message.latestTimestampExclusive, writer.uint32(18).fork()).ldelim();
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(24).uint64(message.limit);
          }
          if (message.filter != null && Object.hasOwnProperty.call(message, "filter")) {
            $root.workflow_monitoring.v2.ListEventSnapshotsRequest.Filter.encode(message.filter, writer.uint32(34).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.earliestTimestampInclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.limit = reader.uint64();
                break;
              }
              case 4: {
                message.filter = $root.workflow_monitoring.v2.ListEventSnapshotsRequest.Filter.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListEventSnapshotsRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListEventSnapshotsRequest();
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsRequest.earliestTimestampInclusive: object expected, but got " + (typeof object.earliestTimestampInclusive));
            }
            message.earliestTimestampInclusive = timing$1.v1.Timestamp.fromObject(object.earliestTimestampInclusive);
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsRequest.latestTimestampExclusive: object expected, but got " + (typeof object.latestTimestampExclusive));
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(object.latestTimestampExclusive);
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(object.limit)).unsigned = true;
            } else if (typeof object.limit === "string") {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === "number") {
              message.limit = object.limit;
            } else if (typeof object.limit === "object") {
              message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber(true);
            }
          }
          if (object.filter != null) {
            if (typeof object.filter !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsRequest.filter: object expected, but got " + (typeof object.filter));
            }
            message.filter = $root.workflow_monitoring.v2.ListEventSnapshotsRequest.Filter.fromObject(object.filter);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.latestTimestampExclusive = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.limit = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.earliestTimestampInclusive != null && message.hasOwnProperty("earliestTimestampInclusive")) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(message.earliestTimestampInclusive, options);
          }
          if (message.latestTimestampExclusive != null && message.hasOwnProperty("latestTimestampExclusive")) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(message.latestTimestampExclusive, options);
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = typeof message.limit === "number" ? (options.longs === String ? String(message.limit) : message.limit) : (options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber(true) : message.limit);
          }
          if (message.filter != null && message.hasOwnProperty("filter")) {
            object.filter = $root.workflow_monitoring.v2.ListEventSnapshotsRequest.Filter.toObject(message.filter, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListEventSnapshotsRequest";
        }
      }

      ListEventSnapshotsRequest.prototype.earliestTimestampInclusive = null;
      ListEventSnapshotsRequest.prototype.latestTimestampExclusive = null;
      ListEventSnapshotsRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      ListEventSnapshotsRequest.prototype.filter = null;
      ListEventSnapshotsRequest.Filter = (() => {
        class Filter {
          constructor(properties) {
            this.eventPayloadHashes = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Filter(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.eventPayloadHashes != null && Object.hasOwnProperty.call(message, "eventPayloadHashes")) {
              for (const element of message.eventPayloadHashes) {
                $root.workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Filter();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.eventPayloadHashes || !message.eventPayloadHashes.length) {
                    message.eventPayloadHashes = [];
                  }
                  message.eventPayloadHashes.push($root.workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListEventSnapshotsRequest.Filter) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListEventSnapshotsRequest.Filter();
            if (object.eventPayloadHashes) {
              if (!Array.isArray(object.eventPayloadHashes)) {
                throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsRequest.Filter.eventPayloadHashes: array type expected, but got " + (typeof object.eventPayloadHashes))
              }
              message.eventPayloadHashes = new Array(object.eventPayloadHashes.length);
              for (let i = 0; i < object.eventPayloadHashes.length; ++i) {
                if (typeof object.eventPayloadHashes[i] !== "object") {
                  throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsRequest.Filter.eventPayloadHashes: object expected, but got " + (typeof object.eventPayloadHashes[i]));
                }
                message.eventPayloadHashes[i] = $root.workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes.fromObject(object.eventPayloadHashes[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.eventPayloadHashes = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.eventPayloadHashes && message.eventPayloadHashes.length) {
              object.eventPayloadHashes = new Array(message.eventPayloadHashes.length);
              for (let i = 0; i < message.eventPayloadHashes.length; ++i) {
                object.eventPayloadHashes[i] = $root.workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes.toObject(message.eventPayloadHashes[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListEventSnapshotsRequest.Filter";
          }
        }

        Filter.prototype.eventPayloadHashes = $util.emptyArray;

        return Filter;
      })();

      ListEventSnapshotsRequest.EventPayloadHashEventTypes = (() => {
        class EventPayloadHashEventTypes {
          constructor(properties) {
            this.eventTypeIndices = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EventPayloadHashEventTypes(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.eventPayloadHash != null && Object.hasOwnProperty.call(message, "eventPayloadHash")) {
              writer.uint32(8).int64(message.eventPayloadHash);
            }
            if (message.eventTypeIndices != null && Object.hasOwnProperty.call(message, "eventTypeIndices")) {
              writer.uint32(18).fork();
              for (const element of message.eventTypeIndices) {
                writer.int64(element)
              }
              writer.ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EventPayloadHashEventTypes();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.eventPayloadHash = reader.int64();
                  break;
                }
                case 2: {
                  if (!message.eventTypeIndices || !message.eventTypeIndices.length) {
                    message.eventTypeIndices = [];
                  }
                  if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2) message.eventTypeIndices.push(reader.int64());
                  } else {
                    message.eventTypeIndices.push(reader.int64());
                  }
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes();
            if (object.eventPayloadHash != null) {
              if ($util.Long) {
                (message.eventPayloadHash = $util.Long.fromValue(object.eventPayloadHash)).unsigned = false;
              } else if (typeof object.eventPayloadHash === "string") {
                message.eventPayloadHash = parseInt(object.eventPayloadHash, 10);
              } else if (typeof object.eventPayloadHash === "number") {
                message.eventPayloadHash = object.eventPayloadHash;
              } else if (typeof object.eventPayloadHash === "object") {
                message.eventPayloadHash = new $util.LongBits(object.eventPayloadHash.low >>> 0, object.eventPayloadHash.high >>> 0).toNumber();
              }
            }
            if (object.eventTypeIndices) {
              if (!Array.isArray(object.eventTypeIndices)) {
                throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes.eventTypeIndices: array type expected, but got " + (typeof object.eventTypeIndices))
              }
              message.eventTypeIndices = new Array(object.eventTypeIndices.length);
              for (let i = 0; i < object.eventTypeIndices.length; ++i) {
                if ($util.Long) {
                  (message.eventTypeIndices[i] = $util.Long.fromValue(object.eventTypeIndices[i])).unsigned = false;
                } else if (typeof object.eventTypeIndices[i] === "string") {
                  message.eventTypeIndices[i] = parseInt(object.eventTypeIndices[i], 10);
                } else if (typeof object.eventTypeIndices[i] === "number") {
                  message.eventTypeIndices[i] = object.eventTypeIndices[i];
                } else if (typeof object.eventTypeIndices[i] === "object") {
                  message.eventTypeIndices[i] = new $util.LongBits(object.eventTypeIndices[i].low >>> 0, object.eventTypeIndices[i].high >>> 0).toNumber();
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.eventTypeIndices = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false)
                object.eventPayloadHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.eventPayloadHash = options.longs === String ? "0" : 0;
              }
            }
            let keys;
            if (message.eventPayloadHash != null && message.hasOwnProperty("eventPayloadHash")) {
              object.eventPayloadHash = typeof message.eventPayloadHash === "number" ? (options.longs === String ? String(message.eventPayloadHash) : message.eventPayloadHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.eventPayloadHash) : options.longs === Number ? new $util.LongBits(message.eventPayloadHash.low >>> 0, message.eventPayloadHash.high >>> 0).toNumber() : message.eventPayloadHash);
            }
            if (message.eventTypeIndices && message.eventTypeIndices.length) {
              object.eventTypeIndices = new Array(message.eventTypeIndices.length);
              for (let i = 0; i < message.eventTypeIndices.length; ++i) {
                object.eventTypeIndices[i] = typeof message.eventTypeIndices[i] === "number" ? (options.longs === String ? String(message.eventTypeIndices[i]) : message.eventTypeIndices[i]) : (options.longs === String ? $util.Long.prototype.toString.call(message.eventTypeIndices[i]) : options.longs === Number ? new $util.LongBits(message.eventTypeIndices[i].low >>> 0, message.eventTypeIndices[i].high >>> 0).toNumber() : message.eventTypeIndices[i]);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListEventSnapshotsRequest.EventPayloadHashEventTypes";
          }
        }

        EventPayloadHashEventTypes.prototype.eventPayloadHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
        EventPayloadHashEventTypes.prototype.eventTypeIndices = $util.emptyArray;

        return EventPayloadHashEventTypes;
      })();

      return ListEventSnapshotsRequest;
    })();

    v2.ListEventSnapshotsResponse = (() => {
      class ListEventSnapshotsResponse {
        constructor(properties) {
          this.eventSnapshots = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventSnapshots != null && Object.hasOwnProperty.call(message, "eventSnapshots")) {
            for (const element of message.eventSnapshots) {
              event_payload$1.v2.EventSnapshotSummary.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.earliestFetchedTimestamp != null && Object.hasOwnProperty.call(message, "earliestFetchedTimestamp")) {
            timing$1.v1.Timestamp.encode(message.earliestFetchedTimestamp, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventSnapshots || !message.eventSnapshots.length) {
                  message.eventSnapshots = [];
                }
                message.eventSnapshots.push(event_payload$1.v2.EventSnapshotSummary.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListEventSnapshotsResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListEventSnapshotsResponse();
          if (object.eventSnapshots) {
            if (!Array.isArray(object.eventSnapshots)) {
              throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsResponse.eventSnapshots: array type expected, but got " + (typeof object.eventSnapshots))
            }
            message.eventSnapshots = new Array(object.eventSnapshots.length);
            for (let i = 0; i < object.eventSnapshots.length; ++i) {
              if (typeof object.eventSnapshots[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsResponse.eventSnapshots: object expected, but got " + (typeof object.eventSnapshots[i]));
              }
              message.eventSnapshots[i] = event_payload$1.v2.EventSnapshotSummary.fromObject(object.eventSnapshots[i]);
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotsResponse.earliestFetchedTimestamp: object expected, but got " + (typeof object.earliestFetchedTimestamp));
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(object.earliestFetchedTimestamp);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventSnapshots = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventSnapshots && message.eventSnapshots.length) {
            object.eventSnapshots = new Array(message.eventSnapshots.length);
            for (let i = 0; i < message.eventSnapshots.length; ++i) {
              object.eventSnapshots[i] = event_payload$1.v2.EventSnapshotSummary.toObject(message.eventSnapshots[i], options);
            }
          }
          if (message.earliestFetchedTimestamp != null && message.hasOwnProperty("earliestFetchedTimestamp")) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(message.earliestFetchedTimestamp, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListEventSnapshotsResponse";
        }
      }

      ListEventSnapshotsResponse.prototype.eventSnapshots = $util.emptyArray;
      ListEventSnapshotsResponse.prototype.earliestFetchedTimestamp = null;

      return ListEventSnapshotsResponse;
    })();

    v2.ListEventSnapshotWorkflowExecutionsRequest = (() => {
      class ListEventSnapshotWorkflowExecutionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotWorkflowExecutionsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
            writer.uint32(10).string(message.eventSnapshotId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotWorkflowExecutionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventSnapshotId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsRequest();
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventSnapshotId = "";
          }
          let keys;
          if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsRequest";
        }
      }

      ListEventSnapshotWorkflowExecutionsRequest.prototype.eventSnapshotId = "";

      return ListEventSnapshotWorkflowExecutionsRequest;
    })();

    v2.ListEventSnapshotWorkflowExecutionsResponse = (() => {
      class ListEventSnapshotWorkflowExecutionsResponse {
        constructor(properties) {
          this.executions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotWorkflowExecutionsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.executions != null && Object.hasOwnProperty.call(message, "executions")) {
            for (const element of message.executions) {
              $root.workflow_monitoring.v2.WorkflowExecutionSummary.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotWorkflowExecutionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.executions || !message.executions.length) {
                  message.executions = [];
                }
                message.executions.push($root.workflow_monitoring.v2.WorkflowExecutionSummary.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsResponse();
          if (object.executions) {
            if (!Array.isArray(object.executions)) {
              throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsResponse.executions: array type expected, but got " + (typeof object.executions))
            }
            message.executions = new Array(object.executions.length);
            for (let i = 0; i < object.executions.length; ++i) {
              if (typeof object.executions[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsResponse.executions: object expected, but got " + (typeof object.executions[i]));
              }
              message.executions[i] = $root.workflow_monitoring.v2.WorkflowExecutionSummary.fromObject(object.executions[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.executions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.executions && message.executions.length) {
            object.executions = new Array(message.executions.length);
            for (let i = 0; i < message.executions.length; ++i) {
              object.executions[i] = $root.workflow_monitoring.v2.WorkflowExecutionSummary.toObject(message.executions[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsResponse";
        }
      }

      ListEventSnapshotWorkflowExecutionsResponse.prototype.executions = $util.emptyArray;

      return ListEventSnapshotWorkflowExecutionsResponse;
    })();

    v2.ErrorHash = (() => {
      class ErrorHash {
        get type() {
          for (const key of ["workflowErrorHash", "actionErrorHash"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["workflowErrorHash", "actionErrorHash"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ErrorHash(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.workflowErrorHash != null && Object.hasOwnProperty.call(message, "workflowErrorHash")) {
            $root.workflow_monitoring.v2.ErrorHash.Workflow.encode(message.workflowErrorHash, writer.uint32(10).fork()).ldelim();
          }
          if (message.actionErrorHash != null && Object.hasOwnProperty.call(message, "actionErrorHash")) {
            $root.workflow_monitoring.v2.ErrorHash.DestinationAction.encode(message.actionErrorHash, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ErrorHash();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowErrorHash = $root.workflow_monitoring.v2.ErrorHash.Workflow.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.actionErrorHash = $root.workflow_monitoring.v2.ErrorHash.DestinationAction.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ErrorHash) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ErrorHash();
          if (object.workflowErrorHash != null) {
            if (typeof object.workflowErrorHash !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ErrorHash.workflowErrorHash: object expected, but got " + (typeof object.workflowErrorHash));
            }
            message.workflowErrorHash = $root.workflow_monitoring.v2.ErrorHash.Workflow.fromObject(object.workflowErrorHash);
          }
          if (object.actionErrorHash != null) {
            if (typeof object.actionErrorHash !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ErrorHash.actionErrorHash: object expected, but got " + (typeof object.actionErrorHash));
            }
            message.actionErrorHash = $root.workflow_monitoring.v2.ErrorHash.DestinationAction.fromObject(object.actionErrorHash);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.workflowErrorHash != null && message.hasOwnProperty("workflowErrorHash")) {
            object.workflowErrorHash = $root.workflow_monitoring.v2.ErrorHash.Workflow.toObject(message.workflowErrorHash, options);
            if (options.oneofs) {
              object.type = "workflowErrorHash";
            }
          }
          if (message.actionErrorHash != null && message.hasOwnProperty("actionErrorHash")) {
            object.actionErrorHash = $root.workflow_monitoring.v2.ErrorHash.DestinationAction.toObject(message.actionErrorHash, options);
            if (options.oneofs) {
              object.type = "actionErrorHash";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ErrorHash";
        }
      }

      ErrorHash.prototype.workflowErrorHash = null;
      ErrorHash.prototype.actionErrorHash = null;
      ErrorHash.Workflow = (() => {
        class Workflow {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Workflow(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.errorHash != null && Object.hasOwnProperty.call(message, "errorHash")) {
              writer.uint32(8).int64(message.errorHash);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Workflow();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.errorHash = reader.int64();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ErrorHash.Workflow) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ErrorHash.Workflow();
            if (object.errorHash != null) {
              if ($util.Long) {
                (message.errorHash = $util.Long.fromValue(object.errorHash)).unsigned = false;
              } else if (typeof object.errorHash === "string") {
                message.errorHash = parseInt(object.errorHash, 10);
              } else if (typeof object.errorHash === "number") {
                message.errorHash = object.errorHash;
              } else if (typeof object.errorHash === "object") {
                message.errorHash = new $util.LongBits(object.errorHash.low >>> 0, object.errorHash.high >>> 0).toNumber();
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false)
                object.errorHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.errorHash = options.longs === String ? "0" : 0;
              }
            }
            let keys;
            if (message.errorHash != null && message.hasOwnProperty("errorHash")) {
              object.errorHash = typeof message.errorHash === "number" ? (options.longs === String ? String(message.errorHash) : message.errorHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.errorHash) : options.longs === Number ? new $util.LongBits(message.errorHash.low >>> 0, message.errorHash.high >>> 0).toNumber() : message.errorHash);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ErrorHash.Workflow";
          }
        }

        Workflow.prototype.errorHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

        return Workflow;
      })();

      ErrorHash.DestinationAction = (() => {
        class DestinationAction {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new DestinationAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.errorHash != null && Object.hasOwnProperty.call(message, "errorHash")) {
              writer.uint32(8).int64(message.errorHash);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new DestinationAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.errorHash = reader.int64();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ErrorHash.DestinationAction) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ErrorHash.DestinationAction();
            if (object.errorHash != null) {
              if ($util.Long) {
                (message.errorHash = $util.Long.fromValue(object.errorHash)).unsigned = false;
              } else if (typeof object.errorHash === "string") {
                message.errorHash = parseInt(object.errorHash, 10);
              } else if (typeof object.errorHash === "number") {
                message.errorHash = object.errorHash;
              } else if (typeof object.errorHash === "object") {
                message.errorHash = new $util.LongBits(object.errorHash.low >>> 0, object.errorHash.high >>> 0).toNumber();
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, false)
                object.errorHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.errorHash = options.longs === String ? "0" : 0;
              }
            }
            let keys;
            if (message.errorHash != null && message.hasOwnProperty("errorHash")) {
              object.errorHash = typeof message.errorHash === "number" ? (options.longs === String ? String(message.errorHash) : message.errorHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.errorHash) : options.longs === Number ? new $util.LongBits(message.errorHash.low >>> 0, message.errorHash.high >>> 0).toNumber() : message.errorHash);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ErrorHash.DestinationAction";
          }
        }

        DestinationAction.prototype.errorHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

        return DestinationAction;
      })();

      return ErrorHash;
    })();

    v2.GroupedErrorData = (() => {
      class GroupedErrorData {
        constructor(properties) {
          this.countsOverTime = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GroupedErrorData(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.errorClass != null && Object.hasOwnProperty.call(message, "errorClass")) {
            writer.uint32(8).int32(message.errorClass);
          }
          if (message.errorString != null && Object.hasOwnProperty.call(message, "errorString")) {
            writer.uint32(18).string(message.errorString);
          }
          if (message.groupingName != null && Object.hasOwnProperty.call(message, "groupingName")) {
            writer.uint32(26).string(message.groupingName);
          }
          if (message.profilesAffectedCount != null && Object.hasOwnProperty.call(message, "profilesAffectedCount")) {
            writer.uint32(32).uint64(message.profilesAffectedCount);
          }
          if (message.workflowsAffectedCount != null && Object.hasOwnProperty.call(message, "workflowsAffectedCount")) {
            writer.uint32(40).uint64(message.workflowsAffectedCount);
          }
          if (message.affectedEventsCount != null && Object.hasOwnProperty.call(message, "affectedEventsCount")) {
            writer.uint32(48).uint64(message.affectedEventsCount);
          }
          if (message.lastSeenWithinRange != null && Object.hasOwnProperty.call(message, "lastSeenWithinRange")) {
            timing$1.v1.Timestamp.encode(message.lastSeenWithinRange, writer.uint32(58).fork()).ldelim();
          }
          if (message.destinationActionErrorApplication != null && Object.hasOwnProperty.call(message, "destinationActionErrorApplication")) {
            writer.uint32(64).int32(message.destinationActionErrorApplication);
          }
          if (message.countsOverTime != null && Object.hasOwnProperty.call(message, "countsOverTime")) {
            for (const element of message.countsOverTime) {
              $root.workflow_monitoring.v2.CountsAtTime.encode(element, writer.uint32(74).fork()).ldelim();
            }
          }
          if (message.errorHash != null && Object.hasOwnProperty.call(message, "errorHash")) {
            $root.workflow_monitoring.v2.ErrorHash.encode(message.errorHash, writer.uint32(82).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GroupedErrorData();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.errorClass = reader.int32();
                break;
              }
              case 2: {
                message.errorString = reader.string();
                break;
              }
              case 3: {
                message.groupingName = reader.string();
                break;
              }
              case 4: {
                message.profilesAffectedCount = reader.uint64();
                break;
              }
              case 5: {
                message.workflowsAffectedCount = reader.uint64();
                break;
              }
              case 6: {
                message.affectedEventsCount = reader.uint64();
                break;
              }
              case 7: {
                message.lastSeenWithinRange = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.destinationActionErrorApplication = reader.int32();
                break;
              }
              case 9: {
                if (!message.countsOverTime || !message.countsOverTime.length) {
                  message.countsOverTime = [];
                }
                message.countsOverTime.push($root.workflow_monitoring.v2.CountsAtTime.decode(reader, reader.uint32()));
                break;
              }
              case 10: {
                message.errorHash = $root.workflow_monitoring.v2.ErrorHash.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GroupedErrorData) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GroupedErrorData();
          if (object.errorClass != null) {
            switch (object.errorClass) {
              case "UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS":
              case 0: {
                message.errorClass = 0;
                break;
              }
              case "ERROR":
              case 1: {
                message.errorClass = 1;
                break;
              }
              case "WARNING":
              case 2: {
                message.errorClass = 2;
                break;
              }
              case "INTERNAL_ERROR":
              case 4: {
                message.errorClass = 4;
                break;
              }
              default: {
                if (typeof object.errorClass == "number") {
                  message.errorClass = object.errorClass;
                  break;
                }
                break;
              }
            }
          }
          if (object.errorString != null) {
            message.errorString = String(object.errorString);
          }
          if (object.groupingName != null) {
            message.groupingName = String(object.groupingName);
          }
          if (object.profilesAffectedCount != null) {
            if ($util.Long) {
              (message.profilesAffectedCount = $util.Long.fromValue(object.profilesAffectedCount)).unsigned = true;
            } else if (typeof object.profilesAffectedCount === "string") {
              message.profilesAffectedCount = parseInt(object.profilesAffectedCount, 10);
            } else if (typeof object.profilesAffectedCount === "number") {
              message.profilesAffectedCount = object.profilesAffectedCount;
            } else if (typeof object.profilesAffectedCount === "object") {
              message.profilesAffectedCount = new $util.LongBits(object.profilesAffectedCount.low >>> 0, object.profilesAffectedCount.high >>> 0).toNumber(true);
            }
          }
          if (object.workflowsAffectedCount != null) {
            if ($util.Long) {
              (message.workflowsAffectedCount = $util.Long.fromValue(object.workflowsAffectedCount)).unsigned = true;
            } else if (typeof object.workflowsAffectedCount === "string") {
              message.workflowsAffectedCount = parseInt(object.workflowsAffectedCount, 10);
            } else if (typeof object.workflowsAffectedCount === "number") {
              message.workflowsAffectedCount = object.workflowsAffectedCount;
            } else if (typeof object.workflowsAffectedCount === "object") {
              message.workflowsAffectedCount = new $util.LongBits(object.workflowsAffectedCount.low >>> 0, object.workflowsAffectedCount.high >>> 0).toNumber(true);
            }
          }
          if (object.affectedEventsCount != null) {
            if ($util.Long) {
              (message.affectedEventsCount = $util.Long.fromValue(object.affectedEventsCount)).unsigned = true;
            } else if (typeof object.affectedEventsCount === "string") {
              message.affectedEventsCount = parseInt(object.affectedEventsCount, 10);
            } else if (typeof object.affectedEventsCount === "number") {
              message.affectedEventsCount = object.affectedEventsCount;
            } else if (typeof object.affectedEventsCount === "object") {
              message.affectedEventsCount = new $util.LongBits(object.affectedEventsCount.low >>> 0, object.affectedEventsCount.high >>> 0).toNumber(true);
            }
          }
          if (object.lastSeenWithinRange != null) {
            if (typeof object.lastSeenWithinRange !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GroupedErrorData.lastSeenWithinRange: object expected, but got " + (typeof object.lastSeenWithinRange));
            }
            message.lastSeenWithinRange = timing$1.v1.Timestamp.fromObject(object.lastSeenWithinRange);
          }
          if (object.destinationActionErrorApplication != null) {
            switch (object.destinationActionErrorApplication) {
              case "UNSPECIFIED_DESTINATION_APPLICATION":
              case 0: {
                message.destinationActionErrorApplication = 0;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 1: {
                message.destinationActionErrorApplication = 1;
                break;
              }
              case "CUSTOMER_IO":
              case 2: {
                message.destinationActionErrorApplication = 2;
                break;
              }
              case "FACEBOOK":
              case 3: {
                message.destinationActionErrorApplication = 3;
                break;
              }
              case "GOOGLE":
              case 4: {
                message.destinationActionErrorApplication = 4;
                break;
              }
              case "HEALTHIE":
              case 5: {
                message.destinationActionErrorApplication = 5;
                break;
              }
              case "MORF":
              case 6: {
                message.destinationActionErrorApplication = 6;
                break;
              }
              case "INTAKEQ":
              case 7: {
                message.destinationActionErrorApplication = 7;
                break;
              }
              case "SEGMENT":
              case 8: {
                message.destinationActionErrorApplication = 8;
                break;
              }
              case "SPRUCE":
              case 9: {
                message.destinationActionErrorApplication = 9;
                break;
              }
              case "SLACK":
              case 10: {
                message.destinationActionErrorApplication = 10;
                break;
              }
              case "FRESHDESK":
              case 11: {
                message.destinationActionErrorApplication = 11;
                break;
              }
              case "HUBSPOT":
              case 12: {
                message.destinationActionErrorApplication = 12;
                break;
              }
              case "INTERCOM":
              case 13: {
                message.destinationActionErrorApplication = 13;
                break;
              }
              case "TIKTOK":
              case 14: {
                message.destinationActionErrorApplication = 14;
                break;
              }
              case "TWILIO":
              case 15: {
                message.destinationActionErrorApplication = 15;
                break;
              }
              default: {
                if (typeof object.destinationActionErrorApplication == "number") {
                  message.destinationActionErrorApplication = object.destinationActionErrorApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.countsOverTime) {
            if (!Array.isArray(object.countsOverTime)) {
              throw new TypeError(".workflow_monitoring.v2.GroupedErrorData.countsOverTime: array type expected, but got " + (typeof object.countsOverTime))
            }
            message.countsOverTime = new Array(object.countsOverTime.length);
            for (let i = 0; i < object.countsOverTime.length; ++i) {
              if (typeof object.countsOverTime[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.GroupedErrorData.countsOverTime: object expected, but got " + (typeof object.countsOverTime[i]));
              }
              message.countsOverTime[i] = $root.workflow_monitoring.v2.CountsAtTime.fromObject(object.countsOverTime[i]);
            }
          }
          if (object.errorHash != null) {
            if (typeof object.errorHash !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GroupedErrorData.errorHash: object expected, but got " + (typeof object.errorHash));
            }
            message.errorHash = $root.workflow_monitoring.v2.ErrorHash.fromObject(object.errorHash);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.countsOverTime = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.errorClass = options.enums === String ? "UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS" : 0
            object.errorString = "";
            object.groupingName = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.profilesAffectedCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.profilesAffectedCount = options.longs === String ? "0" : 0;
            }
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.workflowsAffectedCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.workflowsAffectedCount = options.longs === String ? "0" : 0;
            }
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.affectedEventsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.affectedEventsCount = options.longs === String ? "0" : 0;
            }
            object.lastSeenWithinRange = null;
            object.errorHash = null;
          }
          let keys;
          if (message.errorClass != null && message.hasOwnProperty("errorClass")) {
            object.errorClass = options.enums === String ? workflow_evaluation_results$1.v1.ErrorClass[message.errorClass] === undefined ? message.errorClass : workflow_evaluation_results$1.v1.ErrorClass[message.errorClass] : message.errorClass;
          }
          if (message.errorString != null && message.hasOwnProperty("errorString")) {
            object.errorString = message.errorString;
          }
          if (message.groupingName != null && message.hasOwnProperty("groupingName")) {
            object.groupingName = message.groupingName;
          }
          if (message.profilesAffectedCount != null && message.hasOwnProperty("profilesAffectedCount")) {
            object.profilesAffectedCount = typeof message.profilesAffectedCount === "number" ? (options.longs === String ? String(message.profilesAffectedCount) : message.profilesAffectedCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.profilesAffectedCount) : options.longs === Number ? new $util.LongBits(message.profilesAffectedCount.low >>> 0, message.profilesAffectedCount.high >>> 0).toNumber(true) : message.profilesAffectedCount);
          }
          if (message.workflowsAffectedCount != null && message.hasOwnProperty("workflowsAffectedCount")) {
            object.workflowsAffectedCount = typeof message.workflowsAffectedCount === "number" ? (options.longs === String ? String(message.workflowsAffectedCount) : message.workflowsAffectedCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.workflowsAffectedCount) : options.longs === Number ? new $util.LongBits(message.workflowsAffectedCount.low >>> 0, message.workflowsAffectedCount.high >>> 0).toNumber(true) : message.workflowsAffectedCount);
          }
          if (message.affectedEventsCount != null && message.hasOwnProperty("affectedEventsCount")) {
            object.affectedEventsCount = typeof message.affectedEventsCount === "number" ? (options.longs === String ? String(message.affectedEventsCount) : message.affectedEventsCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.affectedEventsCount) : options.longs === Number ? new $util.LongBits(message.affectedEventsCount.low >>> 0, message.affectedEventsCount.high >>> 0).toNumber(true) : message.affectedEventsCount);
          }
          if (message.lastSeenWithinRange != null && message.hasOwnProperty("lastSeenWithinRange")) {
            object.lastSeenWithinRange = timing$1.v1.Timestamp.toObject(message.lastSeenWithinRange, options);
          }
          if (message.destinationActionErrorApplication != null && message.hasOwnProperty("destinationActionErrorApplication")) {
            object.destinationActionErrorApplication = options.enums === String ? workflow_destination_applications$1.v1.DestinationApplication.Application[message.destinationActionErrorApplication] === undefined ? message.destinationActionErrorApplication : workflow_destination_applications$1.v1.DestinationApplication.Application[message.destinationActionErrorApplication] : message.destinationActionErrorApplication;
          }
          if (message.countsOverTime && message.countsOverTime.length) {
            object.countsOverTime = new Array(message.countsOverTime.length);
            for (let i = 0; i < message.countsOverTime.length; ++i) {
              object.countsOverTime[i] = $root.workflow_monitoring.v2.CountsAtTime.toObject(message.countsOverTime[i], options);
            }
          }
          if (message.errorHash != null && message.hasOwnProperty("errorHash")) {
            object.errorHash = $root.workflow_monitoring.v2.ErrorHash.toObject(message.errorHash, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GroupedErrorData";
        }
      }

      GroupedErrorData.prototype.errorClass = 0;
      GroupedErrorData.prototype.errorString = "";
      GroupedErrorData.prototype.groupingName = "";
      GroupedErrorData.prototype.profilesAffectedCount = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      GroupedErrorData.prototype.workflowsAffectedCount = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      GroupedErrorData.prototype.affectedEventsCount = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      GroupedErrorData.prototype.lastSeenWithinRange = null;
      GroupedErrorData.prototype.destinationActionErrorApplication = null;
      GroupedErrorData.prototype.countsOverTime = $util.emptyArray;
      GroupedErrorData.prototype.errorHash = null;

      return GroupedErrorData;
    })();

    v2.GetGroupedErrorRequest = (() => {
      class GetGroupedErrorRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetGroupedErrorRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.earliestTimestampInclusive != null && Object.hasOwnProperty.call(message, "earliestTimestampInclusive")) {
            timing$1.v1.Timestamp.encode(message.earliestTimestampInclusive, writer.uint32(10).fork()).ldelim();
          }
          if (message.latestTimestampExclusive != null && Object.hasOwnProperty.call(message, "latestTimestampExclusive")) {
            timing$1.v1.Timestamp.encode(message.latestTimestampExclusive, writer.uint32(18).fork()).ldelim();
          }
          if (message.errorHash != null && Object.hasOwnProperty.call(message, "errorHash")) {
            writer.uint32(24).int64(message.errorHash);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetGroupedErrorRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.earliestTimestampInclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.errorHash = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetGroupedErrorRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetGroupedErrorRequest();
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetGroupedErrorRequest.earliestTimestampInclusive: object expected, but got " + (typeof object.earliestTimestampInclusive));
            }
            message.earliestTimestampInclusive = timing$1.v1.Timestamp.fromObject(object.earliestTimestampInclusive);
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetGroupedErrorRequest.latestTimestampExclusive: object expected, but got " + (typeof object.latestTimestampExclusive));
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(object.latestTimestampExclusive);
          }
          if (object.errorHash != null) {
            if ($util.Long) {
              (message.errorHash = $util.Long.fromValue(object.errorHash)).unsigned = false;
            } else if (typeof object.errorHash === "string") {
              message.errorHash = parseInt(object.errorHash, 10);
            } else if (typeof object.errorHash === "number") {
              message.errorHash = object.errorHash;
            } else if (typeof object.errorHash === "object") {
              message.errorHash = new $util.LongBits(object.errorHash.low >>> 0, object.errorHash.high >>> 0).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.earliestTimestampInclusive = null;
            object.latestTimestampExclusive = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.errorHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.errorHash = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.earliestTimestampInclusive != null && message.hasOwnProperty("earliestTimestampInclusive")) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(message.earliestTimestampInclusive, options);
          }
          if (message.latestTimestampExclusive != null && message.hasOwnProperty("latestTimestampExclusive")) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(message.latestTimestampExclusive, options);
          }
          if (message.errorHash != null && message.hasOwnProperty("errorHash")) {
            object.errorHash = typeof message.errorHash === "number" ? (options.longs === String ? String(message.errorHash) : message.errorHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.errorHash) : options.longs === Number ? new $util.LongBits(message.errorHash.low >>> 0, message.errorHash.high >>> 0).toNumber() : message.errorHash);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetGroupedErrorRequest";
        }
      }

      GetGroupedErrorRequest.prototype.earliestTimestampInclusive = null;
      GetGroupedErrorRequest.prototype.latestTimestampExclusive = null;
      GetGroupedErrorRequest.prototype.errorHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      return GetGroupedErrorRequest;
    })();

    v2.GetGroupedErrorResponse = (() => {
      class GetGroupedErrorResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetGroupedErrorResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.groupedError != null && Object.hasOwnProperty.call(message, "groupedError")) {
            $root.workflow_monitoring.v2.GroupedErrorData.encode(message.groupedError, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetGroupedErrorResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.groupedError = $root.workflow_monitoring.v2.GroupedErrorData.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetGroupedErrorResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetGroupedErrorResponse();
          if (object.groupedError != null) {
            if (typeof object.groupedError !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetGroupedErrorResponse.groupedError: object expected, but got " + (typeof object.groupedError));
            }
            message.groupedError = $root.workflow_monitoring.v2.GroupedErrorData.fromObject(object.groupedError);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.groupedError = null;
          }
          let keys;
          if (message.groupedError != null && message.hasOwnProperty("groupedError")) {
            object.groupedError = $root.workflow_monitoring.v2.GroupedErrorData.toObject(message.groupedError, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetGroupedErrorResponse";
        }
      }

      GetGroupedErrorResponse.prototype.groupedError = null;

      return GetGroupedErrorResponse;
    })();

    v2.ListGroupedErrorsRequest = (() => {
      class ListGroupedErrorsRequest {
        constructor(properties) {
          this.errorClasses = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListGroupedErrorsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.earliestTimestampInclusive != null && Object.hasOwnProperty.call(message, "earliestTimestampInclusive")) {
            timing$1.v1.Timestamp.encode(message.earliestTimestampInclusive, writer.uint32(10).fork()).ldelim();
          }
          if (message.latestTimestampExclusive != null && Object.hasOwnProperty.call(message, "latestTimestampExclusive")) {
            timing$1.v1.Timestamp.encode(message.latestTimestampExclusive, writer.uint32(18).fork()).ldelim();
          }
          if (message.errorClasses != null && Object.hasOwnProperty.call(message, "errorClasses")) {
            writer.uint32(26).fork();
            for (const element of message.errorClasses) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListGroupedErrorsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.earliestTimestampInclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                if (!message.errorClasses || !message.errorClasses.length) {
                  message.errorClasses = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.errorClasses.push(reader.int32());
                } else {
                  message.errorClasses.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListGroupedErrorsRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListGroupedErrorsRequest();
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsRequest.earliestTimestampInclusive: object expected, but got " + (typeof object.earliestTimestampInclusive));
            }
            message.earliestTimestampInclusive = timing$1.v1.Timestamp.fromObject(object.earliestTimestampInclusive);
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsRequest.latestTimestampExclusive: object expected, but got " + (typeof object.latestTimestampExclusive));
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(object.latestTimestampExclusive);
          }
          if (object.errorClasses) {
            if (!Array.isArray(object.errorClasses)) {
              throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsRequest.errorClasses: array type expected, but got " + (typeof object.errorClasses))
            }
            message.errorClasses = new Array(object.errorClasses.length);
            for (let i = 0; i < object.errorClasses.length; ++i) {
              switch (object.errorClasses[i]) {
                case "UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS":
                case 0: {
                  message.errorClasses[i] = 0;
                  break;
                }
                case "ERROR":
                case 1: {
                  message.errorClasses[i] = 1;
                  break;
                }
                case "WARNING":
                case 2: {
                  message.errorClasses[i] = 2;
                  break;
                }
                case "INTERNAL_ERROR":
                case 4: {
                  message.errorClasses[i] = 4;
                  break;
                }
                default: {
                  if (typeof object.errorClasses[i] == "number") {
                    message.errorClasses[i] = object.errorClasses[i];
                    break;
                  }
                  message.errorClasses[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errorClasses = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.earliestTimestampInclusive = null;
            object.latestTimestampExclusive = null;
          }
          let keys;
          if (message.earliestTimestampInclusive != null && message.hasOwnProperty("earliestTimestampInclusive")) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(message.earliestTimestampInclusive, options);
          }
          if (message.latestTimestampExclusive != null && message.hasOwnProperty("latestTimestampExclusive")) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(message.latestTimestampExclusive, options);
          }
          if (message.errorClasses && message.errorClasses.length) {
            object.errorClasses = new Array(message.errorClasses.length);
            for (let i = 0; i < message.errorClasses.length; ++i) {
              object.errorClasses[i] = options.enums === String ? workflow_evaluation_results$1.v1.ErrorClass[message.errorClasses[i]] === undefined ? message.errorClasses[i] : workflow_evaluation_results$1.v1.ErrorClass[message.errorClasses[i]] : message.errorClasses[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListGroupedErrorsRequest";
        }
      }

      ListGroupedErrorsRequest.prototype.earliestTimestampInclusive = null;
      ListGroupedErrorsRequest.prototype.latestTimestampExclusive = null;
      ListGroupedErrorsRequest.prototype.errorClasses = $util.emptyArray;

      return ListGroupedErrorsRequest;
    })();

    v2.CountsAtTime = (() => {
      class CountsAtTime {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CountsAtTime(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.binStartTimestamp != null && Object.hasOwnProperty.call(message, "binStartTimestamp")) {
            timing$1.v1.Timestamp.encode(message.binStartTimestamp, writer.uint32(10).fork()).ldelim();
          }
          if (message.counts != null && Object.hasOwnProperty.call(message, "counts")) {
            writer.uint32(16).uint64(message.counts);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CountsAtTime();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.binStartTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.counts = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.CountsAtTime) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.CountsAtTime();
          if (object.binStartTimestamp != null) {
            if (typeof object.binStartTimestamp !== "object") {
              throw new TypeError(".workflow_monitoring.v2.CountsAtTime.binStartTimestamp: object expected, but got " + (typeof object.binStartTimestamp));
            }
            message.binStartTimestamp = timing$1.v1.Timestamp.fromObject(object.binStartTimestamp);
          }
          if (object.counts != null) {
            if ($util.Long) {
              (message.counts = $util.Long.fromValue(object.counts)).unsigned = true;
            } else if (typeof object.counts === "string") {
              message.counts = parseInt(object.counts, 10);
            } else if (typeof object.counts === "number") {
              message.counts = object.counts;
            } else if (typeof object.counts === "object") {
              message.counts = new $util.LongBits(object.counts.low >>> 0, object.counts.high >>> 0).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.binStartTimestamp = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.counts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.counts = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.binStartTimestamp != null && message.hasOwnProperty("binStartTimestamp")) {
            object.binStartTimestamp = timing$1.v1.Timestamp.toObject(message.binStartTimestamp, options);
          }
          if (message.counts != null && message.hasOwnProperty("counts")) {
            object.counts = typeof message.counts === "number" ? (options.longs === String ? String(message.counts) : message.counts) : (options.longs === String ? $util.Long.prototype.toString.call(message.counts) : options.longs === Number ? new $util.LongBits(message.counts.low >>> 0, message.counts.high >>> 0).toNumber(true) : message.counts);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.CountsAtTime";
        }
      }

      CountsAtTime.prototype.binStartTimestamp = null;
      CountsAtTime.prototype.counts = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

      return CountsAtTime;
    })();

    v2.ListGroupedErrorsResponse = (() => {
      class ListGroupedErrorsResponse {
        constructor(properties) {
          this.groupedErrors = [];
          this.summedGroupedErrorCounts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListGroupedErrorsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.groupedErrors != null && Object.hasOwnProperty.call(message, "groupedErrors")) {
            for (const element of message.groupedErrors) {
              $root.workflow_monitoring.v2.GroupedErrorData.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.summedGroupedErrorCounts != null && Object.hasOwnProperty.call(message, "summedGroupedErrorCounts")) {
            for (const element of message.summedGroupedErrorCounts) {
              $root.workflow_monitoring.v2.CountsAtTime.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListGroupedErrorsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.groupedErrors || !message.groupedErrors.length) {
                  message.groupedErrors = [];
                }
                message.groupedErrors.push($root.workflow_monitoring.v2.GroupedErrorData.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                if (!message.summedGroupedErrorCounts || !message.summedGroupedErrorCounts.length) {
                  message.summedGroupedErrorCounts = [];
                }
                message.summedGroupedErrorCounts.push($root.workflow_monitoring.v2.CountsAtTime.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListGroupedErrorsResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListGroupedErrorsResponse();
          if (object.groupedErrors) {
            if (!Array.isArray(object.groupedErrors)) {
              throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsResponse.groupedErrors: array type expected, but got " + (typeof object.groupedErrors))
            }
            message.groupedErrors = new Array(object.groupedErrors.length);
            for (let i = 0; i < object.groupedErrors.length; ++i) {
              if (typeof object.groupedErrors[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsResponse.groupedErrors: object expected, but got " + (typeof object.groupedErrors[i]));
              }
              message.groupedErrors[i] = $root.workflow_monitoring.v2.GroupedErrorData.fromObject(object.groupedErrors[i]);
            }
          }
          if (object.summedGroupedErrorCounts) {
            if (!Array.isArray(object.summedGroupedErrorCounts)) {
              throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsResponse.summedGroupedErrorCounts: array type expected, but got " + (typeof object.summedGroupedErrorCounts))
            }
            message.summedGroupedErrorCounts = new Array(object.summedGroupedErrorCounts.length);
            for (let i = 0; i < object.summedGroupedErrorCounts.length; ++i) {
              if (typeof object.summedGroupedErrorCounts[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListGroupedErrorsResponse.summedGroupedErrorCounts: object expected, but got " + (typeof object.summedGroupedErrorCounts[i]));
              }
              message.summedGroupedErrorCounts[i] = $root.workflow_monitoring.v2.CountsAtTime.fromObject(object.summedGroupedErrorCounts[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.groupedErrors = [];
            object.summedGroupedErrorCounts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.groupedErrors && message.groupedErrors.length) {
            object.groupedErrors = new Array(message.groupedErrors.length);
            for (let i = 0; i < message.groupedErrors.length; ++i) {
              object.groupedErrors[i] = $root.workflow_monitoring.v2.GroupedErrorData.toObject(message.groupedErrors[i], options);
            }
          }
          if (message.summedGroupedErrorCounts && message.summedGroupedErrorCounts.length) {
            object.summedGroupedErrorCounts = new Array(message.summedGroupedErrorCounts.length);
            for (let i = 0; i < message.summedGroupedErrorCounts.length; ++i) {
              object.summedGroupedErrorCounts[i] = $root.workflow_monitoring.v2.CountsAtTime.toObject(message.summedGroupedErrorCounts[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListGroupedErrorsResponse";
        }
      }

      ListGroupedErrorsResponse.prototype.groupedErrors = $util.emptyArray;
      ListGroupedErrorsResponse.prototype.summedGroupedErrorCounts = $util.emptyArray;

      return ListGroupedErrorsResponse;
    })();

    v2.GetWorkflowExecutionRequest = (() => {
      class GetWorkflowExecutionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowExecutionRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowExecutionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetWorkflowExecutionRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetWorkflowExecutionRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = "";
          }
          let keys;
          if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetWorkflowExecutionRequest";
        }
      }

      GetWorkflowExecutionRequest.prototype.workflowExecutionId = "";

      return GetWorkflowExecutionRequest;
    })();

    v2.GetWorkflowExecutionResponse = (() => {
      class GetWorkflowExecutionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowExecutionResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.execution != null && Object.hasOwnProperty.call(message, "execution")) {
            $root.workflow_monitoring.v2.WorkflowExecutionSummary.encode(message.execution, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowExecutionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.execution = $root.workflow_monitoring.v2.WorkflowExecutionSummary.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetWorkflowExecutionResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetWorkflowExecutionResponse();
          if (object.execution != null) {
            if (typeof object.execution !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetWorkflowExecutionResponse.execution: object expected, but got " + (typeof object.execution));
            }
            message.execution = $root.workflow_monitoring.v2.WorkflowExecutionSummary.fromObject(object.execution);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.execution = null;
          }
          let keys;
          if (message.execution != null && message.hasOwnProperty("execution")) {
            object.execution = $root.workflow_monitoring.v2.WorkflowExecutionSummary.toObject(message.execution, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetWorkflowExecutionResponse";
        }
      }

      GetWorkflowExecutionResponse.prototype.execution = null;

      return GetWorkflowExecutionResponse;
    })();

    v2.ListWorkflowExecutionsRequest = (() => {
      class ListWorkflowExecutionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowExecutionsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.earliestTimestampInclusive != null && Object.hasOwnProperty.call(message, "earliestTimestampInclusive")) {
            timing$1.v1.Timestamp.encode(message.earliestTimestampInclusive, writer.uint32(10).fork()).ldelim();
          }
          if (message.latestTimestampExclusive != null && Object.hasOwnProperty.call(message, "latestTimestampExclusive")) {
            timing$1.v1.Timestamp.encode(message.latestTimestampExclusive, writer.uint32(18).fork()).ldelim();
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(24).uint64(message.limit);
          }
          if (message.filter != null && Object.hasOwnProperty.call(message, "filter")) {
            $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.encode(message.filter, writer.uint32(34).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowExecutionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.earliestTimestampInclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.limit = reader.uint64();
                break;
              }
              case 4: {
                message.filter = $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest();
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.earliestTimestampInclusive: object expected, but got " + (typeof object.earliestTimestampInclusive));
            }
            message.earliestTimestampInclusive = timing$1.v1.Timestamp.fromObject(object.earliestTimestampInclusive);
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.latestTimestampExclusive: object expected, but got " + (typeof object.latestTimestampExclusive));
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(object.latestTimestampExclusive);
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(object.limit)).unsigned = true;
            } else if (typeof object.limit === "string") {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === "number") {
              message.limit = object.limit;
            } else if (typeof object.limit === "object") {
              message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber(true);
            }
          }
          if (object.filter != null) {
            if (typeof object.filter !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.filter: object expected, but got " + (typeof object.filter));
            }
            message.filter = $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.fromObject(object.filter);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.latestTimestampExclusive = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.limit = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.earliestTimestampInclusive != null && message.hasOwnProperty("earliestTimestampInclusive")) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(message.earliestTimestampInclusive, options);
          }
          if (message.latestTimestampExclusive != null && message.hasOwnProperty("latestTimestampExclusive")) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(message.latestTimestampExclusive, options);
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = typeof message.limit === "number" ? (options.longs === String ? String(message.limit) : message.limit) : (options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber(true) : message.limit);
          }
          if (message.filter != null && message.hasOwnProperty("filter")) {
            object.filter = $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.toObject(message.filter, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListWorkflowExecutionsRequest";
        }
      }

      ListWorkflowExecutionsRequest.prototype.earliestTimestampInclusive = null;
      ListWorkflowExecutionsRequest.prototype.latestTimestampExclusive = null;
      ListWorkflowExecutionsRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      ListWorkflowExecutionsRequest.prototype.filter = null;
      ListWorkflowExecutionsRequest.Filter = (() => {
        class Filter {
          constructor(properties) {
            this.eventSnapshotIds = [];
            this.workflowIds = [];
            this.errorHashes = [];
            this.profileIndices = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Filter(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.eventSnapshotIds != null && Object.hasOwnProperty.call(message, "eventSnapshotIds")) {
              for (const element of message.eventSnapshotIds) {
                writer.uint32(10).string(element);
              }
            }
            if (message.workflowIds != null && Object.hasOwnProperty.call(message, "workflowIds")) {
              for (const element of message.workflowIds) {
                writer.uint32(18).string(element);
              }
            }
            if (message.errorHashes != null && Object.hasOwnProperty.call(message, "errorHashes")) {
              for (const element of message.errorHashes) {
                $root.workflow_monitoring.v2.ErrorHash.encode(element, writer.uint32(26).fork()).ldelim();
              }
            }
            if (message.profileIndices != null && Object.hasOwnProperty.call(message, "profileIndices")) {
              for (const element of message.profileIndices) {
                profiles$1.v1.ProfileIndex.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            if (message.errorDetailLike != null && Object.hasOwnProperty.call(message, "errorDetailLike")) {
              writer.uint32(42).string(message.errorDetailLike);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Filter();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.eventSnapshotIds || !message.eventSnapshotIds.length) {
                    message.eventSnapshotIds = [];
                  }
                  message.eventSnapshotIds.push(reader.string());
                  break;
                }
                case 2: {
                  if (!message.workflowIds || !message.workflowIds.length) {
                    message.workflowIds = [];
                  }
                  message.workflowIds.push(reader.string());
                  break;
                }
                case 3: {
                  if (!message.errorHashes || !message.errorHashes.length) {
                    message.errorHashes = [];
                  }
                  message.errorHashes.push($root.workflow_monitoring.v2.ErrorHash.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  if (!message.profileIndices || !message.profileIndices.length) {
                    message.profileIndices = [];
                  }
                  message.profileIndices.push(profiles$1.v1.ProfileIndex.decode(reader, reader.uint32()));
                  break;
                }
                case 5: {
                  message.errorDetailLike = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter();
            if (object.eventSnapshotIds) {
              if (!Array.isArray(object.eventSnapshotIds)) {
                throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.eventSnapshotIds: array type expected, but got " + (typeof object.eventSnapshotIds))
              }
              message.eventSnapshotIds = new Array(object.eventSnapshotIds.length);
              for (let i = 0; i < object.eventSnapshotIds.length; ++i) {
                message.eventSnapshotIds[i] = String(object.eventSnapshotIds[i]);
              }
            }
            if (object.workflowIds) {
              if (!Array.isArray(object.workflowIds)) {
                throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.workflowIds: array type expected, but got " + (typeof object.workflowIds))
              }
              message.workflowIds = new Array(object.workflowIds.length);
              for (let i = 0; i < object.workflowIds.length; ++i) {
                message.workflowIds[i] = String(object.workflowIds[i]);
              }
            }
            if (object.errorHashes) {
              if (!Array.isArray(object.errorHashes)) {
                throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.errorHashes: array type expected, but got " + (typeof object.errorHashes))
              }
              message.errorHashes = new Array(object.errorHashes.length);
              for (let i = 0; i < object.errorHashes.length; ++i) {
                if (typeof object.errorHashes[i] !== "object") {
                  throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.errorHashes: object expected, but got " + (typeof object.errorHashes[i]));
                }
                message.errorHashes[i] = $root.workflow_monitoring.v2.ErrorHash.fromObject(object.errorHashes[i]);
              }
            }
            if (object.profileIndices) {
              if (!Array.isArray(object.profileIndices)) {
                throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.profileIndices: array type expected, but got " + (typeof object.profileIndices))
              }
              message.profileIndices = new Array(object.profileIndices.length);
              for (let i = 0; i < object.profileIndices.length; ++i) {
                if (typeof object.profileIndices[i] !== "object") {
                  throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter.profileIndices: object expected, but got " + (typeof object.profileIndices[i]));
                }
                message.profileIndices[i] = profiles$1.v1.ProfileIndex.fromObject(object.profileIndices[i]);
              }
            }
            if (object.errorDetailLike != null) {
              message.errorDetailLike = String(object.errorDetailLike);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.eventSnapshotIds = [];
              object.workflowIds = [];
              object.errorHashes = [];
              object.profileIndices = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.eventSnapshotIds && message.eventSnapshotIds.length) {
              object.eventSnapshotIds = new Array(message.eventSnapshotIds.length);
              for (let i = 0; i < message.eventSnapshotIds.length; ++i) {
                object.eventSnapshotIds[i] = message.eventSnapshotIds[i];
              }
            }
            if (message.workflowIds && message.workflowIds.length) {
              object.workflowIds = new Array(message.workflowIds.length);
              for (let i = 0; i < message.workflowIds.length; ++i) {
                object.workflowIds[i] = message.workflowIds[i];
              }
            }
            if (message.errorHashes && message.errorHashes.length) {
              object.errorHashes = new Array(message.errorHashes.length);
              for (let i = 0; i < message.errorHashes.length; ++i) {
                object.errorHashes[i] = $root.workflow_monitoring.v2.ErrorHash.toObject(message.errorHashes[i], options);
              }
            }
            if (message.profileIndices && message.profileIndices.length) {
              object.profileIndices = new Array(message.profileIndices.length);
              for (let i = 0; i < message.profileIndices.length; ++i) {
                object.profileIndices[i] = profiles$1.v1.ProfileIndex.toObject(message.profileIndices[i], options);
              }
            }
            if (message.errorDetailLike != null && message.hasOwnProperty("errorDetailLike")) {
              object.errorDetailLike = message.errorDetailLike;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ListWorkflowExecutionsRequest.Filter";
          }
        }

        Filter.prototype.eventSnapshotIds = $util.emptyArray;
        Filter.prototype.workflowIds = $util.emptyArray;
        Filter.prototype.errorHashes = $util.emptyArray;
        Filter.prototype.profileIndices = $util.emptyArray;
        Filter.prototype.errorDetailLike = null;

        return Filter;
      })();

      return ListWorkflowExecutionsRequest;
    })();

    v2.ListWorkflowExecutionsResponse = (() => {
      class ListWorkflowExecutionsResponse {
        constructor(properties) {
          this.executions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowExecutionsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.executions != null && Object.hasOwnProperty.call(message, "executions")) {
            for (const element of message.executions) {
              $root.workflow_monitoring.v2.WorkflowExecutionSummary.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.earliestFetchedTimestamp != null && Object.hasOwnProperty.call(message, "earliestFetchedTimestamp")) {
            timing$1.v1.Timestamp.encode(message.earliestFetchedTimestamp, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowExecutionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.executions || !message.executions.length) {
                  message.executions = [];
                }
                message.executions.push($root.workflow_monitoring.v2.WorkflowExecutionSummary.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListWorkflowExecutionsResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListWorkflowExecutionsResponse();
          if (object.executions) {
            if (!Array.isArray(object.executions)) {
              throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsResponse.executions: array type expected, but got " + (typeof object.executions))
            }
            message.executions = new Array(object.executions.length);
            for (let i = 0; i < object.executions.length; ++i) {
              if (typeof object.executions[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsResponse.executions: object expected, but got " + (typeof object.executions[i]));
              }
              message.executions[i] = $root.workflow_monitoring.v2.WorkflowExecutionSummary.fromObject(object.executions[i]);
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionsResponse.earliestFetchedTimestamp: object expected, but got " + (typeof object.earliestFetchedTimestamp));
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(object.earliestFetchedTimestamp);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.executions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.executions && message.executions.length) {
            object.executions = new Array(message.executions.length);
            for (let i = 0; i < message.executions.length; ++i) {
              object.executions[i] = $root.workflow_monitoring.v2.WorkflowExecutionSummary.toObject(message.executions[i], options);
            }
          }
          if (message.earliestFetchedTimestamp != null && message.hasOwnProperty("earliestFetchedTimestamp")) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(message.earliestFetchedTimestamp, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListWorkflowExecutionsResponse";
        }
      }

      ListWorkflowExecutionsResponse.prototype.executions = $util.emptyArray;
      ListWorkflowExecutionsResponse.prototype.earliestFetchedTimestamp = null;

      return ListWorkflowExecutionsResponse;
    })();

    v2.TemporalWorkflowExecution = (() => {
      class TemporalWorkflowExecution {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TemporalWorkflowExecution(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.temporalWorkflowId != null && Object.hasOwnProperty.call(message, "temporalWorkflowId")) {
            writer.uint32(10).string(message.temporalWorkflowId);
          }
          if (message.temporalWorkflowRunId != null && Object.hasOwnProperty.call(message, "temporalWorkflowRunId")) {
            writer.uint32(18).string(message.temporalWorkflowRunId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TemporalWorkflowExecution();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.temporalWorkflowId = reader.string();
                break;
              }
              case 2: {
                message.temporalWorkflowRunId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.TemporalWorkflowExecution) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.TemporalWorkflowExecution();
          if (object.temporalWorkflowId != null) {
            message.temporalWorkflowId = String(object.temporalWorkflowId);
          }
          if (object.temporalWorkflowRunId != null) {
            message.temporalWorkflowRunId = String(object.temporalWorkflowRunId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.temporalWorkflowId = "";
            object.temporalWorkflowRunId = "";
          }
          let keys;
          if (message.temporalWorkflowId != null && message.hasOwnProperty("temporalWorkflowId")) {
            object.temporalWorkflowId = message.temporalWorkflowId;
          }
          if (message.temporalWorkflowRunId != null && message.hasOwnProperty("temporalWorkflowRunId")) {
            object.temporalWorkflowRunId = message.temporalWorkflowRunId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.TemporalWorkflowExecution";
        }
      }

      TemporalWorkflowExecution.prototype.temporalWorkflowId = "";
      TemporalWorkflowExecution.prototype.temporalWorkflowRunId = "";

      return TemporalWorkflowExecution;
    })();

    v2.WorkflowError = (() => {
      class WorkflowError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowError(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.errorHash != null && Object.hasOwnProperty.call(message, "errorHash")) {
            writer.uint32(8).int64(message.errorHash);
          }
          if (message.errorClass != null && Object.hasOwnProperty.call(message, "errorClass")) {
            writer.uint32(16).int32(message.errorClass);
          }
          if (message.errorString != null && Object.hasOwnProperty.call(message, "errorString")) {
            writer.uint32(26).string(message.errorString);
          }
          if (message.errorDetail != null && Object.hasOwnProperty.call(message, "errorDetail")) {
            writer.uint32(34).string(message.errorDetail);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.errorHash = reader.int64();
                break;
              }
              case 2: {
                message.errorClass = reader.int32();
                break;
              }
              case 3: {
                message.errorString = reader.string();
                break;
              }
              case 4: {
                message.errorDetail = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.WorkflowError) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.WorkflowError();
          if (object.errorHash != null) {
            if ($util.Long) {
              (message.errorHash = $util.Long.fromValue(object.errorHash)).unsigned = false;
            } else if (typeof object.errorHash === "string") {
              message.errorHash = parseInt(object.errorHash, 10);
            } else if (typeof object.errorHash === "number") {
              message.errorHash = object.errorHash;
            } else if (typeof object.errorHash === "object") {
              message.errorHash = new $util.LongBits(object.errorHash.low >>> 0, object.errorHash.high >>> 0).toNumber();
            }
          }
          if (object.errorClass != null) {
            switch (object.errorClass) {
              case "UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS":
              case 0: {
                message.errorClass = 0;
                break;
              }
              case "ERROR":
              case 1: {
                message.errorClass = 1;
                break;
              }
              case "WARNING":
              case 2: {
                message.errorClass = 2;
                break;
              }
              case "INTERNAL_ERROR":
              case 4: {
                message.errorClass = 4;
                break;
              }
              default: {
                if (typeof object.errorClass == "number") {
                  message.errorClass = object.errorClass;
                  break;
                }
                break;
              }
            }
          }
          if (object.errorString != null) {
            message.errorString = String(object.errorString);
          }
          if (object.errorDetail != null) {
            message.errorDetail = String(object.errorDetail);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.errorHash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.errorHash = options.longs === String ? "0" : 0;
            }
            object.errorClass = options.enums === String ? "UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS" : 0
            object.errorString = "";
            object.errorDetail = "";
          }
          let keys;
          if (message.errorHash != null && message.hasOwnProperty("errorHash")) {
            object.errorHash = typeof message.errorHash === "number" ? (options.longs === String ? String(message.errorHash) : message.errorHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.errorHash) : options.longs === Number ? new $util.LongBits(message.errorHash.low >>> 0, message.errorHash.high >>> 0).toNumber() : message.errorHash);
          }
          if (message.errorClass != null && message.hasOwnProperty("errorClass")) {
            object.errorClass = options.enums === String ? workflow_evaluation_results$1.v1.ErrorClass[message.errorClass] === undefined ? message.errorClass : workflow_evaluation_results$1.v1.ErrorClass[message.errorClass] : message.errorClass;
          }
          if (message.errorString != null && message.hasOwnProperty("errorString")) {
            object.errorString = message.errorString;
          }
          if (message.errorDetail != null && message.hasOwnProperty("errorDetail")) {
            object.errorDetail = message.errorDetail;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.WorkflowError";
        }
      }

      WorkflowError.prototype.errorHash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      WorkflowError.prototype.errorClass = 0;
      WorkflowError.prototype.errorString = "";
      WorkflowError.prototype.errorDetail = "";

      return WorkflowError;
    })();

    v2.WorkflowExecutionSummary = (() => {
      class WorkflowExecutionSummary {
        constructor(properties) {
          this.workflowErrors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowExecutionSummary(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
            writer.uint32(10).string(message.eventSnapshotId);
          }
          if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
            writer.uint32(18).string(message.workflowExecutionId);
          }
          if (message.workflowId != null && Object.hasOwnProperty.call(message, "workflowId")) {
            writer.uint32(26).string(message.workflowId);
          }
          if (message.workflowVersion != null && Object.hasOwnProperty.call(message, "workflowVersion")) {
            writer.uint32(32).int64(message.workflowVersion);
          }
          if (message.workflowName != null && Object.hasOwnProperty.call(message, "workflowName")) {
            writer.uint32(42).string(message.workflowName);
          }
          if (message.applicationType != null && Object.hasOwnProperty.call(message, "applicationType")) {
            writer.uint32(48).int32(message.applicationType);
          }
          if (message.temporalWorkflowExecution != null && Object.hasOwnProperty.call(message, "temporalWorkflowExecution")) {
            $root.workflow_monitoring.v2.TemporalWorkflowExecution.encode(message.temporalWorkflowExecution, writer.uint32(58).fork()).ldelim();
          }
          if (message.supersededWorkflowExecutionId != null && Object.hasOwnProperty.call(message, "supersededWorkflowExecutionId")) {
            writer.uint32(66).string(message.supersededWorkflowExecutionId);
          }
          if (message.supersededByWorkflowExecutionId != null && Object.hasOwnProperty.call(message, "supersededByWorkflowExecutionId")) {
            writer.uint32(74).string(message.supersededByWorkflowExecutionId);
          }
          if (message.retryNumber != null && Object.hasOwnProperty.call(message, "retryNumber")) {
            writer.uint32(80).uint64(message.retryNumber);
          }
          if (message.retryOriginalWorkflowExecutionId != null && Object.hasOwnProperty.call(message, "retryOriginalWorkflowExecutionId")) {
            writer.uint32(90).string(message.retryOriginalWorkflowExecutionId);
          }
          if (message.matchedProfileIndex != null && Object.hasOwnProperty.call(message, "matchedProfileIndex")) {
            profiles$1.v1.ProfileIndex.encode(message.matchedProfileIndex, writer.uint32(98).fork()).ldelim();
          }
          if (message.instantaneousMatchedProfileEmail != null && Object.hasOwnProperty.call(message, "instantaneousMatchedProfileEmail")) {
            writer.uint32(106).string(message.instantaneousMatchedProfileEmail);
          }
          if (message.instantaneousMatchedProfilePhone != null && Object.hasOwnProperty.call(message, "instantaneousMatchedProfilePhone")) {
            writer.uint32(114).string(message.instantaneousMatchedProfilePhone);
          }
          if (message.triggeredAtTime != null && Object.hasOwnProperty.call(message, "triggeredAtTime")) {
            timing$1.v1.Timestamp.encode(message.triggeredAtTime, writer.uint32(122).fork()).ldelim();
          }
          if (message.executionStartTime != null && Object.hasOwnProperty.call(message, "executionStartTime")) {
            timing$1.v1.Timestamp.encode(message.executionStartTime, writer.uint32(130).fork()).ldelim();
          }
          if (message.executionEndTime != null && Object.hasOwnProperty.call(message, "executionEndTime")) {
            timing$1.v1.Timestamp.encode(message.executionEndTime, writer.uint32(138).fork()).ldelim();
          }
          if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
            writer.uint32(144).int32(message.status);
          }
          if (message.workflowErrors != null && Object.hasOwnProperty.call(message, "workflowErrors")) {
            for (const element of message.workflowErrors) {
              $root.workflow_monitoring.v2.WorkflowError.encode(element, writer.uint32(154).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowExecutionSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 2: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 3: {
                message.workflowId = reader.string();
                break;
              }
              case 4: {
                message.workflowVersion = reader.int64();
                break;
              }
              case 5: {
                message.workflowName = reader.string();
                break;
              }
              case 6: {
                message.applicationType = reader.int32();
                break;
              }
              case 7: {
                message.temporalWorkflowExecution = $root.workflow_monitoring.v2.TemporalWorkflowExecution.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.supersededWorkflowExecutionId = reader.string();
                break;
              }
              case 9: {
                message.supersededByWorkflowExecutionId = reader.string();
                break;
              }
              case 10: {
                message.retryNumber = reader.uint64();
                break;
              }
              case 11: {
                message.retryOriginalWorkflowExecutionId = reader.string();
                break;
              }
              case 12: {
                message.matchedProfileIndex = profiles$1.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.instantaneousMatchedProfileEmail = reader.string();
                break;
              }
              case 14: {
                message.instantaneousMatchedProfilePhone = reader.string();
                break;
              }
              case 15: {
                message.triggeredAtTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.executionStartTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.executionEndTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.status = reader.int32();
                break;
              }
              case 19: {
                if (!message.workflowErrors || !message.workflowErrors.length) {
                  message.workflowErrors = [];
                }
                message.workflowErrors.push($root.workflow_monitoring.v2.WorkflowError.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.WorkflowExecutionSummary) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.WorkflowExecutionSummary();
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.workflowVersion != null) {
            if ($util.Long) {
              (message.workflowVersion = $util.Long.fromValue(object.workflowVersion)).unsigned = false;
            } else if (typeof object.workflowVersion === "string") {
              message.workflowVersion = parseInt(object.workflowVersion, 10);
            } else if (typeof object.workflowVersion === "number") {
              message.workflowVersion = object.workflowVersion;
            } else if (typeof object.workflowVersion === "object") {
              message.workflowVersion = new $util.LongBits(object.workflowVersion.low >>> 0, object.workflowVersion.high >>> 0).toNumber();
            }
          }
          if (object.workflowName != null) {
            message.workflowName = String(object.workflowName);
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case "UNSPECIFIED_SOURCE_APPLICATION":
              case 0: {
                message.applicationType = 0;
                break;
              }
              case "FORMSORT":
              case 1: {
                message.applicationType = 1;
                break;
              }
              case "HEALTHIE":
              case 2: {
                message.applicationType = 2;
                break;
              }
              case "AXLE_HEALTH":
              case 3: {
                message.applicationType = 3;
                break;
              }
              case "BUTTERFLY_LABS":
              case 4: {
                message.applicationType = 4;
                break;
              }
              case "MORF":
              case 5: {
                message.applicationType = 5;
                break;
              }
              case "PUSH_JSON":
              case 6: {
                message.applicationType = 6;
                break;
              }
              case "ACUITY":
              case 7: {
                message.applicationType = 7;
                break;
              }
              case "VITAL":
              case 8: {
                message.applicationType = 8;
                break;
              }
              case "SEGMENT":
              case 9: {
                message.applicationType = 9;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 10: {
                message.applicationType = 10;
                break;
              }
              case "INTAKEQ":
              case 11: {
                message.applicationType = 11;
                break;
              }
              case "CUSTOMER_IO":
              case 12: {
                message.applicationType = 12;
                break;
              }
              case "HUBSPOT":
              case 13: {
                message.applicationType = 13;
                break;
              }
              case "CUSTOMER":
              case 14: {
                message.applicationType = 14;
                break;
              }
              case "CALENDLY":
              case 15: {
                message.applicationType = 15;
                break;
              }
              default: {
                if (typeof object.applicationType == "number") {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.temporalWorkflowExecution != null) {
            if (typeof object.temporalWorkflowExecution !== "object") {
              throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.temporalWorkflowExecution: object expected, but got " + (typeof object.temporalWorkflowExecution));
            }
            message.temporalWorkflowExecution = $root.workflow_monitoring.v2.TemporalWorkflowExecution.fromObject(object.temporalWorkflowExecution);
          }
          if (object.supersededWorkflowExecutionId != null) {
            message.supersededWorkflowExecutionId = String(object.supersededWorkflowExecutionId);
          }
          if (object.supersededByWorkflowExecutionId != null) {
            message.supersededByWorkflowExecutionId = String(object.supersededByWorkflowExecutionId);
          }
          if (object.retryNumber != null) {
            if ($util.Long) {
              (message.retryNumber = $util.Long.fromValue(object.retryNumber)).unsigned = true;
            } else if (typeof object.retryNumber === "string") {
              message.retryNumber = parseInt(object.retryNumber, 10);
            } else if (typeof object.retryNumber === "number") {
              message.retryNumber = object.retryNumber;
            } else if (typeof object.retryNumber === "object") {
              message.retryNumber = new $util.LongBits(object.retryNumber.low >>> 0, object.retryNumber.high >>> 0).toNumber(true);
            }
          }
          if (object.retryOriginalWorkflowExecutionId != null) {
            message.retryOriginalWorkflowExecutionId = String(object.retryOriginalWorkflowExecutionId);
          }
          if (object.matchedProfileIndex != null) {
            if (typeof object.matchedProfileIndex !== "object") {
              throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.matchedProfileIndex: object expected, but got " + (typeof object.matchedProfileIndex));
            }
            message.matchedProfileIndex = profiles$1.v1.ProfileIndex.fromObject(object.matchedProfileIndex);
          }
          if (object.instantaneousMatchedProfileEmail != null) {
            message.instantaneousMatchedProfileEmail = String(object.instantaneousMatchedProfileEmail);
          }
          if (object.instantaneousMatchedProfilePhone != null) {
            message.instantaneousMatchedProfilePhone = String(object.instantaneousMatchedProfilePhone);
          }
          if (object.triggeredAtTime != null) {
            if (typeof object.triggeredAtTime !== "object") {
              throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.triggeredAtTime: object expected, but got " + (typeof object.triggeredAtTime));
            }
            message.triggeredAtTime = timing$1.v1.Timestamp.fromObject(object.triggeredAtTime);
          }
          if (object.executionStartTime != null) {
            if (typeof object.executionStartTime !== "object") {
              throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.executionStartTime: object expected, but got " + (typeof object.executionStartTime));
            }
            message.executionStartTime = timing$1.v1.Timestamp.fromObject(object.executionStartTime);
          }
          if (object.executionEndTime != null) {
            if (typeof object.executionEndTime !== "object") {
              throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.executionEndTime: object expected, but got " + (typeof object.executionEndTime));
            }
            message.executionEndTime = timing$1.v1.Timestamp.fromObject(object.executionEndTime);
          }
          if (object.status != null) {
            switch (object.status) {
              case "UNSPECIFIED_WORKFLOW_EXECUTION_STATUS":
              case 0: {
                message.status = 0;
                break;
              }
              case "SKIPPED":
              case 1: {
                message.status = 1;
                break;
              }
              case "TRIGGERED":
              case 2: {
                message.status = 2;
                break;
              }
              case "PROCESSING":
              case 3: {
                message.status = 3;
                break;
              }
              case "RETRYING":
              case 4: {
                message.status = 4;
                break;
              }
              case "SUPERSEDED":
              case 5: {
                message.status = 5;
                break;
              }
              case "EXECUTED":
              case 6: {
                message.status = 6;
                break;
              }
              case "FAILED":
              case 7: {
                message.status = 7;
                break;
              }
              default: {
                if (typeof object.status == "number") {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.workflowErrors) {
            if (!Array.isArray(object.workflowErrors)) {
              throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.workflowErrors: array type expected, but got " + (typeof object.workflowErrors))
            }
            message.workflowErrors = new Array(object.workflowErrors.length);
            for (let i = 0; i < object.workflowErrors.length; ++i) {
              if (typeof object.workflowErrors[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.WorkflowExecutionSummary.workflowErrors: object expected, but got " + (typeof object.workflowErrors[i]));
              }
              message.workflowErrors[i] = $root.workflow_monitoring.v2.WorkflowError.fromObject(object.workflowErrors[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflowErrors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventSnapshotId = "";
            object.workflowExecutionId = "";
            object.workflowId = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.workflowVersion = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.workflowVersion = options.longs === String ? "0" : 0;
            }
            object.workflowName = "";
            object.applicationType = options.enums === String ? "UNSPECIFIED_SOURCE_APPLICATION" : 0
            object.matchedProfileIndex = null;
            object.triggeredAtTime = null;
            object.status = options.enums === String ? "UNSPECIFIED_WORKFLOW_EXECUTION_STATUS" : 0
          }
          let keys;
          if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (message.workflowId != null && message.hasOwnProperty("workflowId")) {
            object.workflowId = message.workflowId;
          }
          if (message.workflowVersion != null && message.hasOwnProperty("workflowVersion")) {
            object.workflowVersion = typeof message.workflowVersion === "number" ? (options.longs === String ? String(message.workflowVersion) : message.workflowVersion) : (options.longs === String ? $util.Long.prototype.toString.call(message.workflowVersion) : options.longs === Number ? new $util.LongBits(message.workflowVersion.low >>> 0, message.workflowVersion.high >>> 0).toNumber() : message.workflowVersion);
          }
          if (message.workflowName != null && message.hasOwnProperty("workflowName")) {
            object.workflowName = message.workflowName;
          }
          if (message.applicationType != null && message.hasOwnProperty("applicationType")) {
            object.applicationType = options.enums === String ? source_application$1.v2.SourceApplication.Application[message.applicationType] === undefined ? message.applicationType : source_application$1.v2.SourceApplication.Application[message.applicationType] : message.applicationType;
          }
          if (message.temporalWorkflowExecution != null && message.hasOwnProperty("temporalWorkflowExecution")) {
            object.temporalWorkflowExecution = $root.workflow_monitoring.v2.TemporalWorkflowExecution.toObject(message.temporalWorkflowExecution, options);
          }
          if (message.supersededWorkflowExecutionId != null && message.hasOwnProperty("supersededWorkflowExecutionId")) {
            object.supersededWorkflowExecutionId = message.supersededWorkflowExecutionId;
          }
          if (message.supersededByWorkflowExecutionId != null && message.hasOwnProperty("supersededByWorkflowExecutionId")) {
            object.supersededByWorkflowExecutionId = message.supersededByWorkflowExecutionId;
          }
          if (message.retryNumber != null && message.hasOwnProperty("retryNumber")) {
            object.retryNumber = typeof message.retryNumber === "number" ? (options.longs === String ? String(message.retryNumber) : message.retryNumber) : (options.longs === String ? $util.Long.prototype.toString.call(message.retryNumber) : options.longs === Number ? new $util.LongBits(message.retryNumber.low >>> 0, message.retryNumber.high >>> 0).toNumber(true) : message.retryNumber);
          }
          if (message.retryOriginalWorkflowExecutionId != null && message.hasOwnProperty("retryOriginalWorkflowExecutionId")) {
            object.retryOriginalWorkflowExecutionId = message.retryOriginalWorkflowExecutionId;
          }
          if (message.matchedProfileIndex != null && message.hasOwnProperty("matchedProfileIndex")) {
            object.matchedProfileIndex = profiles$1.v1.ProfileIndex.toObject(message.matchedProfileIndex, options);
          }
          if (message.instantaneousMatchedProfileEmail != null && message.hasOwnProperty("instantaneousMatchedProfileEmail")) {
            object.instantaneousMatchedProfileEmail = message.instantaneousMatchedProfileEmail;
          }
          if (message.instantaneousMatchedProfilePhone != null && message.hasOwnProperty("instantaneousMatchedProfilePhone")) {
            object.instantaneousMatchedProfilePhone = message.instantaneousMatchedProfilePhone;
          }
          if (message.triggeredAtTime != null && message.hasOwnProperty("triggeredAtTime")) {
            object.triggeredAtTime = timing$1.v1.Timestamp.toObject(message.triggeredAtTime, options);
          }
          if (message.executionStartTime != null && message.hasOwnProperty("executionStartTime")) {
            object.executionStartTime = timing$1.v1.Timestamp.toObject(message.executionStartTime, options);
          }
          if (message.executionEndTime != null && message.hasOwnProperty("executionEndTime")) {
            object.executionEndTime = timing$1.v1.Timestamp.toObject(message.executionEndTime, options);
          }
          if (message.status != null && message.hasOwnProperty("status")) {
            object.status = options.enums === String ? $root.workflow_monitoring.v2.WorkflowExecutionStatus[message.status] === undefined ? message.status : $root.workflow_monitoring.v2.WorkflowExecutionStatus[message.status] : message.status;
          }
          if (message.workflowErrors && message.workflowErrors.length) {
            object.workflowErrors = new Array(message.workflowErrors.length);
            for (let i = 0; i < message.workflowErrors.length; ++i) {
              object.workflowErrors[i] = $root.workflow_monitoring.v2.WorkflowError.toObject(message.workflowErrors[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.WorkflowExecutionSummary";
        }
      }

      WorkflowExecutionSummary.prototype.eventSnapshotId = "";
      WorkflowExecutionSummary.prototype.workflowExecutionId = "";
      WorkflowExecutionSummary.prototype.workflowId = "";
      WorkflowExecutionSummary.prototype.workflowVersion = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      WorkflowExecutionSummary.prototype.workflowName = "";
      WorkflowExecutionSummary.prototype.applicationType = 0;
      WorkflowExecutionSummary.prototype.temporalWorkflowExecution = null;
      WorkflowExecutionSummary.prototype.supersededWorkflowExecutionId = null;
      WorkflowExecutionSummary.prototype.supersededByWorkflowExecutionId = null;
      WorkflowExecutionSummary.prototype.retryNumber = null;
      WorkflowExecutionSummary.prototype.retryOriginalWorkflowExecutionId = null;
      WorkflowExecutionSummary.prototype.matchedProfileIndex = null;
      WorkflowExecutionSummary.prototype.instantaneousMatchedProfileEmail = null;
      WorkflowExecutionSummary.prototype.instantaneousMatchedProfilePhone = null;
      WorkflowExecutionSummary.prototype.triggeredAtTime = null;
      WorkflowExecutionSummary.prototype.executionStartTime = null;
      WorkflowExecutionSummary.prototype.executionEndTime = null;
      WorkflowExecutionSummary.prototype.status = 0;
      WorkflowExecutionSummary.prototype.workflowErrors = $util.emptyArray;

      return WorkflowExecutionSummary;
    })();

    v2.WorkflowExecutionRetry = (() => {
      class WorkflowExecutionRetry {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowExecutionRetry(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.originalWorkflowExecutionId != null && Object.hasOwnProperty.call(message, "originalWorkflowExecutionId")) {
            writer.uint32(10).string(message.originalWorkflowExecutionId);
          }
          if (message.retryNumber != null && Object.hasOwnProperty.call(message, "retryNumber")) {
            writer.uint32(16).int64(message.retryNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowExecutionRetry();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.originalWorkflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.retryNumber = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.WorkflowExecutionRetry) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.WorkflowExecutionRetry();
          if (object.originalWorkflowExecutionId != null) {
            message.originalWorkflowExecutionId = String(object.originalWorkflowExecutionId);
          }
          if (object.retryNumber != null) {
            if ($util.Long) {
              (message.retryNumber = $util.Long.fromValue(object.retryNumber)).unsigned = false;
            } else if (typeof object.retryNumber === "string") {
              message.retryNumber = parseInt(object.retryNumber, 10);
            } else if (typeof object.retryNumber === "number") {
              message.retryNumber = object.retryNumber;
            } else if (typeof object.retryNumber === "object") {
              message.retryNumber = new $util.LongBits(object.retryNumber.low >>> 0, object.retryNumber.high >>> 0).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.originalWorkflowExecutionId = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.retryNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.retryNumber = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.originalWorkflowExecutionId != null && message.hasOwnProperty("originalWorkflowExecutionId")) {
            object.originalWorkflowExecutionId = message.originalWorkflowExecutionId;
          }
          if (message.retryNumber != null && message.hasOwnProperty("retryNumber")) {
            object.retryNumber = typeof message.retryNumber === "number" ? (options.longs === String ? String(message.retryNumber) : message.retryNumber) : (options.longs === String ? $util.Long.prototype.toString.call(message.retryNumber) : options.longs === Number ? new $util.LongBits(message.retryNumber.low >>> 0, message.retryNumber.high >>> 0).toNumber() : message.retryNumber);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.WorkflowExecutionRetry";
        }
      }

      WorkflowExecutionRetry.prototype.originalWorkflowExecutionId = "";
      WorkflowExecutionRetry.prototype.retryNumber = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      return WorkflowExecutionRetry;
    })();

    v2.GetEventSnapshotRequest = (() => {
      class GetEventSnapshotRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetEventSnapshotRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
            writer.uint32(10).string(message.eventSnapshotId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetEventSnapshotRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventSnapshotId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetEventSnapshotRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetEventSnapshotRequest();
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventSnapshotId = "";
          }
          let keys;
          if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetEventSnapshotRequest";
        }
      }

      GetEventSnapshotRequest.prototype.eventSnapshotId = "";

      return GetEventSnapshotRequest;
    })();

    v2.GetEventSnapshotResponse = (() => {
      class GetEventSnapshotResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetEventSnapshotResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventSnapshotId != null && Object.hasOwnProperty.call(message, "eventSnapshotId")) {
            writer.uint32(10).string(message.eventSnapshotId);
          }
          if (message.webhookId != null && Object.hasOwnProperty.call(message, "webhookId")) {
            writer.uint32(18).string(message.webhookId);
          }
          if (message.eventTime != null && Object.hasOwnProperty.call(message, "eventTime")) {
            timing$1.v1.Timestamp.encode(message.eventTime, writer.uint32(26).fork()).ldelim();
          }
          if (message.eventPayloadStruct != null && Object.hasOwnProperty.call(message, "eventPayloadStruct")) {
            google$2.protobuf.Struct.encode(message.eventPayloadStruct, writer.uint32(34).fork()).ldelim();
          }
          if (message.formattedEventType != null && Object.hasOwnProperty.call(message, "formattedEventType")) {
            writer.uint32(42).string(message.formattedEventType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetEventSnapshotResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 2: {
                message.webhookId = reader.string();
                break;
              }
              case 3: {
                message.eventTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.eventPayloadStruct = google$2.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.formattedEventType = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetEventSnapshotResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetEventSnapshotResponse();
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.webhookId != null) {
            message.webhookId = String(object.webhookId);
          }
          if (object.eventTime != null) {
            if (typeof object.eventTime !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetEventSnapshotResponse.eventTime: object expected, but got " + (typeof object.eventTime));
            }
            message.eventTime = timing$1.v1.Timestamp.fromObject(object.eventTime);
          }
          if (object.eventPayloadStruct != null) {
            if (typeof object.eventPayloadStruct !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetEventSnapshotResponse.eventPayloadStruct: object expected, but got " + (typeof object.eventPayloadStruct));
            }
            message.eventPayloadStruct = google$2.protobuf.Struct.fromObject(object.eventPayloadStruct);
          }
          if (object.formattedEventType != null) {
            message.formattedEventType = String(object.formattedEventType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventSnapshotId = "";
            object.eventTime = null;
            object.eventPayloadStruct = null;
            object.formattedEventType = "";
          }
          let keys;
          if (message.eventSnapshotId != null && message.hasOwnProperty("eventSnapshotId")) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (message.webhookId != null && message.hasOwnProperty("webhookId")) {
            object.webhookId = message.webhookId;
          }
          if (message.eventTime != null && message.hasOwnProperty("eventTime")) {
            object.eventTime = timing$1.v1.Timestamp.toObject(message.eventTime, options);
          }
          if (message.eventPayloadStruct != null && message.hasOwnProperty("eventPayloadStruct")) {
            object.eventPayloadStruct = google$2.protobuf.Struct.toObject(message.eventPayloadStruct, options);
          }
          if (message.formattedEventType != null && message.hasOwnProperty("formattedEventType")) {
            object.formattedEventType = message.formattedEventType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetEventSnapshotResponse";
        }
      }

      GetEventSnapshotResponse.prototype.eventSnapshotId = "";
      GetEventSnapshotResponse.prototype.webhookId = null;
      GetEventSnapshotResponse.prototype.eventTime = null;
      GetEventSnapshotResponse.prototype.eventPayloadStruct = null;
      GetEventSnapshotResponse.prototype.formattedEventType = "";

      return GetEventSnapshotResponse;
    })();

    v2.GetWebhookRequest = (() => {
      class GetWebhookRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWebhookRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.webhookId != null && Object.hasOwnProperty.call(message, "webhookId")) {
            writer.uint32(10).string(message.webhookId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWebhookRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetWebhookRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetWebhookRequest();
          if (object.webhookId != null) {
            message.webhookId = String(object.webhookId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.webhookId = "";
          }
          let keys;
          if (message.webhookId != null && message.hasOwnProperty("webhookId")) {
            object.webhookId = message.webhookId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetWebhookRequest";
        }
      }

      GetWebhookRequest.prototype.webhookId = "";

      return GetWebhookRequest;
    })();

    v2.GetWebhookResponse = (() => {
      class GetWebhookResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWebhookResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.webhookRequest != null && Object.hasOwnProperty.call(message, "webhookRequest")) {
            sources$1.v2.WebhookRequest.encode(message.webhookRequest, writer.uint32(10).fork()).ldelim();
          }
          if (message.webhookRequestStruct != null && Object.hasOwnProperty.call(message, "webhookRequestStruct")) {
            google$2.protobuf.Struct.encode(message.webhookRequestStruct, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWebhookResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookRequest = sources$1.v2.WebhookRequest.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.webhookRequestStruct = google$2.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetWebhookResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetWebhookResponse();
          if (object.webhookRequest != null) {
            if (typeof object.webhookRequest !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetWebhookResponse.webhookRequest: object expected, but got " + (typeof object.webhookRequest));
            }
            message.webhookRequest = sources$1.v2.WebhookRequest.fromObject(object.webhookRequest);
          }
          if (object.webhookRequestStruct != null) {
            if (typeof object.webhookRequestStruct !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetWebhookResponse.webhookRequestStruct: object expected, but got " + (typeof object.webhookRequestStruct));
            }
            message.webhookRequestStruct = google$2.protobuf.Struct.fromObject(object.webhookRequestStruct);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.webhookRequest = null;
            object.webhookRequestStruct = null;
          }
          let keys;
          if (message.webhookRequest != null && message.hasOwnProperty("webhookRequest")) {
            object.webhookRequest = sources$1.v2.WebhookRequest.toObject(message.webhookRequest, options);
          }
          if (message.webhookRequestStruct != null && message.hasOwnProperty("webhookRequestStruct")) {
            object.webhookRequestStruct = google$2.protobuf.Struct.toObject(message.webhookRequestStruct, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetWebhookResponse";
        }
      }

      GetWebhookResponse.prototype.webhookRequest = null;
      GetWebhookResponse.prototype.webhookRequestStruct = null;

      return GetWebhookResponse;
    })();

    v2.GetActionAttemptResultRequest = (() => {
      class GetActionAttemptResultRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetActionAttemptResultRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (message.workflowNodeId != null && Object.hasOwnProperty.call(message, "workflowNodeId")) {
            writer.uint32(18).string(message.workflowNodeId);
          }
          if (message.attemptNumber != null && Object.hasOwnProperty.call(message, "attemptNumber")) {
            writer.uint32(24).int64(message.attemptNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetActionAttemptResultRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.workflowNodeId = reader.string();
                break;
              }
              case 3: {
                message.attemptNumber = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetActionAttemptResultRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetActionAttemptResultRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.workflowNodeId != null) {
            message.workflowNodeId = String(object.workflowNodeId);
          }
          if (object.attemptNumber != null) {
            if ($util.Long) {
              (message.attemptNumber = $util.Long.fromValue(object.attemptNumber)).unsigned = false;
            } else if (typeof object.attemptNumber === "string") {
              message.attemptNumber = parseInt(object.attemptNumber, 10);
            } else if (typeof object.attemptNumber === "number") {
              message.attemptNumber = object.attemptNumber;
            } else if (typeof object.attemptNumber === "object") {
              message.attemptNumber = new $util.LongBits(object.attemptNumber.low >>> 0, object.attemptNumber.high >>> 0).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = "";
            object.workflowNodeId = "";
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.attemptNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.attemptNumber = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (message.workflowNodeId != null && message.hasOwnProperty("workflowNodeId")) {
            object.workflowNodeId = message.workflowNodeId;
          }
          if (message.attemptNumber != null && message.hasOwnProperty("attemptNumber")) {
            object.attemptNumber = typeof message.attemptNumber === "number" ? (options.longs === String ? String(message.attemptNumber) : message.attemptNumber) : (options.longs === String ? $util.Long.prototype.toString.call(message.attemptNumber) : options.longs === Number ? new $util.LongBits(message.attemptNumber.low >>> 0, message.attemptNumber.high >>> 0).toNumber() : message.attemptNumber);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetActionAttemptResultRequest";
        }
      }

      GetActionAttemptResultRequest.prototype.workflowExecutionId = "";
      GetActionAttemptResultRequest.prototype.workflowNodeId = "";
      GetActionAttemptResultRequest.prototype.attemptNumber = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      return GetActionAttemptResultRequest;
    })();

    v2.GetActionAttemptResultResponse = (() => {
      class GetActionAttemptResultResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetActionAttemptResultResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
            workflow_evaluation_results$1.v1.DestinationActionResult.encode(message.result, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetActionAttemptResultResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.result = workflow_evaluation_results$1.v1.DestinationActionResult.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetActionAttemptResultResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetActionAttemptResultResponse();
          if (object.result != null) {
            if (typeof object.result !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetActionAttemptResultResponse.result: object expected, but got " + (typeof object.result));
            }
            message.result = workflow_evaluation_results$1.v1.DestinationActionResult.fromObject(object.result);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.result = null;
          }
          let keys;
          if (message.result != null && message.hasOwnProperty("result")) {
            object.result = workflow_evaluation_results$1.v1.DestinationActionResult.toObject(message.result, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetActionAttemptResultResponse";
        }
      }

      GetActionAttemptResultResponse.prototype.result = null;

      return GetActionAttemptResultResponse;
    })();

    v2.ListWorkflowExecutionDestinationActionOutcomesRequest = (() => {
      class ListWorkflowExecutionDestinationActionOutcomesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowExecutionDestinationActionOutcomesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowExecutionDestinationActionOutcomesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = "";
          }
          let keys;
          if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesRequest";
        }
      }

      ListWorkflowExecutionDestinationActionOutcomesRequest.prototype.workflowExecutionId = "";

      return ListWorkflowExecutionDestinationActionOutcomesRequest;
    })();

    v2.ListWorkflowExecutionDestinationActionOutcomesResponse = (() => {
      class ListWorkflowExecutionDestinationActionOutcomesResponse {
        constructor(properties) {
          this.actionsExecutionAttempts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowExecutionDestinationActionOutcomesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.actionsExecutionAttempts != null && Object.hasOwnProperty.call(message, "actionsExecutionAttempts")) {
            for (const element of message.actionsExecutionAttempts) {
              $root.workflow_monitoring.v2.ActionExecutionAttempts.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowExecutionDestinationActionOutcomesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.actionsExecutionAttempts || !message.actionsExecutionAttempts.length) {
                  message.actionsExecutionAttempts = [];
                }
                message.actionsExecutionAttempts.push($root.workflow_monitoring.v2.ActionExecutionAttempts.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesResponse();
          if (object.actionsExecutionAttempts) {
            if (!Array.isArray(object.actionsExecutionAttempts)) {
              throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesResponse.actionsExecutionAttempts: array type expected, but got " + (typeof object.actionsExecutionAttempts))
            }
            message.actionsExecutionAttempts = new Array(object.actionsExecutionAttempts.length);
            for (let i = 0; i < object.actionsExecutionAttempts.length; ++i) {
              if (typeof object.actionsExecutionAttempts[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesResponse.actionsExecutionAttempts: object expected, but got " + (typeof object.actionsExecutionAttempts[i]));
              }
              message.actionsExecutionAttempts[i] = $root.workflow_monitoring.v2.ActionExecutionAttempts.fromObject(object.actionsExecutionAttempts[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.actionsExecutionAttempts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.actionsExecutionAttempts && message.actionsExecutionAttempts.length) {
            object.actionsExecutionAttempts = new Array(message.actionsExecutionAttempts.length);
            for (let i = 0; i < message.actionsExecutionAttempts.length; ++i) {
              object.actionsExecutionAttempts[i] = $root.workflow_monitoring.v2.ActionExecutionAttempts.toObject(message.actionsExecutionAttempts[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesResponse";
        }
      }

      ListWorkflowExecutionDestinationActionOutcomesResponse.prototype.actionsExecutionAttempts = $util.emptyArray;

      return ListWorkflowExecutionDestinationActionOutcomesResponse;
    })();

    v2.ListProfileDestinationActionOutcomesRequest = (() => {
      class ListProfileDestinationActionOutcomesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfileDestinationActionOutcomesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileIndex != null && Object.hasOwnProperty.call(message, "profileIndex")) {
            profiles$1.v1.ProfileIndex.encode(message.profileIndex, writer.uint32(10).fork()).ldelim();
          }
          if (message.earliestTimestampInclusive != null && Object.hasOwnProperty.call(message, "earliestTimestampInclusive")) {
            timing$1.v1.Timestamp.encode(message.earliestTimestampInclusive, writer.uint32(18).fork()).ldelim();
          }
          if (message.latestTimestampExclusive != null && Object.hasOwnProperty.call(message, "latestTimestampExclusive")) {
            timing$1.v1.Timestamp.encode(message.latestTimestampExclusive, writer.uint32(26).fork()).ldelim();
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(32).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfileDestinationActionOutcomesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileIndex = profiles$1.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.earliestTimestampInclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest();
          if (object.profileIndex != null) {
            if (typeof object.profileIndex !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest.profileIndex: object expected, but got " + (typeof object.profileIndex));
            }
            message.profileIndex = profiles$1.v1.ProfileIndex.fromObject(object.profileIndex);
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest.earliestTimestampInclusive: object expected, but got " + (typeof object.earliestTimestampInclusive));
            }
            message.earliestTimestampInclusive = timing$1.v1.Timestamp.fromObject(object.earliestTimestampInclusive);
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest.latestTimestampExclusive: object expected, but got " + (typeof object.latestTimestampExclusive));
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(object.latestTimestampExclusive);
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(object.limit)).unsigned = true;
            } else if (typeof object.limit === "string") {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === "number") {
              message.limit = object.limit;
            } else if (typeof object.limit === "object") {
              message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profileIndex = null;
            object.latestTimestampExclusive = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.limit = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.profileIndex != null && message.hasOwnProperty("profileIndex")) {
            object.profileIndex = profiles$1.v1.ProfileIndex.toObject(message.profileIndex, options);
          }
          if (message.earliestTimestampInclusive != null && message.hasOwnProperty("earliestTimestampInclusive")) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(message.earliestTimestampInclusive, options);
          }
          if (message.latestTimestampExclusive != null && message.hasOwnProperty("latestTimestampExclusive")) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(message.latestTimestampExclusive, options);
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = typeof message.limit === "number" ? (options.longs === String ? String(message.limit) : message.limit) : (options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber(true) : message.limit);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest";
        }
      }

      ListProfileDestinationActionOutcomesRequest.prototype.profileIndex = null;
      ListProfileDestinationActionOutcomesRequest.prototype.earliestTimestampInclusive = null;
      ListProfileDestinationActionOutcomesRequest.prototype.latestTimestampExclusive = null;
      ListProfileDestinationActionOutcomesRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

      return ListProfileDestinationActionOutcomesRequest;
    })();

    v2.ListProfileDestinationActionOutcomesResponse = (() => {
      class ListProfileDestinationActionOutcomesResponse {
        constructor(properties) {
          this.actionsExecutionAttempts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfileDestinationActionOutcomesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.actionsExecutionAttempts != null && Object.hasOwnProperty.call(message, "actionsExecutionAttempts")) {
            for (const element of message.actionsExecutionAttempts) {
              $root.workflow_monitoring.v2.ActionExecutionAttempts.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.earliestFetchedTimestamp != null && Object.hasOwnProperty.call(message, "earliestFetchedTimestamp")) {
            timing$1.v1.Timestamp.encode(message.earliestFetchedTimestamp, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfileDestinationActionOutcomesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.actionsExecutionAttempts || !message.actionsExecutionAttempts.length) {
                  message.actionsExecutionAttempts = [];
                }
                message.actionsExecutionAttempts.push($root.workflow_monitoring.v2.ActionExecutionAttempts.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse();
          if (object.actionsExecutionAttempts) {
            if (!Array.isArray(object.actionsExecutionAttempts)) {
              throw new TypeError(".workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse.actionsExecutionAttempts: array type expected, but got " + (typeof object.actionsExecutionAttempts))
            }
            message.actionsExecutionAttempts = new Array(object.actionsExecutionAttempts.length);
            for (let i = 0; i < object.actionsExecutionAttempts.length; ++i) {
              if (typeof object.actionsExecutionAttempts[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse.actionsExecutionAttempts: object expected, but got " + (typeof object.actionsExecutionAttempts[i]));
              }
              message.actionsExecutionAttempts[i] = $root.workflow_monitoring.v2.ActionExecutionAttempts.fromObject(object.actionsExecutionAttempts[i]);
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== "object") {
              throw new TypeError(".workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse.earliestFetchedTimestamp: object expected, but got " + (typeof object.earliestFetchedTimestamp));
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(object.earliestFetchedTimestamp);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.actionsExecutionAttempts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.actionsExecutionAttempts && message.actionsExecutionAttempts.length) {
            object.actionsExecutionAttempts = new Array(message.actionsExecutionAttempts.length);
            for (let i = 0; i < message.actionsExecutionAttempts.length; ++i) {
              object.actionsExecutionAttempts[i] = $root.workflow_monitoring.v2.ActionExecutionAttempts.toObject(message.actionsExecutionAttempts[i], options);
            }
          }
          if (message.earliestFetchedTimestamp != null && message.hasOwnProperty("earliestFetchedTimestamp")) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(message.earliestFetchedTimestamp, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse";
        }
      }

      ListProfileDestinationActionOutcomesResponse.prototype.actionsExecutionAttempts = $util.emptyArray;
      ListProfileDestinationActionOutcomesResponse.prototype.earliestFetchedTimestamp = null;

      return ListProfileDestinationActionOutcomesResponse;
    })();

    v2.ActionExecutionAttempts = (() => {
      class ActionExecutionAttempts {
        constructor(properties) {
          this.attempts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActionExecutionAttempts(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(16).int32(message.application);
          }
          if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId")) {
            writer.uint32(26).string(message.nodeId);
          }
          if (message.attempts != null && Object.hasOwnProperty.call(message, "attempts")) {
            for (const element of message.attempts) {
              $root.workflow_monitoring.v2.ActionExecutionAttempts.Attempt.encode(element, writer.uint32(34).fork()).ldelim();
            }
          }
          if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
            writer.uint32(40).int32(message.status);
          }
          if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
            writer.uint32(50).string(message.workflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActionExecutionAttempts();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.application = reader.int32();
                break;
              }
              case 3: {
                message.nodeId = reader.string();
                break;
              }
              case 4: {
                if (!message.attempts || !message.attempts.length) {
                  message.attempts = [];
                }
                message.attempts.push($root.workflow_monitoring.v2.ActionExecutionAttempts.Attempt.decode(reader, reader.uint32()));
                break;
              }
              case 5: {
                message.status = reader.int32();
                break;
              }
              case 6: {
                message.workflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.ActionExecutionAttempts) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.ActionExecutionAttempts();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_DESTINATION_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 1: {
                message.application = 1;
                break;
              }
              case "CUSTOMER_IO":
              case 2: {
                message.application = 2;
                break;
              }
              case "FACEBOOK":
              case 3: {
                message.application = 3;
                break;
              }
              case "GOOGLE":
              case 4: {
                message.application = 4;
                break;
              }
              case "HEALTHIE":
              case 5: {
                message.application = 5;
                break;
              }
              case "MORF":
              case 6: {
                message.application = 6;
                break;
              }
              case "INTAKEQ":
              case 7: {
                message.application = 7;
                break;
              }
              case "SEGMENT":
              case 8: {
                message.application = 8;
                break;
              }
              case "SPRUCE":
              case 9: {
                message.application = 9;
                break;
              }
              case "SLACK":
              case 10: {
                message.application = 10;
                break;
              }
              case "FRESHDESK":
              case 11: {
                message.application = 11;
                break;
              }
              case "HUBSPOT":
              case 12: {
                message.application = 12;
                break;
              }
              case "INTERCOM":
              case 13: {
                message.application = 13;
                break;
              }
              case "TIKTOK":
              case 14: {
                message.application = 14;
                break;
              }
              case "TWILIO":
              case 15: {
                message.application = 15;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          if (object.attempts) {
            if (!Array.isArray(object.attempts)) {
              throw new TypeError(".workflow_monitoring.v2.ActionExecutionAttempts.attempts: array type expected, but got " + (typeof object.attempts))
            }
            message.attempts = new Array(object.attempts.length);
            for (let i = 0; i < object.attempts.length; ++i) {
              if (typeof object.attempts[i] !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ActionExecutionAttempts.attempts: object expected, but got " + (typeof object.attempts[i]));
              }
              message.attempts[i] = $root.workflow_monitoring.v2.ActionExecutionAttempts.Attempt.fromObject(object.attempts[i]);
            }
          }
          if (object.status != null) {
            switch (object.status) {
              case "UNSPECIFIED_STATUS":
              case 0: {
                message.status = 0;
                break;
              }
              case "PROCESSING":
              case 1: {
                message.status = 1;
                break;
              }
              case "EXECUTED":
              case 2: {
                message.status = 2;
                break;
              }
              case "ERROR":
              case 3: {
                message.status = 3;
                break;
              }
              case "WARNING":
              case 4: {
                message.status = 4;
                break;
              }
              case "WAITING":
              case 5: {
                message.status = 5;
                break;
              }
              case "TERMINATED":
              case 6: {
                message.status = 6;
                break;
              }
              case "RETRYING":
              case 7: {
                message.status = 7;
                break;
              }
              default: {
                if (typeof object.status == "number") {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.attempts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
            object.application = options.enums === String ? "UNSPECIFIED_DESTINATION_APPLICATION" : 0
            object.nodeId = "";
            object.status = options.enums === String ? "UNSPECIFIED_STATUS" : 0
            object.workflowExecutionId = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? workflow_destination_applications$1.v1.DestinationApplication.Application[message.application] === undefined ? message.application : workflow_destination_applications$1.v1.DestinationApplication.Application[message.application] : message.application;
          }
          if (message.nodeId != null && message.hasOwnProperty("nodeId")) {
            object.nodeId = message.nodeId;
          }
          if (message.attempts && message.attempts.length) {
            object.attempts = new Array(message.attempts.length);
            for (let i = 0; i < message.attempts.length; ++i) {
              object.attempts[i] = $root.workflow_monitoring.v2.ActionExecutionAttempts.Attempt.toObject(message.attempts[i], options);
            }
          }
          if (message.status != null && message.hasOwnProperty("status")) {
            object.status = options.enums === String ? $root.workflow_monitoring.v2.ActionExecutionAttempts.Status[message.status] === undefined ? message.status : $root.workflow_monitoring.v2.ActionExecutionAttempts.Status[message.status] : message.status;
          }
          if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.ActionExecutionAttempts";
        }
      }

      ActionExecutionAttempts.prototype.name = "";
      ActionExecutionAttempts.prototype.application = 0;
      ActionExecutionAttempts.prototype.nodeId = "";
      ActionExecutionAttempts.prototype.attempts = $util.emptyArray;
      ActionExecutionAttempts.prototype.status = 0;
      ActionExecutionAttempts.prototype.workflowExecutionId = "";
      ActionExecutionAttempts.Attempt = (() => {
        class Attempt {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Attempt(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.attemptNumber != null && Object.hasOwnProperty.call(message, "attemptNumber")) {
              writer.uint32(8).uint64(message.attemptNumber);
            }
            if (message.error != null && Object.hasOwnProperty.call(message, "error")) {
              workflow_evaluation_results$1.v1.FormattedError.encode(message.error, writer.uint32(18).fork()).ldelim();
            }
            if (message.errorHash != null && Object.hasOwnProperty.call(message, "errorHash")) {
              writer.uint32(24).int64(message.errorHash);
            }
            if (message.executionStart != null && Object.hasOwnProperty.call(message, "executionStart")) {
              timing$1.v1.Timestamp.encode(message.executionStart, writer.uint32(34).fork()).ldelim();
            }
            if (message.executionEnd != null && Object.hasOwnProperty.call(message, "executionEnd")) {
              timing$1.v1.Timestamp.encode(message.executionEnd, writer.uint32(42).fork()).ldelim();
            }
            if (message.expectedExecutionEnd != null && Object.hasOwnProperty.call(message, "expectedExecutionEnd")) {
              timing$1.v1.Timestamp.encode(message.expectedExecutionEnd, writer.uint32(50).fork()).ldelim();
            }
            if (message.filterOutcome != null && Object.hasOwnProperty.call(message, "filterOutcome")) {
              writer.uint32(56).bool(message.filterOutcome);
            }
            if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
              writer.uint32(64).int32(message.status);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Attempt();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.attemptNumber = reader.uint64();
                  break;
                }
                case 2: {
                  message.error = workflow_evaluation_results$1.v1.FormattedError.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.errorHash = reader.int64();
                  break;
                }
                case 4: {
                  message.executionStart = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                  break;
                }
                case 5: {
                  message.executionEnd = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                  break;
                }
                case 6: {
                  message.expectedExecutionEnd = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                  break;
                }
                case 7: {
                  message.filterOutcome = reader.bool();
                  break;
                }
                case 8: {
                  message.status = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflow_monitoring.v2.ActionExecutionAttempts.Attempt) {
              return object;
            }
            const message = new $root.workflow_monitoring.v2.ActionExecutionAttempts.Attempt();
            if (object.attemptNumber != null) {
              if ($util.Long) {
                (message.attemptNumber = $util.Long.fromValue(object.attemptNumber)).unsigned = true;
              } else if (typeof object.attemptNumber === "string") {
                message.attemptNumber = parseInt(object.attemptNumber, 10);
              } else if (typeof object.attemptNumber === "number") {
                message.attemptNumber = object.attemptNumber;
              } else if (typeof object.attemptNumber === "object") {
                message.attemptNumber = new $util.LongBits(object.attemptNumber.low >>> 0, object.attemptNumber.high >>> 0).toNumber(true);
              }
            }
            if (object.error != null) {
              if (typeof object.error !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ActionExecutionAttempts.Attempt.error: object expected, but got " + (typeof object.error));
              }
              message.error = workflow_evaluation_results$1.v1.FormattedError.fromObject(object.error);
            }
            if (object.errorHash != null) {
              if ($util.Long) {
                (message.errorHash = $util.Long.fromValue(object.errorHash)).unsigned = false;
              } else if (typeof object.errorHash === "string") {
                message.errorHash = parseInt(object.errorHash, 10);
              } else if (typeof object.errorHash === "number") {
                message.errorHash = object.errorHash;
              } else if (typeof object.errorHash === "object") {
                message.errorHash = new $util.LongBits(object.errorHash.low >>> 0, object.errorHash.high >>> 0).toNumber();
              }
            }
            if (object.executionStart != null) {
              if (typeof object.executionStart !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ActionExecutionAttempts.Attempt.executionStart: object expected, but got " + (typeof object.executionStart));
              }
              message.executionStart = timing$1.v1.Timestamp.fromObject(object.executionStart);
            }
            if (object.executionEnd != null) {
              if (typeof object.executionEnd !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ActionExecutionAttempts.Attempt.executionEnd: object expected, but got " + (typeof object.executionEnd));
              }
              message.executionEnd = timing$1.v1.Timestamp.fromObject(object.executionEnd);
            }
            if (object.expectedExecutionEnd != null) {
              if (typeof object.expectedExecutionEnd !== "object") {
                throw new TypeError(".workflow_monitoring.v2.ActionExecutionAttempts.Attempt.expectedExecutionEnd: object expected, but got " + (typeof object.expectedExecutionEnd));
              }
              message.expectedExecutionEnd = timing$1.v1.Timestamp.fromObject(object.expectedExecutionEnd);
            }
            if (object.filterOutcome != null) {
              message.filterOutcome = Boolean(object.filterOutcome);
            }
            if (object.status != null) {
              switch (object.status) {
                case "UNSPECIFIED_STATUS":
                case 0: {
                  message.status = 0;
                  break;
                }
                case "PROCESSING":
                case 1: {
                  message.status = 1;
                  break;
                }
                case "EXECUTED":
                case 2: {
                  message.status = 2;
                  break;
                }
                case "ERROR":
                case 3: {
                  message.status = 3;
                  break;
                }
                case "WARNING":
                case 4: {
                  message.status = 4;
                  break;
                }
                case "WAITING":
                case 5: {
                  message.status = 5;
                  break;
                }
                case "TERMINATED":
                case 6: {
                  message.status = 6;
                  break;
                }
                case "RETRYING":
                case 7: {
                  message.status = 7;
                  break;
                }
                default: {
                  if (typeof object.status == "number") {
                    message.status = object.status;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if ($util.Long) {
                let long = new $util.Long(0, 0, true)
                object.attemptNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.attemptNumber = options.longs === String ? "0" : 0;
              }
              object.executionStart = null;
              object.status = options.enums === String ? "UNSPECIFIED_STATUS" : 0
            }
            let keys;
            if (message.attemptNumber != null && message.hasOwnProperty("attemptNumber")) {
              object.attemptNumber = typeof message.attemptNumber === "number" ? (options.longs === String ? String(message.attemptNumber) : message.attemptNumber) : (options.longs === String ? $util.Long.prototype.toString.call(message.attemptNumber) : options.longs === Number ? new $util.LongBits(message.attemptNumber.low >>> 0, message.attemptNumber.high >>> 0).toNumber(true) : message.attemptNumber);
            }
            if (message.error != null && message.hasOwnProperty("error")) {
              object.error = workflow_evaluation_results$1.v1.FormattedError.toObject(message.error, options);
            }
            if (message.errorHash != null && message.hasOwnProperty("errorHash")) {
              object.errorHash = typeof message.errorHash === "number" ? (options.longs === String ? String(message.errorHash) : message.errorHash) : (options.longs === String ? $util.Long.prototype.toString.call(message.errorHash) : options.longs === Number ? new $util.LongBits(message.errorHash.low >>> 0, message.errorHash.high >>> 0).toNumber() : message.errorHash);
            }
            if (message.executionStart != null && message.hasOwnProperty("executionStart")) {
              object.executionStart = timing$1.v1.Timestamp.toObject(message.executionStart, options);
            }
            if (message.executionEnd != null && message.hasOwnProperty("executionEnd")) {
              object.executionEnd = timing$1.v1.Timestamp.toObject(message.executionEnd, options);
            }
            if (message.expectedExecutionEnd != null && message.hasOwnProperty("expectedExecutionEnd")) {
              object.expectedExecutionEnd = timing$1.v1.Timestamp.toObject(message.expectedExecutionEnd, options);
            }
            if (message.filterOutcome != null && message.hasOwnProperty("filterOutcome")) {
              object.filterOutcome = message.filterOutcome;
            }
            if (message.status != null && message.hasOwnProperty("status")) {
              object.status = options.enums === String ? $root.workflow_monitoring.v2.ActionExecutionAttempts.Status[message.status] === undefined ? message.status : $root.workflow_monitoring.v2.ActionExecutionAttempts.Status[message.status] : message.status;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "workflow_monitoring.v2.ActionExecutionAttempts.Attempt";
          }
        }

        Attempt.prototype.attemptNumber = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
        Attempt.prototype.error = null;
        Attempt.prototype.errorHash = null;
        Attempt.prototype.executionStart = null;
        Attempt.prototype.executionEnd = null;
        Attempt.prototype.expectedExecutionEnd = null;
        Attempt.prototype.filterOutcome = null;
        Attempt.prototype.status = 0;

        return Attempt;
      })();

      ActionExecutionAttempts.Status = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED_STATUS"] = 0;
        values[valuesById[1] = "PROCESSING"] = 1;
        values[valuesById[2] = "EXECUTED"] = 2;
        values[valuesById[3] = "ERROR"] = 3;
        values[valuesById[4] = "WARNING"] = 4;
        values[valuesById[5] = "WAITING"] = 5;
        values[valuesById[6] = "TERMINATED"] = 6;
        values[valuesById[7] = "RETRYING"] = 7;
        return values;
      })();

      return ActionExecutionAttempts;
    })();

    v2.GetProfileLookupResultRequest = (() => {
      class GetProfileLookupResultRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetProfileLookupResultRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.workflowExecutionId != null && Object.hasOwnProperty.call(message, "workflowExecutionId")) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetProfileLookupResultRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetProfileLookupResultRequest) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetProfileLookupResultRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = "";
          }
          let keys;
          if (message.workflowExecutionId != null && message.hasOwnProperty("workflowExecutionId")) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetProfileLookupResultRequest";
        }
      }

      GetProfileLookupResultRequest.prototype.workflowExecutionId = "";

      return GetProfileLookupResultRequest;
    })();

    v2.GetProfileLookupResultResponse = (() => {
      class GetProfileLookupResultResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetProfileLookupResultResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileLookupResult != null && Object.hasOwnProperty.call(message, "profileLookupResult")) {
            profiles$1.v1.LookupExecutionResult.encode(message.profileLookupResult, writer.uint32(10).fork()).ldelim();
          }
          if (message.profileLookupResultStruct != null && Object.hasOwnProperty.call(message, "profileLookupResultStruct")) {
            google$2.protobuf.Struct.encode(message.profileLookupResultStruct, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetProfileLookupResultResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileLookupResult = profiles$1.v1.LookupExecutionResult.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.profileLookupResultStruct = google$2.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_monitoring.v2.GetProfileLookupResultResponse) {
            return object;
          }
          const message = new $root.workflow_monitoring.v2.GetProfileLookupResultResponse();
          if (object.profileLookupResult != null) {
            if (typeof object.profileLookupResult !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetProfileLookupResultResponse.profileLookupResult: object expected, but got " + (typeof object.profileLookupResult));
            }
            message.profileLookupResult = profiles$1.v1.LookupExecutionResult.fromObject(object.profileLookupResult);
          }
          if (object.profileLookupResultStruct != null) {
            if (typeof object.profileLookupResultStruct !== "object") {
              throw new TypeError(".workflow_monitoring.v2.GetProfileLookupResultResponse.profileLookupResultStruct: object expected, but got " + (typeof object.profileLookupResultStruct));
            }
            message.profileLookupResultStruct = google$2.protobuf.Struct.fromObject(object.profileLookupResultStruct);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profileLookupResult = null;
            object.profileLookupResultStruct = null;
          }
          let keys;
          if (message.profileLookupResult != null && message.hasOwnProperty("profileLookupResult")) {
            object.profileLookupResult = profiles$1.v1.LookupExecutionResult.toObject(message.profileLookupResult, options);
          }
          if (message.profileLookupResultStruct != null && message.hasOwnProperty("profileLookupResultStruct")) {
            object.profileLookupResultStruct = google$2.protobuf.Struct.toObject(message.profileLookupResultStruct, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_monitoring.v2.GetProfileLookupResultResponse";
        }
      }

      GetProfileLookupResultResponse.prototype.profileLookupResult = null;
      GetProfileLookupResultResponse.prototype.profileLookupResultStruct = null;

      return GetProfileLookupResultResponse;
    })();

    v2.WorkflowExecutionStatus = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_WORKFLOW_EXECUTION_STATUS"] = 0;
      values[valuesById[1] = "SKIPPED"] = 1;
      values[valuesById[2] = "TRIGGERED"] = 2;
      values[valuesById[3] = "PROCESSING"] = 3;
      values[valuesById[4] = "RETRYING"] = 4;
      values[valuesById[5] = "SUPERSEDED"] = 5;
      values[valuesById[6] = "EXECUTED"] = 6;
      values[valuesById[7] = "FAILED"] = 7;
      return values;
    })();

    v2.WorkflowMonitoringService = (() => {
      class WorkflowMonitoringService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new WorkflowMonitoringService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(WorkflowMonitoringService.prototype.listOrganizationFilterTypes = function listOrganizationFilterTypes(request, callback) {
        return this.rpcCall(listOrganizationFilterTypes, $root.workflow_monitoring.v2.ListOrganizationFilterTypesRequest, $root.workflow_monitoring.v2.ListOrganizationFilterTypesResponse, request, callback);
      }, "name", { value: "ListOrganizationFilterTypes" });

      Object.defineProperty(WorkflowMonitoringService.prototype.listEventSnapshots = function listEventSnapshots(request, callback) {
        return this.rpcCall(listEventSnapshots, $root.workflow_monitoring.v2.ListEventSnapshotsRequest, $root.workflow_monitoring.v2.ListEventSnapshotsResponse, request, callback);
      }, "name", { value: "ListEventSnapshots" });

      Object.defineProperty(WorkflowMonitoringService.prototype.listEventSnapshotWorkflowExecutions = function listEventSnapshotWorkflowExecutions(request, callback) {
        return this.rpcCall(listEventSnapshotWorkflowExecutions, $root.workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsRequest, $root.workflow_monitoring.v2.ListEventSnapshotWorkflowExecutionsResponse, request, callback);
      }, "name", { value: "ListEventSnapshotWorkflowExecutions" });

      Object.defineProperty(WorkflowMonitoringService.prototype.listGroupedErrors = function listGroupedErrors(request, callback) {
        return this.rpcCall(listGroupedErrors, $root.workflow_monitoring.v2.ListGroupedErrorsRequest, $root.workflow_monitoring.v2.ListGroupedErrorsResponse, request, callback);
      }, "name", { value: "ListGroupedErrors" });

      Object.defineProperty(WorkflowMonitoringService.prototype.getGroupedError = function getGroupedError(request, callback) {
        return this.rpcCall(getGroupedError, $root.workflow_monitoring.v2.GetGroupedErrorRequest, $root.workflow_monitoring.v2.GetGroupedErrorResponse, request, callback);
      }, "name", { value: "GetGroupedError" });

      Object.defineProperty(WorkflowMonitoringService.prototype.getWorkflowExecution = function getWorkflowExecution(request, callback) {
        return this.rpcCall(getWorkflowExecution, $root.workflow_monitoring.v2.GetWorkflowExecutionRequest, $root.workflow_monitoring.v2.GetWorkflowExecutionResponse, request, callback);
      }, "name", { value: "GetWorkflowExecution" });

      Object.defineProperty(WorkflowMonitoringService.prototype.listWorkflowExecutions = function listWorkflowExecutions(request, callback) {
        return this.rpcCall(listWorkflowExecutions, $root.workflow_monitoring.v2.ListWorkflowExecutionsRequest, $root.workflow_monitoring.v2.ListWorkflowExecutionsResponse, request, callback);
      }, "name", { value: "ListWorkflowExecutions" });

      Object.defineProperty(WorkflowMonitoringService.prototype.getEventSnapshot = function getEventSnapshot(request, callback) {
        return this.rpcCall(getEventSnapshot, $root.workflow_monitoring.v2.GetEventSnapshotRequest, $root.workflow_monitoring.v2.GetEventSnapshotResponse, request, callback);
      }, "name", { value: "GetEventSnapshot" });

      Object.defineProperty(WorkflowMonitoringService.prototype.getWebhook = function getWebhook(request, callback) {
        return this.rpcCall(getWebhook, $root.workflow_monitoring.v2.GetWebhookRequest, $root.workflow_monitoring.v2.GetWebhookResponse, request, callback);
      }, "name", { value: "GetWebhook" });

      Object.defineProperty(WorkflowMonitoringService.prototype.getActionAttemptResult = function getActionAttemptResult(request, callback) {
        return this.rpcCall(getActionAttemptResult, $root.workflow_monitoring.v2.GetActionAttemptResultRequest, $root.workflow_monitoring.v2.GetActionAttemptResultResponse, request, callback);
      }, "name", { value: "GetActionAttemptResult" });

      Object.defineProperty(WorkflowMonitoringService.prototype.listWorkflowExecutionDestinationActionOutcomes = function listWorkflowExecutionDestinationActionOutcomes(request, callback) {
        return this.rpcCall(listWorkflowExecutionDestinationActionOutcomes, $root.workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesRequest, $root.workflow_monitoring.v2.ListWorkflowExecutionDestinationActionOutcomesResponse, request, callback);
      }, "name", { value: "ListWorkflowExecutionDestinationActionOutcomes" });

      Object.defineProperty(WorkflowMonitoringService.prototype.listProfileDestinationActionOutcomes = function listProfileDestinationActionOutcomes(request, callback) {
        return this.rpcCall(listProfileDestinationActionOutcomes, $root.workflow_monitoring.v2.ListProfileDestinationActionOutcomesRequest, $root.workflow_monitoring.v2.ListProfileDestinationActionOutcomesResponse, request, callback);
      }, "name", { value: "ListProfileDestinationActionOutcomes" });

      Object.defineProperty(WorkflowMonitoringService.prototype.getProfileLookupResult = function getProfileLookupResult(request, callback) {
        return this.rpcCall(getProfileLookupResult, $root.workflow_monitoring.v2.GetProfileLookupResultRequest, $root.workflow_monitoring.v2.GetProfileLookupResultResponse, request, callback);
      }, "name", { value: "GetProfileLookupResult" });

      return WorkflowMonitoringService;
    })();

    return v2;
  })();

  return workflow_monitoring;
})();

export const workflow_monitoring = $root.workflow_monitoring;
