"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.pagination = (() => {
  const pagination = {};
  pagination.v1 = (() => {
    const v1 = {};
    v1.Pagination = (() => {
      class Pagination {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Pagination(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.perPage != null && Object.hasOwnProperty.call(message, "perPage")) {
            writer.uint32(8).uint32(message.perPage);
          }
          if (message.page != null && Object.hasOwnProperty.call(message, "page")) {
            writer.uint32(16).uint32(message.page);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Pagination();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.perPage = reader.uint32();
                break;
              }
              case 2: {
                message.page = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.pagination.v1.Pagination) {
            return object;
          }
          const message = new $root.pagination.v1.Pagination();
          if (object.perPage != null) {
            message.perPage = object.perPage >>> 0;
          }
          if (object.page != null) {
            message.page = object.page >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.perPage = 0;
            object.page = 0;
          }
          let keys;
          if (message.perPage != null && message.hasOwnProperty("perPage")) {
            object.perPage = message.perPage;
          }
          if (message.page != null && message.hasOwnProperty("page")) {
            object.page = message.page;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "pagination.v1.Pagination";
        }
      }

      Pagination.prototype.perPage = 0;
      Pagination.prototype.page = 0;

      return Pagination;
    })();

    return v1;
  })();

  return pagination;
})();

export const pagination = $root.pagination;
