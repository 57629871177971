import { css, styled } from '@morf/theming';
import { AvatarProps } from './types';
import { Flexbox } from '../Flexbox';

const XSmall = css(
  ({ theme }) => `
  width: 1rem;
  height: 1rem;
  border-radius: ${theme.borderRadius['full']};
  padding: ${theme.spacing[0.25]};
`
);

const Small = css(
  ({ theme }) => `
  width: 1.25rem;
  height: 1.25rem;
  padding: ${theme.spacing[0.25]};
`
);

const Normal = css(
  ({ theme }) => `
  width: 2rem;
  height: 2rem; 
  padding: ${theme.spacing[0.25]};
`
);

const Large = css(
  ({ theme }) => `
  width: 3rem;
  height: 3rem;
  padding: ${theme.spacing[0.5]};
`
);

const XLarge = css(
  ({ theme }) => `
  width: 3.75rem;
  height: 3.75rem;
  padding: ${theme.spacing[0.75]};
`
);

const handleSize = (size: AvatarProps['size']) => {
  switch (size) {
    case 'xs':
      return XSmall;
    case 'sm':
      return Small;
    case 'md':
      return Normal;
    case 'lg':
      return Large;
    case 'xl':
      return XLarge;
  }
};

export const StyledAvatar = styled.img<
  React.ImgHTMLAttributes<HTMLImageElement>
>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledAvatarContainer = styled(Flexbox)<{
  size: AvatarProps['size'];
}>`
  ${(props) => handleSize(props.size)};
`;
