"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.ping = (() => {
  const ping = {};
  ping.PingRequest = (() => {
    class PingRequest {
      constructor(properties) {
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new PingRequest(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.tracer != null && Object.hasOwnProperty.call(message, "tracer")) {
          writer.uint32(8).uint64(message.tracer);
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new PingRequest();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.tracer = reader.uint64();
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.ping.PingRequest) {
          return object;
        }
        const message = new $root.ping.PingRequest();
        if (object.tracer != null) {
          if ($util.Long) {
            (message.tracer = $util.Long.fromValue(object.tracer)).unsigned = true;
          } else if (typeof object.tracer === "string") {
            message.tracer = parseInt(object.tracer, 10);
          } else if (typeof object.tracer === "number") {
            message.tracer = object.tracer;
          } else if (typeof object.tracer === "object") {
            message.tracer = new $util.LongBits(object.tracer.low >>> 0, object.tracer.high >>> 0).toNumber(true);
          }
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
          if ($util.Long) {
            let long = new $util.Long(0, 0, true)
            object.tracer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else {
            object.tracer = options.longs === String ? "0" : 0;
          }
        }
        let keys;
        if (message.tracer != null && message.hasOwnProperty("tracer")) {
          object.tracer = typeof message.tracer === "number" ? (options.longs === String ? String(message.tracer) : message.tracer) : (options.longs === String ? $util.Long.prototype.toString.call(message.tracer) : options.longs === Number ? new $util.LongBits(message.tracer.low >>> 0, message.tracer.high >>> 0).toNumber(true) : message.tracer);
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "ping.PingRequest";
      }
    }

    PingRequest.prototype.tracer = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    return PingRequest;
  })();

  ping.PingResponse = (() => {
    class PingResponse {
      constructor(properties) {
        if (properties) {
          for (let key of Object.keys(properties)) {
            if (properties[key] != null) this[key] = properties[key];
          }
        }
      }

      static create(properties) {
        return new PingResponse(properties)
      }

      static encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.tracer != null && Object.hasOwnProperty.call(message, "tracer")) {
          writer.uint32(8).uint64(message.tracer);
        }
        return writer;
      }

      static decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length;
        let message = new PingResponse();
        let key, value;
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.tracer = reader.uint64();
              break;
            }
            default: {
              reader.skipType(tag & 7);
              break;
            }
          }
        }
        return message;
      }

      static fromObject(object) {
        if (object instanceof $root.ping.PingResponse) {
          return object;
        }
        const message = new $root.ping.PingResponse();
        if (object.tracer != null) {
          if ($util.Long) {
            (message.tracer = $util.Long.fromValue(object.tracer)).unsigned = true;
          } else if (typeof object.tracer === "string") {
            message.tracer = parseInt(object.tracer, 10);
          } else if (typeof object.tracer === "number") {
            message.tracer = object.tracer;
          } else if (typeof object.tracer === "object") {
            message.tracer = new $util.LongBits(object.tracer.low >>> 0, object.tracer.high >>> 0).toNumber(true);
          }
        }
        return message;
      }

      static toObject(message, options = {}) {
        const object = {};
        if (options.arrays || options.defaults) {
        }
        if (options.objects || options.defaults) {
        }
        if (options.defaults) {
          if ($util.Long) {
            let long = new $util.Long(0, 0, true)
            object.tracer = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else {
            object.tracer = options.longs === String ? "0" : 0;
          }
        }
        let keys;
        if (message.tracer != null && message.hasOwnProperty("tracer")) {
          object.tracer = typeof message.tracer === "number" ? (options.longs === String ? String(message.tracer) : message.tracer) : (options.longs === String ? $util.Long.prototype.toString.call(message.tracer) : options.longs === Number ? new $util.LongBits(message.tracer.low >>> 0, message.tracer.high >>> 0).toNumber(true) : message.tracer);
        }
        return object;
      }

      static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
        return typeUrlPrefix + "ping.PingResponse";
      }
    }

    PingResponse.prototype.tracer = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    return PingResponse;
  })();

  ping.PingService = (() => {
    class PingService extends $protobuf.rpc.Service {
      constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
        super(rpcImpl, requestDelimited, responseDelimited);
      }

      static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
        return new PingService(rpcImpl, requestDelimited, responseDelimited);
      }
    }

    Object.defineProperty(PingService.prototype.ping = function ping(request, callback) {
      return this.rpcCall(ping, $root.ping.PingRequest, $root.ping.PingResponse, request, callback);
    }, "name", { value: "Ping" });

    return PingService;
  })();

  return ping;
})();

export const ping = $root.ping;
