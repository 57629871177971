"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.protobuf = (() => {
    const protobuf = {};
    protobuf.Timestamp = (() => {
      class Timestamp {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Timestamp(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds")) {
            writer.uint32(8).int64(message.seconds);
          }
          if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos")) {
            writer.uint32(16).int32(message.nanos);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Timestamp();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.seconds = reader.int64();
                break;
              }
              case 2: {
                message.nanos = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.protobuf.Timestamp) {
            return object;
          }
          const message = new $root.google.protobuf.Timestamp();
          if (object.seconds != null) {
            if ($util.Long) {
              (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
            } else if (typeof object.seconds === "string") {
              message.seconds = parseInt(object.seconds, 10);
            } else if (typeof object.seconds === "number") {
              message.seconds = object.seconds;
            } else if (typeof object.seconds === "object") {
              message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
            }
          }
          if (object.nanos != null) {
            message.nanos = object.nanos | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.seconds = options.longs === String ? "0" : 0;
            }
            object.nanos = 0;
          }
          let keys;
          if (message.seconds != null && message.hasOwnProperty("seconds")) {
            object.seconds = typeof message.seconds === "number" ? (options.longs === String ? String(message.seconds) : message.seconds) : (options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds);
          }
          if (message.nanos != null && message.hasOwnProperty("nanos")) {
            object.nanos = message.nanos;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "google.protobuf.Timestamp";
        }
      }

      Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;
      Timestamp.prototype.nanos = 0;

      return Timestamp;
    })();

    return protobuf;
  })();

  return google;
})();

export const google = $root.google;
