import { FC } from 'react';
import { ItemProps } from './types';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { Icon } from '../Icon';
import { Flexbox } from '../Flexbox';
import { Container } from '../Container';

export const Item: FC<ItemProps> = ({ label, isSelected }) => {
  const theme = useTheme();
  return (
    <Flexbox
      justifyContent='flex-start'
      alignItems='center'
      data-testid='item'
      width='auto'
      height='auto'
      gap={0.125}
    >
      <Container width='1.25rem' pt={0.125}>
        <Icon
          name='check-badge'
          size={1.25}
          fill={isSelected ? theme.colors.text.muted : 'none'}
          stroke={isSelected ? 'none' : theme.colors.text.muted}
        />
      </Container>
      <Text tag='p2' color={theme.colors.text.body} whiteSpace='nowrap'>
        {label}
      </Text>
    </Flexbox>
  );
};
