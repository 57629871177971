"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./struct_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.morf_analytics = (() => {
  const morf_analytics = {};
  morf_analytics.v1 = (() => {
    const v1 = {};
    v1.TrackEventType = (() => {
      class TrackEventType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackEventType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName")) {
            writer.uint32(10).string(message.eventName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackEventType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TrackEventType) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TrackEventType();
          if (object.eventName != null) {
            message.eventName = String(object.eventName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventName = "";
          }
          let keys;
          if (message.eventName != null && message.hasOwnProperty("eventName")) {
            object.eventName = message.eventName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TrackEventType";
        }
      }

      TrackEventType.prototype.eventName = "";

      return TrackEventType;
    })();

    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            for (const element of message.eventTypes) {
              $root.morf_analytics.v1.TrackEventType.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                message.eventTypes.push($root.morf_analytics.v1.TrackEventType.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.morf_analytics.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".morf_analytics.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              if (typeof object.eventTypes[i] !== "object") {
                throw new TypeError(".morf_analytics.v1.SubscribedEventTypes.eventTypes: object expected, but got " + (typeof object.eventTypes[i]));
              }
              message.eventTypes[i] = $root.morf_analytics.v1.TrackEventType.fromObject(object.eventTypes[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = $root.morf_analytics.v1.TrackEventType.toObject(message.eventTypes[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.TrackProfileIds = (() => {
      class TrackProfileIds {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackProfileIds(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.customer != null && Object.hasOwnProperty.call(message, "customer")) {
            writer.uint32(10).string(message.customer);
          }
          if (message.formsort != null && Object.hasOwnProperty.call(message, "formsort")) {
            writer.uint32(18).string(message.formsort);
          }
          if (message.healthie != null && Object.hasOwnProperty.call(message, "healthie")) {
            writer.uint32(26).string(message.healthie);
          }
          if (message.axleHealth != null && Object.hasOwnProperty.call(message, "axleHealth")) {
            writer.uint32(34).string(message.axleHealth);
          }
          if (message.butterflyLabs != null && Object.hasOwnProperty.call(message, "butterflyLabs")) {
            writer.uint32(42).string(message.butterflyLabs);
          }
          if (message.recurly != null && Object.hasOwnProperty.call(message, "recurly")) {
            writer.uint32(50).string(message.recurly);
          }
          if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom")) {
            writer.uint32(58).string(message.intercom);
          }
          if (message.sanaBenefits != null && Object.hasOwnProperty.call(message, "sanaBenefits")) {
            writer.uint32(66).string(message.sanaBenefits);
          }
          if (message.activeCampaign != null && Object.hasOwnProperty.call(message, "activeCampaign")) {
            writer.uint32(74).string(message.activeCampaign);
          }
          if (message.vital != null && Object.hasOwnProperty.call(message, "vital")) {
            writer.uint32(82).string(message.vital);
          }
          if (message.segment != null && Object.hasOwnProperty.call(message, "segment")) {
            writer.uint32(90).string(message.segment);
          }
          if (message.intakeq != null && Object.hasOwnProperty.call(message, "intakeq")) {
            writer.uint32(98).string(message.intakeq);
          }
          if (message.customerIo != null && Object.hasOwnProperty.call(message, "customerIo")) {
            writer.uint32(106).string(message.customerIo);
          }
          if (message.freshdesk != null && Object.hasOwnProperty.call(message, "freshdesk")) {
            writer.uint32(114).string(message.freshdesk);
          }
          if (message.hubspot != null && Object.hasOwnProperty.call(message, "hubspot")) {
            writer.uint32(122).string(message.hubspot);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackProfileIds();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customer = reader.string();
                break;
              }
              case 2: {
                message.formsort = reader.string();
                break;
              }
              case 3: {
                message.healthie = reader.string();
                break;
              }
              case 4: {
                message.axleHealth = reader.string();
                break;
              }
              case 5: {
                message.butterflyLabs = reader.string();
                break;
              }
              case 6: {
                message.recurly = reader.string();
                break;
              }
              case 7: {
                message.intercom = reader.string();
                break;
              }
              case 8: {
                message.sanaBenefits = reader.string();
                break;
              }
              case 9: {
                message.activeCampaign = reader.string();
                break;
              }
              case 10: {
                message.vital = reader.string();
                break;
              }
              case 11: {
                message.segment = reader.string();
                break;
              }
              case 12: {
                message.intakeq = reader.string();
                break;
              }
              case 13: {
                message.customerIo = reader.string();
                break;
              }
              case 14: {
                message.freshdesk = reader.string();
                break;
              }
              case 15: {
                message.hubspot = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TrackProfileIds) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TrackProfileIds();
          if (object.customer != null) {
            message.customer = String(object.customer);
          }
          if (object.formsort != null) {
            message.formsort = String(object.formsort);
          }
          if (object.healthie != null) {
            message.healthie = String(object.healthie);
          }
          if (object.axleHealth != null) {
            message.axleHealth = String(object.axleHealth);
          }
          if (object.butterflyLabs != null) {
            message.butterflyLabs = String(object.butterflyLabs);
          }
          if (object.recurly != null) {
            message.recurly = String(object.recurly);
          }
          if (object.intercom != null) {
            message.intercom = String(object.intercom);
          }
          if (object.sanaBenefits != null) {
            message.sanaBenefits = String(object.sanaBenefits);
          }
          if (object.activeCampaign != null) {
            message.activeCampaign = String(object.activeCampaign);
          }
          if (object.vital != null) {
            message.vital = String(object.vital);
          }
          if (object.segment != null) {
            message.segment = String(object.segment);
          }
          if (object.intakeq != null) {
            message.intakeq = String(object.intakeq);
          }
          if (object.customerIo != null) {
            message.customerIo = String(object.customerIo);
          }
          if (object.freshdesk != null) {
            message.freshdesk = String(object.freshdesk);
          }
          if (object.hubspot != null) {
            message.hubspot = String(object.hubspot);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.customer != null && message.hasOwnProperty("customer")) {
            object.customer = message.customer;
          }
          if (message.formsort != null && message.hasOwnProperty("formsort")) {
            object.formsort = message.formsort;
          }
          if (message.healthie != null && message.hasOwnProperty("healthie")) {
            object.healthie = message.healthie;
          }
          if (message.axleHealth != null && message.hasOwnProperty("axleHealth")) {
            object.axleHealth = message.axleHealth;
          }
          if (message.butterflyLabs != null && message.hasOwnProperty("butterflyLabs")) {
            object.butterflyLabs = message.butterflyLabs;
          }
          if (message.recurly != null && message.hasOwnProperty("recurly")) {
            object.recurly = message.recurly;
          }
          if (message.intercom != null && message.hasOwnProperty("intercom")) {
            object.intercom = message.intercom;
          }
          if (message.sanaBenefits != null && message.hasOwnProperty("sanaBenefits")) {
            object.sanaBenefits = message.sanaBenefits;
          }
          if (message.activeCampaign != null && message.hasOwnProperty("activeCampaign")) {
            object.activeCampaign = message.activeCampaign;
          }
          if (message.vital != null && message.hasOwnProperty("vital")) {
            object.vital = message.vital;
          }
          if (message.segment != null && message.hasOwnProperty("segment")) {
            object.segment = message.segment;
          }
          if (message.intakeq != null && message.hasOwnProperty("intakeq")) {
            object.intakeq = message.intakeq;
          }
          if (message.customerIo != null && message.hasOwnProperty("customerIo")) {
            object.customerIo = message.customerIo;
          }
          if (message.freshdesk != null && message.hasOwnProperty("freshdesk")) {
            object.freshdesk = message.freshdesk;
          }
          if (message.hubspot != null && message.hasOwnProperty("hubspot")) {
            object.hubspot = message.hubspot;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TrackProfileIds";
        }
      }

      TrackProfileIds.prototype.customer = null;
      TrackProfileIds.prototype.formsort = null;
      TrackProfileIds.prototype.healthie = null;
      TrackProfileIds.prototype.axleHealth = null;
      TrackProfileIds.prototype.butterflyLabs = null;
      TrackProfileIds.prototype.recurly = null;
      TrackProfileIds.prototype.intercom = null;
      TrackProfileIds.prototype.sanaBenefits = null;
      TrackProfileIds.prototype.activeCampaign = null;
      TrackProfileIds.prototype.vital = null;
      TrackProfileIds.prototype.segment = null;
      TrackProfileIds.prototype.intakeq = null;
      TrackProfileIds.prototype.customerIo = null;
      TrackProfileIds.prototype.freshdesk = null;
      TrackProfileIds.prototype.hubspot = null;

      return TrackProfileIds;
    })();

    v1.Track = (() => {
      class Track {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Track(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId")) {
            writer.uint32(10).string(message.eventId);
          }
          if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName")) {
            writer.uint32(18).string(message.eventName);
          }
          if (message.userId != null && Object.hasOwnProperty.call(message, "userId")) {
            writer.uint32(26).string(message.userId);
          }
          if (message.profileIds != null && Object.hasOwnProperty.call(message, "profileIds")) {
            $root.morf_analytics.v1.TrackProfileIds.encode(message.profileIds, writer.uint32(34).fork()).ldelim();
          }
          if (message.profileProperties != null && Object.hasOwnProperty.call(message, "profileProperties")) {
            google$1.protobuf.Struct.encode(message.profileProperties, writer.uint32(42).fork()).ldelim();
          }
          if (message.eventData != null && Object.hasOwnProperty.call(message, "eventData")) {
            google$1.protobuf.Struct.encode(message.eventData, writer.uint32(50).fork()).ldelim();
          }
          if (message.occurredAt != null && Object.hasOwnProperty.call(message, "occurredAt")) {
            writer.uint32(58).string(message.occurredAt);
          }
          if (message.sourceId != null && Object.hasOwnProperty.call(message, "sourceId")) {
            writer.uint32(74).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Track();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventId = reader.string();
                break;
              }
              case 2: {
                message.eventName = reader.string();
                break;
              }
              case 3: {
                message.userId = reader.string();
                break;
              }
              case 4: {
                message.profileIds = $root.morf_analytics.v1.TrackProfileIds.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.profileProperties = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.eventData = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.occurredAt = reader.string();
                break;
              }
              case 9: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.Track) {
            return object;
          }
          const message = new $root.morf_analytics.v1.Track();
          if (object.eventId != null) {
            message.eventId = String(object.eventId);
          }
          if (object.eventName != null) {
            message.eventName = String(object.eventName);
          }
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.profileIds != null) {
            if (typeof object.profileIds !== "object") {
              throw new TypeError(".morf_analytics.v1.Track.profileIds: object expected, but got " + (typeof object.profileIds));
            }
            message.profileIds = $root.morf_analytics.v1.TrackProfileIds.fromObject(object.profileIds);
          }
          if (object.profileProperties != null) {
            if (typeof object.profileProperties !== "object") {
              throw new TypeError(".morf_analytics.v1.Track.profileProperties: object expected, but got " + (typeof object.profileProperties));
            }
            message.profileProperties = google$1.protobuf.Struct.fromObject(object.profileProperties);
          }
          if (object.eventData != null) {
            if (typeof object.eventData !== "object") {
              throw new TypeError(".morf_analytics.v1.Track.eventData: object expected, but got " + (typeof object.eventData));
            }
            message.eventData = google$1.protobuf.Struct.fromObject(object.eventData);
          }
          if (object.occurredAt != null) {
            message.occurredAt = String(object.occurredAt);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventName = "";
            object.userId = "";
            object.sourceId = "";
          }
          let keys;
          if (message.eventId != null && message.hasOwnProperty("eventId")) {
            object.eventId = message.eventId;
          }
          if (message.eventName != null && message.hasOwnProperty("eventName")) {
            object.eventName = message.eventName;
          }
          if (message.userId != null && message.hasOwnProperty("userId")) {
            object.userId = message.userId;
          }
          if (message.profileIds != null && message.hasOwnProperty("profileIds")) {
            object.profileIds = $root.morf_analytics.v1.TrackProfileIds.toObject(message.profileIds, options);
          }
          if (message.profileProperties != null && message.hasOwnProperty("profileProperties")) {
            object.profileProperties = google$1.protobuf.Struct.toObject(message.profileProperties, options);
          }
          if (message.eventData != null && message.hasOwnProperty("eventData")) {
            object.eventData = google$1.protobuf.Struct.toObject(message.eventData, options);
          }
          if (message.occurredAt != null && message.hasOwnProperty("occurredAt")) {
            object.occurredAt = message.occurredAt;
          }
          if (message.sourceId != null && message.hasOwnProperty("sourceId")) {
            object.sourceId = message.sourceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.Track";
        }
      }

      Track.prototype.eventId = null;
      Track.prototype.eventName = "";
      Track.prototype.userId = "";
      Track.prototype.profileIds = null;
      Track.prototype.profileProperties = null;
      Track.prototype.eventData = null;
      Track.prototype.occurredAt = null;
      Track.prototype.sourceId = "";

      return Track;
    })();

    v1.TrackResponse = (() => {
      class TrackResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.statusCode != null && Object.hasOwnProperty.call(message, "statusCode")) {
            writer.uint32(8).int32(message.statusCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.statusCode = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TrackResponse) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TrackResponse();
          if (object.statusCode != null) {
            message.statusCode = object.statusCode | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.statusCode = 0;
          }
          let keys;
          if (message.statusCode != null && message.hasOwnProperty("statusCode")) {
            object.statusCode = message.statusCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TrackResponse";
        }
      }

      TrackResponse.prototype.statusCode = 0;

      return TrackResponse;
    })();

    v1.MorfAnalyticsService = (() => {
      class MorfAnalyticsService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new MorfAnalyticsService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(MorfAnalyticsService.prototype.morfAnalyticsTrack = function morfAnalyticsTrack(request, callback) {
        return this.rpcCall(morfAnalyticsTrack, $root.morf_analytics.v1.Track, $root.morf_analytics.v1.TrackResponse, request, callback);
      }, "name", { value: "MorfAnalyticsTrack" });

      return MorfAnalyticsService;
    })();

    return v1;
  })();

  return morf_analytics;
})();

export const morf_analytics = $root.morf_analytics;
