import { styled } from '@morf/theming';

export const StyledTable = styled.table<
  { height: string } & React.HTMLAttributes<HTMLTableElement>
>`
  border-collapse: separate;
  border-radius: 0.5rem;
  border-spacing: 0;
  height: ${(props) => props.height};
  width: 100%;
`;
