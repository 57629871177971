"use strict";

import * as $protobuf from "protobufjs/minimal";
import { acuity as acuity$1 } from "./acuity_v1_ts_proto";
import { google as google$1 } from "./empty_ts_proto";
import { healthie as healthie$1 } from "./healthie_v1_ts_proto";
import { profiles as profiles$1 } from "./profiles_v1_ts_proto";
import { google as google$2 } from "./struct_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";
import { values as values$1 } from "./values_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.scheduling = (() => {
  const scheduling = {};
  scheduling.v1 = (() => {
    const v1 = {};
    v1.ListUniqueTagsRequest = (() => {
      class ListUniqueTagsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListUniqueTagsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListUniqueTagsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ListUniqueTagsRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.ListUniqueTagsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".scheduling.v1.ListUniqueTagsRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ListUniqueTagsRequest";
        }
      }

      ListUniqueTagsRequest.prototype.stub = null;

      return ListUniqueTagsRequest;
    })();

    v1.ListProvidersRequest = (() => {
      class ListProvidersRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProvidersRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(8).uint32(message.limit);
          }
          if (message.offset != null && Object.hasOwnProperty.call(message, "offset")) {
            writer.uint32(16).uint32(message.offset);
          }
          if (message.showOnlyEnabledForScheduling != null && Object.hasOwnProperty.call(message, "showOnlyEnabledForScheduling")) {
            writer.uint32(24).bool(message.showOnlyEnabledForScheduling);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProvidersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.limit = reader.uint32();
                break;
              }
              case 2: {
                message.offset = reader.uint32();
                break;
              }
              case 3: {
                message.showOnlyEnabledForScheduling = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ListProvidersRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.ListProvidersRequest();
          if (object.limit != null) {
            message.limit = object.limit >>> 0;
          }
          if (object.offset != null) {
            message.offset = object.offset >>> 0;
          }
          if (object.showOnlyEnabledForScheduling != null) {
            message.showOnlyEnabledForScheduling = Boolean(object.showOnlyEnabledForScheduling);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.limit = 0;
            object.offset = 0;
            object.showOnlyEnabledForScheduling = false;
          }
          let keys;
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = message.limit;
          }
          if (message.offset != null && message.hasOwnProperty("offset")) {
            object.offset = message.offset;
          }
          if (message.showOnlyEnabledForScheduling != null && message.hasOwnProperty("showOnlyEnabledForScheduling")) {
            object.showOnlyEnabledForScheduling = message.showOnlyEnabledForScheduling;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ListProvidersRequest";
        }
      }

      ListProvidersRequest.prototype.limit = 0;
      ListProvidersRequest.prototype.offset = 0;
      ListProvidersRequest.prototype.showOnlyEnabledForScheduling = false;

      return ListProvidersRequest;
    })();

    v1.ListProvidersResponse = (() => {
      class ListProvidersResponse {
        constructor(properties) {
          this.providers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProvidersResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.providers != null && Object.hasOwnProperty.call(message, "providers")) {
            for (const element of message.providers) {
              $root.scheduling.v1.Provider.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.nextOffset != null && Object.hasOwnProperty.call(message, "nextOffset")) {
            writer.uint32(16).uint32(message.nextOffset);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProvidersResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.providers || !message.providers.length) {
                  message.providers = [];
                }
                message.providers.push($root.scheduling.v1.Provider.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.nextOffset = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ListProvidersResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.ListProvidersResponse();
          if (object.providers) {
            if (!Array.isArray(object.providers)) {
              throw new TypeError(".scheduling.v1.ListProvidersResponse.providers: array type expected, but got " + (typeof object.providers))
            }
            message.providers = new Array(object.providers.length);
            for (let i = 0; i < object.providers.length; ++i) {
              if (typeof object.providers[i] !== "object") {
                throw new TypeError(".scheduling.v1.ListProvidersResponse.providers: object expected, but got " + (typeof object.providers[i]));
              }
              message.providers[i] = $root.scheduling.v1.Provider.fromObject(object.providers[i]);
            }
          }
          if (object.nextOffset != null) {
            message.nextOffset = object.nextOffset >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.providers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.providers && message.providers.length) {
            object.providers = new Array(message.providers.length);
            for (let i = 0; i < message.providers.length; ++i) {
              object.providers[i] = $root.scheduling.v1.Provider.toObject(message.providers[i], options);
            }
          }
          if (message.nextOffset != null && message.hasOwnProperty("nextOffset")) {
            object.nextOffset = message.nextOffset;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ListProvidersResponse";
        }
      }

      ListProvidersResponse.prototype.providers = $util.emptyArray;
      ListProvidersResponse.prototype.nextOffset = null;

      return ListProvidersResponse;
    })();

    v1.FilterInsurancePayer = (() => {
      class FilterInsurancePayer {
        get insurance() {
          for (const key of ["insurancePayer", "geographicallyScopedInsurancePayer"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set insurance(name) {
          for (const key of ["insurancePayer", "geographicallyScopedInsurancePayer"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FilterInsurancePayer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.insurancePayer != null && Object.hasOwnProperty.call(message, "insurancePayer")) {
            $root.scheduling.v1.InsurancePayer.encode(message.insurancePayer, writer.uint32(10).fork()).ldelim();
          }
          if (message.geographicallyScopedInsurancePayer != null && Object.hasOwnProperty.call(message, "geographicallyScopedInsurancePayer")) {
            $root.scheduling.v1.GeographicallyScopedInsurancePayer.encode(message.geographicallyScopedInsurancePayer, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FilterInsurancePayer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.insurancePayer = $root.scheduling.v1.InsurancePayer.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.geographicallyScopedInsurancePayer = $root.scheduling.v1.GeographicallyScopedInsurancePayer.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.FilterInsurancePayer) {
            return object;
          }
          const message = new $root.scheduling.v1.FilterInsurancePayer();
          if (object.insurancePayer != null) {
            if (typeof object.insurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.FilterInsurancePayer.insurancePayer: object expected, but got " + (typeof object.insurancePayer));
            }
            message.insurancePayer = $root.scheduling.v1.InsurancePayer.fromObject(object.insurancePayer);
          }
          if (object.geographicallyScopedInsurancePayer != null) {
            if (typeof object.geographicallyScopedInsurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.FilterInsurancePayer.geographicallyScopedInsurancePayer: object expected, but got " + (typeof object.geographicallyScopedInsurancePayer));
            }
            message.geographicallyScopedInsurancePayer = $root.scheduling.v1.GeographicallyScopedInsurancePayer.fromObject(object.geographicallyScopedInsurancePayer);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.insurancePayer != null && message.hasOwnProperty("insurancePayer")) {
            object.insurancePayer = $root.scheduling.v1.InsurancePayer.toObject(message.insurancePayer, options);
            if (options.oneofs) {
              object.insurance = "insurancePayer";
            }
          }
          if (message.geographicallyScopedInsurancePayer != null && message.hasOwnProperty("geographicallyScopedInsurancePayer")) {
            object.geographicallyScopedInsurancePayer = $root.scheduling.v1.GeographicallyScopedInsurancePayer.toObject(message.geographicallyScopedInsurancePayer, options);
            if (options.oneofs) {
              object.insurance = "geographicallyScopedInsurancePayer";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.FilterInsurancePayer";
        }
      }

      FilterInsurancePayer.prototype.insurancePayer = null;
      FilterInsurancePayer.prototype.geographicallyScopedInsurancePayer = null;

      return FilterInsurancePayer;
    })();

    v1.FilterProvidersRequest = (() => {
      class FilterProvidersRequest {
        constructor(properties) {
          this.licensedGeographicLocales = [];
          this.specialties = [];
          this.internalTags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FilterProvidersRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.licensedGeographicLocales != null && Object.hasOwnProperty.call(message, "licensedGeographicLocales")) {
            for (const element of message.licensedGeographicLocales) {
              writer.uint32(10).string(element);
            }
          }
          if (message.specialties != null && Object.hasOwnProperty.call(message, "specialties")) {
            for (const element of message.specialties) {
              writer.uint32(18).string(element);
            }
          }
          if (message.internalTags != null && Object.hasOwnProperty.call(message, "internalTags")) {
            for (const element of message.internalTags) {
              writer.uint32(26).string(element);
            }
          }
          if (message.insurance != null && Object.hasOwnProperty.call(message, "insurance")) {
            $root.scheduling.v1.FilterInsurancePayer.encode(message.insurance, writer.uint32(34).fork()).ldelim();
          }
          if (message.randomizeOrder != null && Object.hasOwnProperty.call(message, "randomizeOrder")) {
            writer.uint32(48).bool(message.randomizeOrder);
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(56).uint32(message.limit);
          }
          if (message.offset != null && Object.hasOwnProperty.call(message, "offset")) {
            writer.uint32(64).uint32(message.offset);
          }
          if (message.showOnlyEnabledForScheduling != null && Object.hasOwnProperty.call(message, "showOnlyEnabledForScheduling")) {
            writer.uint32(72).bool(message.showOnlyEnabledForScheduling);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FilterProvidersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.licensedGeographicLocales || !message.licensedGeographicLocales.length) {
                  message.licensedGeographicLocales = [];
                }
                message.licensedGeographicLocales.push(reader.string());
                break;
              }
              case 2: {
                if (!message.specialties || !message.specialties.length) {
                  message.specialties = [];
                }
                message.specialties.push(reader.string());
                break;
              }
              case 3: {
                if (!message.internalTags || !message.internalTags.length) {
                  message.internalTags = [];
                }
                message.internalTags.push(reader.string());
                break;
              }
              case 4: {
                message.insurance = $root.scheduling.v1.FilterInsurancePayer.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.randomizeOrder = reader.bool();
                break;
              }
              case 7: {
                message.limit = reader.uint32();
                break;
              }
              case 8: {
                message.offset = reader.uint32();
                break;
              }
              case 9: {
                message.showOnlyEnabledForScheduling = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.FilterProvidersRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.FilterProvidersRequest();
          if (object.licensedGeographicLocales) {
            if (!Array.isArray(object.licensedGeographicLocales)) {
              throw new TypeError(".scheduling.v1.FilterProvidersRequest.licensedGeographicLocales: array type expected, but got " + (typeof object.licensedGeographicLocales))
            }
            message.licensedGeographicLocales = new Array(object.licensedGeographicLocales.length);
            for (let i = 0; i < object.licensedGeographicLocales.length; ++i) {
              message.licensedGeographicLocales[i] = String(object.licensedGeographicLocales[i]);
            }
          }
          if (object.specialties) {
            if (!Array.isArray(object.specialties)) {
              throw new TypeError(".scheduling.v1.FilterProvidersRequest.specialties: array type expected, but got " + (typeof object.specialties))
            }
            message.specialties = new Array(object.specialties.length);
            for (let i = 0; i < object.specialties.length; ++i) {
              message.specialties[i] = String(object.specialties[i]);
            }
          }
          if (object.internalTags) {
            if (!Array.isArray(object.internalTags)) {
              throw new TypeError(".scheduling.v1.FilterProvidersRequest.internalTags: array type expected, but got " + (typeof object.internalTags))
            }
            message.internalTags = new Array(object.internalTags.length);
            for (let i = 0; i < object.internalTags.length; ++i) {
              message.internalTags[i] = String(object.internalTags[i]);
            }
          }
          if (object.insurance != null) {
            if (typeof object.insurance !== "object") {
              throw new TypeError(".scheduling.v1.FilterProvidersRequest.insurance: object expected, but got " + (typeof object.insurance));
            }
            message.insurance = $root.scheduling.v1.FilterInsurancePayer.fromObject(object.insurance);
          }
          if (object.randomizeOrder != null) {
            message.randomizeOrder = Boolean(object.randomizeOrder);
          }
          if (object.limit != null) {
            message.limit = object.limit >>> 0;
          }
          if (object.offset != null) {
            message.offset = object.offset >>> 0;
          }
          if (object.showOnlyEnabledForScheduling != null) {
            message.showOnlyEnabledForScheduling = Boolean(object.showOnlyEnabledForScheduling);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.licensedGeographicLocales = [];
            object.specialties = [];
            object.internalTags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.randomizeOrder = false;
            object.limit = 0;
            object.offset = 0;
            object.showOnlyEnabledForScheduling = false;
          }
          let keys;
          if (message.licensedGeographicLocales && message.licensedGeographicLocales.length) {
            object.licensedGeographicLocales = new Array(message.licensedGeographicLocales.length);
            for (let i = 0; i < message.licensedGeographicLocales.length; ++i) {
              object.licensedGeographicLocales[i] = message.licensedGeographicLocales[i];
            }
          }
          if (message.specialties && message.specialties.length) {
            object.specialties = new Array(message.specialties.length);
            for (let i = 0; i < message.specialties.length; ++i) {
              object.specialties[i] = message.specialties[i];
            }
          }
          if (message.internalTags && message.internalTags.length) {
            object.internalTags = new Array(message.internalTags.length);
            for (let i = 0; i < message.internalTags.length; ++i) {
              object.internalTags[i] = message.internalTags[i];
            }
          }
          if (message.insurance != null && message.hasOwnProperty("insurance")) {
            object.insurance = $root.scheduling.v1.FilterInsurancePayer.toObject(message.insurance, options);
          }
          if (message.randomizeOrder != null && message.hasOwnProperty("randomizeOrder")) {
            object.randomizeOrder = message.randomizeOrder;
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = message.limit;
          }
          if (message.offset != null && message.hasOwnProperty("offset")) {
            object.offset = message.offset;
          }
          if (message.showOnlyEnabledForScheduling != null && message.hasOwnProperty("showOnlyEnabledForScheduling")) {
            object.showOnlyEnabledForScheduling = message.showOnlyEnabledForScheduling;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.FilterProvidersRequest";
        }
      }

      FilterProvidersRequest.prototype.licensedGeographicLocales = $util.emptyArray;
      FilterProvidersRequest.prototype.specialties = $util.emptyArray;
      FilterProvidersRequest.prototype.internalTags = $util.emptyArray;
      FilterProvidersRequest.prototype.insurance = null;
      FilterProvidersRequest.prototype.randomizeOrder = false;
      FilterProvidersRequest.prototype.limit = 0;
      FilterProvidersRequest.prototype.offset = 0;
      FilterProvidersRequest.prototype.showOnlyEnabledForScheduling = false;

      return FilterProvidersRequest;
    })();

    v1.DeleteProviderResponse = (() => {
      class DeleteProviderResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteProviderResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteProviderResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.DeleteProviderResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.DeleteProviderResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.DeleteProviderResponse";
        }
      }

      return DeleteProviderResponse;
    })();

    v1.SearchNameRequest = (() => {
      class SearchNameRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SearchNameRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SearchNameRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.SearchNameRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.SearchNameRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.SearchNameRequest";
        }
      }

      SearchNameRequest.prototype.name = "";

      return SearchNameRequest;
    })();

    v1.GetMaximumOrderingIndexRequest = (() => {
      class GetMaximumOrderingIndexRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetMaximumOrderingIndexRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetMaximumOrderingIndexRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.GetMaximumOrderingIndexRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.GetMaximumOrderingIndexRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".scheduling.v1.GetMaximumOrderingIndexRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.GetMaximumOrderingIndexRequest";
        }
      }

      GetMaximumOrderingIndexRequest.prototype.stub = null;

      return GetMaximumOrderingIndexRequest;
    })();

    v1.GetMaximumOrderingIndexResponse = (() => {
      class GetMaximumOrderingIndexResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetMaximumOrderingIndexResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.maximumExistingOrderingIndex != null && Object.hasOwnProperty.call(message, "maximumExistingOrderingIndex")) {
            writer.uint32(8).uint32(message.maximumExistingOrderingIndex);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetMaximumOrderingIndexResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.maximumExistingOrderingIndex = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.GetMaximumOrderingIndexResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.GetMaximumOrderingIndexResponse();
          if (object.maximumExistingOrderingIndex != null) {
            message.maximumExistingOrderingIndex = object.maximumExistingOrderingIndex >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.maximumExistingOrderingIndex = 0;
          }
          let keys;
          if (message.maximumExistingOrderingIndex != null && message.hasOwnProperty("maximumExistingOrderingIndex")) {
            object.maximumExistingOrderingIndex = message.maximumExistingOrderingIndex;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.GetMaximumOrderingIndexResponse";
        }
      }

      GetMaximumOrderingIndexResponse.prototype.maximumExistingOrderingIndex = 0;

      return GetMaximumOrderingIndexResponse;
    })();

    v1.FilterProvidersForAvailabilityRequest = (() => {
      class FilterProvidersForAvailabilityRequest {
        constructor(properties) {
          this.specialties = [];
          this.internalTags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FilterProvidersForAvailabilityRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.appointmentTypeId != null && Object.hasOwnProperty.call(message, "appointmentTypeId")) {
            writer.uint32(18).string(message.appointmentTypeId);
          }
          if (message.licensedGeographicLocale != null && Object.hasOwnProperty.call(message, "licensedGeographicLocale")) {
            writer.uint32(26).string(message.licensedGeographicLocale);
          }
          if (message.insurancePayer != null && Object.hasOwnProperty.call(message, "insurancePayer")) {
            $root.scheduling.v1.InsurancePayer.encode(message.insurancePayer, writer.uint32(34).fork()).ldelim();
          }
          if (message.specialties != null && Object.hasOwnProperty.call(message, "specialties")) {
            for (const element of message.specialties) {
              writer.uint32(42).string(element);
            }
          }
          if (message.internalTags != null && Object.hasOwnProperty.call(message, "internalTags")) {
            for (const element of message.internalTags) {
              writer.uint32(50).string(element);
            }
          }
          if (message.apptLocId != null && Object.hasOwnProperty.call(message, "apptLocId")) {
            writer.uint32(58).string(message.apptLocId);
          }
          if (message.contactType != null && Object.hasOwnProperty.call(message, "contactType")) {
            writer.uint32(66).string(message.contactType);
          }
          if (message.randomizeOrder != null && Object.hasOwnProperty.call(message, "randomizeOrder")) {
            writer.uint32(72).bool(message.randomizeOrder);
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(80).uint32(message.limit);
          }
          if (message.offset != null && Object.hasOwnProperty.call(message, "offset")) {
            writer.uint32(88).uint32(message.offset);
          }
          if (message.schedulingTimezone != null && Object.hasOwnProperty.call(message, "schedulingTimezone")) {
            writer.uint32(98).string(message.schedulingTimezone);
          }
          if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate")) {
            values$1.v1.Date.encode(message.startDate, writer.uint32(106).fork()).ldelim();
          }
          if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate")) {
            values$1.v1.Date.encode(message.endDate, writer.uint32(114).fork()).ldelim();
          }
          if (message.rangeDurations != null && Object.hasOwnProperty.call(message, "rangeDurations")) {
            timing$1.v1.Duration.encode(message.rangeDurations, writer.uint32(122).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FilterProvidersForAvailabilityRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 3: {
                message.licensedGeographicLocale = reader.string();
                break;
              }
              case 4: {
                message.insurancePayer = $root.scheduling.v1.InsurancePayer.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                if (!message.specialties || !message.specialties.length) {
                  message.specialties = [];
                }
                message.specialties.push(reader.string());
                break;
              }
              case 6: {
                if (!message.internalTags || !message.internalTags.length) {
                  message.internalTags = [];
                }
                message.internalTags.push(reader.string());
                break;
              }
              case 7: {
                message.apptLocId = reader.string();
                break;
              }
              case 8: {
                message.contactType = reader.string();
                break;
              }
              case 9: {
                message.randomizeOrder = reader.bool();
                break;
              }
              case 10: {
                message.limit = reader.uint32();
                break;
              }
              case 11: {
                message.offset = reader.uint32();
                break;
              }
              case 12: {
                message.schedulingTimezone = reader.string();
                break;
              }
              case 13: {
                message.startDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.endDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.rangeDurations = timing$1.v1.Duration.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.FilterProvidersForAvailabilityRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.FilterProvidersForAvailabilityRequest();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.licensedGeographicLocale != null) {
            message.licensedGeographicLocale = String(object.licensedGeographicLocale);
          }
          if (object.insurancePayer != null) {
            if (typeof object.insurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityRequest.insurancePayer: object expected, but got " + (typeof object.insurancePayer));
            }
            message.insurancePayer = $root.scheduling.v1.InsurancePayer.fromObject(object.insurancePayer);
          }
          if (object.specialties) {
            if (!Array.isArray(object.specialties)) {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityRequest.specialties: array type expected, but got " + (typeof object.specialties))
            }
            message.specialties = new Array(object.specialties.length);
            for (let i = 0; i < object.specialties.length; ++i) {
              message.specialties[i] = String(object.specialties[i]);
            }
          }
          if (object.internalTags) {
            if (!Array.isArray(object.internalTags)) {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityRequest.internalTags: array type expected, but got " + (typeof object.internalTags))
            }
            message.internalTags = new Array(object.internalTags.length);
            for (let i = 0; i < object.internalTags.length; ++i) {
              message.internalTags[i] = String(object.internalTags[i]);
            }
          }
          if (object.apptLocId != null) {
            message.apptLocId = String(object.apptLocId);
          }
          if (object.contactType != null) {
            message.contactType = String(object.contactType);
          }
          if (object.randomizeOrder != null) {
            message.randomizeOrder = Boolean(object.randomizeOrder);
          }
          if (object.limit != null) {
            message.limit = object.limit >>> 0;
          }
          if (object.offset != null) {
            message.offset = object.offset >>> 0;
          }
          if (object.schedulingTimezone != null) {
            message.schedulingTimezone = String(object.schedulingTimezone);
          }
          if (object.startDate != null) {
            if (typeof object.startDate !== "object") {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityRequest.startDate: object expected, but got " + (typeof object.startDate));
            }
            message.startDate = values$1.v1.Date.fromObject(object.startDate);
          }
          if (object.endDate != null) {
            if (typeof object.endDate !== "object") {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityRequest.endDate: object expected, but got " + (typeof object.endDate));
            }
            message.endDate = values$1.v1.Date.fromObject(object.endDate);
          }
          if (object.rangeDurations != null) {
            if (typeof object.rangeDurations !== "object") {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityRequest.rangeDurations: object expected, but got " + (typeof object.rangeDurations));
            }
            message.rangeDurations = timing$1.v1.Duration.fromObject(object.rangeDurations);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.specialties = [];
            object.internalTags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.appointmentTypeId = "";
            object.randomizeOrder = false;
            object.limit = 0;
            object.offset = 0;
            object.schedulingTimezone = "";
            object.startDate = null;
            object.endDate = null;
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.application] === undefined ? message.application : $root.scheduling.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.appointmentTypeId != null && message.hasOwnProperty("appointmentTypeId")) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (message.licensedGeographicLocale != null && message.hasOwnProperty("licensedGeographicLocale")) {
            object.licensedGeographicLocale = message.licensedGeographicLocale;
          }
          if (message.insurancePayer != null && message.hasOwnProperty("insurancePayer")) {
            object.insurancePayer = $root.scheduling.v1.InsurancePayer.toObject(message.insurancePayer, options);
          }
          if (message.specialties && message.specialties.length) {
            object.specialties = new Array(message.specialties.length);
            for (let i = 0; i < message.specialties.length; ++i) {
              object.specialties[i] = message.specialties[i];
            }
          }
          if (message.internalTags && message.internalTags.length) {
            object.internalTags = new Array(message.internalTags.length);
            for (let i = 0; i < message.internalTags.length; ++i) {
              object.internalTags[i] = message.internalTags[i];
            }
          }
          if (message.apptLocId != null && message.hasOwnProperty("apptLocId")) {
            object.apptLocId = message.apptLocId;
          }
          if (message.contactType != null && message.hasOwnProperty("contactType")) {
            object.contactType = message.contactType;
          }
          if (message.randomizeOrder != null && message.hasOwnProperty("randomizeOrder")) {
            object.randomizeOrder = message.randomizeOrder;
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = message.limit;
          }
          if (message.offset != null && message.hasOwnProperty("offset")) {
            object.offset = message.offset;
          }
          if (message.schedulingTimezone != null && message.hasOwnProperty("schedulingTimezone")) {
            object.schedulingTimezone = message.schedulingTimezone;
          }
          if (message.startDate != null && message.hasOwnProperty("startDate")) {
            object.startDate = values$1.v1.Date.toObject(message.startDate, options);
          }
          if (message.endDate != null && message.hasOwnProperty("endDate")) {
            object.endDate = values$1.v1.Date.toObject(message.endDate, options);
          }
          if (message.rangeDurations != null && message.hasOwnProperty("rangeDurations")) {
            object.rangeDurations = timing$1.v1.Duration.toObject(message.rangeDurations, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.FilterProvidersForAvailabilityRequest";
        }
      }

      FilterProvidersForAvailabilityRequest.prototype.application = 0;
      FilterProvidersForAvailabilityRequest.prototype.appointmentTypeId = "";
      FilterProvidersForAvailabilityRequest.prototype.licensedGeographicLocale = null;
      FilterProvidersForAvailabilityRequest.prototype.insurancePayer = null;
      FilterProvidersForAvailabilityRequest.prototype.specialties = $util.emptyArray;
      FilterProvidersForAvailabilityRequest.prototype.internalTags = $util.emptyArray;
      FilterProvidersForAvailabilityRequest.prototype.apptLocId = null;
      FilterProvidersForAvailabilityRequest.prototype.contactType = null;
      FilterProvidersForAvailabilityRequest.prototype.randomizeOrder = false;
      FilterProvidersForAvailabilityRequest.prototype.limit = 0;
      FilterProvidersForAvailabilityRequest.prototype.offset = 0;
      FilterProvidersForAvailabilityRequest.prototype.schedulingTimezone = "";
      FilterProvidersForAvailabilityRequest.prototype.startDate = null;
      FilterProvidersForAvailabilityRequest.prototype.endDate = null;
      FilterProvidersForAvailabilityRequest.prototype.rangeDurations = null;

      return FilterProvidersForAvailabilityRequest;
    })();

    v1.GetAppointmentTypeInfoRequest = (() => {
      class GetAppointmentTypeInfoRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentTypeInfoRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.appointmentTypeId != null && Object.hasOwnProperty.call(message, "appointmentTypeId")) {
            writer.uint32(18).string(message.appointmentTypeId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentTypeInfoRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.appointmentTypeId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.GetAppointmentTypeInfoRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.GetAppointmentTypeInfoRequest();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.appointmentTypeId = "";
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.application] === undefined ? message.application : $root.scheduling.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.appointmentTypeId != null && message.hasOwnProperty("appointmentTypeId")) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.GetAppointmentTypeInfoRequest";
        }
      }

      GetAppointmentTypeInfoRequest.prototype.application = 0;
      GetAppointmentTypeInfoRequest.prototype.appointmentTypeId = "";

      return GetAppointmentTypeInfoRequest;
    })();

    v1.AppointmentTypeInfo = (() => {
      class AppointmentTypeInfo {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AppointmentTypeInfo(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes")) {
            writer.uint32(24).uint32(message.lengthMinutes);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AppointmentTypeInfo();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.lengthMinutes = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.AppointmentTypeInfo) {
            return object;
          }
          const message = new $root.scheduling.v1.AppointmentTypeInfo();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = object.lengthMinutes >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.name = "";
            object.lengthMinutes = 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes")) {
            object.lengthMinutes = message.lengthMinutes;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.AppointmentTypeInfo";
        }
      }

      AppointmentTypeInfo.prototype.id = "";
      AppointmentTypeInfo.prototype.name = "";
      AppointmentTypeInfo.prototype.lengthMinutes = 0;

      return AppointmentTypeInfo;
    })();

    v1.AvailabilitySlotTimeRange = (() => {
      class AvailabilitySlotTimeRange {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AvailabilitySlotTimeRange(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.startTimestamp != null && Object.hasOwnProperty.call(message, "startTimestamp")) {
            timing$1.v1.Timestamp.encode(message.startTimestamp, writer.uint32(10).fork()).ldelim();
          }
          if (message.endTimestamp != null && Object.hasOwnProperty.call(message, "endTimestamp")) {
            timing$1.v1.Timestamp.encode(message.endTimestamp, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AvailabilitySlotTimeRange();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.startTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.endTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.AvailabilitySlotTimeRange) {
            return object;
          }
          const message = new $root.scheduling.v1.AvailabilitySlotTimeRange();
          if (object.startTimestamp != null) {
            if (typeof object.startTimestamp !== "object") {
              throw new TypeError(".scheduling.v1.AvailabilitySlotTimeRange.startTimestamp: object expected, but got " + (typeof object.startTimestamp));
            }
            message.startTimestamp = timing$1.v1.Timestamp.fromObject(object.startTimestamp);
          }
          if (object.endTimestamp != null) {
            if (typeof object.endTimestamp !== "object") {
              throw new TypeError(".scheduling.v1.AvailabilitySlotTimeRange.endTimestamp: object expected, but got " + (typeof object.endTimestamp));
            }
            message.endTimestamp = timing$1.v1.Timestamp.fromObject(object.endTimestamp);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.startTimestamp = null;
            object.endTimestamp = null;
          }
          let keys;
          if (message.startTimestamp != null && message.hasOwnProperty("startTimestamp")) {
            object.startTimestamp = timing$1.v1.Timestamp.toObject(message.startTimestamp, options);
          }
          if (message.endTimestamp != null && message.hasOwnProperty("endTimestamp")) {
            object.endTimestamp = timing$1.v1.Timestamp.toObject(message.endTimestamp, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.AvailabilitySlotTimeRange";
        }
      }

      AvailabilitySlotTimeRange.prototype.startTimestamp = null;
      AvailabilitySlotTimeRange.prototype.endTimestamp = null;

      return AvailabilitySlotTimeRange;
    })();

    v1.FilterProvidersForAvailabilityResponse = (() => {
      class FilterProvidersForAvailabilityResponse {
        constructor(properties) {
          this.providers = [];
          this.availabilitySlotTimeRanges = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FilterProvidersForAvailabilityResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.providers != null && Object.hasOwnProperty.call(message, "providers")) {
            for (const element of message.providers) {
              $root.scheduling.v1.Provider.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.availabilitySlotTimeRanges != null && Object.hasOwnProperty.call(message, "availabilitySlotTimeRanges")) {
            for (const element of message.availabilitySlotTimeRanges) {
              $root.scheduling.v1.AvailabilitySlotTimeRange.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          if (message.nextOffset != null && Object.hasOwnProperty.call(message, "nextOffset")) {
            writer.uint32(24).uint32(message.nextOffset);
          }
          if (message.appointmentTypeInfo != null && Object.hasOwnProperty.call(message, "appointmentTypeInfo")) {
            $root.scheduling.v1.AppointmentTypeInfo.encode(message.appointmentTypeInfo, writer.uint32(34).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FilterProvidersForAvailabilityResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.providers || !message.providers.length) {
                  message.providers = [];
                }
                message.providers.push($root.scheduling.v1.Provider.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                if (!message.availabilitySlotTimeRanges || !message.availabilitySlotTimeRanges.length) {
                  message.availabilitySlotTimeRanges = [];
                }
                message.availabilitySlotTimeRanges.push($root.scheduling.v1.AvailabilitySlotTimeRange.decode(reader, reader.uint32()));
                break;
              }
              case 3: {
                message.nextOffset = reader.uint32();
                break;
              }
              case 4: {
                message.appointmentTypeInfo = $root.scheduling.v1.AppointmentTypeInfo.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.FilterProvidersForAvailabilityResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.FilterProvidersForAvailabilityResponse();
          if (object.providers) {
            if (!Array.isArray(object.providers)) {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityResponse.providers: array type expected, but got " + (typeof object.providers))
            }
            message.providers = new Array(object.providers.length);
            for (let i = 0; i < object.providers.length; ++i) {
              if (typeof object.providers[i] !== "object") {
                throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityResponse.providers: object expected, but got " + (typeof object.providers[i]));
              }
              message.providers[i] = $root.scheduling.v1.Provider.fromObject(object.providers[i]);
            }
          }
          if (object.availabilitySlotTimeRanges) {
            if (!Array.isArray(object.availabilitySlotTimeRanges)) {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityResponse.availabilitySlotTimeRanges: array type expected, but got " + (typeof object.availabilitySlotTimeRanges))
            }
            message.availabilitySlotTimeRanges = new Array(object.availabilitySlotTimeRanges.length);
            for (let i = 0; i < object.availabilitySlotTimeRanges.length; ++i) {
              if (typeof object.availabilitySlotTimeRanges[i] !== "object") {
                throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityResponse.availabilitySlotTimeRanges: object expected, but got " + (typeof object.availabilitySlotTimeRanges[i]));
              }
              message.availabilitySlotTimeRanges[i] = $root.scheduling.v1.AvailabilitySlotTimeRange.fromObject(object.availabilitySlotTimeRanges[i]);
            }
          }
          if (object.nextOffset != null) {
            message.nextOffset = object.nextOffset >>> 0;
          }
          if (object.appointmentTypeInfo != null) {
            if (typeof object.appointmentTypeInfo !== "object") {
              throw new TypeError(".scheduling.v1.FilterProvidersForAvailabilityResponse.appointmentTypeInfo: object expected, but got " + (typeof object.appointmentTypeInfo));
            }
            message.appointmentTypeInfo = $root.scheduling.v1.AppointmentTypeInfo.fromObject(object.appointmentTypeInfo);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.providers = [];
            object.availabilitySlotTimeRanges = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentTypeInfo = null;
          }
          let keys;
          if (message.providers && message.providers.length) {
            object.providers = new Array(message.providers.length);
            for (let i = 0; i < message.providers.length; ++i) {
              object.providers[i] = $root.scheduling.v1.Provider.toObject(message.providers[i], options);
            }
          }
          if (message.availabilitySlotTimeRanges && message.availabilitySlotTimeRanges.length) {
            object.availabilitySlotTimeRanges = new Array(message.availabilitySlotTimeRanges.length);
            for (let i = 0; i < message.availabilitySlotTimeRanges.length; ++i) {
              object.availabilitySlotTimeRanges[i] = $root.scheduling.v1.AvailabilitySlotTimeRange.toObject(message.availabilitySlotTimeRanges[i], options);
            }
          }
          if (message.nextOffset != null && message.hasOwnProperty("nextOffset")) {
            object.nextOffset = message.nextOffset;
          }
          if (message.appointmentTypeInfo != null && message.hasOwnProperty("appointmentTypeInfo")) {
            object.appointmentTypeInfo = $root.scheduling.v1.AppointmentTypeInfo.toObject(message.appointmentTypeInfo, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.FilterProvidersForAvailabilityResponse";
        }
      }

      FilterProvidersForAvailabilityResponse.prototype.providers = $util.emptyArray;
      FilterProvidersForAvailabilityResponse.prototype.availabilitySlotTimeRanges = $util.emptyArray;
      FilterProvidersForAvailabilityResponse.prototype.nextOffset = null;
      FilterProvidersForAvailabilityResponse.prototype.appointmentTypeInfo = null;

      return FilterProvidersForAvailabilityResponse;
    })();

    v1.ListAvailabilitySlotsRequest = (() => {
      class ListAvailabilitySlotsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAvailabilitySlotsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.appointmentTypeId != null && Object.hasOwnProperty.call(message, "appointmentTypeId")) {
            writer.uint32(10).string(message.appointmentTypeId);
          }
          if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId")) {
            $root.scheduling.v1.ProviderId.encode(message.providerId, writer.uint32(18).fork()).ldelim();
          }
          if (message.availabilitySlotTimeRange != null && Object.hasOwnProperty.call(message, "availabilitySlotTimeRange")) {
            $root.scheduling.v1.AvailabilitySlotTimeRange.encode(message.availabilitySlotTimeRange, writer.uint32(26).fork()).ldelim();
          }
          if (message.schedulingTimezone != null && Object.hasOwnProperty.call(message, "schedulingTimezone")) {
            writer.uint32(34).string(message.schedulingTimezone);
          }
          if (message.contactType != null && Object.hasOwnProperty.call(message, "contactType")) {
            writer.uint32(42).string(message.contactType);
          }
          if (message.apptLocId != null && Object.hasOwnProperty.call(message, "apptLocId")) {
            writer.uint32(50).string(message.apptLocId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAvailabilitySlotsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 2: {
                message.providerId = $root.scheduling.v1.ProviderId.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.availabilitySlotTimeRange = $root.scheduling.v1.AvailabilitySlotTimeRange.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.schedulingTimezone = reader.string();
                break;
              }
              case 5: {
                message.contactType = reader.string();
                break;
              }
              case 6: {
                message.apptLocId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ListAvailabilitySlotsRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.ListAvailabilitySlotsRequest();
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.providerId != null) {
            if (typeof object.providerId !== "object") {
              throw new TypeError(".scheduling.v1.ListAvailabilitySlotsRequest.providerId: object expected, but got " + (typeof object.providerId));
            }
            message.providerId = $root.scheduling.v1.ProviderId.fromObject(object.providerId);
          }
          if (object.availabilitySlotTimeRange != null) {
            if (typeof object.availabilitySlotTimeRange !== "object") {
              throw new TypeError(".scheduling.v1.ListAvailabilitySlotsRequest.availabilitySlotTimeRange: object expected, but got " + (typeof object.availabilitySlotTimeRange));
            }
            message.availabilitySlotTimeRange = $root.scheduling.v1.AvailabilitySlotTimeRange.fromObject(object.availabilitySlotTimeRange);
          }
          if (object.schedulingTimezone != null) {
            message.schedulingTimezone = String(object.schedulingTimezone);
          }
          if (object.contactType != null) {
            message.contactType = String(object.contactType);
          }
          if (object.apptLocId != null) {
            message.apptLocId = String(object.apptLocId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentTypeId = "";
            object.providerId = null;
            object.availabilitySlotTimeRange = null;
            object.schedulingTimezone = "";
          }
          let keys;
          if (message.appointmentTypeId != null && message.hasOwnProperty("appointmentTypeId")) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (message.providerId != null && message.hasOwnProperty("providerId")) {
            object.providerId = $root.scheduling.v1.ProviderId.toObject(message.providerId, options);
          }
          if (message.availabilitySlotTimeRange != null && message.hasOwnProperty("availabilitySlotTimeRange")) {
            object.availabilitySlotTimeRange = $root.scheduling.v1.AvailabilitySlotTimeRange.toObject(message.availabilitySlotTimeRange, options);
          }
          if (message.schedulingTimezone != null && message.hasOwnProperty("schedulingTimezone")) {
            object.schedulingTimezone = message.schedulingTimezone;
          }
          if (message.contactType != null && message.hasOwnProperty("contactType")) {
            object.contactType = message.contactType;
          }
          if (message.apptLocId != null && message.hasOwnProperty("apptLocId")) {
            object.apptLocId = message.apptLocId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ListAvailabilitySlotsRequest";
        }
      }

      ListAvailabilitySlotsRequest.prototype.appointmentTypeId = "";
      ListAvailabilitySlotsRequest.prototype.providerId = null;
      ListAvailabilitySlotsRequest.prototype.availabilitySlotTimeRange = null;
      ListAvailabilitySlotsRequest.prototype.schedulingTimezone = "";
      ListAvailabilitySlotsRequest.prototype.contactType = null;
      ListAvailabilitySlotsRequest.prototype.apptLocId = null;

      return ListAvailabilitySlotsRequest;
    })();

    v1.ListAvailabilitySlotsResponse = (() => {
      class ListAvailabilitySlotsResponse {
        constructor(properties) {
          this.slots = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListAvailabilitySlotsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId")) {
            $root.scheduling.v1.ProviderId.encode(message.providerId, writer.uint32(10).fork()).ldelim();
          }
          if (message.slots != null && Object.hasOwnProperty.call(message, "slots")) {
            for (const element of message.slots) {
              timing$1.v1.Timestamp.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListAvailabilitySlotsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.providerId = $root.scheduling.v1.ProviderId.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                if (!message.slots || !message.slots.length) {
                  message.slots = [];
                }
                message.slots.push(timing$1.v1.Timestamp.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ListAvailabilitySlotsResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.ListAvailabilitySlotsResponse();
          if (object.providerId != null) {
            if (typeof object.providerId !== "object") {
              throw new TypeError(".scheduling.v1.ListAvailabilitySlotsResponse.providerId: object expected, but got " + (typeof object.providerId));
            }
            message.providerId = $root.scheduling.v1.ProviderId.fromObject(object.providerId);
          }
          if (object.slots) {
            if (!Array.isArray(object.slots)) {
              throw new TypeError(".scheduling.v1.ListAvailabilitySlotsResponse.slots: array type expected, but got " + (typeof object.slots))
            }
            message.slots = new Array(object.slots.length);
            for (let i = 0; i < object.slots.length; ++i) {
              if (typeof object.slots[i] !== "object") {
                throw new TypeError(".scheduling.v1.ListAvailabilitySlotsResponse.slots: object expected, but got " + (typeof object.slots[i]));
              }
              message.slots[i] = timing$1.v1.Timestamp.fromObject(object.slots[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.slots = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.providerId = null;
          }
          let keys;
          if (message.providerId != null && message.hasOwnProperty("providerId")) {
            object.providerId = $root.scheduling.v1.ProviderId.toObject(message.providerId, options);
          }
          if (message.slots && message.slots.length) {
            object.slots = new Array(message.slots.length);
            for (let i = 0; i < message.slots.length; ++i) {
              object.slots[i] = timing$1.v1.Timestamp.toObject(message.slots[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ListAvailabilitySlotsResponse";
        }
      }

      ListAvailabilitySlotsResponse.prototype.providerId = null;
      ListAvailabilitySlotsResponse.prototype.slots = $util.emptyArray;

      return ListAvailabilitySlotsResponse;
    })();

    v1.ScheduleAppointmentRequest = (() => {
      class ScheduleAppointmentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ScheduleAppointmentRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.appointmentTypeId != null && Object.hasOwnProperty.call(message, "appointmentTypeId")) {
            writer.uint32(18).string(message.appointmentTypeId);
          }
          if (message.appointmentStartTime != null && Object.hasOwnProperty.call(message, "appointmentStartTime")) {
            timing$1.v1.Timestamp.encode(message.appointmentStartTime, writer.uint32(26).fork()).ldelim();
          }
          if (message.schedulingTimezone != null && Object.hasOwnProperty.call(message, "schedulingTimezone")) {
            writer.uint32(34).string(message.schedulingTimezone);
          }
          if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId")) {
            $root.scheduling.v1.ProviderId.encode(message.providerId, writer.uint32(42).fork()).ldelim();
          }
          if (message.customerProfileId != null && Object.hasOwnProperty.call(message, "customerProfileId")) {
            writer.uint32(50).string(message.customerProfileId);
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(58).string(message.emailAddress);
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(66).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(74).string(message.lastName);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            writer.uint32(82).string(message.phoneNumber);
          }
          if (message.sendWelcomeEmail != null && Object.hasOwnProperty.call(message, "sendWelcomeEmail")) {
            writer.uint32(88).bool(message.sendWelcomeEmail);
          }
          if (message.uniqueByEmail != null && Object.hasOwnProperty.call(message, "uniqueByEmail")) {
            writer.uint32(96).bool(message.uniqueByEmail);
          }
          if (message.contactType != null && Object.hasOwnProperty.call(message, "contactType")) {
            writer.uint32(106).string(message.contactType);
          }
          if (message.additionalFields != null && Object.hasOwnProperty.call(message, "additionalFields")) {
            google$2.protobuf.Struct.encode(message.additionalFields, writer.uint32(114).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ScheduleAppointmentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 3: {
                message.appointmentStartTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.schedulingTimezone = reader.string();
                break;
              }
              case 5: {
                message.providerId = $root.scheduling.v1.ProviderId.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.customerProfileId = reader.string();
                break;
              }
              case 7: {
                message.emailAddress = reader.string();
                break;
              }
              case 8: {
                message.firstName = reader.string();
                break;
              }
              case 9: {
                message.lastName = reader.string();
                break;
              }
              case 10: {
                message.phoneNumber = reader.string();
                break;
              }
              case 11: {
                message.sendWelcomeEmail = reader.bool();
                break;
              }
              case 12: {
                message.uniqueByEmail = reader.bool();
                break;
              }
              case 13: {
                message.contactType = reader.string();
                break;
              }
              case 14: {
                message.additionalFields = google$2.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ScheduleAppointmentRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.ScheduleAppointmentRequest();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.appointmentStartTime != null) {
            if (typeof object.appointmentStartTime !== "object") {
              throw new TypeError(".scheduling.v1.ScheduleAppointmentRequest.appointmentStartTime: object expected, but got " + (typeof object.appointmentStartTime));
            }
            message.appointmentStartTime = timing$1.v1.Timestamp.fromObject(object.appointmentStartTime);
          }
          if (object.schedulingTimezone != null) {
            message.schedulingTimezone = String(object.schedulingTimezone);
          }
          if (object.providerId != null) {
            if (typeof object.providerId !== "object") {
              throw new TypeError(".scheduling.v1.ScheduleAppointmentRequest.providerId: object expected, but got " + (typeof object.providerId));
            }
            message.providerId = $root.scheduling.v1.ProviderId.fromObject(object.providerId);
          }
          if (object.customerProfileId != null) {
            message.customerProfileId = String(object.customerProfileId);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.sendWelcomeEmail != null) {
            message.sendWelcomeEmail = Boolean(object.sendWelcomeEmail);
          }
          if (object.uniqueByEmail != null) {
            message.uniqueByEmail = Boolean(object.uniqueByEmail);
          }
          if (object.contactType != null) {
            message.contactType = String(object.contactType);
          }
          if (object.additionalFields != null) {
            if (typeof object.additionalFields !== "object") {
              throw new TypeError(".scheduling.v1.ScheduleAppointmentRequest.additionalFields: object expected, but got " + (typeof object.additionalFields));
            }
            message.additionalFields = google$2.protobuf.Struct.fromObject(object.additionalFields);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.appointmentTypeId = "";
            object.appointmentStartTime = null;
            object.schedulingTimezone = "";
            object.providerId = null;
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.application] === undefined ? message.application : $root.scheduling.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.appointmentTypeId != null && message.hasOwnProperty("appointmentTypeId")) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (message.appointmentStartTime != null && message.hasOwnProperty("appointmentStartTime")) {
            object.appointmentStartTime = timing$1.v1.Timestamp.toObject(message.appointmentStartTime, options);
          }
          if (message.schedulingTimezone != null && message.hasOwnProperty("schedulingTimezone")) {
            object.schedulingTimezone = message.schedulingTimezone;
          }
          if (message.providerId != null && message.hasOwnProperty("providerId")) {
            object.providerId = $root.scheduling.v1.ProviderId.toObject(message.providerId, options);
          }
          if (message.customerProfileId != null && message.hasOwnProperty("customerProfileId")) {
            object.customerProfileId = message.customerProfileId;
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = message.phoneNumber;
          }
          if (message.sendWelcomeEmail != null && message.hasOwnProperty("sendWelcomeEmail")) {
            object.sendWelcomeEmail = message.sendWelcomeEmail;
          }
          if (message.uniqueByEmail != null && message.hasOwnProperty("uniqueByEmail")) {
            object.uniqueByEmail = message.uniqueByEmail;
          }
          if (message.contactType != null && message.hasOwnProperty("contactType")) {
            object.contactType = message.contactType;
          }
          if (message.additionalFields != null && message.hasOwnProperty("additionalFields")) {
            object.additionalFields = google$2.protobuf.Struct.toObject(message.additionalFields, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ScheduleAppointmentRequest";
        }
      }

      ScheduleAppointmentRequest.prototype.application = 0;
      ScheduleAppointmentRequest.prototype.appointmentTypeId = "";
      ScheduleAppointmentRequest.prototype.appointmentStartTime = null;
      ScheduleAppointmentRequest.prototype.schedulingTimezone = "";
      ScheduleAppointmentRequest.prototype.providerId = null;
      ScheduleAppointmentRequest.prototype.customerProfileId = null;
      ScheduleAppointmentRequest.prototype.emailAddress = null;
      ScheduleAppointmentRequest.prototype.firstName = null;
      ScheduleAppointmentRequest.prototype.lastName = null;
      ScheduleAppointmentRequest.prototype.phoneNumber = null;
      ScheduleAppointmentRequest.prototype.sendWelcomeEmail = null;
      ScheduleAppointmentRequest.prototype.uniqueByEmail = null;
      ScheduleAppointmentRequest.prototype.contactType = null;
      ScheduleAppointmentRequest.prototype.additionalFields = null;

      return ScheduleAppointmentRequest;
    })();

    v1.ScheduleAppointmentResponse = (() => {
      class ScheduleAppointmentResponse {
        get type() {
          for (const key of ["healthieAppointment", "acuityAppointment"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["healthieAppointment", "acuityAppointment"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ScheduleAppointmentResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.healthieAppointment != null && Object.hasOwnProperty.call(message, "healthieAppointment")) {
            $root.scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment.encode(message.healthieAppointment, writer.uint32(10).fork()).ldelim();
          }
          if (message.acuityAppointment != null && Object.hasOwnProperty.call(message, "acuityAppointment")) {
            acuity$1.v1.Appointment.encode(message.acuityAppointment, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ScheduleAppointmentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.healthieAppointment = $root.scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.acuityAppointment = acuity$1.v1.Appointment.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ScheduleAppointmentResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.ScheduleAppointmentResponse();
          if (object.healthieAppointment != null) {
            if (typeof object.healthieAppointment !== "object") {
              throw new TypeError(".scheduling.v1.ScheduleAppointmentResponse.healthieAppointment: object expected, but got " + (typeof object.healthieAppointment));
            }
            message.healthieAppointment = $root.scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment.fromObject(object.healthieAppointment);
          }
          if (object.acuityAppointment != null) {
            if (typeof object.acuityAppointment !== "object") {
              throw new TypeError(".scheduling.v1.ScheduleAppointmentResponse.acuityAppointment: object expected, but got " + (typeof object.acuityAppointment));
            }
            message.acuityAppointment = acuity$1.v1.Appointment.fromObject(object.acuityAppointment);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.healthieAppointment != null && message.hasOwnProperty("healthieAppointment")) {
            object.healthieAppointment = $root.scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment.toObject(message.healthieAppointment, options);
            if (options.oneofs) {
              object.type = "healthieAppointment";
            }
          }
          if (message.acuityAppointment != null && message.hasOwnProperty("acuityAppointment")) {
            object.acuityAppointment = acuity$1.v1.Appointment.toObject(message.acuityAppointment, options);
            if (options.oneofs) {
              object.type = "acuityAppointment";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ScheduleAppointmentResponse";
        }
      }

      ScheduleAppointmentResponse.prototype.healthieAppointment = null;
      ScheduleAppointmentResponse.prototype.acuityAppointment = null;
      ScheduleAppointmentResponse.HealthieBookedAppointment = (() => {
        class HealthieBookedAppointment {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new HealthieBookedAppointment(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.thirdPartyIds != null && Object.hasOwnProperty.call(message, "thirdPartyIds")) {
              profiles$1.v1.ThirdPartyIds.encode(message.thirdPartyIds, writer.uint32(10).fork()).ldelim();
            }
            if (message.appointment != null && Object.hasOwnProperty.call(message, "appointment")) {
              healthie$1.v1.Appointment.encode(message.appointment, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new HealthieBookedAppointment();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.thirdPartyIds = profiles$1.v1.ThirdPartyIds.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.appointment = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment) {
              return object;
            }
            const message = new $root.scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment();
            if (object.thirdPartyIds != null) {
              if (typeof object.thirdPartyIds !== "object") {
                throw new TypeError(".scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment.thirdPartyIds: object expected, but got " + (typeof object.thirdPartyIds));
              }
              message.thirdPartyIds = profiles$1.v1.ThirdPartyIds.fromObject(object.thirdPartyIds);
            }
            if (object.appointment != null) {
              if (typeof object.appointment !== "object") {
                throw new TypeError(".scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment.appointment: object expected, but got " + (typeof object.appointment));
              }
              message.appointment = healthie$1.v1.Appointment.fromObject(object.appointment);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.thirdPartyIds = null;
              object.appointment = null;
            }
            let keys;
            if (message.thirdPartyIds != null && message.hasOwnProperty("thirdPartyIds")) {
              object.thirdPartyIds = profiles$1.v1.ThirdPartyIds.toObject(message.thirdPartyIds, options);
            }
            if (message.appointment != null && message.hasOwnProperty("appointment")) {
              object.appointment = healthie$1.v1.Appointment.toObject(message.appointment, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "scheduling.v1.ScheduleAppointmentResponse.HealthieBookedAppointment";
          }
        }

        HealthieBookedAppointment.prototype.thirdPartyIds = null;
        HealthieBookedAppointment.prototype.appointment = null;

        return HealthieBookedAppointment;
      })();

      return ScheduleAppointmentResponse;
    })();

    v1.GetAppointmentRequest = (() => {
      class GetAppointmentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId")) {
            writer.uint32(18).string(message.appointmentId);
          }
          if (message.schedulingTimezone != null && Object.hasOwnProperty.call(message, "schedulingTimezone")) {
            writer.uint32(26).string(message.schedulingTimezone);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.appointmentId = reader.string();
                break;
              }
              case 3: {
                message.schedulingTimezone = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.GetAppointmentRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.GetAppointmentRequest();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentId != null) {
            message.appointmentId = String(object.appointmentId);
          }
          if (object.schedulingTimezone != null) {
            message.schedulingTimezone = String(object.schedulingTimezone);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.appointmentId = "";
            object.schedulingTimezone = "";
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.application] === undefined ? message.application : $root.scheduling.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.appointmentId != null && message.hasOwnProperty("appointmentId")) {
            object.appointmentId = message.appointmentId;
          }
          if (message.schedulingTimezone != null && message.hasOwnProperty("schedulingTimezone")) {
            object.schedulingTimezone = message.schedulingTimezone;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.GetAppointmentRequest";
        }
      }

      GetAppointmentRequest.prototype.application = 0;
      GetAppointmentRequest.prototype.appointmentId = "";
      GetAppointmentRequest.prototype.schedulingTimezone = "";

      return GetAppointmentRequest;
    })();

    v1.GetAppointmentResponse = (() => {
      class GetAppointmentResponse {
        get type() {
          for (const key of ["healthieAppointment", "acuityAppointment"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["healthieAppointment", "acuityAppointment"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetAppointmentResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.healthieAppointment != null && Object.hasOwnProperty.call(message, "healthieAppointment")) {
            healthie$1.v1.Appointment.encode(message.healthieAppointment, writer.uint32(10).fork()).ldelim();
          }
          if (message.acuityAppointment != null && Object.hasOwnProperty.call(message, "acuityAppointment")) {
            acuity$1.v1.Appointment.encode(message.acuityAppointment, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetAppointmentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.healthieAppointment = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.acuityAppointment = acuity$1.v1.Appointment.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.GetAppointmentResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.GetAppointmentResponse();
          if (object.healthieAppointment != null) {
            if (typeof object.healthieAppointment !== "object") {
              throw new TypeError(".scheduling.v1.GetAppointmentResponse.healthieAppointment: object expected, but got " + (typeof object.healthieAppointment));
            }
            message.healthieAppointment = healthie$1.v1.Appointment.fromObject(object.healthieAppointment);
          }
          if (object.acuityAppointment != null) {
            if (typeof object.acuityAppointment !== "object") {
              throw new TypeError(".scheduling.v1.GetAppointmentResponse.acuityAppointment: object expected, but got " + (typeof object.acuityAppointment));
            }
            message.acuityAppointment = acuity$1.v1.Appointment.fromObject(object.acuityAppointment);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.healthieAppointment != null && message.hasOwnProperty("healthieAppointment")) {
            object.healthieAppointment = healthie$1.v1.Appointment.toObject(message.healthieAppointment, options);
            if (options.oneofs) {
              object.type = "healthieAppointment";
            }
          }
          if (message.acuityAppointment != null && message.hasOwnProperty("acuityAppointment")) {
            object.acuityAppointment = acuity$1.v1.Appointment.toObject(message.acuityAppointment, options);
            if (options.oneofs) {
              object.type = "acuityAppointment";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.GetAppointmentResponse";
        }
      }

      GetAppointmentResponse.prototype.healthieAppointment = null;
      GetAppointmentResponse.prototype.acuityAppointment = null;

      return GetAppointmentResponse;
    })();

    v1.CancelAppointmentRequest = (() => {
      class CancelAppointmentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CancelAppointmentRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId")) {
            writer.uint32(18).string(message.appointmentId);
          }
          if (message.schedulingTimezone != null && Object.hasOwnProperty.call(message, "schedulingTimezone")) {
            writer.uint32(26).string(message.schedulingTimezone);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CancelAppointmentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.appointmentId = reader.string();
                break;
              }
              case 3: {
                message.schedulingTimezone = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.CancelAppointmentRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.CancelAppointmentRequest();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentId != null) {
            message.appointmentId = String(object.appointmentId);
          }
          if (object.schedulingTimezone != null) {
            message.schedulingTimezone = String(object.schedulingTimezone);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.appointmentId = "";
            object.schedulingTimezone = "";
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.application] === undefined ? message.application : $root.scheduling.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.appointmentId != null && message.hasOwnProperty("appointmentId")) {
            object.appointmentId = message.appointmentId;
          }
          if (message.schedulingTimezone != null && message.hasOwnProperty("schedulingTimezone")) {
            object.schedulingTimezone = message.schedulingTimezone;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.CancelAppointmentRequest";
        }
      }

      CancelAppointmentRequest.prototype.application = 0;
      CancelAppointmentRequest.prototype.appointmentId = "";
      CancelAppointmentRequest.prototype.schedulingTimezone = "";

      return CancelAppointmentRequest;
    })();

    v1.CancelAppointmentResponse = (() => {
      class CancelAppointmentResponse {
        get type() {
          for (const key of ["healthieAppointment", "acuityAppointment"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["healthieAppointment", "acuityAppointment"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CancelAppointmentResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.healthieAppointment != null && Object.hasOwnProperty.call(message, "healthieAppointment")) {
            healthie$1.v1.Appointment.encode(message.healthieAppointment, writer.uint32(10).fork()).ldelim();
          }
          if (message.acuityAppointment != null && Object.hasOwnProperty.call(message, "acuityAppointment")) {
            acuity$1.v1.Appointment.encode(message.acuityAppointment, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CancelAppointmentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.healthieAppointment = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.acuityAppointment = acuity$1.v1.Appointment.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.CancelAppointmentResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.CancelAppointmentResponse();
          if (object.healthieAppointment != null) {
            if (typeof object.healthieAppointment !== "object") {
              throw new TypeError(".scheduling.v1.CancelAppointmentResponse.healthieAppointment: object expected, but got " + (typeof object.healthieAppointment));
            }
            message.healthieAppointment = healthie$1.v1.Appointment.fromObject(object.healthieAppointment);
          }
          if (object.acuityAppointment != null) {
            if (typeof object.acuityAppointment !== "object") {
              throw new TypeError(".scheduling.v1.CancelAppointmentResponse.acuityAppointment: object expected, but got " + (typeof object.acuityAppointment));
            }
            message.acuityAppointment = acuity$1.v1.Appointment.fromObject(object.acuityAppointment);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.healthieAppointment != null && message.hasOwnProperty("healthieAppointment")) {
            object.healthieAppointment = healthie$1.v1.Appointment.toObject(message.healthieAppointment, options);
            if (options.oneofs) {
              object.type = "healthieAppointment";
            }
          }
          if (message.acuityAppointment != null && message.hasOwnProperty("acuityAppointment")) {
            object.acuityAppointment = acuity$1.v1.Appointment.toObject(message.acuityAppointment, options);
            if (options.oneofs) {
              object.type = "acuityAppointment";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.CancelAppointmentResponse";
        }
      }

      CancelAppointmentResponse.prototype.healthieAppointment = null;
      CancelAppointmentResponse.prototype.acuityAppointment = null;

      return CancelAppointmentResponse;
    })();

    v1.RescheduleAppointmentRequest = (() => {
      class RescheduleAppointmentRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RescheduleAppointmentRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.existingAppointmentId != null && Object.hasOwnProperty.call(message, "existingAppointmentId")) {
            writer.uint32(18).string(message.existingAppointmentId);
          }
          if (message.newAppointmentStartTime != null && Object.hasOwnProperty.call(message, "newAppointmentStartTime")) {
            timing$1.v1.Timestamp.encode(message.newAppointmentStartTime, writer.uint32(26).fork()).ldelim();
          }
          if (message.schedulingTimezone != null && Object.hasOwnProperty.call(message, "schedulingTimezone")) {
            writer.uint32(34).string(message.schedulingTimezone);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RescheduleAppointmentRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.existingAppointmentId = reader.string();
                break;
              }
              case 3: {
                message.newAppointmentStartTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.schedulingTimezone = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.RescheduleAppointmentRequest) {
            return object;
          }
          const message = new $root.scheduling.v1.RescheduleAppointmentRequest();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.existingAppointmentId != null) {
            message.existingAppointmentId = String(object.existingAppointmentId);
          }
          if (object.newAppointmentStartTime != null) {
            if (typeof object.newAppointmentStartTime !== "object") {
              throw new TypeError(".scheduling.v1.RescheduleAppointmentRequest.newAppointmentStartTime: object expected, but got " + (typeof object.newAppointmentStartTime));
            }
            message.newAppointmentStartTime = timing$1.v1.Timestamp.fromObject(object.newAppointmentStartTime);
          }
          if (object.schedulingTimezone != null) {
            message.schedulingTimezone = String(object.schedulingTimezone);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.existingAppointmentId = "";
            object.newAppointmentStartTime = null;
            object.schedulingTimezone = "";
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.application] === undefined ? message.application : $root.scheduling.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.existingAppointmentId != null && message.hasOwnProperty("existingAppointmentId")) {
            object.existingAppointmentId = message.existingAppointmentId;
          }
          if (message.newAppointmentStartTime != null && message.hasOwnProperty("newAppointmentStartTime")) {
            object.newAppointmentStartTime = timing$1.v1.Timestamp.toObject(message.newAppointmentStartTime, options);
          }
          if (message.schedulingTimezone != null && message.hasOwnProperty("schedulingTimezone")) {
            object.schedulingTimezone = message.schedulingTimezone;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.RescheduleAppointmentRequest";
        }
      }

      RescheduleAppointmentRequest.prototype.application = 0;
      RescheduleAppointmentRequest.prototype.existingAppointmentId = "";
      RescheduleAppointmentRequest.prototype.newAppointmentStartTime = null;
      RescheduleAppointmentRequest.prototype.schedulingTimezone = "";

      return RescheduleAppointmentRequest;
    })();

    v1.RescheduleAppointmentResponse = (() => {
      class RescheduleAppointmentResponse {
        get type() {
          for (const key of ["healthie", "acuity"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["healthie", "acuity"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RescheduleAppointmentResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.healthie != null && Object.hasOwnProperty.call(message, "healthie")) {
            $root.scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment.encode(message.healthie, writer.uint32(10).fork()).ldelim();
          }
          if (message.acuity != null && Object.hasOwnProperty.call(message, "acuity")) {
            $root.scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment.encode(message.acuity, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RescheduleAppointmentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.healthie = $root.scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.acuity = $root.scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.RescheduleAppointmentResponse) {
            return object;
          }
          const message = new $root.scheduling.v1.RescheduleAppointmentResponse();
          if (object.healthie != null) {
            if (typeof object.healthie !== "object") {
              throw new TypeError(".scheduling.v1.RescheduleAppointmentResponse.healthie: object expected, but got " + (typeof object.healthie));
            }
            message.healthie = $root.scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment.fromObject(object.healthie);
          }
          if (object.acuity != null) {
            if (typeof object.acuity !== "object") {
              throw new TypeError(".scheduling.v1.RescheduleAppointmentResponse.acuity: object expected, but got " + (typeof object.acuity));
            }
            message.acuity = $root.scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment.fromObject(object.acuity);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.healthie != null && message.hasOwnProperty("healthie")) {
            object.healthie = $root.scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment.toObject(message.healthie, options);
            if (options.oneofs) {
              object.type = "healthie";
            }
          }
          if (message.acuity != null && message.hasOwnProperty("acuity")) {
            object.acuity = $root.scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment.toObject(message.acuity, options);
            if (options.oneofs) {
              object.type = "acuity";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.RescheduleAppointmentResponse";
        }
      }

      RescheduleAppointmentResponse.prototype.healthie = null;
      RescheduleAppointmentResponse.prototype.acuity = null;
      RescheduleAppointmentResponse.HealthieRescheduledAppointment = (() => {
        class HealthieRescheduledAppointment {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new HealthieRescheduledAppointment(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.existingAppointment != null && Object.hasOwnProperty.call(message, "existingAppointment")) {
              healthie$1.v1.Appointment.encode(message.existingAppointment, writer.uint32(10).fork()).ldelim();
            }
            if (message.newAppointment != null && Object.hasOwnProperty.call(message, "newAppointment")) {
              healthie$1.v1.Appointment.encode(message.newAppointment, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new HealthieRescheduledAppointment();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.existingAppointment = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.newAppointment = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment) {
              return object;
            }
            const message = new $root.scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment();
            if (object.existingAppointment != null) {
              if (typeof object.existingAppointment !== "object") {
                throw new TypeError(".scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment.existingAppointment: object expected, but got " + (typeof object.existingAppointment));
              }
              message.existingAppointment = healthie$1.v1.Appointment.fromObject(object.existingAppointment);
            }
            if (object.newAppointment != null) {
              if (typeof object.newAppointment !== "object") {
                throw new TypeError(".scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment.newAppointment: object expected, but got " + (typeof object.newAppointment));
              }
              message.newAppointment = healthie$1.v1.Appointment.fromObject(object.newAppointment);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.existingAppointment = null;
              object.newAppointment = null;
            }
            let keys;
            if (message.existingAppointment != null && message.hasOwnProperty("existingAppointment")) {
              object.existingAppointment = healthie$1.v1.Appointment.toObject(message.existingAppointment, options);
            }
            if (message.newAppointment != null && message.hasOwnProperty("newAppointment")) {
              object.newAppointment = healthie$1.v1.Appointment.toObject(message.newAppointment, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "scheduling.v1.RescheduleAppointmentResponse.HealthieRescheduledAppointment";
          }
        }

        HealthieRescheduledAppointment.prototype.existingAppointment = null;
        HealthieRescheduledAppointment.prototype.newAppointment = null;

        return HealthieRescheduledAppointment;
      })();

      RescheduleAppointmentResponse.AcuityRescheduledAppointment = (() => {
        class AcuityRescheduledAppointment {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new AcuityRescheduledAppointment(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.existingAppointment != null && Object.hasOwnProperty.call(message, "existingAppointment")) {
              acuity$1.v1.Appointment.encode(message.existingAppointment, writer.uint32(10).fork()).ldelim();
            }
            if (message.newAppointment != null && Object.hasOwnProperty.call(message, "newAppointment")) {
              acuity$1.v1.Appointment.encode(message.newAppointment, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new AcuityRescheduledAppointment();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.existingAppointment = acuity$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.newAppointment = acuity$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment) {
              return object;
            }
            const message = new $root.scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment();
            if (object.existingAppointment != null) {
              if (typeof object.existingAppointment !== "object") {
                throw new TypeError(".scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment.existingAppointment: object expected, but got " + (typeof object.existingAppointment));
              }
              message.existingAppointment = acuity$1.v1.Appointment.fromObject(object.existingAppointment);
            }
            if (object.newAppointment != null) {
              if (typeof object.newAppointment !== "object") {
                throw new TypeError(".scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment.newAppointment: object expected, but got " + (typeof object.newAppointment));
              }
              message.newAppointment = acuity$1.v1.Appointment.fromObject(object.newAppointment);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.existingAppointment = null;
              object.newAppointment = null;
            }
            let keys;
            if (message.existingAppointment != null && message.hasOwnProperty("existingAppointment")) {
              object.existingAppointment = acuity$1.v1.Appointment.toObject(message.existingAppointment, options);
            }
            if (message.newAppointment != null && message.hasOwnProperty("newAppointment")) {
              object.newAppointment = acuity$1.v1.Appointment.toObject(message.newAppointment, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "scheduling.v1.RescheduleAppointmentResponse.AcuityRescheduledAppointment";
          }
        }

        AcuityRescheduledAppointment.prototype.existingAppointment = null;
        AcuityRescheduledAppointment.prototype.newAppointment = null;

        return AcuityRescheduledAppointment;
      })();

      return RescheduleAppointmentResponse;
    })();

    v1.QualificationLabel = (() => {
      class QualificationLabel {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new QualificationLabel(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation")) {
            writer.uint32(10).string(message.abbreviation);
          }
          if (message.label != null && Object.hasOwnProperty.call(message, "label")) {
            writer.uint32(18).string(message.label);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new QualificationLabel();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.abbreviation = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.QualificationLabel) {
            return object;
          }
          const message = new $root.scheduling.v1.QualificationLabel();
          if (object.abbreviation != null) {
            message.abbreviation = String(object.abbreviation);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.abbreviation = "";
            object.label = "";
          }
          let keys;
          if (message.abbreviation != null && message.hasOwnProperty("abbreviation")) {
            object.abbreviation = message.abbreviation;
          }
          if (message.label != null && message.hasOwnProperty("label")) {
            object.label = message.label;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.QualificationLabel";
        }
      }

      QualificationLabel.prototype.abbreviation = "";
      QualificationLabel.prototype.label = "";

      return QualificationLabel;
    })();

    v1.InsuranceType = (() => {
      class InsuranceType {
        constructor(properties) {
          this.geographicLocaleScopings = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InsuranceType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.geographicLocaleScopings != null && Object.hasOwnProperty.call(message, "geographicLocaleScopings")) {
            for (const element of message.geographicLocaleScopings) {
              writer.uint32(10).string(element);
            }
          }
          if (message.insurancePayer != null && Object.hasOwnProperty.call(message, "insurancePayer")) {
            $root.scheduling.v1.InsurancePayer.encode(message.insurancePayer, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InsuranceType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.geographicLocaleScopings || !message.geographicLocaleScopings.length) {
                  message.geographicLocaleScopings = [];
                }
                message.geographicLocaleScopings.push(reader.string());
                break;
              }
              case 2: {
                message.insurancePayer = $root.scheduling.v1.InsurancePayer.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.InsuranceType) {
            return object;
          }
          const message = new $root.scheduling.v1.InsuranceType();
          if (object.geographicLocaleScopings) {
            if (!Array.isArray(object.geographicLocaleScopings)) {
              throw new TypeError(".scheduling.v1.InsuranceType.geographicLocaleScopings: array type expected, but got " + (typeof object.geographicLocaleScopings))
            }
            message.geographicLocaleScopings = new Array(object.geographicLocaleScopings.length);
            for (let i = 0; i < object.geographicLocaleScopings.length; ++i) {
              message.geographicLocaleScopings[i] = String(object.geographicLocaleScopings[i]);
            }
          }
          if (object.insurancePayer != null) {
            if (typeof object.insurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.InsuranceType.insurancePayer: object expected, but got " + (typeof object.insurancePayer));
            }
            message.insurancePayer = $root.scheduling.v1.InsurancePayer.fromObject(object.insurancePayer);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.geographicLocaleScopings = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.insurancePayer = null;
          }
          let keys;
          if (message.geographicLocaleScopings && message.geographicLocaleScopings.length) {
            object.geographicLocaleScopings = new Array(message.geographicLocaleScopings.length);
            for (let i = 0; i < message.geographicLocaleScopings.length; ++i) {
              object.geographicLocaleScopings[i] = message.geographicLocaleScopings[i];
            }
          }
          if (message.insurancePayer != null && message.hasOwnProperty("insurancePayer")) {
            object.insurancePayer = $root.scheduling.v1.InsurancePayer.toObject(message.insurancePayer, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.InsuranceType";
        }
      }

      InsuranceType.prototype.geographicLocaleScopings = $util.emptyArray;
      InsuranceType.prototype.insurancePayer = null;

      return InsuranceType;
    })();

    v1.InsurancePayer = (() => {
      class InsurancePayer {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InsurancePayer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InsurancePayer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.InsurancePayer) {
            return object;
          }
          const message = new $root.scheduling.v1.InsurancePayer();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.name = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.InsurancePayer";
        }
      }

      InsurancePayer.prototype.id = "";
      InsurancePayer.prototype.name = "";

      return InsurancePayer;
    })();

    v1.GeographicallyScopedInsurancePayer = (() => {
      class GeographicallyScopedInsurancePayer {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GeographicallyScopedInsurancePayer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.insurancePayer != null && Object.hasOwnProperty.call(message, "insurancePayer")) {
            $root.scheduling.v1.InsurancePayer.encode(message.insurancePayer, writer.uint32(10).fork()).ldelim();
          }
          if (message.geographicLocale != null && Object.hasOwnProperty.call(message, "geographicLocale")) {
            writer.uint32(18).string(message.geographicLocale);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GeographicallyScopedInsurancePayer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.insurancePayer = $root.scheduling.v1.InsurancePayer.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.geographicLocale = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.GeographicallyScopedInsurancePayer) {
            return object;
          }
          const message = new $root.scheduling.v1.GeographicallyScopedInsurancePayer();
          if (object.insurancePayer != null) {
            if (typeof object.insurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.GeographicallyScopedInsurancePayer.insurancePayer: object expected, but got " + (typeof object.insurancePayer));
            }
            message.insurancePayer = $root.scheduling.v1.InsurancePayer.fromObject(object.insurancePayer);
          }
          if (object.geographicLocale != null) {
            message.geographicLocale = String(object.geographicLocale);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.insurancePayer = null;
            object.geographicLocale = "";
          }
          let keys;
          if (message.insurancePayer != null && message.hasOwnProperty("insurancePayer")) {
            object.insurancePayer = $root.scheduling.v1.InsurancePayer.toObject(message.insurancePayer, options);
          }
          if (message.geographicLocale != null && message.hasOwnProperty("geographicLocale")) {
            object.geographicLocale = message.geographicLocale;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.GeographicallyScopedInsurancePayer";
        }
      }

      GeographicallyScopedInsurancePayer.prototype.insurancePayer = null;
      GeographicallyScopedInsurancePayer.prototype.geographicLocale = "";

      return GeographicallyScopedInsurancePayer;
    })();

    v1.ProviderId = (() => {
      class ProviderId {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProviderId(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.idType != null && Object.hasOwnProperty.call(message, "idType")) {
            writer.uint32(8).int32(message.idType);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(18).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProviderId();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.idType = reader.int32();
                break;
              }
              case 2: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.ProviderId) {
            return object;
          }
          const message = new $root.scheduling.v1.ProviderId();
          if (object.idType != null) {
            switch (object.idType) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.idType = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.idType = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.idType = 2;
                break;
              }
              default: {
                if (typeof object.idType == "number") {
                  message.idType = object.idType;
                  break;
                }
                break;
              }
            }
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.idType = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.id = "";
          }
          let keys;
          if (message.idType != null && message.hasOwnProperty("idType")) {
            object.idType = options.enums === String ? $root.scheduling.v1.SchedulingApplication[message.idType] === undefined ? message.idType : $root.scheduling.v1.SchedulingApplication[message.idType] : message.idType;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.ProviderId";
        }
      }

      ProviderId.prototype.idType = 0;
      ProviderId.prototype.id = "";

      return ProviderId;
    })();

    v1.Provider = (() => {
      class Provider {
        constructor(properties) {
          this.qualificationAbbreviations = [];
          this.licensedGeographicLocales = [];
          this.specialties = [];
          this.internalTags = [];
          this.insuranceTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Provider(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId")) {
            $root.scheduling.v1.ProviderId.encode(message.providerId, writer.uint32(10).fork()).ldelim();
          }
          if (message.orderingIndex != null && Object.hasOwnProperty.call(message, "orderingIndex")) {
            writer.uint32(16).uint32(message.orderingIndex);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(26).string(message.name);
          }
          if (message.avatarImageUrl != null && Object.hasOwnProperty.call(message, "avatarImageUrl")) {
            writer.uint32(34).string(message.avatarImageUrl);
          }
          if (message.shortBio != null && Object.hasOwnProperty.call(message, "shortBio")) {
            writer.uint32(42).string(message.shortBio);
          }
          if (message.bio != null && Object.hasOwnProperty.call(message, "bio")) {
            writer.uint32(50).string(message.bio);
          }
          if (message.qualificationAbbreviations != null && Object.hasOwnProperty.call(message, "qualificationAbbreviations")) {
            for (const element of message.qualificationAbbreviations) {
              writer.uint32(58).string(element);
            }
          }
          if (message.licensedGeographicLocales != null && Object.hasOwnProperty.call(message, "licensedGeographicLocales")) {
            for (const element of message.licensedGeographicLocales) {
              writer.uint32(66).string(element);
            }
          }
          if (message.specialties != null && Object.hasOwnProperty.call(message, "specialties")) {
            for (const element of message.specialties) {
              writer.uint32(74).string(element);
            }
          }
          if (message.internalTags != null && Object.hasOwnProperty.call(message, "internalTags")) {
            for (const element of message.internalTags) {
              writer.uint32(82).string(element);
            }
          }
          if (message.insuranceTypes != null && Object.hasOwnProperty.call(message, "insuranceTypes")) {
            for (const element of message.insuranceTypes) {
              $root.scheduling.v1.InsuranceType.encode(element, writer.uint32(90).fork()).ldelim();
            }
          }
          if (message.upsertTime != null && Object.hasOwnProperty.call(message, "upsertTime")) {
            timing$1.v1.Timestamp.encode(message.upsertTime, writer.uint32(98).fork()).ldelim();
          }
          if (message.enabledForScheduling != null && Object.hasOwnProperty.call(message, "enabledForScheduling")) {
            writer.uint32(104).bool(message.enabledForScheduling);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Provider();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.providerId = $root.scheduling.v1.ProviderId.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.orderingIndex = reader.uint32();
                break;
              }
              case 3: {
                message.name = reader.string();
                break;
              }
              case 4: {
                message.avatarImageUrl = reader.string();
                break;
              }
              case 5: {
                message.shortBio = reader.string();
                break;
              }
              case 6: {
                message.bio = reader.string();
                break;
              }
              case 7: {
                if (!message.qualificationAbbreviations || !message.qualificationAbbreviations.length) {
                  message.qualificationAbbreviations = [];
                }
                message.qualificationAbbreviations.push(reader.string());
                break;
              }
              case 8: {
                if (!message.licensedGeographicLocales || !message.licensedGeographicLocales.length) {
                  message.licensedGeographicLocales = [];
                }
                message.licensedGeographicLocales.push(reader.string());
                break;
              }
              case 9: {
                if (!message.specialties || !message.specialties.length) {
                  message.specialties = [];
                }
                message.specialties.push(reader.string());
                break;
              }
              case 10: {
                if (!message.internalTags || !message.internalTags.length) {
                  message.internalTags = [];
                }
                message.internalTags.push(reader.string());
                break;
              }
              case 11: {
                if (!message.insuranceTypes || !message.insuranceTypes.length) {
                  message.insuranceTypes = [];
                }
                message.insuranceTypes.push($root.scheduling.v1.InsuranceType.decode(reader, reader.uint32()));
                break;
              }
              case 12: {
                message.upsertTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.enabledForScheduling = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.Provider) {
            return object;
          }
          const message = new $root.scheduling.v1.Provider();
          if (object.providerId != null) {
            if (typeof object.providerId !== "object") {
              throw new TypeError(".scheduling.v1.Provider.providerId: object expected, but got " + (typeof object.providerId));
            }
            message.providerId = $root.scheduling.v1.ProviderId.fromObject(object.providerId);
          }
          if (object.orderingIndex != null) {
            message.orderingIndex = object.orderingIndex >>> 0;
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.avatarImageUrl != null) {
            message.avatarImageUrl = String(object.avatarImageUrl);
          }
          if (object.shortBio != null) {
            message.shortBio = String(object.shortBio);
          }
          if (object.bio != null) {
            message.bio = String(object.bio);
          }
          if (object.qualificationAbbreviations) {
            if (!Array.isArray(object.qualificationAbbreviations)) {
              throw new TypeError(".scheduling.v1.Provider.qualificationAbbreviations: array type expected, but got " + (typeof object.qualificationAbbreviations))
            }
            message.qualificationAbbreviations = new Array(object.qualificationAbbreviations.length);
            for (let i = 0; i < object.qualificationAbbreviations.length; ++i) {
              message.qualificationAbbreviations[i] = String(object.qualificationAbbreviations[i]);
            }
          }
          if (object.licensedGeographicLocales) {
            if (!Array.isArray(object.licensedGeographicLocales)) {
              throw new TypeError(".scheduling.v1.Provider.licensedGeographicLocales: array type expected, but got " + (typeof object.licensedGeographicLocales))
            }
            message.licensedGeographicLocales = new Array(object.licensedGeographicLocales.length);
            for (let i = 0; i < object.licensedGeographicLocales.length; ++i) {
              message.licensedGeographicLocales[i] = String(object.licensedGeographicLocales[i]);
            }
          }
          if (object.specialties) {
            if (!Array.isArray(object.specialties)) {
              throw new TypeError(".scheduling.v1.Provider.specialties: array type expected, but got " + (typeof object.specialties))
            }
            message.specialties = new Array(object.specialties.length);
            for (let i = 0; i < object.specialties.length; ++i) {
              message.specialties[i] = String(object.specialties[i]);
            }
          }
          if (object.internalTags) {
            if (!Array.isArray(object.internalTags)) {
              throw new TypeError(".scheduling.v1.Provider.internalTags: array type expected, but got " + (typeof object.internalTags))
            }
            message.internalTags = new Array(object.internalTags.length);
            for (let i = 0; i < object.internalTags.length; ++i) {
              message.internalTags[i] = String(object.internalTags[i]);
            }
          }
          if (object.insuranceTypes) {
            if (!Array.isArray(object.insuranceTypes)) {
              throw new TypeError(".scheduling.v1.Provider.insuranceTypes: array type expected, but got " + (typeof object.insuranceTypes))
            }
            message.insuranceTypes = new Array(object.insuranceTypes.length);
            for (let i = 0; i < object.insuranceTypes.length; ++i) {
              if (typeof object.insuranceTypes[i] !== "object") {
                throw new TypeError(".scheduling.v1.Provider.insuranceTypes: object expected, but got " + (typeof object.insuranceTypes[i]));
              }
              message.insuranceTypes[i] = $root.scheduling.v1.InsuranceType.fromObject(object.insuranceTypes[i]);
            }
          }
          if (object.upsertTime != null) {
            if (typeof object.upsertTime !== "object") {
              throw new TypeError(".scheduling.v1.Provider.upsertTime: object expected, but got " + (typeof object.upsertTime));
            }
            message.upsertTime = timing$1.v1.Timestamp.fromObject(object.upsertTime);
          }
          if (object.enabledForScheduling != null) {
            message.enabledForScheduling = Boolean(object.enabledForScheduling);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.qualificationAbbreviations = [];
            object.licensedGeographicLocales = [];
            object.specialties = [];
            object.internalTags = [];
            object.insuranceTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.providerId = null;
            object.orderingIndex = 0;
            object.name = "";
            object.upsertTime = null;
            object.enabledForScheduling = false;
          }
          let keys;
          if (message.providerId != null && message.hasOwnProperty("providerId")) {
            object.providerId = $root.scheduling.v1.ProviderId.toObject(message.providerId, options);
          }
          if (message.orderingIndex != null && message.hasOwnProperty("orderingIndex")) {
            object.orderingIndex = message.orderingIndex;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.avatarImageUrl != null && message.hasOwnProperty("avatarImageUrl")) {
            object.avatarImageUrl = message.avatarImageUrl;
          }
          if (message.shortBio != null && message.hasOwnProperty("shortBio")) {
            object.shortBio = message.shortBio;
          }
          if (message.bio != null && message.hasOwnProperty("bio")) {
            object.bio = message.bio;
          }
          if (message.qualificationAbbreviations && message.qualificationAbbreviations.length) {
            object.qualificationAbbreviations = new Array(message.qualificationAbbreviations.length);
            for (let i = 0; i < message.qualificationAbbreviations.length; ++i) {
              object.qualificationAbbreviations[i] = message.qualificationAbbreviations[i];
            }
          }
          if (message.licensedGeographicLocales && message.licensedGeographicLocales.length) {
            object.licensedGeographicLocales = new Array(message.licensedGeographicLocales.length);
            for (let i = 0; i < message.licensedGeographicLocales.length; ++i) {
              object.licensedGeographicLocales[i] = message.licensedGeographicLocales[i];
            }
          }
          if (message.specialties && message.specialties.length) {
            object.specialties = new Array(message.specialties.length);
            for (let i = 0; i < message.specialties.length; ++i) {
              object.specialties[i] = message.specialties[i];
            }
          }
          if (message.internalTags && message.internalTags.length) {
            object.internalTags = new Array(message.internalTags.length);
            for (let i = 0; i < message.internalTags.length; ++i) {
              object.internalTags[i] = message.internalTags[i];
            }
          }
          if (message.insuranceTypes && message.insuranceTypes.length) {
            object.insuranceTypes = new Array(message.insuranceTypes.length);
            for (let i = 0; i < message.insuranceTypes.length; ++i) {
              object.insuranceTypes[i] = $root.scheduling.v1.InsuranceType.toObject(message.insuranceTypes[i], options);
            }
          }
          if (message.upsertTime != null && message.hasOwnProperty("upsertTime")) {
            object.upsertTime = timing$1.v1.Timestamp.toObject(message.upsertTime, options);
          }
          if (message.enabledForScheduling != null && message.hasOwnProperty("enabledForScheduling")) {
            object.enabledForScheduling = message.enabledForScheduling;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.Provider";
        }
      }

      Provider.prototype.providerId = null;
      Provider.prototype.orderingIndex = 0;
      Provider.prototype.name = "";
      Provider.prototype.avatarImageUrl = null;
      Provider.prototype.shortBio = null;
      Provider.prototype.bio = null;
      Provider.prototype.qualificationAbbreviations = $util.emptyArray;
      Provider.prototype.licensedGeographicLocales = $util.emptyArray;
      Provider.prototype.specialties = $util.emptyArray;
      Provider.prototype.internalTags = $util.emptyArray;
      Provider.prototype.insuranceTypes = $util.emptyArray;
      Provider.prototype.upsertTime = null;
      Provider.prototype.enabledForScheduling = false;

      return Provider;
    })();

    v1.UniqueTags = (() => {
      class UniqueTags {
        constructor(properties) {
          this.qualificationTypes = [];
          this.geographicLocales = [];
          this.specialties = [];
          this.insuranceTypes = [];
          this.internalTags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UniqueTags(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.qualificationTypes != null && Object.hasOwnProperty.call(message, "qualificationTypes")) {
            for (const element of message.qualificationTypes) {
              $root.scheduling.v1.QualificationLabel.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.geographicLocales != null && Object.hasOwnProperty.call(message, "geographicLocales")) {
            for (const element of message.geographicLocales) {
              writer.uint32(18).string(element);
            }
          }
          if (message.specialties != null && Object.hasOwnProperty.call(message, "specialties")) {
            for (const element of message.specialties) {
              writer.uint32(26).string(element);
            }
          }
          if (message.insuranceTypes != null && Object.hasOwnProperty.call(message, "insuranceTypes")) {
            for (const element of message.insuranceTypes) {
              $root.scheduling.v1.InsuranceType.encode(element, writer.uint32(34).fork()).ldelim();
            }
          }
          if (message.internalTags != null && Object.hasOwnProperty.call(message, "internalTags")) {
            for (const element of message.internalTags) {
              writer.uint32(42).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UniqueTags();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.qualificationTypes || !message.qualificationTypes.length) {
                  message.qualificationTypes = [];
                }
                message.qualificationTypes.push($root.scheduling.v1.QualificationLabel.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                if (!message.geographicLocales || !message.geographicLocales.length) {
                  message.geographicLocales = [];
                }
                message.geographicLocales.push(reader.string());
                break;
              }
              case 3: {
                if (!message.specialties || !message.specialties.length) {
                  message.specialties = [];
                }
                message.specialties.push(reader.string());
                break;
              }
              case 4: {
                if (!message.insuranceTypes || !message.insuranceTypes.length) {
                  message.insuranceTypes = [];
                }
                message.insuranceTypes.push($root.scheduling.v1.InsuranceType.decode(reader, reader.uint32()));
                break;
              }
              case 5: {
                if (!message.internalTags || !message.internalTags.length) {
                  message.internalTags = [];
                }
                message.internalTags.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.UniqueTags) {
            return object;
          }
          const message = new $root.scheduling.v1.UniqueTags();
          if (object.qualificationTypes) {
            if (!Array.isArray(object.qualificationTypes)) {
              throw new TypeError(".scheduling.v1.UniqueTags.qualificationTypes: array type expected, but got " + (typeof object.qualificationTypes))
            }
            message.qualificationTypes = new Array(object.qualificationTypes.length);
            for (let i = 0; i < object.qualificationTypes.length; ++i) {
              if (typeof object.qualificationTypes[i] !== "object") {
                throw new TypeError(".scheduling.v1.UniqueTags.qualificationTypes: object expected, but got " + (typeof object.qualificationTypes[i]));
              }
              message.qualificationTypes[i] = $root.scheduling.v1.QualificationLabel.fromObject(object.qualificationTypes[i]);
            }
          }
          if (object.geographicLocales) {
            if (!Array.isArray(object.geographicLocales)) {
              throw new TypeError(".scheduling.v1.UniqueTags.geographicLocales: array type expected, but got " + (typeof object.geographicLocales))
            }
            message.geographicLocales = new Array(object.geographicLocales.length);
            for (let i = 0; i < object.geographicLocales.length; ++i) {
              message.geographicLocales[i] = String(object.geographicLocales[i]);
            }
          }
          if (object.specialties) {
            if (!Array.isArray(object.specialties)) {
              throw new TypeError(".scheduling.v1.UniqueTags.specialties: array type expected, but got " + (typeof object.specialties))
            }
            message.specialties = new Array(object.specialties.length);
            for (let i = 0; i < object.specialties.length; ++i) {
              message.specialties[i] = String(object.specialties[i]);
            }
          }
          if (object.insuranceTypes) {
            if (!Array.isArray(object.insuranceTypes)) {
              throw new TypeError(".scheduling.v1.UniqueTags.insuranceTypes: array type expected, but got " + (typeof object.insuranceTypes))
            }
            message.insuranceTypes = new Array(object.insuranceTypes.length);
            for (let i = 0; i < object.insuranceTypes.length; ++i) {
              if (typeof object.insuranceTypes[i] !== "object") {
                throw new TypeError(".scheduling.v1.UniqueTags.insuranceTypes: object expected, but got " + (typeof object.insuranceTypes[i]));
              }
              message.insuranceTypes[i] = $root.scheduling.v1.InsuranceType.fromObject(object.insuranceTypes[i]);
            }
          }
          if (object.internalTags) {
            if (!Array.isArray(object.internalTags)) {
              throw new TypeError(".scheduling.v1.UniqueTags.internalTags: array type expected, but got " + (typeof object.internalTags))
            }
            message.internalTags = new Array(object.internalTags.length);
            for (let i = 0; i < object.internalTags.length; ++i) {
              message.internalTags[i] = String(object.internalTags[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.qualificationTypes = [];
            object.geographicLocales = [];
            object.specialties = [];
            object.insuranceTypes = [];
            object.internalTags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.qualificationTypes && message.qualificationTypes.length) {
            object.qualificationTypes = new Array(message.qualificationTypes.length);
            for (let i = 0; i < message.qualificationTypes.length; ++i) {
              object.qualificationTypes[i] = $root.scheduling.v1.QualificationLabel.toObject(message.qualificationTypes[i], options);
            }
          }
          if (message.geographicLocales && message.geographicLocales.length) {
            object.geographicLocales = new Array(message.geographicLocales.length);
            for (let i = 0; i < message.geographicLocales.length; ++i) {
              object.geographicLocales[i] = message.geographicLocales[i];
            }
          }
          if (message.specialties && message.specialties.length) {
            object.specialties = new Array(message.specialties.length);
            for (let i = 0; i < message.specialties.length; ++i) {
              object.specialties[i] = message.specialties[i];
            }
          }
          if (message.insuranceTypes && message.insuranceTypes.length) {
            object.insuranceTypes = new Array(message.insuranceTypes.length);
            for (let i = 0; i < message.insuranceTypes.length; ++i) {
              object.insuranceTypes[i] = $root.scheduling.v1.InsuranceType.toObject(message.insuranceTypes[i], options);
            }
          }
          if (message.internalTags && message.internalTags.length) {
            object.internalTags = new Array(message.internalTags.length);
            for (let i = 0; i < message.internalTags.length; ++i) {
              object.internalTags[i] = message.internalTags[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.UniqueTags";
        }
      }

      UniqueTags.prototype.qualificationTypes = $util.emptyArray;
      UniqueTags.prototype.geographicLocales = $util.emptyArray;
      UniqueTags.prototype.specialties = $util.emptyArray;
      UniqueTags.prototype.insuranceTypes = $util.emptyArray;
      UniqueTags.prototype.internalTags = $util.emptyArray;

      return UniqueTags;
    })();

    v1.Tag = (() => {
      class Tag {
        get type() {
          for (const key of ["geographicLocale", "specialty", "internalTag", "unscopedInsurancePayer", "geographicallyScopedInsurancePayer"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["geographicLocale", "specialty", "internalTag", "unscopedInsurancePayer", "geographicallyScopedInsurancePayer"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Tag(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.geographicLocale != null && Object.hasOwnProperty.call(message, "geographicLocale")) {
            writer.uint32(10).string(message.geographicLocale);
          }
          if (message.specialty != null && Object.hasOwnProperty.call(message, "specialty")) {
            writer.uint32(18).string(message.specialty);
          }
          if (message.internalTag != null && Object.hasOwnProperty.call(message, "internalTag")) {
            writer.uint32(26).string(message.internalTag);
          }
          if (message.unscopedInsurancePayer != null && Object.hasOwnProperty.call(message, "unscopedInsurancePayer")) {
            $root.scheduling.v1.InsurancePayer.encode(message.unscopedInsurancePayer, writer.uint32(34).fork()).ldelim();
          }
          if (message.geographicallyScopedInsurancePayer != null && Object.hasOwnProperty.call(message, "geographicallyScopedInsurancePayer")) {
            $root.scheduling.v1.GeographicallyScopedInsurancePayer.encode(message.geographicallyScopedInsurancePayer, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Tag();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.geographicLocale = reader.string();
                break;
              }
              case 2: {
                message.specialty = reader.string();
                break;
              }
              case 3: {
                message.internalTag = reader.string();
                break;
              }
              case 4: {
                message.unscopedInsurancePayer = $root.scheduling.v1.InsurancePayer.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.geographicallyScopedInsurancePayer = $root.scheduling.v1.GeographicallyScopedInsurancePayer.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.scheduling.v1.Tag) {
            return object;
          }
          const message = new $root.scheduling.v1.Tag();
          if (object.geographicLocale != null) {
            message.geographicLocale = String(object.geographicLocale);
          }
          if (object.specialty != null) {
            message.specialty = String(object.specialty);
          }
          if (object.internalTag != null) {
            message.internalTag = String(object.internalTag);
          }
          if (object.unscopedInsurancePayer != null) {
            if (typeof object.unscopedInsurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.Tag.unscopedInsurancePayer: object expected, but got " + (typeof object.unscopedInsurancePayer));
            }
            message.unscopedInsurancePayer = $root.scheduling.v1.InsurancePayer.fromObject(object.unscopedInsurancePayer);
          }
          if (object.geographicallyScopedInsurancePayer != null) {
            if (typeof object.geographicallyScopedInsurancePayer !== "object") {
              throw new TypeError(".scheduling.v1.Tag.geographicallyScopedInsurancePayer: object expected, but got " + (typeof object.geographicallyScopedInsurancePayer));
            }
            message.geographicallyScopedInsurancePayer = $root.scheduling.v1.GeographicallyScopedInsurancePayer.fromObject(object.geographicallyScopedInsurancePayer);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.geographicLocale != null && message.hasOwnProperty("geographicLocale")) {
            object.geographicLocale = message.geographicLocale;
            if (options.oneofs) {
              object.type = "geographicLocale";
            }
          }
          if (message.specialty != null && message.hasOwnProperty("specialty")) {
            object.specialty = message.specialty;
            if (options.oneofs) {
              object.type = "specialty";
            }
          }
          if (message.internalTag != null && message.hasOwnProperty("internalTag")) {
            object.internalTag = message.internalTag;
            if (options.oneofs) {
              object.type = "internalTag";
            }
          }
          if (message.unscopedInsurancePayer != null && message.hasOwnProperty("unscopedInsurancePayer")) {
            object.unscopedInsurancePayer = $root.scheduling.v1.InsurancePayer.toObject(message.unscopedInsurancePayer, options);
            if (options.oneofs) {
              object.type = "unscopedInsurancePayer";
            }
          }
          if (message.geographicallyScopedInsurancePayer != null && message.hasOwnProperty("geographicallyScopedInsurancePayer")) {
            object.geographicallyScopedInsurancePayer = $root.scheduling.v1.GeographicallyScopedInsurancePayer.toObject(message.geographicallyScopedInsurancePayer, options);
            if (options.oneofs) {
              object.type = "geographicallyScopedInsurancePayer";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "scheduling.v1.Tag";
        }
      }

      Tag.prototype.geographicLocale = "";
      Tag.prototype.specialty = "";
      Tag.prototype.internalTag = "";
      Tag.prototype.unscopedInsurancePayer = null;
      Tag.prototype.geographicallyScopedInsurancePayer = null;

      return Tag;
    })();

    v1.SchedulingApplication = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_SCHEDULING_APPLICATION"] = 0;
      values[valuesById[1] = "HEALTHIE"] = 1;
      values[valuesById[2] = "ACUITY"] = 2;
      return values;
    })();

    v1.ProviderQualification = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_PROVIDER_QUALIFICATION"] = 0;
      values[valuesById[1] = "AAP"] = 1;
      values[valuesById[2] = "ABAI"] = 2;
      values[valuesById[3] = "ABFP"] = 3;
      values[valuesById[4] = "ABO"] = 4;
      values[valuesById[5] = "ABPN"] = 5;
      values[valuesById[6] = "AK"] = 6;
      values[valuesById[7] = "AOBFP"] = 7;
      values[valuesById[8] = "AOBSPOMM"] = 8;
      values[valuesById[9] = "AP"] = 9;
      values[valuesById[10] = "ASG"] = 10;
      values[valuesById[11] = "BHMS"] = 11;
      values[valuesById[12] = "BSN"] = 12;
      values[valuesById[13] = "BVSCAH"] = 13;
      values[valuesById[14] = "CA"] = 14;
      values[valuesById[15] = "CAAPM"] = 15;
      values[valuesById[16] = "CAC"] = 16;
      values[valuesById[17] = "CCH"] = 17;
      values[valuesById[18] = "CD"] = 18;
      values[valuesById[19] = "CEDS"] = 19;
      values[valuesById[20] = "CDCED"] = 20;
      values[valuesById[21] = "CDCES"] = 21;
      values[valuesById[22] = "CDN"] = 22;
      values[valuesById[23] = "CCSP"] = 23;
      values[valuesById[24] = "CIEC"] = 24;
      values[valuesById[25] = "CNS"] = 25;
      values[valuesById[26] = "CNSC"] = 26;
      values[valuesById[27] = "CRNP"] = 27;
      values[valuesById[28] = "CRRN"] = 28;
      values[valuesById[29] = "CSPOMM"] = 29;
      values[valuesById[30] = "CSPCC"] = 30;
      values[valuesById[31] = "CPT"] = 31;
      values[valuesById[32] = "CSP"] = 32;
      values[valuesById[33] = "CVA"] = 33;
      values[valuesById[34] = "DAAPM"] = 34;
      values[valuesById[35] = "DABFP"] = 35;
      values[valuesById[36] = "DABIM"] = 36;
      values[valuesById[37] = "DAC"] = 37;
      values[valuesById[38] = "DACRI"] = 38;
      values[valuesById[39] = "DACWV"] = 39;
      values[valuesById[40] = "DACBN"] = 40;
      values[valuesById[41] = "DACVD"] = 41;
      values[valuesById[42] = "DC"] = 42;
      values[valuesById[43] = "DDS"] = 43;
      values[valuesById[44] = "DHANP"] = 44;
      values[valuesById[45] = "DHT"] = 45;
      values[valuesById[46] = "DMD"] = 46;
      values[valuesById[47] = "DNBHE"] = 47;
      values[valuesById[48] = "DO"] = 48;
      values[valuesById[49] = "DOM"] = 49;
      values[valuesById[50] = "DPM"] = 50;
      values[valuesById[51] = "DVM"] = 51;
      values[valuesById[52] = "FAAEM"] = 52;
      values[valuesById[53] = "FAAFP"] = 53;
      values[valuesById[54] = "FAAP"] = 54;
      values[valuesById[55] = "FACFO"] = 55;
      values[valuesById[56] = "FACOG"] = 56;
      values[valuesById[57] = "FAGD"] = 57;
      values[valuesById[58] = "FIACA"] = 58;
      values[valuesById[59] = "FIAOMT"] = 59;
      values[valuesById[60] = "FICCMO"] = 60;
      values[valuesById[61] = "FNP"] = 61;
      values[valuesById[62] = "HASG"] = 62;
      values[valuesById[63] = "HLL"] = 63;
      values[valuesById[64] = "HMD"] = 64;
      values[valuesById[65] = "HSG"] = 65;
      values[valuesById[66] = "LAC"] = 66;
      values[valuesById[67] = "LDN"] = 67;
      values[valuesById[68] = "LD"] = 68;
      values[valuesById[69] = "LICAC"] = 69;
      values[valuesById[70] = "LL"] = 70;
      values[valuesById[71] = "LMHC"] = 71;
      values[valuesById[72] = "LMFT"] = 72;
      values[valuesById[73] = "LMFTA"] = 73;
      values[valuesById[74] = "LMSW"] = 74;
      values[valuesById[75] = "LN"] = 75;
      values[valuesById[76] = "LNC"] = 76;
      values[valuesById[77] = "LMNT"] = 77;
      values[valuesById[78] = "LPC"] = 78;
      values[valuesById[79] = "LPCA"] = 79;
      values[valuesById[80] = "LCSW"] = 80;
      values[valuesById[81] = "MBA"] = 81;
      values[valuesById[82] = "M"] = 82;
      values[valuesById[83] = "MD"] = 83;
      values[valuesById[84] = "MDH"] = 84;
      values[valuesById[85] = "MFCC"] = 85;
      values[valuesById[86] = "MNNP"] = 86;
      values[valuesById[87] = "MPH"] = 87;
      values[valuesById[88] = "MS"] = 88;
      values[valuesById[89] = "MSN"] = 89;
      values[valuesById[90] = "MSW"] = 90;
      values[valuesById[91] = "NCCA"] = 91;
      values[valuesById[92] = "ND"] = 92;
      values[valuesById[93] = "NMD"] = 93;
      values[valuesById[94] = "NP"] = 94;
      values[valuesById[95] = "OD"] = 95;
      values[valuesById[96] = "OMD"] = 96;
      values[valuesById[97] = "OTHER"] = 97;
      values[valuesById[98] = "PA"] = 98;
      values[valuesById[99] = "PAC"] = 99;
      values[valuesById[100] = "PHD"] = 100;
      values[valuesById[101] = "PSYD"] = 101;
      values[valuesById[102] = "PT"] = 102;
      values[valuesById[103] = "RCSWI"] = 103;
      values[valuesById[104] = "RD"] = 104;
      values[valuesById[107] = "RDN"] = 107;
      values[valuesById[108] = "RMHCI"] = 108;
      values[valuesById[109] = "RN"] = 109;
      values[valuesById[110] = "RNC"] = 110;
      values[valuesById[111] = "RNCS"] = 111;
      values[valuesById[112] = "RNNP"] = 112;
      values[valuesById[113] = "RPH"] = 113;
      values[valuesById[114] = "RS"] = 114;
      values[valuesById[115] = "SG"] = 115;
      values[valuesById[116] = "VMD"] = 116;
      return values;
    })();

    v1.SchedulingService = (() => {
      class SchedulingService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new SchedulingService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(SchedulingService.prototype.listUniqueTags = function listUniqueTags(request, callback) {
        return this.rpcCall(listUniqueTags, $root.scheduling.v1.ListUniqueTagsRequest, $root.scheduling.v1.UniqueTags, request, callback);
      }, "name", { value: "ListUniqueTags" });

      Object.defineProperty(SchedulingService.prototype.upsertProvider = function upsertProvider(request, callback) {
        return this.rpcCall(upsertProvider, $root.scheduling.v1.Provider, $root.scheduling.v1.Provider, request, callback);
      }, "name", { value: "UpsertProvider" });

      Object.defineProperty(SchedulingService.prototype.getProvider = function getProvider(request, callback) {
        return this.rpcCall(getProvider, $root.scheduling.v1.ProviderId, $root.scheduling.v1.Provider, request, callback);
      }, "name", { value: "GetProvider" });

      Object.defineProperty(SchedulingService.prototype.deleteProvider = function deleteProvider(request, callback) {
        return this.rpcCall(deleteProvider, $root.scheduling.v1.ProviderId, $root.scheduling.v1.DeleteProviderResponse, request, callback);
      }, "name", { value: "DeleteProvider" });

      Object.defineProperty(SchedulingService.prototype.listProviders = function listProviders(request, callback) {
        return this.rpcCall(listProviders, $root.scheduling.v1.ListProvidersRequest, $root.scheduling.v1.ListProvidersResponse, request, callback);
      }, "name", { value: "ListProviders" });

      Object.defineProperty(SchedulingService.prototype.filterProviders = function filterProviders(request, callback) {
        return this.rpcCall(filterProviders, $root.scheduling.v1.FilterProvidersRequest, $root.scheduling.v1.ListProvidersResponse, request, callback);
      }, "name", { value: "FilterProviders" });

      Object.defineProperty(SchedulingService.prototype.searchName = function searchName(request, callback) {
        return this.rpcCall(searchName, $root.scheduling.v1.SearchNameRequest, $root.scheduling.v1.ListProvidersResponse, request, callback);
      }, "name", { value: "SearchName" });

      Object.defineProperty(SchedulingService.prototype.getMaximumOrderingIndex = function getMaximumOrderingIndex(request, callback) {
        return this.rpcCall(getMaximumOrderingIndex, $root.scheduling.v1.GetMaximumOrderingIndexRequest, $root.scheduling.v1.GetMaximumOrderingIndexResponse, request, callback);
      }, "name", { value: "GetMaximumOrderingIndex" });

      Object.defineProperty(SchedulingService.prototype.filterProvidersForAvailability = function filterProvidersForAvailability(request, callback) {
        return this.rpcCall(filterProvidersForAvailability, $root.scheduling.v1.FilterProvidersForAvailabilityRequest, $root.scheduling.v1.FilterProvidersForAvailabilityResponse, request, callback);
      }, "name", { value: "FilterProvidersForAvailability" });

      Object.defineProperty(SchedulingService.prototype.listAvailabilitySlots = function listAvailabilitySlots(request, callback) {
        return this.rpcCall(listAvailabilitySlots, $root.scheduling.v1.ListAvailabilitySlotsRequest, $root.scheduling.v1.ListAvailabilitySlotsResponse, request, callback);
      }, "name", { value: "ListAvailabilitySlots" });

      Object.defineProperty(SchedulingService.prototype.getAppointmentTypeInfo = function getAppointmentTypeInfo(request, callback) {
        return this.rpcCall(getAppointmentTypeInfo, $root.scheduling.v1.GetAppointmentTypeInfoRequest, $root.scheduling.v1.AppointmentTypeInfo, request, callback);
      }, "name", { value: "GetAppointmentTypeInfo" });

      Object.defineProperty(SchedulingService.prototype.scheduleAppointment = function scheduleAppointment(request, callback) {
        return this.rpcCall(scheduleAppointment, $root.scheduling.v1.ScheduleAppointmentRequest, $root.scheduling.v1.ScheduleAppointmentResponse, request, callback);
      }, "name", { value: "ScheduleAppointment" });

      Object.defineProperty(SchedulingService.prototype.getAppointment = function getAppointment(request, callback) {
        return this.rpcCall(getAppointment, $root.scheduling.v1.GetAppointmentRequest, $root.scheduling.v1.GetAppointmentResponse, request, callback);
      }, "name", { value: "GetAppointment" });

      Object.defineProperty(SchedulingService.prototype.cancelAppointment = function cancelAppointment(request, callback) {
        return this.rpcCall(cancelAppointment, $root.scheduling.v1.CancelAppointmentRequest, $root.scheduling.v1.CancelAppointmentResponse, request, callback);
      }, "name", { value: "CancelAppointment" });

      Object.defineProperty(SchedulingService.prototype.rescheduleAppointment = function rescheduleAppointment(request, callback) {
        return this.rpcCall(rescheduleAppointment, $root.scheduling.v1.RescheduleAppointmentRequest, $root.scheduling.v1.RescheduleAppointmentResponse, request, callback);
      }, "name", { value: "RescheduleAppointment" });

      return SchedulingService;
    })();

    return v1;
  })();

  return scheduling;
})();

export const scheduling = $root.scheduling;
