"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "./empty_ts_proto";
import { phonenumbers as phonenumbers$1 } from "./phonenumbers_ts_proto";
import { profile_properties as profile_properties$1 } from "./profile_properties_v1_ts_proto";
import { google as google$2 } from "./struct_ts_proto";
import { thirdparties as thirdparties$1 } from "./thirdparties_v1_ts_proto";
import { timing as timing$1 } from "./timing_v1_ts_proto";
import { values as values$1 } from "./values_v1_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "./workflow_parameters_v1_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.profiles = (() => {
  const profiles = {};
  profiles.v1 = (() => {
    const v1 = {};
    v1.GetRequest = (() => {
      class GetRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            $root.profiles.v1.ProfileIndex.encode(message.index, writer.uint32(10).fork()).ldelim();
          }
          if (message.includeDemographicPropertyValues != null && Object.hasOwnProperty.call(message, "includeDemographicPropertyValues")) {
            writer.uint32(16).bool(message.includeDemographicPropertyValues);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.index = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.includeDemographicPropertyValues = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.GetRequest) {
            return object;
          }
          const message = new $root.profiles.v1.GetRequest();
          if (object.index != null) {
            if (typeof object.index !== "object") {
              throw new TypeError(".profiles.v1.GetRequest.index: object expected, but got " + (typeof object.index));
            }
            message.index = $root.profiles.v1.ProfileIndex.fromObject(object.index);
          }
          if (object.includeDemographicPropertyValues != null) {
            message.includeDemographicPropertyValues = Boolean(object.includeDemographicPropertyValues);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.index = null;
            object.includeDemographicPropertyValues = false;
          }
          let keys;
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = $root.profiles.v1.ProfileIndex.toObject(message.index, options);
          }
          if (message.includeDemographicPropertyValues != null && message.hasOwnProperty("includeDemographicPropertyValues")) {
            object.includeDemographicPropertyValues = message.includeDemographicPropertyValues;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.GetRequest";
        }
      }

      GetRequest.prototype.index = null;
      GetRequest.prototype.includeDemographicPropertyValues = false;

      return GetRequest;
    })();

    v1.DemographicPropertyValues = (() => {
      class DemographicPropertyValues {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DemographicPropertyValues(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(10).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(18).string(message.lastName);
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(26).string(message.emailAddress);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            phonenumbers$1.PhoneNumber.encode(message.phoneNumber, writer.uint32(34).fork()).ldelim();
          }
          if (message.usMailingAddress != null && Object.hasOwnProperty.call(message, "usMailingAddress")) {
            profile_properties$1.v1.UsAddress.encode(message.usMailingAddress, writer.uint32(42).fork()).ldelim();
          }
          if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
            values$1.v1.Date.encode(message.dateOfBirth, writer.uint32(50).fork()).ldelim();
          }
          if (message.sexAssignedAtBirth != null && Object.hasOwnProperty.call(message, "sexAssignedAtBirth")) {
            writer.uint32(56).int32(message.sexAssignedAtBirth);
          }
          if (message.genderIdentity != null && Object.hasOwnProperty.call(message, "genderIdentity")) {
            writer.uint32(66).string(message.genderIdentity);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DemographicPropertyValues();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.firstName = reader.string();
                break;
              }
              case 2: {
                message.lastName = reader.string();
                break;
              }
              case 3: {
                message.emailAddress = reader.string();
                break;
              }
              case 4: {
                message.phoneNumber = phonenumbers$1.PhoneNumber.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.usMailingAddress = profile_properties$1.v1.UsAddress.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.dateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.sexAssignedAtBirth = reader.int32();
                break;
              }
              case 8: {
                message.genderIdentity = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.DemographicPropertyValues) {
            return object;
          }
          const message = new $root.profiles.v1.DemographicPropertyValues();
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            if (typeof object.phoneNumber !== "object") {
              throw new TypeError(".profiles.v1.DemographicPropertyValues.phoneNumber: object expected, but got " + (typeof object.phoneNumber));
            }
            message.phoneNumber = phonenumbers$1.PhoneNumber.fromObject(object.phoneNumber);
          }
          if (object.usMailingAddress != null) {
            if (typeof object.usMailingAddress !== "object") {
              throw new TypeError(".profiles.v1.DemographicPropertyValues.usMailingAddress: object expected, but got " + (typeof object.usMailingAddress));
            }
            message.usMailingAddress = profile_properties$1.v1.UsAddress.fromObject(object.usMailingAddress);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== "object") {
              throw new TypeError(".profiles.v1.DemographicPropertyValues.dateOfBirth: object expected, but got " + (typeof object.dateOfBirth));
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(object.dateOfBirth);
          }
          if (object.sexAssignedAtBirth != null) {
            switch (object.sexAssignedAtBirth) {
              case "UNSPECIFIED_SEX_ASSIGNED_AT_BIRTH":
              case 0: {
                message.sexAssignedAtBirth = 0;
                break;
              }
              case "FEMALE":
              case 1: {
                message.sexAssignedAtBirth = 1;
                break;
              }
              case "MALE":
              case 2: {
                message.sexAssignedAtBirth = 2;
                break;
              }
              case "INTERSEX":
              case 3: {
                message.sexAssignedAtBirth = 3;
                break;
              }
              case "OTHER":
              case 4: {
                message.sexAssignedAtBirth = 4;
                break;
              }
              default: {
                if (typeof object.sexAssignedAtBirth == "number") {
                  message.sexAssignedAtBirth = object.sexAssignedAtBirth;
                  break;
                }
                break;
              }
            }
          }
          if (object.genderIdentity != null) {
            message.genderIdentity = String(object.genderIdentity);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.usMailingAddress = null;
          }
          let keys;
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = phonenumbers$1.PhoneNumber.toObject(message.phoneNumber, options);
          }
          if (message.usMailingAddress != null && message.hasOwnProperty("usMailingAddress")) {
            object.usMailingAddress = profile_properties$1.v1.UsAddress.toObject(message.usMailingAddress, options);
          }
          if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
            object.dateOfBirth = values$1.v1.Date.toObject(message.dateOfBirth, options);
          }
          if (message.sexAssignedAtBirth != null && message.hasOwnProperty("sexAssignedAtBirth")) {
            object.sexAssignedAtBirth = options.enums === String ? profile_properties$1.v1.SexAssignedAtBirth[message.sexAssignedAtBirth] === undefined ? message.sexAssignedAtBirth : profile_properties$1.v1.SexAssignedAtBirth[message.sexAssignedAtBirth] : message.sexAssignedAtBirth;
          }
          if (message.genderIdentity != null && message.hasOwnProperty("genderIdentity")) {
            object.genderIdentity = message.genderIdentity;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.DemographicPropertyValues";
        }
      }

      DemographicPropertyValues.prototype.firstName = null;
      DemographicPropertyValues.prototype.lastName = null;
      DemographicPropertyValues.prototype.emailAddress = null;
      DemographicPropertyValues.prototype.phoneNumber = null;
      DemographicPropertyValues.prototype.usMailingAddress = null;
      DemographicPropertyValues.prototype.dateOfBirth = null;
      DemographicPropertyValues.prototype.sexAssignedAtBirth = null;
      DemographicPropertyValues.prototype.genderIdentity = null;

      return DemographicPropertyValues;
    })();

    v1.GetResponse = (() => {
      class GetResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.Profile.encode(message.profile, writer.uint32(10).fork()).ldelim();
          }
          if (message.demographicPropertyValues != null && Object.hasOwnProperty.call(message, "demographicPropertyValues")) {
            $root.profiles.v1.DemographicPropertyValues.encode(message.demographicPropertyValues, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.demographicPropertyValues = $root.profiles.v1.DemographicPropertyValues.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.GetResponse) {
            return object;
          }
          const message = new $root.profiles.v1.GetResponse();
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.GetResponse.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
          }
          if (object.demographicPropertyValues != null) {
            if (typeof object.demographicPropertyValues !== "object") {
              throw new TypeError(".profiles.v1.GetResponse.demographicPropertyValues: object expected, but got " + (typeof object.demographicPropertyValues));
            }
            message.demographicPropertyValues = $root.profiles.v1.DemographicPropertyValues.fromObject(object.demographicPropertyValues);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profile = null;
          }
          let keys;
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
          }
          if (message.demographicPropertyValues != null && message.hasOwnProperty("demographicPropertyValues")) {
            object.demographicPropertyValues = $root.profiles.v1.DemographicPropertyValues.toObject(message.demographicPropertyValues, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.GetResponse";
        }
      }

      GetResponse.prototype.profile = null;
      GetResponse.prototype.demographicPropertyValues = null;

      return GetResponse;
    })();

    v1.CreateRequest = (() => {
      class CreateRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.email != null && Object.hasOwnProperty.call(message, "email")) {
            writer.uint32(10).string(message.email);
          }
          if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
            writer.uint32(18).string(message.fullName);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            writer.uint32(34).string(message.phoneNumber);
          }
          if (message.thirdPartyIds != null && Object.hasOwnProperty.call(message, "thirdPartyIds")) {
            $root.profiles.v1.ThirdPartyIds.encode(message.thirdPartyIds, writer.uint32(42).fork()).ldelim();
          }
          if (message.isPatient != null && Object.hasOwnProperty.call(message, "isPatient")) {
            writer.uint32(48).bool(message.isPatient);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.email = reader.string();
                break;
              }
              case 2: {
                message.fullName = reader.string();
                break;
              }
              case 4: {
                message.phoneNumber = reader.string();
                break;
              }
              case 5: {
                message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.isPatient = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.CreateRequest) {
            return object;
          }
          const message = new $root.profiles.v1.CreateRequest();
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.fullName != null) {
            message.fullName = String(object.fullName);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== "object") {
              throw new TypeError(".profiles.v1.CreateRequest.thirdPartyIds: object expected, but got " + (typeof object.thirdPartyIds));
            }
            message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.fromObject(object.thirdPartyIds);
          }
          if (object.isPatient != null) {
            message.isPatient = Boolean(object.isPatient);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartyIds = null;
            object.isPatient = false;
          }
          let keys;
          if (message.email != null && message.hasOwnProperty("email")) {
            object.email = message.email;
          }
          if (message.fullName != null && message.hasOwnProperty("fullName")) {
            object.fullName = message.fullName;
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = message.phoneNumber;
          }
          if (message.thirdPartyIds != null && message.hasOwnProperty("thirdPartyIds")) {
            object.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.toObject(message.thirdPartyIds, options);
          }
          if (message.isPatient != null && message.hasOwnProperty("isPatient")) {
            object.isPatient = message.isPatient;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.CreateRequest";
        }
      }

      CreateRequest.prototype.email = null;
      CreateRequest.prototype.fullName = null;
      CreateRequest.prototype.phoneNumber = null;
      CreateRequest.prototype.thirdPartyIds = null;
      CreateRequest.prototype.isPatient = false;

      return CreateRequest;
    })();

    v1.CreateResponse = (() => {
      class CreateResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.Profile.encode(message.profile, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.CreateResponse) {
            return object;
          }
          const message = new $root.profiles.v1.CreateResponse();
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.CreateResponse.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profile = null;
          }
          let keys;
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.CreateResponse";
        }
      }

      CreateResponse.prototype.profile = null;

      return CreateResponse;
    })();

    v1.MergeRequest = (() => {
      class MergeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MergeRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileToKeep != null && Object.hasOwnProperty.call(message, "profileToKeep")) {
            $root.profiles.v1.ProfileIndex.encode(message.profileToKeep, writer.uint32(10).fork()).ldelim();
          }
          if (message.profileToMerge != null && Object.hasOwnProperty.call(message, "profileToMerge")) {
            $root.profiles.v1.ProfileIndex.encode(message.profileToMerge, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MergeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileToKeep = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.profileToMerge = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.MergeRequest) {
            return object;
          }
          const message = new $root.profiles.v1.MergeRequest();
          if (object.profileToKeep != null) {
            if (typeof object.profileToKeep !== "object") {
              throw new TypeError(".profiles.v1.MergeRequest.profileToKeep: object expected, but got " + (typeof object.profileToKeep));
            }
            message.profileToKeep = $root.profiles.v1.ProfileIndex.fromObject(object.profileToKeep);
          }
          if (object.profileToMerge != null) {
            if (typeof object.profileToMerge !== "object") {
              throw new TypeError(".profiles.v1.MergeRequest.profileToMerge: object expected, but got " + (typeof object.profileToMerge));
            }
            message.profileToMerge = $root.profiles.v1.ProfileIndex.fromObject(object.profileToMerge);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profileToKeep = null;
            object.profileToMerge = null;
          }
          let keys;
          if (message.profileToKeep != null && message.hasOwnProperty("profileToKeep")) {
            object.profileToKeep = $root.profiles.v1.ProfileIndex.toObject(message.profileToKeep, options);
          }
          if (message.profileToMerge != null && message.hasOwnProperty("profileToMerge")) {
            object.profileToMerge = $root.profiles.v1.ProfileIndex.toObject(message.profileToMerge, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.MergeRequest";
        }
      }

      MergeRequest.prototype.profileToKeep = null;
      MergeRequest.prototype.profileToMerge = null;

      return MergeRequest;
    })();

    v1.MergeResponse = (() => {
      class MergeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MergeResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.Profile.encode(message.profile, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MergeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.MergeResponse) {
            return object;
          }
          const message = new $root.profiles.v1.MergeResponse();
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.MergeResponse.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profile = null;
          }
          let keys;
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.MergeResponse";
        }
      }

      MergeResponse.prototype.profile = null;

      return MergeResponse;
    })();

    v1.AssociateThirdPartyIdsRequest = (() => {
      class AssociateThirdPartyIdsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AssociateThirdPartyIdsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.ProfileIndex.encode(message.profile, writer.uint32(10).fork()).ldelim();
          }
          if (message.thirdPartyIds != null && Object.hasOwnProperty.call(message, "thirdPartyIds")) {
            $root.profiles.v1.ThirdPartyIds.encode(message.thirdPartyIds, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AssociateThirdPartyIdsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profile = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.AssociateThirdPartyIdsRequest) {
            return object;
          }
          const message = new $root.profiles.v1.AssociateThirdPartyIdsRequest();
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.AssociateThirdPartyIdsRequest.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.ProfileIndex.fromObject(object.profile);
          }
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== "object") {
              throw new TypeError(".profiles.v1.AssociateThirdPartyIdsRequest.thirdPartyIds: object expected, but got " + (typeof object.thirdPartyIds));
            }
            message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.fromObject(object.thirdPartyIds);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profile = null;
            object.thirdPartyIds = null;
          }
          let keys;
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.ProfileIndex.toObject(message.profile, options);
          }
          if (message.thirdPartyIds != null && message.hasOwnProperty("thirdPartyIds")) {
            object.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.toObject(message.thirdPartyIds, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.AssociateThirdPartyIdsRequest";
        }
      }

      AssociateThirdPartyIdsRequest.prototype.profile = null;
      AssociateThirdPartyIdsRequest.prototype.thirdPartyIds = null;

      return AssociateThirdPartyIdsRequest;
    })();

    v1.AssociateThirdPartyIdsResponse = (() => {
      class AssociateThirdPartyIdsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AssociateThirdPartyIdsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.Profile.encode(message.profile, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AssociateThirdPartyIdsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.AssociateThirdPartyIdsResponse) {
            return object;
          }
          const message = new $root.profiles.v1.AssociateThirdPartyIdsResponse();
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.AssociateThirdPartyIdsResponse.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profile = null;
          }
          let keys;
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.AssociateThirdPartyIdsResponse";
        }
      }

      AssociateThirdPartyIdsResponse.prototype.profile = null;

      return AssociateThirdPartyIdsResponse;
    })();

    v1.ListPropertySchemasRequest = (() => {
      class ListPropertySchemasRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertySchemasRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.includeDefaultTypes != null && Object.hasOwnProperty.call(message, "includeDefaultTypes")) {
            writer.uint32(8).bool(message.includeDefaultTypes);
          }
          if (message.includeCustomTypes != null && Object.hasOwnProperty.call(message, "includeCustomTypes")) {
            writer.uint32(16).bool(message.includeCustomTypes);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertySchemasRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.includeDefaultTypes = reader.bool();
                break;
              }
              case 2: {
                message.includeCustomTypes = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ListPropertySchemasRequest) {
            return object;
          }
          const message = new $root.profiles.v1.ListPropertySchemasRequest();
          if (object.includeDefaultTypes != null) {
            message.includeDefaultTypes = Boolean(object.includeDefaultTypes);
          }
          if (object.includeCustomTypes != null) {
            message.includeCustomTypes = Boolean(object.includeCustomTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.includeDefaultTypes = false;
            object.includeCustomTypes = false;
          }
          let keys;
          if (message.includeDefaultTypes != null && message.hasOwnProperty("includeDefaultTypes")) {
            object.includeDefaultTypes = message.includeDefaultTypes;
          }
          if (message.includeCustomTypes != null && message.hasOwnProperty("includeCustomTypes")) {
            object.includeCustomTypes = message.includeCustomTypes;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ListPropertySchemasRequest";
        }
      }

      ListPropertySchemasRequest.prototype.includeDefaultTypes = false;
      ListPropertySchemasRequest.prototype.includeCustomTypes = false;

      return ListPropertySchemasRequest;
    })();

    v1.ListPropertySchemasResponse = (() => {
      class ListPropertySchemasResponse {
        constructor(properties) {
          this.defaultPropertySchemas = {};
          this.customPropertySchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertySchemasResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.defaultPropertySchemas != null && Object.hasOwnProperty.call(message, "defaultPropertySchemas")) {
            for (const key of Object.keys(message.defaultPropertySchemas)) {
              writer.uint32(10).fork().uint32(10).string(key);
              profile_properties$1.v1.PropertySchema.encode(message.defaultPropertySchemas[key], writer.uint32(18).fork()).ldelim().ldelim();
            }
          }
          if (message.customPropertySchemas != null && Object.hasOwnProperty.call(message, "customPropertySchemas")) {
            for (const element of message.customPropertySchemas) {
              profile_properties$1.v1.PropertySchema.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertySchemasResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.defaultPropertySchemas === $util.emptyObject) message.defaultPropertySchemas = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = profile_properties$1.v1.PropertySchema.decode(reader, reader.uint32());
                      break;
                    }
                  }
                  message.defaultPropertySchemas[key] = value;
                }
                break;
              }
              case 2: {
                if (!message.customPropertySchemas || !message.customPropertySchemas.length) {
                  message.customPropertySchemas = [];
                }
                message.customPropertySchemas.push(profile_properties$1.v1.PropertySchema.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ListPropertySchemasResponse) {
            return object;
          }
          const message = new $root.profiles.v1.ListPropertySchemasResponse();
          if (object.defaultPropertySchemas) {
            if (typeof object.defaultPropertySchemas !== "object") {
              throw new TypeError(".profiles.v1.ListPropertySchemasResponse.defaultPropertySchemas: object expected, but got " + (typeof object.defaultPropertySchemas));
            }
            message.defaultPropertySchemas = {};
            for (let keys = Object.keys(object.defaultPropertySchemas), i = 0; i < keys.length; ++i) {
              if (typeof object.defaultPropertySchemas[keys[i]] !== "object") {
                throw new TypeError(".profiles.v1.ListPropertySchemasResponse.value: object expected, but got " + (typeof object.defaultPropertySchemas[keys[i]]));
              }
              message.defaultPropertySchemas[keys[i]] = profile_properties$1.v1.PropertySchema.fromObject(object.defaultPropertySchemas[keys[i]]);
            }
          }
          if (object.customPropertySchemas) {
            if (!Array.isArray(object.customPropertySchemas)) {
              throw new TypeError(".profiles.v1.ListPropertySchemasResponse.customPropertySchemas: array type expected, but got " + (typeof object.customPropertySchemas))
            }
            message.customPropertySchemas = new Array(object.customPropertySchemas.length);
            for (let i = 0; i < object.customPropertySchemas.length; ++i) {
              if (typeof object.customPropertySchemas[i] !== "object") {
                throw new TypeError(".profiles.v1.ListPropertySchemasResponse.customPropertySchemas: object expected, but got " + (typeof object.customPropertySchemas[i]));
              }
              message.customPropertySchemas[i] = profile_properties$1.v1.PropertySchema.fromObject(object.customPropertySchemas[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.customPropertySchemas = [];
          }
          if (options.objects || options.defaults) {
            object.defaultPropertySchemas = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.defaultPropertySchemas && (keys = Object.keys(message.defaultPropertySchemas)).length) {
            object.defaultPropertySchemas = {};
            for (let i = 0; i < keys.length; ++i) {
              object.defaultPropertySchemas[keys[i]] = profile_properties$1.v1.PropertySchema.toObject(message.defaultPropertySchemas[keys[i]], options);
            }
          }
          if (message.customPropertySchemas && message.customPropertySchemas.length) {
            object.customPropertySchemas = new Array(message.customPropertySchemas.length);
            for (let i = 0; i < message.customPropertySchemas.length; ++i) {
              object.customPropertySchemas[i] = profile_properties$1.v1.PropertySchema.toObject(message.customPropertySchemas[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ListPropertySchemasResponse";
        }
      }

      ListPropertySchemasResponse.prototype.defaultPropertySchemas = $util.emptyObject;
      ListPropertySchemasResponse.prototype.customPropertySchemas = $util.emptyArray;

      return ListPropertySchemasResponse;
    })();

    v1.ListPropertyValuesRequest = (() => {
      class ListPropertyValuesRequest {
        get lookup() {
          for (const key of ["index", "profile"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set lookup(name) {
          for (const key of ["index", "profile"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertyValuesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            $root.profiles.v1.ProfileIndex.encode(message.index, writer.uint32(10).fork()).ldelim();
          }
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.ThirdPartyIds.encode(message.profile, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertyValuesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.index = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.profile = $root.profiles.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ListPropertyValuesRequest) {
            return object;
          }
          const message = new $root.profiles.v1.ListPropertyValuesRequest();
          if (object.index != null) {
            if (typeof object.index !== "object") {
              throw new TypeError(".profiles.v1.ListPropertyValuesRequest.index: object expected, but got " + (typeof object.index));
            }
            message.index = $root.profiles.v1.ProfileIndex.fromObject(object.index);
          }
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.ListPropertyValuesRequest.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.ThirdPartyIds.fromObject(object.profile);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = $root.profiles.v1.ProfileIndex.toObject(message.index, options);
            if (options.oneofs) {
              object.lookup = "index";
            }
          }
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.ThirdPartyIds.toObject(message.profile, options);
            if (options.oneofs) {
              object.lookup = "profile";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ListPropertyValuesRequest";
        }
      }

      ListPropertyValuesRequest.prototype.index = null;
      ListPropertyValuesRequest.prototype.profile = null;

      return ListPropertyValuesRequest;
    })();

    v1.ListPropertyValuesResponse = (() => {
      class ListPropertyValuesResponse {
        constructor(properties) {
          this.propertyValues = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertyValuesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.propertyValues != null && Object.hasOwnProperty.call(message, "propertyValues")) {
            for (const key of Object.keys(message.propertyValues)) {
              writer.uint32(10).fork().uint32(10).string(key);
              profile_properties$1.v1.PropertyValue.encode(message.propertyValues[key], writer.uint32(18).fork()).ldelim().ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertyValuesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.propertyValues === $util.emptyObject) message.propertyValues = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = profile_properties$1.v1.PropertyValue.decode(reader, reader.uint32());
                      break;
                    }
                  }
                  message.propertyValues[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ListPropertyValuesResponse) {
            return object;
          }
          const message = new $root.profiles.v1.ListPropertyValuesResponse();
          if (object.propertyValues) {
            if (typeof object.propertyValues !== "object") {
              throw new TypeError(".profiles.v1.ListPropertyValuesResponse.propertyValues: object expected, but got " + (typeof object.propertyValues));
            }
            message.propertyValues = {};
            for (let keys = Object.keys(object.propertyValues), i = 0; i < keys.length; ++i) {
              if (typeof object.propertyValues[keys[i]] !== "object") {
                throw new TypeError(".profiles.v1.ListPropertyValuesResponse.value: object expected, but got " + (typeof object.propertyValues[keys[i]]));
              }
              message.propertyValues[keys[i]] = profile_properties$1.v1.PropertyValue.fromObject(object.propertyValues[keys[i]]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.propertyValues = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.propertyValues && (keys = Object.keys(message.propertyValues)).length) {
            object.propertyValues = {};
            for (let i = 0; i < keys.length; ++i) {
              object.propertyValues[keys[i]] = profile_properties$1.v1.PropertyValue.toObject(message.propertyValues[keys[i]], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ListPropertyValuesResponse";
        }
      }

      ListPropertyValuesResponse.prototype.propertyValues = $util.emptyObject;

      return ListPropertyValuesResponse;
    })();

    v1.GetProfilePropertiesRequest = (() => {
      class GetProfilePropertiesRequest {
        constructor(properties) {
          this.property = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetProfilePropertiesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            $root.profiles.v1.ProfileIndex.encode(message.index, writer.uint32(10).fork()).ldelim();
          }
          if (message.property != null && Object.hasOwnProperty.call(message, "property")) {
            for (const element of message.property) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetProfilePropertiesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.index = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                if (!message.property || !message.property.length) {
                  message.property = [];
                }
                message.property.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.GetProfilePropertiesRequest) {
            return object;
          }
          const message = new $root.profiles.v1.GetProfilePropertiesRequest();
          if (object.index != null) {
            if (typeof object.index !== "object") {
              throw new TypeError(".profiles.v1.GetProfilePropertiesRequest.index: object expected, but got " + (typeof object.index));
            }
            message.index = $root.profiles.v1.ProfileIndex.fromObject(object.index);
          }
          if (object.property) {
            if (!Array.isArray(object.property)) {
              throw new TypeError(".profiles.v1.GetProfilePropertiesRequest.property: array type expected, but got " + (typeof object.property))
            }
            message.property = new Array(object.property.length);
            for (let i = 0; i < object.property.length; ++i) {
              message.property[i] = String(object.property[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.property = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.index = null;
          }
          let keys;
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = $root.profiles.v1.ProfileIndex.toObject(message.index, options);
          }
          if (message.property && message.property.length) {
            object.property = new Array(message.property.length);
            for (let i = 0; i < message.property.length; ++i) {
              object.property[i] = message.property[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.GetProfilePropertiesRequest";
        }
      }

      GetProfilePropertiesRequest.prototype.index = null;
      GetProfilePropertiesRequest.prototype.property = $util.emptyArray;

      return GetProfilePropertiesRequest;
    })();

    v1.IndexSearchRequest = (() => {
      class IndexSearchRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IndexSearchRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString")) {
            writer.uint32(10).string(message.searchString);
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(16).uint32(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IndexSearchRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.searchString = reader.string();
                break;
              }
              case 2: {
                message.limit = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.IndexSearchRequest) {
            return object;
          }
          const message = new $root.profiles.v1.IndexSearchRequest();
          if (object.searchString != null) {
            message.searchString = String(object.searchString);
          }
          if (object.limit != null) {
            message.limit = object.limit >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.searchString = "";
            object.limit = 0;
          }
          let keys;
          if (message.searchString != null && message.hasOwnProperty("searchString")) {
            object.searchString = message.searchString;
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.IndexSearchRequest";
        }
      }

      IndexSearchRequest.prototype.searchString = "";
      IndexSearchRequest.prototype.limit = 0;

      return IndexSearchRequest;
    })();

    v1.IndexSearchResponse = (() => {
      class IndexSearchResponse {
        constructor(properties) {
          this.profileHits = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IndexSearchResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileHits != null && Object.hasOwnProperty.call(message, "profileHits")) {
            for (const element of message.profileHits) {
              $root.profiles.v1.IndexSearchResponse.ProfileHit.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.searchDuration != null && Object.hasOwnProperty.call(message, "searchDuration")) {
            timing$1.v1.Duration.encode(message.searchDuration, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IndexSearchResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.profileHits || !message.profileHits.length) {
                  message.profileHits = [];
                }
                message.profileHits.push($root.profiles.v1.IndexSearchResponse.ProfileHit.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.searchDuration = timing$1.v1.Duration.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.IndexSearchResponse) {
            return object;
          }
          const message = new $root.profiles.v1.IndexSearchResponse();
          if (object.profileHits) {
            if (!Array.isArray(object.profileHits)) {
              throw new TypeError(".profiles.v1.IndexSearchResponse.profileHits: array type expected, but got " + (typeof object.profileHits))
            }
            message.profileHits = new Array(object.profileHits.length);
            for (let i = 0; i < object.profileHits.length; ++i) {
              if (typeof object.profileHits[i] !== "object") {
                throw new TypeError(".profiles.v1.IndexSearchResponse.profileHits: object expected, but got " + (typeof object.profileHits[i]));
              }
              message.profileHits[i] = $root.profiles.v1.IndexSearchResponse.ProfileHit.fromObject(object.profileHits[i]);
            }
          }
          if (object.searchDuration != null) {
            if (typeof object.searchDuration !== "object") {
              throw new TypeError(".profiles.v1.IndexSearchResponse.searchDuration: object expected, but got " + (typeof object.searchDuration));
            }
            message.searchDuration = timing$1.v1.Duration.fromObject(object.searchDuration);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.profileHits = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.searchDuration = null;
          }
          let keys;
          if (message.profileHits && message.profileHits.length) {
            object.profileHits = new Array(message.profileHits.length);
            for (let i = 0; i < message.profileHits.length; ++i) {
              object.profileHits[i] = $root.profiles.v1.IndexSearchResponse.ProfileHit.toObject(message.profileHits[i], options);
            }
          }
          if (message.searchDuration != null && message.hasOwnProperty("searchDuration")) {
            object.searchDuration = timing$1.v1.Duration.toObject(message.searchDuration, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.IndexSearchResponse";
        }
      }

      IndexSearchResponse.prototype.profileHits = $util.emptyArray;
      IndexSearchResponse.prototype.searchDuration = null;
      IndexSearchResponse.ProfileHit = (() => {
        class ProfileHit {
          get hitType() {
            for (const key of ["discoveredProfileIndex", "fullName", "emailAddress"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set hitType(name) {
            for (const key of ["discoveredProfileIndex", "fullName", "emailAddress"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ProfileHit(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.highlight != null && Object.hasOwnProperty.call(message, "highlight")) {
              writer.uint32(10).string(message.highlight);
            }
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
              $root.profiles.v1.Profile.encode(message.profile, writer.uint32(18).fork()).ldelim();
            }
            if (message.profileIndex != null && Object.hasOwnProperty.call(message, "profileIndex")) {
              $root.profiles.v1.ProfileIndex.encode(message.profileIndex, writer.uint32(26).fork()).ldelim();
            }
            if (message.score != null && Object.hasOwnProperty.call(message, "score")) {
              writer.uint32(33).double(message.score);
            }
            if (message.discoveredProfileIndex != null && Object.hasOwnProperty.call(message, "discoveredProfileIndex")) {
              $root.profiles.v1.ProfileIndex.encode(message.discoveredProfileIndex, writer.uint32(42).fork()).ldelim();
            }
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
              google$1.protobuf.Empty.encode(message.fullName, writer.uint32(50).fork()).ldelim();
            }
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
              google$1.protobuf.Empty.encode(message.emailAddress, writer.uint32(58).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ProfileHit();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.highlight = reader.string();
                  break;
                }
                case 2: {
                  message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.profileIndex = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  message.score = reader.double();
                  break;
                }
                case 5: {
                  message.discoveredProfileIndex = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                  break;
                }
                case 6: {
                  message.fullName = google$1.protobuf.Empty.decode(reader, reader.uint32());
                  break;
                }
                case 7: {
                  message.emailAddress = google$1.protobuf.Empty.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.IndexSearchResponse.ProfileHit) {
              return object;
            }
            const message = new $root.profiles.v1.IndexSearchResponse.ProfileHit();
            if (object.highlight != null) {
              message.highlight = String(object.highlight);
            }
            if (object.profile != null) {
              if (typeof object.profile !== "object") {
                throw new TypeError(".profiles.v1.IndexSearchResponse.ProfileHit.profile: object expected, but got " + (typeof object.profile));
              }
              message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
            }
            if (object.profileIndex != null) {
              if (typeof object.profileIndex !== "object") {
                throw new TypeError(".profiles.v1.IndexSearchResponse.ProfileHit.profileIndex: object expected, but got " + (typeof object.profileIndex));
              }
              message.profileIndex = $root.profiles.v1.ProfileIndex.fromObject(object.profileIndex);
            }
            if (object.score != null) {
              message.score = Number(object.score);
            }
            if (object.discoveredProfileIndex != null) {
              if (typeof object.discoveredProfileIndex !== "object") {
                throw new TypeError(".profiles.v1.IndexSearchResponse.ProfileHit.discoveredProfileIndex: object expected, but got " + (typeof object.discoveredProfileIndex));
              }
              message.discoveredProfileIndex = $root.profiles.v1.ProfileIndex.fromObject(object.discoveredProfileIndex);
            }
            if (object.fullName != null) {
              if (typeof object.fullName !== "object") {
                throw new TypeError(".profiles.v1.IndexSearchResponse.ProfileHit.fullName: object expected, but got " + (typeof object.fullName));
              }
              message.fullName = google$1.protobuf.Empty.fromObject(object.fullName);
            }
            if (object.emailAddress != null) {
              if (typeof object.emailAddress !== "object") {
                throw new TypeError(".profiles.v1.IndexSearchResponse.ProfileHit.emailAddress: object expected, but got " + (typeof object.emailAddress));
              }
              message.emailAddress = google$1.protobuf.Empty.fromObject(object.emailAddress);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.highlight = "";
              object.profile = null;
              object.profileIndex = null;
              object.score = 0;
            }
            let keys;
            if (message.highlight != null && message.hasOwnProperty("highlight")) {
              object.highlight = message.highlight;
            }
            if (message.profile != null && message.hasOwnProperty("profile")) {
              object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
            }
            if (message.profileIndex != null && message.hasOwnProperty("profileIndex")) {
              object.profileIndex = $root.profiles.v1.ProfileIndex.toObject(message.profileIndex, options);
            }
            if (message.score != null && message.hasOwnProperty("score")) {
              object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
            }
            if (message.discoveredProfileIndex != null && message.hasOwnProperty("discoveredProfileIndex")) {
              object.discoveredProfileIndex = $root.profiles.v1.ProfileIndex.toObject(message.discoveredProfileIndex, options);
              if (options.oneofs) {
                object.hitType = "discoveredProfileIndex";
              }
            }
            if (message.fullName != null && message.hasOwnProperty("fullName")) {
              object.fullName = google$1.protobuf.Empty.toObject(message.fullName, options);
              if (options.oneofs) {
                object.hitType = "fullName";
              }
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
              object.emailAddress = google$1.protobuf.Empty.toObject(message.emailAddress, options);
              if (options.oneofs) {
                object.hitType = "emailAddress";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.IndexSearchResponse.ProfileHit";
          }
        }

        ProfileHit.prototype.highlight = "";
        ProfileHit.prototype.profile = null;
        ProfileHit.prototype.profileIndex = null;
        ProfileHit.prototype.score = 0;
        ProfileHit.prototype.discoveredProfileIndex = null;
        ProfileHit.prototype.fullName = null;
        ProfileHit.prototype.emailAddress = null;

        return ProfileHit;
      })();

      return IndexSearchResponse;
    })();

    v1.ListRequest = (() => {
      class ListRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.earliestProfileUpdateTimestampInclusive != null && Object.hasOwnProperty.call(message, "earliestProfileUpdateTimestampInclusive")) {
            timing$1.v1.Timestamp.encode(message.earliestProfileUpdateTimestampInclusive, writer.uint32(10).fork()).ldelim();
          }
          if (message.latestProfileUpdateTimestampExclusive != null && Object.hasOwnProperty.call(message, "latestProfileUpdateTimestampExclusive")) {
            timing$1.v1.Timestamp.encode(message.latestProfileUpdateTimestampExclusive, writer.uint32(18).fork()).ldelim();
          }
          if (message.limit != null && Object.hasOwnProperty.call(message, "limit")) {
            writer.uint32(24).uint32(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.earliestProfileUpdateTimestampInclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.latestProfileUpdateTimestampExclusive = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.limit = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ListRequest) {
            return object;
          }
          const message = new $root.profiles.v1.ListRequest();
          if (object.earliestProfileUpdateTimestampInclusive != null) {
            if (typeof object.earliestProfileUpdateTimestampInclusive !== "object") {
              throw new TypeError(".profiles.v1.ListRequest.earliestProfileUpdateTimestampInclusive: object expected, but got " + (typeof object.earliestProfileUpdateTimestampInclusive));
            }
            message.earliestProfileUpdateTimestampInclusive = timing$1.v1.Timestamp.fromObject(object.earliestProfileUpdateTimestampInclusive);
          }
          if (object.latestProfileUpdateTimestampExclusive != null) {
            if (typeof object.latestProfileUpdateTimestampExclusive !== "object") {
              throw new TypeError(".profiles.v1.ListRequest.latestProfileUpdateTimestampExclusive: object expected, but got " + (typeof object.latestProfileUpdateTimestampExclusive));
            }
            message.latestProfileUpdateTimestampExclusive = timing$1.v1.Timestamp.fromObject(object.latestProfileUpdateTimestampExclusive);
          }
          if (object.limit != null) {
            message.limit = object.limit >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.limit = 0;
          }
          let keys;
          if (message.earliestProfileUpdateTimestampInclusive != null && message.hasOwnProperty("earliestProfileUpdateTimestampInclusive")) {
            object.earliestProfileUpdateTimestampInclusive = timing$1.v1.Timestamp.toObject(message.earliestProfileUpdateTimestampInclusive, options);
          }
          if (message.latestProfileUpdateTimestampExclusive != null && message.hasOwnProperty("latestProfileUpdateTimestampExclusive")) {
            object.latestProfileUpdateTimestampExclusive = timing$1.v1.Timestamp.toObject(message.latestProfileUpdateTimestampExclusive, options);
          }
          if (message.limit != null && message.hasOwnProperty("limit")) {
            object.limit = message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ListRequest";
        }
      }

      ListRequest.prototype.earliestProfileUpdateTimestampInclusive = null;
      ListRequest.prototype.latestProfileUpdateTimestampExclusive = null;
      ListRequest.prototype.limit = 0;

      return ListRequest;
    })();

    v1.ListResponse = (() => {
      class ListResponse {
        constructor(properties) {
          this.profiles = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profiles != null && Object.hasOwnProperty.call(message, "profiles")) {
            for (const element of message.profiles) {
              $root.profiles.v1.ListResponse.ListProfile.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.earliestFetchedTimestamp != null && Object.hasOwnProperty.call(message, "earliestFetchedTimestamp")) {
            timing$1.v1.Timestamp.encode(message.earliestFetchedTimestamp, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.profiles || !message.profiles.length) {
                  message.profiles = [];
                }
                message.profiles.push($root.profiles.v1.ListResponse.ListProfile.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ListResponse) {
            return object;
          }
          const message = new $root.profiles.v1.ListResponse();
          if (object.profiles) {
            if (!Array.isArray(object.profiles)) {
              throw new TypeError(".profiles.v1.ListResponse.profiles: array type expected, but got " + (typeof object.profiles))
            }
            message.profiles = new Array(object.profiles.length);
            for (let i = 0; i < object.profiles.length; ++i) {
              if (typeof object.profiles[i] !== "object") {
                throw new TypeError(".profiles.v1.ListResponse.profiles: object expected, but got " + (typeof object.profiles[i]));
              }
              message.profiles[i] = $root.profiles.v1.ListResponse.ListProfile.fromObject(object.profiles[i]);
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== "object") {
              throw new TypeError(".profiles.v1.ListResponse.earliestFetchedTimestamp: object expected, but got " + (typeof object.earliestFetchedTimestamp));
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(object.earliestFetchedTimestamp);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.profiles = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.profiles && message.profiles.length) {
            object.profiles = new Array(message.profiles.length);
            for (let i = 0; i < message.profiles.length; ++i) {
              object.profiles[i] = $root.profiles.v1.ListResponse.ListProfile.toObject(message.profiles[i], options);
            }
          }
          if (message.earliestFetchedTimestamp != null && message.hasOwnProperty("earliestFetchedTimestamp")) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(message.earliestFetchedTimestamp, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ListResponse";
        }
      }

      ListResponse.prototype.profiles = $util.emptyArray;
      ListResponse.prototype.earliestFetchedTimestamp = null;
      ListResponse.ListProfile = (() => {
        class ListProfile {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListProfile(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
              $root.profiles.v1.Profile.encode(message.profile, writer.uint32(10).fork()).ldelim();
            }
            if (message.profileIndex != null && Object.hasOwnProperty.call(message, "profileIndex")) {
              $root.profiles.v1.ProfileIndex.encode(message.profileIndex, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListProfile();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.profileIndex = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.ListResponse.ListProfile) {
              return object;
            }
            const message = new $root.profiles.v1.ListResponse.ListProfile();
            if (object.profile != null) {
              if (typeof object.profile !== "object") {
                throw new TypeError(".profiles.v1.ListResponse.ListProfile.profile: object expected, but got " + (typeof object.profile));
              }
              message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
            }
            if (object.profileIndex != null) {
              if (typeof object.profileIndex !== "object") {
                throw new TypeError(".profiles.v1.ListResponse.ListProfile.profileIndex: object expected, but got " + (typeof object.profileIndex));
              }
              message.profileIndex = $root.profiles.v1.ProfileIndex.fromObject(object.profileIndex);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.profile = null;
              object.profileIndex = null;
            }
            let keys;
            if (message.profile != null && message.hasOwnProperty("profile")) {
              object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
            }
            if (message.profileIndex != null && message.hasOwnProperty("profileIndex")) {
              object.profileIndex = $root.profiles.v1.ProfileIndex.toObject(message.profileIndex, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.ListResponse.ListProfile";
          }
        }

        ListProfile.prototype.profile = null;
        ListProfile.prototype.profileIndex = null;

        return ListProfile;
      })();

      return ListResponse;
    })();

    v1.ParseFormatPhoneNumberRequest = (() => {
      class ParseFormatPhoneNumberRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ParseFormatPhoneNumberRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            writer.uint32(10).string(message.phoneNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ParseFormatPhoneNumberRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.phoneNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ParseFormatPhoneNumberRequest) {
            return object;
          }
          const message = new $root.profiles.v1.ParseFormatPhoneNumberRequest();
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.phoneNumber = "";
          }
          let keys;
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = message.phoneNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ParseFormatPhoneNumberRequest";
        }
      }

      ParseFormatPhoneNumberRequest.prototype.phoneNumber = "";

      return ParseFormatPhoneNumberRequest;
    })();

    v1.ParseFormatPhoneNumberResponse = (() => {
      class ParseFormatPhoneNumberResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ParseFormatPhoneNumberResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.usFormattedPhoneNumber != null && Object.hasOwnProperty.call(message, "usFormattedPhoneNumber")) {
            writer.uint32(10).string(message.usFormattedPhoneNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ParseFormatPhoneNumberResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.usFormattedPhoneNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ParseFormatPhoneNumberResponse) {
            return object;
          }
          const message = new $root.profiles.v1.ParseFormatPhoneNumberResponse();
          if (object.usFormattedPhoneNumber != null) {
            message.usFormattedPhoneNumber = String(object.usFormattedPhoneNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.usFormattedPhoneNumber = "";
          }
          let keys;
          if (message.usFormattedPhoneNumber != null && message.hasOwnProperty("usFormattedPhoneNumber")) {
            object.usFormattedPhoneNumber = message.usFormattedPhoneNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ParseFormatPhoneNumberResponse";
        }
      }

      ParseFormatPhoneNumberResponse.prototype.usFormattedPhoneNumber = "";

      return ParseFormatPhoneNumberResponse;
    })();

    v1.ConvertToPatientRequest = (() => {
      class ConvertToPatientRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ConvertToPatientRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            $root.profiles.v1.ProfileIndex.encode(message.index, writer.uint32(10).fork()).ldelim();
          }
          if (message.mergeIfEmailAddressAlreadyExists != null && Object.hasOwnProperty.call(message, "mergeIfEmailAddressAlreadyExists")) {
            writer.uint32(16).bool(message.mergeIfEmailAddressAlreadyExists);
          }
          if (message.mergeIfPhoneNumberAlreadyExists != null && Object.hasOwnProperty.call(message, "mergeIfPhoneNumberAlreadyExists")) {
            writer.uint32(24).bool(message.mergeIfPhoneNumberAlreadyExists);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ConvertToPatientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.index = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mergeIfEmailAddressAlreadyExists = reader.bool();
                break;
              }
              case 3: {
                message.mergeIfPhoneNumberAlreadyExists = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ConvertToPatientRequest) {
            return object;
          }
          const message = new $root.profiles.v1.ConvertToPatientRequest();
          if (object.index != null) {
            if (typeof object.index !== "object") {
              throw new TypeError(".profiles.v1.ConvertToPatientRequest.index: object expected, but got " + (typeof object.index));
            }
            message.index = $root.profiles.v1.ProfileIndex.fromObject(object.index);
          }
          if (object.mergeIfEmailAddressAlreadyExists != null) {
            message.mergeIfEmailAddressAlreadyExists = Boolean(object.mergeIfEmailAddressAlreadyExists);
          }
          if (object.mergeIfPhoneNumberAlreadyExists != null) {
            message.mergeIfPhoneNumberAlreadyExists = Boolean(object.mergeIfPhoneNumberAlreadyExists);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.index = null;
            object.mergeIfEmailAddressAlreadyExists = false;
            object.mergeIfPhoneNumberAlreadyExists = false;
          }
          let keys;
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = $root.profiles.v1.ProfileIndex.toObject(message.index, options);
          }
          if (message.mergeIfEmailAddressAlreadyExists != null && message.hasOwnProperty("mergeIfEmailAddressAlreadyExists")) {
            object.mergeIfEmailAddressAlreadyExists = message.mergeIfEmailAddressAlreadyExists;
          }
          if (message.mergeIfPhoneNumberAlreadyExists != null && message.hasOwnProperty("mergeIfPhoneNumberAlreadyExists")) {
            object.mergeIfPhoneNumberAlreadyExists = message.mergeIfPhoneNumberAlreadyExists;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ConvertToPatientRequest";
        }
      }

      ConvertToPatientRequest.prototype.index = null;
      ConvertToPatientRequest.prototype.mergeIfEmailAddressAlreadyExists = false;
      ConvertToPatientRequest.prototype.mergeIfPhoneNumberAlreadyExists = false;

      return ConvertToPatientRequest;
    })();

    v1.ConvertProfileToPatientResult = (() => {
      class ConvertProfileToPatientResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ConvertProfileToPatientResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.convertedProfile != null && Object.hasOwnProperty.call(message, "convertedProfile")) {
            $root.profiles.v1.PatientProfile.encode(message.convertedProfile, writer.uint32(10).fork()).ldelim();
          }
          if (message.mergedIntoExistingEmail != null && Object.hasOwnProperty.call(message, "mergedIntoExistingEmail")) {
            $root.profiles.v1.PatientProfile.encode(message.mergedIntoExistingEmail, writer.uint32(18).fork()).ldelim();
          }
          if (message.mergedIntoExistingPhone != null && Object.hasOwnProperty.call(message, "mergedIntoExistingPhone")) {
            $root.profiles.v1.PatientProfile.encode(message.mergedIntoExistingPhone, writer.uint32(26).fork()).ldelim();
          }
          if (message.profileAlreadyPatientNoOp != null && Object.hasOwnProperty.call(message, "profileAlreadyPatientNoOp")) {
            writer.uint32(32).bool(message.profileAlreadyPatientNoOp);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ConvertProfileToPatientResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.convertedProfile = $root.profiles.v1.PatientProfile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mergedIntoExistingEmail = $root.profiles.v1.PatientProfile.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.mergedIntoExistingPhone = $root.profiles.v1.PatientProfile.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.profileAlreadyPatientNoOp = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ConvertProfileToPatientResult) {
            return object;
          }
          const message = new $root.profiles.v1.ConvertProfileToPatientResult();
          if (object.convertedProfile != null) {
            if (typeof object.convertedProfile !== "object") {
              throw new TypeError(".profiles.v1.ConvertProfileToPatientResult.convertedProfile: object expected, but got " + (typeof object.convertedProfile));
            }
            message.convertedProfile = $root.profiles.v1.PatientProfile.fromObject(object.convertedProfile);
          }
          if (object.mergedIntoExistingEmail != null) {
            if (typeof object.mergedIntoExistingEmail !== "object") {
              throw new TypeError(".profiles.v1.ConvertProfileToPatientResult.mergedIntoExistingEmail: object expected, but got " + (typeof object.mergedIntoExistingEmail));
            }
            message.mergedIntoExistingEmail = $root.profiles.v1.PatientProfile.fromObject(object.mergedIntoExistingEmail);
          }
          if (object.mergedIntoExistingPhone != null) {
            if (typeof object.mergedIntoExistingPhone !== "object") {
              throw new TypeError(".profiles.v1.ConvertProfileToPatientResult.mergedIntoExistingPhone: object expected, but got " + (typeof object.mergedIntoExistingPhone));
            }
            message.mergedIntoExistingPhone = $root.profiles.v1.PatientProfile.fromObject(object.mergedIntoExistingPhone);
          }
          if (object.profileAlreadyPatientNoOp != null) {
            message.profileAlreadyPatientNoOp = Boolean(object.profileAlreadyPatientNoOp);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.convertedProfile = null;
            object.profileAlreadyPatientNoOp = false;
          }
          let keys;
          if (message.convertedProfile != null && message.hasOwnProperty("convertedProfile")) {
            object.convertedProfile = $root.profiles.v1.PatientProfile.toObject(message.convertedProfile, options);
          }
          if (message.mergedIntoExistingEmail != null && message.hasOwnProperty("mergedIntoExistingEmail")) {
            object.mergedIntoExistingEmail = $root.profiles.v1.PatientProfile.toObject(message.mergedIntoExistingEmail, options);
          }
          if (message.mergedIntoExistingPhone != null && message.hasOwnProperty("mergedIntoExistingPhone")) {
            object.mergedIntoExistingPhone = $root.profiles.v1.PatientProfile.toObject(message.mergedIntoExistingPhone, options);
          }
          if (message.profileAlreadyPatientNoOp != null && message.hasOwnProperty("profileAlreadyPatientNoOp")) {
            object.profileAlreadyPatientNoOp = message.profileAlreadyPatientNoOp;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ConvertProfileToPatientResult";
        }
      }

      ConvertProfileToPatientResult.prototype.convertedProfile = null;
      ConvertProfileToPatientResult.prototype.mergedIntoExistingEmail = null;
      ConvertProfileToPatientResult.prototype.mergedIntoExistingPhone = null;
      ConvertProfileToPatientResult.prototype.profileAlreadyPatientNoOp = false;

      return ConvertProfileToPatientResult;
    })();

    v1.MakeProfileIndexActiveRequest = (() => {
      class MakeProfileIndexActiveRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MakeProfileIndexActiveRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            $root.profiles.v1.ProfileIndex.encode(message.index, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MakeProfileIndexActiveRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.index = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.MakeProfileIndexActiveRequest) {
            return object;
          }
          const message = new $root.profiles.v1.MakeProfileIndexActiveRequest();
          if (object.index != null) {
            if (typeof object.index !== "object") {
              throw new TypeError(".profiles.v1.MakeProfileIndexActiveRequest.index: object expected, but got " + (typeof object.index));
            }
            message.index = $root.profiles.v1.ProfileIndex.fromObject(object.index);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.index = null;
          }
          let keys;
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = $root.profiles.v1.ProfileIndex.toObject(message.index, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.MakeProfileIndexActiveRequest";
        }
      }

      MakeProfileIndexActiveRequest.prototype.index = null;

      return MakeProfileIndexActiveRequest;
    })();

    v1.MakeProfileIndexActiveResponse = (() => {
      class MakeProfileIndexActiveResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MakeProfileIndexActiveResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
            $root.profiles.v1.Profile.encode(message.profile, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MakeProfileIndexActiveResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.MakeProfileIndexActiveResponse) {
            return object;
          }
          const message = new $root.profiles.v1.MakeProfileIndexActiveResponse();
          if (object.profile != null) {
            if (typeof object.profile !== "object") {
              throw new TypeError(".profiles.v1.MakeProfileIndexActiveResponse.profile: object expected, but got " + (typeof object.profile));
            }
            message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profile = null;
          }
          let keys;
          if (message.profile != null && message.hasOwnProperty("profile")) {
            object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.MakeProfileIndexActiveResponse";
        }
      }

      MakeProfileIndexActiveResponse.prototype.profile = null;

      return MakeProfileIndexActiveResponse;
    })();

    v1.ThirdPartyId = (() => {
      class ThirdPartyId {
        constructor(properties) {
          this.mergedIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ThirdPartyId(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.associatedAt != null && Object.hasOwnProperty.call(message, "associatedAt")) {
            timing$1.v1.Timestamp.encode(message.associatedAt, writer.uint32(18).fork()).ldelim();
          }
          if (message.mergedIds != null && Object.hasOwnProperty.call(message, "mergedIds")) {
            for (const element of message.mergedIds) {
              $root.profiles.v1.ThirdPartyId.MergedId.encode(element, writer.uint32(26).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ThirdPartyId();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.associatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                if (!message.mergedIds || !message.mergedIds.length) {
                  message.mergedIds = [];
                }
                message.mergedIds.push($root.profiles.v1.ThirdPartyId.MergedId.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ThirdPartyId) {
            return object;
          }
          const message = new $root.profiles.v1.ThirdPartyId();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.associatedAt != null) {
            if (typeof object.associatedAt !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyId.associatedAt: object expected, but got " + (typeof object.associatedAt));
            }
            message.associatedAt = timing$1.v1.Timestamp.fromObject(object.associatedAt);
          }
          if (object.mergedIds) {
            if (!Array.isArray(object.mergedIds)) {
              throw new TypeError(".profiles.v1.ThirdPartyId.mergedIds: array type expected, but got " + (typeof object.mergedIds))
            }
            message.mergedIds = new Array(object.mergedIds.length);
            for (let i = 0; i < object.mergedIds.length; ++i) {
              if (typeof object.mergedIds[i] !== "object") {
                throw new TypeError(".profiles.v1.ThirdPartyId.mergedIds: object expected, but got " + (typeof object.mergedIds[i]));
              }
              message.mergedIds[i] = $root.profiles.v1.ThirdPartyId.MergedId.fromObject(object.mergedIds[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.mergedIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.associatedAt != null && message.hasOwnProperty("associatedAt")) {
            object.associatedAt = timing$1.v1.Timestamp.toObject(message.associatedAt, options);
          }
          if (message.mergedIds && message.mergedIds.length) {
            object.mergedIds = new Array(message.mergedIds.length);
            for (let i = 0; i < message.mergedIds.length; ++i) {
              object.mergedIds[i] = $root.profiles.v1.ThirdPartyId.MergedId.toObject(message.mergedIds[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ThirdPartyId";
        }
      }

      ThirdPartyId.prototype.id = "";
      ThirdPartyId.prototype.associatedAt = null;
      ThirdPartyId.prototype.mergedIds = $util.emptyArray;
      ThirdPartyId.MergedId = (() => {
        class MergedId {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new MergedId(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
              writer.uint32(10).string(message.id);
            }
            if (message.associatedAt != null && Object.hasOwnProperty.call(message, "associatedAt")) {
              timing$1.v1.Timestamp.encode(message.associatedAt, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new MergedId();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.id = reader.string();
                  break;
                }
                case 2: {
                  message.associatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.ThirdPartyId.MergedId) {
              return object;
            }
            const message = new $root.profiles.v1.ThirdPartyId.MergedId();
            if (object.id != null) {
              message.id = String(object.id);
            }
            if (object.associatedAt != null) {
              if (typeof object.associatedAt !== "object") {
                throw new TypeError(".profiles.v1.ThirdPartyId.MergedId.associatedAt: object expected, but got " + (typeof object.associatedAt));
              }
              message.associatedAt = timing$1.v1.Timestamp.fromObject(object.associatedAt);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.id = "";
            }
            let keys;
            if (message.id != null && message.hasOwnProperty("id")) {
              object.id = message.id;
            }
            if (message.associatedAt != null && message.hasOwnProperty("associatedAt")) {
              object.associatedAt = timing$1.v1.Timestamp.toObject(message.associatedAt, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.ThirdPartyId.MergedId";
          }
        }

        MergedId.prototype.id = "";
        MergedId.prototype.associatedAt = null;

        return MergedId;
      })();

      return ThirdPartyId;
    })();

    v1.ThirdPartyIds = (() => {
      class ThirdPartyIds {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ThirdPartyIds(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.customer != null && Object.hasOwnProperty.call(message, "customer")) {
            $root.profiles.v1.ThirdPartyId.encode(message.customer, writer.uint32(10).fork()).ldelim();
          }
          if (message.formsort != null && Object.hasOwnProperty.call(message, "formsort")) {
            $root.profiles.v1.ThirdPartyId.encode(message.formsort, writer.uint32(18).fork()).ldelim();
          }
          if (message.healthie != null && Object.hasOwnProperty.call(message, "healthie")) {
            $root.profiles.v1.ThirdPartyId.encode(message.healthie, writer.uint32(26).fork()).ldelim();
          }
          if (message.axleHealth != null && Object.hasOwnProperty.call(message, "axleHealth")) {
            $root.profiles.v1.ThirdPartyId.encode(message.axleHealth, writer.uint32(34).fork()).ldelim();
          }
          if (message.butterflyLabs != null && Object.hasOwnProperty.call(message, "butterflyLabs")) {
            $root.profiles.v1.ThirdPartyId.encode(message.butterflyLabs, writer.uint32(42).fork()).ldelim();
          }
          if (message.recurly != null && Object.hasOwnProperty.call(message, "recurly")) {
            $root.profiles.v1.ThirdPartyId.encode(message.recurly, writer.uint32(50).fork()).ldelim();
          }
          if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom")) {
            $root.profiles.v1.ThirdPartyId.encode(message.intercom, writer.uint32(58).fork()).ldelim();
          }
          if (message.sanaBenefits != null && Object.hasOwnProperty.call(message, "sanaBenefits")) {
            $root.profiles.v1.ThirdPartyId.encode(message.sanaBenefits, writer.uint32(66).fork()).ldelim();
          }
          if (message.activeCampaign != null && Object.hasOwnProperty.call(message, "activeCampaign")) {
            $root.profiles.v1.ThirdPartyId.encode(message.activeCampaign, writer.uint32(74).fork()).ldelim();
          }
          if (message.vital != null && Object.hasOwnProperty.call(message, "vital")) {
            $root.profiles.v1.ThirdPartyId.encode(message.vital, writer.uint32(82).fork()).ldelim();
          }
          if (message.segment != null && Object.hasOwnProperty.call(message, "segment")) {
            $root.profiles.v1.ThirdPartyId.encode(message.segment, writer.uint32(90).fork()).ldelim();
          }
          if (message.intakeq != null && Object.hasOwnProperty.call(message, "intakeq")) {
            $root.profiles.v1.ThirdPartyId.encode(message.intakeq, writer.uint32(98).fork()).ldelim();
          }
          if (message.customerIo != null && Object.hasOwnProperty.call(message, "customerIo")) {
            $root.profiles.v1.ThirdPartyId.encode(message.customerIo, writer.uint32(106).fork()).ldelim();
          }
          if (message.freshdesk != null && Object.hasOwnProperty.call(message, "freshdesk")) {
            $root.profiles.v1.ThirdPartyId.encode(message.freshdesk, writer.uint32(114).fork()).ldelim();
          }
          if (message.hubspot != null && Object.hasOwnProperty.call(message, "hubspot")) {
            $root.profiles.v1.ThirdPartyId.encode(message.hubspot, writer.uint32(122).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ThirdPartyIds();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customer = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.formsort = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.healthie = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.axleHealth = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.butterflyLabs = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.recurly = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.intercom = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.sanaBenefits = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.activeCampaign = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.vital = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.segment = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.intakeq = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.customerIo = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.freshdesk = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.hubspot = $root.profiles.v1.ThirdPartyId.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ThirdPartyIds) {
            return object;
          }
          const message = new $root.profiles.v1.ThirdPartyIds();
          if (object.customer != null) {
            if (typeof object.customer !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.customer: object expected, but got " + (typeof object.customer));
            }
            message.customer = $root.profiles.v1.ThirdPartyId.fromObject(object.customer);
          }
          if (object.formsort != null) {
            if (typeof object.formsort !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.formsort: object expected, but got " + (typeof object.formsort));
            }
            message.formsort = $root.profiles.v1.ThirdPartyId.fromObject(object.formsort);
          }
          if (object.healthie != null) {
            if (typeof object.healthie !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.healthie: object expected, but got " + (typeof object.healthie));
            }
            message.healthie = $root.profiles.v1.ThirdPartyId.fromObject(object.healthie);
          }
          if (object.axleHealth != null) {
            if (typeof object.axleHealth !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.axleHealth: object expected, but got " + (typeof object.axleHealth));
            }
            message.axleHealth = $root.profiles.v1.ThirdPartyId.fromObject(object.axleHealth);
          }
          if (object.butterflyLabs != null) {
            if (typeof object.butterflyLabs !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.butterflyLabs: object expected, but got " + (typeof object.butterflyLabs));
            }
            message.butterflyLabs = $root.profiles.v1.ThirdPartyId.fromObject(object.butterflyLabs);
          }
          if (object.recurly != null) {
            if (typeof object.recurly !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.recurly: object expected, but got " + (typeof object.recurly));
            }
            message.recurly = $root.profiles.v1.ThirdPartyId.fromObject(object.recurly);
          }
          if (object.intercom != null) {
            if (typeof object.intercom !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.intercom: object expected, but got " + (typeof object.intercom));
            }
            message.intercom = $root.profiles.v1.ThirdPartyId.fromObject(object.intercom);
          }
          if (object.sanaBenefits != null) {
            if (typeof object.sanaBenefits !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.sanaBenefits: object expected, but got " + (typeof object.sanaBenefits));
            }
            message.sanaBenefits = $root.profiles.v1.ThirdPartyId.fromObject(object.sanaBenefits);
          }
          if (object.activeCampaign != null) {
            if (typeof object.activeCampaign !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.activeCampaign: object expected, but got " + (typeof object.activeCampaign));
            }
            message.activeCampaign = $root.profiles.v1.ThirdPartyId.fromObject(object.activeCampaign);
          }
          if (object.vital != null) {
            if (typeof object.vital !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.vital: object expected, but got " + (typeof object.vital));
            }
            message.vital = $root.profiles.v1.ThirdPartyId.fromObject(object.vital);
          }
          if (object.segment != null) {
            if (typeof object.segment !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.segment: object expected, but got " + (typeof object.segment));
            }
            message.segment = $root.profiles.v1.ThirdPartyId.fromObject(object.segment);
          }
          if (object.intakeq != null) {
            if (typeof object.intakeq !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.intakeq: object expected, but got " + (typeof object.intakeq));
            }
            message.intakeq = $root.profiles.v1.ThirdPartyId.fromObject(object.intakeq);
          }
          if (object.customerIo != null) {
            if (typeof object.customerIo !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.customerIo: object expected, but got " + (typeof object.customerIo));
            }
            message.customerIo = $root.profiles.v1.ThirdPartyId.fromObject(object.customerIo);
          }
          if (object.freshdesk != null) {
            if (typeof object.freshdesk !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.freshdesk: object expected, but got " + (typeof object.freshdesk));
            }
            message.freshdesk = $root.profiles.v1.ThirdPartyId.fromObject(object.freshdesk);
          }
          if (object.hubspot != null) {
            if (typeof object.hubspot !== "object") {
              throw new TypeError(".profiles.v1.ThirdPartyIds.hubspot: object expected, but got " + (typeof object.hubspot));
            }
            message.hubspot = $root.profiles.v1.ThirdPartyId.fromObject(object.hubspot);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.customer != null && message.hasOwnProperty("customer")) {
            object.customer = $root.profiles.v1.ThirdPartyId.toObject(message.customer, options);
          }
          if (message.formsort != null && message.hasOwnProperty("formsort")) {
            object.formsort = $root.profiles.v1.ThirdPartyId.toObject(message.formsort, options);
          }
          if (message.healthie != null && message.hasOwnProperty("healthie")) {
            object.healthie = $root.profiles.v1.ThirdPartyId.toObject(message.healthie, options);
          }
          if (message.axleHealth != null && message.hasOwnProperty("axleHealth")) {
            object.axleHealth = $root.profiles.v1.ThirdPartyId.toObject(message.axleHealth, options);
          }
          if (message.butterflyLabs != null && message.hasOwnProperty("butterflyLabs")) {
            object.butterflyLabs = $root.profiles.v1.ThirdPartyId.toObject(message.butterflyLabs, options);
          }
          if (message.recurly != null && message.hasOwnProperty("recurly")) {
            object.recurly = $root.profiles.v1.ThirdPartyId.toObject(message.recurly, options);
          }
          if (message.intercom != null && message.hasOwnProperty("intercom")) {
            object.intercom = $root.profiles.v1.ThirdPartyId.toObject(message.intercom, options);
          }
          if (message.sanaBenefits != null && message.hasOwnProperty("sanaBenefits")) {
            object.sanaBenefits = $root.profiles.v1.ThirdPartyId.toObject(message.sanaBenefits, options);
          }
          if (message.activeCampaign != null && message.hasOwnProperty("activeCampaign")) {
            object.activeCampaign = $root.profiles.v1.ThirdPartyId.toObject(message.activeCampaign, options);
          }
          if (message.vital != null && message.hasOwnProperty("vital")) {
            object.vital = $root.profiles.v1.ThirdPartyId.toObject(message.vital, options);
          }
          if (message.segment != null && message.hasOwnProperty("segment")) {
            object.segment = $root.profiles.v1.ThirdPartyId.toObject(message.segment, options);
          }
          if (message.intakeq != null && message.hasOwnProperty("intakeq")) {
            object.intakeq = $root.profiles.v1.ThirdPartyId.toObject(message.intakeq, options);
          }
          if (message.customerIo != null && message.hasOwnProperty("customerIo")) {
            object.customerIo = $root.profiles.v1.ThirdPartyId.toObject(message.customerIo, options);
          }
          if (message.freshdesk != null && message.hasOwnProperty("freshdesk")) {
            object.freshdesk = $root.profiles.v1.ThirdPartyId.toObject(message.freshdesk, options);
          }
          if (message.hubspot != null && message.hasOwnProperty("hubspot")) {
            object.hubspot = $root.profiles.v1.ThirdPartyId.toObject(message.hubspot, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ThirdPartyIds";
        }
      }

      ThirdPartyIds.prototype.customer = null;
      ThirdPartyIds.prototype.formsort = null;
      ThirdPartyIds.prototype.healthie = null;
      ThirdPartyIds.prototype.axleHealth = null;
      ThirdPartyIds.prototype.butterflyLabs = null;
      ThirdPartyIds.prototype.recurly = null;
      ThirdPartyIds.prototype.intercom = null;
      ThirdPartyIds.prototype.sanaBenefits = null;
      ThirdPartyIds.prototype.activeCampaign = null;
      ThirdPartyIds.prototype.vital = null;
      ThirdPartyIds.prototype.segment = null;
      ThirdPartyIds.prototype.intakeq = null;
      ThirdPartyIds.prototype.customerIo = null;
      ThirdPartyIds.prototype.freshdesk = null;
      ThirdPartyIds.prototype.hubspot = null;

      return ThirdPartyIds;
    })();

    v1.ProfileIndex = (() => {
      class ProfileIndex {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfileIndex(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
            writer.uint32(8).int32(message.type);
          }
          if (message.thirdPartyId != null && Object.hasOwnProperty.call(message, "thirdPartyId")) {
            writer.uint32(18).string(message.thirdPartyId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfileIndex();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.int32();
                break;
              }
              case 2: {
                message.thirdPartyId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ProfileIndex) {
            return object;
          }
          const message = new $root.profiles.v1.ProfileIndex();
          if (object.type != null) {
            switch (object.type) {
              case "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION":
              case 0: {
                message.type = 0;
                break;
              }
              case "CUSTOMER":
              case 1: {
                message.type = 1;
                break;
              }
              case "FORMSORT":
              case 2: {
                message.type = 2;
                break;
              }
              case "HEALTHIE":
              case 3: {
                message.type = 3;
                break;
              }
              case "AXLE_HEALTH":
              case 4: {
                message.type = 4;
                break;
              }
              case "BUTTERFLY_LABS":
              case 5: {
                message.type = 5;
                break;
              }
              case "RECURLY":
              case 6: {
                message.type = 6;
                break;
              }
              case "INTERCOM":
              case 7: {
                message.type = 7;
                break;
              }
              case "SANA_BENEFITS":
              case 8: {
                message.type = 8;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 9: {
                message.type = 9;
                break;
              }
              case "VITAL":
              case 10: {
                message.type = 10;
                break;
              }
              case "SEGMENT":
              case 11: {
                message.type = 11;
                break;
              }
              case "INTAKEQ":
              case 12: {
                message.type = 12;
                break;
              }
              case "CUSTOMER_IO":
              case 13: {
                message.type = 13;
                break;
              }
              case "FRESHDESK":
              case 14: {
                message.type = 14;
                break;
              }
              case "HUBSPOT":
              case 15: {
                message.type = 15;
                break;
              }
              default: {
                if (typeof object.type == "number") {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          if (object.thirdPartyId != null) {
            message.thirdPartyId = String(object.thirdPartyId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = options.enums === String ? "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION" : 0
            object.thirdPartyId = "";
          }
          let keys;
          if (message.type != null && message.hasOwnProperty("type")) {
            object.type = options.enums === String ? thirdparties$1.v1.ThirdPartyIdType[message.type] === undefined ? message.type : thirdparties$1.v1.ThirdPartyIdType[message.type] : message.type;
          }
          if (message.thirdPartyId != null && message.hasOwnProperty("thirdPartyId")) {
            object.thirdPartyId = message.thirdPartyId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ProfileIndex";
        }
      }

      ProfileIndex.prototype.type = 0;
      ProfileIndex.prototype.thirdPartyId = "";

      return ProfileIndex;
    })();

    v1.Profile = (() => {
      class Profile {
        get type() {
          for (const key of ["anonymous", "lead", "patient"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["anonymous", "lead", "patient"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Profile(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartyIds != null && Object.hasOwnProperty.call(message, "thirdPartyIds")) {
            $root.profiles.v1.ThirdPartyIds.encode(message.thirdPartyIds, writer.uint32(10).fork()).ldelim();
          }
          if (message.timing != null && Object.hasOwnProperty.call(message, "timing")) {
            timing$1.v1.Timing.encode(message.timing, writer.uint32(18).fork()).ldelim();
          }
          if (message.anonymous != null && Object.hasOwnProperty.call(message, "anonymous")) {
            $root.profiles.v1.Profile.AnonymousType.encode(message.anonymous, writer.uint32(26).fork()).ldelim();
          }
          if (message.lead != null && Object.hasOwnProperty.call(message, "lead")) {
            $root.profiles.v1.Profile.LeadType.encode(message.lead, writer.uint32(34).fork()).ldelim();
          }
          if (message.patient != null && Object.hasOwnProperty.call(message, "patient")) {
            $root.profiles.v1.Profile.PatientType.encode(message.patient, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Profile();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.timing = timing$1.v1.Timing.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.anonymous = $root.profiles.v1.Profile.AnonymousType.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.lead = $root.profiles.v1.Profile.LeadType.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.patient = $root.profiles.v1.Profile.PatientType.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.Profile) {
            return object;
          }
          const message = new $root.profiles.v1.Profile();
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== "object") {
              throw new TypeError(".profiles.v1.Profile.thirdPartyIds: object expected, but got " + (typeof object.thirdPartyIds));
            }
            message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.fromObject(object.thirdPartyIds);
          }
          if (object.timing != null) {
            if (typeof object.timing !== "object") {
              throw new TypeError(".profiles.v1.Profile.timing: object expected, but got " + (typeof object.timing));
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.anonymous != null) {
            if (typeof object.anonymous !== "object") {
              throw new TypeError(".profiles.v1.Profile.anonymous: object expected, but got " + (typeof object.anonymous));
            }
            message.anonymous = $root.profiles.v1.Profile.AnonymousType.fromObject(object.anonymous);
          }
          if (object.lead != null) {
            if (typeof object.lead !== "object") {
              throw new TypeError(".profiles.v1.Profile.lead: object expected, but got " + (typeof object.lead));
            }
            message.lead = $root.profiles.v1.Profile.LeadType.fromObject(object.lead);
          }
          if (object.patient != null) {
            if (typeof object.patient !== "object") {
              throw new TypeError(".profiles.v1.Profile.patient: object expected, but got " + (typeof object.patient));
            }
            message.patient = $root.profiles.v1.Profile.PatientType.fromObject(object.patient);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartyIds = null;
            object.timing = null;
          }
          let keys;
          if (message.thirdPartyIds != null && message.hasOwnProperty("thirdPartyIds")) {
            object.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.toObject(message.thirdPartyIds, options);
          }
          if (message.timing != null && message.hasOwnProperty("timing")) {
            object.timing = timing$1.v1.Timing.toObject(message.timing, options);
          }
          if (message.anonymous != null && message.hasOwnProperty("anonymous")) {
            object.anonymous = $root.profiles.v1.Profile.AnonymousType.toObject(message.anonymous, options);
            if (options.oneofs) {
              object.type = "anonymous";
            }
          }
          if (message.lead != null && message.hasOwnProperty("lead")) {
            object.lead = $root.profiles.v1.Profile.LeadType.toObject(message.lead, options);
            if (options.oneofs) {
              object.type = "lead";
            }
          }
          if (message.patient != null && message.hasOwnProperty("patient")) {
            object.patient = $root.profiles.v1.Profile.PatientType.toObject(message.patient, options);
            if (options.oneofs) {
              object.type = "patient";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.Profile";
        }
      }

      Profile.prototype.thirdPartyIds = null;
      Profile.prototype.timing = null;
      Profile.prototype.anonymous = null;
      Profile.prototype.lead = null;
      Profile.prototype.patient = null;
      Profile.AnonymousType = (() => {
        class AnonymousType {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new AnonymousType(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
              writer.uint32(10).string(message.fullName);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new AnonymousType();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.fullName = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.Profile.AnonymousType) {
              return object;
            }
            const message = new $root.profiles.v1.Profile.AnonymousType();
            if (object.fullName != null) {
              message.fullName = String(object.fullName);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.fullName != null && message.hasOwnProperty("fullName")) {
              object.fullName = message.fullName;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.Profile.AnonymousType";
          }
        }

        AnonymousType.prototype.fullName = null;

        return AnonymousType;
      })();

      Profile.LeadType = (() => {
        class LeadType {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new LeadType(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
              writer.uint32(10).string(message.emailAddress);
            }
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
              writer.uint32(18).string(message.phoneNumber);
            }
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
              writer.uint32(26).string(message.fullName);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new LeadType();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.emailAddress = reader.string();
                  break;
                }
                case 2: {
                  message.phoneNumber = reader.string();
                  break;
                }
                case 3: {
                  message.fullName = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.Profile.LeadType) {
              return object;
            }
            const message = new $root.profiles.v1.Profile.LeadType();
            if (object.emailAddress != null) {
              message.emailAddress = String(object.emailAddress);
            }
            if (object.phoneNumber != null) {
              message.phoneNumber = String(object.phoneNumber);
            }
            if (object.fullName != null) {
              message.fullName = String(object.fullName);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
              object.emailAddress = message.emailAddress;
            }
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
              object.phoneNumber = message.phoneNumber;
            }
            if (message.fullName != null && message.hasOwnProperty("fullName")) {
              object.fullName = message.fullName;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.Profile.LeadType";
          }
        }

        LeadType.prototype.emailAddress = null;
        LeadType.prototype.phoneNumber = null;
        LeadType.prototype.fullName = null;

        return LeadType;
      })();

      Profile.PatientType = (() => {
        class PatientType {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new PatientType(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
              writer.uint32(10).string(message.emailAddress);
            }
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
              writer.uint32(18).string(message.phoneNumber);
            }
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
              writer.uint32(26).string(message.fullName);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new PatientType();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.emailAddress = reader.string();
                  break;
                }
                case 2: {
                  message.phoneNumber = reader.string();
                  break;
                }
                case 3: {
                  message.fullName = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.Profile.PatientType) {
              return object;
            }
            const message = new $root.profiles.v1.Profile.PatientType();
            if (object.emailAddress != null) {
              message.emailAddress = String(object.emailAddress);
            }
            if (object.phoneNumber != null) {
              message.phoneNumber = String(object.phoneNumber);
            }
            if (object.fullName != null) {
              message.fullName = String(object.fullName);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
              object.emailAddress = message.emailAddress;
            }
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
              object.phoneNumber = message.phoneNumber;
            }
            if (message.fullName != null && message.hasOwnProperty("fullName")) {
              object.fullName = message.fullName;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.Profile.PatientType";
          }
        }

        PatientType.prototype.emailAddress = null;
        PatientType.prototype.phoneNumber = null;
        PatientType.prototype.fullName = null;

        return PatientType;
      })();

      return Profile;
    })();

    v1.LeadProfile = (() => {
      class LeadProfile {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LeadProfile(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartyIds != null && Object.hasOwnProperty.call(message, "thirdPartyIds")) {
            $root.profiles.v1.ThirdPartyIds.encode(message.thirdPartyIds, writer.uint32(10).fork()).ldelim();
          }
          if (message.timing != null && Object.hasOwnProperty.call(message, "timing")) {
            timing$1.v1.Timing.encode(message.timing, writer.uint32(18).fork()).ldelim();
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(26).string(message.emailAddress);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            writer.uint32(34).string(message.phoneNumber);
          }
          if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
            writer.uint32(42).string(message.fullName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LeadProfile();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.timing = timing$1.v1.Timing.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.emailAddress = reader.string();
                break;
              }
              case 4: {
                message.phoneNumber = reader.string();
                break;
              }
              case 5: {
                message.fullName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LeadProfile) {
            return object;
          }
          const message = new $root.profiles.v1.LeadProfile();
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== "object") {
              throw new TypeError(".profiles.v1.LeadProfile.thirdPartyIds: object expected, but got " + (typeof object.thirdPartyIds));
            }
            message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.fromObject(object.thirdPartyIds);
          }
          if (object.timing != null) {
            if (typeof object.timing !== "object") {
              throw new TypeError(".profiles.v1.LeadProfile.timing: object expected, but got " + (typeof object.timing));
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.fullName != null) {
            message.fullName = String(object.fullName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartyIds = null;
            object.timing = null;
          }
          let keys;
          if (message.thirdPartyIds != null && message.hasOwnProperty("thirdPartyIds")) {
            object.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.toObject(message.thirdPartyIds, options);
          }
          if (message.timing != null && message.hasOwnProperty("timing")) {
            object.timing = timing$1.v1.Timing.toObject(message.timing, options);
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = message.phoneNumber;
          }
          if (message.fullName != null && message.hasOwnProperty("fullName")) {
            object.fullName = message.fullName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LeadProfile";
        }
      }

      LeadProfile.prototype.thirdPartyIds = null;
      LeadProfile.prototype.timing = null;
      LeadProfile.prototype.emailAddress = null;
      LeadProfile.prototype.phoneNumber = null;
      LeadProfile.prototype.fullName = null;

      return LeadProfile;
    })();

    v1.PatientProfile = (() => {
      class PatientProfile {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientProfile(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartyIds != null && Object.hasOwnProperty.call(message, "thirdPartyIds")) {
            $root.profiles.v1.ThirdPartyIds.encode(message.thirdPartyIds, writer.uint32(10).fork()).ldelim();
          }
          if (message.timing != null && Object.hasOwnProperty.call(message, "timing")) {
            timing$1.v1.Timing.encode(message.timing, writer.uint32(18).fork()).ldelim();
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(26).string(message.emailAddress);
          }
          if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber")) {
            writer.uint32(34).string(message.phoneNumber);
          }
          if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName")) {
            writer.uint32(42).string(message.fullName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientProfile();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.timing = timing$1.v1.Timing.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.emailAddress = reader.string();
                break;
              }
              case 4: {
                message.phoneNumber = reader.string();
                break;
              }
              case 5: {
                message.fullName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.PatientProfile) {
            return object;
          }
          const message = new $root.profiles.v1.PatientProfile();
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== "object") {
              throw new TypeError(".profiles.v1.PatientProfile.thirdPartyIds: object expected, but got " + (typeof object.thirdPartyIds));
            }
            message.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.fromObject(object.thirdPartyIds);
          }
          if (object.timing != null) {
            if (typeof object.timing !== "object") {
              throw new TypeError(".profiles.v1.PatientProfile.timing: object expected, but got " + (typeof object.timing));
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.phoneNumber != null) {
            message.phoneNumber = String(object.phoneNumber);
          }
          if (object.fullName != null) {
            message.fullName = String(object.fullName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartyIds = null;
            object.timing = null;
          }
          let keys;
          if (message.thirdPartyIds != null && message.hasOwnProperty("thirdPartyIds")) {
            object.thirdPartyIds = $root.profiles.v1.ThirdPartyIds.toObject(message.thirdPartyIds, options);
          }
          if (message.timing != null && message.hasOwnProperty("timing")) {
            object.timing = timing$1.v1.Timing.toObject(message.timing, options);
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
            object.phoneNumber = message.phoneNumber;
          }
          if (message.fullName != null && message.hasOwnProperty("fullName")) {
            object.fullName = message.fullName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.PatientProfile";
        }
      }

      PatientProfile.prototype.thirdPartyIds = null;
      PatientProfile.prototype.timing = null;
      PatientProfile.prototype.emailAddress = null;
      PatientProfile.prototype.phoneNumber = null;
      PatientProfile.prototype.fullName = null;

      return PatientProfile;
    })();

    v1.UnprocessedProfileIndexUpdateOps = (() => {
      class UnprocessedProfileIndexUpdateOps {
        constructor(properties) {
          this.ops = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UnprocessedProfileIndexUpdateOps(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.ops != null && Object.hasOwnProperty.call(message, "ops")) {
            for (const element of message.ops) {
              $root.profiles.v1.UnprocessedProfileIndexUpdateOps.Op.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UnprocessedProfileIndexUpdateOps();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.ops || !message.ops.length) {
                  message.ops = [];
                }
                message.ops.push($root.profiles.v1.UnprocessedProfileIndexUpdateOps.Op.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.UnprocessedProfileIndexUpdateOps) {
            return object;
          }
          const message = new $root.profiles.v1.UnprocessedProfileIndexUpdateOps();
          if (object.ops) {
            if (!Array.isArray(object.ops)) {
              throw new TypeError(".profiles.v1.UnprocessedProfileIndexUpdateOps.ops: array type expected, but got " + (typeof object.ops))
            }
            message.ops = new Array(object.ops.length);
            for (let i = 0; i < object.ops.length; ++i) {
              if (typeof object.ops[i] !== "object") {
                throw new TypeError(".profiles.v1.UnprocessedProfileIndexUpdateOps.ops: object expected, but got " + (typeof object.ops[i]));
              }
              message.ops[i] = $root.profiles.v1.UnprocessedProfileIndexUpdateOps.Op.fromObject(object.ops[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.ops = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.ops && message.ops.length) {
            object.ops = new Array(message.ops.length);
            for (let i = 0; i < message.ops.length; ++i) {
              object.ops[i] = $root.profiles.v1.UnprocessedProfileIndexUpdateOps.Op.toObject(message.ops[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.UnprocessedProfileIndexUpdateOps";
        }
      }

      UnprocessedProfileIndexUpdateOps.prototype.ops = $util.emptyArray;
      UnprocessedProfileIndexUpdateOps.Op = (() => {
        class Op {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Op(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.updateOperationId != null && Object.hasOwnProperty.call(message, "updateOperationId")) {
              writer.uint32(10).bytes(message.updateOperationId);
            }
            if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
              writer.uint32(18).bytes(message.orgId);
            }
            if (message.internalProfileId != null && Object.hasOwnProperty.call(message, "internalProfileId")) {
              writer.uint32(26).bytes(message.internalProfileId);
            }
            if (message.indexOp != null && Object.hasOwnProperty.call(message, "indexOp")) {
              writer.uint32(32).int32(message.indexOp);
            }
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile")) {
              $root.profiles.v1.Profile.encode(message.profile, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Op();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.updateOperationId = reader.bytes();
                  break;
                }
                case 2: {
                  message.orgId = reader.bytes();
                  break;
                }
                case 3: {
                  message.internalProfileId = reader.bytes();
                  break;
                }
                case 4: {
                  message.indexOp = reader.int32();
                  break;
                }
                case 5: {
                  message.profile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.UnprocessedProfileIndexUpdateOps.Op) {
              return object;
            }
            const message = new $root.profiles.v1.UnprocessedProfileIndexUpdateOps.Op();
            if (object.updateOperationId != null) {
              if (typeof object.updateOperationId === "string") {
                $util.base64.decode(object.updateOperationId, message.updateOperationId = $util.newBuffer($util.base64.length(object.updateOperationId)), 0);
              } else if (object.updateOperationId.length >= 0) {
                message.updateOperationId = object.updateOperationId;
              }
            }
            if (object.orgId != null) {
              if (typeof object.orgId === "string") {
                $util.base64.decode(object.orgId, message.orgId = $util.newBuffer($util.base64.length(object.orgId)), 0);
              } else if (object.orgId.length >= 0) {
                message.orgId = object.orgId;
              }
            }
            if (object.internalProfileId != null) {
              if (typeof object.internalProfileId === "string") {
                $util.base64.decode(object.internalProfileId, message.internalProfileId = $util.newBuffer($util.base64.length(object.internalProfileId)), 0);
              } else if (object.internalProfileId.length >= 0) {
                message.internalProfileId = object.internalProfileId;
              }
            }
            if (object.indexOp != null) {
              switch (object.indexOp) {
                case "UNSPECIFIED_ILLEGAL_INTERNAL_PROFILE_INDEX_OP_TYPE":
                case 0: {
                  message.indexOp = 0;
                  break;
                }
                case "UPDATE_INDEX":
                case 1: {
                  message.indexOp = 1;
                  break;
                }
                case "DELETE_INDEX":
                case 2: {
                  message.indexOp = 2;
                  break;
                }
                default: {
                  if (typeof object.indexOp == "number") {
                    message.indexOp = object.indexOp;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.profile != null) {
              if (typeof object.profile !== "object") {
                throw new TypeError(".profiles.v1.UnprocessedProfileIndexUpdateOps.Op.profile: object expected, but got " + (typeof object.profile));
              }
              message.profile = $root.profiles.v1.Profile.fromObject(object.profile);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              if (options.bytes === String) {
                object.updateOperationId = ""
              } else {
                object.updateOperationId = [];
                if (options.bytes !== Array) {
                  object.updateOperationId = $util.newBuffer(object.updateOperationId);
                }
              }
              object.indexOp = options.enums === String ? "UNSPECIFIED_ILLEGAL_INTERNAL_PROFILE_INDEX_OP_TYPE" : 0
            }
            let keys;
            if (message.updateOperationId != null && message.hasOwnProperty("updateOperationId")) {
              object.updateOperationId = options.bytes === String ? $util.base64.encode(message.updateOperationId, 0, message.updateOperationId.length) : options.bytes === Array ? Array.prototype.slice.call(message.updateOperationId) : message.updateOperationId;
            }
            if (message.orgId != null && message.hasOwnProperty("orgId")) {
              object.orgId = options.bytes === String ? $util.base64.encode(message.orgId, 0, message.orgId.length) : options.bytes === Array ? Array.prototype.slice.call(message.orgId) : message.orgId;
            }
            if (message.internalProfileId != null && message.hasOwnProperty("internalProfileId")) {
              object.internalProfileId = options.bytes === String ? $util.base64.encode(message.internalProfileId, 0, message.internalProfileId.length) : options.bytes === Array ? Array.prototype.slice.call(message.internalProfileId) : message.internalProfileId;
            }
            if (message.indexOp != null && message.hasOwnProperty("indexOp")) {
              object.indexOp = options.enums === String ? $root.profiles.v1.InternalProfileIndexOp[message.indexOp] === undefined ? message.indexOp : $root.profiles.v1.InternalProfileIndexOp[message.indexOp] : message.indexOp;
            }
            if (message.profile != null && message.hasOwnProperty("profile")) {
              object.profile = $root.profiles.v1.Profile.toObject(message.profile, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.UnprocessedProfileIndexUpdateOps.Op";
          }
        }

        Op.prototype.updateOperationId = new Uint8Array();
        Op.prototype.orgId = null;
        Op.prototype.internalProfileId = null;
        Op.prototype.indexOp = 0;
        Op.prototype.profile = null;

        return Op;
      })();

      return UnprocessedProfileIndexUpdateOps;
    })();

    v1.LookupConfiguration = (() => {
      class LookupConfiguration {
        constructor(properties) {
          this.lookupsSequence = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LookupConfiguration(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.lookupType != null && Object.hasOwnProperty.call(message, "lookupType")) {
            writer.uint32(8).int32(message.lookupType);
          }
          if (message.lookupsSequence != null && Object.hasOwnProperty.call(message, "lookupsSequence")) {
            for (const element of message.lookupsSequence) {
              $root.profiles.v1.Lookup.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          if (message.noProfileFoundAction != null && Object.hasOwnProperty.call(message, "noProfileFoundAction")) {
            writer.uint32(24).int32(message.noProfileFoundAction);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LookupConfiguration();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.lookupType = reader.int32();
                break;
              }
              case 2: {
                if (!message.lookupsSequence || !message.lookupsSequence.length) {
                  message.lookupsSequence = [];
                }
                message.lookupsSequence.push($root.profiles.v1.Lookup.decode(reader, reader.uint32()));
                break;
              }
              case 3: {
                message.noProfileFoundAction = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LookupConfiguration) {
            return object;
          }
          const message = new $root.profiles.v1.LookupConfiguration();
          if (object.lookupType != null) {
            switch (object.lookupType) {
              case "UNSPECIFIED_ILLEGAL_LOOKUP_TYPE":
              case 0: {
                message.lookupType = 0;
                break;
              }
              case "LOOKUP_TYPE_LEAD":
              case 1: {
                message.lookupType = 1;
                break;
              }
              case "LOOKUP_TYPE_PATIENT":
              case 2: {
                message.lookupType = 2;
                break;
              }
              case "LOOKUP_TYPE_LEAD_AND_PATIENT":
              case 3: {
                message.lookupType = 3;
                break;
              }
              default: {
                if (typeof object.lookupType == "number") {
                  message.lookupType = object.lookupType;
                  break;
                }
                break;
              }
            }
          }
          if (object.lookupsSequence) {
            if (!Array.isArray(object.lookupsSequence)) {
              throw new TypeError(".profiles.v1.LookupConfiguration.lookupsSequence: array type expected, but got " + (typeof object.lookupsSequence))
            }
            message.lookupsSequence = new Array(object.lookupsSequence.length);
            for (let i = 0; i < object.lookupsSequence.length; ++i) {
              if (typeof object.lookupsSequence[i] !== "object") {
                throw new TypeError(".profiles.v1.LookupConfiguration.lookupsSequence: object expected, but got " + (typeof object.lookupsSequence[i]));
              }
              message.lookupsSequence[i] = $root.profiles.v1.Lookup.fromObject(object.lookupsSequence[i]);
            }
          }
          if (object.noProfileFoundAction != null) {
            switch (object.noProfileFoundAction) {
              case "UNSPECIFIED_ILLEGAL_NO_PROFILE_FOUND_ACTION":
              case 0: {
                message.noProfileFoundAction = 0;
                break;
              }
              case "NO_PROFILE_FOUND_ACTION_CREATE_LEAD":
              case 1: {
                message.noProfileFoundAction = 1;
                break;
              }
              case "NO_PROFILE_FOUND_ACTION_CREATE_PATIENT":
              case 2: {
                message.noProfileFoundAction = 2;
                break;
              }
              case "NO_PROFILE_FOUND_ACTION_ERROR":
              case 3: {
                message.noProfileFoundAction = 3;
                break;
              }
              case "NO_PROFILE_FOUND_ACTION_TERMINATE_WORKFLOW_GRACEFULLY":
              case 4: {
                message.noProfileFoundAction = 4;
                break;
              }
              case "NO_PROFILE_FOUND_ACTION_RETRY":
              case 5: {
                message.noProfileFoundAction = 5;
                break;
              }
              default: {
                if (typeof object.noProfileFoundAction == "number") {
                  message.noProfileFoundAction = object.noProfileFoundAction;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.lookupsSequence = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.lookupType = options.enums === String ? "UNSPECIFIED_ILLEGAL_LOOKUP_TYPE" : 0
            object.noProfileFoundAction = options.enums === String ? "UNSPECIFIED_ILLEGAL_NO_PROFILE_FOUND_ACTION" : 0
          }
          let keys;
          if (message.lookupType != null && message.hasOwnProperty("lookupType")) {
            object.lookupType = options.enums === String ? $root.profiles.v1.LookupType[message.lookupType] === undefined ? message.lookupType : $root.profiles.v1.LookupType[message.lookupType] : message.lookupType;
          }
          if (message.lookupsSequence && message.lookupsSequence.length) {
            object.lookupsSequence = new Array(message.lookupsSequence.length);
            for (let i = 0; i < message.lookupsSequence.length; ++i) {
              object.lookupsSequence[i] = $root.profiles.v1.Lookup.toObject(message.lookupsSequence[i], options);
            }
          }
          if (message.noProfileFoundAction != null && message.hasOwnProperty("noProfileFoundAction")) {
            object.noProfileFoundAction = options.enums === String ? $root.profiles.v1.NoProfileFoundAction[message.noProfileFoundAction] === undefined ? message.noProfileFoundAction : $root.profiles.v1.NoProfileFoundAction[message.noProfileFoundAction] : message.noProfileFoundAction;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LookupConfiguration";
        }
      }

      LookupConfiguration.prototype.lookupType = 0;
      LookupConfiguration.prototype.lookupsSequence = $util.emptyArray;
      LookupConfiguration.prototype.noProfileFoundAction = 0;

      return LookupConfiguration;
    })();

    v1.Lookup = (() => {
      class Lookup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Lookup(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.fieldNotPresentAction != null && Object.hasOwnProperty.call(message, "fieldNotPresentAction")) {
            writer.uint32(8).int32(message.fieldNotPresentAction);
          }
          if (message.index != null && Object.hasOwnProperty.call(message, "index")) {
            $root.profiles.v1.LookupIndex.encode(message.index, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Lookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldNotPresentAction = reader.int32();
                break;
              }
              case 2: {
                message.index = $root.profiles.v1.LookupIndex.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.Lookup) {
            return object;
          }
          const message = new $root.profiles.v1.Lookup();
          if (object.fieldNotPresentAction != null) {
            switch (object.fieldNotPresentAction) {
              case "UNSPECIFIED_ILLEGAL_FIELD_NOT_PRESENT_ACTION":
              case 0: {
                message.fieldNotPresentAction = 0;
                break;
              }
              case "FIELD_NOT_PRESENT_ACTION_ERROR":
              case 1: {
                message.fieldNotPresentAction = 1;
                break;
              }
              case "FIELD_NOT_PRESENT_ACTION_SKIP_TO_NEXT_LOOKUP_IN_SEQUENCE":
              case 2: {
                message.fieldNotPresentAction = 2;
                break;
              }
              case "FIELD_NOT_PRESENT_ACTION_TERMINATE_WORKFLOW_GRACEFULLY":
              case 3: {
                message.fieldNotPresentAction = 3;
                break;
              }
              default: {
                if (typeof object.fieldNotPresentAction == "number") {
                  message.fieldNotPresentAction = object.fieldNotPresentAction;
                  break;
                }
                break;
              }
            }
          }
          if (object.index != null) {
            if (typeof object.index !== "object") {
              throw new TypeError(".profiles.v1.Lookup.index: object expected, but got " + (typeof object.index));
            }
            message.index = $root.profiles.v1.LookupIndex.fromObject(object.index);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldNotPresentAction = options.enums === String ? "UNSPECIFIED_ILLEGAL_FIELD_NOT_PRESENT_ACTION" : 0
            object.index = null;
          }
          let keys;
          if (message.fieldNotPresentAction != null && message.hasOwnProperty("fieldNotPresentAction")) {
            object.fieldNotPresentAction = options.enums === String ? $root.profiles.v1.FieldNotPresentAction[message.fieldNotPresentAction] === undefined ? message.fieldNotPresentAction : $root.profiles.v1.FieldNotPresentAction[message.fieldNotPresentAction] : message.fieldNotPresentAction;
          }
          if (message.index != null && message.hasOwnProperty("index")) {
            object.index = $root.profiles.v1.LookupIndex.toObject(message.index, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.Lookup";
        }
      }

      Lookup.prototype.fieldNotPresentAction = 0;
      Lookup.prototype.index = null;

      return Lookup;
    })();

    v1.LookupIndex = (() => {
      class LookupIndex {
        get type() {
          for (const key of ["id", "email", "phone"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["id", "email", "phone"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LookupIndex(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            $root.profiles.v1.IdIndex.encode(message.id, writer.uint32(10).fork()).ldelim();
          }
          if (message.email != null && Object.hasOwnProperty.call(message, "email")) {
            $root.profiles.v1.UniqueIndex.encode(message.email, writer.uint32(18).fork()).ldelim();
          }
          if (message.phone != null && Object.hasOwnProperty.call(message, "phone")) {
            $root.profiles.v1.UniqueIndex.encode(message.phone, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LookupIndex();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.profiles.v1.IdIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.email = $root.profiles.v1.UniqueIndex.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.phone = $root.profiles.v1.UniqueIndex.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LookupIndex) {
            return object;
          }
          const message = new $root.profiles.v1.LookupIndex();
          if (object.id != null) {
            if (typeof object.id !== "object") {
              throw new TypeError(".profiles.v1.LookupIndex.id: object expected, but got " + (typeof object.id));
            }
            message.id = $root.profiles.v1.IdIndex.fromObject(object.id);
          }
          if (object.email != null) {
            if (typeof object.email !== "object") {
              throw new TypeError(".profiles.v1.LookupIndex.email: object expected, but got " + (typeof object.email));
            }
            message.email = $root.profiles.v1.UniqueIndex.fromObject(object.email);
          }
          if (object.phone != null) {
            if (typeof object.phone !== "object") {
              throw new TypeError(".profiles.v1.LookupIndex.phone: object expected, but got " + (typeof object.phone));
            }
            message.phone = $root.profiles.v1.UniqueIndex.fromObject(object.phone);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = $root.profiles.v1.IdIndex.toObject(message.id, options);
            if (options.oneofs) {
              object.type = "id";
            }
          }
          if (message.email != null && message.hasOwnProperty("email")) {
            object.email = $root.profiles.v1.UniqueIndex.toObject(message.email, options);
            if (options.oneofs) {
              object.type = "email";
            }
          }
          if (message.phone != null && message.hasOwnProperty("phone")) {
            object.phone = $root.profiles.v1.UniqueIndex.toObject(message.phone, options);
            if (options.oneofs) {
              object.type = "phone";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LookupIndex";
        }
      }

      LookupIndex.prototype.id = null;
      LookupIndex.prototype.email = null;
      LookupIndex.prototype.phone = null;

      return LookupIndex;
    })();

    v1.IdIndex = (() => {
      class IdIndex {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IdIndex(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.thirdPartyIdType != null && Object.hasOwnProperty.call(message, "thirdPartyIdType")) {
            writer.uint32(8).int32(message.thirdPartyIdType);
          }
          if (message.idFieldLookup != null && Object.hasOwnProperty.call(message, "idFieldLookup")) {
            workflow_parameters$1.v1.EventPayloadFieldLookup.encode(message.idFieldLookup, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IdIndex();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.thirdPartyIdType = reader.int32();
                break;
              }
              case 2: {
                message.idFieldLookup = workflow_parameters$1.v1.EventPayloadFieldLookup.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.IdIndex) {
            return object;
          }
          const message = new $root.profiles.v1.IdIndex();
          if (object.thirdPartyIdType != null) {
            switch (object.thirdPartyIdType) {
              case "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION":
              case 0: {
                message.thirdPartyIdType = 0;
                break;
              }
              case "CUSTOMER":
              case 1: {
                message.thirdPartyIdType = 1;
                break;
              }
              case "FORMSORT":
              case 2: {
                message.thirdPartyIdType = 2;
                break;
              }
              case "HEALTHIE":
              case 3: {
                message.thirdPartyIdType = 3;
                break;
              }
              case "AXLE_HEALTH":
              case 4: {
                message.thirdPartyIdType = 4;
                break;
              }
              case "BUTTERFLY_LABS":
              case 5: {
                message.thirdPartyIdType = 5;
                break;
              }
              case "RECURLY":
              case 6: {
                message.thirdPartyIdType = 6;
                break;
              }
              case "INTERCOM":
              case 7: {
                message.thirdPartyIdType = 7;
                break;
              }
              case "SANA_BENEFITS":
              case 8: {
                message.thirdPartyIdType = 8;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 9: {
                message.thirdPartyIdType = 9;
                break;
              }
              case "VITAL":
              case 10: {
                message.thirdPartyIdType = 10;
                break;
              }
              case "SEGMENT":
              case 11: {
                message.thirdPartyIdType = 11;
                break;
              }
              case "INTAKEQ":
              case 12: {
                message.thirdPartyIdType = 12;
                break;
              }
              case "CUSTOMER_IO":
              case 13: {
                message.thirdPartyIdType = 13;
                break;
              }
              case "FRESHDESK":
              case 14: {
                message.thirdPartyIdType = 14;
                break;
              }
              case "HUBSPOT":
              case 15: {
                message.thirdPartyIdType = 15;
                break;
              }
              default: {
                if (typeof object.thirdPartyIdType == "number") {
                  message.thirdPartyIdType = object.thirdPartyIdType;
                  break;
                }
                break;
              }
            }
          }
          if (object.idFieldLookup != null) {
            if (typeof object.idFieldLookup !== "object") {
              throw new TypeError(".profiles.v1.IdIndex.idFieldLookup: object expected, but got " + (typeof object.idFieldLookup));
            }
            message.idFieldLookup = workflow_parameters$1.v1.EventPayloadFieldLookup.fromObject(object.idFieldLookup);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.thirdPartyIdType = options.enums === String ? "UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION" : 0
            object.idFieldLookup = null;
          }
          let keys;
          if (message.thirdPartyIdType != null && message.hasOwnProperty("thirdPartyIdType")) {
            object.thirdPartyIdType = options.enums === String ? thirdparties$1.v1.ThirdPartyIdType[message.thirdPartyIdType] === undefined ? message.thirdPartyIdType : thirdparties$1.v1.ThirdPartyIdType[message.thirdPartyIdType] : message.thirdPartyIdType;
          }
          if (message.idFieldLookup != null && message.hasOwnProperty("idFieldLookup")) {
            object.idFieldLookup = workflow_parameters$1.v1.EventPayloadFieldLookup.toObject(message.idFieldLookup, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.IdIndex";
        }
      }

      IdIndex.prototype.thirdPartyIdType = 0;
      IdIndex.prototype.idFieldLookup = null;

      return IdIndex;
    })();

    v1.UniqueIndex = (() => {
      class UniqueIndex {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UniqueIndex(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.uniqueIndexFieldLookup != null && Object.hasOwnProperty.call(message, "uniqueIndexFieldLookup")) {
            workflow_parameters$1.v1.EventPayloadFieldLookup.encode(message.uniqueIndexFieldLookup, writer.uint32(10).fork()).ldelim();
          }
          if (message.multipleProfilesAction != null && Object.hasOwnProperty.call(message, "multipleProfilesAction")) {
            writer.uint32(16).int32(message.multipleProfilesAction);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UniqueIndex();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.uniqueIndexFieldLookup = workflow_parameters$1.v1.EventPayloadFieldLookup.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.multipleProfilesAction = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.UniqueIndex) {
            return object;
          }
          const message = new $root.profiles.v1.UniqueIndex();
          if (object.uniqueIndexFieldLookup != null) {
            if (typeof object.uniqueIndexFieldLookup !== "object") {
              throw new TypeError(".profiles.v1.UniqueIndex.uniqueIndexFieldLookup: object expected, but got " + (typeof object.uniqueIndexFieldLookup));
            }
            message.uniqueIndexFieldLookup = workflow_parameters$1.v1.EventPayloadFieldLookup.fromObject(object.uniqueIndexFieldLookup);
          }
          if (object.multipleProfilesAction != null) {
            switch (object.multipleProfilesAction) {
              case "UNSPECIFIED_ILLEGAL_MULTIPLE_PROFILES_ACTION":
              case 0: {
                message.multipleProfilesAction = 0;
                break;
              }
              case "MULTIPLE_PROFILES_ACTION_CHOOSE_LEAD":
              case 1: {
                message.multipleProfilesAction = 1;
                break;
              }
              case "MULTIPLE_PROFILES_ACTION_CHOOSE_PATIENT":
              case 2: {
                message.multipleProfilesAction = 2;
                break;
              }
              default: {
                if (typeof object.multipleProfilesAction == "number") {
                  message.multipleProfilesAction = object.multipleProfilesAction;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.uniqueIndexFieldLookup = null;
            object.multipleProfilesAction = options.enums === String ? "UNSPECIFIED_ILLEGAL_MULTIPLE_PROFILES_ACTION" : 0
          }
          let keys;
          if (message.uniqueIndexFieldLookup != null && message.hasOwnProperty("uniqueIndexFieldLookup")) {
            object.uniqueIndexFieldLookup = workflow_parameters$1.v1.EventPayloadFieldLookup.toObject(message.uniqueIndexFieldLookup, options);
          }
          if (message.multipleProfilesAction != null && message.hasOwnProperty("multipleProfilesAction")) {
            object.multipleProfilesAction = options.enums === String ? $root.profiles.v1.MultipleProfilesAction[message.multipleProfilesAction] === undefined ? message.multipleProfilesAction : $root.profiles.v1.MultipleProfilesAction[message.multipleProfilesAction] : message.multipleProfilesAction;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.UniqueIndex";
        }
      }

      UniqueIndex.prototype.uniqueIndexFieldLookup = null;
      UniqueIndex.prototype.multipleProfilesAction = 0;

      return UniqueIndex;
    })();

    v1.LookupExecutionResult = (() => {
      class LookupExecutionResult {
        constructor(properties) {
          this.executedLookupsSequence = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LookupExecutionResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.configuration != null && Object.hasOwnProperty.call(message, "configuration")) {
            $root.profiles.v1.LookupConfiguration.encode(message.configuration, writer.uint32(10).fork()).ldelim();
          }
          if (message.executedLookupsSequence != null && Object.hasOwnProperty.call(message, "executedLookupsSequence")) {
            for (const element of message.executedLookupsSequence) {
              $root.profiles.v1.LookupStepOutcome.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome")) {
            $root.profiles.v1.LookupOutcome.encode(message.outcome, writer.uint32(26).fork()).ldelim();
          }
          if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime")) {
            timing$1.v1.Timestamp.encode(message.startTime, writer.uint32(34).fork()).ldelim();
          }
          if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime")) {
            timing$1.v1.Timestamp.encode(message.endTime, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LookupExecutionResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.configuration = $root.profiles.v1.LookupConfiguration.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                if (!message.executedLookupsSequence || !message.executedLookupsSequence.length) {
                  message.executedLookupsSequence = [];
                }
                message.executedLookupsSequence.push($root.profiles.v1.LookupStepOutcome.decode(reader, reader.uint32()));
                break;
              }
              case 3: {
                message.outcome = $root.profiles.v1.LookupOutcome.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.startTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.endTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LookupExecutionResult) {
            return object;
          }
          const message = new $root.profiles.v1.LookupExecutionResult();
          if (object.configuration != null) {
            if (typeof object.configuration !== "object") {
              throw new TypeError(".profiles.v1.LookupExecutionResult.configuration: object expected, but got " + (typeof object.configuration));
            }
            message.configuration = $root.profiles.v1.LookupConfiguration.fromObject(object.configuration);
          }
          if (object.executedLookupsSequence) {
            if (!Array.isArray(object.executedLookupsSequence)) {
              throw new TypeError(".profiles.v1.LookupExecutionResult.executedLookupsSequence: array type expected, but got " + (typeof object.executedLookupsSequence))
            }
            message.executedLookupsSequence = new Array(object.executedLookupsSequence.length);
            for (let i = 0; i < object.executedLookupsSequence.length; ++i) {
              if (typeof object.executedLookupsSequence[i] !== "object") {
                throw new TypeError(".profiles.v1.LookupExecutionResult.executedLookupsSequence: object expected, but got " + (typeof object.executedLookupsSequence[i]));
              }
              message.executedLookupsSequence[i] = $root.profiles.v1.LookupStepOutcome.fromObject(object.executedLookupsSequence[i]);
            }
          }
          if (object.outcome != null) {
            if (typeof object.outcome !== "object") {
              throw new TypeError(".profiles.v1.LookupExecutionResult.outcome: object expected, but got " + (typeof object.outcome));
            }
            message.outcome = $root.profiles.v1.LookupOutcome.fromObject(object.outcome);
          }
          if (object.startTime != null) {
            if (typeof object.startTime !== "object") {
              throw new TypeError(".profiles.v1.LookupExecutionResult.startTime: object expected, but got " + (typeof object.startTime));
            }
            message.startTime = timing$1.v1.Timestamp.fromObject(object.startTime);
          }
          if (object.endTime != null) {
            if (typeof object.endTime !== "object") {
              throw new TypeError(".profiles.v1.LookupExecutionResult.endTime: object expected, but got " + (typeof object.endTime));
            }
            message.endTime = timing$1.v1.Timestamp.fromObject(object.endTime);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.executedLookupsSequence = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.configuration = null;
            object.outcome = null;
            object.startTime = null;
            object.endTime = null;
          }
          let keys;
          if (message.configuration != null && message.hasOwnProperty("configuration")) {
            object.configuration = $root.profiles.v1.LookupConfiguration.toObject(message.configuration, options);
          }
          if (message.executedLookupsSequence && message.executedLookupsSequence.length) {
            object.executedLookupsSequence = new Array(message.executedLookupsSequence.length);
            for (let i = 0; i < message.executedLookupsSequence.length; ++i) {
              object.executedLookupsSequence[i] = $root.profiles.v1.LookupStepOutcome.toObject(message.executedLookupsSequence[i], options);
            }
          }
          if (message.outcome != null && message.hasOwnProperty("outcome")) {
            object.outcome = $root.profiles.v1.LookupOutcome.toObject(message.outcome, options);
          }
          if (message.startTime != null && message.hasOwnProperty("startTime")) {
            object.startTime = timing$1.v1.Timestamp.toObject(message.startTime, options);
          }
          if (message.endTime != null && message.hasOwnProperty("endTime")) {
            object.endTime = timing$1.v1.Timestamp.toObject(message.endTime, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LookupExecutionResult";
        }
      }

      LookupExecutionResult.prototype.configuration = null;
      LookupExecutionResult.prototype.executedLookupsSequence = $util.emptyArray;
      LookupExecutionResult.prototype.outcome = null;
      LookupExecutionResult.prototype.startTime = null;
      LookupExecutionResult.prototype.endTime = null;

      return LookupExecutionResult;
    })();

    v1.LookupStepOutcome = (() => {
      class LookupStepOutcome {
        get stepOutcome() {
          for (const key of ["error", "fieldMissing", "fieldInvalidType", "noProfileFound", "singleProfileFound", "multipleProfilesFound"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set stepOutcome(name) {
          for (const key of ["error", "fieldMissing", "fieldInvalidType", "noProfileFound", "singleProfileFound", "multipleProfilesFound"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LookupStepOutcome(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.lookupIndex != null && Object.hasOwnProperty.call(message, "lookupIndex")) {
            $root.profiles.v1.LookupIndex.encode(message.lookupIndex, writer.uint32(10).fork()).ldelim();
          }
          if (message.discoveredIndexValue != null && Object.hasOwnProperty.call(message, "discoveredIndexValue")) {
            writer.uint32(18).string(message.discoveredIndexValue);
          }
          if (message.stepExecutedAt != null && Object.hasOwnProperty.call(message, "stepExecutedAt")) {
            timing$1.v1.Timestamp.encode(message.stepExecutedAt, writer.uint32(26).fork()).ldelim();
          }
          if (message.error != null && Object.hasOwnProperty.call(message, "error")) {
            $root.profiles.v1.LookupError.encode(message.error, writer.uint32(34).fork()).ldelim();
          }
          if (message.fieldMissing != null && Object.hasOwnProperty.call(message, "fieldMissing")) {
            google$1.protobuf.Empty.encode(message.fieldMissing, writer.uint32(42).fork()).ldelim();
          }
          if (message.fieldInvalidType != null && Object.hasOwnProperty.call(message, "fieldInvalidType")) {
            google$1.protobuf.Empty.encode(message.fieldInvalidType, writer.uint32(50).fork()).ldelim();
          }
          if (message.noProfileFound != null && Object.hasOwnProperty.call(message, "noProfileFound")) {
            google$1.protobuf.Empty.encode(message.noProfileFound, writer.uint32(58).fork()).ldelim();
          }
          if (message.singleProfileFound != null && Object.hasOwnProperty.call(message, "singleProfileFound")) {
            $root.profiles.v1.Profile.encode(message.singleProfileFound, writer.uint32(66).fork()).ldelim();
          }
          if (message.multipleProfilesFound != null && Object.hasOwnProperty.call(message, "multipleProfilesFound")) {
            $root.profiles.v1.MultipleProfilesFound.encode(message.multipleProfilesFound, writer.uint32(74).fork()).ldelim();
          }
          if (message.discoveredProfileIndexStatus != null && Object.hasOwnProperty.call(message, "discoveredProfileIndexStatus")) {
            $root.profiles.v1.DiscoveredProfileIndexStatus.encode(message.discoveredProfileIndexStatus, writer.uint32(82).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LookupStepOutcome();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.lookupIndex = $root.profiles.v1.LookupIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.discoveredIndexValue = reader.string();
                break;
              }
              case 3: {
                message.stepExecutedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.error = $root.profiles.v1.LookupError.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.fieldMissing = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.fieldInvalidType = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.noProfileFound = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.singleProfileFound = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.multipleProfilesFound = $root.profiles.v1.MultipleProfilesFound.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.discoveredProfileIndexStatus = $root.profiles.v1.DiscoveredProfileIndexStatus.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LookupStepOutcome) {
            return object;
          }
          const message = new $root.profiles.v1.LookupStepOutcome();
          if (object.lookupIndex != null) {
            if (typeof object.lookupIndex !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.lookupIndex: object expected, but got " + (typeof object.lookupIndex));
            }
            message.lookupIndex = $root.profiles.v1.LookupIndex.fromObject(object.lookupIndex);
          }
          if (object.discoveredIndexValue != null) {
            message.discoveredIndexValue = String(object.discoveredIndexValue);
          }
          if (object.stepExecutedAt != null) {
            if (typeof object.stepExecutedAt !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.stepExecutedAt: object expected, but got " + (typeof object.stepExecutedAt));
            }
            message.stepExecutedAt = timing$1.v1.Timestamp.fromObject(object.stepExecutedAt);
          }
          if (object.error != null) {
            if (typeof object.error !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.error: object expected, but got " + (typeof object.error));
            }
            message.error = $root.profiles.v1.LookupError.fromObject(object.error);
          }
          if (object.fieldMissing != null) {
            if (typeof object.fieldMissing !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.fieldMissing: object expected, but got " + (typeof object.fieldMissing));
            }
            message.fieldMissing = google$1.protobuf.Empty.fromObject(object.fieldMissing);
          }
          if (object.fieldInvalidType != null) {
            if (typeof object.fieldInvalidType !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.fieldInvalidType: object expected, but got " + (typeof object.fieldInvalidType));
            }
            message.fieldInvalidType = google$1.protobuf.Empty.fromObject(object.fieldInvalidType);
          }
          if (object.noProfileFound != null) {
            if (typeof object.noProfileFound !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.noProfileFound: object expected, but got " + (typeof object.noProfileFound));
            }
            message.noProfileFound = google$1.protobuf.Empty.fromObject(object.noProfileFound);
          }
          if (object.singleProfileFound != null) {
            if (typeof object.singleProfileFound !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.singleProfileFound: object expected, but got " + (typeof object.singleProfileFound));
            }
            message.singleProfileFound = $root.profiles.v1.Profile.fromObject(object.singleProfileFound);
          }
          if (object.multipleProfilesFound != null) {
            if (typeof object.multipleProfilesFound !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.multipleProfilesFound: object expected, but got " + (typeof object.multipleProfilesFound));
            }
            message.multipleProfilesFound = $root.profiles.v1.MultipleProfilesFound.fromObject(object.multipleProfilesFound);
          }
          if (object.discoveredProfileIndexStatus != null) {
            if (typeof object.discoveredProfileIndexStatus !== "object") {
              throw new TypeError(".profiles.v1.LookupStepOutcome.discoveredProfileIndexStatus: object expected, but got " + (typeof object.discoveredProfileIndexStatus));
            }
            message.discoveredProfileIndexStatus = $root.profiles.v1.DiscoveredProfileIndexStatus.fromObject(object.discoveredProfileIndexStatus);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.lookupIndex = null;
            object.stepExecutedAt = null;
          }
          let keys;
          if (message.lookupIndex != null && message.hasOwnProperty("lookupIndex")) {
            object.lookupIndex = $root.profiles.v1.LookupIndex.toObject(message.lookupIndex, options);
          }
          if (message.discoveredIndexValue != null && message.hasOwnProperty("discoveredIndexValue")) {
            object.discoveredIndexValue = message.discoveredIndexValue;
          }
          if (message.stepExecutedAt != null && message.hasOwnProperty("stepExecutedAt")) {
            object.stepExecutedAt = timing$1.v1.Timestamp.toObject(message.stepExecutedAt, options);
          }
          if (message.error != null && message.hasOwnProperty("error")) {
            object.error = $root.profiles.v1.LookupError.toObject(message.error, options);
            if (options.oneofs) {
              object.stepOutcome = "error";
            }
          }
          if (message.fieldMissing != null && message.hasOwnProperty("fieldMissing")) {
            object.fieldMissing = google$1.protobuf.Empty.toObject(message.fieldMissing, options);
            if (options.oneofs) {
              object.stepOutcome = "fieldMissing";
            }
          }
          if (message.fieldInvalidType != null && message.hasOwnProperty("fieldInvalidType")) {
            object.fieldInvalidType = google$1.protobuf.Empty.toObject(message.fieldInvalidType, options);
            if (options.oneofs) {
              object.stepOutcome = "fieldInvalidType";
            }
          }
          if (message.noProfileFound != null && message.hasOwnProperty("noProfileFound")) {
            object.noProfileFound = google$1.protobuf.Empty.toObject(message.noProfileFound, options);
            if (options.oneofs) {
              object.stepOutcome = "noProfileFound";
            }
          }
          if (message.singleProfileFound != null && message.hasOwnProperty("singleProfileFound")) {
            object.singleProfileFound = $root.profiles.v1.Profile.toObject(message.singleProfileFound, options);
            if (options.oneofs) {
              object.stepOutcome = "singleProfileFound";
            }
          }
          if (message.multipleProfilesFound != null && message.hasOwnProperty("multipleProfilesFound")) {
            object.multipleProfilesFound = $root.profiles.v1.MultipleProfilesFound.toObject(message.multipleProfilesFound, options);
            if (options.oneofs) {
              object.stepOutcome = "multipleProfilesFound";
            }
          }
          if (message.discoveredProfileIndexStatus != null && message.hasOwnProperty("discoveredProfileIndexStatus")) {
            object.discoveredProfileIndexStatus = $root.profiles.v1.DiscoveredProfileIndexStatus.toObject(message.discoveredProfileIndexStatus, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LookupStepOutcome";
        }
      }

      LookupStepOutcome.prototype.lookupIndex = null;
      LookupStepOutcome.prototype.discoveredIndexValue = null;
      LookupStepOutcome.prototype.stepExecutedAt = null;
      LookupStepOutcome.prototype.error = null;
      LookupStepOutcome.prototype.fieldMissing = null;
      LookupStepOutcome.prototype.fieldInvalidType = null;
      LookupStepOutcome.prototype.noProfileFound = null;
      LookupStepOutcome.prototype.singleProfileFound = null;
      LookupStepOutcome.prototype.multipleProfilesFound = null;
      LookupStepOutcome.prototype.discoveredProfileIndexStatus = null;

      return LookupStepOutcome;
    })();

    v1.DiscoveredProfileIndexStatus = (() => {
      class DiscoveredProfileIndexStatus {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DiscoveredProfileIndexStatus(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.profileIndex != null && Object.hasOwnProperty.call(message, "profileIndex")) {
            $root.profiles.v1.ProfileIndex.encode(message.profileIndex, writer.uint32(10).fork()).ldelim();
          }
          if (message.isActiveId != null && Object.hasOwnProperty.call(message, "isActiveId")) {
            writer.uint32(16).bool(message.isActiveId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DiscoveredProfileIndexStatus();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileIndex = $root.profiles.v1.ProfileIndex.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.isActiveId = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.DiscoveredProfileIndexStatus) {
            return object;
          }
          const message = new $root.profiles.v1.DiscoveredProfileIndexStatus();
          if (object.profileIndex != null) {
            if (typeof object.profileIndex !== "object") {
              throw new TypeError(".profiles.v1.DiscoveredProfileIndexStatus.profileIndex: object expected, but got " + (typeof object.profileIndex));
            }
            message.profileIndex = $root.profiles.v1.ProfileIndex.fromObject(object.profileIndex);
          }
          if (object.isActiveId != null) {
            message.isActiveId = Boolean(object.isActiveId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profileIndex = null;
            object.isActiveId = false;
          }
          let keys;
          if (message.profileIndex != null && message.hasOwnProperty("profileIndex")) {
            object.profileIndex = $root.profiles.v1.ProfileIndex.toObject(message.profileIndex, options);
          }
          if (message.isActiveId != null && message.hasOwnProperty("isActiveId")) {
            object.isActiveId = message.isActiveId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.DiscoveredProfileIndexStatus";
        }
      }

      DiscoveredProfileIndexStatus.prototype.profileIndex = null;
      DiscoveredProfileIndexStatus.prototype.isActiveId = false;

      return DiscoveredProfileIndexStatus;
    })();

    v1.MultipleProfilesFound = (() => {
      class MultipleProfilesFound {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MultipleProfilesFound(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.lead != null && Object.hasOwnProperty.call(message, "lead")) {
            $root.profiles.v1.LeadProfile.encode(message.lead, writer.uint32(10).fork()).ldelim();
          }
          if (message.patient != null && Object.hasOwnProperty.call(message, "patient")) {
            $root.profiles.v1.PatientProfile.encode(message.patient, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MultipleProfilesFound();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.lead = $root.profiles.v1.LeadProfile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.patient = $root.profiles.v1.PatientProfile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.MultipleProfilesFound) {
            return object;
          }
          const message = new $root.profiles.v1.MultipleProfilesFound();
          if (object.lead != null) {
            if (typeof object.lead !== "object") {
              throw new TypeError(".profiles.v1.MultipleProfilesFound.lead: object expected, but got " + (typeof object.lead));
            }
            message.lead = $root.profiles.v1.LeadProfile.fromObject(object.lead);
          }
          if (object.patient != null) {
            if (typeof object.patient !== "object") {
              throw new TypeError(".profiles.v1.MultipleProfilesFound.patient: object expected, but got " + (typeof object.patient));
            }
            message.patient = $root.profiles.v1.PatientProfile.fromObject(object.patient);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.lead = null;
            object.patient = null;
          }
          let keys;
          if (message.lead != null && message.hasOwnProperty("lead")) {
            object.lead = $root.profiles.v1.LeadProfile.toObject(message.lead, options);
          }
          if (message.patient != null && message.hasOwnProperty("patient")) {
            object.patient = $root.profiles.v1.PatientProfile.toObject(message.patient, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.MultipleProfilesFound";
        }
      }

      MultipleProfilesFound.prototype.lead = null;
      MultipleProfilesFound.prototype.patient = null;

      return MultipleProfilesFound;
    })();

    v1.LookupOutcome = (() => {
      class LookupOutcome {
        get outcome() {
          for (const key of ["discoveredProfile", "createdProfile", "error", "terminateGracefully", "retry", "usedProvidedProfile"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set outcome(name) {
          for (const key of ["discoveredProfile", "createdProfile", "error", "terminateGracefully", "retry", "usedProvidedProfile"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LookupOutcome(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.discoveredProfile != null && Object.hasOwnProperty.call(message, "discoveredProfile")) {
            $root.profiles.v1.Profile.encode(message.discoveredProfile, writer.uint32(10).fork()).ldelim();
          }
          if (message.createdProfile != null && Object.hasOwnProperty.call(message, "createdProfile")) {
            $root.profiles.v1.Profile.encode(message.createdProfile, writer.uint32(18).fork()).ldelim();
          }
          if (message.error != null && Object.hasOwnProperty.call(message, "error")) {
            $root.profiles.v1.LookupError.encode(message.error, writer.uint32(26).fork()).ldelim();
          }
          if (message.terminateGracefully != null && Object.hasOwnProperty.call(message, "terminateGracefully")) {
            google$1.protobuf.Empty.encode(message.terminateGracefully, writer.uint32(34).fork()).ldelim();
          }
          if (message.retry != null && Object.hasOwnProperty.call(message, "retry")) {
            google$1.protobuf.Empty.encode(message.retry, writer.uint32(42).fork()).ldelim();
          }
          if (message.usedProvidedProfile != null && Object.hasOwnProperty.call(message, "usedProvidedProfile")) {
            $root.profiles.v1.Profile.encode(message.usedProvidedProfile, writer.uint32(50).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LookupOutcome();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.discoveredProfile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.createdProfile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.error = $root.profiles.v1.LookupError.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.terminateGracefully = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.retry = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.usedProvidedProfile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LookupOutcome) {
            return object;
          }
          const message = new $root.profiles.v1.LookupOutcome();
          if (object.discoveredProfile != null) {
            if (typeof object.discoveredProfile !== "object") {
              throw new TypeError(".profiles.v1.LookupOutcome.discoveredProfile: object expected, but got " + (typeof object.discoveredProfile));
            }
            message.discoveredProfile = $root.profiles.v1.Profile.fromObject(object.discoveredProfile);
          }
          if (object.createdProfile != null) {
            if (typeof object.createdProfile !== "object") {
              throw new TypeError(".profiles.v1.LookupOutcome.createdProfile: object expected, but got " + (typeof object.createdProfile));
            }
            message.createdProfile = $root.profiles.v1.Profile.fromObject(object.createdProfile);
          }
          if (object.error != null) {
            if (typeof object.error !== "object") {
              throw new TypeError(".profiles.v1.LookupOutcome.error: object expected, but got " + (typeof object.error));
            }
            message.error = $root.profiles.v1.LookupError.fromObject(object.error);
          }
          if (object.terminateGracefully != null) {
            if (typeof object.terminateGracefully !== "object") {
              throw new TypeError(".profiles.v1.LookupOutcome.terminateGracefully: object expected, but got " + (typeof object.terminateGracefully));
            }
            message.terminateGracefully = google$1.protobuf.Empty.fromObject(object.terminateGracefully);
          }
          if (object.retry != null) {
            if (typeof object.retry !== "object") {
              throw new TypeError(".profiles.v1.LookupOutcome.retry: object expected, but got " + (typeof object.retry));
            }
            message.retry = google$1.protobuf.Empty.fromObject(object.retry);
          }
          if (object.usedProvidedProfile != null) {
            if (typeof object.usedProvidedProfile !== "object") {
              throw new TypeError(".profiles.v1.LookupOutcome.usedProvidedProfile: object expected, but got " + (typeof object.usedProvidedProfile));
            }
            message.usedProvidedProfile = $root.profiles.v1.Profile.fromObject(object.usedProvidedProfile);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.discoveredProfile != null && message.hasOwnProperty("discoveredProfile")) {
            object.discoveredProfile = $root.profiles.v1.Profile.toObject(message.discoveredProfile, options);
            if (options.oneofs) {
              object.outcome = "discoveredProfile";
            }
          }
          if (message.createdProfile != null && message.hasOwnProperty("createdProfile")) {
            object.createdProfile = $root.profiles.v1.Profile.toObject(message.createdProfile, options);
            if (options.oneofs) {
              object.outcome = "createdProfile";
            }
          }
          if (message.error != null && message.hasOwnProperty("error")) {
            object.error = $root.profiles.v1.LookupError.toObject(message.error, options);
            if (options.oneofs) {
              object.outcome = "error";
            }
          }
          if (message.terminateGracefully != null && message.hasOwnProperty("terminateGracefully")) {
            object.terminateGracefully = google$1.protobuf.Empty.toObject(message.terminateGracefully, options);
            if (options.oneofs) {
              object.outcome = "terminateGracefully";
            }
          }
          if (message.retry != null && message.hasOwnProperty("retry")) {
            object.retry = google$1.protobuf.Empty.toObject(message.retry, options);
            if (options.oneofs) {
              object.outcome = "retry";
            }
          }
          if (message.usedProvidedProfile != null && message.hasOwnProperty("usedProvidedProfile")) {
            object.usedProvidedProfile = $root.profiles.v1.Profile.toObject(message.usedProvidedProfile, options);
            if (options.oneofs) {
              object.outcome = "usedProvidedProfile";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LookupOutcome";
        }
      }

      LookupOutcome.prototype.discoveredProfile = null;
      LookupOutcome.prototype.createdProfile = null;
      LookupOutcome.prototype.error = null;
      LookupOutcome.prototype.terminateGracefully = null;
      LookupOutcome.prototype.retry = null;
      LookupOutcome.prototype.usedProvidedProfile = null;

      return LookupOutcome;
    })();

    v1.LookupError = (() => {
      class LookupError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LookupError(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage")) {
            writer.uint32(10).string(message.errorMessage);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LookupError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.errorMessage = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.LookupError) {
            return object;
          }
          const message = new $root.profiles.v1.LookupError();
          if (object.errorMessage != null) {
            message.errorMessage = String(object.errorMessage);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.errorMessage = "";
          }
          let keys;
          if (message.errorMessage != null && message.hasOwnProperty("errorMessage")) {
            object.errorMessage = message.errorMessage;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.LookupError";
        }
      }

      LookupError.prototype.errorMessage = "";

      return LookupError;
    })();

    v1.ProfileUpdateEmailAndPhoneNumberMergingResult = (() => {
      class ProfileUpdateEmailAndPhoneNumberMergingResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfileUpdateEmailAndPhoneNumberMergingResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile")) {
            $root.profiles.v1.Profile.encode(message.updatedProfile, writer.uint32(10).fork()).ldelim();
          }
          if (message.mergedIntoExistingEmail != null && Object.hasOwnProperty.call(message, "mergedIntoExistingEmail")) {
            $root.profiles.v1.Profile.encode(message.mergedIntoExistingEmail, writer.uint32(18).fork()).ldelim();
          }
          if (message.mergedIntoExistingPhone != null && Object.hasOwnProperty.call(message, "mergedIntoExistingPhone")) {
            $root.profiles.v1.Profile.encode(message.mergedIntoExistingPhone, writer.uint32(26).fork()).ldelim();
          }
          if (message.successfullyUpdatedEmail != null && Object.hasOwnProperty.call(message, "successfullyUpdatedEmail")) {
            writer.uint32(32).bool(message.successfullyUpdatedEmail);
          }
          if (message.successfullyUpdatedPhoneNumber != null && Object.hasOwnProperty.call(message, "successfullyUpdatedPhoneNumber")) {
            writer.uint32(40).bool(message.successfullyUpdatedPhoneNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfileUpdateEmailAndPhoneNumberMergingResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.updatedProfile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mergedIntoExistingEmail = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.mergedIntoExistingPhone = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.successfullyUpdatedEmail = reader.bool();
                break;
              }
              case 5: {
                message.successfullyUpdatedPhoneNumber = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ProfileUpdateEmailAndPhoneNumberMergingResult) {
            return object;
          }
          const message = new $root.profiles.v1.ProfileUpdateEmailAndPhoneNumberMergingResult();
          if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdateEmailAndPhoneNumberMergingResult.updatedProfile: object expected, but got " + (typeof object.updatedProfile));
            }
            message.updatedProfile = $root.profiles.v1.Profile.fromObject(object.updatedProfile);
          }
          if (object.mergedIntoExistingEmail != null) {
            if (typeof object.mergedIntoExistingEmail !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdateEmailAndPhoneNumberMergingResult.mergedIntoExistingEmail: object expected, but got " + (typeof object.mergedIntoExistingEmail));
            }
            message.mergedIntoExistingEmail = $root.profiles.v1.Profile.fromObject(object.mergedIntoExistingEmail);
          }
          if (object.mergedIntoExistingPhone != null) {
            if (typeof object.mergedIntoExistingPhone !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdateEmailAndPhoneNumberMergingResult.mergedIntoExistingPhone: object expected, but got " + (typeof object.mergedIntoExistingPhone));
            }
            message.mergedIntoExistingPhone = $root.profiles.v1.Profile.fromObject(object.mergedIntoExistingPhone);
          }
          if (object.successfullyUpdatedEmail != null) {
            message.successfullyUpdatedEmail = Boolean(object.successfullyUpdatedEmail);
          }
          if (object.successfullyUpdatedPhoneNumber != null) {
            message.successfullyUpdatedPhoneNumber = Boolean(object.successfullyUpdatedPhoneNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.updatedProfile = null;
            object.successfullyUpdatedEmail = false;
            object.successfullyUpdatedPhoneNumber = false;
          }
          let keys;
          if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            object.updatedProfile = $root.profiles.v1.Profile.toObject(message.updatedProfile, options);
          }
          if (message.mergedIntoExistingEmail != null && message.hasOwnProperty("mergedIntoExistingEmail")) {
            object.mergedIntoExistingEmail = $root.profiles.v1.Profile.toObject(message.mergedIntoExistingEmail, options);
          }
          if (message.mergedIntoExistingPhone != null && message.hasOwnProperty("mergedIntoExistingPhone")) {
            object.mergedIntoExistingPhone = $root.profiles.v1.Profile.toObject(message.mergedIntoExistingPhone, options);
          }
          if (message.successfullyUpdatedEmail != null && message.hasOwnProperty("successfullyUpdatedEmail")) {
            object.successfullyUpdatedEmail = message.successfullyUpdatedEmail;
          }
          if (message.successfullyUpdatedPhoneNumber != null && message.hasOwnProperty("successfullyUpdatedPhoneNumber")) {
            object.successfullyUpdatedPhoneNumber = message.successfullyUpdatedPhoneNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ProfileUpdateEmailAndPhoneNumberMergingResult";
        }
      }

      ProfileUpdateEmailAndPhoneNumberMergingResult.prototype.updatedProfile = null;
      ProfileUpdateEmailAndPhoneNumberMergingResult.prototype.mergedIntoExistingEmail = null;
      ProfileUpdateEmailAndPhoneNumberMergingResult.prototype.mergedIntoExistingPhone = null;
      ProfileUpdateEmailAndPhoneNumberMergingResult.prototype.successfullyUpdatedEmail = false;
      ProfileUpdateEmailAndPhoneNumberMergingResult.prototype.successfullyUpdatedPhoneNumber = false;

      return ProfileUpdateEmailAndPhoneNumberMergingResult;
    })();

    v1.ProfileUpdateEmailMergingResult = (() => {
      class ProfileUpdateEmailMergingResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfileUpdateEmailMergingResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile")) {
            $root.profiles.v1.Profile.encode(message.updatedProfile, writer.uint32(10).fork()).ldelim();
          }
          if (message.mergedIntoExistingEmail != null && Object.hasOwnProperty.call(message, "mergedIntoExistingEmail")) {
            $root.profiles.v1.Profile.encode(message.mergedIntoExistingEmail, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfileUpdateEmailMergingResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.updatedProfile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mergedIntoExistingEmail = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ProfileUpdateEmailMergingResult) {
            return object;
          }
          const message = new $root.profiles.v1.ProfileUpdateEmailMergingResult();
          if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdateEmailMergingResult.updatedProfile: object expected, but got " + (typeof object.updatedProfile));
            }
            message.updatedProfile = $root.profiles.v1.Profile.fromObject(object.updatedProfile);
          }
          if (object.mergedIntoExistingEmail != null) {
            if (typeof object.mergedIntoExistingEmail !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdateEmailMergingResult.mergedIntoExistingEmail: object expected, but got " + (typeof object.mergedIntoExistingEmail));
            }
            message.mergedIntoExistingEmail = $root.profiles.v1.Profile.fromObject(object.mergedIntoExistingEmail);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.updatedProfile = null;
          }
          let keys;
          if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            object.updatedProfile = $root.profiles.v1.Profile.toObject(message.updatedProfile, options);
          }
          if (message.mergedIntoExistingEmail != null && message.hasOwnProperty("mergedIntoExistingEmail")) {
            object.mergedIntoExistingEmail = $root.profiles.v1.Profile.toObject(message.mergedIntoExistingEmail, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ProfileUpdateEmailMergingResult";
        }
      }

      ProfileUpdateEmailMergingResult.prototype.updatedProfile = null;
      ProfileUpdateEmailMergingResult.prototype.mergedIntoExistingEmail = null;

      return ProfileUpdateEmailMergingResult;
    })();

    v1.ProfileUpdatePhoneNumberMergingResult = (() => {
      class ProfileUpdatePhoneNumberMergingResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProfileUpdatePhoneNumberMergingResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile")) {
            $root.profiles.v1.Profile.encode(message.updatedProfile, writer.uint32(10).fork()).ldelim();
          }
          if (message.mergedIntoExistingPhone != null && Object.hasOwnProperty.call(message, "mergedIntoExistingPhone")) {
            $root.profiles.v1.Profile.encode(message.mergedIntoExistingPhone, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProfileUpdatePhoneNumberMergingResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.updatedProfile = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mergedIntoExistingPhone = $root.profiles.v1.Profile.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.ProfileUpdatePhoneNumberMergingResult) {
            return object;
          }
          const message = new $root.profiles.v1.ProfileUpdatePhoneNumberMergingResult();
          if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdatePhoneNumberMergingResult.updatedProfile: object expected, but got " + (typeof object.updatedProfile));
            }
            message.updatedProfile = $root.profiles.v1.Profile.fromObject(object.updatedProfile);
          }
          if (object.mergedIntoExistingPhone != null) {
            if (typeof object.mergedIntoExistingPhone !== "object") {
              throw new TypeError(".profiles.v1.ProfileUpdatePhoneNumberMergingResult.mergedIntoExistingPhone: object expected, but got " + (typeof object.mergedIntoExistingPhone));
            }
            message.mergedIntoExistingPhone = $root.profiles.v1.Profile.fromObject(object.mergedIntoExistingPhone);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.updatedProfile = null;
          }
          let keys;
          if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            object.updatedProfile = $root.profiles.v1.Profile.toObject(message.updatedProfile, options);
          }
          if (message.mergedIntoExistingPhone != null && message.hasOwnProperty("mergedIntoExistingPhone")) {
            object.mergedIntoExistingPhone = $root.profiles.v1.Profile.toObject(message.mergedIntoExistingPhone, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.ProfileUpdatePhoneNumberMergingResult";
        }
      }

      ProfileUpdatePhoneNumberMergingResult.prototype.updatedProfile = null;
      ProfileUpdatePhoneNumberMergingResult.prototype.mergedIntoExistingPhone = null;

      return ProfileUpdatePhoneNumberMergingResult;
    })();

    v1.VerifyProfileRequest = (() => {
      class VerifyProfileRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new VerifyProfileRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.lookupType != null && Object.hasOwnProperty.call(message, "lookupType")) {
            writer.uint32(8).int32(message.lookupType);
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(18).string(message.emailAddress);
          }
          if (message.propertyAssertions != null && Object.hasOwnProperty.call(message, "propertyAssertions")) {
            $root.profiles.v1.VerifyProfileRequest.PropertyAssertions.encode(message.propertyAssertions, writer.uint32(26).fork()).ldelim();
          }
          if (message.multipleProfilesAction != null && Object.hasOwnProperty.call(message, "multipleProfilesAction")) {
            writer.uint32(32).int32(message.multipleProfilesAction);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new VerifyProfileRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.lookupType = reader.int32();
                break;
              }
              case 2: {
                message.emailAddress = reader.string();
                break;
              }
              case 3: {
                message.propertyAssertions = $root.profiles.v1.VerifyProfileRequest.PropertyAssertions.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.multipleProfilesAction = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.profiles.v1.VerifyProfileRequest) {
            return object;
          }
          const message = new $root.profiles.v1.VerifyProfileRequest();
          if (object.lookupType != null) {
            switch (object.lookupType) {
              case "UNSPECIFIED_ILLEGAL_LOOKUP_TYPE":
              case 0: {
                message.lookupType = 0;
                break;
              }
              case "LOOKUP_TYPE_LEAD":
              case 1: {
                message.lookupType = 1;
                break;
              }
              case "LOOKUP_TYPE_PATIENT":
              case 2: {
                message.lookupType = 2;
                break;
              }
              case "LOOKUP_TYPE_LEAD_AND_PATIENT":
              case 3: {
                message.lookupType = 3;
                break;
              }
              default: {
                if (typeof object.lookupType == "number") {
                  message.lookupType = object.lookupType;
                  break;
                }
                break;
              }
            }
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.propertyAssertions != null) {
            if (typeof object.propertyAssertions !== "object") {
              throw new TypeError(".profiles.v1.VerifyProfileRequest.propertyAssertions: object expected, but got " + (typeof object.propertyAssertions));
            }
            message.propertyAssertions = $root.profiles.v1.VerifyProfileRequest.PropertyAssertions.fromObject(object.propertyAssertions);
          }
          if (object.multipleProfilesAction != null) {
            switch (object.multipleProfilesAction) {
              case "UNSPECIFIED_ILLEGAL_MULTIPLE_PROFILES_ACTION":
              case 0: {
                message.multipleProfilesAction = 0;
                break;
              }
              case "MULTIPLE_PROFILES_ACTION_CHOOSE_LEAD":
              case 1: {
                message.multipleProfilesAction = 1;
                break;
              }
              case "MULTIPLE_PROFILES_ACTION_CHOOSE_PATIENT":
              case 2: {
                message.multipleProfilesAction = 2;
                break;
              }
              default: {
                if (typeof object.multipleProfilesAction == "number") {
                  message.multipleProfilesAction = object.multipleProfilesAction;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.lookupType = options.enums === String ? "UNSPECIFIED_ILLEGAL_LOOKUP_TYPE" : 0
            object.emailAddress = "";
          }
          let keys;
          if (message.lookupType != null && message.hasOwnProperty("lookupType")) {
            object.lookupType = options.enums === String ? $root.profiles.v1.LookupType[message.lookupType] === undefined ? message.lookupType : $root.profiles.v1.LookupType[message.lookupType] : message.lookupType;
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.propertyAssertions != null && message.hasOwnProperty("propertyAssertions")) {
            object.propertyAssertions = $root.profiles.v1.VerifyProfileRequest.PropertyAssertions.toObject(message.propertyAssertions, options);
          }
          if (message.multipleProfilesAction != null && message.hasOwnProperty("multipleProfilesAction")) {
            object.multipleProfilesAction = options.enums === String ? $root.profiles.v1.MultipleProfilesAction[message.multipleProfilesAction] === undefined ? message.multipleProfilesAction : $root.profiles.v1.MultipleProfilesAction[message.multipleProfilesAction] : message.multipleProfilesAction;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "profiles.v1.VerifyProfileRequest";
        }
      }

      VerifyProfileRequest.prototype.lookupType = 0;
      VerifyProfileRequest.prototype.emailAddress = "";
      VerifyProfileRequest.prototype.propertyAssertions = null;
      VerifyProfileRequest.prototype.multipleProfilesAction = null;
      VerifyProfileRequest.PropertyAssertions = (() => {
        class PropertyAssertions {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new PropertyAssertions(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
              writer.uint32(10).string(message.dateOfBirth);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new PropertyAssertions();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.dateOfBirth = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.profiles.v1.VerifyProfileRequest.PropertyAssertions) {
              return object;
            }
            const message = new $root.profiles.v1.VerifyProfileRequest.PropertyAssertions();
            if (object.dateOfBirth != null) {
              message.dateOfBirth = String(object.dateOfBirth);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
              object.dateOfBirth = message.dateOfBirth;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "profiles.v1.VerifyProfileRequest.PropertyAssertions";
          }
        }

        PropertyAssertions.prototype.dateOfBirth = null;

        return PropertyAssertions;
      })();

      return VerifyProfileRequest;
    })();

    v1.InternalProfileIndexOp = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_INTERNAL_PROFILE_INDEX_OP_TYPE"] = 0;
      values[valuesById[1] = "UPDATE_INDEX"] = 1;
      values[valuesById[2] = "DELETE_INDEX"] = 2;
      return values;
    })();

    v1.LookupType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_LOOKUP_TYPE"] = 0;
      values[valuesById[1] = "LOOKUP_TYPE_LEAD"] = 1;
      values[valuesById[2] = "LOOKUP_TYPE_PATIENT"] = 2;
      values[valuesById[3] = "LOOKUP_TYPE_LEAD_AND_PATIENT"] = 3;
      return values;
    })();

    v1.FieldNotPresentAction = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_FIELD_NOT_PRESENT_ACTION"] = 0;
      values[valuesById[1] = "FIELD_NOT_PRESENT_ACTION_ERROR"] = 1;
      values[valuesById[2] = "FIELD_NOT_PRESENT_ACTION_SKIP_TO_NEXT_LOOKUP_IN_SEQUENCE"] = 2;
      values[valuesById[3] = "FIELD_NOT_PRESENT_ACTION_TERMINATE_WORKFLOW_GRACEFULLY"] = 3;
      return values;
    })();

    v1.MultipleProfilesAction = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_MULTIPLE_PROFILES_ACTION"] = 0;
      values[valuesById[1] = "MULTIPLE_PROFILES_ACTION_CHOOSE_LEAD"] = 1;
      values[valuesById[2] = "MULTIPLE_PROFILES_ACTION_CHOOSE_PATIENT"] = 2;
      return values;
    })();

    v1.NoProfileFoundAction = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_NO_PROFILE_FOUND_ACTION"] = 0;
      values[valuesById[1] = "NO_PROFILE_FOUND_ACTION_CREATE_LEAD"] = 1;
      values[valuesById[2] = "NO_PROFILE_FOUND_ACTION_CREATE_PATIENT"] = 2;
      values[valuesById[3] = "NO_PROFILE_FOUND_ACTION_ERROR"] = 3;
      values[valuesById[4] = "NO_PROFILE_FOUND_ACTION_TERMINATE_WORKFLOW_GRACEFULLY"] = 4;
      values[valuesById[5] = "NO_PROFILE_FOUND_ACTION_RETRY"] = 5;
      return values;
    })();

    v1.ProfilesService = (() => {
      class ProfilesService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new ProfilesService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(ProfilesService.prototype.get = function get(request, callback) {
        return this.rpcCall(get, $root.profiles.v1.GetRequest, $root.profiles.v1.GetResponse, request, callback);
      }, "name", { value: "Get" });

      Object.defineProperty(ProfilesService.prototype.create = function create(request, callback) {
        return this.rpcCall(create, $root.profiles.v1.CreateRequest, $root.profiles.v1.CreateResponse, request, callback);
      }, "name", { value: "Create" });

      Object.defineProperty(ProfilesService.prototype.merge = function merge(request, callback) {
        return this.rpcCall(merge, $root.profiles.v1.MergeRequest, $root.profiles.v1.MergeResponse, request, callback);
      }, "name", { value: "Merge" });

      Object.defineProperty(ProfilesService.prototype.associateThirdPartyIds = function associateThirdPartyIds(request, callback) {
        return this.rpcCall(associateThirdPartyIds, $root.profiles.v1.AssociateThirdPartyIdsRequest, $root.profiles.v1.AssociateThirdPartyIdsResponse, request, callback);
      }, "name", { value: "AssociateThirdPartyIds" });

      Object.defineProperty(ProfilesService.prototype.listPropertySchemas = function listPropertySchemas(request, callback) {
        return this.rpcCall(listPropertySchemas, $root.profiles.v1.ListPropertySchemasRequest, $root.profiles.v1.ListPropertySchemasResponse, request, callback);
      }, "name", { value: "ListPropertySchemas" });

      Object.defineProperty(ProfilesService.prototype.listPropertyValues = function listPropertyValues(request, callback) {
        return this.rpcCall(listPropertyValues, $root.profiles.v1.ListPropertyValuesRequest, $root.profiles.v1.ListPropertyValuesResponse, request, callback);
      }, "name", { value: "ListPropertyValues" });

      Object.defineProperty(ProfilesService.prototype.getProfileProperties = function getProfileProperties(request, callback) {
        return this.rpcCall(getProfileProperties, $root.profiles.v1.GetProfilePropertiesRequest, google$2.protobuf.Struct, request, callback);
      }, "name", { value: "GetProfileProperties" });

      Object.defineProperty(ProfilesService.prototype.search = function search(request, callback) {
        return this.rpcCall(search, $root.profiles.v1.IndexSearchRequest, $root.profiles.v1.IndexSearchResponse, request, callback);
      }, "name", { value: "Search" });

      Object.defineProperty(ProfilesService.prototype.list = function list(request, callback) {
        return this.rpcCall(list, $root.profiles.v1.ListRequest, $root.profiles.v1.ListResponse, request, callback);
      }, "name", { value: "List" });

      Object.defineProperty(ProfilesService.prototype.parseFormatPhoneNumber = function parseFormatPhoneNumber(request, callback) {
        return this.rpcCall(parseFormatPhoneNumber, $root.profiles.v1.ParseFormatPhoneNumberRequest, $root.profiles.v1.ParseFormatPhoneNumberResponse, request, callback);
      }, "name", { value: "ParseFormatPhoneNumber" });

      Object.defineProperty(ProfilesService.prototype.convertToPatient = function convertToPatient(request, callback) {
        return this.rpcCall(convertToPatient, $root.profiles.v1.ConvertToPatientRequest, $root.profiles.v1.ConvertProfileToPatientResult, request, callback);
      }, "name", { value: "ConvertToPatient" });

      Object.defineProperty(ProfilesService.prototype.makeProfileIndexActive = function makeProfileIndexActive(request, callback) {
        return this.rpcCall(makeProfileIndexActive, $root.profiles.v1.MakeProfileIndexActiveRequest, $root.profiles.v1.MakeProfileIndexActiveResponse, request, callback);
      }, "name", { value: "MakeProfileIndexActive" });

      Object.defineProperty(ProfilesService.prototype.verifyProfile = function verifyProfile(request, callback) {
        return this.rpcCall(verifyProfile, $root.profiles.v1.VerifyProfileRequest, $root.profiles.v1.ThirdPartyIds, request, callback);
      }, "name", { value: "VerifyProfile" });

      return ProfilesService;
    })();

    return v1;
  })();

  return profiles;
})();

export const profiles = $root.profiles;
